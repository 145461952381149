import React from 'react';
import { MenuOption } from '../../basic/DotMenu';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import RelationshipIndicator from './RelationshipIndicator';
import RelationshipsActionHeader from './RelationshipsActionHeader';

export enum RelationshipType {
  enables = 'enables',
  dependsOn = 'depends on',
  related = 'related to',
}

type RelationshipRowProps = {
  relatedEntityId: string;
  relatedEntityName: string;
  relationshipWithThisEntity: RelationshipType;
  nextTouchpoint: string;
  touchpointCount: number;
};

const RelationshipRowButton = ({
  relatedEntityId,
  relatedEntityName,
  relationshipWithThisEntity,
  nextTouchpoint,
  touchpointCount,
}: RelationshipRowProps): React.ReactElement => {
  return (
    // TODO: Re-enable button when slideout for mutual action plan has been designed
    <button
      type="button"
      key={relatedEntityId}
      className={`text-sm w-full cursor-default flex justify-between bg-gray-100 border border-gray-200 px-4 py-3 align-middle items-center rounded-md`} // hover:bg-gray-200
      onClick={
        () => null // console.log(`Show slideover or edit panel for "${relatedEntityName}"`)
      }
    >
      <div className="text-left">
        <div className="font-medium mb-0.5">{relatedEntityName}</div>
        <RelationshipIndicator type={relationshipWithThisEntity} />
      </div>
      <div className="text-right">
        <p className="mb-0.5">Next touchpoint: {nextTouchpoint}</p>
        <p className="text-gray-500">{touchpointCount} remaining touchpoints</p>
      </div>
    </button>
  );
};

const RelationshipsTitle = () => (
  <DetailsHeaderTitle
    title={`Relationships`}
    tooltipText={`Things we have interdependencies with`}
  />
);

type RelatedEntity = {
  id: string;
  relatedEntityName: string;
  relationshipWithThisEntity: RelationshipType;
  touchpointCount: number;
  nextTouchpoint: string;
};

type RelationshipsContentProps = {
  relatedEntities: RelatedEntity[];
};

const RelationshipsContent = ({
  relatedEntities,
}: RelationshipsContentProps) => (
  <div className="min-w-full space-y-4 lg:space-y-3">
    {relatedEntities.length > 0 ? (
      <div className="space-y-3">
        {relatedEntities.map(entity => (
          <div key={entity.id}>
            <RelationshipRowButton
              relatedEntityId={entity.id}
              relatedEntityName={entity.relatedEntityName}
              relationshipWithThisEntity={entity.relationshipWithThisEntity}
              nextTouchpoint={entity.nextTouchpoint}
              touchpointCount={entity.touchpointCount}
            />
          </div>
        ))}
      </div>
    ) : (
      // TODO: Replace with a nice empty state with call to action
      <div>
        <p className="text-gray-400 italic text-center text-sm">
          No relationships identified yet.
        </p>
      </div>
    )}
  </div>
);

type RelationshipsPanelProps = {
  relatedEntities: RelatedEntity[];

  menuOptions: Array<MenuOption>;
  actionsDisabled: boolean;
  onAddClick: () => void;
  isVisible: boolean;
  isHidden: boolean;
};

export default function RelationshipsPanel({
  relatedEntities,
  actionsDisabled,
  onAddClick,
  isVisible,
  isHidden,
}: RelationshipsPanelProps): React.ReactElement {
  return (
    <div className={`${isHidden && 'hidden'}`}>
      <DetailsHeader
        headerLeft={<RelationshipsTitle />}
        headerRight={
          <RelationshipsActionHeader
            onClick={onAddClick}
            isVisible={isVisible}
            disabled={actionsDisabled}
          />
        }
        content={<RelationshipsContent relatedEntities={relatedEntities} />}
      />
    </div>
  );
}
