import React, { useContext, useState } from 'react';
import {
  capitaliseFirstLetter,
  classNames,
  getLocalDate,
  getRelativeTime,
} from '../../common/utils';
import { PortfolioPageContext } from './PortfolioPageContext';
import {
  EntityType,
  ProgramMember,
  ProgramRole,
  ProjectMember,
  ProjectRole,
} from '../../api/index';

import ReactTooltip from 'react-tooltip';
import translate, {
  enumTranslates,
  strings,
} from '../../common/i18n/translate';
import EmptyState from '../../common/layout/EmptyState';
import { PROGRAM, PROJECT } from '../../common/routes';
import Avatar from 'react-avatar';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import { CaretRight, FolderSimple, Lock, Wrench } from 'phosphor-react';
import { ProgramPageContext } from '../program/ProgramPageContext';
import PortfolioTreeLayout from './PortfolioTreeLayout';
import Tag from '../../common/Tag';
import PermissionLink from '../../common/PermissionLink';
import ToggleButton from '../../common/ToggleButton';
import { avatarBgColor, globalTooltipDelay } from '../../common/constants';

function PortfolioListingTab(): React.ReactElement {
  // Get page context
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const { state: programPageState } = useContext(ProgramPageContext);

  const [showAsList, setShowAsList] = useState(false);
  const [showDescriptions, setShowDescriptions] = useState(false);

  const tooltipDelay = globalTooltipDelay;

  // Get list of who can edit the current portfolio

  // Check current user permisssion level

  // Icons
  const iconProgram = (
    <FolderSimple
      className="w-6 h-6 flex-shrink-0 text-gray-400 group-hover:text-primary-500"
      weight="duotone"
      data-tip
      data-for={'program-icon'}
    />
  );

  const iconProject = (
    <Wrench
      className="w-6 h-6 flex-shrink-0 text-gray-400 group-hover:text-primary-500"
      weight="duotone"
      data-tip
      data-for={'project-icon'}
    />
  );

  const iconLock = (
    <Lock weight="fill" className="h-5 w-5 shrink-0" aria-hidden="true" />
  );

  const iconCaretRight = (
    <CaretRight
      weight="bold"
      size={16}
      className="shrink-0"
      aria-hidden="true"
    />
  );

  const portfolioContentsTitle = (
    <DetailsPanelHeader
      title={`What's in this ${capitaliseFirstLetter(
        enumTranslates[EntityType.Portfolio]
      )}`}
      description={`All ${enumTranslates[EntityType.Program]}s and ${
        enumTranslates[EntityType.Project]
      }s in this ${enumTranslates[EntityType.Portfolio]}`}
    />
  );

  const portfolioContentsButton = portfolioPageState.selectedPortfolio && (
    <div className="flex gap-x-3">
      {/* Toggle description if not in list view */}
      <ToggleButton
        label="Show descriptions"
        stateOn={showDescriptions}
        setStateOn={setShowDescriptions}
      />

      {/* Toggle list view */}
      <ToggleButton
        label="List view"
        stateOn={showAsList}
        setStateOn={setShowAsList}
      />

      {/* {portfolioPageState.selectedPortfolio && userIsAdmin && (
        <WizardButton
          editObject={null}
          parentObject={portfolioPageState.selectedPortfolio}
          type="PROGRAM_DETAILS"
          quickSave={true}
          startStep={1}
        />
      )} */}
    </div>
  );

  const portfolioPrograms =
    portfolioPageState?.selectedPortfolio?.programs || [];
  const myList = (
    <React.Fragment>
      {portfolioPrograms &&
      portfolioPrograms.length > 0 &&
      portfolioPageState.selectedPortfolio &&
      programPageState.programs &&
      programPageState.programs?.length > 0 ? (
        <div className="overflow-hidden border border-gray-200 sm:rounded-lg">
          {/* Tailwind grid table style */}
          <table className="table min-w-full divide-y divide-gray-200">
            <thead className="table-header-group bg-gray-50">
              {/* Header row */}
              <tr className="table-row">
                {/* <div className="table-cell align-middle pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"></div> */}
                <th className="table-cell min-w-[30rem] grow align-middle pl-12 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {showDescriptions ? 'Name & Description' : 'Name'}
                </th>
                {/* <div className="hidden xl:table-cell align-middle px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Description
                  </div> */}

                <th className="table-cell align-middle px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {capitaliseFirstLetter(enumTranslates[EntityType.Project])}{' '}
                  Stage
                </th>

                <th className="table-cell align-middle px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {translate(
                    strings.PROJECT_DELIVERY_DATE,
                    'Estimated Completion Date'
                  )}
                </th>

                <th className="table-cell align-middle px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Funding
                  {/* {translate(
                      strings.PROJECT_DELIVERY_CONFIDENCE,
                      'Delivery Confidence'
                    )} */}
                </th>

                <th className="table-cell align-middle px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {enumTranslates[ProgramRole.BusinessOwner]}
                </th>

                <th className="table-cell align-middle px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Manager
                </th>

                <th className="table-cell align-middle pl-2 pr-4 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {/* Actions */}
                </th>
              </tr>
            </thead>

            {/* Table data */}
            <tbody className="table-row-group bg-white text-sm divide-y divide-gray-200">
              {portfolioPrograms?.map((program, programIndex: number) => {
                // Get key program roles
                const programMembers = program.members
                  ?.items as ProgramMember[];
                const programBusinessOwner = programMembers.find(
                  member => member.role === ProgramRole.BusinessOwner
                );
                const programLead = programMembers.find(
                  member => member.role === ProgramRole.ProgramOwner
                );

                return (
                  // Create a row for each program
                  <React.Fragment key={program.id}>
                    <tr className="table-row align-middle border-t border-gray-200 row-span-full hover:bg-gray-100">
                      {/* Program name */}
                      <td
                        className={classNames(
                          programIndex > 0 ? '' : '',
                          'table-cell align-middle pl-4 pr-4 py-3 whitespace-normal border-t border-gray-200'
                        )}
                      >
                        <div className="flex flex-row align-top gap-x-2">
                          {iconProgram}

                          <div className="flex flex-col py-0.5">
                            <PermissionLink
                              entity={program}
                              to={`${PROGRAM}/${program.id}#performance`}
                              allowedLayout={
                                <div className="font-medium hover:text-primary-600 hover:underline">
                                  {program.name}
                                </div>
                              }
                              blockedLayout={
                                <div className="font-medium">
                                  {program.name}
                                </div>
                              }
                            />

                            <div
                              className={`mt-1 ${
                                showDescriptions
                                  ? 'whitespace-pre-wrap'
                                  : 'hidden'
                              }`}
                            >
                              {program.outcome &&
                              program.outcome?.length > 0 ? (
                                program.outcome
                              ) : (
                                <p className="text-sm italic text-gray-400">
                                  No description provided
                                </p>
                              )}
                            </div>
                          </div>

                          <ReactTooltip
                            id={'program-icon'}
                            place="left"
                            type="info"
                            effect="solid"
                            delayShow={tooltipDelay}
                          >
                            <div className="text-sm">
                              <p>
                                This is a {enumTranslates[EntityType.Program]}
                              </p>
                            </div>
                          </ReactTooltip>
                        </div>
                      </td>

                      {/* Stage */}
                      <td
                        className={classNames(
                          programIndex > 0 ? '' : '',
                          'table-cell text-center align-middle px-2 py-3 whitespace-normal border-t border-gray-200'
                        )}
                      >
                        {/* Blank - Programs do not have a stage */}-
                      </td>

                      {/* Date */}
                      <td
                        className={classNames(
                          programIndex > 0 ? '' : '',
                          'table-cell text-center align-middle px-2 py-3 whitespace-normal border-t border-gray-200'
                        )}
                      >
                        {/* Blank - Programs do not have a stage */}-
                      </td>

                      {/* Funding */}
                      <td
                        className={classNames(
                          programIndex > 0 ? '' : '',
                          'table-cell align-middle justify-center px-2 py-3 whitespace-normal border-t border-gray-200 text-center'
                        )}
                      >
                        {program.envelope ? (
                          `$${program.envelope.toLocaleString()}`
                        ) : (
                          <div className="italic text-gray-400">Not set</div>
                        )}
                        {/* {getLatestProgramUpdate(program) != null ? (
                          <div className="flex w-min mx-auto">
                            <ConfidenceBadge
                              rating={getLatestProgramUpdate(program)?.trend}
                              reviewDate={
                                getLatestProgramUpdate(program)?.updateDate
                              }
                            />
                          </div>
                        ) : (
                          <div className="flex w-min mx-auto">
                            <ConfidenceBadge
                              rating={null}
                              // reviewDate={null}
                            />
                          </div>
                        )} */}
                      </td>

                      {/* Sponsor and manager */}
                      <td
                        className={classNames(
                          programIndex > 0 ? '' : '',
                          'table-cell align-middle px-2 py-3 whitespace-normal border-t border-gray-200 text-center'
                        )}
                      >
                        <Avatar
                          name={
                            programBusinessOwner
                              ? `${programBusinessOwner.user.firstName} ${programBusinessOwner.user.lastName}`
                              : '?'
                          }
                          size="32"
                          round="16px"
                          color={avatarBgColor}
                        />
                      </td>

                      <td
                        className={classNames(
                          programIndex > 0 ? '' : '',
                          'table-cell align-middle px-2 py-3 whitespace-normal border-t border-gray-200 text-center'
                        )}
                      >
                        <Avatar
                          name={
                            programLead
                              ? `${programLead.user.firstName} ${programLead.user.lastName}`
                              : '?'
                          }
                          size="32"
                          round="16px"
                          color={avatarBgColor}
                        />
                      </td>

                      {/* Actions */}
                      <td
                        className={classNames(
                          programIndex > 0 ? '' : '',
                          'table-cell align-middle pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200 text-center'
                        )}
                      >
                        {/* If a user has access, or we are in demo mode, they can go to the program page */}
                        <PermissionLink
                          entity={program}
                          to={`${PROGRAM}/${program.id}#performance`}
                          allowedLayout={
                            <div className="flex justify-center mx-auto">
                              <button
                                type="button"
                                className="p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                {iconCaretRight}
                              </button>
                            </div>
                          }
                          blockedLayout={
                            <div className="flex justify-center mx-auto">
                              <button
                                // id={program.id}
                                data-tip
                                data-for={program.id}
                                type="button"
                                className="p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                              >
                                {iconLock}
                              </button>
                              <ReactTooltip
                                id={program.id}
                                place="left"
                                type="dark"
                                effect="solid"
                              >
                                <div className="text-sm">
                                  <p>
                                    You do not currently have access to this
                                    {enumTranslates[EntityType.Program]}.
                                  </p>
                                  {programBusinessOwner ? (
                                    <p className="pb-1">
                                      {`Contact ${programBusinessOwner?.user.email} for access.`}
                                    </p>
                                  ) : programLead ? (
                                    <p className="pb-1">
                                      {`Contact ${programLead?.user.email} for access.`}
                                    </p>
                                  ) : null}
                                </div>
                              </ReactTooltip>
                            </div>
                          }
                        />
                      </td>
                    </tr>

                    {/* Add a row for each project in this program */}
                    {program.projects &&
                      program.projects.map(project => {
                        // Get key project roles
                        const projectMembers = project.members
                          ?.items as ProjectMember[];
                        const projectBusinessOwner = projectMembers.find(
                          member => member.role === ProjectRole.BusinessOwner
                        );
                        const projectLead = projectMembers.find(
                          member => member.role === ProjectRole.ProjectOwner
                        );

                        return (
                          <tr
                            key={project.id}
                            className="table-row align-middle border-t border-gray-200 row-span-full hover:bg-gray-100"
                          >
                            {/* Project name */}
                            <div
                              className={classNames(
                                programIndex > 0 ? '' : '',
                                'table-cell align-middle pl-7 pr-4 py-3 whitespace-normal border-t border-gray-200'
                              )}
                            >
                              <div className="flex flex-row align-top gap-x-2">
                                {iconProject}

                                <div className="flex flex-col">
                                  {portfolioPageState.selectedPortfolio && (
                                    <PermissionLink
                                      entity={project}
                                      parentPortfolio={
                                        portfolioPageState.selectedPortfolio
                                      }
                                      to={`${PROJECT}/${project.id}`}
                                      allowedLayout={
                                        <div className="font-medium hover:text-primary-600 hover:underline">
                                          {project.name}
                                        </div>
                                      }
                                      blockedLayout={
                                        <div className="font-medium">
                                          {project.name}
                                        </div>
                                      }
                                    />
                                  )}

                                  <div
                                    className={`mt-1 ${
                                      showDescriptions
                                        ? 'whitespace-pre-wrap'
                                        : 'hidden'
                                    }`}
                                  >
                                    {project.outcome &&
                                    project.outcome.length > 0 ? (
                                      project.outcome
                                    ) : (
                                      <p className="text-sm italic text-gray-400">
                                        No description provided
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <ReactTooltip
                                  id={'project-icon'}
                                  place="left"
                                  type="info"
                                  effect="solid"
                                  delayShow={tooltipDelay}
                                >
                                  <div className="text-sm">
                                    <p>
                                      This is a{' '}
                                      {enumTranslates[EntityType.Project]}
                                    </p>
                                  </div>
                                </ReactTooltip>
                              </div>
                            </div>

                            {/* Stage */}
                            <div
                              className={classNames(
                                programIndex > 0 ? '' : '',
                                'table-cell align-middle px-4 py-3 whitespace-normal border-t border-gray-200'
                              )}
                            >
                              <div className="flex items-center justify-center">
                                <Tag
                                  type="custom"
                                  textColour="text-black"
                                  bgColour="bg-gray-200"
                                  label={
                                    enumTranslates[project.stage as string]
                                  }
                                />
                              </div>
                            </div>

                            {/* Delivery Date */}
                            <div
                              className={classNames(
                                programIndex > 0 ? '' : '',
                                'table-cell text-center align-middle px-2 py-3 whitespace-normal border-t border-gray-200'
                              )}
                            >
                              <div className="w-full justify-center flex-col">
                                {project.deliveryDate ? (
                                  <>
                                    <p className="whitespace-nowrap">
                                      {getLocalDate(project.deliveryDate)}
                                    </p>
                                    <p className="text-xs text-gray-400 whitespace-nowrap">
                                      {capitaliseFirstLetter(
                                        getRelativeTime(project.deliveryDate)
                                      )}
                                    </p>
                                  </>
                                ) : (
                                  <p className="text-gray-400 italic">
                                    Not set
                                  </p>
                                )}
                              </div>
                            </div>

                            {/* Funding */}
                            <div
                              className={classNames(
                                programIndex > 0 ? '' : '',
                                'table-cell align-middle justify-center px-2 py-3 whitespace-normal border-t border-gray-200 text-center'
                              )}
                            >
                              {project.budget ? (
                                `$${project.budget.toLocaleString()}`
                              ) : (
                                <div className="italic text-gray-400">
                                  Not set
                                </div>
                              )}
                              {/* {getLatestProjectUpdate(project) != null ? (
                                <div className="flex w-min mx-auto">
                                  <ConfidenceBadge
                                    rating={
                                      getLatestProjectUpdate(project)
                                        ?.confidenceRating
                                    }
                                    reviewDate={
                                      getLatestProjectUpdate(project)
                                        ?.updateDate
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="flex w-min mx-auto">
                                  <ConfidenceBadge
                                    rating={null}
                                    // reviewDate={null}
                                  />
                                </div>
                              )} */}
                            </div>

                            {/* Key roles */}
                            <div
                              className={classNames(
                                programIndex > 0 ? '' : '',
                                'table-cell align-middle px-2 py-3 whitespace-normal border-t border-gray-200 text-center'
                              )}
                            >
                              <Avatar
                                name={
                                  projectBusinessOwner
                                    ? `${projectBusinessOwner.user.firstName} ${projectBusinessOwner.user.lastName}`
                                    : '?'
                                }
                                size="32"
                                round="16px"
                                color={avatarBgColor}
                              />
                            </div>

                            <div
                              className={classNames(
                                programIndex > 0 ? '' : '',
                                'table-cell align-middle px-2 py-3 whitespace-normal border-t border-gray-200 text-center'
                              )}
                            >
                              <Avatar
                                name={
                                  projectLead
                                    ? `${projectLead.user.firstName} ${projectLead.user.lastName}`
                                    : '?'
                                }
                                size="32"
                                round="16px"
                                color={avatarBgColor}
                              />
                            </div>

                            <div
                              className={classNames(
                                programIndex > 0 ? '' : '',
                                'table-cell align-middle pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200 text-center'
                              )}
                            >
                              {/* If a user has access, or we are in demo mode, they can go to the project page */}
                              {portfolioPageState.selectedPortfolio && (
                                <PermissionLink
                                  entity={project}
                                  parentPortfolio={
                                    portfolioPageState.selectedPortfolio
                                  }
                                  to={`${PROJECT}/${project.id}`}
                                  allowedLayout={
                                    <div className="flex justify-center mx-auto">
                                      <button
                                        type="button"
                                        className="p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                      >
                                        {iconCaretRight}
                                      </button>
                                    </div>
                                  }
                                  blockedLayout={
                                    // If user is not a member, they can request access
                                    <div className="flex justify-center mx-auto">
                                      <button
                                        // id={program.id}
                                        data-tip
                                        data-for={project.id}
                                        type="button"
                                        className="p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                      >
                                        {iconLock}
                                      </button>
                                      <ReactTooltip
                                        id={project.id}
                                        place="left"
                                        type="dark"
                                        effect="solid"
                                      >
                                        <div className="text-sm">
                                          <p>
                                            You do not currently have access to
                                            this{' '}
                                            {enumTranslates[EntityType.Project]}
                                            .
                                          </p>

                                          {projectBusinessOwner ? (
                                            <p className="pb-1">
                                              {`Contact ${projectBusinessOwner?.user.email} for access.`}
                                            </p>
                                          ) : projectLead ? (
                                            <p className="pb-1">
                                              {`Contact ${projectLead?.user.email} for access.`}
                                            </p>
                                          ) : null}
                                        </div>
                                      </ReactTooltip>
                                    </div>
                                  }
                                />
                              )}
                            </div>
                          </tr>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <EmptyState type={`${enumTranslates[EntityType.Program]}s`} />
      )}
    </React.Fragment>
  );

  const portfolioContentsContent = (
    <React.Fragment>
      {showAsList ? (
        myList
      ) : (
        <PortfolioTreeLayout
          portfolio={portfolioPageState.selectedPortfolio}
          showDetails={showDescriptions}
        />
      )}
    </React.Fragment>
  );

  return (
    <div className="flex flex-col gap-y-6">
      <DetailsPanel
        headerLeft={portfolioContentsTitle}
        headerRight={portfolioContentsButton}
        content={portfolioContentsContent}
      />
    </div>
  );
}

export default PortfolioListingTab;
