import { Star } from 'phosphor-react';
import * as React from 'react';
import { ExceptionStatus } from '../../api/index';
import { classNames, getExceptionStatusColour } from '../../common/utils';

type Props = {
  exceptionStatus: ExceptionStatus | string | undefined | null;
};

export default function ExceptionIcon({
  exceptionStatus,
}: Props): React.ReactElement {
  return (
    <span
      className={classNames(
        getExceptionStatusColour(exceptionStatus),
        'flex flex-shrink-0 w-5 h-5 rounded-full align-middle justify-center items-center'
      )}
    >
      <Star className={`h-3 w-3 text-white`} weight="fill" />
    </span>
  );
}
