import React, { useContext } from 'react';
import { ProgramPageContext } from '../../program/ProgramPageContext';
import { getLatestProjectUpdate } from '../../../common/utils';
import {
  EntityType,
  Group,
  Project,
  ProjectMember,
  ProjectRole,
} from '../../../api/index';
import { enumTranslates } from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import { PROJECT } from '../../../common/routes';
// import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import PermissionLink from '../../../common/PermissionLink';
import { PortfolioPageContext } from '../../portfolio/PortfolioPageContext';
import { CaretRight, Lock } from 'phosphor-react';
import Tag from '../../../common/Tag';

type Props = {
  children: Project[];
  showDescription: boolean;
};

function EntityChildList({
  children,
  showDescription,
}: Props): React.ReactElement {
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const { state: programPageState } = useContext(ProgramPageContext);

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {children === undefined || children?.length == 0 ? (
            <EmptyState
              type={`in-flight ${enumTranslates[EntityType.Project]}s`}
              secondaryText={`We aren't working on any ${
                enumTranslates[EntityType.Project]
              }s at this time.`}
            />
          ) : (
            <div className="overflow-hidden border border-gray-200 rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  {/* Header row */}
                  <tr>
                    <th
                      scope="col"
                      className="pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>

                    {/* Only show confidence if toggled */}
                    {/* {showPerformance ? (
                      <th
                        scope="col"
                        className="w-28 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {translate(
                          strings.PROJECT_DELIVERY_CONFIDENCE,
                          'Delivery Confidence'
                        )}
                      </th>
                    ) : null} */}

                    <th
                      scope="col"
                      className="pl-2 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Description
                    </th>

                    {/* <th
                      scope="col"
                      className="w-32 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {stages?.some(stage => stage == ProjectStage.Inflight)
                        ? translate(
                            strings.PROJECT_DELIVERY_DATE,
                            'Estimated Delivery Date'
                          )
                        : stages?.some(
                            stage => stage == ProjectStage.Inplanning
                          )
                        ? `Estimated Start Date`
                        : `Delivery Date`}
                    </th> */}

                    <th
                      scope="col"
                      className="px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {`Lifecycle${'\u00A0'}stage`}
                    </th>

                    {/* <th
                      scope="col"
                      className="w-24 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {enumTranslates[ProjectRole.ProjectOwner]}
                    </th> */}

                    <th scope="col" className="w-px relative px-2 py-3">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {/* Make a new row for each project */}
                  {children.map(project => {
                    const members = project.members?.items as ProjectMember[];
                    const businessOwner = members.filter(
                      member => member.role == ProjectRole.BusinessOwner
                    )[0];
                    const pm = members.filter(
                      member => member.role == ProjectRole.ProjectOwner
                    )[0];

                    const update = getLatestProjectUpdate(project);

                    return (
                      <tr
                        key={project.id}
                        className="text-sm text-gray-900 whitespace-normal"
                      >
                        {/* Name */}
                        <td className="pl-4 pr-2 py-2 text-sm">
                          <div className="items-center">
                            {portfolioPageState.selectedPortfolio && (
                              <PermissionLink
                                entity={project}
                                parentPortfolio={
                                  portfolioPageState.selectedPortfolio
                                }
                                to={`${PROJECT}/${project.id}`}
                                allowedLayout={
                                  <div className="font-medium hover:underline hover:text-primary-600">
                                    {project.name}
                                  </div>
                                }
                                blockedLayout={
                                  <div className="font-medium">
                                    {project.name}
                                  </div>
                                }
                              />
                            )}

                            {/* <div
                              className={`mt-1 ${
                                showDescription ? 'text-gray-600' : 'hidden'
                              }`}
                            >
                              {project.outcome && project.outcome.length > 0 ? (
                                project.outcome
                              ) : (
                                <p className="text-sm italic text-gray-400">
                                  No description provided
                                </p>
                              )}
                            </div> */}
                          </div>
                        </td>

                        {/* Description */}
                        <td className="hidden xl:table-cell px-2 py-2">
                          <div className="flex items-center">
                            <div className="">
                              {/* whitespace-pre-wrap */}
                              {project.outcome && project.outcome.length > 0 ? (
                                project.outcome
                              ) : (
                                <p className="text-sm italic text-gray-400">
                                  Not provided
                                </p>
                              )}
                            </div>
                          </div>
                        </td>

                        {/* Delivery date */}

                        {/* TODO: show multiple avatars if needed */}
                        {/* <td className="px-2 py-2">
                          <span className="flex items-center justify-center">
                            <Avatar
                              name={
                                businessOwner &&
                                businessOwner.user.firstName &&
                                businessOwner.user.lastName
                                  ? businessOwner.user.firstName +
                                    ' ' +
                                    businessOwner.user.lastName
                                  : '?'
                              }
                              size="32"
                              round="16px"
                              color="#304057"
                            />
                          </span>
                        </td> */}

                        {/* Lifecycle stage */}
                        <td className="px-2 py-2 flex-col justify-center align-middle">
                          <div className="flex justify-center">
                            {project.stage && (
                              <Tag
                                type="custom"
                                label={enumTranslates[project.stage]}
                                textColour=""
                                bgColour="bg-gray-200"
                              />
                            )}
                          </div>
                        </td>

                        <td className="table-cell text-center">
                          {portfolioPageState.selectedPortfolio && (
                            <PermissionLink
                              entity={project}
                              parentPortfolio={
                                portfolioPageState.selectedPortfolio
                              }
                              to={`${PROJECT}/${project.id}`}
                              allowedLayout={
                                <div className="flex justify-center mx-auto">
                                  <button
                                    type="button"
                                    className="p-1 mr-2 inline-flex border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                  >
                                    <CaretRight
                                      weight="bold"
                                      size={16}
                                      className="shrink-0"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </div>
                              }
                              blockedLayout={
                                <div className="flex justify-center mx-auto">
                                  <button
                                    // id={program.id}
                                    data-tip
                                    data-for={project.id}
                                    type="button"
                                    className="p-1 mr-2 inline-flex border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                  >
                                    <Lock
                                      weight="fill"
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </button>
                                  <ReactTooltip id={project.id} effect="solid">
                                    <div className="text-sm text-center">
                                      <p>
                                        You do not currently have access to this
                                        project.
                                      </p>
                                      <p className="pb-1">
                                        Contact{' '}
                                        {
                                          project.members?.items?.find(
                                            member =>
                                              member.group == Group.Owner
                                          )?.user.email
                                        }{' '}
                                        for access.
                                      </p>
                                    </div>
                                  </ReactTooltip>
                                </div>
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EntityChildList;
