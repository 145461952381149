import React, { useState } from 'react';
import { ulid } from 'ulid';
import {
  EntityType,
  PortfolioMeasureStatus,
  PortfolioUpdate,
  Program,
  ProgramUpdateTrend,
  StrategicObjective,
} from '../../../api/index';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import { PROGRAM } from '../../../common/routes';
import { PanelTab } from '../../../common/PanelTab';
import {
  capitaliseFirstLetter,
  getLastFourObjectiveUpdates,
  getLatestObjectiveUpdate,
  getLatestProgramUpdate,
  getLocalDate,
  getProjectMeasureStatusColour,
  sortProgramBenefits,
  sortPrograms,
} from '../../../common/utils';
import { getStatusDot } from '../../program/PerformanceTab/HelperFunctions';
import ExpandableSlideoverCard from './ExpandableSlideoverCard';
import Tag from '../../../common/Tag';
import { CheckCircle, XCircle } from 'phosphor-react';

type Props = {
  strategicObjective: StrategicObjective | undefined;
  portfolioUpdates: PortfolioUpdate[];
  programs: Program[];
  fixedHeight?: string;
};

export default function ObjectiveSlideoverLayout({
  strategicObjective,
  portfolioUpdates,
  programs,
  fixedHeight,
}: Props): React.ReactElement {
  //   Pre-process tabs into groups
  const tabNames = [
    'Status',
    `Contributing ${capitaliseFirstLetter(
      enumTranslates[EntityType.Program]
    )}s`,
  ] as const;
  type tabs = typeof tabNames[number];
  const [selectedTab, setSelectedTab] = useState<tabs>(tabNames[0]);

  // Get latest update
  const latest = getLatestObjectiveUpdate(
    portfolioUpdates,
    strategicObjective?.id as string
  );

  const todayText = 'Current performance';
  const tomorrowText = 'Outlook';
  const today = ['Not satisfied', 'Satisfied'];
  const tomorrow = ['On track', 'Off track'];

  const iconSatisfied = (
    <CheckCircle
      weight="fill"
      className={`text-green-500 inline-block rounded-full w-4 h-4 flex-shrink-0`}
    />
  );
  const iconUnsatisfied = (
    <XCircle
      weight="fill"
      className={`text-gray-600 inline-block rounded-full w-4 h-4 flex-shrink-0`}
    />
  );

  // Temporary solution to categorise objectives based on current performance
  // TODO: Add mechanism to check current performance vs targets
  const meetsKey = '[Y]';

  // Get the inputs for this objective
  const currentlyMeets = latest?.lastUpdate?.remarks?.slice(0, 3) == meetsKey;

  const outlookOnTrack =
    latest?.lastUpdate?.status === PortfolioMeasureStatus.OnTrack;

  const latestUpdate =
    strategicObjective &&
    getLatestObjectiveUpdate(portfolioUpdates, strategicObjective?.id);

  const statusView = (
    <div>
      {/* <DetailsPanelSectionDivider title={'Status'} /> */}
      {latestUpdate?.update ? (
        <div className="space-y-3 text-sm">
          <div>
            <div className="font-semibold mb-2">Recent reviews</div>
            <div className="relative flex flex-row-reverse justify-center gap-x-4 mt-4">
              {/* If the objective has updates, get the last four updates and show the status */}
              {strategicObjective &&
                getLastFourObjectiveUpdates(
                  portfolioUpdates,
                  strategicObjective?.id
                )?.map((item, index) => {
                  const updateDate = item?.updateDate;

                  return updateDate ? (
                    <div className="w-1/4 flex flex-col align-middle justify-start z-[1]">
                      <span className="mx-auto mb-1">
                        {/* Just show a dot */}
                        <div className="w-4 h-4 shrink-0 bg-gray-400 rounded-full mt-0.5 border-2 border-white"></div>

                        {/* {getStatusDot(
                          displayObjective?.status as string,
                          updateDate,
                          ulid()
                        )} */}
                      </span>
                      <div className="text-sm text-center">
                        {updateDate
                          ? getLocalDate(updateDate)
                          : 'No other program reviews'}
                        {index === 0 ? (
                          <div className="flex flex-col items-center">
                            <div className="text-xs text-gray-400">Latest</div>
                            <div className="w-px h-4 bg-gray-200 mt-2"></div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null;
                })}

              <div className="absolute top-2 w-full h-1 bg-gray-300 rounded-full"></div>
            </div>

            <div className="w-full border border-gray-200 rounded-md px-4 py-3 mb-4">
              <div className="flex items-center justify-center gap-x-4">
                <div className="flex flex-col items-center">
                  <span className="text-xs text-gray-400 mb-1">
                    {todayText}
                  </span>
                  <Tag
                    type="custom"
                    label={currentlyMeets ? today[1] : today[0]}
                    textColour="text-gray-900"
                    bgColour=""
                    border={true}
                    decoration={
                      <span className="block mt-0 ml-1">
                        {currentlyMeets ? iconSatisfied : iconUnsatisfied}
                      </span>
                    }
                  />
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-xs text-gray-400 mb-1">
                    {tomorrowText}
                  </span>
                  <Tag
                    type="custom"
                    label={outlookOnTrack ? tomorrow[0] : tomorrow[1]}
                    textColour="text-gray-900"
                    bgColour=""
                    border={true}
                    decoration={
                      <span className="block mt-0 ml-1">
                        <span
                          className={`${
                            outlookOnTrack
                              ? getProjectMeasureStatusColour(
                                  PortfolioMeasureStatus.OnTrack
                                )
                              : getProjectMeasureStatusColour(
                                  PortfolioMeasureStatus.HelpNeeded
                                )
                          } inline-block rounded-full w-3 h-3 flex-shrink-0`}
                        />
                      </span>
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="font-semibold mb-2">Latest comments</div>
            {latestUpdate?.lastUpdate?.remarks ? (
              <p>
                {/* TODO: Remove the slice after updating data model */}
                {latestUpdate?.lastUpdate?.remarks[0] === '['
                  ? latestUpdate?.lastUpdate?.remarks?.slice(3).trim()
                  : latestUpdate?.lastUpdate?.remarks}
              </p>
            ) : (
              <p className="italic text-gray-500">Nothing to report</p>
            )}
          </div>
        </div>
      ) : (
        <div className="border-2 border-gray-300 border-dashed rounded-md px-6 py-6">
          <EmptyState
            type="strategic objective reviews"
            secondaryText={`We'll show how this ${translate(
              strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
              'Strategic Objective'
            ).toLowerCase()} is tracking once a review has been added.`}
          />
        </div>
      )}
    </div>
  );

  const programsView = (
    <div>
      {programs?.filter(program =>
        program?.measures?.some(
          measure => measure.strategicObjectiveId === strategicObjective?.id
        )
      ).length == 0 ? (
        <div className="border-2 border-gray-300 border-dashed rounded-md px-6 py-6">
          <EmptyState
            type={`${enumTranslates[EntityType.Program]}s`}
            secondaryText={`No programs are contributing to this ${translate(
              strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
              'Strategic Objective'
            ).toLowerCase()}.`}
          />
        </div>
      ) : (
        <div className="space-y-3">
          {sortPrograms(
            programs?.filter(program =>
              program?.measures?.some(
                measure =>
                  measure.strategicObjectiveId === strategicObjective?.id
              )
            ) || []
          )?.map((program, idx) => {
            const latestUpdate = getLatestProgramUpdate(program);

            return (
              <ExpandableSlideoverCard
                key={idx}
                statusIcon={
                  <div className="-mt-0.5">
                    <ConfidenceBadge
                      rating={latestUpdate?.trend}
                      reviewDate={latestUpdate?.updateDate}
                      size="shortened"
                    />
                  </div>
                }
                title={<div className="mt-0.5">{program.name}</div>}
                isExpandable={true}
                expandDefault={
                  latestUpdate?.trend === ProgramUpdateTrend.Trenddown ||
                  latestUpdate?.trend === ProgramUpdateTrend.Trendflat
                }
                content={
                  <div className="bg-white border-t border-gray-200 divide-y divide-gray-200">
                    {(program.measures?.filter(
                      item =>
                        item.strategicObjectiveId === strategicObjective?.id
                    )?.length as number) > 0 ? (
                      sortProgramBenefits(
                        program.measures?.filter(
                          item =>
                            item.strategicObjectiveId === strategicObjective?.id
                        ) || [],
                        latestUpdate
                      )?.map((programMeasure, idx) => {
                        const status = latestUpdate?.measures?.find(
                          item => item.measureId === programMeasure.id
                        )?.status;

                        return (
                          <div key={idx} className="flex px-3 py-2">
                            <div className="w-6 mr-3 flex justify-center align-top shrink-0">
                              <span className="mt-0.5">
                                {getStatusDot(
                                  status as string,
                                  latestUpdate?.updateDate,
                                  ulid()
                                )}
                              </span>
                            </div>
                            {programMeasure.name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex px-3 py-2 text-gray-500 italic">
                        <div className="w-6 mr-3 flex justify-center items-center"></div>
                        No relevant benefits
                      </div>
                    )}
                  </div>
                }
                link={`${PROGRAM}/${program.id}#performance`}
              />
            );
          })}
        </div>
      )}
    </div>
  );

  const layout = (
    <div className={`${fixedHeight ? '' : 'pb-12'}`}>
      {/* Tab selector */}
      <div className="w-min mx-auto">
        <div className="flex items-center justify-center rounded-md overflow-hidden mb-6 border border-gray-200 divide-x divide-gray-200">
          {tabNames.map((tab, index) => (
            <PanelTab
              selected={tabNames[index] === selectedTab}
              title={tab}
              onClick={() => setSelectedTab(tabNames[index])}
            />
            //   <button onClick={() => setTab(tabNames[index])}>{tab}</button>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className={`${fixedHeight ? `${fixedHeight} overflow-y-auto` : ''}`}>
        {selectedTab === tabNames[0] ? statusView : programsView}
      </div>
    </div>
  );

  return layout;
}
