import { CaretRight } from 'phosphor-react';
import React, { MouseEvent } from 'react';
import ReactTooltip from 'react-tooltip';
import { ulid } from 'ulid';

export type TableSchemaColumn = {
  // The title of the column
  label: string;

  // The key inside the row object that is submitted to the back end
  key: string;

  // Set whether text is center aligned
  centered?: boolean;

  // Set width manually
  widthClass?: string;
};

export type TableSchema = {
  columns: Array<TableSchemaColumn>;
};

export type StyledTableRow = {
  id: string;
  //   [key: string]: string | boolean;
  cells: Array<TableCell>;
  canExpand: boolean;
  invisibleExpand?: boolean;
  expanded?: boolean;
  setExpand?: React.Dispatch<React.SetStateAction<boolean>>;
  childRows?: StyledTableRow[] | [];
  childLevel?: number;
};

export type TableCell = {
  // [key:string]:string,
  content: React.ReactElement;
};

type Props = {
  schema: TableSchema;
  rowData: Array<StyledTableRow> | null;
  disableHoverHighlight?: boolean;
  clickedRowId?: React.Dispatch<React.SetStateAction<string>>;
  noRowsMessage?: string;
};

function StyledTable({
  schema,
  rowData,
  disableHoverHighlight,
  clickedRowId,
  noRowsMessage,
}: Props): React.ReactElement {
  const { columns } = schema;

  const iconCaretRight = (
    <CaretRight
      weight="bold"
      size={16}
      className="shrink-0"
      aria-hidden="true"
      key={ulid()}
    />
  );

  function handleRowClick(e: MouseEvent, rowId: string) {
    // console.log(`Placeholder: Clicked row ${rowId}`);
    if (clickedRowId) {
      clickedRowId(rowId);
    }
    return;
  }

  function handleRowExpand(
    setExpand: React.Dispatch<React.SetStateAction<boolean>>,
    expanded: boolean
  ) {
    setExpand(expanded);
  }

  function TableRow(row: StyledTableRow, index?: number) {
    const output = (
      <React.Fragment key={index}>
        <tr
          key={`id:${row.id}`}
          className={`${
            disableHoverHighlight ? '' : 'hover:bg-gray-100 cursor-pointer'
          }`}
          onClick={e => handleRowClick(e, row.id)}
        >
          {row.cells.map((cell, cellIndex) => {
            // console.log(`id-${index}-${row.id}_col-key-${cellIndex}`);
            return (
              <td
                key={`id-${row.id}_col-key-${cellIndex}`}
                className={`${cellIndex === 0 ? 'pl-4' : 'pl-2'} ${
                  cellIndex === columns.length - 1 ? 'pr-4' : 'pr-2'
                } ${
                  columns[cellIndex].widthClass
                    ? columns[cellIndex].widthClass
                    : ''
                } py-3 align-middle`}
              >
                <div
                  key={cellIndex}
                  className={`${
                    row.childLevel &&
                    cellIndex === 0 &&
                    `pl-${4 * row.childLevel}`
                  }`}
                >
                  <div key={cellIndex} className="flex gap-x-1.5">
                    <button
                      type="button"
                      className={`rounded-full w-6 h-6 shrink-0 p-1 flex items-center justify-center ${
                        row.expanded ? 'rotate-90' : ''
                      } ${
                        cellIndex === 0
                          ? row.canExpand
                            ? 'block'
                            : row.invisibleExpand
                            ? 'invisible'
                            : 'hidden'
                          : 'hidden'
                      } ${
                        row.childRows?.length === 0 ? '' : 'hover:bg-gray-200'
                      } disabled:text-gray-400`}
                      onClick={() =>
                        row.setExpand &&
                        handleRowExpand(row.setExpand, !row.expanded)
                      }
                      disabled={row.childRows?.length === 0}
                      data-tip
                      data-for={`${row.id}-expander`}
                    >
                      {iconCaretRight}
                    </button>
                    {cell.content}

                    {/* Show tooltip if disabled */}
                    <ReactTooltip
                      id={`${row.id}-expander`}
                      // place="right"
                      // type="info"
                      // key={ulid()}
                      effect="solid"
                      // delayShow={tooltipDelay}
                    >
                      <div className="">
                        {row.childRows?.length === 0
                          ? `There's nothing more to show`
                          : row.expanded
                          ? `Click to collapse`
                          : `Click to expand`}
                      </div>
                    </ReactTooltip>
                  </div>

                  {/* {cellIndex === 0 && row.canExpand ? (
                  
                ) : (
                  cell.content
                )} */}
                </div>
              </td>
            );
          })}
        </tr>

        {/* Then also show children */}
        {row.expanded &&
          row.childRows &&
          // row.childRows.length > 0 &&
          row.childRows.map((childRow: StyledTableRow, childRowIndex: number) =>
            TableRow(childRow, childRowIndex)
          )}
      </React.Fragment>
    );

    return output;
  }

  const layout = (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="overflow-hidden border border-gray-200 rounded-md">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                {/* Header row */}
                <tr key="header-row-mpt">
                  {columns.map((column: TableSchemaColumn, index: number) => {
                    return (
                      <th
                        scope="col"
                        className={`${index === 0 ? 'pl-4' : 'pl-2'} ${
                          index === columns.length - 1 ? 'pr-4' : 'pr-2'
                        } ${column.widthClass ? column.widthClass : ''} py-3 ${
                          column.centered ? 'text-center' : 'text-left'
                        } text-xs font-medium text-gray-500 uppercase tracking-wider`}
                        key={column.key}
                      >
                        {column.label}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              <tbody className="bg-white divide-y divide-gray-200">
                {rowData && rowData.length > 0 ? (
                  rowData?.map((row: StyledTableRow, index: number) => {
                    // Create the current row item
                    // console.log(index);
                    return TableRow(row, index);
                  })
                ) : (
                  // Empty state to show when there aren't any rows
                  <tr
                    className="cursor-pointer hover:bg-gray-100"
                    // onClick={e => handleRowClick(e, row.id)}
                  >
                    <td
                      colSpan={columns.length}
                      className={`px-4 py-3 align-middle text-sm italic text-gray-400`}
                    >
                      {noRowsMessage ? noRowsMessage : `No data`}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  return layout;
}

export default StyledTable;
