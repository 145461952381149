import React, { useState } from 'react';

import {
  /* GetWorkspaceUsersQuery,
  GetWorkspaceUsersQueryVariables, */
  EntityType,
  User,
  InviteGroup,
} from '../api/index';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

type Props = {
  entityId: string;
  entityType: EntityType;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AddMember({ entityId, entityType }: Props): React.ReactElement {
  const [query, setQuery] = useState<string>('');
  const [users] = useState<User[]>([]);

  const [group, setGroup] = useState<InviteGroup>();
  // const [role, setRole] = useState<Role>();

  const [email, setEmail] = useState<string>('');

  const _search = async () => {
    /* try {
      const variable: GetWorkspaceUsersQueryVariables = {
        entityId,
        entityType,
      }

      const resp: GraphQLResult<GetWorkspaceUsersQuery> = (await API.graphql(
        graphqlOperation(getWorkspaceUsers, variable),
      )) as { data: GetWorkspaceUsersQuery }

      if (resp.data) {
        const respGetWorkspaceUsers: GetWorkspaceUsersQuery = resp.data

        if (respGetWorkspaceUsers.getWorkspaceUsers) {
          let workspaceUsers = respGetWorkspaceUsers.getWorkspaceUsers
          if (query) {
            workspaceUsers = respGetWorkspaceUsers.getWorkspaceUsers.filter(
              (user) =>
                new RegExp(query, 'gi').test(user.firstName) ||
                new RegExp(query, 'gi').test(user.lastName) ||
                new RegExp(query, 'gi').test(user.email),
            )

            console.log(workspaceUsers)
          }

          setUsers(workspaceUsers)

          if (workspaceUsers.length === 0 && isEmail(query)) {
            setEmail(query)
          }
        }
      }
    } catch (err) {
      console.error(err)
    } */
  };

  // const invite = async () => {
  //   if (email && group && role) {
  //     try {
  //       await inviteAPI(email, entityId, entityType, group, role);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  return (
    <>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Add Members
          </h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Search User</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  placeholder="Email / Name"
                  onChange={e => setQuery(e.target.value)}
                  value={query}
                  className="shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                />
                <button
                  onClick={() => {
                    _search();
                  }}
                  className="m-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Search
                </button>
              </dd>
            </div>
          </dl>
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          First Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Last Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Email
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Select</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {users.map((user, idx) => (
                        <tr key={idx}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {user.firstName}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {user.lastName}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {user.email}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <button
                              className="text-primary-600 hover:text-primary-900"
                              onClick={() => {
                                setEmail(user.email as string);
                              }}
                            >
                              Select
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Invite User</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span>{email}</span>
                <select
                  onChange={e => setGroup(e.target.value as InviteGroup)}
                  value={group}
                  className="border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                >
                  <option value="-1">Select Group</option>
                  {Object.values(InviteGroup).map((group, idx) => (
                    <option value={group} key={idx}>
                      {group}
                    </option>
                  ))}
                </select>
                <select
                  // onChange={e => setRole(e.target.value as Role)}
                  // value={role}
                  className="border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                >
                  <option value="-1">Select Role</option>
                  {/* {Object.values(Role).map((role, idx) => (
                    <option value={role} key={idx}>
                      {role}
                    </option>
                  ))} */}
                </select>
                <button
                  onClick={() => {
                    // invite();
                  }}
                  className="m-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Invite
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {/*  */}
    </>
  );
}

export default AddMember;
