import SignIn from '../user/SignIn';
import PortfolioHomePage from '../components/portfolio/PortfolioHomePage';
import ProgramHomepage from '../components/program/ProgramHomepage';
import ProjectHomepage from '../components/project/ProjectHomepage';
import InvitationList from '../components/invitations/InvitationsList';
import TempWorkspace from '../components/temp/TempWorkspace';
import TempPortfolio from '../components/temp/TempPortfolio';
import SignUp from '../user/SignUp';
import SignUpConfirm from '../user/SignUpConfirm';
import ResetPassword from '../user/ResetPassword';
import ResetPasswordConfirm from '../user/ResetPasswordConfirm';
import PageNotFound from '../components/errorPages/PageNotFound';
import Forbidden from '../components/errorPages/Forbidden';
import Home from '../components/dashboard/Dashboard';
import WorkspaceDirectory from '../components/dashboard/WorkspaceDirectory';
import SupportCentre from '../components/support/SupportCentre';
import ProfilePage from '../components/profile/ProfilePage';
import NotificationList from '../components/notifications/NotificationList';
import Report from '../components/report/ReportConfig';
import PrioritisationModule from '../components/prioritisation/PrioritisationModule';
import ReportPage from '../components/report/ReportPage';
import WorkspacePage from '../components/workspace/WorkspacePage';
import EntityHomePage from '../JLcomponents/entity/EntityHomePage';

export const HOME = '/';

export const SIGNIN = '/signin';
export const SIGNUP = '/signup';
export const SIGNUPCONFIRM = '/signupconfirm';
export const RESETPW = '/resetpassword';
export const RESETCONFIRM = '/passwordconfirm';

export const WORKSPACE = '/workspace';
export const ENTITY = '/entity';

export const PORTFOLIO = '/portfolio';
export const PROGRAM = '/program';
export const PROJECT = '/project';
export const PORTFOLIO_LIST = '/portfolios';
export const PROGRAM_LIST = '/programs';
export const PROJECT_LIST = '/projects';

export const REPORTING = '/reporting';
export const REPORT = '/report';

export const HELP = '/help';
export const PROFILE = '/profile';

export const INVITATION = '/invitations';
export const NOTIFICATION = '/notifications';
export const PRIORITISATION = '/prioritisation';

export const TEMP_WORKSPACE = '/temp_workspace';
export const TEMP_PORTFOLIO = '/temp_portfolio';
export const TEMP_PROGRAM = '/temp_program';
export const TEMP_PROJECT = '/temp_project';

export const NOT_FOUND = '/pagenotfound';

export const privateRoutes = [
  {
    path: HOME,
    component: Home,
    exact: true,
  },
  {
    path: PORTFOLIO,
    component: PortfolioHomePage,
    exact: true,
  },
  {
    path: PORTFOLIO_LIST,
    component: WorkspaceDirectory,
    exact: true,
  },
  {
    path: PROGRAM_LIST,
    component: WorkspaceDirectory,
    exact: true,
  },
  {
    path: PROJECT_LIST,
    component: WorkspaceDirectory,
    exact: true,
  },
  {
    path: `${WORKSPACE}/:workspaceId`,
    component: WorkspacePage,
    exact: true,
  },
  {
    path: `${ENTITY}/:entityId`,
    component: EntityHomePage,
    exact: false,
  },
  {
    path: `${PORTFOLIO}/:portfolioId`,
    component: PortfolioHomePage,
    exact: false,
  },
  {
    path: PROGRAM,
    component: ProgramHomepage,
    exact: true,
  },
  {
    path: `${PROGRAM}/:programId`,
    component: ProgramHomepage,
    exact: false,
  },
  {
    path: PROJECT,
    component: ProjectHomepage,
    exact: true,
  },
  {
    path: `${PROJECT}/:projectId`,
    component: ProjectHomepage,
    exact: false,
  },
  // {
  //   path: REPORTING,
  //   component: Report,
  //   exact: true,
  // },
  {
    path: HELP,
    component: SupportCentre,
    exact: true,
  },
  {
    path: PROFILE,
    component: ProfilePage,
    exact: true,
  },
  {
    path: INVITATION,
    component: InvitationList,
    exact: true,
  },
  {
    path: `${TEMP_WORKSPACE}/:workspaceId`,
    component: TempWorkspace,
    exact: false,
  },
  {
    path: `${TEMP_PORTFOLIO}/:portfolioId`,
    component: TempPortfolio,
    exact: false,
  },
  {
    path: `/:page/:id/accessdenied`,
    component: Forbidden,
    exact: false,
  },
  {
    path: INVITATION,
    component: InvitationList,
    exact: true,
  },
  {
    path: NOTIFICATION,
    component: NotificationList,
    exact: true,
  },
  {
    path: '*',
    component: PageNotFound,
    exact: false,
  },
  {
    path: NOT_FOUND,
    component: PageNotFound,
    exact: true,
  },
];

export const betaRoutes = [
  {
    path: HOME,
    component: Home,
    exact: true,
  },
  {
    path: PORTFOLIO,
    component: PortfolioHomePage,
    exact: true,
  },
  {
    path: `${WORKSPACE}/:workspaceId`,
    component: WorkspacePage,
    exact: true,
  },
  {
    path: `${ENTITY}/:entityId`,
    component: EntityHomePage,
    exact: false,
  },
  {
    path: PORTFOLIO_LIST,
    component: WorkspaceDirectory,
    exact: true,
  },
  {
    path: PROGRAM_LIST,
    component: WorkspaceDirectory,
    exact: true,
  },
  {
    path: PROJECT_LIST,
    component: WorkspaceDirectory,
    exact: true,
  },
  {
    path: `${PORTFOLIO}/:portfolioId`,
    component: PortfolioHomePage,
    exact: false,
  },
  {
    path: PROGRAM,
    component: ProgramHomepage,
    exact: true,
  },
  {
    path: `${PROGRAM}/:programId`,
    component: ProgramHomepage,
    exact: false,
  },
  {
    path: PROJECT,
    component: ProjectHomepage,
    exact: true,
  },
  {
    path: `${PROJECT}/:projectId`,
    component: ProjectHomepage,
    exact: false,
  },
  {
    path: REPORTING,
    component: Report,
    exact: true,
  },
  {
    path: HELP,
    component: SupportCentre,
    exact: true,
  },
  {
    path: PROFILE,
    component: ProfilePage,
    exact: true,
  },
  {
    path: INVITATION,
    component: InvitationList,
    exact: true,
  },
  {
    path: `${TEMP_WORKSPACE}/:workspaceId`,
    component: TempWorkspace,
    exact: false,
  },
  {
    path: `${TEMP_PORTFOLIO}/:portfolioId`,
    component: TempPortfolio,
    exact: false,
  },
  {
    path: `/:page/:id/accessdenied`,
    component: Forbidden,
    exact: false,
  },
  {
    path: INVITATION,
    component: InvitationList,
    exact: true,
  },
  {
    path: NOTIFICATION,
    component: NotificationList,
    exact: true,
  },
  {
    path: PRIORITISATION,
    component: PrioritisationModule,
    exact: true,
  },
  {
    path: '*',
    component: PageNotFound,
    exact: false,
  },
  {
    path: NOT_FOUND,
    component: PageNotFound,
    exact: true,
  },
];

export const publicRoutes = [
  {
    path: SIGNIN,
    component: SignIn,
    exact: true,
  },
  {
    path: SIGNUP,
    component: SignUp,
    exact: true,
  },
  {
    path: SIGNUPCONFIRM,
    component: SignUpConfirm,
    exact: true,
  },
  {
    path: RESETPW,
    component: ResetPassword,
    exact: true,
  },
  {
    path: `${REPORT}/:reportId`,
    component: ReportPage,
    exact: true,
  },
  {
    path: '/passwordconfirm/:email',
    component: ResetPasswordConfirm,
    exact: true,
  },
  {
    path: '/signupconfirm/:email',
    component: SignUpConfirm,
    exact: false,
  },
];
