import { useCallback, useMemo } from 'react';
import NonEmptyValueValidationRuleStrategy from './strategies/NonEmptyValueValidationRuleStrategy';
import RegexValidationRuleStrategy from './strategies/RegexValidationRuleStrategy';
import IsEmailValidationRule from './strategies/IsEmailValidationRule';
import IsNumericValidationRule from './strategies/IsNumericValidationRule';

import type {
  ValidateResult,
  Validation,
  ValidationRule,
} from './Validation.types';

export type ValidationResult = {
  validate: (value: string) => ValidateResult;
};

export default function useValidation(
  validation: Validation | null = null
): ValidationResult {
  const resolve = useCallback((rule: ValidationRule) => {
    switch (rule.type) {
      case 'NonEmptyValueRule':
        return NonEmptyValueValidationRuleStrategy;
      case 'RegexRule':
        return RegexValidationRuleStrategy;
      case 'IsUniqueRule':
        return RegexValidationRuleStrategy;
      case 'IsEmailRule':
        return IsEmailValidationRule;
      case 'IsNumericRule':
        return IsNumericValidationRule;
    }
  }, []);

  return useMemo(() => {
    if (validation == null) {
      return {
        validate: () => {
          return {
            isValid: true,
            messages: [],
          };
        },
      };
    }

    return {
      validate: (value: string): ValidateResult => {
        return {
          isValid: validation.rules.every((rule: ValidationRule) => {
            return resolve(rule).validate(rule, value).isValid;
          }),
          messages: validation.rules.reduce(
            (messages: Array<string>, rule: ValidationRule) => {
              const strategy = resolve(rule).validate(rule, value);
              if (!strategy.isValid) {
                messages = messages.concat(strategy.messages);
              }
              return messages;
            },
            []
          ),
        };
      },
    };
  }, [resolve, validation]);
}
