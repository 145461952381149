import React from 'react';
import DirectoryList from './DirectoryList';

function WorkspaceDirectory(): React.ReactElement {
  //const { dispatch } = useContext(WorkspacePageContext);
  // const { data, loading, error } = useGetMyWorkspaceQuery();
  // useEffect(() => {
  //   if (data?.getMyWorkspace) {
  //     dispatch({
  //       type: 'SET_SELECTED_WORKSPACE',
  //       workspace: data?.getMyWorkspace,
  //     });
  //   }
  // }, [data, dispatch]);

  // if (loading) {
  //   return <span>Loading</span>;
  // }

  // if (error) {
  //   return <span>{error?.message}</span>;
  // }

  return (
    <div className="flex-col">
      <DirectoryList />
    </div>
  );
}

export default WorkspaceDirectory;
