import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { EnvelopeSimple } from 'phosphor-react';

import {
  EntityType,
  IInvitation,
  useGetMyInvitationsLazyQuery,
} from '../api/index';
import { useNavigate } from 'react-router-dom';
import InvitationItem from '../components/invitations/InvitationItem';
import * as ROUTES from './routes';
import EmptyState from './layout/EmptyState';
import { enumTranslates } from './i18n/translate';

type PopoverCloseType = {
  close: () => void;
};

function getNotificationIcon(invites: Array<IInvitation> | undefined) {
  const newInviteIcon = (
    <EnvelopeSimple
      className="h-6 w-6 shrink-0 text-red-500 group-hover:text-red-600"
      weight="duotone"
      aria-hidden="true"
    />
  );
  const noInviteIcon = (
    <EnvelopeSimple
      className="h-6 w-6 shrink-0"
      weight="duotone"
      aria-hidden="true"
    />
  );

  let icon = null;

  if (invites && invites.find(i => i.status === 'notyet')) {
    icon = (
      <span className="group">
        {newInviteIcon}
        <span>
          <span className="animate-ping absolute h-2 w-2 rounded-full bg-red-400 opacity-75 bottom-1.5 left-4"></span>
          <span className="absolute rounded-full h-2 w-2 bg-red-500 bottom-1.5 left-4"></span>
        </span>
      </span>
    );
  } else {
    icon = noInviteIcon;
  }

  return icon;
}

function InviteNotifications(): React.ReactElement {
  const navigate = useNavigate();

  const pageSize = 5;

  const [getMyInvitationsQuery, { data, loading, error }] =
    useGetMyInvitationsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        limit: pageSize,
      },
    });

  useEffect(() => {
    getMyInvitationsQuery();
  }, [getMyInvitationsQuery]);

  if (loading) {
    return <React.Fragment>{/* Don't show anything yet */}</React.Fragment>;
  }

  if (error) {
    return <span>Error: `${error.message}`</span>;
  }

  return (
    <Popover className="relative">
      <React.Fragment>
        <Popover.Button
          type="button"
          className="bg-white m-2 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <span className="sr-only">View invites</span>
          <span>{getNotificationIcon(data?.getMyInvitations?.items)}</span>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 right-0 transform translate-x-8 mt-4 w-screen max-w-sm max-h-screen overflow-auto rounded shadow-md">
            <div className="rounded-lg overflow-hidden shadow-md ring-1 ring-primary-600 ring-opacity-5 border border-gray-200">
              <div className="relative divide-y divide-gray-200 bg-white">
                {data?.getMyInvitations?.items &&
                data?.getMyInvitations?.items.find(
                  i => i.status === 'notyet'
                ) ? (
                  data?.getMyInvitations?.items
                    ?.filter(invitation => invitation.status === 'notyet')
                    .map(invitation => (
                      <InvitationItem
                        invitation={invitation}
                        key={`${invitation.entityId}#${invitation.inviteeEmail}`}
                        refresh={() => {
                          getMyInvitationsQuery();
                        }}
                      />
                    ))
                ) : (
                  <div className="text-sm px-6 py-4">
                    <EmptyState
                      type="invites"
                      secondaryText={`You'll get notified here when someone adds you to a ${
                        enumTranslates[EntityType.Project]
                      }, ${enumTranslates[EntityType.Program]} or ${
                        enumTranslates[EntityType.Portfolio]
                      }.`}
                    />
                  </div>
                )}
              </div>
              <div className="px-2 py-1 bg-gray-100 border-t border-gray-200">
                <div className="text-sm">
                  <Popover.Panel>
                    {(popoverClose: PopoverCloseType) => (
                      <div className="flex flex-row justify-center">
                        <button
                          onClick={() => {
                            navigate(ROUTES.INVITATION);
                            popoverClose.close();
                          }}
                          className="font-medium rounded px-4 py-2 text-primary-600 hover:text-primary-700 hover:bg-gray-200 transition ease-in-out duration-150 whitespace-nowrap"
                        >
                          See all invites
                          {/* <span aria-hidden="true">&rarr;</span> */}
                        </button>
                      </div>
                    )}
                  </Popover.Panel>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </React.Fragment>
    </Popover>
  );
}

export default InviteNotifications;
