import { EscalateIcon } from '../../basic/ButtonIcons';

type Props = {
  entityId: string;
  hasEscalations: boolean;
};

function EscalationsTableCell({ entityId, hasEscalations }: Props) {
  const icon = <EscalateIcon />;

  return (
    <div className={`flex justify-center mt-0.5`}>{hasEscalations && icon}</div>
  );
}

export default EscalationsTableCell;
