import React, { useState, useEffect, useMemo } from 'react';
import {
  capitaliseFirstLetter,
  getLocalDate,
  getRelativeTime,
  useWindowDimensions,
} from '../../../common/utils';
import moment from 'moment';
import { getWaypointMarkerStyle, Waypoint } from './DummyWaypoints';
import { CaretLeft, CaretRight } from 'phosphor-react';
import ReactTooltip from 'react-tooltip';
import { globalTooltipDelay } from '../../../common/constants';
import Tag from '../../../common/Tag';

interface Props {
  waypoints: Waypoint[];
  selectedWaypoint: Waypoint | null;
  setSelectedWaypoint: React.Dispatch<React.SetStateAction<Waypoint | null>>;
}

const WaypointSelector: React.FC<Props> = ({
  waypoints,
  selectedWaypoint,
  setSelectedWaypoint,
}) => {
  // const [selectedWaypoint, setSelectedWaypoint] = useState<Waypoint | null>(
  //   null
  // );
  const [currentPage, setCurrentPage] = useState(1);
  const { height, width } = useWindowDimensions();

  const itemsPerPage = 8; // TODO: Make this more robust / explicit and adapt live
  const totalPages = Math.ceil(waypoints.length / itemsPerPage);

  const iconPrevious = <CaretLeft className="" size={20} weight="bold" />;
  const iconNext = <CaretRight className="" size={20} weight="bold" />;

  useEffect(() => {
    if (waypoints.length > 0) {
      const closestWaypoints = waypoints.filter(waypoint =>
        moment(waypoint.date).isAfter(moment(), 'day')
      );
      const closestWaypoint =
        closestWaypoints.reverse().pop() || waypoints[waypoints.length - 1];
      setSelectedWaypoint(closestWaypoint);
      const currentPage = Math.ceil(
        (waypoints.indexOf(closestWaypoint) + 1) / itemsPerPage
      );
      setCurrentPage(currentPage);
    }
  }, [waypoints]);

  const handleClick = (waypoint: Waypoint) => {
    setSelectedWaypoint(waypoint);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const paginatedWaypoints = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return waypoints.slice(startIndex, endIndex);
  }, [currentPage, waypoints]);

  const renderPageDots = () => {
    const dots = Array.from({ length: totalPages }, (_, i) => i + 1);
    return (
      <div className="flex space-x-1">
        {dots.map((dot, index) => (
          <React.Fragment>
            <div
              key={dot}
              className={`w-${
                index === currentPage - 1 ? '4' : '2'
              } h-2 mx-0.5 rounded-full ${
                dot === currentPage ? 'bg-primary-500' : 'bg-gray-300'
              }`}
              onClick={() => setCurrentPage(index + 1)}
              data-tip
              data-for={`page-${index}`}
            />
            <ReactTooltip
              id={`page-${index}`}
              place="top"
              effect="solid"
              delayShow={globalTooltipDelay}
            >{`Page ${index + 1} of ${totalPages}`}</ReactTooltip>
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col bg-gray-50 shadow-inner rounded-lg px-3 py-2 h-full">
      {/* Page selector */}
      <div className="flex justify-center">
        <div className="my-2 flex items-center gap-x-3">{renderPageDots()}</div>
      </div>

      {/* List of Waypoints */}
      <div className="flex justify-between align-top">
        {/* Previous button */}
        <button
          className="bg-transparent hover:bg-gray-200 p-2 rounded-full text-sm h-min mt-4 disabled:invisible"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {iconPrevious}
        </button>

        <div className="flex justify-center align-top space-x-4 py-1">
          {paginatedWaypoints.map((waypoint, index) => (
            <button
              type="button"
              key={index}
              onClick={() => handleClick(waypoint)}
              disabled={waypoint.type === 'today'}
              className={`px-1 py-3.5 w-28 h-36 rounded-lg flex flex-col items-center ${
                waypoint === selectedWaypoint
                  ? 'bg-white border border-gray-200 shadow'
                  : `border border-transparent ${
                      waypoint.type === 'today'
                        ? ''
                        : 'cursor-pointer hover:bg-gray-100'
                    }`
              }`}
            >
              <div
                className={`${
                  waypoint.date < new Date() && waypoint.type != 'today'
                    ? 'text-gray-400'
                    : ''
                }`}
              >
                <p
                  className={`text-sm font-semibold text-center ${
                    waypoint.type === 'today' ? 'invisible' : ''
                  }`}
                >
                  {getLocalDate(waypoint.date)}
                </p>
                <p className="text-xs text-gray-500 text-center mt-0.5 hidden">
                  {capitaliseFirstLetter(getRelativeTime(waypoint.date))}
                </p>

                {/* Timeline */}
                <div className="relative">
                  {/* Line before */}
                  <div
                    className={`${
                      waypoints[0].date === waypoint.date ||
                      waypoint.date === selectedWaypoint?.date
                        ? 'hidden'
                        : ''
                    } absolute top-1/2 right-1/2 w-14 h-px border-t-2 border-gray-300 -translate-x-4`}
                  />

                  {/* Marker */}
                  {waypoint.type === 'today' ? (
                    <div className="shrink-0 rounded-full mx-auto my-4 flex items-center justify-center">
                      <div className="my-0.5 z-[1]">
                        <Tag
                          label="Today"
                          type="custom"
                          bgColour="bg-gray-200"
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`${
                        getWaypointMarkerStyle(waypoint.type).bgColour
                      } w-8 h-8 shrink-0 rounded-full mx-auto mt-3 mb-3 text-white flex items-center justify-center text-sm text-center ${
                        waypoint.date < new Date() ? 'opacity-50' : ''
                      }`}
                    >
                      {getWaypointMarkerStyle(waypoint.type).icon}
                    </div>
                  )}

                  {/* Line after */}
                  <div
                    className={`${
                      waypoints[waypoints.length - 1].date === waypoint.date ||
                      waypoint.date === selectedWaypoint?.date
                        ? 'hidden'
                        : ''
                    } absolute top-1/2 left-1/2 w-14 h-px border-t-2 border-gray-300 translate-x-4`}
                  />
                </div>

                <p
                  className={`text-sm text-gray-900 text-center ${
                    waypoint.date < new Date() && waypoint.type != 'today'
                      ? 'text-gray-400'
                      : ''
                  } ${waypoint.type === 'today' ? 'invisible' : ''}`}
                >
                  {capitaliseFirstLetter(waypoint.type)}
                </p>
              </div>
            </button>
          ))}
        </div>

        {/* Next button */}
        <button
          className="bg-transparent hover:bg-gray-200 p-2 rounded-full text-sm h-min mt-4 disabled:invisible"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage * itemsPerPage >= waypoints.length}
        >
          {iconNext}
        </button>
      </div>

      {/* Debug */}
      {/* <div className="my-2">
        <div className="mr-2">
          {`Selected: ${selectedWaypoint ? selectedWaypoint.date : 'None'}`}
        </div>
      </div> */}
    </div>
  );
};

export default WaypointSelector;
