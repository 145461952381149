import React from "react";
import DetailsHeader from "../../common/panels/PanelHeader";
import DetailsHeaderTitle from "../../common/panels/PanelHeaderTitle";
import BasicTable, { DataRow } from "../../common/tables/BasicTable";
import BasicTableCell from "../../common/tables/BasicTableCell";
import ObjectiveTableCell from "../../common/tables/ObjectiveTableCell";
import ObjectivesPanelActionHeader from "./ObjectivesActionHeader";
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

const ObjectivesTitle = () => (
  <DetailsHeaderTitle
    title={`Objectives`}
    tooltipText={`What success looks like for this entity`}
  />
);

type ObjectivesContentProps = {
  objectives: Objective[];
};

function ObjectivesContent({ objectives }: ObjectivesContentProps) {
  const tableHeader: DataRow = {
    data: [
      {
        key: "TH1",
        cellContents: <BasicTableCell contents="Description" />,
        // tooltip: <div className="inline-flex">'No comment'</div>,
      },
      { key: "TH2", cellContents: <BasicTableCell contents="Target" /> },
      {
        key: "TH3",
        cellContents: <BasicTableCell contents="Realisation date" />,
        tooltip: <div>When we expect to achieve the target</div>,
      },
    ],
  };

  const tableData: DataRow[] = [];
  objectives.forEach((objective) =>
    tableData.push({
      data: [
        {
          key: `${objective.description}-1`,
          cellContents: (
            <ObjectiveTableCell contents={objective.description || "-"} />
          ),
        },
        {
          key: `${objective.description}-2`,
          cellContents: <BasicTableCell contents={objective.target || "-"} />,
        },
        {
          key: `${objective.description}-3`,
          cellContents: (
            <BasicTableCell contents={objective.targetDate || "-"} />
          ),
        },
      ],
    })
  );

  return (
    <div className="min-w-full text-sm space-y-4 lg:space-y-3">
      {objectives.length > 0 ? (
        <BasicTable tableData={tableData} tableHeader={tableHeader} />
      ) : (
        // Empty state
        <div className="text-gray-400 italic text-center">
          No objectives added yet
        </div>
      )}
    </div>
  );
}

type Objective = {
  description: string;
  target?: string | null;
  targetDate?: string | null;
};

type ObjectivesPanelProps = {
  objectives: Objective[];
  actionsDisabled: boolean;
  onEditClick: () => void;
  isVisible: boolean;
};

export default function ObjectivesPanel({
  objectives,
  actionsDisabled,
  onEditClick,
  isVisible,
}: ObjectivesPanelProps): React.ReactElement {
  // Set up content to show

  return (
    <DetailsHeader
      headerLeft={<ObjectivesTitle />}
      headerRight={
        <ObjectivesPanelActionHeader
          objectiveCount={objectives.length}
          onClick={onEditClick}
          isVisible={isVisible}
          disabled={actionsDisabled}
        />
      }
      content={<ObjectivesContent objectives={objectives} />}
    />
  );
}
