import React, { useEffect, useState } from 'react';

import {
  useGetMyNotificationsLazyQuery,
  useReadNotificationMutation,
  Notification,
} from '../../api/index';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import EmptyState from '../../common/layout/EmptyState';
import Spinner from '../../common/SpinnerThemed';
import NotificationItem from './NotificationItem';

function NotificationList(): React.ReactElement {
  const pageSize = 10;
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const [GetMyNotificationsQuery, { data, loading, error }] =
    useGetMyNotificationsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        limit: pageSize,
      },
    });

  const [view] = useReadNotificationMutation();

  useEffect(() => {
    GetMyNotificationsQuery();
  }, [GetMyNotificationsQuery]);

  useEffect(() => {
    const readAll = async () =>
      await data?.getMyNotifications?.items.forEach(n => {
        if (!n.read) {
          view({
            variables: {
              id: n.id,
            },
          });
        }
      });

    if (data?.getMyNotifications) {
      readAll();
      const newNotifications = data?.getMyNotifications
        ?.items as Notification[];
      setNotifications(oldNotifications => [
        ...oldNotifications,
        ...newNotifications,
      ]);
    }
  }, [data, view]);

  const notificationsTitle = (
    <DetailsPanelHeader title={'Notifications'} description={''} />
  );

  const notificationList = (
    <div className="-mx-6 -my-6">
      <div className="relative divide-y divide-gray-200">
        {notifications.length > 0 ? (
          notifications.map((notification, idx) => (
            <div className="">
              <NotificationItem
                notification={notification}
                key={idx}
                refresh={() => {
                  GetMyNotificationsQuery();
                }}
              />
            </div>
          ))
        ) : (
          <div className="text-center">
            <EmptyState
              type="notifications"
              secondaryText="You haven't received any notifications yet."
            />
          </div>
        )}
      </div>

      {data?.getMyNotifications?.nextToken && (
        <button
          onClick={() =>
            GetMyNotificationsQuery({
              variables: {
                limit: pageSize,
                nextToken: data?.getMyNotifications?.nextToken,
              },
            })
          }
          className="w-full text-sm bg-gray-100 hover:bg-gray-200 border-t border-gray-200 py-3 font-medium text-primary-600 hover:text-primary-700 transition ease-in-out duration-150"
        >
          Show more notifications
        </button>

        //  <div className="px-5 py-1 bg-gray-50 sm:px-8 sm:py-2 border-t border-gray-200">
        //     <div className="mt-1 text-sm">

        //     </div>
        //   </div>
      )}
    </div>
  );

  const notificationsContent = (
    <React.Fragment>
      {loading ? (
        <div className="flex flex-col align-middle items-center">
          <Spinner text={'Loading notifications...'} />
        </div>
      ) : error ? (
        <span>Error: `${error?.message}`</span>
      ) : (
        notificationList
      )}
    </React.Fragment>
  );

  return (
    <div className="flex flex-col space-y-6 max-w-3xl mx-auto">
      <DetailsPanel
        headerLeft={notificationsTitle}
        headerRight={null}
        content={notificationsContent}
      />
    </div>
  );
}

export default NotificationList;
