import * as React from 'react';

type Props = {
  selected: boolean;
  title: string;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const PanelTab = ({ selected, title, isDisabled, onClick }: Props) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${
        selected
          ? 'text-primary-700 bg-primary-100'
          : `${
              isDisabled
                ? 'text-gray-400'
                : `text-gray-500 hover:text-gray-700 hover:bg-gray-100`
            }`
      } group inline-flex items-center justify-center px-4 py-1 font-medium text-sm w-min h-full`}
    >
      <div className="flex items-center justify-center whitespace-nowrap mx-auto">
        {title}
        {/* <span className={`sm:flex text-center lg:whitespace-nowrap mx-auto`}>
        </span> */}
      </div>
    </button>
  );
};
