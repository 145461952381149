import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Validation,
  ValidField,
} from '../../common/wizard/validation/Validation.types';
import {
  useWizardOptions,
  WizardContext,
} from '../../common/wizard/WizardContext';
import WizardSelect from '../../common/wizard/components/WizardSelect';
import WizardTextInput from '../../common/wizard/components/WizardTextInput';
import { BillingAccount, BillingAccountPlan, EntityType, Workspace } from '../../api/index';
import { classNames } from '../../common/utils';
import { Tab } from '@headlessui/react';
import { UserContext } from '../../context/UserContext';
import { enumTranslates } from '../../common/i18n/translate';
import { WizardContentsData } from '../../common/wizard/components/WizardContentsCard';

import { Info } from 'phosphor-react';
import { wizardContentsIconStyle, wizardContentsIconSize } from '../../common/constants';

const tabs = [
  { name: 'My Workspace', href: '#', current: true },
  { name: 'Billing Account', href: '#', current: false },
  { name: 'Users', href: '#', current: false },
];

const stepName = 'Workspace Information';
const iconAbout = (
  <Info
    className={wizardContentsIconStyle}
    weight="duotone"
    size={wizardContentsIconSize}
  />
);

const meta: WizardContentsData = {
  icon: iconAbout,
  label: stepName,
  description: `The bare minimum we need to know about this ${enumTranslates[
    EntityType.Workspace
  ].toLowerCase()}`,
};

function Description(): React.ReactElement {
  const options = useWizardOptions();

  const { state } = useContext(WizardContext);
  const payloadObject = state.requestPayload as Workspace;
  const billingAccount = state.options.parentObject as BillingAccount;
  return (
    <React.Fragment>
      <div className="flex flex-col h-full">
        {/* Metadata */}
        <div className="flex flex-col h-full justify-between mt-8 text-sm">
          {/* Top of panel */}
          <div className="flex">
            {/* Status */}
            <div className="w-1/4 font-semibold mt-3 mr-2">Status:</div>
            <div className="w-3/4 mt-3">{billingAccount.status}</div>
          </div>

          {/* Bottom of panel */}
          <div className="flex flex-col mt-auto text-sm justify-end">
            <div className="flex">
              <div className="w-1/4 font-semibold mt-3 mr-2">
                Workspace Name:
              </div>
              <div className="w-3/4 mt-3"> {payloadObject?.name}</div>
            </div>
            {/* Counts */}
            <div className="flex">
              <div className="w-1/4 font-semibold mt-3 mr-2">
                Portfolio Count:
              </div>
              <div className="w-3/4 mt-3">{payloadObject.portfolioCount}</div>
            </div>
            <div className="flex">
              <div className="w-1/4 font-semibold mt-3 mr-2">
                Program Count:
              </div>
              <div className="w-3/4 mt-3">{payloadObject.programCount}</div>
            </div>
            <div className="flex">
              <div className="w-1/4 font-semibold mt-3 mr-2">
                Project Count:
              </div>
              <div className="w-3/4 mt-3">{payloadObject.projectCount}</div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const WORKSPACE_NAME_VALIDATION: Validation = {
  rules: [{ level: 'error', type: 'NonEmptyValueRule' }],
};

function Content(): React.ReactElement {
  const { user: currentUser } = useContext(UserContext);
  const { state, dispatch } = useContext(WizardContext);

  const [fieldValidations, setFieldValidations] = useState<ValidField[] | []>([
    {
      field: 'name',
      valid: false,
    },
  ]);

  const options = useWizardOptions();
  const payloadObject = state.requestPayload;
  console.log(state);
  useEffect(() => {
    const isValid = fieldValidations.some(
      (f: { valid: boolean }) => f.valid !== true
    );
    dispatch({
      type: 'WRITE_TO_STEP_VALIDATION',
      key: state.currentStepIndex,
      value: !isValid,
    });
  }, [dispatch, fieldValidations, state.currentStepIndex]);

  const billingAccountPlanOptions = useMemo(() => {
    return Object.values(BillingAccountPlan);
  }, []);

  return (
    <React.Fragment>
      {
        <Tab.Group>
          <div className="flex justify-center pb-3 ">
            <Tab.List className="flex whitespace-nowrap w-min space-x-2 font-medium text-sm rounded-md justify-center p-1">
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'bg-primary-200 text-primary-700'
                      : 'text-gray-500 hover:text-gray-700',
                    'px-3 py-2 font-semibold text-sm rounded-md'
                  )
                }
              >
                {tabs[0].name}
              </Tab>
              {options.isEditing && (
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? 'bg-primary-100 text-primary-700'
                        : 'text-gray-500 hover:text-gray-700',
                      'px-3 py-2 font-medium text-sm rounded-md'
                    )
                  }
                >
                  {tabs[1].name}
                </Tab>
              )}
            </Tab.List>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-5 md:mt-0">
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-6">
                    <WizardTextInput
                      id="name"
                      key="name"
                      isWizard={true}
                      requestKey="name"
                      setFieldValidations={setFieldValidations}
                      fieldValidations={fieldValidations}
                      validation={WORKSPACE_NAME_VALIDATION}
                      label={`Workspace Name`}
                      placeholder={`Workspace Name`}
                    />
                  </div>
                </div>
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <div className="mt-5 md:mt-0">
                <div className="grid grid-cols-6 gap-4">
                  <div className="col-span-6">
                    <div
                      className={classNames(
                        options.isEditing ? '' : 'hidden',
                        'col-span-6 sm:col-span-3'
                      )}
                    >
                      <WizardSelect
                        id="plan"
                        key="plan"
                        requestKey="plan"
                        label={'Plan'}
                        options={billingAccountPlanOptions}
                        disabled={true}
                      />
                      <p className="text-xs text-gray-500 mt-1">
                        Billing Account Plan
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      }
    </React.Fragment>
  );
}

export default {
  meta: meta,
  description: <Description />,
  content: <Content />,
  valid: true,
};
