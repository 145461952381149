import * as React from 'react';

import { useEffect, useReducer } from 'react';

import { Report } from '../../api/index';

export type ReportState = {
  report: Report | null;
};

const initialState: ReportState = JSON.parse(
  localStorage.getItem('report') || '{}'
);

type SetReportAction = {
  type: 'SET_REPORT';
  report: Report | null;
};

export type ReportAction = SetReportAction;

type ReportContextState = {
  dispatch: React.Dispatch<ReportAction>;
  state: ReportState;
};

export const ReportContext = React.createContext<ReportContextState>({
  dispatch: () => null,
  state: initialState,
});

const reducer = (state: ReportState, action: ReportAction) => {
  switch (action.type) {
    case 'SET_REPORT':
      return {
        ...state,
        report: action.report,
      };
    default:
      return state;
  }
};

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

export function ReportContextProvider({ children }: Props): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    localStorage.setItem('report', JSON.stringify(state));
  }, [state]);
  return (
    <ReportContext.Provider value={{ dispatch, state }}>
      {children}
    </ReportContext.Provider>
  );
}
