import {
  ValidateResult,
  ValidationRule,
  ValidationRuleStrategy,
} from '../Validation.types';

const NonEmptyValueValidationRuleStrategy: ValidationRuleStrategy = {
  validate(rule: ValidationRule, value: string): ValidateResult {
    return {
      isValid: value.trim().length > 0,
      messages: ['This cannot be blank'],
    };
  },
};

export default NonEmptyValueValidationRuleStrategy;
