import React, { useMemo } from 'react';
import styles from './task-list-table.module.css';
import { Task } from '../../types/public-types';
import { backgroundColors } from '../../types/bar-color-array';

type LocaleCacheType = { [key: string]: string };
const localeDateStringCache: LocaleCacheType = {};
const toLocaleDateStringFactory =
  (locale: string) =>
  (date: Date, dateTimeOptions: Intl.DateTimeFormatOptions) => {
    const key = date.toString();
    let lds = localeDateStringCache[key];
    if (!lds) {
      lds = date.toLocaleDateString(locale, dateTimeOptions);
      localeDateStringCache[key] = lds;
    }
    return lds;
  };
const dateTimeOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export const TaskListTableDefault: React.FC<{
  rowHeight: number;
  rowWidth: string;
  fontFamily: string;
  fontSize: string;
  locale: string;
  tasks: Task[];
  selectedTaskId: string;
  setSelectedTask: (taskId: string) => void;
  onExpanderClick: (task: Task) => void;
}> = ({
  rowHeight,
  rowWidth,
  tasks,
  fontFamily,
  fontSize,
  locale,
  onExpanderClick,
}) => {
  const toLocaleDateString = useMemo(
    () => toLocaleDateStringFactory(locale),
    [locale]
  );

  let colorIndex = 0;
  let currentName = '';
  let rowTitle = '';

  return (
    <div
      className="table rounded-lg border-b border-l bg-indi border-gray-200"
      style={{
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {tasks.map(t => {
        let expanderSymbol = '';
        if (t.hideChildren === false) {
          expanderSymbol = '▼';
        } else if (t.hideChildren === true) {
          expanderSymbol = '▶';
        }
        if (currentName !== t.project) {
          currentName = t.project as string;
          rowTitle = t.project as string;
          colorIndex = (colorIndex + 1) % backgroundColors.length;
        } else if (currentName === t.project) {
          rowTitle = '';
        }

        return (
          <div
            className="table-row overflow-ellipsis bg-gray-100 [&>*:nth-of-type(even)]:bg-gray-200"
            style={{ height: rowHeight, width: 300 }}
            key={`${t.id}row`}
          >
            <div
              className={`table-cell align-middle whitespace-nowrap overflow-ellipsis overflow-hidden ${backgroundColors[colorIndex]}`}
              style={{
                minWidth: rowWidth,
                width: 300,
              }}
              title={t.project}
            >
              <div className={styles.taskListNameWrapper}>
                <div
                  className={
                    expanderSymbol
                      ? styles.taskListExpander
                      : styles.taskListEmptyExpander
                  }
                  onClick={() => onExpanderClick(t)}
                >
                  {expanderSymbol}
                </div>
                <div className="text-white font-semibold whitespace-pre-wrap mr-2">{`${rowTitle}`}</div>
              </div>
            </div>
            {/* <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
            >
              &nbsp;{toLocaleDateString(t.start, dateTimeOptions)}
            </div>
            <div
              className={styles.taskListCell}
              style={{
                minWidth: rowWidth,
                maxWidth: rowWidth,
              }}
            >
              &nbsp;{toLocaleDateString(t.end, dateTimeOptions)}
            </div> */}
          </div>
        );
      })}
    </div>
  );
};
