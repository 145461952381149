import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import error from './graphics/404_error.png';

function PageNotFound(): React.ReactElement {
  const user = useContext(UserContext);
  const name = user.profile?.firstName;

  const href = `mailto:team@journeylab.io?subject=Support Request: Page not found&body=From Team JourneyLab:%0D%0A%0D%0ASorry for the disruption${
    user ? `, ${name}` : ''
  }. We'll fix this as soon as we can.%0D%0A%0D%0ATo help us, briefly describe what you were trying to access and what you clicked when the error happened.%0D%0A%0D%0AWe'll reply in this email thread when it's fixed or if we need more information.%0D%0A%0D%0AThanks in advance!%0D%0A%0D%0A____%0D%0A%0D%0A`;

  return (
    <div className="h-screen flex flex-col bg-white justify-between">
      <div className="h-px block" />
      <main className="flex flex-col justify-center max-w-7xl w-full mx-auto px-4">
        {/* Graphic */}
        <div className="flex-shrink-0 flex justify-center max-w-xl mx-auto -mt-10 -mb-10">
          <img className="object-contain" src={error} alt="" />
        </div>

        {/* Message */}
        <div className="">
          <div className="text-center">
            <h1 className="mt-2 text-4xl font-semibold text-gray-900 tracking-tight sm:text-5xl">
              Page not found
            </h1>
            <p className="mt-4 text-base text-gray-500">
              Sorry, we couldn’t find the page you’re looking for.
            </p>
            <div className="flex justify-center space-x-3 mt-6">
              <a
                href={href}
                className="text-sm font-medium text-gray-500 hover:text-gray-600"
              >
                <button
                  type="button"
                  className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-secondary-800 hover:bg-secondary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-800"
                >
                  Contact support
                </button>
              </a>
              <Link
                to="/"
                className="text-base font-medium text-primary-600 hover:text-primary-500"
              >
                <button
                  type="button"
                  className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {user?.profile ? 'Return to home page' : 'Log In'}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </main>

      <footer className="max-w-7xl w-full mx-auto text-center pt-8 pb-3">
        {/* Attribution: Graphic credit */}
        <a
          href="https://www.freepik.com/free-vector/404-error-with-landscape-concept-illustration_20824299.htm#query=404&position=5&from_view=author"
          target="_blank"
          className="text-xs text-gray-500 text-center"
        >
          Illustration by Storyset
        </a>
      </footer>
    </div>
  );
}

export default PageNotFound;
