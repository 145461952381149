import UserInfo from "../../basic/UserInfo";

type MembersTableCellProps = {
  firstName: string;
  surname: string;
  email: string;
};

function MembersTableCell({
  firstName,
  surname,
  email,
}: MembersTableCellProps) {
  return (
    <div className="inline-flex">
      <UserInfo firstName={firstName} surname={surname} email={email} />
    </div>
  );
}

export default MembersTableCell;
