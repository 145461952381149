import React, { useState, useEffect } from 'react';
import moment from 'moment';
import WaypointTile from './WaypointTile';
import { CaretLeft, CaretRight } from 'phosphor-react';
import BasicButton from '../../basic/BasicButton';
import { AddIcon } from '../../basic/ButtonIcons';

export type Waypoint = {
  id: string;
  date: Date;
  type: WaypointType;
  description?: string;
};

export enum WaypointType {
  milestone = 'Milestone',
  checkIn = 'Check-in',
  externalCommitment = 'External Commitment',
}

const iconPrevious = <CaretLeft className="" size={20} weight="bold" />;
const iconNext = <CaretRight className="" size={20} weight="bold" />;

type WaypointListProps = {
  waypoints: Waypoint[];
  todayWaypointIndex: number;
  onWaypointClick: (waypointId: string) => void;
};

function WaypointList({
  waypoints,
  todayWaypointIndex,
  onWaypointClick,
}: WaypointListProps): React.ReactElement {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedWaypointIndex, setSelectedWaypointIndex] =
    useState(todayWaypointIndex);
  const [pageSize, setPageSize] = useState(6);
  const [loaded, setLoaded] = useState(false);

  const width = window.innerWidth;

  // Adjust number of cards that are visible based on window width
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth >= 1440) {
        setPageSize(6);
      } else if (windowWidth >= 1280) {
        setPageSize(5);
      } else if (windowWidth >= 1080) {
        setPageSize(4);
      } else if (windowWidth >= 900) {
        setPageSize(3);
      } else {
        setPageSize(2);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [setPageSize, width]);

  const start = (currentPage - 1) * pageSize;
  const end = currentPage * pageSize;
  const paginatedWaypoints = waypoints.slice(start, end);

  const totalPages = Math.ceil(waypoints?.length / pageSize);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleWaypointClick = (index: number) => {
    setSelectedWaypointIndex(index);
    // callback to top level component
    onWaypointClick(waypoints[index].id);
  };

  // Select the today waypoint by default
  if (selectedWaypointIndex === -1) {
    setSelectedWaypointIndex(todayWaypointIndex);
  }

  // Go to the page with the current focus by default
  useEffect(() => {
    if (!loaded) {
      const pageOfTodayWaypoint = Math.ceil(
        (todayWaypointIndex + 1) / pageSize
      );

      // console.log(todayWaypointIndex, pageSize, pageOfTodayWaypoint);
      setCurrentPage(pageOfTodayWaypoint);
      setLoaded(true);
    }
  }, []);

  return (
    <div>
      {/* Page selector */}
      <div className="flex justify-center">
        <div className="flex justify-center align-middle items-center w-full gap-x-4">
          {/* Previous page button */}
          <button
            className="bg-transparent hover:bg-gray-200 p-2 rounded-full text-sm h-min disabled:invisible"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            {iconPrevious}
          </button>

          {/* Page dots */}
          <div className="flex items-center gap-2">
            {Array.from(Array(totalPages).keys()).map(pageNumber => (
              <div
                key={pageNumber}
                className={`h-2 rounded-full ${
                  pageNumber + 1 === currentPage
                    ? 'bg-primary-500 w-4'
                    : 'bg-gray-300 w-2'
                }`}
                onClick={() => handlePageChange(pageNumber + 1)}
              ></div>
            ))}
          </div>

          {/* Next page button */}
          <button
            className="bg-transparent hover:bg-gray-200 p-2 rounded-full text-sm h-min disabled:invisible"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            {iconNext}
          </button>
        </div>
      </div>

      {/* List of waypoint tiles for the current page */}
      <div className="flex items-center w-full justify-center mt-6">
        {paginatedWaypoints.map((waypoint, index) => (
          <WaypointTile
            key={waypoint.id}
            id={waypoint.id}
            isSelected={index + start === selectedWaypointIndex}
            onClick={() => handleWaypointClick(index + start)}
            date={waypoint.date}
            type={waypoint.type}
            description={waypoint.description || ''}
            isCurrentFocus={index + start === todayWaypointIndex}
            isBeforeCurrent={index + start < todayWaypointIndex}
          />
        ))}
      </div>
    </div>
  );
}

type WaypointSelectorProps = {
  waypoints: Waypoint[];
  todayWaypointIndex: number;
  onWaypointClick: (waypointId: string) => void;
};

export default function WaypointSelector({
  waypoints,
  todayWaypointIndex,
  onWaypointClick,
}: WaypointSelectorProps): React.ReactElement {
  return (
    <div className="relative bg-gray-50 shadow-inner rounded-lg px-4 pt-4 pb-4 flex flex-col items-center">
      {/* Add waypoint button - placeholder for now */}
      <div className="absolute top-4 right-6">
        <BasicButton icon={<AddIcon />} label={`Add waypoint`} />
      </div>

      {waypoints ? (
        // Additional bottom padding to cater for the selection indicator triangle
        <div className="pb-4">
          <WaypointList
            waypoints={waypoints}
            todayWaypointIndex={todayWaypointIndex}
            onWaypointClick={onWaypointClick}
          />
        </div>
      ) : (
        <div className="text-gray-400 text-center italic h-40 flex align-middle items-center">
          No waypoints added yet
        </div>
      )}
    </div>
  );
}
