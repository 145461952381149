import React from 'react';

type Props = {
  label: string;
  isValid: boolean;
};

function PasswordMatchIndicator({ label, isValid }: Props): React.ReactElement {
  const colour = isValid ? 'bg-green-600' : 'bg-gray-600';
  const colourBar = isValid ? 'bg-green-400' : 'bg-gray-400';
  return (
    <div className="relative flex justify-center items-center text-xs">
      <span
        className={`${colour} z-10 rounded-full flex h-5 w-9 shrink-0 items-center align-middle justify-center whitespace-nowrap`}
      >
        {label}
      </span>
      <div className="absolute top-1/2 left-0 w-full h-1.5 -mt-0.5">
        <div className={`w-full h-full ${colourBar} rounded-full`}></div>
      </div>
    </div>
  );
}

export default PasswordMatchIndicator;
