type ComponentProps = Record<string, unknown>;

export interface ComponentInfo<T> {
  id: string;
  Component: React.ComponentType<T>;
  props?: any;
}

interface ContainerProps {
  components: ComponentInfo<any>[];
}

export function GenericContainer({
  components,
}: ContainerProps): React.ReactElement {
  return (
    <div className="space-y-6">
      {components.map(containerPanel => {
        const { id, Component, props } = containerPanel;
        return <Component key={id} {...props} />;
      })}
    </div>
  );
}
