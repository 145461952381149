import React from 'react';
import { EscalatedWatchout } from './PerformanceSlideOverContent';
import BasicButton from '../../basic/BasicButton';
import { AgreedActionsIcon } from '../../basic/ButtonIcons';
import Tag from '../../basic/Tag';

type EscalationRowProps = {
  label: string;
  content: string | undefined;
};

const EscalationRow = ({
  label,
  content,
}: EscalationRowProps): React.ReactElement => {
  const isContentNull = !content;
  return (
    <div className="flex justify-between text-sm">
      <div className={`pr-4`}>
        <p className="font-medium">{label}</p>
      </div>
      <div className={``}>
        <p className={isContentNull ? `italic text-gray-400` : `text-gray-800`}>
          {content || 'Not provided '}
        </p>
      </div>
    </div>
  );
};

type PerformanceSlideOverEscalationCardProps = {
  escalation: EscalatedWatchout;
};

export default function PerformanceSlideOverEscalationCard({
  escalation,
}: PerformanceSlideOverEscalationCardProps): React.ReactElement {
  return (
    <div
      key={escalation.id}
      className="rounded-md border border-gray-200 shadow px-4 py-3 space-y-3"
    >
      {/* Main content */}
      <div className="py-1">
        <div className="mb-2">
          <Tag type="ask type" label={escalation.type} />
        </div>
        <div className="text-sm font-medium">{escalation.description}</div>
        <div className="text-sm mt-1 mb-3 text-gray-400">
          Escalated from: {escalation.sourceEntityName}
          {/* TODO: This should link to the source entity page */}
        </div>

        <div className="space-y-1">
          <EscalationRow label="Creator" content={escalation.creatorName} />
          <EscalationRow
            label="Assigned to"
            content={escalation.escalatedAssigneeName}
          />
          <EscalationRow label="Due date" content={escalation.dueDate} />
        </div>
      </div>

      {/* Action buttons */}
      <div className="-mx-4 px-4 pt-3 border-t border-gray-200 flex justify-end gap-x-3">
        <BasicButton
          icon={<AgreedActionsIcon />}
          label={'Add action plan...'}
          onClick={() => console.log(`Add action plan for ${escalation.id}`)}
          disabled={false}
        />
        {/* <BasicButton
          icon={<AssignIcon />}
          label={"Assign to..."}
          onClick={() => console.log(`Mark ${escalation.id} as resolved`)}
          disabled={false}
        /> */}
      </div>
    </div>
  );
}
