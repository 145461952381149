import { Info } from 'phosphor-react';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import { ulid } from 'ulid';

export type DataItem = {
  key: string;
  cellContents: React.ReactElement;
  widthClass?: string;
  tooltip?: React.ReactElement;
  rowSpan?: number;
  classes?: string;
};

export type DataRow = {
  data: Array<DataItem>;
};

type Props = {
  tableHeader: DataRow;
  tableData: Array<DataRow>;
  doCellPadding?: boolean;
};

function BasicTable({
  tableHeader,
  tableData,
  doCellPadding = true,
}: Props): React.ReactElement {
  // Set different x-padding for the ends of each row to give the illusion of even spacing
  function CellPadding(index: number, columnCount: number): string {
    if (!doCellPadding) {
      return 'px-2';
    }

    let format = '';

    if (index === 0) {
      format = 'pl-4 pr-2';
    } else if (index === columnCount - 1) {
      format = 'pl-2 pr-4';
    } else {
      format = 'px-2';
    }

    return format;
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {/* Updated table */}
          <div className="overflow-hidden border border-gray-200 rounded-lg">
            {/* Tailwind grid table style */}
            <table className="table min-w-full divide-y divide-gray-200">
              <thead className="table-header-group bg-gray-50">
                {/* Header row */}
                <React.Fragment>
                  <tr className="table-row">
                    {tableHeader.data.map((item, columnIndex) => (
                      <th
                        key={item.key}
                        className={`table-cell ${
                          item.widthClass ? `${item.widthClass}` : ''
                        } ${CellPadding(
                          columnIndex,
                          tableHeader.data.length
                        )} py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                      >
                        <div className="flex items-center gap-x-2">
                          {item.cellContents}
                          {item.tooltip && (
                            <React.Fragment>
                              <button
                                type="button"
                                className="h-min disabled:opacity-50 inline-flex rounded-full text-gray-400 hover:text-blue-600 hover:shadow-sm"
                                data-tip
                                data-for={item.key}
                              >
                                <Info
                                  weight="bold"
                                  className="h-4 w-4 shrink-0"
                                  aria-hidden="true"
                                />
                              </button>
                              <ReactTooltip
                                id={item.key}
                                place="right"
                                effect="solid"
                              >
                                <div className="text-sm space-y-2 normal-case font-normal">
                                  {item.tooltip}
                                </div>
                              </ReactTooltip>
                            </React.Fragment>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </React.Fragment>
              </thead>

              {/* Table data */}
              <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
                {/* Direct members */}
                {tableData.map(row => {
                  return (
                    <tr key={ulid()} className="table-row">
                      {/* hover:bg-gray-100 - disable row hover for now */}
                      {row.data.map((item, columnIndex) => (
                        <td
                          key={item.key}
                          rowSpan={item.rowSpan}
                          className={`table-cell ${CellPadding(
                            columnIndex,
                            row.data.length
                          )} border-t border-gray-200 py-3 whitespace-normal align-top ${
                            item.classes
                          }`}
                        >
                          {item.cellContents}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BasicTable;
