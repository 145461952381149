export type Entity = {
  id: string;
  name: string;
  strategicObjective: string;
  baseline: string;
  target: string;
  current: string;
  confidence?: 'Low' | 'Medium' | 'High';
  hasEscalations: boolean;
};

export type GroupedEntities = {
  [key: string]: {
    entities: Array<Entity & { count: number }>;
    count: number;
  };
};

export default function restructureEntities(
  entities: Array<Entity>
): GroupedEntities {
  entities.sort((a, b) =>
    a.strategicObjective.localeCompare(b.strategicObjective)
  );

  const groups: GroupedEntities = {};

  entities.forEach(entity => {
    const key = entity.strategicObjective;
    if (!groups[key]) {
      groups[key] = { entities: [], count: 0 };
    }
    groups[key].entities.push({ ...entity, count: ++groups[key].count });
  });

  return groups;
}
