import * as React from "react";

type SlideOverSectionHeaderProps = {
  label: string;
};

export const SlideOverSectionHeader = ({
  label,
}: SlideOverSectionHeaderProps): React.ReactElement => {
  return <p className="font-semibold text-sm pb-2">{label}</p>;
};
