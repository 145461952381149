import React, { useContext, useEffect, useMemo } from 'react';
import { Tab } from '@headlessui/react';

import BasicHeaderWithTabsLayout from '../../common/layout/BasicHeaderWithTabsLayout';
import { PageTab } from '../../common/PageTab';

import { ulid } from 'ulid';
import {
  EntityType,
  useGetBillingAccountLazyQuery,
  useGetWorkspaceLazyQuery,
  useSetLastViewedWorkspaceMutation,
} from '../../api/index';
import { WorkspacePageContext } from './WorkspacePageContext';
import { useNavigate, useParams } from 'react-router-dom';
import { capitaliseFirstLetter } from '../../common/utils';
import { enumTranslates } from '../../common/i18n/translate';
import WorkspaceSettings from './WorkspaceSettings';
import AccountSettings from './AccountSettings';
import WorkspaceMembersTab from './WorkspaceMembersTab';

function WorkspacePage(): React.ReactElement {
  const { state: workspacePageState, dispatch: workspacePageDispatch } =
    useContext(WorkspacePageContext);
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const navigate = useNavigate();

  const [setLastViewedWorkspace] = useSetLastViewedWorkspaceMutation();

  const [GetWorkspace, { data: data, loading: loading, error: error }] =
    useGetWorkspaceLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [
    GetAccount,
    {
      data: dataBillingAccount,
      loading: loadingBillingAccount,
      error: errorBillingAccount,
    },
  ] = useGetBillingAccountLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    GetWorkspace({
      variables: {
        workspaceId: workspaceId as string,
      },
    });
    GetAccount({
      variables: {
        workspaceId: workspaceId as string,
      },
    });
  }, [GetWorkspace, GetAccount, workspaceId]);

  useEffect(() => {
    if (!loading && !loadingBillingAccount) {
      if (data?.getWorkspace && dataBillingAccount?.getBillingAccount) {
        workspacePageDispatch({
          type: 'SET_SELECTED_WORKSPACE',
          workspace: data?.getWorkspace,
        });
        workspacePageDispatch({
          type: 'SET_BILLING_ACCOUNT',
          billingAccount: dataBillingAccount.getBillingAccount,
        });
        setLastViewedWorkspace({
          variables: {
            workspaceId: data?.getWorkspace.id,
          },
        });
      }
    }
  }, [
    data?.getWorkspace,
    dataBillingAccount?.getBillingAccount,
    loading,
    loadingBillingAccount,
    setLastViewedWorkspace,
    workspacePageDispatch,
  ]);

  const tabs = [
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`${capitaliseFirstLetter(
                enumTranslates[EntityType.Workspace]
              )} Settings`}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <WorkspaceSettings />
          </div>
        </Tab.Panel>
      ),
      hash: '#workspace',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab selected={selected} title={'Billing Account'} />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <AccountSettings />
          </div>
        </Tab.Panel>
      ),
      hash: '#account',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`${capitaliseFirstLetter(
                enumTranslates[EntityType.Workspace]
              )} Users`}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <WorkspaceMembersTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#users',
    },
  ];

  const showErrorMessage = useMemo(() => {
    return error != null;
  }, [error]);

  const showLoadingSpinner = useMemo(() => {
    return (
      !showErrorMessage &&
      (loading || workspacePageState.selectedWorkspace == null)
    );
  }, [loading, showErrorMessage]);

  return (
    <BasicHeaderWithTabsLayout
      header={<div />}
      tabs={tabs}
      isLoading={showLoadingSpinner}
    />
  );
}

export default WorkspacePage;
