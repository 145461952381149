import React from 'react';

import { EntityType, IInvitation, useAcceptMutation } from '../../api/index';
import { EnvelopeSimple } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { PORTFOLIO, PROGRAM, PROJECT } from '../../common/routes';

type InvitationItemProps = {
  invitation: IInvitation;
  refresh: () => void;
};

function InvitationItem({
  invitation,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh,
}: InvitationItemProps): React.ReactElement {
  const [accept, { loading, error }] = useAcceptMutation({
    variables: {
      entityId: invitation.entityId,
    },
  });

  const navigate = useNavigate();

  function handleClick(status: string) {
    if (status === 'accepted') {
      // Go to the entity page
      if (invitation.entityType === EntityType.Portfolio) {
        navigate(`${PORTFOLIO}/${invitation.entityId}`);
      } else if (invitation.entityType === EntityType.Program) {
        navigate(`${PROGRAM}/${invitation.entityId}`);
      } else if (invitation.entityType === EntityType.Project) {
        navigate(`${PROJECT}/${invitation.entityId}`);
      }
    } else {
      // Accept the invite
      _accept();
    }
  }

  const _accept = async () => {
    try {
      // Accept the invite
      await accept();

      // Then go to the entity page
      if (invitation.entityType === EntityType.Portfolio) {
        navigate(`${PORTFOLIO}/${invitation.entityId}`);
      } else if (invitation.entityType === EntityType.Program) {
        navigate(`${PROGRAM}/${invitation.entityId}`);
      } else if (invitation.entityType === EntityType.Project) {
        navigate(`${PROJECT}/${invitation.entityId}`);
      }

      //refresh();
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error: `${error.message}`</span>;
  }

  const iconNew = (
    <EnvelopeSimple
      className="flex-shrink-0 h-7 w-7 text-primary-600"
      weight="duotone"
      aria-hidden="true"
    />
  );

  const iconAccepted = (
    <EnvelopeSimple
      className="flex-shrink-0 h-7 w-7 text-gray-400"
      weight="duotone"
      aria-hidden="true"
    />
  );

  return (
    <button
      onClick={() => handleClick(invitation.status)}
      className="px-6 py-3 w-full flex items-start hover:bg-gray-200 transition ease-in-out duration-150"
    >
      {invitation.status === 'accepted' ? iconAccepted : iconNew}

      <div className="ml-3 pt-1 text-left">
        <p className="text-sm font-medium text-gray-900">
          {`You have been invited to the ${invitation.entityType} "${invitation.entityName}"`}
        </p>
        <p className="mt-1 text-sm text-gray-500">
          {invitation.status === 'accepted'
            ? 'Invitation accepted'
            : `Click to accept invitation`}
        </p>
      </div>
    </button>
  );
}

export default InvitationItem;
