import React from 'react';
import { EnvelopeSimple, FloppyDisk, Plus } from 'phosphor-react';

type Props = {
  icon?: iconType;
  label: string | React.ReactElement;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled: boolean;
  width?: string;
};

type iconType = 'plus' | 'report' | 'save';

const iconPlus = (
  <Plus className="-ml-1 mr-2 h-5 w-5" weight="bold" aria-hidden="true" />
);
const iconReport = (
  <EnvelopeSimple
    className="-ml-1 mr-2 h-5 w-5"
    weight="bold"
    aria-hidden="true"
  />
);
const iconSave = (
  <FloppyDisk className="-ml-1 mr-2 h-5 w-5" weight="bold" aria-hidden="true" />
);

export default function PrimaryButton({
  icon,
  label,
  onClick,
  disabled,
  width,
}: Props): React.ReactElement {
  // Get the icon type
  function ButtonIcon(): React.ReactElement {
    let output = <React.Fragment />;

    switch (icon) {
      case 'plus':
        output = iconPlus;
        break;
      case 'report':
        output = iconReport;
        break;
      case 'save':
        output = iconSave;
        break;

      default:
        break;
    }

    return output;
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className={`${
        width ? width : ''
      } bg-gradient-to-tr from-primary-700 to-primary-500 hover:to-primary-600 inline-flex justify-center items-center px-4 py-2 rounded-md shadow-sm whitespace-nowrap text-sm font-semibold text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50`}
      // bg-primary-500 hover:bg-primary-600
      onClick={onClick}
    >
      {icon ? ButtonIcon() : null}
      {label}
    </button>
  );
}
