import * as React from 'react';

type Props = {
  title: string;
};

export default function DetailsPanelSectionDivider({
  title,
}: Props): React.ReactElement {
  return (
    <>
      {/* Left aligned */}
      {/* <div className="flex items-center mb-3">
        <h4 className="text-base">{title}</h4>
        <span className="flex flex-grow h-1.5 border-b border-primary-500 ml-4"></span>
      </div> */}

      {/* Left aligned tag */}
      <div className="flex items-center mb-3">
        <span className="text-xs text-primary-700 font-semibold rounded bg-primary-200 py-1 px-3">
          {title}
        </span>
        <span className="flex flex-grow h-0 border-b border-primary-200 ml-2"></span>
      </div>

      {/* Centred option */}
      {/* <div className="flex items-center mb-3">
        <span className="flex flex-grow h-1.5 border-b border-gray-200 mr-4"></span>
        <h4 className="text-base">{title}</h4>
        <span className="flex flex-grow h-1.5 border-b border-gray-200 ml-4"></span>
      </div> */}
    </>
  );
}
