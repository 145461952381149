import * as React from "react";
import { CaretRight } from "phosphor-react";

type Props = {
  headerLeft: React.ReactElement | Array<React.ReactElement>;
  headerRight?: React.ReactElement | Array<React.ReactElement> | null;
  content?: React.ReactElement | Array<React.ReactElement> | null;
};

export default function PanelHeader({
  headerLeft,
  headerRight,
  content,
}: Props): React.ReactElement {
  const [isExpanded, setIsExpanded] = React.useState(true);

  const expandIcon = <CaretRight size={20} weight="bold" className="" />;

  function handleHeaderClick() {
    setIsExpanded(!isExpanded);
  }

  return (
    // Note: Keep overflow visible so that menus that drop below the panel bounds can still be seen
    <div className="bg-white overflow-visible rounded-lg divide-y divide-gray-200 border border-gray-200 shadow">
      {/* Header */}
      <div className="px-4 py-3 sm:px-6">
        <div className="flex justify-between items-center flex-wrap">
          <div className="flex align-middle">
            <div
              className={`w-min my-auto hover:bg-gray-100 hover:cursor-pointer rounded-full p-1 -ml-2 ${
                isExpanded ? "rotate-90" : ""
              }`}
              onClick={() => handleHeaderClick()}
            >
              {expandIcon}
            </div>
            <div className="mx-2">{headerLeft}</div>
          </div>
          <div className="flex items-center">
            <span className={`flex flex-wrap ${isExpanded ? "" : "hidden"}`}>
              {headerRight}
            </span>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className={`px-4 py-5 sm:p-6 ${isExpanded ? "" : "hidden"}`}>
        {content}
      </div>
    </div>
  );
}
