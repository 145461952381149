/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { CaretRight } from 'phosphor-react';
import { useCallback, useContext, useMemo } from 'react';
import PrimaryButton from '../PrimaryButton';
import WizardContentsCard, {
  WizardContentsData,
} from './components/WizardContentsCard';
import {
  initialNullWizardOptions,
  WizardContext,
  WizardContextProvider,
  WizardOptions,
} from './WizardContext';

export type WizardStep = {
  meta: WizardContentsData;
  description: React.ReactElement;
  content: React.ReactElement;
  valid: boolean;
};

export type WizardFormInputProps = {
  id?: string;
  requestKey: string;
};

type Props = {
  initialPayload?: { [key: string]: any | undefined };
  options?: WizardOptions | Partial<WizardOptions>;
  steps: Array<WizardStep> | undefined;
  onSubmit: (payload: any) => void;
};

function WizardDescription(): React.ReactElement {
  const { dispatch, state } = useContext(WizardContext);
  const handleStepClick = useCallback(
    (stepIndex: number) => {
      dispatch({
        type: 'JUMP_TO_STEP',
        stepIndex,
      });
    },
    [dispatch]
  );

  return (
    <div
      key={`description-${state.steps[state.currentStepIndex]}`}
      className="flex flex-col h-full"
    >
      <h3 className="text-lg font-medium leading-6 mb-6">
        {state.options.title}
      </h3>

      {/* Steps */}
      <div className="flex-1">
        <div className="grid grid-cols-1 gap-1 overscroll-y-auto">
          {state.steps.map((item, itemIdx) => {
            const data: WizardContentsData = item.meta;

            return (
              <WizardContentsCard
                key={itemIdx}
                contents={data}
                selected={state.currentStepIndex === itemIdx}
                jumpTo={itemIdx}
              />
            );
          })}
        </div>
      </div>

      {/* Progress dots - commented out for now */}
      {/* {state.steps.length > 1 && (
        <WizardProgressDots
          currentStepIndex={state.currentStepIndex}
          numTotalSteps={state.steps.length}
          onStepClick={handleStepClick}
        />
      )} */}
    </div>
  );
}

type ActionsProps = {
  onSubmit: (payload: any) => void;
};

function WizardContentActions({ onSubmit }: ActionsProps): React.ReactElement {
  const { dispatch, state } = useContext(WizardContext);
  const enableQuickSave = state.options.quickSave;

  const handleBackClick = useCallback(() => {
    dispatch({
      type: 'PREVIOUS_STEP',
    });
  }, [dispatch]);

  const handleNextClick = useCallback(() => {
    dispatch({
      type: 'NEXT_STEP',
    });
  }, [dispatch]);

  const handleSubmitClick = useCallback(() => {
    onSubmit && onSubmit(state.requestPayload);
  }, [onSubmit, state.requestPayload]);

  const isLastStep = state.currentStepIndex === state.steps.length - 1;
  const actions = state.actions?.find(a => a.step === state.currentStepIndex);
  const nextOrSubmitButton = (
    <div className="flex">
      {/* Wizard navigation buttons */}
      {state.steps.length > 1 && (
        <div className="mr-3 flex border border-gray-300 divide-x divide-gray-300 rounded-md overflow-hidden">
          {state.currentStepIndex > 0 ? (
            <button
              type="button"
              className={`disabled:opacity-50 inline-flex items-center px-4 py-2 text-sm font-medium shadow-sm text-gray-700 bg-white hover:bg-gray-100 focus:outline-none`} // focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
              onClick={
                actions?.previousButtonAction &&
                actions?.previousButtonAction !== null
                  ? actions.previousButtonAction
                  : handleBackClick
              }
            >
              Previous
            </button>
          ) : null}
          {isLastStep ? null : (
            <button
              type="button"
              disabled={!state.steps[state.currentStepIndex].valid}
              className={`disabled:opacity-50 inline-flex items-center px-4 py-2 text-sm font-medium shadow-sm text-gray-700 bg-white hover:bg-gray-100 focus:outline-none`} // focus:ring-2 focus:ring-offset-2 focus:ring-primary-500
              onClick={
                actions?.nextButtonAction && actions?.nextButtonAction !== null
                  ? actions.nextButtonAction
                  : handleNextClick
              }
            >
              Next
            </button>
          )}
        </div>
      )}

      {/* Submit button */}
      {enableQuickSave || isLastStep ? (
        <PrimaryButton
          label="Save & Close"
          disabled={!state.steps[state.currentStepIndex].valid}
          onClick={handleSubmitClick}
        />
      ) : null}
    </div>
  );

  return (
    <div className="flex flex-row justify-end mt-4">{nextOrSubmitButton}</div>
  );
}

type ContentProps = {
  onSubmit: (payload: any) => void;
};

function WizardStepSummary(): React.ReactElement {
  const { state } = useContext(WizardContext);
  const [isExpanded, setIsExpanded] = React.useState(true);

  const caretIcon = <CaretRight weight="bold" size={16} className="shrink-0" />;

  return (
    // This is a collapsible menu that shows the step name plus additional information that can be hidden
    <button
      type="button"
      className="flex-1 w-full border border-blue-200 px-4 py-3 rounded-md bg-blue-50 hover:bg-blue-100 text-left"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex">
        {/* Expand/collapse indicator */}
        <div
          className={`w-4 h-4 flex shrink-0 ${
            isExpanded ? 'rotate-90' : ''
          } mt-1 mr-3`}
        >
          {caretIcon}
        </div>

        <div>
          {/* Name of the step */}
          <h4 className="text-base font-medium leading-6 text-blue-800">
            {state.steps[state.currentStepIndex].meta?.label}
          </h4>
          {/* Additional information about the step */}
          <div className={`${isExpanded ? 'mt-3' : 'hidden'}`}>
            {state.steps[state.currentStepIndex].description}
          </div>
        </div>
      </div>
    </button>
  );
}

function WizardContent({ onSubmit }: ContentProps): React.ReactElement {
  const { state } = useContext(WizardContext);
  return (
    <div
      className="flex flex-col h-full pt-10"
      key={`content-${state.steps[state.currentStepIndex]}`}
    >
      {/* This content changes for each step */}
      <div className="overflow-y-auto overflow-x-hidden">
        <div className="mb-6">
          <WizardStepSummary />
        </div>
        <div className="px-1 flex-grow">
          {state.steps[state.currentStepIndex].content}
        </div>
      </div>

      {/* Action buttons */}
      <div className="pr-1 pb-1 mt-auto justify-end">
        <WizardContentActions onSubmit={onSubmit} />
      </div>

      {/* <div className="max-w-2xl overflow-y-auto">
        <WizardDebugger />
      </div> */}
    </div>
  );
}

export default function Wizard({
  initialPayload,
  onSubmit,
  options: partialOptions,
  steps,
}: Props): React.ReactElement {
  const options = useMemo(() => {
    return {
      ...JSON.parse(JSON.stringify(initialNullWizardOptions)),
      ...partialOptions,
    };
  }, [partialOptions]);

  const initialState = useMemo(() => {
    const maybeInitialPayload =
      initialPayload != null ? { requestPayload: initialPayload } : {};
    return { currentStepIndex: 0, steps, options, ...maybeInitialPayload };
  }, [initialPayload, options, steps]);

  // No longer needed for div style below
  // const contentColumnStyles = useMemo(() => {
  //   return {
  //     // flex: '3 3 0',
  //     height: `640px`,
  //   };
  // }, []);

  return (
    <WizardContextProvider initialState={initialState}>
      <div className="flex flex-col md:flex-row w-full">
        <div className="bg-white text-black p-5 md:w-[320px] md:flex-shrink-0 md:flex-grow-0 border-r border-gray-300">
          <WizardDescription />
        </div>
        <div
          className="bg-white p-4 w-full h-screen flex-grow"
          // style={contentColumnStyles}
        >
          <div className="mx-auto max-w-6xl h-full">
            <WizardContent onSubmit={onSubmit} />
          </div>
        </div>
      </div>
    </WizardContextProvider>
  );
}
