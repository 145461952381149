import { ChartLine, Target } from 'phosphor-react';
import React, { useState } from 'react';
import { ulid } from 'ulid';
import {
  EntityType,
  LogicModelGroup,
  Program,
  ProgramMeasureStatus,
  ProjectUpdateConfidence,
} from '../../../api/index';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import { PROGRAM, PROJECT } from '../../../common/routes';
import { PanelTab } from '../../../common/PanelTab';
import {
  capitaliseFirstLetter,
  getLatestProgramBenefitStatus,
  getLatestProgramUpdate,
  getLatestProjectUpdate,
  getLocalDate,
  getParentProgram,
  sortProgramBenefits,
  sortProjectBenefits,
  sortProjects,
} from '../../../common/utils';
import { getStatusDot } from '../../program/PerformanceTab/HelperFunctions';
import ExpandableSlideoverCard from './ExpandableSlideoverCard';

type Props = {
  slideOverProgram: Program | null;
  programs: Program[];
};

export default function ProgramSlideoverLayout({
  slideOverProgram,
  programs,
}: Props): React.ReactElement {
  //   Pre-process tabs into groups
  const tabNames = [
    `${translate(strings.PROGRAM_MEASURES, 'Program Measures')}`,
    `Contributing ${capitaliseFirstLetter(
      enumTranslates[EntityType.Project]
    )}s`,
  ] as const;
  // TODO: Check contributing logic
  type tabs = typeof tabNames[number];
  const [selectedTab, setSelectedTab] = useState<tabs>(tabNames[0]);

  const iconCurrentValue = (
    <ChartLine
      className="inline-block text-gray-500 mb-0.5 flex-shrink-0"
      // color="#7D8390"
      weight="duotone"
      size={20}
    />
  );

  const iconTarget = (
    <Target
      className="inline-block text-gray-500 mb-0.5 flex-shrink-0"
      weight="duotone"
      size={20}
    />
  );

  const benefitsView = (
    <div>
      {slideOverProgram?.measures?.length == 0 ? (
        <div className="border-2 border-gray-300 border-dashed rounded-md py-6">
          <EmptyState type="benefits" />
        </div>
      ) : (
        <div className="space-y-3">
          {sortProgramBenefits(
            slideOverProgram?.measures ? slideOverProgram.measures : [],
            getLatestProgramUpdate(slideOverProgram)
          )?.map(benefit => {
            const latestStatus = getLatestProgramBenefitStatus(
              benefit,
              programs
            );

            return (
              <ExpandableSlideoverCard
                statusIcon={
                  <div className="ml-1.5 mt-0.5">
                    {getStatusDot(latestStatus, new Date(), benefit.id)}
                  </div>
                }
                title={<div>{benefit.name}</div>}
                isExpandable={true}
                expandDefault={
                  latestStatus === ProgramMeasureStatus.HelpNeeded ||
                  latestStatus === ProgramMeasureStatus.Risk
                }
                content={
                  <div className="border-t border-gray-200 px-3 py-2 bg-white h-full align-top space-y-1">
                    {/* Target */}
                    <div className="flex flex-row space-x-2 align-baseline">
                      <span className="w-6 flex-none mx-0.5">{iconTarget}</span>
                      <div>
                        {benefit.targetValue
                          ? `${benefit.targetValue}${
                              benefit.achieveByDate
                                ? `, by ${getLocalDate(benefit.achieveByDate)}`
                                : ''
                            }`
                          : '(No target)'}
                      </div>
                    </div>

                    {/* Current value */}
                    <div className="flex flex-row space-x-2 align-baseline">
                      <span className="w-6 flex-none mx-0.5">
                        {iconCurrentValue}
                      </span>
                      <div>
                        {getLatestProgramUpdate(
                          getParentProgram(programs, benefit.id)
                        )?.measures?.find(
                          measure => measure.measureId === benefit.id
                        )?.value || '(No commentary)'}
                      </div>
                    </div>
                  </div>
                }
                link={`${PROGRAM}/${
                  getParentProgram(programs, benefit.id)?.id
                }#performance`}
              />
            );
          })}
        </div>
      )}
    </div>
  );

  const projectsView = (
    <div>
      {slideOverProgram?.projects?.length == 0 ? (
        <div className="border-2 border-gray-300 border-dashed rounded-md py-6">
          <EmptyState type={`${enumTranslates[EntityType.Project]}s`} />
        </div>
      ) : (
        <div className="space-y-3">
          {sortProjects(
            slideOverProgram?.projects ? slideOverProgram?.projects : []
          )?.map(project => {
            const update = getLatestProjectUpdate(project);

            return (
              <ExpandableSlideoverCard
                statusIcon={
                  <div className="-mt-0.5">
                    <ConfidenceBadge
                      rating={update?.confidenceRating}
                      reviewDate={update?.updateDate}
                      size="shortened"
                    />
                  </div>
                }
                title={<div className="mt-0.5">{project.name}</div>}
                isExpandable={true}
                expandDefault={
                  // false
                  update?.confidenceRating === ProjectUpdateConfidence.Low ||
                  update?.confidenceRating === ProjectUpdateConfidence.Medium
                }
                content={
                  <div className="bg-white border-t border-gray-200 divide-y divide-gray-200">
                    {(project.measures?.filter(
                      item => item.logicModelGroup === LogicModelGroup.Impacts
                    )?.length as number) > 0 ? (
                      sortProjectBenefits(
                        project.measures?.filter(
                          item =>
                            item.logicModelGroup === LogicModelGroup.Impacts
                        ) || [],
                        update
                      )?.map(projectMeasure => {
                        const latestUpdate = update;
                        const status = latestUpdate?.measures?.find(
                          item => item.measureId === projectMeasure.id
                        )?.status;

                        return (
                          <div className="flex px-3 py-2">
                            <div className="w-6 mr-3 flex justify-center items-center shrink-0">
                              {getStatusDot(
                                status as string,
                                latestUpdate?.updateDate,
                                ulid()
                              )}
                            </div>
                            {projectMeasure.name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex px-3 py-2 text-gray-500 italic">
                        <div className="w-6 mr-3 flex justify-center items-center"></div>
                        No benefits added yet
                      </div>
                    )}
                  </div>
                }
                link={`${PROJECT}/${project.id}`}
              />
            );
          })}
        </div>
      )}
    </div>
  );

  const layout = (
    <div className="pb-12">
      {/* Tab selector */}
      <div className="w-min mx-auto">
        <div className="flex items-center justify-center rounded-md overflow-hidden mb-6 border border-gray-200 divide-x divide-gray-200">
          {tabNames.map((tab, index) => (
            <PanelTab
              selected={tabNames[index] === selectedTab}
              title={tab}
              onClick={() => setSelectedTab(tabNames[index])}
            />
            //   <button onClick={() => setTab(tabNames[index])}>{tab}</button>
          ))}
        </div>
      </div>

      {/* Content */}
      {selectedTab === tabNames[0] ? benefitsView : projectsView}
    </div>
  );

  return layout;
}
