import React, { useContext } from 'react';
import { PortfolioPageContext } from '../PortfolioPageContext';
import translate, { strings } from '../../../common/i18n/translate';
import { classNames, getLocalDate } from '../../../common/utils';
import { Target } from 'phosphor-react';
import { StrategicTheme, StrategicObjective } from '../../../api/index';

function PortfolioObjectivesTable(): React.ReactElement {
  const { state: portfolioPageState } = useContext(PortfolioPageContext);

  const iconTarget = (
    <Target
      className="inline-block flex-shrink-0 text-gray-500"
      weight="duotone"
      size={20}
      data-tip
      data-for={'target-icon'}
    />
  );

  function getObjectivesByTheme(
    theme: StrategicTheme | null
  ): StrategicObjective[] {
    let objectives: StrategicObjective[] = [];

    if (theme) {
      // Get measures that are mapped to this objective
      objectives =
        portfolioPageState.selectedPortfolio?.objectives?.filter(
          (objective: StrategicObjective) =>
            objective.strategicThemeId == theme.id
        ) || [];
    } else {
      // Get unmapped measures
      objectives =
        portfolioPageState.selectedPortfolio?.objectives?.filter(
          (objective: StrategicObjective) =>
            !portfolioPageState.selectedPortfolio?.themes?.some(
              theme => theme.id === objective.strategicThemeId
            )
        ) || [];
    }
    return objectives;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {/* Updated table */}
          <div className="overflow-hidden border border-gray-200 rounded-lg">
            {/* Tailwind grid table style */}
            <table className="table min-w-full divide-y divide-gray-200">
              <thead className="table-header-group bg-gray-50">
                {/* Header row */}
                <tr className="table-row">
                  {/* Pillars */}
                  <td className="table-cell pl-4 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {translate(
                      strings.PORTFOLIO_STRATEGIC_PRIORITIES,
                      'Strategic Priorities'
                    )}
                    {/* <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                      {translate(
                        strings.PORTFOLIO_STRATEGIC_PRIORITIES_DEFINITION,
                        `The focus areas for our current strategy`
                      )}
                    </p> */}
                  </td>

                  {/* Objectives */}
                  <td className="table-cell pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {translate(
                      strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                      'Strategic Objectives'
                    )}
                    {/* <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                      {translate(
                        strings.PORTFOLIO_STRATEGIC_OBJECTIVES_DEFINITION,
                        `Specific goals we want to achieve as an organisation`
                      )}
                    </p> */}
                  </td>
                </tr>
              </thead>

              {/* Table data */}
              <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
                {portfolioPageState.selectedPortfolio?.themes?.map(
                  (theme, themeIndex) =>
                    getObjectivesByTheme(theme).map((objective, oIndex) => (
                      <tr key={objective.id} className="table-row">
                        {/* Theme name and description */}
                        {/* rowSpan={getObjectivesByTheme(theme).length} */}
                        <td
                          rowSpan={getObjectivesByTheme(theme).length}
                          className={classNames(
                            oIndex == 0 ? 'border-t border-gray-200' : 'hidden',
                            'table-cell pl-4 pr-4 py-3 whitespace-normal font-medium align-top border-t border-gray-200'
                          )}
                        >
                          {theme.name}
                          <p className="font-normal mt-1">
                            {theme.description}
                          </p>
                        </td>
                        {/* Strategic objectives (sub-themes) */}
                        <td
                          className={classNames(
                            themeIndex == 0 ? 'border-t border-gray-200' : '',
                            'table-cell pl-2 pr-4 py-3 whitespace-normal align-top border-t border-gray-200'
                          )}
                        >
                          <p>{objective.description}</p>
                          <div className="flex align-top gap-x-1.5 mt-1">
                            <span className="flex align-top text-gray-500">
                              {iconTarget}
                            </span>
                            <div className="text-gray-500">
                              {!objective.targets ||
                              objective.targets.length < 1 ? (
                                <p className="italic">(No target)</p>
                              ) : (
                                objective.targets.map(target => (
                                  <p key={target.id}>{`${target?.targetValue}${
                                    target?.achieveByDate
                                      ? ', by ' +
                                        getLocalDate(target?.achieveByDate)
                                      : ' (no target date)'
                                  }`}</p>
                                ))
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PortfolioObjectivesTable;
