/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { useContext, useReducer } from 'react';
import { WizardStep } from './Wizard';

export type WizardState = {
  currentStepIndex: number;
  options: WizardOptions;
  requestPayload: {
    [key: string]: string | number | null | undefined | [] | any;
  };
  actions?: Array<{
    step: number;
    nextButtonAction: (() => void) | null;
    previousButtonAction: (() => void) | null;
  }>;
  steps: Array<WizardStep>;
};

export type WizardOptions = {
  title: string;
  // Index of steps where the user does not have to fill in the step,
  // but can click on a "Skip for now" button
  skippableSteps: Array<number>; // TODO: Can we remove this?

  // we can add any flags in the options here to read in our steps or anywhere else
  // to display something different under different conditions... here we say it is
  // useful to know if the user entered this wizard to edit some existing data or
  // to (do something else, like create new data), because the messages we show might
  // be different
  isEditing: boolean;
  quickSave: boolean;

  // TODO: Start the wizard at this step number - can we remove this?
  startAtStep: number;

  editObject: any | null;
  parentObject: any | null;
  topLevelEntity: any | null;
};

export const initialNullWizardOptions: WizardOptions = {
  title: '',
  skippableSteps: [],
  isEditing: false,
  quickSave: false,
  startAtStep: 0,
  editObject: null,
  parentObject: null,
  topLevelEntity: null,
};

const initialNullState: WizardState = {
  currentStepIndex: -1,
  requestPayload: {},
  steps: [],
  options: initialNullWizardOptions,
};

type JumpToStepAction = {
  type: 'JUMP_TO_STEP';
  stepIndex: number;
};

type NextStepAction = {
  type: 'NEXT_STEP';
};

type PreviousStepAction = {
  type: 'PREVIOUS_STEP';
};

type WriteToRequestPayloadAction = {
  type: 'WRITE_TO_REQUEST_PAYLOAD';
  key: string;
  value: any;
};

type WriteToStepValidationAction = {
  type: 'WRITE_TO_STEP_VALIDATION';
  value: boolean;
  key: number;
};

type SetStepActionsAction = {
  type: 'SET_STEP_ACTIONS';
  step: number;
  nextButtonAction: (() => void) | null;
  previousButtonAction: (() => void) | null;
};

export type WizardAction =
  | JumpToStepAction
  | NextStepAction
  | PreviousStepAction
  | WriteToRequestPayloadAction
  | WriteToStepValidationAction
  | SetStepActionsAction;

type WizardContextState = {
  dispatch: React.Dispatch<WizardAction>;
  state: WizardState;
};

export const WizardContext = React.createContext<WizardContextState>({
  dispatch: () => null,
  state: initialNullState,
});

const reducer = (state: WizardState, action: WizardAction) => {
  switch (action.type) {
    case 'JUMP_TO_STEP':
      return {
        ...state,
        currentStepIndex: action.stepIndex,
      };
    case 'NEXT_STEP':
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
      };
    case 'PREVIOUS_STEP':
      return {
        ...state,
        currentStepIndex: state.currentStepIndex - 1,
      };
    case 'SET_STEP_ACTIONS': {
      const currentActions = state.actions || [];
      const newAction = {
        step: action.step,
        nextButtonAction: action.nextButtonAction,
        previousButtonAction: action.previousButtonAction,
      };
      const i = currentActions.findIndex(
        _action => _action.step === newAction.step
      );
      if (i > -1) currentActions[i] = newAction;
      else currentActions.push(newAction);
      return {
        ...state,
        actions: currentActions,
      };
    }

    case 'WRITE_TO_REQUEST_PAYLOAD':
      return {
        ...state,
        requestPayload: {
          ...state.requestPayload,
          [action.key]: action.value,
        },
      };
    case 'WRITE_TO_STEP_VALIDATION':
      // eslint-disable-next-line no-case-declarations
      const stepsArray = [...state.steps];
      stepsArray[action.key].valid = action.value;
      return {
        ...state,
        steps: stepsArray,
      };
    default:
      return state;
  }
};

type Props = {
  children: Array<React.ReactElement> | React.ReactElement;
  initialState: Partial<WizardState>;
};

export function WizardContextProvider({
  children,
  initialState,
}: Props): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, {
    ...initialNullState,
    ...initialState,
  });

  return (
    <WizardContext.Provider value={{ dispatch, state }}>
      {children}
    </WizardContext.Provider>
  );
}

export function useWizardOptions(): WizardOptions {
  const { state } = useContext(WizardContext);
  return state.options;
}
