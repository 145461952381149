/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import { PortfolioPageContext } from '../PortfolioPageContext';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import DetailsPanel from '../../../common/DetailsPanel';
import { enumTranslates } from '../../../common/i18n/translate';
import {
  ChangeType,
  EntityType,
  Program,
  Project,
  ProjectMeasure,
  ProjectPriority,
  ProjectStage,
  ProjectUpdateConfidence,
} from '../../../api/index';
import DashboardCard from './DashboardCard';
import EmptyState from '../../../common/layout/EmptyState';
import ProgramDetailsContentCard from '../../program/Details/ProgramDetailsContentCard';
import ReactApexChart from 'react-apexcharts';
import StrategicAttentionPanel from './StrategicAttentionPanel';
import DetailsPanelSectionDivider from '../../../common/DetailsPanelSectionDivider';
import {
  capitaliseFirstLetter,
  getLatestProjectUpdate,
} from '../../../common/utils';

function ExecDashTab(): React.ReactElement {
  const { state: portfolioPageState } = useContext(PortfolioPageContext);

  // TODO: Remove this if the toggle isn't necessary
  const [showProjectCounts] = useState(true);

  // Get data
  const portfolio = portfolioPageState.selectedPortfolio;
  const programs: Program[] = portfolio?.programs ? portfolio?.programs : [];
  const allProjects: Project[] = [];
  if (programs && programs.length > 0) {
    programs?.forEach(program =>
      program.projects?.forEach(project => allProjects.push(project))
    );
  }

  const inflightProjects = allProjects.filter(
    project => project.stage === ProjectStage.Inflight
  );

  const inflightProjectBenefits: ProjectMeasure[] = [];
  inflightProjects.forEach(project =>
    project.measures?.forEach(measure => {
      inflightProjectBenefits.push(measure);
    })
  );

  // Charts
  type donutType =
    | 'STRATEGIC_PILLAR'
    | 'CHANGE_DELIVERY'
    | 'PROJECT_RAG'
    | 'PROJECT_PRIORITY'
    | 'FUNCTION'
    | 'STAGE';

  function getProjectBudgetTotal(projects: Array<Project>): number {
    let result = 0;
    projects.forEach(
      project => (result += project.budget ? project.budget : 0)
    );
    return result;
  }

  function countProjectsByStrategicPillar(
    projects: Array<Project> | undefined,
    checkId: string,
    showProjectCounts: boolean
  ): number {
    let count = 0;

    if (projects != undefined) {
      if (showProjectCounts) {
        programs.forEach(program =>
          program.primaryStrategicThemeId == checkId
            ? (count += projects?.filter(
                project => project.program?.id == program.id
              ).length)
            : (count += 0)
        );
      } else {
        programs.forEach(program =>
          program.primaryStrategicThemeId == checkId
            ? (count += getProjectBudgetTotal(
                projects?.filter(project => project.program?.id == program.id)
              ))
            : (count += 0)
        );
      }
    }
    return count;
  }

  function countProjectsByChangeType(
    projects: Array<Project> | undefined,
    checkType: string,
    showProjectCounts: boolean
  ): number {
    let count = 0;

    if (projects != undefined) {
      if (showProjectCounts) {
        programs.forEach(program =>
          program.changeType == checkType
            ? (count += projects?.filter(
                project => project.program?.id == program.id
              ).length)
            : (count += 0)
        );
      } else {
        programs.forEach(program =>
          program.changeType == checkType
            ? (count += getProjectBudgetTotal(
                projects?.filter(project => project.program?.id == program.id)
              ))
            : (count += 0)
        );
      }
    }
    return count;
  }

  function countProjectsByRag(
    projects: Array<Project> | undefined,
    checkStatus: ProjectUpdateConfidence | null,
    showProjectCounts: boolean
  ): number {
    let count = 0;

    if (projects != undefined) {
      if (showProjectCounts) {
        count = projects.filter(project => {
          const update = getLatestProjectUpdate(project);

          return checkStatus === null
            ? update === null || update.confidenceRating === undefined
            : update?.confidenceRating == checkStatus;
        }).length;
      } else {
        count = getProjectBudgetTotal(
          projects.filter(project => {
            const update = getLatestProjectUpdate(project);

            return checkStatus === null
              ? update === null || update.confidenceRating === undefined
              : update?.confidenceRating == checkStatus;
          })
        );
      }
    }

    return count;
  }

  function countProjectsByPriority(
    projects: Array<Project> | undefined,
    checkPriority: string,
    showProjectCounts: boolean
  ): number {
    let count = 0;

    if (projects != undefined) {
      if (showProjectCounts) {
        count = projects.filter(
          project => project.priority == checkPriority
        ).length;
      } else {
        count = getProjectBudgetTotal(
          projects.filter(project => project.priority == checkPriority)
        );
      }
    }

    return count;
  }

  function countProjectsByStage(
    projects: Array<Project> | undefined,
    checkStage: string,
    showProjectCounts: boolean
  ): number {
    let count = 0;

    if (projects != undefined) {
      if (showProjectCounts) {
        count = projects.filter(project => project.stage == checkStage).length;
      } else {
        count = getProjectBudgetTotal(
          projects.filter(project => project.priority == checkStage)
        );
      }
    }

    return count;
  }

  function countProjectsByFunction(
    projects: Array<Project> | undefined,
    checkFunction: string,
    showProjectCounts: boolean
  ): number {
    let count = 0;

    if (projects != undefined) {
      if (showProjectCounts) {
        count = projects.filter(
          project => project.function == checkFunction
        ).length;
      } else {
        count = getProjectBudgetTotal(
          projects.filter(project => project.function == checkFunction)
        );
      }
    }

    return count;
  }

  function getProjectsByFunctionData(
    projects: Array<Project> | undefined
  ): Array<{
    name: string;
    value: number;
  }> {
    const result: Array<{ name: string; value: number }> = [];

    if (projects === undefined) {
      return result;
    } else {
      // Get list of functions
      const projectFunctions: Array<string> = [];

      projects.forEach(project => {
        if (project.function) {
          projectFunctions.push(project.function);
        }
      });

      // Make unique list of functions
      const unique = projectFunctions.filter((v, i, a) => a.indexOf(v) === i);

      // Count projects under each function
      const uniqueCounts: Array<number> = new Array(unique.length);
      unique.forEach(
        (projectFunction, index) =>
          (uniqueCounts[index] = countProjectsByFunction(
            projects,
            projectFunction,
            showProjectCounts
          ))
      );

      // Combine data
      unique.forEach(
        (projectFunction, index) =>
          (result[index] = {
            name: projectFunction,
            value: uniqueCounts[index],
          })
      );

      // Return top 5 functions
      const sortedResult = result.sort((a, b) => b.value - a.value).slice(0, 5);

      return sortedResult;
    }
  }

  function countLegendLabels() {
    const pillars = getDonutData('STRATEGIC_PILLAR').labels.length;
    const delivery = getDonutData('CHANGE_DELIVERY').labels.length;
    const priority = getDonutData('PROJECT_PRIORITY').labels.length;
    const functionChart = getDonutData('FUNCTION').labels.length;

    return Math.max(pillars, delivery, priority, functionChart);
  }
  function getDonutData(chartType: donutType): {
    values: Array<number>;
    labels: Array<string>;
    colours: Array<string>;
  } {
    // Initialise
    let chartData: Array<number> = [];
    let chartLabels: Array<string> = [];
    let chartColours: Array<string> = [
      '#000',
      '#333',
      '#666',
      '#999',
      '#ccc',
      '#fff',
    ];

    if (chartType == 'STRATEGIC_PILLAR') {
      const pillars = portfolioPageState.selectedPortfolio?.themes;
      pillars?.forEach(pillar => {
        chartData.push(
          countProjectsByStrategicPillar(
            inflightProjects,
            pillar.id,
            showProjectCounts
          )
        );
        chartLabels.push(pillar.name);
      });
    } else if (chartType == 'CHANGE_DELIVERY') {
      chartData = [
        countProjectsByChangeType(
          inflightProjects,
          ChangeType.Investment,
          showProjectCounts
        ),
        countProjectsByChangeType(
          inflightProjects,
          ChangeType.Bau,
          showProjectCounts
        ),
      ];
      chartLabels = [
        enumTranslates[ChangeType.Investment],
        enumTranslates[ChangeType.Bau],
      ];
      chartColours = ['#67737d', '#011627'];
    } else if (chartType == 'PROJECT_RAG') {
      chartData = [
        countProjectsByRag(
          inflightProjects,
          ProjectUpdateConfidence.High,
          showProjectCounts
        ),
        countProjectsByRag(
          inflightProjects,
          ProjectUpdateConfidence.Medium,
          showProjectCounts
        ),
        countProjectsByRag(
          inflightProjects,
          ProjectUpdateConfidence.Low,
          showProjectCounts
        ),
        countProjectsByRag(inflightProjects, null, showProjectCounts),
      ];
      chartLabels = [
        enumTranslates[ProjectUpdateConfidence.High],
        enumTranslates[ProjectUpdateConfidence.Medium],
        enumTranslates[ProjectUpdateConfidence.Low],
        `Not rated`,
      ];
      chartColours = ['#10b981', '#fbbf24', '#ef4444', '#cbd5e1'];
    } else if (chartType == 'PROJECT_PRIORITY') {
      chartData = [
        countProjectsByPriority(
          inflightProjects,
          ProjectPriority.Tier1,
          showProjectCounts
        ),
        countProjectsByPriority(
          inflightProjects,
          ProjectPriority.Tier2,
          showProjectCounts
        ),
        countProjectsByPriority(
          inflightProjects,
          ProjectPriority.Tier3,
          showProjectCounts
        ),
      ];
      chartLabels = [
        enumTranslates[ProjectPriority.Tier1],
        enumTranslates[ProjectPriority.Tier2],
        enumTranslates[ProjectPriority.Tier3],
      ];
      chartColours = ['#FBBF24', '#9CA3AF', '#B45309'];
    } else if (chartType == 'FUNCTION') {
      const rawData = getProjectsByFunctionData(inflightProjects);
      rawData.forEach(item => {
        chartData.push(item.value);
        chartLabels.push(item.name);
      });
      chartColours = ['#7F1D1D', '#B91C1C', '#EF4444', '#FCA5A5', '#FEE2E2'];
    } else if (chartType == 'STAGE') {
      chartData = [
        countProjectsByStage(
          allProjects,
          ProjectStage.Inflight,
          showProjectCounts
        ),
        countProjectsByStage(
          allProjects,
          ProjectStage.Inplanning,
          showProjectCounts
        ),
        countProjectsByStage(
          allProjects,
          ProjectStage.Completed,
          showProjectCounts
        ),
        countProjectsByStage(
          allProjects,
          ProjectStage.Stopped,
          showProjectCounts
        ),
      ];
      chartLabels = [
        enumTranslates[ProjectStage.Inflight],
        enumTranslates[ProjectStage.Inplanning],
        enumTranslates[ProjectStage.Completed],
        enumTranslates[ProjectStage.Stopped],
      ];
      // chartColours = ['#0db7bd', '#28d4d7', '#1E40AF', '#94A3B8'];
    }
    return { values: chartData, labels: chartLabels, colours: chartColours };
  }

  function isMonochrome(chartType: donutType) {
    if (
      chartType == 'STRATEGIC_PILLAR' ||
      chartType == 'FUNCTION' ||
      chartType == 'STAGE'
    ) {
      return true;
    } else return false;
  }

  function getMonochromeColour(chartType: donutType) {
    if (chartType == 'STRATEGIC_PILLAR') {
      return '#4F46E5';
    } else if (chartType == 'FUNCTION') {
      return '#DC2626';
    } else if (chartType == 'STAGE') {
      return '#0b939d';
    } else return '#000000';
  }

  function projectBudgetChartData(chartType: donutType) {
    return {
      series: getDonutData(chartType).values,
      options: {
        labels: getDonutData(chartType).labels,
        colors: getDonutData(chartType).colours,
        theme: {
          monochrome: {
            enabled: isMonochrome(chartType),
            color: getMonochromeColour(chartType),
            shadeTo: 'light',
            shadeIntensity: 0.8,
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: number) {
            return val.toFixed(0) + '%';
          },
          style: {
            fontSize: '14px',
            fontFamily: 'Arial, sans-serif',
            fontWeight: 'bold',
          },
        },
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            offsetY: countLegendLabels() * 20,
            donut: {
              size: '70%',
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: function (val: string): string {
                    const output = `${showProjectCounts ? '' : '$'}${parseInt(
                      val
                    ).toLocaleString()}`;
                    return output;
                  },
                },
                total: {
                  show: true,
                  formatter: function (val: any): string {
                    const values: number[] = val.config.series;
                    const total = values.reduce((a, b) => a + b);
                    const output = `${
                      showProjectCounts ? '' : '$'
                    }${total.toLocaleString()}`;
                    return output;
                  },
                },
              },
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          floating: true,
          onItemHover: {
            highlightDataSeries: false,
          },
        },
      },
    };
  }

  function projectsWithoutFunctions() {
    return inflightProjects?.filter(
      project =>
        project.function === undefined ||
        project.function === null ||
        project.function.length === 0
    );
  }

  const thingsRightTitle = (
    <DetailsPanelHeader
      title={`Are we doing the things right?`}
      // description={`Indicators that we're managing well enough to hit our ${translate(
      //   strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
      //   'Strategic Objectives'
      // ).toLowerCase()}`}
    />
  );

  const thingsRightContent =
    portfolio && programs.length > 0 ? (
      <div className="space-y-6">
        {programs.length > 0 && (
          <div>
            <DetailsPanelSectionDivider
              title={`${capitaliseFirstLetter(
                enumTranslates[EntityType.Program]
              )}s`}
            />
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-between gap-4">
              <div className={`col-span-1`}>
                <DashboardCard
                  type="PROGRAMS_LOW_CONFIDENCE"
                  portfolio={portfolio}
                  programs={programs}
                  projects={inflightProjects}
                />
              </div>

              <div className={`col-span-1`}>
                <DashboardCard
                  type="PROGRAM_BENEFITS_NOT_LINKED"
                  portfolio={portfolio}
                  programs={programs}
                  projects={inflightProjects}
                />
              </div>

              <div className={`col-span-1`}>
                <DashboardCard
                  type="PROGRAMS_NO_BENEFIT"
                  portfolio={portfolio}
                  programs={programs}
                  projects={inflightProjects}
                />
              </div>
            </div>
          </div>
        )}

        {inflightProjects.length > 0 && (
          <div>
            <DetailsPanelSectionDivider
              title={`${capitaliseFirstLetter(
                enumTranslates[EntityType.Project]
              )}s`}
            />
            <div className="grid grid-cols-1 lg:grid-cols-3 justify-between gap-4">
              <div className={`col-span-1`}>
                <DashboardCard
                  type="PROJECTS_LOW_CONFIDENCE"
                  portfolio={portfolio}
                  programs={programs}
                  projects={inflightProjects}
                />
              </div>

              <div className={`col-span-1`}>
                <DashboardCard
                  type="PROJECT_BENEFITS_NOT_LINKED"
                  portfolio={portfolio}
                  programs={programs}
                  projects={inflightProjects}
                />
              </div>

              <div className={`col-span-1`}>
                <DashboardCard
                  type="PROJECTS_NO_BENEFIT"
                  portfolio={portfolio}
                  programs={programs}
                  projects={inflightProjects}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <EmptyState type={`${enumTranslates[EntityType.Program]}s`} />
    );

  const statsTitle = <DetailsPanelHeader title={`What are we doing?`} />;

  const statsContent =
    programs.length == 0 ? (
      <EmptyState type={`${enumTranslates[EntityType.Program]}s`} />
    ) : inflightProjects.length == 0 ? (
      <EmptyState
        type={`${enumTranslates[EntityType.Project]}s`}
        secondaryText={`You'll see the breakdown here when we have an in-flight project.`}
      />
    ) : (
      portfolio && (
        <div className="space-y-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            {/* grid grid-cols-1 lg:grid-cols-2  */}
            <div className="col-span-1">
              <DashboardCard
                type="INFLIGHT_PROJECT_RAG"
                portfolio={portfolio}
                programs={programs}
                projects={inflightProjects}
                chart={
                  <div id="projects-by-stage" className="">
                    {/* <img src={prioritiseChart} className="h-64 mx-auto"></img> */}
                    <ReactApexChart
                      options={
                        projectBudgetChartData('PROJECT_RAG')
                          .options as ApexCharts.ApexOptions
                      }
                      series={projectBudgetChartData('PROJECT_RAG').series}
                      type="donut"
                      height={250}
                    />
                  </div>
                }
              />
            </div>
            <div className="col-span-1">
              <DashboardCard
                type="INFLIGHT_PROJECT_COUNT"
                portfolio={portfolio}
                programs={programs}
                projects={inflightProjects}
                chart={
                  <div id="projects-by-stage" className="">
                    {/* <img src={prioritiseChart} className="h-64 mx-auto"></img> */}
                    <ReactApexChart
                      options={
                        projectBudgetChartData('STAGE')
                          .options as ApexCharts.ApexOptions
                      }
                      series={projectBudgetChartData('STAGE').series}
                      type="donut"
                      height={250}
                    />
                  </div>
                }
              />
            </div>
            <div className="col-span-1">
              <DashboardCard
                type="INFLIGHT_PROJECTS_BY_PRIORITY"
                portfolio={portfolio}
                programs={programs}
                projects={inflightProjects}
                chart={
                  <div id="project-budgets" className="">
                    <ReactApexChart
                      options={
                        projectBudgetChartData('PROJECT_PRIORITY')
                          .options as ApexCharts.ApexOptions
                      }
                      series={projectBudgetChartData('PROJECT_PRIORITY').series}
                      type="donut"
                      height={250}
                    />
                  </div>
                }
              />
            </div>
          </div>

          <div className="">
            <DashboardCard
              type="PORTFOLIO_BALANCE"
              portfolio={portfolio}
              programs={programs}
              projects={inflightProjects}
              chart={
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 -mx-6">
                  <ProgramDetailsContentCard title={`By Strategic Pillar`}>
                    <ReactApexChart
                      options={
                        projectBudgetChartData('STRATEGIC_PILLAR')
                          .options as ApexCharts.ApexOptions
                      }
                      series={projectBudgetChartData('STRATEGIC_PILLAR').series}
                      type="donut"
                      height={250}
                    />
                  </ProgramDetailsContentCard>

                  <ProgramDetailsContentCard title={`By Delivery Approach`}>
                    <ReactApexChart
                      options={
                        projectBudgetChartData('CHANGE_DELIVERY')
                          .options as ApexCharts.ApexOptions
                      }
                      series={projectBudgetChartData('CHANGE_DELIVERY').series}
                      type="donut"
                      height={250}
                    />
                  </ProgramDetailsContentCard>

                  <ProgramDetailsContentCard title={`By Function`}>
                    <div className="">
                      <ReactApexChart
                        options={
                          projectBudgetChartData('FUNCTION')
                            .options as ApexCharts.ApexOptions
                        }
                        series={projectBudgetChartData('FUNCTION').series}
                        type="donut"
                        height={250}
                      />
                      {projectBudgetChartData('FUNCTION').series.length <
                      inflightProjects.length ? (
                        <div className="flex px-2 pt-3 text-center items-center align-middle justify-center">
                          <span className="text-xs text-gray-400 italic">
                            {`Note: ${projectsWithoutFunctions()?.length} ${
                              enumTranslates[EntityType.Project]
                            }s don't have a function`}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </ProgramDetailsContentCard>
                </div>
              }
            />
          </div>
        </div>
      )
    );

  return (
    <div className="flex flex-col gap-y-6">
      {/* What needs attention */}
      <StrategicAttentionPanel />

      {/* What we're doing */}
      <DetailsPanel
        headerLeft={statsTitle}
        headerRight={null}
        content={statsContent}
      />

      {/* Doing things right */}
      {programs.length > 0 && (
        <DetailsPanel
          headerLeft={thingsRightTitle}
          headerRight={null}
          content={thingsRightContent}
        />
      )}
    </div>
  );
}

export default ExecDashTab;
