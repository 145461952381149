import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  // createHttpLink,
  HttpLink,
} from '@apollo/client';
import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { Auth } from 'aws-amplify';

import { aws_config } from '../amplify.conf';

const url = aws_config.aws_appsync_graphqlEndpoint || '';
const region = aws_config.Auth.region || '';
const auth: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: async () =>
    (await Auth.currentSession()).getIdToken().getJwtToken(),
};

const apolloApiLink = createAuthLink({
  auth: {
    type: 'API_KEY',
    apiKey: process.env.REACT_APP_API_KEY as string,
  },
  url,
  region,
});

const apolloLinkAuth = createAuthLink({
  auth,
  url,
  region,
});

const linkSplit = ApolloLink.split(
  operation => {
    return (
      operation.operationName === `checkReportPermission` ||
      operation.operationName === `getOtpReport`
    );
  },
  apolloApiLink,
  apolloLinkAuth
);

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  linkSplit,
  createSubscriptionHandshakeLink({ url, auth, region }, httpLink),
  // createHttpLink({ uri: url }),
]);
const AppSyncClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

export default AppSyncClient;
