import React from 'react';
import { ToggleLeft, ToggleRight } from 'phosphor-react';

type Props = {
  label: string;
  stateOn: boolean;
  setStateOn: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
};

export default function ToggleButton({
  label,
  stateOn,
  setStateOn,
  disabled,
}: Props): React.ReactElement {
  return (
    <button
      type="button"
      className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      onClick={() => setStateOn(!stateOn)}
      disabled={disabled}
    >
      {stateOn ? (
        <ToggleRight
          className="h-5 w-5 mr-2 text-primary-500 shrink-0"
          weight="fill"
        />
      ) : (
        <ToggleLeft
          className="h-5 w-5 mr-2 text-gray-500 shrink-0"
          weight="fill"
        />
      )}
      <span className="whitespace-nowrap">{label}</span>
    </button>
  );
}
