/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useUpdateMyWorkspaceMutation,
  EntityType,
  useCreateMyWorkspaceMutation,
  UpdateMyWorkspaceMutation,
  CreateMyWorkspaceMutation,
} from '../../../api/index';
import { ApolloError, FetchResult } from '@apollo/client';
import { WorkspacePageContext } from '../../../components/workspace/WorkspacePageContext';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { enumTranslates } from '../../i18n/translate';

const useEdit = (): {
  onSubmit: (wizardData: { id: any; name: any }) => Promise<void>;
  response: {
    response:
      | FetchResult<
          UpdateMyWorkspaceMutation,
          Record<string, unknown>,
          Record<string, unknown>
        >
      | undefined;
    loading: boolean;
  };
} => {
  const [updateWorkspaceMutation, { loading }] = useUpdateMyWorkspaceMutation();

  const { dispatch } = useContext(WorkspacePageContext);
  const [response, setResponse] =
    useState<
      FetchResult<
        UpdateMyWorkspaceMutation,
        Record<string, unknown>,
        Record<string, unknown>
      >
    >();
  const onSubmit = useCallback(
    async (wizardData: { id: any; name: any }) => {
      try {
        const updateWorkspaceResp = await updateWorkspaceMutation({
          variables: {
            input: {
              id: wizardData.id,
              name: wizardData.name,
            },
          },
        });

        setResponse(updateWorkspaceResp);
        if (updateWorkspaceResp.data?.updateMyWorkspace) {
          dispatch({
            type: 'LOAD_WORKSPACE',
            loadWorkspace: true,
          });
          toast.info(`Updated ${enumTranslates[EntityType.Workspace]}`);
        }
      } catch (err) {
        const error = err as ApolloError;
        toast.error(error.message);
      }
    },
    [dispatch, updateWorkspaceMutation]
  );

  return { onSubmit, response: { response, loading } };
};

const useCreate = (): {
  onSubmit: (wizardData: {
    name: string;
    [key: string]: unknown;
  }) => Promise<void>;
  response: {
    response:
      | FetchResult<
          CreateMyWorkspaceMutation,
          Record<string, unknown>,
          Record<string, unknown>
        >
      | undefined;
    loading: boolean;
  };
} => {
  const [createWorkspaceMutation, { loading }] = useCreateMyWorkspaceMutation();
  const { dispatch } = useContext(WorkspacePageContext);
  const navigate = useNavigate();

  const { state: workspacePageState } = useContext(WorkspacePageContext);
  const { user } = useContext(UserContext);
  const [response, setResponse] =
    useState<
      FetchResult<
        CreateMyWorkspaceMutation,
        Record<string, unknown>,
        Record<string, unknown>
      >
    >();
  const onSubmit = useCallback(
    async (wizardData: { name: string; [key: string]: unknown }) => {
      try {
        const createWorkspaceResp = await createWorkspaceMutation({
          variables: {
            name: wizardData.name,
          },
        });

        setResponse(createWorkspaceResp);
        if (createWorkspaceResp.data?.createMyWorkspace) {
          toast.success(`Created new ${enumTranslates[EntityType.Workspace]}`);
          // navigate(
          //   `${WORKSPACE}/${createWorkspaceResp.data.createWorkspace.id}`
          // );
        }
      } catch (err) {
        const error = err as ApolloError;
        toast.error(error.message);
      }
    },
    [createWorkspaceMutation]
  );

  return { onSubmit, response: { response, loading } };
};

export const WORKSPACE_DETAILS = {
  useCreate,
  useEdit,
};

// FAKE

export const PORTFOLIO_DETAILS = {
  useCreate,
  useEdit,
};
export const PROGRAM_DETAILS = {
  useCreate,
  useEdit,
};
export const PROGRAM_QUICK_ADD = {
  useCreate,
  useEdit,
};
export const PORTFOLIO_UPDATE = {
  useCreate,
  useEdit,
};
export const PROGRAM_UPDATE = {
  useCreate,
  useEdit,
};
export const PROJECT_DETAILS = {
  useCreate,
  useEdit,
};
export const PROJECT_QUICK_ADD = {
  useCreate,
  useEdit,
};
export const PROJECT_UPDATE = {
  useCreate,
  useEdit,
};
export const PROJECT_ASK = {
  useCreate,
  useEdit,
};
