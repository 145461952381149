import React, { useContext, useEffect, useMemo } from 'react';
import { Tab } from '@headlessui/react';

import HeaderWithTabsLayout from '../../common/layout/HeaderWithTabsLayout';
import { PageTab } from '../../common/PageTab';

import PortfolioListingTab from './PortfolioContentsTab';
import PortfolioPerformanceTab from './PerformanceTab/PortfolioPerformanceTab';
import AdminTab from './AdminTab/AdminTab';

import { ulid } from 'ulid';
import {
  EntityType,
  useGetAllProjectsLazyQuery,
  useGetOpenInvitationsByEntityLazyQuery,
  useGetPortfolioLazyQuery,
  useGetPortfoliosLazyQuery,
  useGetPortfolioUpdatesLazyQuery,
  useGetProgramsLazyQuery,
  useSetLastViewedWorkspaceMutation,
} from '../../api/index';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { PortfolioPageContext } from './PortfolioPageContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ProgramPageContext } from '../program/ProgramPageContext';
import { ProjectPageContext } from '../project/ProjectPageContext';
import ExecDashTab from './ExecutiveDashboard/DashboardTab';
import { capitaliseFirstLetter } from '../../common/utils';
import { enumTranslates } from '../../common/i18n/translate';
import { NOT_FOUND } from '../../common/routes';

function PortfolioHomePage(): React.ReactElement {
  const { state: workspacePageState, dispatch: workspacePageDispatch } =
    useContext(WorkspacePageContext);
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const navigate = useNavigate();
  const { dispatch: portfolioDispatch, state: portfolioPageState } =
    useContext(PortfolioPageContext);
  const { dispatch: programDispatch } = useContext(ProgramPageContext);
  const { dispatch: projectDispatch } = useContext(ProjectPageContext);
  const [setLastViewedWorkspace] = useSetLastViewedWorkspaceMutation();

  const [
    GetPortfoliosQuery,
    {
      data: dataPortfolios,
      loading: loadingPortfolios,
      error: errorPortfolios,
    },
  ] = useGetPortfoliosLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    GetPortfolioQuery,
    { data: dataPortfolio, loading: loadingPortfolio, error: errorPortfolio },
  ] = useGetPortfolioLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [GetProgramsQuery, { data: dataPrograms, loading: loadingPrograms }] =
    useGetProgramsLazyQuery({
      fetchPolicy: 'no-cache',
    });

  const [
    GetAllProjectsQuery,
    { data: dataProjects, loading: loadingProjects },
  ] = useGetAllProjectsLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [
    GetOpenInvitations,
    { data: dataInvitations, loading: loadingInvitations },
  ] = useGetOpenInvitationsByEntityLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [GetPortfolioUpdates, { data: dataUpdates, loading: loadingUpdates }] =
    useGetPortfolioUpdatesLazyQuery({
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    GetPortfolioQuery({
      variables: {
        portfolioId: portfolioId as string,
      },
    });
    GetProgramsQuery({
      variables: {
        portfolioId: portfolioId as string,
      },
    });
    GetOpenInvitations({
      variables: {
        entityId: portfolioId as string,
        limit: 25,
      },
    });
  }, [
    GetPortfolioQuery,
    portfolioId,
    GetProgramsQuery,
    portfolioPageState.loadProject,
    portfolioPageState.loadProgram,
    portfolioPageState.loadPortfolio,
    GetOpenInvitations,
  ]);

  useEffect(() => {
    GetPortfolioUpdates({
      variables: {
        portfolioId: portfolioId as string,
        limit: 20,
      },
    });
  }, [GetPortfolioUpdates, portfolioId, portfolioPageState.loadUpdate]);
  useEffect(() => {
    GetAllProjectsQuery();
  }, [GetAllProjectsQuery, portfolioPageState.loadProject]);

  useEffect(() => {
    if (errorPortfolio?.message) {
      navigate(NOT_FOUND);
    }
  }, [errorPortfolio, navigate]);

  useEffect(() => {
    if (dataPortfolio?.getPortfolio) {
      GetPortfoliosQuery({
        variables: {
          workspaceId: dataPortfolio?.getPortfolio?.parentId,
        },
      });
    }
  }, [GetPortfoliosQuery, dataPortfolio?.getPortfolio]);

  useEffect(() => {
    if (
      !loadingPortfolios &&
      !loadingPortfolio &&
      !loadingPrograms &&
      !loadingProjects &&
      !loadingUpdates
    ) {
      if (
        dataPortfolios?.getPortfolios &&
        dataPortfolio?.getPortfolio &&
        dataPrograms?.getPrograms &&
        dataProjects?.getAllProjects &&
        dataUpdates?.getPortfolioUpdates?.items &&
        dataInvitations?.getOpenInvitationsByEntity
      ) {
        portfolioDispatch({
          type: 'SET_PORTFOLIOS',
          portfolios: dataPortfolios.getPortfolios,
        });
        portfolioDispatch({
          type: 'SET_SELECTED_PORTFOLIO',
          portfolio: dataPortfolio.getPortfolio,
        });
        portfolioDispatch({
          type: 'SET_UPDATES',
          portfolioUpdates: dataUpdates.getPortfolioUpdates.items,
        });
        programDispatch({
          type: 'SET_PROGRAMS',
          programs: dataPrograms.getPrograms,
        });
        if (dataInvitations?.getOpenInvitationsByEntity?.items) {
          portfolioDispatch({
            type: 'SET_INVITATIONS',
            invitations: Object.assign(
              dataInvitations?.getOpenInvitationsByEntity?.items
            ),
          });
        }
        projectDispatch({
          type: 'SET_USERS_PROJECTS',
          usersProjects: dataProjects.getAllProjects,
        });
        const workspace = workspacePageState.workspaces.find(
          w => w.id === dataPortfolio.getPortfolio?.parentId
        );
        if (
          workspace &&
          workspace.id !== workspacePageState.selectedWorkspace?.id
        ) {
          setLastViewedWorkspace({
            variables: {
              workspaceId: workspace.id,
            },
          });
          workspacePageDispatch({
            type: 'SET_SELECTED_WORKSPACE',
            workspace: workspace,
          });
        }
      }
    }
  }, [
    dataPortfolios,
    dataUpdates,
    dataInvitations,
    loadingPortfolios,
    portfolioDispatch,
    GetPortfolioQuery,
    loadingPortfolio,
    dataPortfolio,
    navigate,
    loadingPrograms,
    loadingProjects,
    loadingUpdates,
    dataPrograms,
    dataProjects,
    programDispatch,
    projectDispatch,
    workspacePageState.workspaces,
    workspacePageState.selectedWorkspace,
    setLastViewedWorkspace,
    workspacePageDispatch,
  ]);

  const tabs = [
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <>
              <PageTab selected={selected} title={`Dashboard`} />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <ExecDashTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#dashboard',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`${capitaliseFirstLetter(
                enumTranslates[EntityType.Portfolio]
              )} Contents`}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <PortfolioListingTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#contents',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab selected={selected} title={`Performance Updates`} />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <PortfolioPerformanceTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#performance',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Setup`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <AdminTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#setup',
    },
  ];

  const showErrorMessage = useMemo(() => {
    return errorPortfolios != null;
  }, [errorPortfolios]);

  const showLoadingSpinner = useMemo(() => {
    return (
      !showErrorMessage &&
      (loadingPortfolios ||
        loadingPrograms ||
        loadingProjects ||
        loadingInvitations ||
        loadingUpdates ||
        //dataPortfolios?.getPortfolios?.items?.length === 0 ||
        portfolioPageState.selectedPortfolio == null)
    );
  }, [
    loadingInvitations,
    loadingPortfolios,
    loadingUpdates,
    loadingPrograms,
    loadingProjects,
    portfolioPageState.selectedPortfolio,
    showErrorMessage,
  ]);

  return (
    <HeaderWithTabsLayout
      title={portfolioPageState.selectedPortfolio?.name || ''}
      entity={undefined}
      tabs={tabs}
      isLoading={loadingPortfolios || loadingPortfolio || showLoadingSpinner}
    />
  );
}

export default PortfolioHomePage;
