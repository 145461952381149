import RatingBar, {
  ProgressRating,
} from '../../waypoints/OutcomesPanel/RatingBar';

type Props = {
  itemId: string;
  rating: ProgressRating;
};

function ProgressTableCell({ itemId, rating }: Props) {
  return (
    <div className="inline-flex mr-1">
      <RatingBar
        defaultValue={rating}
        onClick={value => console.log(`Set item ${itemId} to ${value}`)}
      />
    </div>
  );
}

export default ProgressTableCell;
