import React from 'react';
import {
  capitaliseFirstLetter,
  getLocalDate,
  getRelativeTime,
} from '../../../common/utils';
import translate, { strings } from '../../../common/i18n/translate';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import ReactTooltip from 'react-tooltip';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import { Portfolio } from '../../../api/index';
import { ProgramUpdateExt } from '../ReportPage';
import { globalTooltipDelay } from '../../../common/constants';

function ProgramUpdates(props: {
  programUpdates: ProgramUpdateExt[];
  entity: Portfolio;
}): React.ReactElement {
  const tooltipDelay = globalTooltipDelay;

  const { programUpdates, entity } = props;
  const alignmentTitle = (
    <DetailsPanelHeader
      title={'Program Updates'}
      description={'How each program in this portfolio is tracking'}
    />
  );

  const performancePrograms = (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              {/* Alignment overview */}
              <table className="table min-w-full divide-y divide-gray-200">
                <thead className="table-header-group bg-gray-50">
                  {/* Header row */}
                  <tr className="table-row">
                    <th className="table-cell w-1/3 pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                      {translate(
                        strings.PORTFOLIO_STRATEGIC_PRIORITIES,
                        'Strategic Priorities'
                      )}
                    </th>

                    <th className="table-cell   pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                      Programs
                      <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                        Coordinated groups of projects delivering to a business
                        outcome
                      </p>
                    </th>
                  </tr>
                </thead>

                <tbody className="table-row-group bg-white divide-y divide-gray-200">
                  {/* Make a new row for each program */}
                  {entity?.themes?.map(theme => (
                    <tr className="table-row" key={theme.id}>
                      {/* Strategic Pillar */}
                      <td className="table-cell border-t border-gray-200 pl-4 pr-2 py-3 whitespace-normal align-top">
                        <div className="flex">
                          <div className="text-sm space-y-3">
                            {/* Pillar name */}
                            <div className="">
                              <span className="font-semibold">
                                {theme.name}
                              </span>
                              <p className="mt-1">{theme.description}</p>
                            </div>

                            {/* Portfolio Objectives */}
                            <div className="text-xs text-gray-500">
                              <span className="font-semibold">
                                {translate(
                                  strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                                  'Strategic Priorities'
                                )}
                              </span>
                              <ul className="mt-1 ml-2">
                                {entity?.objectives
                                  ?.filter(
                                    objective =>
                                      objective.strategicThemeId == theme.id
                                  )
                                  .map((objective, idx) => (
                                    <li
                                      key={idx}
                                      className="list-disc list-outside ml-3"
                                    >
                                      <p className="">
                                        {objective.description}
                                      </p>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </td>

                      <td className="table-cell border-t border-gray-200 pl-2 pr-4 py-3 whitespace-normal align-top">
                        {programUpdates
                          ?.filter(p => p.primaryStrategicThemeId === theme.id)
                          .map((programUpdate, idx) => (
                            <div
                              key={idx}
                              className="bg-gray-100 rounded p-3 mb-2 text-sm"
                            >
                              <div className="flex flex-row align-middle justify-between">
                                <p className="font-semibold hover:underline hover:text-primary-600">
                                  {programUpdate.programName}
                                </p>
                                <span data-tip data-for={programUpdate.id}>
                                  <ConfidenceBadge
                                    rating={programUpdate?.trend}
                                    reviewDate={programUpdate?.updateDate}
                                  />
                                </span>
                                <ReactTooltip
                                  id={programUpdate.id}
                                  place="top"
                                  // type="info"
                                  effect="solid"
                                  delayShow={tooltipDelay}
                                >
                                  <div className="text-sm">
                                    <p>
                                      Delivery confidence, as at{' '}
                                      {getLocalDate(programUpdate?.updateDate)}
                                    </p>
                                  </div>
                                </ReactTooltip>
                              </div>
                              <p className="mt-1">
                                {`Summary: ${
                                  programUpdate
                                    ? programUpdate?.remark
                                    : 'No program updates found'
                                }`}
                              </p>
                              <p className="mt-2 text-gray-500 text-xs">
                                {`Last update: ${
                                  programUpdate
                                    ? `${capitaliseFirstLetter(
                                        getRelativeTime(
                                          programUpdate?.updateDate
                                        )
                                      )} (${getLocalDate(
                                        programUpdate?.updateDate
                                      )})`
                                    : 'Never'
                                }`}
                              </p>
                            </div>
                          ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="flex flex-col gap-y-6">
      <DetailsPanel
        headerLeft={alignmentTitle}
        headerRight={null}
        content={performancePrograms}
      />
    </div>
  );
}

export default ProgramUpdates;
