import { Tab } from '@headlessui/react';
import React from 'react';
import { ulid } from 'ulid';

import HeaderWithTabsLayout from '../../common/layout/HeaderWithTabsLayout';
import { PageTab } from '../../common/PageTab';
import CompareScenario from './CompareScenario';
import { dummyScenarios } from './dummyData';

import Scenarios from './Scenarios';
import SetStrategy from './SetStrategy';
import WhatsIncluded from './WhatsIncluded';

function PrioritisationModule(): React.ReactElement {
  const scenarioData = dummyScenarios; // TODO: Get actual from database
  const [selectedScenarioId, setSelectedScenarioId] = React.useState(
    dummyScenarios[0].id
  );
  const selectedScenario = scenarioData.find(
    item => item.id === selectedScenarioId
  );

  const [selectedTabIndex, setSelectedIndex] = React.useState(0);
  const disableTabs = selectedScenarioId === '';

  const tabs = [
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab selected={selected} title={`Select Scenario`} />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <Scenarios
            scenarioList={scenarioData}
            selectedScenarioId={selectedScenarioId}
            setSelectedScenarioId={setSelectedScenarioId}
            setSelectedTabIndex={setSelectedIndex}
          />
        </Tab.Panel>
      ),
      hash: '#scenarios',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`1.${'\u00A0'}Set Strategy`}
              isDisabled={disableTabs}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <SetStrategy />
        </Tab.Panel>
      ),
      hash: '#strategy',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`2.${'\u00A0'}Choose What's${'\u00A0'}Included`}
              isDisabled={disableTabs}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <WhatsIncluded selectedScenario={selectedScenario} />
        </Tab.Panel>
      ),
      hash: '#inclusions',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`3.${'\u00A0'}Compare Implications`}
              isDisabled={disableTabs}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <CompareScenario selectedScenario={selectedScenario} />
        </Tab.Panel>
      ),
      hash: '#implications',
    },
  ];

  return (
    <HeaderWithTabsLayout
      title={`Prioritisation`}
      subtitle={`Selected scenario: ${
        selectedScenario && selectedScenario.name
      }`}
      tabs={tabs}
      isLoading={false}
    />
  );
}

export default PrioritisationModule;
