// import React, { Fragment, useState } from 'react'
import { Info } from 'phosphor-react';
import React, { useContext, useMemo } from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { ulid } from 'ulid';
import {
  EntityType,
  Group,
  PermissionType,
  WorkspaceInvitation,
  WorkspaceMember,
  WorkspaceRole,
} from '../../api/index';
import translate, {
  enumTranslates,
  reorderEnums,
  strings,
} from '../../common/i18n/translate';
import Tag from '../../common/Tag';
import {
  classNames,
  getPermissionDescriptions,
} from '../../common/utils';
import { UserContext } from '../../context/UserContext';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';

function WorkspaceMembersTab(): React.ReactElement {
  const { state: workspacePagestate } = useContext(WorkspacePageContext);
  const { user } = useContext(UserContext);
  const invites = workspacePagestate?.invitations as WorkspaceInvitation[];

  const roleOptions: Array<WorkspaceRole> = useMemo(() => {
    return reorderEnums(Object.values(WorkspaceRole));
  }, []);
  const groupOptions: Array<Group> = useMemo(() => {
    return reorderEnums(Object.values(Group));
  }, []);

  const permissionTooltipKey = ulid();

  const allUsersWithAccess =
    workspacePagestate?.selectedWorkspace &&
    workspacePagestate?.selectedWorkspace.members?.items;
  // Members with direct access should be a unique list
  const directAccessMembers = allUsersWithAccess?.filter(
    item => item.permissionType === PermissionType.Member
  );
  // Members with indirect access may be duplicated, so we'll use the unique list and subtract direct members
  const indirectAccessUsers = allUsersWithAccess?.filter(
    item => item.permissionType === PermissionType.Inherited
  );

  function getMembersByRole(role: WorkspaceRole | null): WorkspaceMember[] {
    const result = directAccessMembers
      ?.filter(item => item.role === role)
      .map(item => item as WorkspaceMember);

    return result || [];
  }

  function getInvitesByRole(role: WorkspaceRole): WorkspaceInvitation[] {
    const pendingInvites =
      invites?.filter(invite => invite.role === role) || [];

    return pendingInvites.length > 0 ? pendingInvites : [];
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {/* Updated table */}
          <div className="overflow-hidden border border-gray-200 rounded-lg">
            {/* Tailwind grid table style */}
            <table className="table min-w-full divide-y divide-gray-200">
              <thead className="table-header-group bg-gray-50">
                {/* Header row */}
                <React.Fragment>
                  <tr className="table-row">
                    <td className="table-cell pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role on {enumTranslates[EntityType.Workspace]}
                    </td>
                    <td className="table-cell pl-2 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </td>

                    <td className="table-cell pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <div className="flex items-center gap-x-2">
                        Permission level
                        {/* Tooltip explainer */}
                        <React.Fragment>
                          <button
                            type="button"
                            className="h-min disabled:opacity-50 inline-flex rounded-full text-gray-400 hover:text-blue-600 hover:shadow-sm"
                            data-tip
                            data-for={permissionTooltipKey}
                          >
                            <Info
                              weight="bold"
                              className="h-4 w-4 shrink-0"
                              aria-hidden="true"
                            />
                          </button>
                          <ReactTooltip
                            id={permissionTooltipKey}
                            place="right"
                            type="info"
                            effect="solid"
                          >
                            <div className="text-sm space-y-2 normal-case font-normal">
                              {groupOptions.map((group, idx) => (
                                <div key={idx}>
                                  <p>{enumTranslates[group]}</p>
                                  <ul className="list-disc list-outside ml-5">
                                    {getPermissionDescriptions(
                                      group,
                                      'workspace'
                                    ).map((item, idx2) => (
                                      <li key={idx2}>{item}</li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </div>
                          </ReactTooltip>
                        </React.Fragment>
                      </div>
                    </td>
                  </tr>
                </React.Fragment>
              </thead>

              {/* Table data */}
              <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
                {/* Direct members */}
                {roleOptions.map((role, index) => {
                  return (
                    <React.Fragment key={index}>
                      {getMembersByRole(role)?.length +
                        getInvitesByRole(role)?.length ===
                      0 ? (
                        <tr className="table-row">
                          {/* Roles */}
                          <td
                            className={
                              'table-cell border-t border-gray-200 pl-4 pr-2 py-3 whitespace-normal font-medium align-top'
                            }
                          >
                            {enumTranslates[role]}
                          </td>
                          {/* Members */}
                          {/* colSpan={100} */}
                          <td className="table-cell w-16 border-t border-gray-200 pl-2 pr-4 py-3 align-middle">
                            <div className="text-gray-500 italic">
                              No one in this role
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <React.Fragment>
                          {/* Create a row for each member */}
                          {getMembersByRole(role)?.map(
                            (member: WorkspaceMember, memberIndex: number) => {
                              const permission =
                                member.group;
                              return (
                                <tr key={memberIndex} className="table-row">
                                  {/* Roles */}
                                  <td
                                    rowSpan={getMembersByRole(role)?.length}
                                    className={classNames(
                                      memberIndex == 0
                                        ? 'border-t border-gray-200'
                                        : 'hidden',
                                      'table-cell pl-4 pr-2 py-3 whitespace-normal font-medium align-top'
                                    )}
                                  >
                                    {memberIndex === 0
                                      ? enumTranslates[role]
                                      : ''}
                                  </td>

                                  {/* Members */}
                                  <td className="table-cell border-t border-gray-200 pl-2 pr-2 py-3 align-middle">
                                    <div className="flex items-center">
                                      <Avatar
                                        name={
                                          member.user.firstName +
                                          ' ' +
                                          member.user.lastName
                                        }
                                        size="32"
                                        round="16px"
                                        color="#304057"
                                      />
                                      <span className="ml-3">
                                        <p className="inline-flex truncate text-sm font-medium text-gray-900 gap-x-2">
                                          {`${member.user.firstName} ${member.user.lastName}`}
                                          {member.user.id ===
                                            user?.attributes?.sub && (
                                            <Tag type="user" />
                                          )}
                                        </p>
                                        <p className="block truncate text-xs text-gray-400">
                                          {`${member.user.email}`}
                                        </p>
                                      </span>
                                    </div>
                                  </td>

                                  {/* Permission */}
                                  {/* <td className="table-cell border-t border-gray-200 pl-2 pr-4 py-3 align-middle">
                                    {permission ? (
                                      <React.Fragment>
                                        <p>{enumTranslates[permission]}</p>
                                        {message && message?.length > 0 && (
                                          <p className="text-xs text-gray-400">
                                            {message}
                                          </p>
                                        )}
                                      </React.Fragment>
                                    ) : (
                                      <p className="italic">
                                        No permission found
                                      </p>
                                    )}
                                  </td> */}
                                </tr>
                              );
                            }
                          )}

                          {/* Add rows for invites */}
                          {getInvitesByRole(role)?.map(
                            (
                              invite: WorkspaceInvitation,
                              inviteIndex: number
                            ) => (
                              // Create a row for each invite
                              <tr key={inviteIndex} className="table-row">
                                {/* Roles */}
                                <td
                                  rowSpan={getInvitesByRole(role)?.length}
                                  className={classNames(
                                    inviteIndex == 0
                                      ? getMembersByRole(role)?.length === 0
                                        ? 'border-t border-gray-200'
                                        : ''
                                      : 'hidden',
                                    'table-cell pl-4 pr-2 py-3 whitespace-normal font-medium align-top'
                                  )}
                                >
                                  {getMembersByRole(role)?.length === 0 &&
                                  inviteIndex === 0
                                    ? enumTranslates[role]
                                    : ''}
                                </td>

                                {/* Members */}
                                <td className="table-cell border-t border-gray-200 pl-2 pr-2 py-3 align-middle">
                                  <div className="flex items-center">
                                    {invite.firstName ? (
                                      // For users with accounts
                                      <span className="flex items-center">
                                        <Avatar
                                          name={
                                            invite.firstName +
                                            ' ' +
                                            invite.lastName
                                          }
                                          size="32"
                                          round="16px"
                                          color="#304057"
                                        />
                                        <span className="ml-3">
                                          <p className="inline-flex truncate text-sm font-medium text-gray-900 gap-x-2">
                                            {`${invite.firstName} ${invite.lastName}`}
                                            <Tag type="pending" />
                                          </p>
                                          <p className="block truncate text-xs text-gray-400">
                                            {`${invite.inviteeEmail}`}
                                          </p>
                                        </span>
                                      </span>
                                    ) : (
                                      // User accounts pending
                                      <span className="flex items-center">
                                        <Avatar
                                          name={invite.inviteeEmail}
                                          size="32"
                                          round="16px"
                                          color="#304057"
                                        />
                                        <span className="ml-3">
                                          <p className="inline-flex truncate text-sm font-medium text-gray-900 gap-x-2">
                                            {`${invite.inviteeEmail}`}
                                            <Tag type="pending" />
                                          </p>
                                          <p className="block truncate text-xs text-gray-400">
                                            {`${translate(
                                              strings.PENDING_SIGNUP,
                                              'Account pending'
                                            )}`}
                                          </p>
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                </td>
                                {/* Permission */}
                                <td className="table-cell border-t border-gray-200 pl-2 pr-4 py-3 align-middle">
                                  {enumTranslates[invite.group]}
                                </td>
                              </tr>
                            )
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  );
                })}

                {/* Indirect members */}
                {indirectAccessUsers &&
                  indirectAccessUsers?.length > 0 &&
                  indirectAccessUsers?.map((member, memberIndex) => {
                    const permissionThisEntity = member.group;

                    return (
                      <tr key={memberIndex} className="table-row">
                        {/* Roles */}
                        <td
                          rowSpan={indirectAccessUsers?.length}
                          className={classNames(
                            memberIndex == 0
                              ? 'border-t border-gray-200'
                              : 'hidden',
                            'table-cell pl-4 pr-2 py-3 whitespace-normal font-medium align-top'
                          )}
                        >
                          {memberIndex === 0 ? 'Follower' : ''}
                          <p className="text-xs text-gray-400">
                            Access granted from outside this{' '}
                            {enumTranslates[EntityType.Workspace]}
                          </p>
                        </td>

                        {/* Members */}
                        <td className="table-cell border-t border-gray-200 pl-2 pr-2 py-3 align-middle">
                          <div className="flex items-center">
                            <Avatar
                              name={
                                member.user.firstName +
                                ' ' +
                                member.user.lastName
                              }
                              size="32"
                              round="16px"
                              color="#304057"
                            />
                            <span className="ml-3">
                              <p className="inline-flex truncate text-sm font-medium text-gray-900 gap-x-2">
                                {`${member.user.firstName} ${member.user.lastName}`}
                                {member.user.id === user?.attributes?.sub && (
                                  <Tag type="user" />
                                )}
                              </p>
                              <p className="block truncate text-xs text-gray-400">
                                {`${member.user.email}`}
                              </p>
                            </span>
                          </div>
                        </td>

                        {/* Permission */}
                        <td className="table-cell border-t border-gray-200 pl-2 pr-4 py-3 align-middle">
                          {permissionThisEntity ? (
                            <p>{enumTranslates[permissionThisEntity]}</p>
                          ) : (
                            <p className="italic text-gray-500">
                              No permission found
                            </p>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkspaceMembersTab;
