import {
  ValidateResult,
  ValidationRule,
  ValidationRuleStrategy,
} from '../Validation.types';

const IsEmailValidationRule: ValidationRuleStrategy = {
  validate(rule: ValidationRule, value: string): ValidateResult {
    const regex = new RegExp(
      "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
    );
    return {
      isValid: regex.test(value),
      messages: ['Not a valid email'],
    };
  },
};

export default IsEmailValidationRule;
