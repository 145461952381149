import { Target } from "phosphor-react";

type Props = {
  contents: string;
};

const targetIcon = (
  <Target
    size={20}
    weight="duotone"
    className="text-accent-light shrink-0 mt-px"
  />
);

function ObjectiveTableCell({ contents }: Props) {
  return (
    <div className="inline-flex text-accent-light gap-x-1.5 font-medium">
      {targetIcon}
      {contents}
    </div>
  );
}

export default ObjectiveTableCell;
