import * as React from 'react';
import ReactLoading from 'react-loading';

type Props = {
  text: string;
};

export default function Spinner({ text }: Props): React.ReactElement {
  return (
    <div className="flex flex-col items-center text-sm text-gray-500 space-y-2 py-4">
      <ReactLoading type="bubbles" color="#2EC4B6" height={40} width={40} />
      <p>{text != null ? `${text}` : ''}</p>
    </div>
  );
}
