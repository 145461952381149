import React, { useCallback, useState } from 'react';
import {
  //GetMembersQuery,
  //GetMembersQueryVariables,
  IMember,
  EntityType,
} from '../api/index';
import Modal from './Modal';
import AddMember from './AddMember';

type Props = {
  entityId: string;
  entityType: EntityType;
};

function EntityMembers({ entityId, entityType }: Props): React.ReactElement {
  const [members] = useState<IMember[]>([]);

  const [showAddMember, setShowAddMember] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _getMembers = async (entityId: string, nextToken: string | null) => {
    /* try {
      const variable: GetMembersQueryVariables = {
        entityId,
        limit: pageSize,
        nextToken,
      }

      const resp: GraphQLResult<GetMembersQuery> = (await API.graphql(
        graphqlOperation(getMembers, variable),
      )) as { data: GetMembersQuery }

      if (resp.data) {
        const respGetMembers: GetMembersQuery = resp.data

        if (respGetMembers.getMembers) {
          if (respGetMembers.getMembers.items) {
            setMembers(respGetMembers.getMembers.items)
          }
        }
      }
    } catch (err) {
      console.error(err)
    } */
  };

  const handleModalClose = useCallback(() => {
    setShowAddMember(false);
  }, []);

  return (
    <>
      <h3 className="my-5 text-lg leading-6 font-medium text-gray-900">
        Members
      </h3>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      First Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Last Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Role
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Access Level
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {members.map((member, idx) => (
                    <tr key={idx}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {member.user?.firstName}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {member.user?.lastName}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {member.user?.email}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {/* {member.role} */}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {member.group}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button
          className="my-5 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          onClick={() => setShowAddMember(true)}
        >
          Add Member
        </button>
      </div>
      {showAddMember && (
        <Modal onClose={handleModalClose}>
          <AddMember entityId={entityId} entityType={entityType}></AddMember>
        </Modal>
      )}
    </>
  );
}

export default EntityMembers;
