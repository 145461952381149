import React, { useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import {
  ComponentInfo,
  GenericContainer,
} from '../../JLcomponents/basic/GenericContainer';
import OutcomesPanel from './OutcomesPanel/OutcomesPanel';
import { WaypointChecklistItem } from './OutcomesPanel/OutcomesContent';
import WaypointSelector, {
  Waypoint,
  WaypointType,
} from './WaypointSelector/WaypointSelector';
import ReviewPanel, { WaypointReview } from './ReviewPanel/ReviewPanel';
import { DeleteIcon } from '../basic/ButtonIcons';
import WatchoutsPanel, {
  Watchout,
  WatchoutStatus,
  WatchoutType,
} from './WatchoutsPanel/WatchoutsPanel';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import {
  getAllEntityWaypoints,
  getOutcomesByWaypointId,
  getReviewByWaypointId,
  getWatchoutsByEntityId,
  sortWaypointsByDate,
} from '../../common/wizard/payloads/MockQueryFunctions';

const reviewMenuOptions = [
  {
    id: 'DELETE01',
    action: () => console.log('Delete Clicked'),
    text: 'Delete review...',
    icon: <DeleteIcon />,
  },
];

function getTodaysWPIndex(waypoints: Waypoint[]): number {
  let todayWaypointIndex = 0;
  if (waypoints) {
    const today = moment();
    for (let i = 0; i < waypoints.length; i++) {
      if (
        moment(waypoints[i].date).isSame(today, 'day') ||
        moment(waypoints[i].date).isAfter(today)
      ) {
        todayWaypointIndex = i;
        break;
      } else {
        todayWaypointIndex = waypoints.length - 1;
      }
    }
  }
  return todayWaypointIndex;
}

function WaypointsContainer(): React.ReactElement {
  const { entityId } = useParams<{ entityId: string }>();
  const waypoints = sortWaypointsByDate(
    getAllEntityWaypoints(entityId as string),
    false
  ) as Waypoint[];

  // Find the "current focus" waypoint
  // i.e. the one that is either today, or the next one after today
  const [todayWaypointIndex] = useState(getTodaysWPIndex(waypoints));
  // console.log(waypoints, todayWaypointIndex);

  const [waypointId, setWaypointId] = useState(
    waypoints ? waypoints[todayWaypointIndex].id : null
  );
  const [review, setReview] = useState(
    waypointId ? (getReviewByWaypointId(waypointId) as WaypointReview) : null
  );
  const [outcomesList, setOutcomesList] = useState(
    waypointId
      ? (getOutcomesByWaypointId(waypointId) as WaypointChecklistItem[])
      : null
  );
  const [watchoutsList, setWatchoutsList] = useState(
    getWatchoutsByEntityId(entityId as string)
  );

  const handleWaypointClick = (waypointId: string) => {
    setWaypointId(waypointId);
    setReview(getReviewByWaypointId(waypointId) as WaypointReview);
    setOutcomesList(
      getOutcomesByWaypointId(waypointId) as WaypointChecklistItem[]
    );
  };

  const containerPanels: ComponentInfo<any>[] = [
    {
      id: 'PANEL_001',
      Component: WaypointSelector,
      props: {
        waypoints: waypoints,
        todayWaypointIndex: todayWaypointIndex,
        onWaypointClick: handleWaypointClick,
      },
    },
    {
      id: 'PANEL_002',
      Component: OutcomesPanel,
      props: {
        checklist: outcomesList,
        onEditClick: () => {
          console.log(`Clicked button: edit waypoint outcomes`);
        },
        actionsDisabled: false,
        isVisible: true,
      },
    },
    {
      id: 'PANEL_003',
      Component: ReviewPanel,
      props: {
        review: review,
        menuOptions: reviewMenuOptions,
        actionsDisabled: false,
        onEditClick: () => console.log(`Clicked button: edit waypoint review`),
        isVisible: true,
      },
    },
    {
      id: 'PANEL_004',
      Component: WatchoutsPanel,
      props: {
        watchouts: watchoutsList,
        onEditClick: () => {
          console.log(`Clicked button: review and update watchouts`);
        },
        actionsDisabled: false,
        isVisible: true,
      },
    },
  ];
  const { user: currentUser, profile: userProfile } = useContext(UserContext);
  //   const { state: portfolioState } = useContext(PortfolioPageContext);
  // call the useGetEntities hook to get the entities
  // useGetEntities returns an array of entities formatted as above
  return <GenericContainer components={containerPanels} />;
}

export default WaypointsContainer;
