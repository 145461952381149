import React from 'react';
import {
  Portfolio,
  Program,
  ProgramMeasure,
  Project,
  ProjectMeasure,
  StrategicObjective,
} from '../../../api/index';
import translate, { strings } from '../../../common/i18n/translate';
import StyledTable, {
  StyledTableRow,
  TableSchema,
} from '../../../common/layout/StyledTable';
import Modal from '../../../common/Modal';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import Tag from '../../../common/Tag';
import { Wrench } from 'phosphor-react';

export type WorkflowType = 'PROGRAM_BENEFITS' | 'PROJECT_BENEFITS';

type Props = {
  type: WorkflowType | undefined;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalTitle: string;
  modalData:
    | { measure: ProgramMeasure; parent: Program }[]
    | { measure: ProjectMeasure; parent: Project }[]
    | undefined;
  portfolio: Portfolio;
};

export default function WorkflowModal({
  type,
  setModalOpen,
  modalTitle,
  modalData,
  portfolio,
}: Props): React.ReactElement {
  // Fixing program measures
  const programMeasureSchema: TableSchema = {
    columns: [
      {
        label: translate(strings.PROGRAM_MEASURE, 'Program Benefit'),
        key: 'benefitName',
      },
      {
        label: `Link to...`,
        key: 'strategicObjective',
      },
    ],
  };

  function ProgramMeasureRow(item: {
    measure: ProgramMeasure;
    parent: Program;
  }): StyledTableRow {
    const parentOptions: StrategicObjective[] = portfolio.objectives || [];

    const selectId = `${item.measure.id}-parentSelector`;
    // const select = document.getElementById(selectId) as HTMLSelectElement;
    // const value = select?.value;
    // console.log(value);

    const output = {
      id: item.measure.id,
      cells: [
        {
          content: (
            <div>
              <div className="text-sm font-medium">{item.measure.name}</div>
              <div className="text-xs text-gray-400">{item.parent.name}</div>
            </div>
          ),
        },
        {
          content: (
            <div className="text-sm">
              {parentOptions && parentOptions.length > 0 ? (
                <div className="flex items-center">
                  <select
                    id={selectId}
                    className="block w-full max-w-xl rounded-md py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    defaultValue={'NO_SELECTION'}
                    onChange={e =>
                      console.log(
                        `Set ${item.measure.name} to ${e.target.value}`
                      )
                    }
                  >
                    {/* Empty option */}
                    <option
                      key={'NO_SELECTION'}
                      value={'NO_SELECTION'}
                      disabled
                    >
                      Select a{' '}
                      {translate(
                        strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                        'Strategic Objective'
                      ).toLowerCase()}
                      ...
                      {/* {translate(strings.UNMAPPED, 'Not linked')} */}
                    </option>

                    {/* Actual options */}
                    {parentOptions?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.description}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="text-gray-400 italic">
                  {`Parent has no ${translate(
                    strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                    'Strategic Objectives'
                  ).toLowerCase()}`}
                </div>
              )}
            </div>
          ),
        },
      ],
      canExpand: false,
    };

    return output;
  }

  //   Fixing project measures
  const projectMeasureSchema: TableSchema = {
    columns: [
      {
        label: translate(strings.PROJECT_MEASURE, 'Project Benefit'),
        key: 'benefitName',
      },
      {
        label: `Link to...`,
        key: 'programBenefit',
      },
    ],
  };

  function ProjectMeasureRow(item: {
    measure: ProjectMeasure;
    parent: Project;
  }): StyledTableRow {
    const parentOptions: ProgramMeasure[] = item.parent.program.measures || [];

    const selectId = `${item.measure.id}-parentSelector`;

    const output = {
      id: item.measure.id,
      cells: [
        {
          content: (
            <div>
              <div className="text-sm font-medium">{item.measure.name}</div>
              <div className="text-xs text-gray-400">{item.parent.name}</div>
            </div>
          ),
        },
        {
          content: (
            <div className="text-sm">
              {parentOptions && parentOptions.length > 0 ? (
                <div className="flex items-center">
                  <select
                    id={selectId}
                    className="block w-full max-w-xl rounded-md py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                    defaultValue={'NO_SELECTION'}
                    onChange={e =>
                      console.log(
                        `Set ${item.measure.name} to ${e.target.value}`
                      )
                    }
                  >
                    {/* Empty option */}
                    <option
                      key={'NO_SELECTION'}
                      value={'NO_SELECTION'}
                      disabled
                    >
                      Select a{' '}
                      {translate(
                        strings.PROGRAM_MEASURE,
                        'Program Benefit'
                      ).toLowerCase()}
                      ...
                      {/* {translate(strings.UNMAPPED, 'Not linked')} */}
                    </option>

                    {/* Actual options */}
                    {parentOptions?.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div className="text-gray-400 italic">
                  {`Parent has no ${translate(
                    strings.PROGRAM_MEASURES,
                    'Program Benefits'
                  ).toLowerCase()}`}
                </div>
              )}
            </div>
          ),
        },
      ],
      canExpand: false,
    };

    return output;
  }

  const rows: StyledTableRow[] | undefined = modalData?.map(item =>
    type === 'PROGRAM_BENEFITS'
      ? ProgramMeasureRow(item as { measure: ProgramMeasure; parent: Program })
      : ProjectMeasureRow(item as { measure: ProjectMeasure; parent: Project })
  );

  const fixProgramBenefitsLayout = (
    <Modal width={'w-full max-w-4xl'} onClose={() => setModalOpen(false)}>
      <div className="bg-white max-h-[42rem]">
        {/* Title */}
        <h3 className="flex items-center text-lg font-medium leading-6 px-5 py-3 gap-x-3">
          <div className="p-2 rounded-full shrink-0 bg-primary-500 align-middle items-center">
            <Wrench className="text-white" size={20} weight="fill" />
          </div>
          {modalTitle}
          <span className="pt-0.5 flex flex-col justify-end">
            <Tag type="preview" />
          </span>
        </h3>

        {/* Content for workflow */}
        <div className="p-5 border-t border-gray-200 overscroll-y-auto">
          <p className="text-sm mb-4">{`Assign a ${
            type === 'PROGRAM_BENEFITS'
              ? translate(
                  strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                  'Strategic Objective'
                ).toLowerCase()
              : translate(
                  strings.PROGRAM_MEASURE,
                  'Program Benefit'
                ).toLowerCase()
          } for each ${
            type === 'PROGRAM_BENEFITS'
              ? translate(
                  strings.PROGRAM_MEASURE,
                  'Program Benefit'
                ).toLowerCase()
              : translate(
                  strings.PROJECT_MEASURE,
                  'Project Benefit'
                ).toLowerCase()
          } to demonstrate how it links back to our strategy.`}</p>
          <StyledTable
            schema={
              type === 'PROGRAM_BENEFITS'
                ? programMeasureSchema
                : projectMeasureSchema
            }
            rowData={rows || []}
            disableHoverHighlight={true}
            noRowsMessage={`No unlinked ${
              type === 'PROGRAM_BENEFITS'
                ? translate(
                    strings.PROGRAM_MEASURES,
                    'Program Benefits'
                  ).toLowerCase()
                : translate(
                    strings.PROJECT_MEASURES,
                    'Project Benefits'
                  ).toLowerCase()
            }`}
          />

          {/* Submit button */}
          <div className="flex justify-end mt-6 gap-x-3">
            <SecondaryButton
              label="Cancel"
              onClick={() => setModalOpen(false)}
              disabled={false}
            />
            <PrimaryButton
              icon="save"
              label={`Save changes`}
              onClick={() => console.log(`Submit changes to ${type}`)}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </Modal>
  );

  return fixProgramBenefitsLayout;
}
