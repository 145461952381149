import { FolderSimple } from 'phosphor-react';
import React, { useState } from 'react';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import ComparisonTable from './ComparisonTable';
import { Scenario } from './dummyData';

type Props = {
  selectedScenario: Scenario | undefined;
};

function CompareScenario({ selectedScenario }: Props): React.ReactElement {
  const [selectedProgramId, setSelectedProgramId] = useState('program-1');
  // TODO: Get this from state
  const sandboxPortfolio = selectedScenario?.referencePortfolio;
  const sandboxProgram = sandboxPortfolio?.programs.find(
    program => program.programId === selectedProgramId
  );

  const iconProgram = (
    <FolderSimple className="w-6 h-6 flex-shrink-0 text-gray-500 group-hover:text-primary-500" />
  );

  const scenariosTitle = (
    <DetailsPanelHeader
      title={`Scenario Comparison`}
      description={`This scenario versus the reference point`}
    />
  );

  function handleUpdateSelection(value: string) {
    setSelectedProgramId(value);
  }

  const scenariosContent = (
    <div className="flex-col space-y-6">
      <div className="w-full flex justify-center">
        {/* Program selector */}
        <div className="flex w-full lg:max-w-md">
          <span
            className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center"
            data-tip
            data-for={'look-back-icon'}
          >
            {iconProgram}
          </span>
          <select
            className="block grow rounded-r-md pl-3 pr-8 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            defaultValue={
              selectedScenario?.referencePortfolio?.programs[0].name
            }
            onChange={e => handleUpdateSelection(e.target.value)}
          >
            {sandboxPortfolio?.programs.map(item => (
              <option value={item.programId}>{item.name}</option>
            ))}
          </select>
        </div>
      </div>
      {selectedScenario?.referencePortfolio ? (
        <React.Fragment>
          <ComparisonTable
            scenario={selectedScenario}
            // referencePortfolio={selectedScenario?.referencePortfolio}
            // scenarioPortfolio={selectedScenario?.referencePortfolio}
            sandboxProgram={sandboxProgram}
            attribute={'PROJECTS'}
            showTitle={true}
          />
          <div className="w-full border-t border-gray-200"></div>
          <ComparisonTable
            scenario={selectedScenario}
            // referencePortfolio={selectedScenario?.referencePortfolio}
            // scenarioPortfolio={selectedScenario?.referencePortfolio}
            sandboxProgram={sandboxProgram}
            attribute={'TAGS'}
            showTitle={false}
          />
          <ComparisonTable
            scenario={selectedScenario}
            // referencePortfolio={selectedScenario?.referencePortfolio}
            // scenarioPortfolio={selectedScenario?.referencePortfolio}
            sandboxProgram={sandboxProgram}
            attribute={'BENEFITS'}
            showTitle={false}
          />
          <ComparisonTable
            scenario={selectedScenario}
            // referencePortfolio={selectedScenario?.referencePortfolio}
            // scenarioPortfolio={selectedScenario?.referencePortfolio}
            sandboxProgram={sandboxProgram}
            attribute={'COST'}
            showTitle={false}
          />
          <ComparisonTable
            scenario={selectedScenario}
            // referencePortfolio={selectedScenario?.referencePortfolio}
            // scenarioPortfolio={selectedScenario?.referencePortfolio}
            sandboxProgram={sandboxProgram}
            attribute={'COMMENTS'}
            showTitle={false}
          />
        </React.Fragment>
      ) : (
        <div>Missing inputs</div>
      )}
    </div>
  );

  return (
    <DetailsPanel
      headerLeft={scenariosTitle}
      headerRight={null}
      content={scenariosContent}
    />
  );
}

export default CompareScenario;
