type Props = {
  contents: string;
  styleClass: string;
};

function StyledTableCell({ contents, styleClass }: Props) {
  return <div className={`inline-flex ${styleClass}`}>{contents}</div>;
}

export default StyledTableCell;
