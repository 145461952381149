import { Navigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { SIGNIN } from './routes';
import MainLayout from './MainLayout';
import { UserContext } from '../context/UserContext';

export type PrivateRouteProps = {
  component: React.ElementType;
  path: string;
};

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component: Component,
  path,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
}) => {
  const { user } = useContext(UserContext);
  let componentToRender;
  if (path === '*' || path === '/pagenotfound') {
    componentToRender = <Component />;
  } else if (user) {
    componentToRender = (
      <MainLayout>
        <Component />
      </MainLayout>
    );
  } else {
    componentToRender = <Navigate to={SIGNIN} />;
  }
  return componentToRender;
};

export default PrivateRoute;
