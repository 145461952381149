import React from 'react';
import {
  ArrowLeft,
  Buildings,
  FunnelSimple,
  HouseLine,
  // MagicWands,
  PresentationChart,
  Question,
  Sidebar,
  ToggleLeft,
  ToggleRight,
  TreeStructure,
} from 'phosphor-react';
import { Link } from 'react-router-dom';
import { MainNavItem } from './MainLayout';
import ReactTooltip from 'react-tooltip';
import { globalTooltipDelay } from './constants';

type Props = {
  item: MainNavItem;
  desktopSidebarOpen: boolean;
  isDemo: boolean;
  disabled: boolean;
  setMobileMenuOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type MainNavIcon =
  | 'home'
  | 'organisation'
  | 'directory'
  | 'reports'
  | 'prioritisation'
  | 'help'
  | 'demoOn'
  | 'demoOff'
  | 'hide'
  | 'show';

const tooltipDelay = globalTooltipDelay;

export default function MainNavButton({
  item,
  desktopSidebarOpen,
  isDemo,
  disabled,
  setMobileMenuOpen,
}: Props): React.ReactElement {
  const isCurrent = item.routes?.some((r: string) =>
    item.name != 'Home'
      ? window.location.pathname.includes(r)
      : item.href == window.location.pathname
  );

  // Icons
  const iconStyle = `h-6 w-6 ${
    isCurrent ? 'text-white' : 'text-gray-300'
  } shrink-0`;
  const iconWeight = 'duotone';

  const demoIconStyle = `h-6 w-6 text-yellow-400 shrink-0`;
  const demoIconWeight = 'fill';

  const iconHome = (
    <HouseLine className={iconStyle} weight={iconWeight} aria-hidden="true" />
  );
  const iconOrg = (
    <Buildings className={iconStyle} weight={iconWeight} aria-hidden="true" />
  );
  const iconDirectory = (
    <TreeStructure
      className={iconStyle}
      weight={iconWeight}
      aria-hidden="true"
    />
  );
  const iconReports = (
    <PresentationChart
      className={iconStyle}
      weight={iconWeight}
      aria-hidden="true"
    />
  );
  const iconPrioritisation = (
    <FunnelSimple
      className={iconStyle}
      weight={iconWeight}
      aria-hidden="true"
    />
  );
  const iconHelp = (
    <Question className={iconStyle} weight={iconWeight} aria-hidden="true" />
  );
  const iconDemoOn = (
    <ToggleRight
      className={demoIconStyle}
      weight={demoIconWeight}
      aria-hidden="true"
    />
  );
  const iconDemoOff = (
    <ToggleLeft className={iconStyle} weight={iconWeight} aria-hidden="true" />
  );
  const iconCollapseMenu = (
    <ArrowLeft className={iconStyle} weight={iconWeight} aria-hidden="true" />
  );
  const iconShowMenu = (
    <Sidebar className={iconStyle} weight={iconWeight} aria-hidden="true" />
  );

  // Get the icon type
  function getIcon(): React.ReactElement {
    let output = <React.Fragment />;

    switch (item.icon) {
      case 'home':
        output = iconHome;
        break;
      case 'organisation':
        output = iconOrg;
        break;
      case 'directory':
        output = iconDirectory;
        break;
      case 'reports':
        output = iconReports;
        break;
      case 'prioritisation':
        output = iconPrioritisation;
        break;
      case 'help':
        output = iconHelp;
        break;
      case 'demoOn':
        output = iconDemoOn;
        break;
      case 'demoOff':
        output = iconDemoOff;
        break;
      case 'hide':
        output = iconCollapseMenu;
        break;
      case 'show':
        output = iconShowMenu;
        break;

      default:
        break;
    }

    return output;
  }

  function handleLinkClick() {
    if (setMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  }

  function handleActionClick(
    action: React.MouseEventHandler<HTMLButtonElement> | undefined
  ) {
    action;

    if (setMobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  }

  const LinkButton = (
    <Link
      key={item.name}
      to={item.href}
      className={`relative group flex items-center gap-x-3 px-3 py-2 text-base md:text-sm font-medium rounded-md ${
        isCurrent
          ? 'bg-white bg-opacity-20 text-white'
          : 'text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white'
      }`}
      onClick={() => handleLinkClick()}
      data-tip
      data-for={item.name}
    >
      {getIcon()}
      {desktopSidebarOpen && item.name}
      <span
        className={`w-1.5 h-5 bg-gradient-to-tr from-primary-600 to-primary-500 rounded-full absolute -right-0.5 ${
          isCurrent ? '' : 'hidden'
        }`}
      />
    </Link>
  );

  const ActionButton = (
    <button
      type="button"
      disabled={disabled}
      className={`relative w-full group flex items-center gap-x-3 px-3 py-2 text-base md:text-sm font-medium rounded-md text-gray-300 hover:bg-white hover:bg-opacity-10 hover:text-white`}
      onClick={item.action}
      data-tip
      data-for={item.name}
    >
      {getIcon()}
      {item.name}
    </button>
  );

  return (
    <React.Fragment>
      {/* The button itself */}
      {item.isAction ? ActionButton : LinkButton}

      {/* Information tooltip */}
      <ReactTooltip
        id={item.name}
        place="right"
        effect="solid"
        delayShow={tooltipDelay}
        offset={{ right: 10 }}
      >
        <div className="text-sm">
          <p>{item.tooltip}</p>
        </div>
      </ReactTooltip>
    </React.Fragment>
  );
}
