import React, { useContext, useEffect, useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import ProjectMeasuresTable from '../../project/AdminTab/ProjectMeasuresView';
import { ProjectPageContext } from '../../project/ProjectPageContext';
import {
  // capitaliseFirstLetter,
  classNames,
  // getLocalDate,
  // getRelativeTime,
} from '../../../common/utils';
// import WizardButton from '../../../common/wizard/WizardButton';
import { Menu } from '@headlessui/react';
import {
  EntityType,
  Group,
  Project,
  useRemoveEntityMutation,
} from '../../../api/index';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import Modal from '../../../common/Modal';
import { useNavigate } from 'react-router-dom';
import { PROGRAM } from '../../../common/routes';
import Spinner from '../../../common/SpinnerThemed';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { ProgramPageContext } from '../../program/ProgramPageContext';
import { CheckCircle, Circle, Trash } from 'phosphor-react';
import ProjectMembershipTable from '../../project/AdminTab/ProjectMembershipTable';
import Banner from '../../../common/Banner';
import EntityAboutPanel from './EntityAboutPanel';
// import EntitySchedulePanel from './EntitySwimlanesPanel';
import { PortfolioPageContext } from '../../portfolio/PortfolioPageContext';
import OpenWizardButton from '../../../common/wizard/WizardButton';
import EntityRelationshipsPanel from './EntityRelationshipsPanel';
import LinksPanel from '../../project/LinksPanel';

function OverflowMenu(project: Project | null) {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const { dispatch } = useContext(ProgramPageContext);

  const navigate = useNavigate();

  const [removeEntityMutation, { data, loading }] = useRemoveEntityMutation();

  const handleDeleteClick = (project: Project | null) => {
    if (project != null) {
      try {
        removeEntityMutation({
          variables: {
            id: project.id,
          },
        });
      } catch (e) {
        toast.error((e as ApolloError).message);
      }
    }
    return;
  };

  useEffect(() => {
    if (data?.removeEntity) {
      toast.success(
        `Deleted ${enumTranslates[EntityType.Project]} '${
          project?.name ? project.name : ''
        }'`
      );
      dispatch({
        type: 'LOAD_UPDATE',
        loadUpdate: true,
      });
      navigate(`${PROGRAM}/${project?.program.id}`);
    }
  }, [data, dispatch, navigate, project]);

  if (project != null) {
    const iconDelete = <Trash weight="bold" className="h-5 w-5 text-red-600" />;
    const menuOptions = [
      {
        display: `Delete this ${enumTranslates[EntityType.Project]}...`,
        icon: iconDelete,
        action: () => setModalOpen(true),
      },
    ];

    return loading ? (
      <Spinner text={`Deleting ${enumTranslates[EntityType.Project]}...`} />
    ) : (
      <div className="flex items-center group relative">
        {modalOpen && (
          <Modal width={'w-full max-w-xl'} onClose={() => setModalOpen(false)}>
            <div className="flex flex-col space-y-4 bg-white p-4 overflow-y-auto align-middle">
              <div className="flex flex-row items-center">
                <span className="flex p-2 rounded-full bg-red-600 align-middle items-center">
                  <Trash
                    weight="fill"
                    className="h-5 w-5 text-white mx-auto my-auto"
                  />
                </span>
                <h3 className="text-lg font-medium ml-3 text-gray-900">
                  Confirm Delete
                </h3>
              </div>
              <p className="mt-3 text-sm text-gray-900">
                {`Deleting a ${
                  enumTranslates[EntityType.Project]
                } cannot be undone. Impacts also include the following:`}
                <ul className="list-disc list-outside ml-4 mt-2 text-sm">
                  <li>
                    {`All ${
                      enumTranslates[EntityType.Project]
                    } members will lose
                    access to this ${enumTranslates[EntityType.Project]}`}
                  </li>
                  <li>
                    {`This ${
                      enumTranslates[EntityType.Project]
                    }'s data (including
                    all asks and ${enumTranslates[EntityType.Project]} reviews)
                    will be deleted`}
                  </li>
                </ul>
              </p>
              <p className="mt-3 text-sm text-gray-900">
                {`If you still want to proceed, type "DELETE ${project.name.toUpperCase()}" below, then press the "Delete ${
                  enumTranslates[EntityType.Project]
                }" button.`}
              </p>
              <input
                type="text"
                className="block w-full shadow-sm focus:ring-red-600 focus:border-red-600 sm:text-sm border-gray-300 rounded-md"
                onChange={e => setDeleteInputValue(e.target.value)}
                placeholder={`Type "DELETE ${project.name.toUpperCase()}" here`}
                value={deleteInputValue}
              />
              <span className="text-right space-x-3">
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  disabled={
                    deleteInputValue !== `DELETE ${project.name.toUpperCase()}`
                  }
                  onClick={() => handleDeleteClick(project)}
                >
                  Delete {enumTranslates[EntityType.Project]}
                </button>
              </span>
            </div>
          </Modal>
        )}
        <Menu>
          <Menu.Button className="inline-flex p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <EllipsisVerticalIcon className="h-5 w-5 z-0" />
          </Menu.Button>
          <Menu.Items className="w-fit origin-top-right absolute right-0 mt-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {/* Use the `active` render prop to conditionally style the active item. */}
            {menuOptions.map(item => (
              <Menu.Item key={item.display}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-700',
                      'flex w-full items-center px-4 py-2 text-sm text-gray-700'
                    )}
                    onClick={item.action}
                  >
                    <span className="inline-block mr-3">{item.icon}</span>
                    <span className="inline-block whitespace-nowrap">
                      {item.display}
                    </span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    );
  }
}

type Props = {
  entity: Project;
  isLoading: boolean;
};

function EntityAbout({ entity, isLoading }: Props): React.ReactElement {
  // Note: For now we are duplicating the data getting calls because of the old hierarchy
  // In the Entity model, this will just be simplified.

  // Get entity context
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const { state: programPageState } = useContext(ProgramPageContext);
  const { state: projectPageState } = useContext(ProjectPageContext);

  // Determine the entity type
  const thisEntity = projectPageState.selectedProject;
  const parentEntity = programPageState.selectedProgram;

  // Get Current User permissions
  const userPermission = thisEntity?.group;
  const hasEditPermissions =
    userPermission == Group.Owner || userPermission == Group.Editor;
  const hasOwnerPermissions = userPermission == Group.Owner;

  const invitations = { invitations: projectPageState.invitations };
  const editObject = { ...thisEntity, ...invitations };

  // Set up edit button
  const aboutButton = (
    <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
      {/* <span className="sm:ml-3">
        {entity && hasEditPermissions && (
          // This button is for a project
          // In v2 this is will be the same wizard but for any Entity
          <OpenWizardButton
            title={translate(strings.WIZARD_EDIT_PROJECT, 'Edit Project')}
            editObject={editObject}
            parentObject={thisEntity?.program}
            type={'PROJECT_DETAILS'}
            quickSave={true}
            startStep={1}
          />
        )}
      </span> */}

      {/* Overflow menu - purposely hides the delete button */}
      {entity && hasOwnerPermissions && OverflowMenu(entity)}
    </div>
  );

  // const objectivesTitle = (
  //   <DetailsPanelHeader
  //     title={`Strategic Alignment`}
  //     description={`What we are aiming for and why it's important`}
  //   />
  // );

  // const objectivesContent = (
  //   <div className="grid grid-cols-1 justify-between lg:space-x-6 lg:grid-cols-2 space-y-3 lg:space-y-0">
  //     <div className="col-span-1">
  //       <p className="text-sm font-semibold text-gray-900">
  //         {capitaliseFirstLetter(enumTranslates[EntityType.Project])}{' '}
  //         Description
  //       </p>
  //       {project?.outcome ? (
  //         <p className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">
  //           {project?.outcome}
  //         </p>
  //       ) : (
  //         <p className="mt-1 text-sm italic text-gray-400">Not provided</p>
  //       )}
  //     </div>
  //     <div className="col-span-1">
  //       <p className="text-sm font-semibold text-gray-900">Case for Change</p>
  //       {project?.caseForChange ? (
  //         <p className="mt-1 text-sm whitespace-pre-wrap text-gray-600">
  //           {project?.caseForChange}
  //         </p>
  //       ) : (
  //         <p className="mt-1 text-sm italic text-gray-400">Not provided</p>
  //       )}
  //     </div>
  //   </div>
  // );

  const outcomesTitle = (
    <DetailsPanelHeader
      title={`Objectives`} // translate(strings.PROJECT_MEASURES, 'Project Outcomes')
      description={`What success looks like for this ${
        enumTranslates[EntityType.Project]
      }`}
    />
  );

  const outcomesContent =
    entity?.measures && entity?.measures?.length > 0 ? (
      <ProjectMeasuresTable />
    ) : (
      <EmptyState type="benefits" />
    );

  const membersTitleBeta = (
    <DetailsPanelHeader
      title={translate(strings.ROLES_PERMISSIONS, 'Roles & Permissions')}
      description={`Who has access to this ${
        enumTranslates[EntityType.Project]
      }`}
    />
  );

  const membersContentBeta =
    entity?.members?.items && entity?.members?.items.length === 0 ? (
      <EmptyState type="members" />
    ) : (
      <ProjectMembershipTable />
    );

  const doneIcon = (
    <CheckCircle
      className={`w-5 h-5 flex-shrink-0 text-green-500`}
      weight="fill"
    />
  );
  const tbdIcon = (
    <Circle className={`w-5 h-5 flex-shrink-0 text-gray-400`} weight="bold" />
  );

  // Calculate setup progress
  const progressChecklist = [
    thisEntity?.outcome != undefined && thisEntity.outcome.length > 0,
    thisEntity?.caseForChange != undefined &&
      thisEntity.caseForChange.length > 0,
    thisEntity?.measures && thisEntity.measures.length > 0,
    thisEntity?.budget != undefined && thisEntity.budget > 0,
    (thisEntity?.members?.items && thisEntity.members.items.length > 1) ||
      (projectPageState.invitations && projectPageState.invitations.length > 0),
  ];

  const completedSteps = progressChecklist.reduce(
    (partialSum, a) => partialSum + (a == true ? 1 : 0),
    0
  );

  return (
    <React.Fragment>
      {/* Banner */}
      {/* Only show if there are outstanding steps */}
      {hasEditPermissions && completedSteps != progressChecklist.length && (
        <Banner
          colour="blue"
          summary={`Setup progress: ${completedSteps} of ${progressChecklist.length} steps completed`}
          content={
            <ul className="list-outside space-y-1">
              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[0] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[0] ? doneIcon : tbdIcon} Add description
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[1] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[1] ? doneIcon : tbdIcon} Share case for
                change
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[2] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[2] ? doneIcon : tbdIcon} Add{' '}
                {translate(
                  strings.PROJECT_MEASURES,
                  'Project Benefits'
                ).toLowerCase()}
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[3] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[3] ? doneIcon : tbdIcon} Estimate total cost
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[4] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[4] ? doneIcon : tbdIcon} Invite contributors
              </li>
            </ul>
          }
        />
      )}

      {/* TODO: Make the following panels independent components. Visibility may change based on lifecycle stage. */}

      {/* Entity context */}
      {thisEntity && (
        <EntityAboutPanel
          entity={thisEntity}
          editButton={aboutButton}
          // hasEditPermissions={hasEditPermissions}
          // hasOwnerPermissions={hasOwnerPermissions}
        />
      )}

      {/* Related entities */}
      {thisEntity && (
        <EntityRelationshipsPanel
          entity={thisEntity}
          children={[]}
          hasEditPermissions={hasEditPermissions}
        />
      )}

      {/* Entity schedule */}
      {/* {thisEntity && <EntitySchedulePanel entity={thisEntity} children={[]} />} */}

      {/* Strategic alignment */}
      {/* <DetailsPanel
        headerLeft={objectivesTitle}
        headerRight={null}
        content={objectivesContent}
      /> */}

      {/* Project benefits */}
      <DetailsPanel
        headerLeft={outcomesTitle}
        headerRight={null}
        content={outcomesContent}
      />

      {/* Links */}
      <LinksPanel loadingEntity={isLoading} entity={thisEntity} />
    </React.Fragment>
  );
}

export default EntityAbout;
