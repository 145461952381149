import { Gantt, Task, ViewMode } from './gantt';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import { GanttHeader } from './GanttHeader';
import { getLocalDate } from '../../../common/utils';

export interface Project {
  id: string;
  name: string;
  strategicObjective: string;
  startDate: Date;
  endDate: Date;
}

type TooltipProps = {
  task: Task;
  fontFamily: string;
  fontSize: string;
};

const GanttTooltip = ({ task }: TooltipProps) => {
  return (
    <div className="bg-black bg-opacity-70 text-white font-sans text-sm px-4 py-2 rounded text-center">
      <div className="font-semibold">{`${task.name}`}</div>
      <div className="">{`${getLocalDate(task.start)} - ${getLocalDate(
        task.end
      )}`}</div>
    </div>
  );
};

const GanttTitle = () => (
  <DetailsHeaderTitle
    title={`How everything fits together`}
    tooltipText={`The forward plan for this and related entities`}
  />
);

const GanttPanel: React.FC<{
  projects: Project[];
  taskClick: (task: Task) => void;
}> = ({ projects, taskClick }) => {
  const tasks: Task[] = projects.map(project => {
    return {
      id: project.id,
      name: project.name,
      progress: 100,
      type: 'task',
      project: project.strategicObjective,
      start: new Date(project.startDate),
      end: new Date(project.endDate),
    };
  });

  const onClickHandler = (task: Task) => {
    taskClick(task);
  };

  return (
    <DetailsHeader
      headerLeft={<GanttTitle />}
      content={
        projects.length ? (
          <Gantt
            tasks={tasks}
            viewMode={ViewMode.QuarterYear}
            onClick={onClickHandler} // TOOD: Make this open the relationships slideover
            // ganttHeight={400}
            columnWidth={150}
            rowHeight={60}
            TaskListHeader={GanttHeader}
            fontFamily="Inter, sans-serif"
            TooltipContent={GanttTooltip}
          />
        ) : (
          <div className="text-sm text-gray-400 italic text-center">
            No children found
          </div>
        )
      }
    />
  );
};

export default GanttPanel;
