import React, { useContext, useState } from 'react';
import { PortfolioMeasureStatus, StrategicObjective } from '../../../api/index';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import Tag from '../../../common/Tag';
import {
  capitaliseFirstLetter,
  getLatestObjectiveUpdate,
  getLocalDate,
  getProjectMeasureStatusColour,
} from '../../../common/utils';
import ProgramDetailsContentCard from '../../program/Details/ProgramDetailsContentCard';
import { PortfolioPageContext } from '../PortfolioPageContext';
import { CaretRight, CheckCircle, Target, XCircle } from 'phosphor-react';
import translate, { strings } from '../../../common/i18n/translate';
import Modal from '../../../common/Modal';
import moment from 'moment';
import ObjectiveSlideoverLayout from '../PerformanceTab/ObjectiveSlideoverLayout';
import EmptyState from '../../../common/layout/EmptyState';
import ToggleButton from '../../../common/ToggleButton';

export default function StrategicAttentionPanel(): React.ReactElement {
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const [showFramework, setShowFramework] = useState(false);

  const portfolio = portfolioPageState.selectedPortfolio;
  const updates = portfolioPageState.portfolioUpdates;
  const objectives = portfolio?.objectives;

  // Pre-process data into groups
  const groupNames = [
    'Get the basics right',
    'Put out fires',
    'Invest for the future',
    'Validate achievements',
  ] as const;
  type BalanceGroup = typeof groupNames[number];

  function getBalanceGroup(objective: StrategicObjective): BalanceGroup {
    const latest = getLatestObjectiveUpdate(updates, objective.id as string);

    // Temporary solution to categorise objectives based on current performance
    // TODO: Add mechanism to check current performance vs targets
    const meetsKey = '[Y]';

    // Get the inputs for this objective
    const today = latest?.lastUpdate?.remarks?.slice(0, 3) == meetsKey;
    // console.log(latest?.lastUpdate?.remarks?.slice(0, 3));
    // console.log(meetsKey);

    const tomorrow =
      latest?.lastUpdate?.status === PortfolioMeasureStatus.OnTrack;

    const result: BalanceGroup =
      !today && !tomorrow
        ? groupNames[0]
        : !today && tomorrow
        ? groupNames[1]
        : today && !tomorrow
        ? groupNames[2]
        : groupNames[3];

    return result;
  }

  const todayText = 'Current performance';
  const tomorrowText = 'Outlook';
  const today = ['Not satisfied', 'Satisfied'];
  const tomorrow = ['On track', 'Off track'];

  const iconSatisfied = (
    <CheckCircle
      weight="fill"
      className={`text-green-500 inline-block rounded-full w-4 h-4 flex-shrink-0`}
    />
  );
  const iconUnsatisfied = (
    <XCircle
      weight="fill"
      className={`text-gray-600 inline-block rounded-full w-4 h-4 flex-shrink-0`}
    />
  );
  const iconTarget = (
    <Target
      className="inline-block text-gray-500 mb-0.5 flex-shrink-0"
      weight="duotone"
      size={20}
    />
  );

  function balanceBreakdown(group: BalanceGroup): {
    today: string;
    tomorrow: string;
  } {
    let todayString = '';
    let tomorrowString = '';

    switch (group) {
      case 'Get the basics right':
        todayString = today[0];
        tomorrowString = tomorrow[1];
        break;
      case 'Put out fires':
        todayString = today[0];
        tomorrowString = tomorrow[0];
        break;
      case 'Invest for the future':
        todayString = today[1];
        tomorrowString = tomorrow[1];
        break;
      case 'Validate achievements':
        todayString = today[1];
        tomorrowString = tomorrow[0];
        break;

      default:
        break;
    }

    return { today: todayString, tomorrow: tomorrowString };
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState<StrategicObjective>();
  const [modalGroup, setModalGroup] = useState<BalanceGroup>();

  function handleDrillDownClick(
    objective: StrategicObjective,
    group: BalanceGroup
  ) {
    setModalData(objective);
    setModalGroup(group);
    setModalOpen(true);
  }

  // Questions and actions by group
  function getQA(group: BalanceGroup | null) {
    let questions: { question: string; actions: string[] }[] = [];

    switch (group) {
      case 'Get the basics right':
        questions = [
          {
            question: `What's driving the lack of satisfaction?`,
            actions: [
              `Diagnose the root cause of the current performance concerns.`,
            ],
          },
          {
            question: `Is this the right focus for our organisation?`,
            actions: [
              `Determine whether the benefits are still the right ones to pursue.`,
            ],
          },
          {
            question: `Where do we start to fix things?`,
            actions: [
              `Define and prioritise targeted actions to address the root causes.`,
            ],
          },
        ];
        break;
      case 'Put out fires':
        questions = [
          {
            question: `What's driving the lack of satisfaction? Is it likely to last? Do we need to intervene?`,
            actions: [
              `Understand the drivers of current performance concerns and their longer-term impact.`,
            ],
          },
          {
            question: `What can we do to fix it?`,
            actions: [
              `Develop new initiatives to address the problem.`,
              `Review the mix of existing initiatives and sequencing.`,
            ],
          },
        ];
        break;
      case 'Invest for the future':
        questions = [
          {
            question: `What's causing the bad outlook (external pressure, internal constraint, both)?`,
            actions: [
              `Pay closer attention to market conditions.`,
              `Understand the implications for our current and future operations.`,
            ],
          },
          {
            question: `Are the future targets still the right targets?`,
            actions: [`Re-evaluate the feasibility of our future targets.`],
          },
          {
            question: `Do we have the right mix of benefits to achieve this objective within the expected timeframe?`,
            actions: [`Review and adjust priorities.`],
          },
          {
            question: `How might we turn performance around?`,
            actions: [
              `Consider prioritising projects that make an impact sooner.`,
            ],
          },
        ];
        break;
      case 'Validate achievements':
        questions = [
          {
            question: `Are we really satisfied with our current performance and future outlook?`,
            actions: [
              `Check for potential reporting biases or team blindspots.`,
              `Consider setting more ambitious goals.`,
            ],
          },
          {
            question: `Do we have the right things in place?`,
            actions: [
              `Re-evaluate underlying assumptions on whether achieving the underlying program benefits will collectively achieve the strategic objective.`,
            ],
          },
          {
            question: `Have we accounted for all the risks?`,
            actions: [
              `Continue to monitor the operating environment and performance indicators, and set a threshold for potential intervention.`,
            ],
          },
        ];
        break;

      default:
        break;
    }

    return questions;
  }

  const panelTitle = (
    <DetailsPanelHeader
      title={`What needs attention?`}
      description={`${capitaliseFirstLetter(
        translate(
          strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
          'Strategic Objectives'
        ).toLowerCase()
      )} prioritised by management focus`}
    />
  );

  const panelButton = (
    <React.Fragment>
      {portfolio?.objectives && portfolio.objectives.length > 0 && (
        <ToggleButton
          label="Show reasoning"
          stateOn={showFramework}
          setStateOn={setShowFramework}
        />
      )}
    </React.Fragment>
  );

  function getBalanceGroupLayout(
    group: BalanceGroup,
    objectives: StrategicObjective[]
  ): React.ReactElement {
    const layout = (
      <ProgramDetailsContentCard title={group}>
        {/* List the matching objectives */}
        <div className="divide-y divide-gray-200 text-sm -my-4">
          {objectives?.map(objective => {
            // const latest = getLatestObjectiveUpdate(updates, objective.id);

            return (
              <div className="-mx-4" key={objective.id}>
                <div className="flex align-top gap-x-2.5">
                  <button
                    type="button"
                    className="text-left font-normal hover:bg-gray-100 px-4 py-2.5 w-full"
                    onClick={() =>
                      handleDrillDownClick(
                        objective,
                        getBalanceGroup(objective)
                      )
                    }
                  >
                    {objective.description}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </ProgramDetailsContentCard>
    );

    return layout;
  }

  const panelContent = (
    <React.Fragment>
      {/* Drill down modal */}
      {modalOpen && (
        <Modal width={'w-full max-w-6xl'} onClose={() => setModalOpen(false)}>
          <div className="bg-white h-[42rem]">
            {/* Top half */}
            <div className="py-4 px-6">
              <h3 className="text-lg font-semibold mr-8 leading-snug">
                {modalData?.description}
              </h3>
              <div className="text-sm">
                <div className="space-y-2 mt-2">
                  {/* Target */}
                  <div className="flex space-x-2">
                    <span className="-mb-0.5 inline-block">{iconTarget}</span>

                    {modalData?.targets && modalData?.targets.length > 0 ? (
                      <div className="space-y-2">
                        {modalData?.targets?.map(target => {
                          return (
                            <div
                              key={target.id}
                              className={`${
                                moment(target.achieveByDate).isBefore(
                                  new Date()
                                )
                                  ? 'text-gray-500 line-through'
                                  : ''
                              }`}
                            >{`${target?.targetValue}${
                              target?.achieveByDate
                                ? `, by ${getLocalDate(target?.achieveByDate)}`
                                : ' (someday)'
                            }`}</div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="italic text-gray-500">
                        No target provided.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Bottom half */}
            <div className="grid grid-cols-3 gap-x-6 border-t border-gray-200 px-6 py-5">
              <div className="text-sm">
                <ObjectiveSlideoverLayout
                  strategicObjective={modalData}
                  portfolioUpdates={updates || []}
                  programs={portfolio?.programs || []}
                  fixedHeight="max-h-[25rem]"
                />
              </div>
              <div className="text-sm col-span-2 h-min">
                <ProgramDetailsContentCard title="Considerations & Actions">
                  <div className="space-y-3">
                    {getQA(modalGroup || null).map((q, idx) => (
                      <div
                        key={idx}
                        className="grid grid-cols-4 border border-gray-200 rounded-md overflow-hidden"
                      >
                        <div className="font-semibold bg-secondary-800 text-white p-3">
                          {q.question}
                        </div>
                        <div className="col-span-3 bg-white p-3">
                          <ul className="list-disc list-outside ml-5">
                            {q.actions.map((item, idex2) => (
                              <li key={idex2}>{item}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </ProgramDetailsContentCard>
              </div>
            </div>
          </div>
        </Modal>
      )}

      {portfolio?.objectives && portfolio.objectives.length === 0 ? (
        <EmptyState type="strategic objectives" />
      ) : showFramework ? (
        // Matrix grid
        <table className="table w-full pl-6 pb-6">
          <tr className="table-row">
            <td className="table-cell">
              <table className="table w-full">
                <tbody className="table-row-group">
                  {tomorrow.map((tomorrowValue, index) => (
                    <tr key={`tomorrow-${index}`} className="table-row">
                      <td className="table-cell align-middle px-1 w-min relative">
                        <Tag
                          type="custom"
                          label={tomorrowValue}
                          textColour="text-gray-900"
                          bgColour=""
                          border={true}
                          decoration={
                            <span className="block mt-0.5 ml-1">
                              {tomorrowValue === tomorrow[0] ? (
                                <span
                                  className={`${getProjectMeasureStatusColour(
                                    PortfolioMeasureStatus.OnTrack
                                  )} inline-block rounded-full w-3 h-3 flex-shrink-0`}
                                />
                              ) : (
                                <span
                                  className={`${getProjectMeasureStatusColour(
                                    PortfolioMeasureStatus.HelpNeeded
                                  )} inline-block rounded-full w-3 h-3 flex-shrink-0`}
                                />
                              )}
                            </span>
                          }
                        />
                        {index === 0 && (
                          <div className="absolute -bottom-2 -left-10 text-xs text-gray-400 transform -rotate-90">
                            {tomorrowText}
                          </div>
                        )}
                      </td>
                      {today.map((todayValue, todayIdx) => {
                        const group =
                          todayValue === today[0] &&
                          tomorrowValue === tomorrow[1]
                            ? groupNames[0]
                            : todayValue === today[0] &&
                              tomorrowValue === tomorrow[0]
                            ? groupNames[1]
                            : todayValue === today[1] &&
                              tomorrowValue === tomorrow[1]
                            ? groupNames[2]
                            : groupNames[3];

                        const objectivesInGroup = objectives?.filter(
                          objective => getBalanceGroup(objective) === group
                        );

                        return (
                          <td
                            key={todayIdx}
                            className={`table-cell pl-4 pb-4 w-1/2`}
                          >
                            {getBalanceGroupLayout(
                              group,
                              objectivesInGroup ? objectivesInGroup : []
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  ))}

                  <tr className="table-row">
                    <td className="table-cell"></td>
                    {today.map((todayValue, index) => (
                      <td
                        className={`table-cell align-text-top pl-4 relative`}
                        key={`todayval-${index}`}
                      >
                        <div className="flex flex-row items-center align-middle justify-center text-sm font-medium">
                          <Tag
                            type="custom"
                            label={todayValue}
                            textColour="text-gray-900"
                            bgColour=""
                            border={true}
                            decoration={
                              <span className="block mt-0 ml-1">
                                {todayValue === today[1]
                                  ? iconSatisfied
                                  : iconUnsatisfied}
                              </span>
                            }
                          />
                        </div>
                        {index == 0 && (
                          <span className="text-xs text-gray-400 absolute -bottom-6 -right-16">
                            {todayText}
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </table>
      ) : (
        <div>
          {/* Axis */}
          <div className="flex items-center gap-x-2 text-xs mb-3 px-1">
            <div className="text-indigo-600 whitespace-nowrap font-medium">
              <Tag
                type="custom"
                bgColour="bg-indigo-600"
                textColour="text-white"
                label="Focus on these first"
              />
            </div>
            <div className="flex items-center grow mt-0.5">
              <div className="w-full h-0.5 grow bg-gradient-to-r from-indigo-500 via-gray-400 to-gray-300"></div>
              <CaretRight
                weight="bold"
                className="text-gray-300 -ml-2.5 -mr-1"
                size={16}
              />
            </div>
            <div className="text-gray-400 whitespace-nowrap font-medium text-right">
              Check on these later
            </div>
          </div>

          {/* Objectives */}
          <div className="grid grid-cols-4 gap-4">
            {groupNames.map((group, groupIdx) => {
              const objectivesInGroup = objectives?.filter(
                objective => getBalanceGroup(objective) === group
              );

              return (
                <div
                  className="flex flex-col justify-between gap-y-3"
                  key={`group-${groupIdx}`}
                >
                  {/* List relevant objectives */}
                  <div className="grow">
                    {getBalanceGroupLayout(
                      group,
                      objectivesInGroup ? objectivesInGroup : []
                    )}
                  </div>

                  {/* Show the associated tags */}
                  <div className="flex justify-center gap-x-2.5 gap-y-1 mb-2">
                    <div className="flex flex-col items-center">
                      <p className="text-gray-400 text-xs text-center mb-1">
                        {todayText}
                      </p>
                      <Tag
                        type="custom"
                        label={balanceBreakdown(group).today}
                        textColour="text-gray-900"
                        bgColour=""
                        border={true}
                        decoration={
                          <span className="block mt-0 ml-1">
                            {balanceBreakdown(group).today === today[1]
                              ? iconSatisfied
                              : iconUnsatisfied}
                          </span>
                        }
                      />
                    </div>

                    <div className="flex flex-col items-center">
                      <p className="text-gray-400 text-xs text-center mb-1">
                        {tomorrowText}
                      </p>
                      <Tag
                        type="custom"
                        label={balanceBreakdown(group).tomorrow}
                        textColour="text-gray-900"
                        bgColour=""
                        border={true}
                        decoration={
                          <span className="block mt-0.5 ml-1">
                            <span
                              className={`${
                                balanceBreakdown(group).tomorrow === tomorrow[1]
                                  ? getProjectMeasureStatusColour(
                                      PortfolioMeasureStatus.HelpNeeded
                                    )
                                  : getProjectMeasureStatusColour(
                                      PortfolioMeasureStatus.OnTrack
                                    )
                              } inline-block rounded-full w-3 h-3 flex-shrink-0`}
                            />
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );

  const layout = (
    <DetailsPanel
      headerLeft={panelTitle}
      headerRight={panelButton}
      content={panelContent}
    />
  );

  return layout;
}
