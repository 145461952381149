import React, { useState } from 'react';
import { ulid } from 'ulid';
import {
  EntityType,
  LogicModelGroup,
  Program,
  ProgramMeasure,
  ProjectUpdateConfidence,
} from '../../../api/index';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import { PROJECT } from '../../../common/routes';
import { PanelTab } from '../../../common/PanelTab';
import {
  capitaliseFirstLetter,
  getLatestProgramUpdate,
  getLatestProjectUpdate,
  getLocalDate,
  getParentProgram,
  sortProjectBenefits,
  sortProjects,
} from '../../../common/utils';
import { getStatusDot } from '../../program/PerformanceTab/HelperFunctions';
import ExpandableSlideoverCard from './ExpandableSlideoverCard';
import { getLastFourUpdates } from './HelperFunctions';

type Props = {
  slideOverProgramMeasure: ProgramMeasure | undefined;
  programs: Program[];
};

export default function ProgramBenefitsSlideoverLayout({
  slideOverProgramMeasure,
  programs,
}: Props): React.ReactElement {
  //   Pre-process tabs into groups
  const tabNames = [
    'Status',
    `Contributing ${capitaliseFirstLetter(
      enumTranslates[EntityType.Project]
    )}s`,
  ] as const;
  type tabs = typeof tabNames[number];
  const [selectedTab, setSelectedTab] = useState<tabs>(tabNames[0]);

  const benefitsView = (
    <div>
      {/* <DetailsPanelSectionDivider title={'Status'} /> */}
      {getParentProgram(programs, slideOverProgramMeasure?.id)?.updates?.items
        ?.length === 0 ? (
        <div className="border-2 border-gray-300 border-dashed rounded-md px-6 py-6">
          <EmptyState
            type={`${enumTranslates[EntityType.Program]} reviews`}
            secondaryText={`We'll show how this ${translate(
              strings.PROGRAM_MEASURE,
              'Program Benefit'
            ).toLowerCase()} is tracking once a program review has been added.`}
          />
        </div>
      ) : (
        <div className="space-y-3 text-sm">
          <div>
            <div className="font-semibold mb-2">Recent reviews</div>
            <div className="relative flex flex-row-reverse justify-center gap-x-4 mt-4">
              {getLastFourUpdates(
                getParentProgram(programs, slideOverProgramMeasure?.id)
              ).map((update, index) => {
                const benefit = update?.measures?.find(
                  item => item.measureId === slideOverProgramMeasure?.id
                );

                return update?.updateDate ? (
                  <div className="w-1/4 flex flex-col align-middle justify-start z-[1]">
                    <span className="mx-auto mb-1">
                      {getStatusDot(
                        benefit?.status as string,
                        update?.updateDate,
                        ulid()
                      )}
                    </span>
                    <div className="text-sm text-center">
                      {update?.updateDate
                        ? getLocalDate(update?.updateDate)
                        : 'No other program reviews'}
                      {index === 0 ? (
                        <div className="text-xs text-gray-400">Latest</div>
                      ) : null}
                    </div>
                  </div>
                ) : null;
              })}
              <div className="absolute top-2 w-full h-1 bg-gray-300 rounded-full"></div>
            </div>
          </div>

          <div className="">
            <div className="font-semibold mb-2">Latest progress remarks</div>
            {getLatestProgramUpdate(
              getParentProgram(programs, slideOverProgramMeasure?.id)
            )?.measures?.find(
              item => item.measureId === slideOverProgramMeasure?.id
            )?.value ? (
              <p>
                {
                  getLatestProgramUpdate(
                    getParentProgram(programs, slideOverProgramMeasure?.id)
                  )?.measures?.find(
                    item => item.measureId === slideOverProgramMeasure?.id
                  )?.value
                }
              </p>
            ) : (
              <p className="italic text-gray-500">Nothing to report</p>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const parentProgram = getParentProgram(programs, slideOverProgramMeasure?.id);

  const projectsView = (
    <div>
      {/* <DetailsPanelSectionDivider title={'Contributing Projects'} /> */}

      {parentProgram?.projects?.filter(project =>
        project.measures?.some(
          measure => measure.programMeasureId === slideOverProgramMeasure?.id
        )
      ).length == 0 ? (
        <div className="border-2 border-gray-300 border-dashed rounded-md px-6 py-6">
          <EmptyState
            type={`${enumTranslates[EntityType.Project]}s`}
            secondaryText={`No projects are contributing to this ${translate(
              strings.PROGRAM_MEASURE,
              'Program Benefit'
            ).toLowerCase()}.`}
          />
        </div>
      ) : (
        <div className="space-y-3">
          {sortProjects(
            parentProgram?.projects?.filter(project =>
              project.measures?.some(
                measure =>
                  measure.logicModelGroup === LogicModelGroup.Impacts &&
                  measure.programMeasureId === slideOverProgramMeasure?.id
              )
            )
              ? parentProgram?.projects?.filter(project =>
                  project.measures?.some(
                    measure =>
                      measure.logicModelGroup === LogicModelGroup.Impacts &&
                      measure.programMeasureId === slideOverProgramMeasure?.id
                  )
                )
              : []
          )?.map(project => {
            const projectUpdate = getLatestProjectUpdate(project);

            return (
              <ExpandableSlideoverCard
                statusIcon={
                  <div className="-mt-0.5">
                    <ConfidenceBadge
                      rating={projectUpdate?.confidenceRating}
                      reviewDate={projectUpdate?.updateDate}
                      size="shortened"
                    />
                  </div>
                }
                title={<div className="mt-0.5">{project.name}</div>}
                isExpandable={true}
                expandDefault={
                  projectUpdate?.confidenceRating ===
                    ProjectUpdateConfidence.Low ||
                  projectUpdate?.confidenceRating ===
                    ProjectUpdateConfidence.Medium
                }
                content={
                  <div className="bg-white border-t border-gray-200 divide-y divide-gray-200">
                    {(project.measures?.filter(
                      item =>
                        item.logicModelGroup === LogicModelGroup.Impacts &&
                        item.programMeasureId === slideOverProgramMeasure?.id
                    )?.length as number) > 0 ? (
                      sortProjectBenefits(
                        project.measures?.filter(
                          item =>
                            item.logicModelGroup === LogicModelGroup.Impacts &&
                            item.programMeasureId ===
                              slideOverProgramMeasure?.id
                        ) || [],
                        getLatestProjectUpdate(project)
                      )?.map(projectMeasure => {
                        const latestUpdate = getLatestProjectUpdate(project);
                        const status = latestUpdate?.measures?.find(
                          item => item.measureId === projectMeasure.id
                        )?.status;

                        return (
                          <div className="flex px-3 py-2">
                            <div className="w-6 mr-3 flex justify-center items-center">
                              {getStatusDot(
                                status as string,
                                latestUpdate?.updateDate,
                                ulid()
                              )}
                            </div>
                            {projectMeasure.name}
                          </div>
                        );
                      })
                    ) : (
                      <div className="flex px-3 py-2 text-gray-500 italic">
                        <div className="w-6 mr-3 flex justify-center items-center"></div>
                        No relevant benefits
                      </div>
                    )}
                  </div>
                }
                link={`${PROJECT}/${project.id}`}
              />
            );
          })}
        </div>
      )}
    </div>
  );

  const layout = (
    <div className="pb-12">
      {/* Tab selector */}
      <div className="w-min mx-auto">
        <div className="flex items-center justify-center rounded-md overflow-hidden mb-6 border border-gray-200 divide-x divide-gray-200">
          {tabNames.map((tab, index) => (
            <PanelTab
              selected={tabNames[index] === selectedTab}
              title={tab}
              onClick={() => setSelectedTab(tabNames[index])}
            />
            //   <button onClick={() => setTab(tabNames[index])}>{tab}</button>
          ))}
        </div>
      </div>

      {/* Content */}
      {selectedTab === tabNames[0] ? benefitsView : projectsView}
    </div>
  );

  return layout;
}
