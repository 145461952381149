import { StarredIcon, UnstarredIcon } from '../../basic/ButtonIcons';

type Props = {
  entityId: string;
  isStarred: boolean;
  starHandler: (entityId: string) => void;
};

function StarTableCell({ entityId, isStarred, starHandler }: Props) {
  const icon = isStarred ? <StarredIcon /> : <UnstarredIcon />;
  const clickHandler = () => starHandler(entityId);
  return (
    <div className={`inline-flex -mt-px`}>
      <button type="button" onClick={clickHandler} className="">
        {icon}
      </button>
    </div>
  );
}

export default StarTableCell;
