import * as React from 'react';
import { Info } from 'phosphor-react';
import translate, { strings } from '../common/i18n/translate';

function SmallScreenBanner(): React.ReactElement {
  const iconWarning = (
    <Info
      className="w-5 h-5 inline-block text-black shrink-0 mr-1.5"
      weight="bold"
      data-tip
      data-for="password-info"
    />
  );

  {
    /* Support message */
  }
  return (
    <div className="sm:hidden flex bg-yellow-100 border-b border-gray-200 justify-center items-center text-sm py-2">
      {iconWarning}{' '}
      {translate(
        strings.MOBILE_WARNING,
        'Note: JourneyLab is designed for larger screens'
      )}
    </div>
  );
}

export default SmallScreenBanner;
