/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { X } from 'phosphor-react';
import { Link } from 'react-router-dom';

type SlideOverProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  titleUrl?: string;
  titleMetadata: React.ReactElement;
  content: React.ReactElement;
};

export default function SlideOver({
  open,
  setOpen,
  title,
  titleUrl,
  titleMetadata,
  content,
}: SlideOverProps): React.ReactElement {
  //   const [open, setOpen] = useState(true);
  const iconClose = <X size={24} className="" aria-hidden="true" />;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-300 sm:duration-300"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-300 sm:duration-300"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-lg shadow-gray-400">
                    <div className="bg-white py-6 px-4 sm:px-6 border-b border-gray-200">
                      <div className="flex items-center justify-between relative">
                        {/* Title */}
                        <Dialog.Title className="text-lg font-semibold text-black mr-8 leading-snug">
                          {titleUrl ? (
                            <Link
                              to={titleUrl}
                              className={`hover:underline hover:text-primary-600 outline-none`}
                            >
                              {title}
                            </Link>
                          ) : (
                            title
                          )}
                        </Dialog.Title>

                        <div className="flex h-7 items-center absolute -top-1 right-0">
                          <button
                            type="button"
                            className="rounded-md text-gray-600 hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            {iconClose}
                          </button>
                        </div>
                      </div>

                      {/* Metadata */}
                      <div className="mt-2 text-sm">{titleMetadata}</div>
                    </div>

                    <div className="relative flex-1 py-6 px-4 sm:px-6">
                      <div className="absolute inset-0 py-6 px-4 sm:px-6">
                        <div className="h-full space-y-4" aria-hidden="true">
                          {/* Actual content */}
                          {content}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
