import React, { Dispatch, SetStateAction } from 'react';
import { FutureWaypoint, Review } from './PerformanceSlideOverContent';
import { SlideOverSectionHeader } from '../../common/slideovers/SlideOverSectionHeader';
import ConfidenceBadge from '../../basic/ConfidenceBadge';

type PerformanceSlideOverTrajectoryTabProps = {
  reviews: Review[];
  waypoints: FutureWaypoint[];
};

type TriangleDownProps = {
  width: number; // Width in pixels. Height is always half
  visible: boolean;
};

export const TriangleDown = ({ width, visible }: TriangleDownProps) => (
  <svg width={width} height={width / 2}>
    <polygon
      points={`0,0 ${width},0 ${width / 2},${width / 2}`}
      fill="black"
      stroke="none"
      className={`${visible ? '' : 'invisible'}`}
    />
  </svg>
);

const ReviewCard = ({
  review,
  reviewIndex,
  selectedReviewIndex,
  reviewsLength,
  setSelectedReviewIndex,
}: {
  review: Review;
  reviewIndex: number;
  selectedReviewIndex: number;
  reviewsLength: number;
  setSelectedReviewIndex: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <div key={review.date} className="flex flex-col items-center space-y-2">
      <button
        type="button"
        className={`${
          selectedReviewIndex === reviewIndex
            ? 'border border-gray-200 shadow'
            : 'text-gray-400 hover:bg-gray-50'
        } px-2 py-4 rounded-md`}
        onClick={() => setSelectedReviewIndex(reviewIndex)}
      >
        <div className="flex flex-col items-center space-y-1">
          {/* <div className="">{review.confidence}</div> */}
          <ConfidenceBadge
            rating={review.confidence}
            // reviewDate={review.date}
            confidenceLabel={`Confidence rating`}
          />

          <div>
            <div className="text-sm mt-2 whitespace-nowrap">{review.date}</div>
            <div
              className={`text-xs text-gray-400 mt-0.5 ${
                reviewIndex === reviewsLength - 1 ? '' : 'invisible'
              }`}
            >{`(Latest)`}</div>
          </div>
        </div>
      </button>

      <TriangleDown width={20} visible={reviewIndex === selectedReviewIndex} />
    </div>
  );
};

export default function PerformanceSlideOverTrajectoryTab({
  reviews,
  waypoints,
}: PerformanceSlideOverTrajectoryTabProps): React.ReactElement {
  const [selectedReviewIndex, setSelectedReviewIndex] = React.useState(
    reviews?.length - 1
  );

  return (
    <div className="space-y-6">
      {/* Show last four reviews */}
      <div>
        <SlideOverSectionHeader label={`Last four reviews`} />

        {/* Reviews */}
        {reviews?.length > 0 ? (
          // Show the latest reviews so users get a sense of trajectory over time
          <div className="flex justify-between w-full my-2">
            {reviews.map((review, reviewIndex) => (
              <ReviewCard
                review={review}
                key={review.id}
                reviewIndex={reviewIndex}
                reviewsLength={reviews?.length}
                selectedReviewIndex={selectedReviewIndex}
                setSelectedReviewIndex={setSelectedReviewIndex}
              />
            ))}
          </div>
        ) : (
          // Empty state
          <div className="text-gray-400 italic text-sm">No reviews found</div>
        )}
      </div>

      {/* Show remarks for the selected review */}
      <div className="">
        <SlideOverSectionHeader label={`Review commentary`} />

        {selectedReviewIndex >= 0 &&
        reviews[selectedReviewIndex]?.remarks?.length > 0 ? (
          <p className="whitespace-pre-wrap text-sm text-gray-800">
            {reviews[selectedReviewIndex]?.remarks}
          </p>
        ) : (
          // Empty state
          <div className="text-gray-400 italic text-sm">Not provided</div>
        )}
      </div>

      {/* Dividing line */}
      <div className="w-full h-px border-t border-gray-200" />

      {/* Show what is coming up for the Entity */}
      <div>
        <SlideOverSectionHeader label={`Next waypoints`} />

        {waypoints?.length > 0 ? (
          <div className="text-sm">
            <ul className="list-outside list-disc ml-6 mt-1 space-y-2">
              {waypoints.map(waypoint => (
                <li key={waypoint.date} className={``}>
                  <span className="text-black font-medium">
                    {waypoint.date}
                  </span>
                  {` - `}
                  <span className="text-gray-800">{waypoint.description}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="text-gray-400 italic text-sm">
            No future waypoints found
          </div>
        )}
      </div>
    </div>
  );
}
