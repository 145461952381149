type Props = {
  contents: string;
};

function EntitesListTargetCell({ contents }: Props) {
  return (
    <div className="inline-flex align-middle items-center text-accent-light">
      {contents}
    </div>
  );
}

export default EntitesListTargetCell;
