import React from 'react';
import restructureEntities, {
  Entity,
  GroupedEntities,
} from '../../../utils/groupingFunctions/entityListGrouping';
import ConfidenceBadge from '../../basic/ConfidenceBadge';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import BasicTable, { DataRow } from '../../common/tables/BasicTable';
import BasicTableCell from '../../common/tables/BasicTableCell';
import EscalationsTableCell from '../../common/tables/EscalationsTableCell';
import StyledTableCell from '../../common/tables/StyledTableCell';
import EntitiesListActionHeader from './EntitiesListActionHeader';
import EntitiesListSOCell from './EntitiesListSOCell';
import EntitesListTargetCell from './EntitiesListTargetCell';
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

const EntitiesTitle = () => (
  <DetailsHeaderTitle
    title={`Strategic Contribution`}
    tooltipText={`What's contributing to each strategic objective`}
  />
);

type EntitiesListContentProps = {
  entities: Array<Entity>;
  onObjectivesClick: (objectiveId: string) => void;
  onPerformanceClick: (performanceId: string) => void;
};

// TODO: This table should be grouped by the first column
function EntitiesListContent({
  entities,
  onObjectivesClick,
  onPerformanceClick,
}: EntitiesListContentProps) {
  const tableHeader: DataRow = {
    data: [
      {
        key: 'TH1',
        cellContents: <BasicTableCell contents="Strategic Objectives" />,
      },
      { key: 'TH2', cellContents: <BasicTableCell contents="Baseline" /> },
      {
        key: 'TH3',
        cellContents: <BasicTableCell contents="Latest" />,
      },
      {
        key: 'TH4',
        cellContents: <BasicTableCell contents="Target" />,
      },
      {
        key: 'TH5',
        cellContents: <BasicTableCell contents="Initiatives" />,
        widthClass: 'w-1/4',
      },
      {
        key: 'TH6',
        cellContents: (
          <StyledTableCell contents="Confidence" styleClass="text-center" />
        ),
      },
      {
        key: 'TH7',
        cellContents: <BasicTableCell contents="Escalations" />,
      },
    ],
  };

  const tableData: DataRow[] = [];

  const groups: GroupedEntities = restructureEntities(entities);
  // console.log(groups);

  for (const key in groups) {
    const group = groups[key];

    const entity = group.entities[0];
    tableData.push({
      data: [
        {
          key: 'TH1',
          classes: `hover:bg-yellow-50`,
          cellContents: (
            <div
              className="cursor-pointer"
              onClick={() => onObjectivesClick(entity.id)}
            >
              <EntitiesListSOCell contents={key} />
            </div>
          ),
          rowSpan: group.count,
        },
        {
          key: 'TH2',
          cellContents: <EntitesListTargetCell contents={entity.baseline} />,
          rowSpan: group.count,
        },
        {
          key: 'TH3',
          cellContents: <EntitesListTargetCell contents={entity.current} />,
          rowSpan: group.count,
        },
        {
          key: 'TH4',
          cellContents: <EntitesListTargetCell contents={entity.target} />,
          rowSpan: group.count,
        },
        {
          key: 'TH5',
          classes: `hover:bg-yellow-50`,
          cellContents: (
            <div
              className="cursor-pointer"
              onClick={() => onPerformanceClick(entity.id)}
            >
              <StyledTableCell
                contents={entity.name}
                styleClass={`font-medium pt-px`}
              />
            </div>
          ),
        },
        {
          key: 'TH6',
          cellContents: (
            <div className="flex justify-center">
              <ConfidenceBadge
                confidenceLabel="Confidence rating"
                rating={entity.confidence}
              />
            </div>
          ),
        },
        {
          key: 'TH7',
          cellContents: (
            <EscalationsTableCell
              entityId={entity.id}
              hasEscalations={entity.hasEscalations}
            />
          ),
        },
      ],
    });

    if (group.entities.length > 1) {
      group.entities.forEach((entity, index) => {
        if (index === 0) {
          return;
        }

        tableData.push({
          data: [
            {
              key: 'TH5',
              classes: `hover:bg-yellow-50`,
              cellContents: (
                <div
                  className="cursor-pointer"
                  onClick={() => onPerformanceClick(entity.id)}
                >
                  <StyledTableCell
                    contents={entity.name}
                    styleClass={`-ml-2 font-medium pt-px`}
                  />
                </div>
              ),
            },
            {
              key: 'TH6',
              cellContents: (
                <div className="flex justify-center">
                  <ConfidenceBadge
                    confidenceLabel="Confidence rating"
                    rating={entity.confidence}
                  />
                </div>
              ),
            },
            {
              key: 'TH7',
              cellContents: (
                <div>
                  <EscalationsTableCell
                    entityId={entity.id}
                    hasEscalations={entity.hasEscalations}
                  />
                </div>
              ),
            },
          ],
        });
      });
    }
  }

  return (
    <div className="min-w-full text-sm space-y-4 lg:space-y-3">
      {entities.length > 0 ? (
        <BasicTable
          tableData={tableData}
          tableHeader={tableHeader}
          doCellPadding={true}
        />
      ) : (
        // Empty state
        <div className="text-gray-400 italic text-center">
          No entities added yet
        </div>
      )}
    </div>
  );
}

type EntitiesListPanelProps = {
  entities: Array<Entity>;
  actionsDisabled: boolean;
  onEditClick: () => void;
  isVisible: boolean;
  onObjectivesClick: (objectiveId: string) => void;
  onPerformanceClick: (performanceId: string) => void;
};

function EntitiesListPanel({
  entities,
  actionsDisabled,
  onEditClick,
  isVisible,
  onObjectivesClick,
  onPerformanceClick,
}: EntitiesListPanelProps): React.ReactElement {
  // Set up content to show

  return (
    <DetailsHeader
      headerLeft={<EntitiesTitle />}
      headerRight={
        <EntitiesListActionHeader
          entitiesCount={entities.length}
          onClick={onEditClick}
          isVisible={isVisible}
          disabled={actionsDisabled}
        />
      }
      content={
        <EntitiesListContent
          entities={entities}
          onObjectivesClick={onObjectivesClick}
          onPerformanceClick={onPerformanceClick}
        />
      }
    />
  );
}

export default EntitiesListPanel;
