import _ from 'lodash';
import moment from 'moment';
import {
  NumberSquareOne,
  NumberSquareThree,
  NumberSquareTwo,
  Warning,
} from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import {
  EntityType,
  ExceptionStatus,
  Group,
  Portfolio,
  PortfolioMeasureStatus,
  PortfolioMember,
  PortfolioUpdate,
  PortfolioUpdateByMeasure,
  Priority,
  Program,
  ProgramMeasure,
  ProgramMeasureStatus,
  ProgramMember,
  ProgramUpdate,
  Project,
  ProjectAsk,
  ProjectMeasure,
  ProjectMeasureStatus,
  ProjectMember,
  ProjectUpdate,
  StrategicObjective,
  UpdateCadence,
  User,
} from '../api/index';
import { minimumPasswordLength } from './constants';
import translate, {
  enumTranslates,
  programConfidenceOrders,
  projectConfidenceOrders,
  statusOrders,
  strings,
} from './i18n/translate';
import { UserExt } from './types';

export function classNames(...classes: Array<string>): string {
  return classes.filter(Boolean).join(' ');
}

export function capitaliseFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function getLatestProgramUpdate(
  program: Program | null | undefined
): ProgramUpdate | null {
  if (program === undefined || program === null) {
    return null;
  }

  const publishedUpdates: Array<ProgramUpdate> =
    program.updates?.items && program.updates?.items.length > 0
      ? program.updates.items
      : [];
  const programUpdatesSorted = _.orderBy(
    [...publishedUpdates],
    ['updateDate'],
    ['desc']
  );
  const latestUpdate =
    programUpdatesSorted.length > 0 ? programUpdatesSorted[0] : null;

  return latestUpdate;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeTypename(value: any): any {
  if (value === null || value === undefined) {
    return value;
  } else if (Array.isArray(value)) {
    return value.map(v => removeTypename(v));
  } else if (typeof value === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObj: any = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
}

export function getLatestPortfolioUpdate(
  portfolio: Portfolio | null | undefined
): PortfolioUpdate | null {
  if (portfolio === undefined || portfolio === null) {
    return null;
  }

  const publishedUpdates: Array<PortfolioUpdate> =
    portfolio.updates?.items && portfolio.updates?.items.length > 0
      ? portfolio.updates.items
      : [];
  const portfolioUpdatesSorted = _.orderBy(
    [...publishedUpdates],
    ['updateDate'],
    ['desc']
  );
  const latestUpdate =
    portfolioUpdatesSorted.length > 0 ? portfolioUpdatesSorted[0] : null;

  return latestUpdate;
}

export function getLatestObjectiveUpdate(
  portfolioUpdates: Array<PortfolioUpdate> | null | undefined,
  strategicObjectiveId: string
) {
  if (portfolioUpdates === undefined || portfolioUpdates === null) {
    return null;
  }

  const publishedUpdates: Array<PortfolioUpdate> =
    portfolioUpdates && portfolioUpdates.length > 0
      ? portfolioUpdates.filter(update =>
          update.measures?.some(
            m => m.strategicObjectiveId === strategicObjectiveId
          )
        )
      : [];
  const portfolioUpdatesSorted = _.orderBy(
    [...publishedUpdates],
    ['updateDate'],
    ['desc']
  );
  const latestUpdate =
    portfolioUpdatesSorted.length > 0 ? portfolioUpdatesSorted[0] : null;

  return {
    lastUpdate:
      latestUpdate?.measures?.find(
        m => m.strategicObjectiveId === strategicObjectiveId
      ) || null,
    update: latestUpdate,
    date: latestUpdate?.updateDate,
  };
}

export function getLastFourObjectiveUpdates(
  portfolioUpdates: Array<PortfolioUpdate> | null | undefined,
  strategicObjectiveId: string
) {
  const result: (PortfolioUpdate | undefined)[] = new Array<undefined>(4);

  if (portfolioUpdates === undefined || portfolioUpdates === null) {
    return null;
  }

  const publishedUpdates: Array<PortfolioUpdate> =
    portfolioUpdates && portfolioUpdates.length > 0
      ? portfolioUpdates.filter(update =>
          update.measures?.some(
            m => m.strategicObjectiveId === strategicObjectiveId
          )
        )
      : [];
  const portfolioUpdatesSorted = _.orderBy(
    [...publishedUpdates],
    ['updateDate'],
    ['desc']
  );

  // Get first 4 items
  const foundUpdates = portfolioUpdatesSorted.slice(0, 4);
  foundUpdates.forEach(item => result.push(item));

  // Ensure there are at least 4 items
  if (foundUpdates.length < 4) {
    const delta = 4 - foundUpdates.length;

    for (let index = 0; index < delta; index++) {
      result.push(undefined);
    }
  }

  return result.slice(4);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getObjectivesAtRisk(portfolio: Portfolio): {
  objective: StrategicObjective;
  updateMeasure: PortfolioUpdateByMeasure | null;
  update: PortfolioUpdate | null;
}[] {
  const result: {
    objective: StrategicObjective;
    updateMeasure: PortfolioUpdateByMeasure | null;
    update: PortfolioUpdate | null;
  }[] = [];

  portfolio.objectives?.forEach(objective => {
    const latestUpdate = getLatestObjectiveUpdate(
      portfolio.updates?.items && portfolio.updates.items.length > 0
        ? portfolio.updates.items
        : [],
      objective.id
    );

    if (
      latestUpdate &&
      (latestUpdate.lastUpdate?.status === PortfolioMeasureStatus.Risk ||
        latestUpdate.lastUpdate?.status === PortfolioMeasureStatus.HelpNeeded)
    ) {
      result.push({
        objective: objective,
        updateMeasure: latestUpdate?.lastUpdate,
        update: latestUpdate.update,
      });
    }
  });

  return result;
}

export function getPreviousProjectUpdate(
  updateList: Array<ProjectUpdate> | undefined | null,
  update: ProjectUpdate | null
): ProjectUpdate | null {
  // Need at least 2 project updates to be meaningful
  if (!updateList || (updateList?.length as number) <= 1 || update === null) {
    return null;
  }

  // Ensure list is sorted by time
  const projectUpdatesSorted = _.orderBy(
    [...updateList],
    ['updateDate'],
    ['desc']
  );

  // Find the update just before the input arg
  const index = projectUpdatesSorted.findIndex(
    u => u.updateDate < update.updateDate
  );

  return projectUpdatesSorted[index];
}

export function getLatestProjectUpdate(
  project: Project | null | undefined
): ProjectUpdate | null {
  if (project === undefined || project === null) {
    return null;
  }

  // Only look at the published updates (not those still in draft)
  const publishedUpdates: Array<ProjectUpdate> =
    project.updates?.items && project.updates?.items.length > 0
      ? project.updates?.items
      : [];
  const projectUpdatesSorted = _.orderBy(
    [...publishedUpdates],
    ['updateDate'],
    ['desc']
  );
  const latestUpdate =
    projectUpdatesSorted.length > 0 ? projectUpdatesSorted[0] : null;

  return latestUpdate;
}

// Get project update data
export type RecentProjectUpdates = {
  project: Project;
  latestUpdate: ProjectUpdate | null;
  previousUpdate: ProjectUpdate | null;
} | null;

export function getLastTwoProjectUpdates(
  project: Project | undefined
): RecentProjectUpdates | null {
  if (project == undefined) {
    return null;
  }

  const updates = project.updates?.items;
  const sortedUpdates = updates
    ? _.orderBy([...updates], ['updateDate'], ['desc'])
    : [];
  const latestUpdate = sortedUpdates.length > 0 ? sortedUpdates[0] : null;
  const previousUpdate = sortedUpdates.length > 1 ? sortedUpdates[1] : null;

  return {
    project: project,
    latestUpdate: latestUpdate,
    previousUpdate: previousUpdate,
  };
}

// Get project update data
export type RecentProgramUpdates = {
  program: Program;
  latestUpdate: ProgramUpdate | null;
  previousUpdate: ProgramUpdate | null;
} | null;

export function getLastTwoProgramUpdates(
  program: Program | undefined
): RecentProgramUpdates | null {
  if (program == undefined) {
    return null;
  }

  const updates = program.updates?.items;
  const sortedUpdates = updates
    ? _.orderBy([...updates], ['updateDate'], ['desc'])
    : [];
  const latestUpdate = sortedUpdates.length > 0 ? sortedUpdates[0] : null;
  const previousUpdate = sortedUpdates.length > 1 ? sortedUpdates[1] : null;

  return {
    program: program,
    latestUpdate: latestUpdate,
    previousUpdate: previousUpdate,
  };
}

export function getProgramBudget(projects: Array<Project> | undefined): number {
  if (projects === undefined) {
    return 0;
  }

  const projectBudgets: Array<number> = [];
  projects.forEach(project => {
    project.budget
      ? projectBudgets.push(project.budget)
      : projectBudgets.push(0);
  });
  const programBudget = projectBudgets.reduce((a, b) => a + b);

  return programBudget;
}

export function getTotalSpend(projects: Array<Project> | undefined): number {
  if (projects === undefined) {
    return 0;
  }

  const spendByProject: Array<number> = [];
  projects.forEach(project => {
    const lastUpdate = getLatestProjectUpdate(project);
    spendByProject.push(lastUpdate?.spendToDate ? lastUpdate?.spendToDate : 0);
  });
  const totalSpend = spendByProject.reduce((a, b) => a + b);

  return totalSpend;
}

export function getLatestEndDate(
  projects: Array<Project> | undefined
): Date | null {
  if (projects === undefined || projects.length == 0) {
    return null;
  }

  // Get the delivery dates and return the latest one
  //const dates: Array<Date> = projects.map((project) => project.deliveryDate)

  const projectEndDates: Array<Date> = [];

  projects.forEach(project => {
    if (project.deliveryDate) {
      projectEndDates.push(new Date(project.deliveryDate));
    }
  });
  const projectsSorted = _.orderBy([...projects], ['deliveryDate'], ['desc']);
  const latestDate = projectsSorted[0].deliveryDate;

  // return projectEndDates.length > 0
  //   ? projectEndDates.reduce((a, b) => (a.getTime() > b.getTime() ? a : b))
  //   : null;

  return latestDate;
}

export function getBudgetIncrease(
  update: ProjectUpdate
): [delta: number, sign: string] {
  let sign = '';
  let delta = 0;

  const oldBudget = update.onbudgetValueOriginal;
  const newBudget = update.onbudgetValueNew;

  if (oldBudget != null && newBudget != null) {
    delta = newBudget - oldBudget;
  }
  if (delta >= 0) {
    sign = '+';
  } else {
    sign = '-';
  }

  return [delta, sign];
}

export function getProgramRelevantObjectives(
  program: Program | null
): Array<StrategicObjective> {
  let result: Array<StrategicObjective> = [];
  const portfolioObjectives = program?.portfolio.objectives;

  if (program && portfolioObjectives && portfolioObjectives?.length > 0) {
    result = portfolioObjectives.filter(objective =>
      program.measures?.some(
        measure => measure.strategicObjectiveId == objective.id
      )
    );
  }

  return result;
}

export function getProjectRelevantObjectives(
  project: Project | null
): Array<StrategicObjective> {
  let result: Array<StrategicObjective> = [];
  const portfolioObjectives = project?.program.portfolio.objectives;

  if (project && portfolioObjectives && portfolioObjectives?.length > 0) {
    result = portfolioObjectives.filter(objective =>
      project.measures?.some(
        measure => measure.strategicObjectiveId == objective.id
      )
    );
  }

  return result;
}

export function getUsersProjects(
  projects: Array<Project>,
  user: UserExt,
  isDemo?: boolean
): Array<Project> {
  return projects?.filter(
    project =>
      (project.members?.items as ProjectMember[])?.some(
        (member: ProjectMember) =>
          isDemo
            ? member.user.email === 'team@journeylab.io'
            : member?.user?.id == user?.attributes?.sub
      ) ||
      (project.program.members?.items as ProgramMember[])?.some(
        (member: ProgramMember) =>
          isDemo
            ? member.user.email === 'team@journeylab.io'
            : member?.user?.id === user?.attributes?.sub
      )
  );
}

export function getUsersPrograms(
  programs: Array<Program>,
  user: UserExt,
  isDemo?: boolean
): Array<Program> {
  return programs?.filter(
    program =>
      (program.members?.items as ProgramMember[])?.some(
        (member: ProgramMember) =>
          isDemo
            ? member.user.email === 'team@journeylab.io'
            : member?.user?.id == user?.attributes?.sub
      ) ||
      (program?.portfolio?.members?.items as PortfolioMember[])?.some(
        (member: PortfolioMember) =>
          isDemo
            ? member.user.email === 'team@journeylab.io'
            : member?.user?.id == user?.attributes?.sub
      )
  );
}

export function getProjectMeasureStatusColour(
  status: string | undefined | null
): string {
  let colourString = 'bg-white border border-gray-400';

  if (status) {
    switch (status) {
      case ProjectMeasureStatus.OnTrack:
        colourString = 'bg-green-500';
        break;
      case ProjectMeasureStatus.Risk:
        colourString = 'bg-yellow-400';
        break;
      case ProjectMeasureStatus.HelpNeeded:
        colourString = 'bg-red-500';
        break;
      case ProjectMeasureStatus.Paused:
        colourString = 'bg-gray-400';
        break;
      case ProjectMeasureStatus.Done:
        colourString = 'bg-blue-800';
        break;
    }
  }
  return colourString;
}

export function getExceptionStatusColour(
  exceptionStatus: ExceptionStatus | string | undefined | null
): string {
  let colourString = 'bg-gray-500';
  if (exceptionStatus) {
    switch (exceptionStatus) {
      case ExceptionStatus.GoodException:
        colourString = 'bg-green-500';
        break;
      case ExceptionStatus.BadException:
        colourString = 'bg-red-500';
        break;
    }
  }
  return colourString;
}

export function getProgramMeasureStatusColour(
  status: string | undefined | null
): string {
  let colourString = 'bg-white border border-gray-400';

  if (status) {
    switch (status) {
      case ProgramMeasureStatus.OnTrack:
        colourString = 'bg-green-500';
        break;
      case ProgramMeasureStatus.Risk:
        colourString = 'bg-yellow-400';
        break;
      case ProgramMeasureStatus.HelpNeeded:
        colourString = 'bg-red-500';
        break;
      case ProgramMeasureStatus.Paused:
        colourString = 'bg-gray-400';
        break;
      case ProgramMeasureStatus.Done:
        colourString = 'bg-blue-800';
        break;
    }
  }
  return colourString;
}

export function getUnlinkedIcon() {
  return (
    <Warning
      className="text-yellow-400 h-5 w-5"
      weight="fill"
      aria-hidden="true"
    />
  );
}

type UnlinkedProps = {
  tooltipId?: string;
};

export function UnlinkedTag({ tooltipId }: UnlinkedProps): React.ReactElement {
  return (
    <span
      className="bg-yellow-50 flex font-medium text-gray-500 items-center text-sm w-min px-2.5 py-0.5 whitespace-nowrap gap-x-2 rounded-full border border-yellow-400"
      data-tip
      data-for={tooltipId}
    >
      {getUnlinkedIcon()}
      <span className="mt-0.5">
        {translate(strings.UNMAPPED, 'Not linked')}
      </span>
    </span>
  );
}

export function getLocalDate(date: string | Date | null) {
  return date ? moment(date).format('D MMM YYYY') : 'Unknown date';
}

// Call this function, passing-in your date
export function getRelativeTime(date: string | Date | null) {
  // Get from-now for this date
  const fromNow = moment(date).fromNow();

  // Ensure the date is displayed with today and yesterday
  return moment(date).calendar(null, {
    // When the date is closer, specify custom values
    lastWeek: '[last] dddd',
    lastDay: '[yesterday]',
    sameDay: '[today]',
    nextDay: '[tomorrow]',
    nextWeek: '[this] dddd',
    // When the date is further away, use from-now functionality
    sameElse: function () {
      return '[' + fromNow + ']';
    },
  });
}

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function cadenceToDays(cadence: UpdateCadence | undefined): number {
  let result = 0;
  switch (cadence) {
    case UpdateCadence.Weekly:
      result = 7;
      break;
    case UpdateCadence.Fortnightly:
      result = 14;
      break;
    case UpdateCadence.Monthly:
      result = 28;
      break;
    default:
      break;
  }

  return result;
}

export function getConfidenceColour(
  confidence: string | undefined | null,
  shade: 'normal' | 'light'
): string {
  let colourString = 'bg-black';
  const rating = enumTranslates[confidence as string];

  switch (rating) {
    case 'High':
      shade == 'normal'
        ? (colourString = 'bg-green-500')
        : (colourString = 'bg-green-100');
      break;
    case 'Medium':
      shade == 'normal'
        ? (colourString = 'bg-yellow-400')
        : (colourString = 'bg-yellow-100');
      break;
    case 'Low':
      shade == 'normal'
        ? (colourString = 'bg-red-500')
        : (colourString = 'bg-red-100');
      break;
  }
  return colourString;
}

export function getPriorityIcon(priority: string) {
  const commonStyles = 'mr-1.5 flex-shrink-0 h-6 w-6';

  if (priority === Priority.Tier1) {
    return (
      <NumberSquareOne
        className={classNames('text-yellow-400', commonStyles)}
        weight="fill"
        aria-hidden="true"
      />
    );
  } else if (priority === Priority.Tier2) {
    return (
      <NumberSquareTwo
        className={classNames('text-gray-400', commonStyles)}
        weight="fill"
        aria-hidden="true"
      />
    );
  } else if (priority === Priority.Tier3) {
    return (
      <NumberSquareThree
        className={classNames('text-yellow-800', commonStyles)}
        weight="fill"
        aria-hidden="true"
      />
    );
  }
}

// Converts numbers to letters, starting with 1 --> A
export function numToChar(number: number | null): string {
  const output = number ? (number + 9).toString(36).toUpperCase() : '-';
  return output;
}

// Get list of project measures which are not currently linked to a valid program measure in the parent program
export function getUnlinkedProjectMeasures(
  program: Program | null,
  projects: Array<Project>
): Array<ProjectMeasure> {
  // Initialise
  const projectMeasures: Array<ProjectMeasure> =
    getAllProjectMeasures(projects);
  const unmappedMeasures: Array<ProjectMeasure> = [];

  // Get unmapped measures
  projectMeasures.forEach(measure => {
    if (
      !program?.measures?.some(
        programMeasure => programMeasure.id === measure.programMeasureId
      )
    ) {
      unmappedMeasures.push(measure);
    }
  });
  return unmappedMeasures;
}

// Get all the project measures for a given list of projects
export function getAllProjectMeasures(
  projects: Array<Project>
): Array<ProjectMeasure> {
  const result: Array<ProjectMeasure> = [];

  // Get full list of project measures
  projects.forEach(project => {
    const measures = project.measures;
    measures?.forEach(measure => result.push(measure));
  });

  return result;
}

export function sortPrograms(
  programs: Program[]
  // update: ProgramUpdate | null
): Program[] {
  const result = programs.sort((a, b) => {
    console.log('sorting programs');
    const aConfidence = getLatestProgramUpdate(a)?.trend;
    const bConfidence = getLatestProgramUpdate(b)?.trend;

    console.log(aConfidence);
    console.log(bConfidence);

    return (
      (aConfidence || aConfidence != null
        ? programConfidenceOrders[aConfidence as string]
        : 0) -
      (bConfidence || bConfidence != null
        ? programConfidenceOrders[bConfidence as string]
        : 0)
    );
  });

  return result;
}

export function sortProgramBenefits(
  benefits: ProgramMeasure[],
  update: ProgramUpdate | null
): ProgramMeasure[] {
  console.log(`sorting program benefits...`);
  console.log(benefits);
  console.log(update);

  if (update) {
    const result = benefits.sort((a, b) => {
      const aStatus = update.measures?.find(
        measure => measure.measureId === a.id
      )?.status;
      const bStatus = update.measures?.find(
        measure => measure.measureId === b.id
      )?.status;
      // console.log(aStatus);
      // console.log(bStatus);

      return (
        (aStatus || aStatus != null ? statusOrders[aStatus as string] : 0) -
        (bStatus || bStatus != null ? statusOrders[bStatus as string] : 0)
      );
    });
    return result;
  } else {
    return benefits;
  }
}

export function sortProjects(
  projects: Project[]
  // update: ProgramUpdate | null
): Project[] {
  const result = projects.sort((a, b) => {
    console.log('sorting projects');
    const aConfidence = getLatestProjectUpdate(a)?.confidenceRating;
    const bConfidence = getLatestProjectUpdate(b)?.confidenceRating;

    console.log(aConfidence);
    console.log(bConfidence);

    return (
      (aConfidence || aConfidence != null
        ? projectConfidenceOrders[aConfidence as string]
        : 0) -
      (bConfidence || bConfidence != null
        ? projectConfidenceOrders[bConfidence as string]
        : 0)
    );
  });

  return result;
}

export function sortProjectBenefits(
  benefits: ProjectMeasure[],
  update: ProjectUpdate | null
): ProjectMeasure[] {
  console.log(`sorting project benefits...`);
  console.log(benefits);
  console.log(update);

  if (update) {
    const result = benefits.sort((a, b) => {
      const aStatus = update.measures?.find(
        measure => measure.measureId === a.id
      )?.status;
      const bStatus = update.measures?.find(
        measure => measure.measureId === b.id
      )?.status;
      // console.log(aStatus);
      // console.log(bStatus);

      return (
        (aStatus || aStatus != null ? statusOrders[aStatus as string] : 0) -
        (bStatus || bStatus != null ? statusOrders[bStatus as string] : 0)
      );
    });
    return result;
  } else {
    return benefits;
  }
}

export function sortAskList(asks: ProjectAsk[]): ProjectAsk[] {
  // To order these properly so that the earliest are shown first
  // and the ones with no due date are shown last, we will need to double sort.
  // We don't expect there to be too many asks per user so this should be okay.
  const asksSortedPrelim = asks?.sort((a, b) => {
    const ad = a.dueDate ? new Date(a.dueDate).getTime() : 0;
    const bd = b.dueDate ? new Date(b.dueDate).getTime() : 0;
    return ad > bd ? 1 : -1;
  });

  // Get the max number as a resort comparison
  const latestAsk: ProjectAsk | null =
    asksSortedPrelim.length > 1
      ? asksSortedPrelim[asksSortedPrelim.length - 1]
      : null;
  const maxAskDateTime = new Date(latestAsk?.dueDate).getTime();

  // Move the ones with no due date to the end
  const asksSortedByDueDateDesc = asks?.sort((a, b) => {
    const ad = a.dueDate ? new Date(a.dueDate).getTime() : maxAskDateTime + 1;
    const bd = b.dueDate ? new Date(b.dueDate).getTime() : maxAskDateTime + 1;
    return ad > bd ? 1 : -1;
  });

  // console.log(`>> AsksSortedByDueDateDesc`);
  // console.log(asksSortedByDueDateDesc);
  return asksSortedByDueDateDesc;
}

// Helper functions
export function getParentProgram(
  programs: Program[] | null | undefined,
  measureId: string | undefined
): Program | null {
  if (!programs || !measureId) {
    return null;
  }

  const result = programs?.find(program =>
    program?.measures?.find(measure => measure.id === measureId)
  );

  if (result === undefined) {
    return null;
  }

  return result;
}

export function getLatestProgramBenefitStatus(
  measure: ProgramMeasure,
  programs: Program[] | null | undefined
): ProgramMeasureStatus | string {
  const parentProgram = getParentProgram(programs, measure.id);
  const latestUpdate = getLatestProgramUpdate(parentProgram);
  const result = latestUpdate?.measures?.find(
    m => m.measureId === measure.id
  )?.status;

  return result ? result : '';
}

export function getPermissionDescriptions(
  permission: Group,
  entity: 'portfolio' | 'program' | 'project' | 'workspace'
): string[] {
  const result: string[] = [];

  switch (permission) {
    case Group.Owner:
      result.push(`Can see, edit and delete this ${enumTranslates[entity]}`);

      if (entity === 'workspace') {
        result.push(
          `Can see, edit and delete all ${
            enumTranslates[EntityType.Portfolio]
          }s, ${enumTranslates[EntityType.Program]}s and ${
            enumTranslates[EntityType.Project]
          }s`
        );
      } else if (entity === 'portfolio') {
        result.push(
          `Can see, edit and delete all ${
            enumTranslates[EntityType.Program]
          }s and ${enumTranslates[EntityType.Project]}s`
        );
      } else if (entity === 'program') {
        result.push(
          `Can see, edit and delete child ${
            enumTranslates[EntityType.Project]
          }s`
        );
      }

      break;
    case Group.Editor:
      result.push(`Can see and edit this ${enumTranslates[entity]}`);

      if (entity === 'workspace') {
        result.push(
          `Can see, edit and delete all ${
            enumTranslates[EntityType.Portfolio]
          }s, ${enumTranslates[EntityType.Program]}s and ${
            enumTranslates[EntityType.Project]
          }s`
        );
      } else if (entity === 'portfolio') {
        result.push(
          `Can see, edit and delete all ${
            enumTranslates[EntityType.Program]
          }s and ${enumTranslates[EntityType.Project]}s`
        );
      } else if (entity === 'program') {
        result.push(
          `Can see, edit and delete child ${
            enumTranslates[EntityType.Project]
          }s`
        );
      }

      break;

    case Group.Viewer:
      result.push(`Can see this ${enumTranslates[entity]}`);
      if (entity === 'workspace') {
        result.push(
          `If access granted from this ${enumTranslates[entity]}, can see all ${
            enumTranslates[EntityType.Portfolio]
          }s, ${enumTranslates[EntityType.Program]}s and ${
            enumTranslates[EntityType.Project]
          }s`
        );
      } else if (entity === 'portfolio') {
        result.push(
          `If access granted from this ${enumTranslates[entity]}, can see all ${
            enumTranslates[EntityType.Program]
          }s and ${enumTranslates[EntityType.Project]}s`
        );
      } else if (entity === 'program') {
        result.push(
          `If access granted from this ${
            enumTranslates[entity]
          }, can see child ${enumTranslates[EntityType.Project]}s`
        );
      }

      break;

    default:
      break;
  }

  return result;
}

export function getAllUsersWithAccess(
  entity: Portfolio | Program | Project,
  portfolio?: Portfolio // Added to get the portfolio member list from projects since we can't get this from project.program.portfolio
): {
  fullAccessList: {
    member: PortfolioMember | ProgramMember | ProjectMember;
    from: Portfolio | Program | Project;
  }[];
  uniqueUserList: Array<PortfolioMember | ProgramMember | ProjectMember>;
} {
  const entityType = entity.__typename;
  const result: {
    member: PortfolioMember | ProgramMember | ProjectMember;
    from: Portfolio | Program | Project;
  }[] = [];
  const unique: Array<PortfolioMember | ProgramMember | ProjectMember> = [];

  // For each level, get items from highest to lowest entity level to make it easy to find the highest level
  switch (entityType) {
    case 'Project':
      // Also show portfolio members
      portfolio?.members?.items?.forEach(member => {
        result.push({
          member: member as PortfolioMember,
          from: portfolio,
        });

        if (!unique.some(item => item.user.id === member.user.id)) {
          unique.push(member as PortfolioMember);
        }
      });

      // Also show program members
      entity.program.members?.items?.forEach(member => {
        result.push({
          member: member as ProgramMember,
          from: entity.program,
        });

        if (!unique.some(item => item.user.id === member.user.id)) {
          unique.push(member as ProgramMember);
        }
      });

      // Start with project members
      entity.members?.items?.forEach(member => {
        result.push({ member: member as ProjectMember, from: entity });

        if (!unique.some(item => item.user.id === member.user.id)) {
          unique.push(member as ProjectMember);
        }
      });

      break;
    case 'Program':
      // Also show portfolio members
      entity.portfolio.members?.items?.forEach(member => {
        result.push({
          member: member as PortfolioMember,
          from: entity.portfolio,
        });

        if (!unique.some(item => item.user.id === member.user.id)) {
          unique.push(member as PortfolioMember);
        }
      });

      // Start with program members
      entity.members?.items?.forEach(member => {
        result.push({ member: member as ProgramMember, from: entity });

        if (!unique.some(item => item.user.id === member.user.id)) {
          unique.push(member as ProgramMember);
        }
      });

      // Also show child project members
      entity.projects?.forEach(project => {
        project.members?.items?.forEach(member => {
          result.push({
            member: member as ProjectMember,
            from: project,
          });

          if (!unique.some(item => item.user.id === member.user.id)) {
            unique.push(member as ProjectMember);
          }
        });
      });

      break;
    case 'Portfolio':
      // Start with portfolio members
      entity.members?.items?.forEach(member => {
        result.push({ member: member as PortfolioMember, from: entity });

        if (!unique.some(item => item.user.id === member.user.id)) {
          unique.push(member as PortfolioMember);
        }
      });

      // Also show child program members
      entity.programs?.forEach(program => {
        program.members?.items?.forEach(member => {
          result.push({
            member: member as ProgramMember,
            from: program,
          });

          if (!unique.some(item => item.user.id === member.user.id)) {
            unique.push(member as ProgramMember);
          }
        });
      });

      // Also show child project members
      entity.programs?.forEach(program => {
        program.projects?.forEach(project => {
          project.members?.items?.forEach(member => {
            result.push({
              member: member as ProjectMember,
              from: project,
            });

            if (!unique.some(item => item.user.id === member.user.id)) {
              unique.push(member as ProjectMember);
            }
          });
        });
      });

      break;

    default:
      break;
  }

  return { fullAccessList: result, uniqueUserList: unique };
}

// This function is to determine the permission level for any user for any entity,
// based on their permissions not just for the entity itself but for its parents and children
export function getPermissionFromTree(
  user: User,
  entity: Portfolio | Program | Project,
  parentPortfolio?: Portfolio
): {
  entityPermission: Group | undefined; // The permission level for the argument entity
  highestPermissionFrom: Portfolio | Program | Project | undefined; // The highest permission the user has in the tree
  highestPermissionLevel: Group | undefined; // The permission level for the highest level entity
  message: string; // To explain potentially unclear permissions
} {
  // Helper functions to rank and compare across levels
  function getEntityRank(entity: Portfolio | Program | Project | undefined) {
    if (entity) {
      return entity.__typename === 'Portfolio'
        ? 1
        : entity.__typename === 'Program'
        ? 2
        : entity?.__typename === 'Project'
        ? 3
        : 4;
    } else return 4;
  }

  function getPermissionRank(permission: Group | undefined) {
    if (permission) {
      return permission === Group.Owner
        ? 1
        : permission === Group.Editor
        ? 2
        : permission === Group.Viewer
        ? 3
        : 4;
    } else return 4;
  }

  // Get full access tree
  const userPermissionsInTree =
    entity.__typename === 'Project'
      ? // For projects, we need to pass in the parent portfolio object separately
        getAllUsersWithAccess(entity, parentPortfolio).fullAccessList.filter(
          item => item.member.user.id === user.id
        )
      : getAllUsersWithAccess(entity).fullAccessList.filter(
          item => item.member.user.id === user.id
        );

  // Get the permission directly from the argument entity
  const entityRank = getEntityRank(entity);
  const entityPermissionDirect = entity.members?.items?.find(
    member => member.user.id === user.id
  )?.group;

  // 1) Look up the tree
  // Find returns the first in the input array, which is deliberately ordered from highest (portfolios) to lowest (projects)
  // Hence this will give the highest level entity they have inherited permissions from
  const apexEntity =
    userPermissionsInTree.length > 0
      ? userPermissionsInTree[0].from
      : undefined;
  const apexEntityRank = getEntityRank(apexEntity);
  const apexPermissionLevel = apexEntity?.members?.items?.find(
    member => member.user.id === user.id
  )?.group;

  // Based on any parent permissions, determiné the permission for the argument entity
  const entityPermissionFromApex =
    apexEntityRank < entityRank
      ? // The user has a permission granted from a parent
        // So the user would have ownership of this entity unless they are only a viewer
        apexPermissionLevel === Group.Owner ||
        apexPermissionLevel === Group.Editor
        ? Group.Owner
        : Group.Viewer
      : undefined;

  // 2) Look down the tree
  const childPermissionsInTree = userPermissionsInTree.filter(
    item => getEntityRank(item.from) > getEntityRank(entity)
  );
  const highestChildEntity =
    childPermissionsInTree.length > 0
      ? childPermissionsInTree[0].from
      : undefined;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const highestChildPermissionLevel = highestChildEntity?.members?.items?.find(
    member => member.user.id === user.id
  )?.group;
  // If the user is a member of a child entity, they should have view access for the argument entity
  const entityPermissionFromChildren = highestChildEntity
    ? Group.Viewer
    : undefined;

  // Get the overall permission from the bottom up
  let result: Group | undefined = undefined;
  if (entityPermissionFromChildren) {
    result = entityPermissionFromChildren;
  }
  if (
    entityPermissionDirect &&
    getPermissionRank(entityPermissionDirect) < getPermissionRank(result)
  ) {
    result = entityPermissionDirect;
  }
  if (
    entityPermissionFromApex &&
    getPermissionRank(entityPermissionFromApex) < getPermissionRank(result)
  ) {
    result = entityPermissionFromApex;
  }

  // console.log(
  //   `${user.email} in ${entity.name} - Result: ${result} from ${apexEntity?.name}`
  // );

  // Explain discrepancies
  const message =
    // If a parent has granted permission...
    entity.__typename != apexEntity?.__typename &&
    // ...and that permission is defined
    apexPermissionLevel &&
    // ...and it's a different permission
    getPermissionRank(entityPermissionFromApex) <
      getPermissionRank(entityPermissionDirect)
      ? // ? `${user.firstName} ${
        //     permissionToVerb[apexPermissionLevel]
        //   } the parent ${
        //     enumTranslates[apexEntity?.__typename?.toLowerCase() as string]
        //   }`
        `Based on the parent ${
          enumTranslates[apexEntity?.__typename?.toLowerCase() as string]
        }`
      : ``;

  return {
    entityPermission: result, // The true permission level for the argument entity
    highestPermissionFrom: apexEntity, // The highest permission the user has in the tree
    highestPermissionLevel: apexPermissionLevel, // The permission level for the highest level entity
    message: message,
  };
}

export function checkPassword(password: string): {
  upper: boolean;
  lower: boolean;
  number: boolean;
  symbol: boolean;
  length: boolean;
  pass: boolean;
} {
  // Initialise
  const result = {
    upper: false,
    lower: false,
    number: false,
    symbol: false,
    length: false,
    pass: false,
    noSpace: false,
  };

  // Check conditions
  const regexUpper = /[A-Z]/g;
  const regexLower = /[a-z]/g;
  const regexNumber = /[0-9]/g;
  const regexNoSpace = /^\S*$/;
  const regexSymbol = /(?=.*[\^$*.[\]{}()?\-"!@#%&/,><’:;|_~`])/;

  // Update result
  if (password.match(regexUpper)) {
    result.upper = true;
  }
  if (password.match(regexLower)) {
    result.lower = true;
  }
  if (password.match(regexNumber)) {
    result.number = true;
  }
  if (password.match(regexSymbol)) {
    result.symbol = true;
  }
  if (password.match(regexNoSpace)) {
    result.noSpace = true;
  }
  if (password.length >= minimumPasswordLength) {
    result.length = true;
  }

  // Overall password validation
  result.pass =
    result.upper &&
    result.lower &&
    result.number &&
    result.symbol &&
    result.noSpace &&
    result.length;

  return result;
}

export function getCurrentQuarter(): string[] {
  // Initialise
  const result = [];

  // Get current time
  const now = new Date();
  const month = now.getMonth();
  const year = now.getFullYear();

  // Calculate current quarter
  const quarter = Math.floor(month / 3) + 1;
  const currentQuarter = `Q${quarter} ${year}`;
  console.log(currentQuarter);
  result.push(currentQuarter);

  // Get next three quarters
  for (let index = 1; index < 4; index++) {
    let nextQuarter = quarter + index;
    let nextQuarterYear = year;
    if (nextQuarter > 4) {
      nextQuarter = nextQuarter - 4;
      nextQuarterYear = year + 1;
    }
    const output = `Q${nextQuarter} ${nextQuarterYear}`;
    result.push(output);
  }

  console.log(result);

  return result;
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
