import React from "react";
import SlideOver from "../../common/slideovers/SlideOver";
import PerformanceSlideOverContent, {
  EntityOutcome,
  EscalatedWatchout,
  FutureWaypoint,
  Manager,
  Review as ReviewSummary,
} from "./PerformanceSlideOverContent";

type PerformanceSlideOverProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  titleUrl: string;
  description: string;
  managers: Manager[];
  endDate: string;
  outcomes: EntityOutcome[];
  reviews: ReviewSummary[];
  waypoints: FutureWaypoint[];
  escalations: EscalatedWatchout[];
};

type PerformanceSlideOverMetaProps = {
  description: string;
};

const PerformanceSlideOverMeta = ({
  description,
}: PerformanceSlideOverMetaProps) => <div>{description}</div>;

export default function PerformanceSlideOver({
  open,
  setOpen,
  title,
  titleUrl,
  description,
  managers,
  endDate,
  outcomes,
  reviews,
  waypoints,
  escalations,
}: PerformanceSlideOverProps): React.ReactElement {
  // Note: In this case it's a very simple meta component
  // For other slideovers, we may have more complex layouts

  return (
    <React.Fragment>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={title}
        titleUrl={titleUrl}
        titleMetadata={<PerformanceSlideOverMeta description={description} />}
        content={
          <PerformanceSlideOverContent
            managers={managers}
            endDate={endDate}
            outcomes={outcomes}
            reviews={reviews}
            waypoints={waypoints}
            escalations={escalations}
          />
        }
      />
    </React.Fragment>
  );
}
