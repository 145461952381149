import { Auth } from 'aws-amplify';

export const aws_config = {
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,

    region: process.env.REACT_APP_AWS_REGION,

    userPoolId: process.env.REACT_APP_USER_POOL_ID,

    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,

    cookieStorage: {
      domain: process.env.REACT_APP_DOMAIN,
      path: '/',
      secure: false,
      expires: 0.33,
    },
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  graphql_headers: async () => ({
    Authorization: (await Auth.currentSession()).getIdToken().getJwtToken(),
  }),
};
