import * as React from 'react';

import { useEffect, useReducer } from 'react';
import { toast } from 'react-toastify';

import { BillingAccount, EntityType, Workspace, WorkspaceInvitation } from '../../api/index';
import { enumTranslates } from '../../common/i18n/translate';

export type WorkspacePageState = {
  selectedWorkspace: Workspace | null;
  billingAccount: BillingAccount | null;
  workspaces: Array<Workspace>;
  loadWorkspace: boolean;
  workspaceId: string;
  invitationAccepted: boolean;
  userWorkspace: string;
  invitations: Array<WorkspaceInvitation> | null;
};

const initialState: WorkspacePageState = JSON.parse(
  localStorage.getItem('workspace') || '{}'
);

type SetSelectedWorkspaceAction = {
  type: 'SET_SELECTED_WORKSPACE';
  workspace: Workspace;
};

type SetWorkspacesAction = {
  type: 'SET_WORKSPACES';
  userWorkspace: string;
  workspaces: Array<Workspace>;
};

type SetBillingAccountAction = {
  type: 'SET_BILLING_ACCOUNT';
  billingAccount: BillingAccount;
};

type SetInvitationsAction = {
  type: 'SET_INVITATIONS';
  invitations: Array<WorkspaceInvitation> | null;
};

type SetInvitedWorkspaceAction = {
  type: 'LOAD_INVITATION_WORKSPACE';
  workspaceId?: string;
  invitationAccepted: boolean;
};

type LoadWorkspaceAction = {
  type: 'LOAD_WORKSPACE';
  loadWorkspace: boolean;
};

export type WorkspacePageAction =
  | SetSelectedWorkspaceAction
  | SetBillingAccountAction
  | SetWorkspacesAction
  | SetInvitedWorkspaceAction
  | LoadWorkspaceAction
  | SetInvitationsAction;

type WorkspacePageContextState = {
  dispatch: React.Dispatch<WorkspacePageAction>;
  state: WorkspacePageState;
};

export const WorkspacePageContext =
  React.createContext<WorkspacePageContextState>({
    dispatch: () => null,
    state: initialState,
  });

const reducer = (state: WorkspacePageState, action: WorkspacePageAction) => {
  switch (action.type) {
    case 'SET_SELECTED_WORKSPACE':
      if (action.workspace.id !== state.selectedWorkspace?.id) {
        toast.info(
          `Switched to ${enumTranslates[EntityType.Workspace]} '${
            action.workspace.name
          }'`
        );
      }
      return {
        ...state,
        selectedWorkspace: action.workspace,
      };
    case 'SET_WORKSPACES':
      return {
        ...state,
        workspaces: action.workspaces,
        userWorkspace: action.userWorkspace,
      };
    case 'SET_BILLING_ACCOUNT':
      return {
        ...state,
        billingAccount: action.billingAccount,
      };
    case 'LOAD_WORKSPACE':
      return {
        ...state,
        loadWorkspace: action.loadWorkspace,
      };
    case 'SET_INVITATIONS':
      return {
        ...state,
        invitations: action.invitations,
      };
    case 'LOAD_INVITATION_WORKSPACE':
      return {
        ...state,
        invitationAccepted: action.invitationAccepted,
        workspaceId: action.workspaceId
          ? action.workspaceId
          : state.workspaceId,
      };
    default:
      return state;
  }
};

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

export function WorkspacePageContextProvider({
  children,
}: Props): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    localStorage.setItem('workspace', JSON.stringify(state));
  }, [state]);

  return (
    <WorkspacePageContext.Provider value={{ dispatch, state }}>
      {children}
    </WorkspacePageContext.Provider>
  );
}
