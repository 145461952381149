import React from 'react';
import { Tab } from '@headlessui/react';

import HeaderWithTabsLayout from '../../common/layout/HeaderWithTabsLayout';
import { PageTab } from '../../common/PageTab';

import { ulid } from 'ulid';
import { NOT_FOUND } from '../../common/routes';
import { Navigate, useParams } from 'react-router-dom';
import { Entity } from '../../api/index';
import OverviewContainer from '../overview/OverviewContainer';
import SwimlanesContainer from '../swimlanes/SwimlanesContainer';
import WaypointsContainer from '../waypoints/WaypointsContainer';
import NorthStarTab from '../northstar/NorthStarContainer/NorthStarTab';
import { MapTrifold } from 'phosphor-react';
import { getEntityMetaData } from '../../common/wizard/payloads/MockQueryFunctions';

const OverviewIcon = () => (
  <MapTrifold size={24} weight="duotone" className="" />
);

function EntityHomePage(): React.ReactElement {
  const { entityId } = useParams<{ entityId: string }>();
  const entity = getEntityMetaData(entityId);
  const tabs = [
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <>
              <PageTab selected={selected} title={`North Star`} />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <NorthStarTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#northstar',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab selected={selected} title={`Waypoints`} />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <WaypointsContainer />
        </Tab.Panel>
      ),
      hash: '#waypoints',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab selected={selected} title={`Swimlanes`} />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <SwimlanesContainer />
        </Tab.Panel>
      ),
      hash: '#swimlanes',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`Overview`}
              icon={<OverviewIcon />}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <OverviewContainer />
        </Tab.Panel>
      ),
      hash: '#overview',
    },
  ];

  return (
    <React.Fragment>
      {entity ? (
        <HeaderWithTabsLayout
          title={entity?.name ? entity.name : 'Title'}
          entity={entity as unknown as Entity}
          tabs={tabs}
          isLoading={false}
        />
      ) : (
        <Navigate to={NOT_FOUND} />
      )}
    </React.Fragment>
  );
}

export default EntityHomePage;
