import {
  Archive,
  ArrowFatLineDown,
  ArrowFatLineUp,
  ArrowsCounterClockwise,
  Bell,
  CalendarPlus,
  Chat,
  Check,
  Circle,
  CircleDashed,
  CircleHalf,
  Funnel,
  ListChecks,
  PencilSimple,
  Plus,
  Star,
  ToggleLeft,
  ToggleRight,
  Trash,
  TreeStructure,
  UserCirclePlus,
} from 'phosphor-react';

const buttonIconClassBase = 'h-5 w-5 shrink-0';
const buttonIconClassGray = `${buttonIconClassBase} text-gray-500`;
const buttonIconClassGrayLight = `${buttonIconClassBase} text-gray-300 hover:text-gray-400`;
const buttonIconClassRed = `${buttonIconClassBase} text-red-600`;
const buttonIconClassPrimary = `${buttonIconClassBase} text-primary-500`;
const buttonIconClassYellow = `${buttonIconClassBase} text-yellow-400`;
const buttonIconSize = 20;

export const AddIcon = () => (
  <Plus
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);
export const EditIcon = () => (
  <PencilSimple
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);
export const UpdateIcon = () => (
  <ArrowsCounterClockwise
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);
export const FilterIcon = () => (
  <Funnel
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="fill"
    aria-hidden="true"
  />
);

// Escalations
export const EscalateIcon = () => (
  <ArrowFatLineUp
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="duotone"
    aria-hidden="true"
  />
);
export const DeescalateIcon = () => (
  <ArrowFatLineDown
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="duotone"
    aria-hidden="true"
  />
);
export const ReminderIcon = () => (
  <Bell
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);
export const AgreedActionsIcon = () => (
  <ListChecks
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);
export const ArchiveIcon = () => (
  <Archive
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);

export const CommentIcon = () => (
  <Chat
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="fill"
    aria-hidden="true"
  />
);
export const ParentIcon = () => (
  <TreeStructure
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);

export const ResolveIcon = () => (
  <Check
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="bold"
    aria-hidden="true"
  />
);

// Starred
export const StarredIcon = () => (
  <Star
    size={buttonIconSize}
    className={buttonIconClassYellow}
    weight="fill"
    aria-hidden="true"
  />
);
export const UnstarredIcon = () => (
  <Star
    size={buttonIconSize}
    className={buttonIconClassGrayLight}
    weight="bold"
    aria-hidden="true"
  />
);

// Toggle buttons
export const ToggleOffIcon = () => (
  <ToggleLeft
    size={buttonIconSize}
    className={buttonIconClassGray}
    weight="fill"
    aria-hidden="true"
  />
);
export const ToggleOnIcon = () => (
  <ToggleRight
    size={buttonIconSize}
    className={buttonIconClassPrimary}
    weight="fill"
    aria-hidden="true"
  />
);

// Dot menu items
export const DeleteIcon = () => (
  <Trash
    size={buttonIconSize}
    weight="bold"
    className={buttonIconClassRed}
    aria-hidden="true"
  />
);
export const AssignIcon = () => (
  <UserCirclePlus
    size={buttonIconSize}
    weight="bold"
    className={buttonIconClassGray}
    aria-hidden="true"
  />
);
export const DeadlineIcon = () => (
  <CalendarPlus
    size={buttonIconSize}
    weight="bold"
    className={buttonIconClassGray}
    aria-hidden="true"
  />
);

// Waypoint checklist progress buttons
export const NaIcon = () => (
  <CircleDashed
    size={buttonIconSize}
    className={buttonIconClassBase}
    weight="bold"
    aria-hidden="true"
  />
);

export const EmptyCircleIcon = () => (
  <Circle
    size={buttonIconSize}
    className={buttonIconClassBase}
    weight="bold"
    aria-hidden="true"
  />
);

export const PartialIcon = () => (
  <CircleHalf
    size={buttonIconSize}
    className={`${buttonIconClassBase} rotate-90`}
    weight="fill"
    aria-hidden="true"
  />
);

export const FullIcon = () => (
  <Circle
    size={buttonIconSize}
    className={buttonIconClassBase}
    weight="fill"
    aria-hidden="true"
  />
);
