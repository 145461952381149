import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';

import { Bell, BellRinging } from 'phosphor-react';

import {
  Notification,
  OnNotifiedDocument,
  OnNotifiedSubscription,
  useGetMyNotificationsLazyQuery,
  useReadNotificationMutation,
} from '../api/index';
import { useNavigate } from 'react-router-dom';
import NotificationItem from '../components/notifications/NotificationItem';
import * as ROUTES from '../common/routes';
import { useSubscription } from '@apollo/client';
import { UserContext } from '../context/UserContext';
import EmptyState from './layout/EmptyState';

type PopoverCloseType = {
  close: () => void;
};

function getNotificationIcon(notifications: Array<Notification> | undefined) {
  const iconNewNotifications = (
    <BellRinging
      className={`h-6 w-6 shrink-0 text-red-500 group-hover:text-red-600`}
      weight="duotone"
      aria-hidden="true"
    />
  );
  const iconNoNotifications = (
    <Bell className={`h-6 w-6 shrink-0`} weight="duotone" aria-hidden="true" />
  );

  let icon = null;
  if (notifications?.some(n => !n.read)) {
    icon = (
      <span className="group">
        {iconNewNotifications}
        <span>
          <span className="animate-ping absolute h-2 w-2 rounded-full bg-red-400 opacity-75 bottom-1.5 left-4"></span>
          <span className="absolute rounded-full h-2 w-2 bg-red-500 bottom-1.5 left-4"></span>
        </span>
      </span>
    );
  } else {
    // No notifications
    icon = iconNoNotifications;
  }

  return icon;
}

function NotificationsDropdown(): React.ReactElement {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [view, { loading: loadingView, error: errorView }] =
    useReadNotificationMutation();
  const userId = user?.attributes?.sub || '';
  const config = useMemo(
    () => ({
      subscription: OnNotifiedDocument,
      options: { variables: { userId } },
    }),
    [userId]
  );

  const { data: notificationsData } = useSubscription<OnNotifiedSubscription>(
    config.subscription,
    config.options
  );

  const pageSize = 5;

  const [getMyOpenNotificationsQuery, { data, loading, error }] =
    useGetMyNotificationsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        limit: pageSize,
      },
    });

  useEffect(() => {
    getMyOpenNotificationsQuery();
  }, [getMyOpenNotificationsQuery, notificationsData?.onNotified?.message]);

  async function markAllRead() {
    await notifications?.forEach(n => {
      if (n.read === false) {
        view({
          variables: {
            id: n.id,
          },
        });
      }
    });
    getMyOpenNotificationsQuery();
  }
  useEffect(() => {
    if (data?.getMyNotifications?.items) {
      setNotifications(data?.getMyNotifications?.items);
    }
  }, [data]);

  if (loading || loadingView) {
    return <React.Fragment>{/* Don't show anything yet */}</React.Fragment>;
  }

  if (error || errorView) {
    return <span>Error: `${error?.message || errorView?.message}`</span>;
  }

  return (
    <Popover className="relative">
      <React.Fragment>
        <Popover.Button
          type="button"
          className="bg-white m-2 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <span className="sr-only">View notifications</span>
          <span>{getNotificationIcon(notifications)}</span>
        </Popover.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 right-0 transform translate-x-8 mt-4 w-screen max-w-md max-h-screen overflow-auto rounded shadow-md">
            <div className="rounded-lg overflow-hidden shadow-md ring-1 ring-primary-600 ring-opacity-5 border border-gray-200">
              <div className="relative bg-white divide-y divide-gray-200">
                <React.Fragment>
                  {notifications && notifications.length > 0 ? (
                    notifications.map((notification, idx) => (
                      // !notification.read &&
                      <NotificationItem
                        notification={notification}
                        key={idx}
                        refresh={() => {
                          getMyOpenNotificationsQuery();
                        }}
                      />
                    ))
                  ) : (
                    <div className="text-sm px-6 py-4">
                      <EmptyState
                        type="notifications"
                        secondaryText={`You'll get notified here when there are relevant updates`}
                      />
                    </div>
                  )}
                </React.Fragment>
              </div>
              <div className="px-2 py-1 bg-gray-100 border-t border-gray-200">
                <div className="text-sm">
                  <Popover.Panel>
                    {(popoverClose: PopoverCloseType) => (
                      <div className="flex flex-row space-x-2 justify-center">
                        <button
                          onClick={() => {
                            navigate(ROUTES.NOTIFICATION);
                            popoverClose.close();
                          }}
                          className="font-medium rounded px-4 py-2 text-primary-600 hover:text-primary-700 hover:bg-gray-200 transition ease-in-out duration-150 whitespace-nowrap"
                        >
                          See all notifications
                        </button>

                        {notifications && notifications.some(n => !n.read) && (
                          <button
                            onClick={() => {
                              markAllRead();
                            }}
                            className="font-medium rounded px-4 py-2 text-primary-600 hover:text-primary-700 hover:bg-gray-200 transition ease-in-out duration-150 whitespace-nowrap"
                          >
                            Mark all notifications as read
                          </button>
                        )}
                      </div>
                    )}
                  </Popover.Panel>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </React.Fragment>
    </Popover>
  );
}

export default NotificationsDropdown;
