import * as React from 'react';
import translate, {
  statusOrders,
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import {
  ProjectMeasureStatus,
  ProjectMeasure,
  ProjectUpdate,
  Project,
  ProgramMeasure,
  ExceptionStatus,
  ProgramUpdate,
  ProjectStage,
  EntityType,
} from '../../../api/index';
import {
  getLocalDate,
  getProjectMeasureStatusColour,
  classNames,
  capitaliseFirstLetter,
  getProgramRelevantObjectives,
  getRelativeTime,
} from '../../../common/utils';
import { getLatestProjectUpdate } from '../../../common/utils';
import { Target, ChartLine, CirclesFour } from 'phosphor-react';
import ReactTooltip from 'react-tooltip';
import EmptyState from '../../../common/layout/EmptyState';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import DetailsPanelSectionDivider from '../../../common/DetailsPanelSectionDivider';
import ExceptionIcon from '../../project/ExceptionIcon';
import { ProgramExt } from '../ReportPage';

export default function ProgramSnapshotReport({
  includedProjects: originalIncluded,
  program,
}: {
  includedProjects: Array<Project>;
  program: ProgramExt;
}): React.ReactElement {
  const [filterExceptions, setFilterExceptions] = useState(false);
  const latestUpdate: ProgramUpdate = program.latestUpdate;
  const includedProjects: Array<Project> = originalIncluded.filter(
    project => project.stage != ProjectStage.Inplanning
  );
  type ProjectMeasureData = {
    measure: ProjectMeasure;
    status: ProjectMeasureStatus;
    update: ProjectUpdate;
    remarks: string;
    exceptionStatus: ExceptionStatus;
  };

  const targetIcon = (
    <Target
      className="inline-block mr-1 mb-0.5"
      color="#7D8390"
      weight="duotone"
      size={20}
    />
  );

  const dotsIcon = (
    <CirclesFour
      className="inline-block"
      color="#7D8390"
      weight="fill"
      size={20}
    />
  );

  function getProjectMeasureData() {
    // Raw highlights data format
    const highlightsData: Array<ProjectMeasureData> = [];

    // Get project measures
    const allMeasures: Array<ProjectMeasure> = [];
    if (includedProjects) {
      includedProjects.forEach(project =>
        project.measures?.forEach(measure => allMeasures.push(measure))
      );
    }

    // Get latest project updates for all projects in the current program
    const latestProjectUpdates = includedProjects.map(project =>
      getLatestProjectUpdate(project)
    );

    // Get status for each measure
    allMeasures.map(m => {
      const update = latestProjectUpdates.find(update =>
        update?.measures?.some(measure => measure.measureId == m.id)
      );
      const foundMeasure = update?.measures?.find(
        measure => measure.measureId == m.id
      );

      const status = foundMeasure?.status;
      const remark = foundMeasure?.remarks ? foundMeasure.remarks : '';
      const exceptionStatus = foundMeasure?.exceptionStatus
        ? foundMeasure.exceptionStatus
        : ExceptionStatus.NoException;

      if (update && status) {
        const data = {
          measure: m,
          status: status,
          update: update,
          remarks: remark,
          exceptionStatus: exceptionStatus,
        };
        highlightsData.push(data);
      }
    });

    return highlightsData;
  }

  function ProgramMeasureCard(
    programMeasure: ProgramMeasure
  ): React.ReactElement {
    const layout =
      programMeasure === null ? (
        <div className="ml-3 text-sm text-gray-500 italic">
          Not a focus for this program
        </div>
      ) : (
        <div className="flex flex-col shadow rounded-md">
          <div className="flex items-center px-3 py-2 bg-gray-100">
            <div className="flex text-sm font-medium text-gray-900">
              <span
                className={classNames(
                  getProjectMeasureStatusColour(
                    latestUpdate?.measures?.find(
                      m => m.measureId === programMeasure.id
                    )?.status
                  ),
                  'flex-shrink-0 inline-block h-3 w-3 rounded-full ml-0 mr-2 mt-1.5'
                )}
                aria-hidden="true"
              />
              <div className="mt-0">
                {programMeasure.name}
                <div className="mt-1">
                  <span className="text-gray-500 font-normal inline-block mr-3">
                    <ChartLine
                      className="inline-block mr-1 mb-1"
                      color="#7D8390"
                      weight="duotone"
                      size={20}
                    />
                    {programMeasure.id &&
                    latestUpdate?.measures?.find(
                      m => m.measureId === programMeasure.id
                    )?.value &&
                    (latestUpdate?.measures?.find(
                      m => m.measureId === programMeasure.id
                    )?.value?.length as number) > 0
                      ? latestUpdate?.measures?.find(
                          m => m.measureId === programMeasure.id
                        )?.value
                      : '(No update)'}
                  </span>
                  <span className="text-gray-500 font-normal inline-block">
                    {targetIcon}
                    {!programMeasure.targetValue
                      ? '(No target)'
                      : `${programMeasure.targetValue}${
                          programMeasure.achieveByDate
                            ? ', by ' +
                              getLocalDate(programMeasure.achieveByDate)
                            : ' (no target date)'
                        }`}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );

    return layout;
  }

  function getParentProject(measureToFind: ProjectMeasure | undefined) {
    if (measureToFind === undefined) {
      return null;
    }
    const foundProject = includedProjects.find(project =>
      project?.measures?.find(
        (measure: ProjectMeasure) => measure.id == measureToFind.id
      )
    );
    if (foundProject === undefined) {
      return null;
    } else {
      return foundProject;
    }
  }

  function getProjectStatusDots(
    programMeasure: ProgramMeasure
  ): React.ReactElement {
    // Use updated data getter
    const allData = filterExceptions
      ? getProjectMeasureData().filter(
          m => m.exceptionStatus !== ExceptionStatus.NoException
        )
      : getProjectMeasureData();

    // Filter by programMeasure
    const dataByProgramMeasure = allData.filter(
      item => item.measure.programMeasureId === programMeasure.id
      // ...and exclude paused (removed)
      // && item.status != ProjectMeasureStatus.Paused
    );

    // Sort by status
    filterExceptions
      ? dataByProgramMeasure.sort((a, b) =>
          (a.status ? statusOrders[a.exceptionStatus] : 0) >
          (b.status ? statusOrders[b.exceptionStatus] : 0)
            ? 1
            : -1
        )
      : dataByProgramMeasure.sort((a, b) =>
          (a.status ? statusOrders[a.status] : 0) >
          (b.status ? statusOrders[b.status] : 0)
            ? 1
            : -1
        );

    // Layout for default view
    // Show as a series of dots to give users a quick visual overview
    const showAllView: React.ReactElement = (
      <div className="flex items-center">
        <div className="flex w-full text-sm font-medium text-gray-900">
          {allData.length == 0 ? (
            <div className="text-gray-500 italic font-normal">
              No relevant data at this time
            </div>
          ) : (
            dataByProgramMeasure.map((item, idx) => (
              <span key={idx} className="mr-2 mb-1">
                {/* Get statuses and show as dots - based on view */}
                <span data-tip data-for={item.measure.id}>
                  <span
                    className={`${getProjectMeasureStatusColour(
                      item.status
                    )} inline-block rounded-full w-3 h-3`}
                  />
                </span>

                {/* Add tooltip to show more info on hover */}
                <ReactTooltip id={item.measure.id} effect="solid">
                  <div>
                    <p className="text-base">{item.measure.name}</p>
                    <p>
                      {enumTranslates[item.status]} •{' '}
                      {capitaliseFirstLetter(
                        getRelativeTime(item.update.updateDate)
                      )}
                    </p>
                    <p className="text-xs text-gray-200 mb-1 font-normal">
                      Project: {getParentProject(item.measure)?.name}
                    </p>
                  </div>
                </ReactTooltip>
              </span>
            ))
          )}
        </div>
      </div>
    );

    // Layout for exceptions view
    // Show this as a vertical list to provide more detail
    const showExceptionsView: React.ReactElement = (
      <div className="flex items-center">
        <div className="flex flex-col w-full text-sm text-gray-900 space-y-3">
          {allData.length == 0 ? (
            <div className="text-gray-500 italic font-normal">
              No relevant data at this time
            </div>
          ) : (
            dataByProgramMeasure.map((item, idx) => (
              <div key={idx} className="flex mb-0">
                {/* Get statuses and show icon */}
                <span data-tip data-for={item.measure.id}>
                  <ExceptionIcon exceptionStatus={item.exceptionStatus} />
                </span>
                <div className="flex flex-col ml-2">
                  <div className="font-medium">{item.measure.name}</div>

                  {item.remarks.length > 0 ? (
                    <div className="flex text-sm font-normal text-gray-500 align-top mt-0.5">
                      {item.remarks}
                    </div>
                  ) : null}
                </div>

                {/* Add tooltip to show more info on hover */}
                <ReactTooltip id={item.measure.id} effect="solid">
                  <div>
                    <p className="text-base">{item.measure.name}</p>
                    <p>
                      {enumTranslates[item.exceptionStatus]} •{' '}
                      {capitaliseFirstLetter(
                        getRelativeTime(item.update.updateDate)
                      )}
                    </p>
                    <p className="text-xs text-gray-200 mb-1 font-normal">
                      Project: {getParentProject(item.measure)?.name}
                    </p>
                  </div>
                </ReactTooltip>
              </div>
            ))
          )}
        </div>
      </div>
    );

    return filterExceptions ? showExceptionsView : showAllView;
  }

  const snapshotTitle = (
    <DetailsPanelHeader
      title={translate(
        strings.PROGRAM_SNAPSHOT,
        'Title of card on program details page'
      )}
      description={translate(
        strings.PROGRAM_SNAPSHOT_DESCRIPTION,
        'Description of card on program details page'
      )}
    />
  );

  const snapshotButton = (
    <div
      className="flex flex-row ml-4 flex-shrink-0 gap-x-3"
      // Adding the key forces the tooltip to rebuild on toggle
      key={filterExceptions ? 'view-all' : 'view-exceptions'}
    >
      {/* Hiding for now - having multiple dates on the page can confuse users */}
      {/* <div className="m-auto">
        Update Date: {moment(latestUpdate?.updateDate).format('D MMM YYYY')}
      </div> */}

      {/* Toggle viewing of exceptions*/}
      <button
        type="button"
        className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        onClick={() => setFilterExceptions(!filterExceptions)}
        data-tip
        data-for={'view-toggle-button'}
      >
        {filterExceptions ? (
          <span className="block items-center -ml-1 mr-2 -my-1">
            {dotsIcon}
          </span>
        ) : (
          <span className="block items-center -ml-1 mr-2">
            <ExceptionIcon exceptionStatus={null} />
          </span>
        )}
        {filterExceptions ? 'View all' : 'View exceptions'}
      </button>
      {/* Add tooltip to explain what the button does */}
      <ReactTooltip
        id={'view-toggle-button'}
        effect="solid"
        getContent={() => {
          return filterExceptions
            ? `See progress of all ${translate(
                strings.PROJECT_MEASURES,
                'Project Measures'
              ).toLowerCase()}`
            : `Only show what teams have marked as exceptions`;
        }}
      />
    </div>
  );

  const unmappedIcon = (
    <ExclamationTriangleIcon
      className="text-yellow-500 -ml-0.5 mr-2 h-5 w-5"
      aria-hidden="true"
      data-tip
      data-for={'unmapped-icon'}
    />
  );

  const overview = (
    <div className="space-y-6">
      {/* Program update */}

      <div className="mb-8">
        <DetailsPanelSectionDivider title="Summary" />
        {/* <div className="flex flex-grow justify-between mb-3">

          <div className="flex align-center">
            <Article className="w-6 h-6 mr-3" />
            <span>Summary</span>
          </div>
          <div className="ml-4 flex-shrink-0">
            <span className="relative z-0 inline-flex shadow-sm rounded-md gap-x-4">
              {hasEditPermissions && !isDemo
                ? OverflowMenu(setFilterExceptions, filterExceptions)
                : null}
            </span>
          </div> 
        </div>*/}

        {latestUpdate === undefined ? (
          <EmptyState type={`${enumTranslates[EntityType.Program]} reviews`} />
        ) : (
          <table className="table min-w-full text-sm">
            <tr className="table-row align-top border-t border-gray-200">
              <td className="table-cell pr-8 font-semibold whitespace-nowrap w-32">
                Delivery confidence:
              </td>
              <td className="table-cell">
                <ConfidenceBadge
                  rating={latestUpdate?.trend}
                  reviewDate={latestUpdate?.updateDate}
                />
              </td>
            </tr>
            <tr className="table-row align-top border-t border-gray-200">
              <td className="table-cell pt-2 pr-8 font-semibold whitespace-nowrap">
                How we're going:
              </td>
              <td className="table-cell pt-2">{latestUpdate?.remark}</td>
            </tr>
          </table>
        )}
      </div>

      {/* Performance Summary */}
      <div>
        <DetailsPanelSectionDivider title="Path to Outcomes" />

        {/* Updated table */}
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          {/* Tailwind grid table style */}
          <table className="table min-w-full divide-y divide-gray-200">
            <thead className="table-header-group bg-gray-50">
              {/* Header row */}
              <tr className="table-row">
                <td className="table-cell md:w-1/4 pl-4 pr-8 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-middle">
                  <p>
                    Relevant{' '}
                    {translate(
                      strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                      'Portfolio Objectives'
                    )}
                  </p>
                  <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                    Which part of the strategy these contribute to
                  </p>
                </td>
                <td className="table-cell pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-middle">
                  <div className="grid grid-cols-2">
                    <div>
                      <p>
                        {translate(
                          strings.PROGRAM_MEASURES,
                          'Program Measures'
                        )}
                      </p>
                      <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                        The business benefits this program will realise
                      </p>
                    </div>

                    <div className="pl-4">
                      {filterExceptions ? (
                        <>
                          <p>
                            {translate(
                              strings.PROJECT_EXCEPTIONS,
                              'Project Exceptions'
                            )}
                          </p>
                          <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                            Each dot represents the current state of one{' '}
                            {translate(
                              strings.PROJECT_MEASURE,
                              'Project Measure'
                            ).toLowerCase()}
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            {translate(
                              strings.PROJECT_MEASURES,
                              'Project Measures'
                            )}
                          </p>
                          <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                            Each dot represents the current state of one{' '}
                            {translate(
                              strings.PROJECT_MEASURE,
                              'Project Measure'
                            ).toLowerCase()}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </td>
              </tr>
            </thead>

            {/* Table data */}
            <tbody className="table-row-group bg-white divide-y divide-gray-200">
              {program &&
                getProgramRelevantObjectives(program).map(
                  (objective, oIndex) => (
                    // Create a row for each programMeasure
                    <tr className="table-row align-top border-t border-gray-200">
                      {/* Portfolio Measure */}
                      <td
                        className={classNames(
                          oIndex > 0 ? '' : '',
                          'table-cell align-top pl-4 pr-8 py-3 whitespace-normal border-t border-gray-200'
                        )}
                      >
                        <p className="text-sm font-semibold text-gray-900">
                          {objective.description}
                        </p>
                        <div>
                          <span className="flex align-top mt-1 text-gray-500 text-sm">
                            {targetIcon}
                            <p>
                              {!objective.targets ||
                              objective.targets.length < 1
                                ? 'No targets set'
                                : `${objective.targets[0]?.targetValue}${
                                    objective.targets[0]?.achieveByDate
                                      ? ', by ' +
                                        getLocalDate(
                                          objective.targets[0]?.achieveByDate
                                        )
                                      : ' (no target date)'
                                  }`}
                            </p>
                          </span>
                        </div>
                      </td>

                      {/* Program measures */}
                      {program?.measures
                        ?.filter(
                          programMeasure =>
                            programMeasure.strategicObjectiveId === objective.id
                        )
                        .map((programMeasure, idx) => (
                          <React.Fragment key={idx}>
                            <td
                              className={classNames(
                                oIndex > 0 ? '' : '',
                                'table-cell px-2 py-3 whitespace-normal border-t border-gray-200'
                              )}
                            >
                              {ProgramMeasureCard(programMeasure)}
                            </td>
                            <td
                              className={classNames(
                                oIndex > 0 ? '' : '',
                                'table-cell px-4 py-3 whitespace-normal border-t border-gray-200'
                              )}
                            >
                              <div className="pt-1">
                                {getProjectStatusDots(programMeasure)}
                              </div>
                            </td>
                          </React.Fragment>
                        ))}
                    </tr>
                  )
                )}

              {/* Also show unmapped items, if they exist */}
              {(program?.measures?.filter(
                measure =>
                  !program?.portfolio.objectives?.some(
                    m => m.id === measure.strategicObjectiveId
                  )
              ).length as number) > 0 ? (
                <tr className="table-row align-top border-t border-gray-200">
                  {/* Portfolio Measure */}
                  <td className="table-cell pl-4 pr-8 py-3 whitespace-normal border-t border-gray-200">
                    <span className="flex items-center text-gray-500 italic text-sm">
                      {unmappedIcon}{' '}
                      {translate(
                        strings.PROGRAM_BENEFITS_NOT_LINKED,
                        'Not linked'
                      )}
                    </span>
                    <ReactTooltip
                      id={'unmapped-icon'}
                      place="top"
                      type="warning"
                      effect="solid"
                      // delayShow={tooltipDelay}
                    >
                      <div className="text-sm text-center not-italic">
                        <p>
                          These aren't currently linked to a{' '}
                          {translate(
                            strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                            'Portfolio Objective'
                          ).toLowerCase()}{' '}
                          so they won't roll up properly.
                        </p>
                        <p>
                          You can update this through the wizard on the Program
                          Setup tab.
                        </p>
                      </div>
                    </ReactTooltip>
                  </td>

                  {/* Program measures */}
                  {program?.measures
                    ?.filter(
                      measure =>
                        !program?.portfolio.objectives?.some(
                          m => m.id === measure.strategicObjectiveId
                        )
                    )
                    .map((programMeasure, oIndex) => (
                      <>
                        <td
                          className={classNames(
                            oIndex > 0 ? '' : '',
                            'table-cell px-2 py-3 whitespace-normal border-t border-gray-200'
                          )}
                        >
                          {ProgramMeasureCard(programMeasure)}
                        </td>
                        <td
                          className={classNames(
                            oIndex > 0 ? '' : '',
                            'table-cell px-4 py-3 whitespace-normal border-t border-gray-200'
                          )}
                        >
                          <div className="pt-1">
                            {getProjectStatusDots(programMeasure)}
                          </div>
                        </td>
                      </>
                    ))}
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const snapshotContent = <>{overview}</>;

  return (
    <div className="flex flex-col gap-y-6">
      <DetailsPanel
        headerLeft={snapshotTitle}
        headerRight={snapshotButton}
        content={snapshotContent}
      />
    </div>
  );
}
