export const backgroundColors = [
  'bg-indigo-600', // #4f46e5 - Note: It seems to start from the second colour in the array
  'bg-primary-500', // 0db7bd
  'bg-accent-light', // 04579A
  'bg-gray-500', // #64748b
  'bg-green-600', // #059669
];

// bg-red-200: #FDE8E9
// bg-yellow-200: #FEF3C7
// bg-green-200: #D1FAE5
// bg-blue-200: #DBEAFE
// bg-pink-200: #FCE7F3
// bg-indigo-300: #C7D2FE
// bg-orange-200: #FEEBC8

export const backgroundColorsCss = [
  '#ccc9f7',
  '#8df3f7',
  '#b0dafd',
  '#dcdfe5',
  '#9dfbdd',
];

export function darkenColor(color: string): string {
  // Parse the red, green, and blue components of the color
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);
  const darkness = 0.8;

  // Calculate the new red, green, and blue components after darkening by 20%
  r = Math.max(0, Math.floor(r * darkness));
  g = Math.max(0, Math.floor(g * darkness));
  b = Math.max(0, Math.floor(b * darkness));

  // Convert the new components to a hex color string
  const newColor =
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0');
  return newColor;
}
