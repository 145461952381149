import React from 'react';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import PageHeader from './PageHeader';
import { Gift, Lifebuoy, Strategy } from 'phosphor-react';
import graphic1 from './graphics/01_concept.png';
import translate, { strings } from '../../common/i18n/translate';

function SupportCentre(): React.ReactElement {
  type TileStyle = 'solid' | 'dotted';
  type tileData = {
    title: string;
    subtitle: string;
    url: string;
    icon: JSX.Element;
    style: TileStyle;
  };

  const iconClass = 'w-8 h-8 flex-shrink-0 my-1';
  const iconGuides = <Lifebuoy weight="duotone" className={iconClass} />;
  const iconProduct = <Gift weight="duotone" className={iconClass} />;
  const iconS2E = <Strategy weight="duotone" className={iconClass} />;

  const getStartedLinks: Array<tileData> = [
    {
      title: `${translate(strings.BRAND_NAME, 'JourneyLab')} Guides`,
      subtitle: `Everything you need to know to get started with ${translate(
        strings.BRAND_NAME,
        'JourneyLab'
      )}`,
      url: 'https://journeylabio.notion.site/journeylabio/JourneyLab-Guides-3aea8831266b4fec89eaa106922bfa49',
      icon: iconGuides,
      style: 'solid',
    },
    {
      title: 'Product Updates',
      subtitle: `New and upcoming functionality to help you achieve more`,
      url: 'https://journeylabio.notion.site/JourneyLab-Product-Updates-ce1a21d6629544609de8b4e35804692d',
      icon: iconProduct,
      style: 'solid',
    },
    {
      title: 'Strategy-to-Execution Resources',
      subtitle: 'A collection of useful templates, frameworks and articles',
      url: 'https://journeylabio.notion.site/Strategy-to-Execution-Resources-41053ca2a2bc45f0b828ffbd3067098e',
      icon: iconS2E,
      style: 'solid',
    },
  ];

  function linksToTiles(data: Array<tileData>) {
    return (
      <div className="w-full grid grid-cols-1 xl:grid-cols-3 gap-x-5 gap-y-3">
        {data.map((item, idx) => (
          // Links to help users get started
          <a key={idx} href={item.url} target="_blank">
            <button
              type="button"
              className={`${
                item.style == 'solid'
                  ? 'bg-gray-100 border border-gray-200'
                  : 'bg-white border-dashed border-gray-300 border-2'
              } rounded-md col-span-1 w-full xl:h-40 hover:bg-gray-200`}
            >
              <div className="flex px-4 py-2 xl:py-3 align-top h-full">
                <div className="text-sm w-full text-left">
                  <div className="flex flex-row xl:flex-col align-middle">
                    <div className="text-sm font-semibold text-left xl:pb-1 pr-3 items-center my-auto">
                      {item.icon}
                    </div>
                    <div className="my-auto">
                      <div className="flex text-base font-medium text-left gap-x-2">
                        {item.title}
                      </div>
                      <p className="text-sm text-gray-500 mt-1">
                        {item.subtitle}
                      </p>
                    </div>
                    {/* <div className="mt-2">
                      {item.tags.length > 0 ? (
                        <span className="bg-secondary-800 rounded text-xs font-normal text-white px-2 py-1">
                          {item.tags[0]}
                        </span>
                      ) : (
                        ''
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </button>
          </a>
        ))}
      </div>
    );
  }

  const conceptTitle = (
    <DetailsPanelHeader
      title={'Key Concept: Everything links to enterprise strategy'}
      // description={'Everything is anchored against top-level strategy'}
    />
  );

  const conceptContent = (
    <div className="flex text-center items-center">
      <div className="flex-col justify-center items-center w-full align-middle">
        <img className="" src={graphic1} alt="graphic" />
      </div>
    </div>
  );

  const getStartedTitle = (
    <DetailsPanelHeader
      title={'Support Centre'}
      description={'Links will open in a separate tab'}
    />
  );

  const getStartedContent = (
    <div className="flex text-center items-center">
      {linksToTiles(getStartedLinks)}
    </div>
  );

  return (
    <div className="flex flex-col space-y-6 px-2">
      <PageHeader />

      <DetailsPanel
        headerLeft={getStartedTitle}
        headerRight={null}
        content={getStartedContent}
      />

      {/* <DetailsPanel
        headerLeft={conceptTitle}
        headerRight={null}
        content={conceptContent}
      /> */}
    </div>
  );
}

export default SupportCentre;
