import React, { useContext } from 'react';
import { WorkspacePageContext } from '../../../components/workspace/WorkspacePageContext';
import { getAllEntities } from '../../../common/wizard/payloads/MockQueryFunctions';
import { UserContext } from '../../../context/UserContext';
import {
  ComponentInfo,
  GenericContainer,
} from '../../../JLcomponents/basic/GenericContainer';
import ShortcutsPanel from '../../../JLcomponents/shortcuts/ShortcutsPanel/ShortcutsPanel';

const myEntities2 = [
  {
    id: 'ENTITY_001',
    name: 'Entity A',
    description: 'A description',
    isStarred: false,
    myRole: 'Oversight',
  },
  {
    id: 'ENTITY_002',
    name: 'Entity B',
    description: 'B description',
    isStarred: false,
    myRole: null,
  },
  {
    id: 'ENTITY_003',
    name: 'Entity C',
    description: 'C description',
    isStarred: true,
    myRole: 'Owner',
  },
];

const getMyEntities = () => {
  const entites = getAllEntities();
  const modifiedEntities = entites.map(entity => ({
    ...entity,
    myRole: entity.myRole || '-',
    isStarred: entity.isStarred || false,
  }));
  return modifiedEntities;
};

const containerPanels = (
  workspaceId: string | undefined
): ComponentInfo<any>[] => [
  {
    id: 'SC_PANEL_001',
    Component: ShortcutsPanel,
    props: {
      myEntities: getMyEntities(),
      workspaceId,
    },
  },
];

function ShortcutsContainer(): React.ReactElement {
  const { user: currentUser, profile: userProfile } = useContext(UserContext);
  const { state } = useContext(WorkspacePageContext);
  // call the useGetEntities hook to get the entities
  // useGetEntities returns an array of entities formatted as above
  return (
    <GenericContainer
      components={containerPanels(state.selectedWorkspace?.id)}
    />
  );
}

export default ShortcutsContainer;
