import React from 'react';
import ReactTooltip from 'react-tooltip';

export interface BasicButtonProps {
  id: string;
  onClick?: () => void;
  icon?: React.ReactElement;
  label?: string;
  secondaryText?: string;
  disabled?: boolean;
}

const RoundButton = ({
  id,
  onClick,
  icon,
  label,
  secondaryText,
  disabled,
}: BasicButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className="bg-gradient-to-tr from-gray-100 to-gray-50 hover:from-gray-200 hover:to-gray-100 inline-flex items-center p-1 border border-gray-300 rounded-full shadow-sm whitespace-nowrap text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
      onClick={onClick}
      data-tip
      data-for={id}
    >
      {icon}

      {/* Show a tooltip with more info */}
      {label && (
        <ReactTooltip id={id} place="top" effect="solid">
          <div className="text-left max-w-md whitespace-pre-wrap">
            <p className="font-semibold">{label}</p>
            <p className="mt-1">{secondaryText}</p>
          </div>
        </ReactTooltip>
      )}
    </button>
  );
};

export default RoundButton;
