import * as React from 'react';
import { SlideOverSectionHeader } from './SlideOverSectionHeader';

type SlideOverSimpleDataRowProps = {
  label: string;
  content: string | undefined;
};

export const SlideOverSimpleDataRow = ({
  label,
  content,
}: SlideOverSimpleDataRowProps): React.ReactElement => {
  const isContentNull = !content;

  return (
    <div className="flex flex-col">
      <SlideOverSectionHeader label={label} />

      <div className={`align-top whitespace-pre-wrap text-sm`}>
        <p
          className={`${
            isContentNull ? `italic text-gray-400` : `text-gray-800`
          }`}
        >
          {content || 'Not provided '}
        </p>
      </div>
    </div>
  );
};
