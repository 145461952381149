import React from 'react';
import { WaypointType } from './WaypointSelector';
import { ArrowsCounterClockwise, FlagBanner, Star } from 'phosphor-react';
import ReactTooltip from 'react-tooltip';

type WaypointMarkerProps = {
  id: string;
  type: WaypointType;
  isFaded: boolean;
};

// Set background style
const markerStyle = (type: WaypointType) => {
  return 'bg-transparent';

  // switch (type) {
  //   case WaypointType.milestone:
  //     return "bg-accent-light";
  //   case WaypointType.checkIn:
  //     return "bg-primary-500";
  //   case WaypointType.externalCommitment:
  //     return "bg-yellow-400";

  //   default:
  //     break;
  // }
};

// Set icon and styling
const size = 28;
const weight = 'fill';
const styles = 'text-white';

const iconMilestone = (
  <FlagBanner size={size} weight={weight} className={`text-accent-light`} />
);
const iconCheckIn = (
  <ArrowsCounterClockwise
    size={size}
    weight={'bold'}
    className={`text-primary-500`}
  />
);
const iconCommitment = (
  <Star size={size} weight={weight} className={`text-gray-400`} />
);

const MarkerIcon = (type: WaypointType) => {
  switch (type) {
    case WaypointType.milestone:
      return iconMilestone;
    case WaypointType.checkIn:
      return iconCheckIn;
    case WaypointType.externalCommitment:
      return iconCommitment;

    default:
      break;
  }
};

export default function WaypointMarker({
  id,
  type,
  isFaded,
}: WaypointMarkerProps): React.ReactElement {
  return (
    <React.Fragment>
      <div
        className={`flex justify-center items-center w-8 h-8 shrink-0 rounded-full ${markerStyle(
          type
        )} ${isFaded ? 'opacity-40' : ''}`}
        data-tip
        data-for={id}
      >
        {MarkerIcon(type)}
      </div>

      <ReactTooltip id={id} place="top" effect="solid">
        <p className="font-semibold py-0.5">{type}</p>
      </ReactTooltip>
    </React.Fragment>
  );
}
