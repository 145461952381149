/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { WizardFormInputProps } from '../Wizard';
import { WizardContext } from '../WizardContext';
import {
  enumTranslates,
  getEnumString,
  reorderEnums,
} from '../../i18n/translate';
import {
  getConfidenceColour,
  getProjectMeasureStatusColour,
} from '../../utils';
import { ArrowsClockwise, User } from 'phosphor-react';

export type SelectOptions =
  | Array<string>
  | Array<{ label: string; value: string }>;

type Props = {
  label: string | React.ReactElement;
  onChange?: (newValue: string) => void;
  manualChange?: (newValue: string | any, key: string) => void;
  // takes in an array of string (where the value will then be the key), or key, value pairs
  // e.g. { value: "delivery_confidence", label: "Delivery Confidence" }
  options: SelectOptions;
  decorationType?: 'confidence' | 'status' | 'contact' | 'cadence';
  disabled: boolean;
  name?: string;
  multiple?: boolean;
} & WizardFormInputProps;

export default function WizardSelect({
  id,
  label,
  onChange,
  manualChange,
  requestKey,
  options,
  name,
  decorationType,
  disabled,
  multiple,
}: Props): React.ReactElement {
  const { dispatch, state } = useContext(WizardContext);

  const selectRef = useRef<HTMLSelectElement>(null);
  const [hasTriggeredInitialOnChange, setHasTriggeredInitialOnChange] =
    useState<boolean>(false);

  const handleChange = useCallback(
    (e: any) => {
      // console.log('handle change', e);
      if (onChange != null) {
        onChange(e.target.value);
      }
      if (manualChange != null && name) {
        manualChange(e.target.value, name);
      } else {
        dispatch({
          type: 'WRITE_TO_REQUEST_PAYLOAD',
          key: requestKey,
          value: e.target.value,
        });
      }
    },
    [dispatch, onChange, manualChange, name, requestKey]
  );

  const dropdownOptions = useMemo(() => {
    const opts = reorderEnums(options);
    return opts.map(
      (
        valueStringOrObj: string | { label: string; value: string },
        index: number
      ) => {
        return (
          <option
            key={index}
            value={
              typeof valueStringOrObj === 'string'
                ? valueStringOrObj
                : valueStringOrObj.value
            }
          >
            {typeof valueStringOrObj === 'string'
              ? getEnumString(valueStringOrObj)
              : valueStringOrObj.label}
          </option>
        );
      }
    );
  }, [options]);

  useEffect(() => {
    if (!hasTriggeredInitialOnChange && selectRef.current != null) {
      selectRef.current.dispatchEvent(new Event('change', { bubbles: true }));
      setHasTriggeredInitialOnChange(true);
    }
  }, [hasTriggeredInitialOnChange]);

  const hasDecoration = decorationType != undefined;
  let selectClassName = `${
    hasDecoration ? 'rounded-r-md' : 'rounded-md'
  } block w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`;

  if (disabled) {
    selectClassName = `block w-full py-2 px-3 border border-gray-300 bg-gray-100 shadow-none text-gray-500 sm:text-sm  ${
      hasDecoration ? 'rounded-r-md' : 'rounded-md'
    }`;
  }

  function getDotColour(): string {
    let result = 'bg-black';

    if (decorationType == 'status') {
      let status;
      if (manualChange) {
        status = requestKey;
      } else {
        status = state.requestPayload[requestKey];
      }
      result = getProjectMeasureStatusColour(status as string);
    }

    return result;
  }

  function getConfidenceDecoration(): React.ReactElement {
    let colour = 'bg-black';
    let rating = '';

    if (decorationType == 'confidence') {
      rating = state.requestPayload[requestKey] as string;
      colour = getConfidenceColour(rating, 'normal');
    }

    return (
      <span
        className={`flex h-5 w-5 text-xs text-center text-white font-semibold align-middle items-center justify-center rounded ${colour}`}
      >
        {enumTranslates[rating][0].toUpperCase()}
      </span>
    );
  }
  // console.log(
  //   'what is this: ',
  //   requestKey,
  //   state.requestPayload[requestKey],
  //   name ? requestKey : state.requestPayload[requestKey] || ''
  // );
  return (
    <React.Fragment>
      <label
        htmlFor={id || requestKey}
        className="block text-sm font-medium text-gray-700 sm:mt-2 mb-1"
      >
        {label}
      </label>
      <div className="flex mt-1 sm:mt-0 sm:col-span-2">
        {/* Status decoration */}
        {decorationType == 'status' ? (
          <span className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center">
            <span className="flex h-5 w-5 align-middle justify-center items-center">
              <span className={`h-3 w-3 rounded-full ${getDotColour()}`} />
            </span>
          </span>
        ) : null}

        {/* Confidence decoration */}
        {decorationType == 'confidence' ? (
          <span className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center">
            <span
              className={`flex h-5 w-5 align-middle justify-center items-center`}
            >
              {getConfidenceDecoration()}
            </span>
          </span>
        ) : null}

        {/* Contact decoration */}
        {decorationType == 'contact' ? (
          <span className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center">
            <User className="h-5 w-5" weight="bold" />
          </span>
        ) : null}

        {/* Cadence decoration */}
        {decorationType == 'cadence' ? (
          <span className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center">
            <ArrowsClockwise className="h-5 w-5" weight="bold" />
          </span>
        ) : null}

        {/* Select */}
        <select
          className={selectClassName}
          onChange={handleChange}
          ref={selectRef}
          value={name ? requestKey : state.requestPayload[requestKey] || ''}
          disabled={disabled}
          multiple={multiple}
        >
          {dropdownOptions}
        </select>
      </div>
    </React.Fragment>
  );
}
