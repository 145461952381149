/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import moment from 'moment';
import _ from 'lodash';

import {
  EntityType,
  LogicModelGroup,
  Program,
  ProgramMeasure,
  Project,
  ProjectMeasure,
  ProjectMeasureStatus,
  ProjectStage,
} from '../../../api/index';
import {
  capitaliseFirstLetter,
  classNames,
  getLatestProgramUpdate,
  getLatestProjectUpdate,
  getLocalDate,
  getRelativeTime,
} from '../../../common/utils';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { PORTFOLIO, PROJECT } from '../../../common/routes';
import { enumTranslates } from '../../../common/i18n/translate';

import {
  Play,
  FlagCheckered,
  Plus,
  CalendarBlank,
  TrendUp,
  WarningCircle,
  CaretDown,
  CaretRight,
} from 'phosphor-react';

export type TimelineData = Array<{ value: number; time: number; item: any }>;

type Props = {
  program: Program | null;
  projects: Array<Project> | null;
  view: boolean;
  range: number;
};

const now = new Date();

// Define some custom data types to adapt the inputs to the timeline
// TimelineCardDataType represents the sources of information we draw from (we can add more later)
type TimelineCardDataType =
  | 'PORTFOLIO_OUTCOME'
  | 'PROGRAM_OUTCOME'
  | 'PROJECT_OUTCOME'
  | 'PROGRAM_START_DATE'
  | 'PROJECT_START_DATE'
  | 'PROJECT_END_DATE'
  | 'TODAY';

// ProgramTimelineCard represents the cherry picked data we will show on the view
type ProgramTimelineCard = {
  id: number;
  dataType: TimelineCardDataType;
  objectName: string | null;
  parentId: string | undefined | null;
  timelineDate: number;
  primaryText: string;
  secondaryText: string;
  tertiaryText?: ProjectMeasureStatus | string | undefined | null;
  excludedText: string;
  showOnTimeline: boolean;
};

function adaptTimelineData(
  program: Program | null,
  projects: Array<Project> | null
) {
  const RawData: Array<ProgramTimelineCard> = [];
  let index = 0;

  if (program === null) {
    return RawData;
  }

  // Find a project measure status

  function getProjectDeliveryString(delDate: Date): string {
    let result = '';
    if (delDate && delDate.getTime() > 0) {
      delDate.getTime() > new Date().getTime()
        ? (result = 'Project delivery expected')
        : (result = `Delivered a project`);
    } else {
      result = 'No project delivery date';
    }
    return result;
  }

  // Get program key dates
  // Add event data
  RawData.push({
    id: index,
    dataType: 'PROGRAM_START_DATE',
    objectName: program.name,
    parentId: program.id,
    timelineDate: new Date(program.startDate).getTime(),
    primaryText: `This program started`,
    secondaryText: '',
    tertiaryText: `Objective: ${
      program.outcome && program.outcome.length > 0
        ? program.outcome
        : 'Not yet defined'
    }`,
    excludedText: 'No start date',
    showOnTimeline: true,
  });
  // Increment index
  index = index + 1;

  // For each project, add the start and dates, if the project is not in planning stage
  if (projects != null) {
    projects.forEach(project => {
      if (project.stage != ProjectStage.Inplanning) {
        // Add event data
        RawData.push({
          id: index,
          dataType: 'PROJECT_START_DATE',
          objectName: project.name,
          parentId: project.id,
          timelineDate: new Date(project.startDate).getTime(),
          primaryText: `New project started`,
          secondaryText: '',
          tertiaryText: `Objective: ${
            project.outcome && project.outcome.length > 0
              ? project.outcome
              : 'Not yet defined'
          }`,
          excludedText: 'No start date',
          showOnTimeline: true,
        });
        // Increment index
        index = index + 1;
      }
    });

    projects.forEach(project => {
      if (project.stage != ProjectStage.Inplanning) {
        // Add event data
        RawData.push({
          id: index,
          dataType: 'PROJECT_END_DATE',
          objectName: project.name,
          parentId: project.id,
          timelineDate: new Date(project.deliveryDate).getTime(),
          primaryText: getProjectDeliveryString(new Date(project.deliveryDate)),
          secondaryText: '',
          tertiaryText: `Objective: ${
            project.outcome && project.outcome.length > 0
              ? project.outcome
              : 'Not yet defined'
          }`,
          excludedText: 'No delivery date',
          showOnTimeline: true,
        });
        // Increment index
        index = index + 1;
      }
    });
  }

  // Get portfolio objectives
  program.portfolio.objectives?.forEach(objective => {
    // Add event data
    // TODO get all targets
    const target =
      objective.targets && objective.targets[0] && objective.targets[0] !== null
        ? objective.targets[0]
        : { targetValue: '', achieveByDate: '' };
    RawData.push({
      id: index,
      dataType: 'PORTFOLIO_OUTCOME',
      objectName: objective.description,
      parentId: program.portfolio.id,
      timelineDate: new Date(target.achieveByDate).getTime(),
      primaryText: 'Portfolio objective expected',
      secondaryText: `Objective: ${objective.description}`,
      tertiaryText: `Target: ${
        target.targetValue !== '' ? target.targetValue : 'N/A'
      }`,
      excludedText: 'No target date',
      showOnTimeline: true,
    });
    // Increment index
    index = index + 1;
  });

  function getProgramMeasureStatusText(
    measure: ProgramMeasure,
    program: Program
  ): { target: string; status: string } {
    let targetString = '';
    let statusString = '';
    const latestUpdate = getLatestProgramUpdate(program);
    const foundMeasureUpdate = latestUpdate
      ? latestUpdate.measures?.find(m => m.measureId === measure.id)
      : null;
    const status = foundMeasureUpdate ? foundMeasureUpdate.status : '';

    (targetString = `Target: ${
      measure.targetValue ? measure.targetValue : 'N/A'
    }`),
      (statusString = `Status: ${
        foundMeasureUpdate
          ? enumTranslates[status as string]
          : 'No update found'
      }`);

    return { target: targetString, status: statusString };
  }

  // Get program measures
  program.measures?.forEach(measure => {
    // Add event data
    RawData.push({
      id: index,
      dataType: 'PROGRAM_OUTCOME',
      objectName: measure.name,
      parentId: program.id,
      timelineDate: new Date(measure.achieveByDate).getTime(),
      primaryText: 'Program outcome expected',
      secondaryText: `Outcome: ${measure.name}`,
      tertiaryText: `${
        getProgramMeasureStatusText(measure, program).target
      } / ${getProgramMeasureStatusText(measure, program).status}`,
      excludedText: 'No target date',
      showOnTimeline: true,
    });
    // Increment index
    index = index + 1;
  });

  function getProjectMeasureStatusText(
    measure: ProjectMeasure,
    project: Project
  ): { target: string; status: string } {
    let targetString = '';
    let statusString = '';
    const latestUpdate = getLatestProjectUpdate(project);
    const foundMeasureUpdate = latestUpdate
      ? latestUpdate.measures?.find(m => m.measureId === measure.id)
      : null;
    const status = foundMeasureUpdate ? foundMeasureUpdate.status : '';

    measure.logicModelGroup === LogicModelGroup.Outputs ||
    measure.logicModelGroup != LogicModelGroup.Impacts
      ? // This is a project deliverable
        ((targetString = ''),
        (statusString = `Status: ${
          foundMeasureUpdate
            ? enumTranslates[status as string]
            : 'No update found'
        }`))
      : // This is a project impact
        ((targetString = `Target: ${
          measure.targetValue ? measure.targetValue : 'N/A'
        }`),
        (statusString = `Status: ${
          foundMeasureUpdate
            ? enumTranslates[status as string]
            : 'No update found'
        }`));

    return { target: targetString, status: statusString };
  }

  // Get project measures
  if (projects != null) {
    projects.forEach(project => {
      {
        if (project.stage != ProjectStage.Inplanning) {
          // Add event data
          project.measures?.forEach(measure => {
            RawData.push({
              id: index,
              dataType: 'PROJECT_OUTCOME',
              objectName: measure.name,
              parentId: projects?.find(project =>
                project.measures?.some(m => m.id === measure.id)
              )?.id,
              timelineDate: new Date(measure.achieveByDate).getTime(),
              primaryText:
                measure.logicModelGroup === LogicModelGroup.Outputs
                  ? 'Project deliverable due'
                  : 'Project impact expected',
              secondaryText: `${
                measure.logicModelGroup === LogicModelGroup.Outputs
                  ? 'Deliverable:'
                  : 'Impact:'
              } ${measure.name}`,
              tertiaryText:
                measure.logicModelGroup === LogicModelGroup.Outputs
                  ? `${getProjectMeasureStatusText(measure, project).status}`
                  : `${
                      getProjectMeasureStatusText(measure, project).target
                    } / ${
                      getProjectMeasureStatusText(measure, project).status
                    }`,
              excludedText: 'No target date',
              showOnTimeline: true,
            });
            // Increment index
            index = index + 1;
          });
        }
      }
    });
  }

  // Add item for the current day
  RawData.push({
    id: index,
    dataType: 'TODAY',
    objectName: null,
    parentId: null,
    timelineDate: new Date().getTime(),
    primaryText: 'Today',
    secondaryText: '',
    tertiaryText: '',
    excludedText: '',
    showOnTimeline: true,
  });
  // Increment index
  index = index + 1;

  return RawData;
}

function getCleanTimelineData(
  program: Program | null,
  projects: Array<Project> | null
) {
  const RawData = adaptTimelineData(program, projects);
  const included: Array<ProgramTimelineCard> = [];
  const excludedEvents: Array<ProgramTimelineCard> = [];

  // Split out data without dates
  RawData.forEach(item =>
    item.timelineDate && item.timelineDate > 0
      ? included.push(item)
      : excludedEvents.push(item)
  );

  // Sort included data by date
  const eventsToShow = _.sortBy([...included], 'timelineDate');

  return { eventsToShow, excludedEvents };
}

function getMarkerIcon(dataType: TimelineCardDataType) {
  const iconSize = 16;
  let icon = (
    <TrendUp
      className="inline-block mx-auto my-auto"
      color="#ffffff"
      weight="bold"
      size={iconSize}
    />
  );

  switch (dataType) {
    case 'PROJECT_START_DATE':
      icon = (
        <Plus
          className="inline-block mx-auto my-auto"
          color="#ffffff"
          weight="bold"
          size={iconSize}
        />
      );
      break;
    case 'PROJECT_END_DATE':
      icon = (
        <FlagCheckered
          className="inline-block mx-auto my-auto"
          color="#ffffff"
          weight="fill"
          size={iconSize}
        />
      );
      break;
    case 'PROGRAM_START_DATE':
      icon = (
        <Play
          className="inline-block mx-auto my-auto"
          color="#ffffff"
          weight="fill"
          size={iconSize}
        />
      );
      break;
    case 'TODAY':
      icon = (
        <CalendarBlank
          className="inline-block mx-auto my-auto"
          color="#ffffff"
          weight="fill"
          size={iconSize}
        />
      );
      break;
  }

  return icon;
}

function TimelineChart({
  program,
  projects,
  view,
  range,
}: Props): React.ReactElement {
  const viewType = view ? 'sequence' : 'timeline';

  // By default, the timeline will show today plus/minus this many days
  const defaultLookBackDays = 14;
  const userLookAheadDays = range;

  const values: number[][] = [];
  const dates: number[] = [];
  const items: number[] = [];
  const cleanTimelineData = getCleanTimelineData(
    program,
    projects
  ).eventsToShow;
  cleanTimelineData.forEach(item => {
    values.push([
      new Date(item.timelineDate).getTime(),
      item.dataType === 'PORTFOLIO_OUTCOME'
        ? 3
        : item.dataType === 'PROGRAM_START_DATE' ||
          item.dataType === 'PROGRAM_OUTCOME'
        ? 2
        : item.dataType === 'PROJECT_START_DATE' ||
          item.dataType === 'PROJECT_END_DATE' ||
          item.dataType === 'PROJECT_OUTCOME'
        ? 1
        : item.dataType === 'TODAY'
        ? 0
        : 0,
    ]);
    dates.push(new Date(item.timelineDate).getTime());
    items.push(
      item.dataType === 'PORTFOLIO_OUTCOME'
        ? 3
        : item.dataType === 'PROGRAM_START_DATE' ||
          item.dataType === 'PROGRAM_OUTCOME'
        ? 2
        : item.dataType === 'PROJECT_START_DATE' ||
          item.dataType === 'PROJECT_END_DATE' ||
          item.dataType === 'PROJECT_OUTCOME'
        ? 1
        : item.dataType === 'TODAY'
        ? 0
        : 0
    );
  });

  function DefaultMinDate(): number {
    const d = new Date();

    if (userLookAheadDays == 0) {
      return BrushMinDate();
    } else {
      d.setDate(d.getDate() - defaultLookBackDays);
      return d.getTime();
    }
  }

  function DefaultMaxDate(): number {
    const d = new Date();

    if (userLookAheadDays == 0) {
      return BrushMaxDate();
    } else {
      d.setDate(d.getDate() + userLookAheadDays);
      return Math.min(d.getTime(), BrushMaxDate());
    }
  }

  // Corresponding brush chart limits
  function BrushMinDate(): number {
    const d = new Date(
      dates.reduce((a, b) => {
        return a < b ? a : b;
      })
    );
    d.setDate(d.getDate() - defaultLookBackDays);
    return d.getTime();
  }

  function BrushMaxDate(): number {
    const d = new Date(
      dates.reduce((a, b) => {
        return a > b ? a : b;
      })
    );
    d.setDate(d.getDate() + defaultLookBackDays);
    return d.getTime();
  }

  const getLabelData = (date: Date) => {
    function getParentEntityName(item: ProgramTimelineCard) {
      let result: string | undefined = '';
      item.dataType === 'PORTFOLIO_OUTCOME'
        ? (result = `Portfolio: ${program?.portfolio.name}`)
        : item.dataType === 'PROGRAM_START_DATE' ||
          item.dataType === 'PROGRAM_OUTCOME'
        ? (result = `Program: ${program?.name}`)
        : item.dataType === 'PROJECT_START_DATE' ||
          item.dataType === 'PROJECT_END_DATE' ||
          item.dataType === 'PROJECT_OUTCOME'
        ? (result = `Project: ${
            projects?.find(project => project.id === item.parentId)?.name
          }`)
        : (result = '');

      return result;
    }

    let htmlString = '';
    cleanTimelineData
      .filter(
        d => new Date(d.timelineDate).valueOf() === new Date(date).valueOf()
      )
      .forEach(item => {
        htmlString =
          htmlString +
          '<li>' +
          // Primary description
          '<h4>' +
          item.primaryText +
          '</h4>' +
          // Relevant entity
          '<p class="text-xs text-gray-500">' +
          getParentEntityName(item) +
          '</p>' +
          // Secondary information
          '<p class="text-xs text-gray-500">' +
          item.secondaryText +
          '</p>' +
          // Other information
          '<p class="text-xs text-gray-500">' +
          item.tertiaryText +
          '</p>' +
          '</li>';
      });
    return (
      '<ul class="list-disc list-outside ml-1 pl-4 space-y-2">' +
      htmlString +
      '</ul>'
    );
  };
  const data = {
    series: [
      {
        data: values,
      },
    ],
    options: {
      chart: {
        id: 'chart2',
        type: 'line',
        height: 200,
        series: [],
        toolbar: {
          autoSelected: 'pan',
          show: false,
        },
      },
      // Custom grid design
      grid: {
        show: true,
        borderColor: '#ddd',
        strokeDashArray: 0,
        position: 'back',
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          left: 20,
          right: 10,
          top: 0,
          bottom: 0,
        },
      },
      annotations: {
        xaxis: [
          {
            x: new Date().getTime(),
            datetimeUTC: false,
            strokeDashArray: 0,
            borderColor: '#775DD0',
            label: {
              borderColor: '#775DD0',
              style: {
                color: '#fff',
                background: '#775DD0',
              },
              text: 'Today',
            },
          },
        ],
      },
      tooltip: {
        custom: function ({ dataPointIndex, w }: any) {
          return (
            '<div class="arrow_box max-w-max">' +
            '<div class="bg-gray-100 p-2 border-b border-gray-200">' +
            '<h3 class="font-medium text-black">' +
            getLocalDate(w.config.series[0].data[dataPointIndex][0]) +
            '</h3>' +
            '<p class="text-xs text-gray-500">' +
            (!moment(w.config.series[0].data[dataPointIndex][0]).isSame(
              moment(),
              'day'
            )
              ? capitaliseFirstLetter(
                  getRelativeTime(w.config.series[0].data[dataPointIndex][0])
                )
              : '') +
            '</p>' +
            '</div>' +
            '<div class="p-2">' +
            '<h4>' +
            getLabelData(w.config.series[0].data[dataPointIndex][0]) +
            '</h4>' +
            '</div>' +
            '</div>'
          );
        },
      },
      colors: ['#011627'],
      markers: {
        size: 8,
      },
      stroke: {
        width: 0,
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        opacity: 1,
      },
      axisBorder: {
        offsetY: 10,
      },
      yaxis: {
        tickAmount: 4,
        max: 3,
        min: 0,
        forceNiceScale: true,
        labels: {
          formatter: function (value: any, _options: any) {
            const newValue = Math.floor(value);
            if (newValue == 1) {
              return capitaliseFirstLetter(enumTranslates[EntityType.Project]);
            } else if (newValue == 2) {
              return capitaliseFirstLetter(enumTranslates[EntityType.Program]);
            } else if (newValue == 3) {
              return capitaliseFirstLetter(
                enumTranslates[EntityType.Portfolio]
              );
            } else if (newValue == 0) return '';
          },
          minWidth: 25,
          style: {
            fontSize: '14px',
          },
          offsetY: 2,
        },
      },
      xaxis: {
        type: 'datetime',
        datetimeUTC: false,
        labels: {
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM',
            day: 'd/M',
            hour: 'HH:mm',
          },
        },
        axisBorder: {
          show: true,
          color: '#666',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0,
        },
        tooltip: {
          enabled: true,
          formatter: function (val: any, _opts: any) {
            return moment(val).format('D MMM');
          },
        },
        // categories: dates,
      },
    },
    seriesLine: [
      {
        data: values,
      },
    ],
    optionsLine: {
      chart: {
        id: 'chart1',
        height: 120,
        type: 'area',
        series: [],
        background: '#fff',
        brush: {
          target: 'chart2',
          enabled: true,
        },
        selection: {
          enabled: true,
          fill: {
            color: 'rgba(13, 183, 189)',
            opacity: 0.1,
          },
          xaxis: {
            min: DefaultMinDate(),
            max: DefaultMaxDate(),
          },
        },
      },
      colors: [''],
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0,
          opacityTo: 0,
        },
      },
      annotations: {
        points: values.map(item => {
          return {
            x: item[0],
            y: item[1],
            marker: {
              size: 4,
              fillColor: 'rgba(13, 183, 189, 0.5)',
              strokeColor: 'rgba(13, 183, 189, var(--tw-bg-opacity))',
              radius: 2,
              cssClass: 'apexcharts-custom-class',
            },
            label: {
              borderColor: '#FF4560',
              offsetY: 0,
              style: {
                color: 'rgba(13, 183, 189, var(--tw-bg-opacity))',
                background: 'rgba(13, 183, 189, var(--tw-bg-opacity))',
              },
            },
          };
        }),
      },
      xaxis: {
        type: 'datetime',
        datetimeUTC: false,
        min: BrushMinDate(),
        max: BrushMaxDate(),
        tooltip: {
          enabled: false,
        },
        labels: {
          datetimeUTC: false,
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM',
            day: 'd/M',
            hour: 'HH:mm',
          },
        },
      },
      yaxis: {
        tickAmount: 3,
        max: 3,
        min: 0,
        labels: {
          formatter: function (_value: any) {
            return '';
          },
        },
      },
      grid: {
        padding: {
          left: 64,
          right: 10,
          top: 0,
          bottom: 0,
        },
      },
    },
  };

  return (
    <React.Fragment>
      {/* Settings bar */}
      {/* Note: Hidden setting here can be moved to children if more are added */}
      <div
        className={classNames(
          getCleanTimelineData(program, projects).excludedEvents.length == 0
            ? 'hidden'
            : '',
          'flex flex-row-reverse text-sm justify-between mb-6'
        )}
      >
        {/* Excluded items */}
        <div className="w-full">
          <Disclosure>
            {({ open }) => (
              <React.Fragment>
                <Disclosure.Button
                  className={classNames(
                    open
                      ? 'border-t border-x rounded-t-md'
                      : 'border rounded-md',
                    'flex flex-row bg-yellow-100 border-yellow-200 text-sm p-2 w-full text-left align-middle'
                  )}
                >
                  <WarningCircle className="w-5 h-5 inline-block my-auto ml-1 mr-2 flex-shrink-0" />
                  <p className="inline-block">
                    {
                      getCleanTimelineData(program, projects).excludedEvents
                        .length
                    }{' '}
                    event
                    {getCleanTimelineData(program, projects).excludedEvents
                      .length == 1
                      ? ''
                      : 's'}{' '}
                    not shown because they don't have dates yet.
                  </p>
                  {/* Use the `open` render prop to rotate the icon when
                      the panel is open */}
                  <CaretDown
                    weight="bold"
                    className={classNames(
                      open ? 'transform rotate-180' : '',
                      'w-4 h-4 inline-block my-auto ml-auto'
                    )}
                  />
                </Disclosure.Button>

                <Disclosure.Panel
                  className={classNames(
                    open ? 'border-b border-x rounded-b-md' : '',
                    'bg-yellow-100 border-yellow-200 text-sm px-9 pb-2 w-full text-left'
                  )}
                >
                  <ul>
                    {getCleanTimelineData(program, projects).excludedEvents.map(
                      (item, idx) =>
                        item.dataType === 'PORTFOLIO_OUTCOME' ? (
                          <li
                            key={item.id}
                            className="list-disc list-outside text-sm ml-6 py-0"
                          >
                            <p>
                              <Link
                                className="hover:underline hover:text-primary-600"
                                to={`${PORTFOLIO}/${program?.portfolio.id}`}
                              >
                                {program?.portfolio.name}
                              </Link>{' '}
                              - {item.excludedText}
                            </p>
                          </li>
                        ) : item.dataType === 'PROJECT_START_DATE' ||
                          item.dataType === 'PROJECT_END_DATE' ||
                          item.dataType === 'PROJECT_OUTCOME' ? (
                          <li
                            key={item.id}
                            className="list-disc list-outside text-sm ml-6 py-0"
                          >
                            <p>
                              <Link
                                className="hover:underline hover:text-primary-600"
                                to={`${PROJECT}/${
                                  projects?.find(
                                    project => project.id === item.parentId
                                  )?.id
                                }`}
                              >
                                {
                                  projects?.find(
                                    project => project.id === item.parentId
                                  )?.name
                                }
                              </Link>{' '}
                              {item.dataType === 'PROJECT_OUTCOME'
                                ? `, ${item.objectName} - `
                                : `- `}
                              {item.excludedText}
                            </p>
                          </li>
                        ) : (
                          // No links for program items
                          <li
                            key={item.id}
                            className="list-disc list-outside text-sm ml-6 py-0"
                          >
                            <p>
                              This program
                              {item.dataType === 'PROGRAM_OUTCOME'
                                ? `, ${item.objectName} - `
                                : `- `}
                              {item.excludedText}
                            </p>
                          </li>
                        )
                    )}
                  </ul>
                </Disclosure.Panel>
              </React.Fragment>
            )}
          </Disclosure>
        </div>
      </div>

      {/* Views */}
      {viewType === 'timeline' ? (
        // Timeline view
        <div className={'w-full'}>
          <div id="wrapper">
            <div id="chart-line2">
              <ReactApexChart
                options={data.options as ApexCharts.ApexOptions}
                series={data.series}
                type="line"
                height={200}
              />
            </div>
            <div id="chart-line">
              <ReactApexChart
                options={data.optionsLine as ApexCharts.ApexOptions}
                series={data.seriesLine}
                type="area"
                height={120}
              />
            </div>
          </div>
        </div>
      ) : viewType === 'sequence' ? (
        // Card view
        <div className="text-sm mb-0">
          {/* Included items */}
          <div className="flex gap-0 overflow-x-auto">
            {getCleanTimelineData(program, projects).eventsToShow.map(
              (item, index) => (
                <div key={index} className="flex flex-col w-48 flex-none">
                  {/* Date */}
                  <div className="timeline-date row-span-1 items-center text-center ">
                    <div className="font-semibold">
                      <p
                        className={classNames(
                          index > 0 &&
                            moment(
                              getCleanTimelineData(program, projects)
                                .eventsToShow[index - 1].timelineDate
                            ).format('D/M/YY') ==
                              moment(item.timelineDate).format('D/M/YY')
                            ? 'text-white'
                            : 'text-black',
                          ''
                        )}
                      >
                        {moment(item.timelineDate).format('D/M/YY')}
                      </p>
                    </div>
                    <div
                      className={classNames(
                        index > 0 &&
                          moment(
                            getCleanTimelineData(program, projects)
                              .eventsToShow[index - 1].timelineDate
                          ).format('D/M/YY') ==
                            moment(item.timelineDate).format('D/M/YY')
                          ? 'text-white'
                          : 'text-gray-400',
                        'text-xs mt-0'
                      )}
                    >
                      {capitaliseFirstLetter(
                        getRelativeTime(new Date(item.timelineDate))
                      )}
                    </div>
                  </div>

                  {/* Timeilne and markers */}
                  <div className="flex items-center justify-center my-4 ">
                    <div
                      className={classNames(
                        index > 0
                          ? 'border border-gray-300'
                          : 'border border-white',
                        'relative top-0 w-full'
                      )}
                    />

                    {/* Marker */}
                    <div
                      className={classNames(
                        item.dataType === 'PORTFOLIO_OUTCOME' ||
                          item.dataType === 'PROGRAM_OUTCOME' ||
                          item.dataType === 'PROJECT_OUTCOME'
                          ? 'bg-secondary-800'
                          : item.dataType === 'TODAY'
                          ? 'bg-indigo-600'
                          : 'bg-primary-500',
                        'flex flex-shrink-0 items-center justify-center align-middle font-semibold text-white mx-1 w-7 h-7 rounded-full'
                      )}
                    >
                      <div className="flex align-middle text-center items-center p-1">
                        {getMarkerIcon(item.dataType)}
                      </div>
                    </div>

                    <div
                      className={classNames(
                        index <
                          getCleanTimelineData(program, projects).eventsToShow
                            .length -
                            1
                          ? 'border border-gray-300'
                          : 'border border-gray-300 border-dashed',
                        'relative top-0 w-full'
                      )}
                    />
                  </div>

                  {/* Card */}
                  {item.dataType === 'TODAY' ? null : (
                    <div
                      className={classNames(
                        item.timelineDate < now.getTime()
                          ? 'bg-gray-100 text-gray-500'
                          : 'bg-gray-200',
                        'timeline-info flex flex-col flex-none rounded p-2 mx-2 mb-5 row-span-1 shadow h-52 justify-between overflow-y-auto'
                      )}
                    >
                      {/* Top */}
                      <div>
                        <div className="font-semibold mb-3 h-10 overflow-ellipsis overflow-y-hidden">
                          {item.primaryText}
                        </div>
                        <p className="text-xs mb-1">{item.secondaryText}</p>
                        <p className="text-xs">{item.tertiaryText}</p>
                      </div>

                      {/* Bottom */}
                      <div className="mt-2">
                        {item.dataType === 'PORTFOLIO_OUTCOME' ? (
                          <React.Fragment>
                            <p className="text-xs text-gray-400">Portfolio</p>
                            <Link to={`${PORTFOLIO}/${program?.portfolio.id}`}>
                              <div className="flex items-center align-middle text-primary-600">
                                <p className="font-semibold text-xs hover:underline truncate mr-1">
                                  {program?.portfolio.name}
                                </p>
                                <CaretRight
                                  weight="bold"
                                  size={12}
                                  className="flex-shrink-0"
                                />
                              </div>
                            </Link>
                          </React.Fragment>
                        ) : item.dataType === 'PROJECT_START_DATE' ||
                          item.dataType === 'PROJECT_END_DATE' ||
                          item.dataType === 'PROJECT_OUTCOME' ? (
                          <React.Fragment>
                            <p className="text-xs text-gray-400">Project</p>
                            <Link to={`${PROJECT}/${item.parentId}`}>
                              <div className="flex items-center align-middle text-primary-600">
                                <p className="font-semibold text-xs hover:underline truncate mr-1">
                                  {
                                    projects?.find(
                                      project => project.id === item.parentId
                                    )?.name
                                  }
                                </p>
                                <CaretRight
                                  weight="bold"
                                  size={12}
                                  className="flex-shrink-0"
                                />
                              </div>
                            </Link>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <p className="text-xs text-gray-400">Program</p>
                            <p className="font-semibold text-xs text-gray-500 truncate">
                              {program?.name}
                            </p>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

export default TimelineChart;
