import {
  ArrowCounterClockwise,
  CalendarBlank,
  CurrencyDollar,
  FlagBanner,
  Star,
  User,
} from 'phosphor-react';
import { ProjectUpdateConfidence } from '../../../api/index';
import { addDays } from '../../../common/utils';

export type Waypoint = {
  date: Date;
  type: WaypointType;
  description: string; // TBD - Do we need this?
  checkList: Milestone[];
  review: Review | null; // This would be an evolution of the current ProjectUpdate object in v1
};

export type Review = {
  confidence: ProjectUpdateConfidence; // Using this to as a placeholder for now
  remarks: string;
};

type WaypointType =
  | 'milestone'
  | 'kick-off'
  | 'stage gate'
  | 'steering committee'
  | 'team retro'
  | 'quarterly review'
  | 'customer commitment'
  | 'today';

export function getWaypointMarkerStyle(type: WaypointType): {
  icon: React.ReactElement;
  bgColour: string;
} {
  const size = 18;
  const weight = 'fill';
  const styles = 'text-white';

  const colourTeam = 'bg-gradient-to-tr from-accent-dark to-blue-700';
  const colourCompany = 'bg-gradient-to-tr from-primary-700 to-primary-500';
  const colourExternal = 'bg-gradient-to-tr from-yellow-600 to-yellow-400';
  const colourToday = 'bg-gradient-to-tr from-gray-400 to-gray-400';

  let icon = <FlagBanner size={size} weight={weight} className={styles} />;
  let colour = colourTeam;

  switch (type) {
    case 'stage gate':
      icon = <CurrencyDollar size={size} weight={'bold'} className={styles} />;
      colour = colourCompany;
      break;

    case 'team retro':
      icon = (
        <ArrowCounterClockwise size={size} weight={'bold'} className={styles} />
      );
      break;

    case 'quarterly review':
      icon = <Star size={size} weight={weight} className={styles} />;
      colour = colourCompany;
      break;

    case 'customer commitment':
      icon = <User size={size} weight={weight} className={styles} />;
      colour = colourExternal;
      break;

    case 'today':
      icon = <CalendarBlank size={size} weight={weight} className={styles} />;
      colour = colourToday;
      break;

    default:
      break;
  }

  const result = { icon: icon, bgColour: colour };

  return result;
}

type Milestone = {
  checked: boolean;
  description: string;
};

// When the v2 Entity data model is ready, we expect to get the Waypoint data from the API
// e.g. The page will retrieve the Entity object, and this will include a field Entity.Waypoints
// that corresponds to the array below.

// For now, we'll use a dummy getter.
// The real one may be more like getWaypoints(entity: Entity, filter: Conditions)
// These should be sorted by date (ascending). Dates and Waypoints should be 1-to-1
export function getWaypoints(): Waypoint[] {
  return Waypoints;
}

// Note: We are using relative dates here for the dummy data to prevent needing ongoing updates
const Waypoints: Waypoint[] = [
  {
    date: addDays(new Date(), -60),
    type: 'milestone',
    description: 'Describe feasibility',
    checkList: [
      {
        checked: true,
        description:
          'Articulate how the concept will create new value for a target customer',
      },
      {
        checked: true,
        description: 'Determine feasibility',
      },
      {
        checked: true,
        description: 'Provide evidence of customer appetite',
      },
    ],
    review: {
      confidence: ProjectUpdateConfidence.Medium,
      remarks:
        'We have articulated this to 10 prospective customers and it is starting to resonate with a subsegment.',
    },
  },
  {
    date: addDays(new Date(), -18),
    type: 'stage gate',
    description: 'Initial funding',
    checkList: [
      {
        checked: true,
        description: 'Complete POC design',
      },
      {
        checked: true,
        description: 'Prove customer appetite with a small sample set',
      },
    ],
    review: {
      confidence: ProjectUpdateConfidence.High,
      remarks:
        'We have proven desirability with 20 people in the target segment and the BU has agreed to invest some time to refine the POC.',
    },
  },
  {
    date: addDays(new Date(), -14),
    type: 'team retro',
    description: 'Reflection time',
    checkList: [
      {
        checked: true,
        description:
          'How would we improve our current ways of working and customer focus?',
      },
    ],
    review: {
      confidence: ProjectUpdateConfidence.High,
      remarks:
        'We actually need a dedicated discovery team to better understand the problem and remove distraction for the design team.',
    },
  },
  {
    date: addDays(new Date(), 0),
    type: 'today',
    description: 'Today',
    checkList: [],
    review: null,
  },
  {
    date: addDays(new Date(), 5),
    type: 'kick-off',
    description: 'Set up the team',
    checkList: [
      {
        checked: true,
        description: 'Get the team together',
      },
      {
        checked: true,
        description: 'Clarify roles and agree ways of working',
      },
      {
        checked: false,
        description: 'Develop a plan to hit the initial customer commitment',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 30),
    type: 'steering committee',
    description: 'Check in with the program board',
    checkList: [
      {
        checked: false,
        description: 'Complete feasibility study',
      },
      {
        checked: false,
        description: 'Prepare board paper',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 74),
    type: 'customer commitment',
    description: 'Phase 1 release',
    checkList: [
      {
        checked: false,
        description: 'Onboard 8 members of customer advisory board',
      },
    ],
    review: null,
  },

  // Placeholders
  {
    date: addDays(new Date(), 80),
    type: 'quarterly review',
    description: 'QBR #1',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
  {
    date: addDays(new Date(), 60),
    type: 'milestone',
    description: 'Phase 1 delivery',
    checkList: [
      {
        checked: false,
        description: 'Deliver prototype to internal team',
      },
      {
        checked: false,
        description: 'Recruit 10 members to customer advisory board',
      },
    ],
    review: null,
  },
];
