import React from 'react';
import { Target } from 'phosphor-react';

type PerformanceSlideOverOutcomeProps = {
  description: string;
  target: string;
  targetDate: string;
};

const TargetIcon = () => (
  <Target
    size={20}
    weight="duotone"
    className="text-accent-light shrink-0 mt-px"
    aria-hidden="true"
  />
);

export default function PerformanceSlideOverOutcomeBlock({
  description,
  target,
  targetDate,
}: PerformanceSlideOverOutcomeProps): React.ReactElement {
  return (
    <div className="flex">
      <div className="px-2 pt-0">
        <TargetIcon />
      </div>
      <div className="text-sm">
        <p className="text-accent-light font-medium">{description}</p>
        {/* TODO: Handle if target and/or date is empty */}
        {/* Make this a util, since it is used in many different places */}
        <p className="text-gray-800">{`${target}, by ${targetDate}`}</p>
      </div>
    </div>
  );
}
