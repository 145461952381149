import { RelationshipType } from '../../../JLcomponents/swimlanes/RelationshipsPanel/RelationshipsPanel';
import {
  WatchoutStatus,
  WatchoutType,
} from '../../../JLcomponents/waypoints/WatchoutsPanel/WatchoutsPanel';
import { WaypointType } from '../../../JLcomponents/waypoints/WaypointSelector/WaypointSelector';
import { getLocalDate } from '../../../utils/utils';
import { addDays } from '../../utils';

// outcome arrays
export const MockOutcomes = [
  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP001',
    checklist: [
      {
        id: 'E005WP001_O01',
        description: 'Finalise recruitment of customer testing focus group',
        progress: 'complete',
        assigneeName: 'Name Surname',
        dueDate: '4 Apr 2023',
      },
      {
        id: 'E005WP001_O03',
        description:
          'Letter of intent with large retail store chain for distribution',
        progress: 'complete',
        assigneeName: 'Name Surname',
        dueDate: '6 Jun 2023',
      },
      {
        id: 'E005WP001_O03',
        description:
          'Campaign strategy and key messaging for our upcoming products',
        progress: 'complete',
        assigneeName: 'Name Surname',
        dueDate: '8 Aug 2023',
      },
    ],
  },
  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP002', // Last waypoint
    checklist: [
      {
        id: 'E005WP002_O01',
        description: '90% satisfaction with the customer focus group',
        progress: 'partial',
        assigneeName: 'Name Surname',
        dueDate: '4 Apr 2023',
      },
      {
        id: 'E005WP002_O03',
        description: 'Distribution contract signed with the retail store chain',
        progress: 'na',
        assigneeName: 'Name Surname',
        dueDate: '6 Jun 2023',
      },
      {
        id: 'E005WP002_O03',
        description: 'Exec approval of the campaign plan',
        progress: 'complete',
        assigneeName: 'Name Surname',
        dueDate: '8 Aug 2023',
      },
    ],
  },
  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP003', // Next waypoint
    checklist: [
      {
        id: 'E005WP003_O01',
        description: 'Decision on whether to postpone the launch',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '4 Apr 2023',
      },
      {
        id: 'E005WP003_O03',
        description: 'Have a contingency plan in place for the launch',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '6 Jun 2023',
      },
      {
        id: 'E005WP003_O03',
        description: 'Go/no-go on alternative distribution channels',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '8 Aug 2023',
      },
    ],
  },

  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP004',
    checklist: [
      {
        id: 'E005WP004_O01',
        description: 'Research and evaluate potential distribution partners',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '7 Mar 2023',
      },
      {
        id: 'E005WP004_O02',
        description: 'Make decision on distribution partner(s)',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '30 Mar 2023',
      },

      {
        id: 'E005WP004_O03',
        description: 'Test the distribution model and address any issues',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '6 Jun 2023',
      },
    ],
  },
  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP005',
    checklist: [
      {
        id: 'E005WP005_O01',
        description: 'Confirm product launch date and channels',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '18 Mar 2023',
      },
    ],
  },
  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP006',
    checklist: [
      {
        id: 'E005WP006_O01',
        description:
          'Ensure all necessary preparations are completed in time for the launch date, including manufacturing, packaging, distribution, and marketing.',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '27 Apr 2023',
      },

      {
        id: 'E005WP006_O02',
        description:
          'Launch the product on the scheduled date and monitor initial feedback and sales performance.',
        progress: 'none',
        assigneeName: 'Name Surname',
        dueDate: '28 Apr 2023',
      },
    ],
  },
];

// review

export const MockReviews = [
  {
    entityId: 'ENTITY_002',
    waypointId: 'E002WP001',
    review: {
      confidenceRating: 'High',
      remarks:
        'We are progressing as planned. All stakeholders have been informed and are updated regularly on the project progress. No major issues to report.',
      spendToDate: 500000,
    },
  },
  {
    entityId: 'ENTITY_003',
    waypointId: 'E003WP001',
    review: {
      confidenceRating: 'High',
      remarks:
        'The team is on track and we have a clear understanding of the project requirements. Some minor scope changes, but nothing that has caused major delays or budget overruns.',
      spendToDate: 250000,
    },
  },
  // {
  //   entityId: 'ENTITY_004',
  //   waypointId: 'E004WP001',
  //   review: {
  //     confidenceRating: 'High',
  //     remarks:
  //       'We are still in the planning stage and conducting research. There have been some delays due to unforeseen challenges, but we have implemented a risk management plan and are confident we can meet our objectives.',
  //     spendToDate: 100000,
  //   },
  // },

  // Note: Reverse sorted
  // It seems to be picking up only the WP001 waypoint
  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP002',
    review: {
      confidenceRating: 'Low',
      remarks: `Due to recent negative publicity associated with our preferred distribution partner, the launch of our new line of premium widgets is at risk. The negative publicity has impacted consumer trust and confidence in the chain, which may damage the reputation of our company and the new product line and make it more difficult for us to meet the revenue target.

We are currently assessing the impact of this development on our distribution strategy, and are considering alternative options to ensure that our new product line is successfully launched.`,
      spendToDate: 75000,
    },
  },
  {
    entityId: 'ENTITY_005',
    waypointId: 'E005WP001',
    review: {
      confidenceRating: 'High',
      remarks: `We're making good progress on our new line of premium chicken products. We've finalized recruitment for our customer taste testing focus group and we have a letter of intent with the health food store chain for distribution. Additionally, our campaign strategy for 'lean protein' is complete. We're on track to meet our milestones and are excited about the potential for this new line of products.`,
      spendToDate: 70000,
    },
  },

  {
    entityId: 'ENTITY_006',
    waypointId: 'E006WP002',
    review: {
      confidenceRating: 'High',
      remarks: `We are progressing as planned and have identified several sustainable packaging options. The team is working on a recycling program that aligns with our customer's expectations.`,
      spendToDate: 150000,
    },
  },
  {
    entityId: 'ENTITY_007',
    waypointId: 'E007WP001',
    review: {
      confidenceRating: '-',
      remarks: `Overall, the team is making good progress towards our objectives, with the campaign strategy for our new line of premium chicken products on track to be completed by the due date. However, we identified a potential issue with our planned distribution through health food stores, and have assigned a team member to investigate this further and identify possible solutions.`,
      spendToDate: 150000,
    },
  },
  {
    entityId: 'ENTITY_008',
    waypointId: 'E008WP001',
    review: {
      confidenceRating: 'Medium',
      remarks:
        'We have experienced some delays due to employee training requirements and adoption of the new system. However, we are confident that with continued support and training, employees will become more comfortable and efficient with the new system.',
      spendToDate: 100000,
    },
  },
  {
    entityId: 'ENTITY_009',
    waypointId: 'E009WP001',
    review: {
      confidenceRating: 'High',
      remarks:
        'We are making good progress, and the fleet is in the planning stages. The team has identified several experienced drivers and logistics professionals who are committed to ensuring the timely and safe delivery of products to customers.',
      spendToDate: 75000,
    },
  },
  {
    entityId: 'ENTITY_010',
    waypointId: 'E010WP001',
    review: {
      confidenceRating: 'High',
      remarks:
        'We are making good progress, and the team is working on digitising training materials and developing e-learning modules. We have a clear understanding of the project requirements and are confident that the new system will be effective and efficient.',
      spendToDate: 100000,
    },
  },
];

// waypoints

export const MockWaypoints = [
  {
    entityId: 'ENTITY_0001',
    waypoints: [
      {
        id: 'E0001WP001',
        date: new Date('31 Mar 2023'),
        type: WaypointType.checkIn,
        description: 'Q1 business review',
      },
      {
        id: 'E0001WP002',
        date: new Date('30 Jun 2023'),
        type: WaypointType.checkIn,
        description: 'Q2 business review',
      },
      {
        id: 'E0001WP005',
        date: new Date('15 May 2023'),
        type: WaypointType.externalCommitment,
        description: 'Industry conference event',
      },
      {
        id: 'E0001WP003',
        date: new Date('30 Sep 2023'),
        type: WaypointType.checkIn,
        description: 'Q3 business review',
      },
      {
        id: 'E0001WP006',
        date: new Date('1 Nov 2023'),
        type: WaypointType.externalCommitment,
        description: 'Launch of holiday-themed products',
      },
      {
        id: 'E0001WP004',
        date: new Date('31 Dec 2023'),
        type: WaypointType.checkIn,
        description: 'Q4 business review',
      },
    ],
  },

  {
    entityId: 'ENTITY_002',
    waypoints: [
      {
        id: 'E002WP001',
        date: new Date('1 Feb 2023'),
        type: WaypointType.checkIn,
        description: 'Review project plan with team',
      },
      {
        id: 'E002WP002',
        date: new Date('28 Feb 2023'),
        type: WaypointType.milestone,
        description: 'Complete refrigeration system upgrade',
      },
      {
        id: 'E002WP003',
        date: new Date('15 Mar 2023'),
        type: WaypointType.checkIn,
        description: 'Assess impact of upgrade on production line',
      },
    ],
  },
  {
    entityId: 'ENTITY_003',
    waypoints: [
      {
        id: 'E003WP001',
        date: new Date('2 Mar 2023'),
        type: WaypointType.checkIn,
        description: 'Finalize robotics selection and purchase',
      },
      {
        id: 'E003WP002',
        date: new Date('6 Apr 2023'),
        type: WaypointType.checkIn,
        description: 'Begin installing and testing monitoring systems',
      },
      {
        id: 'E003WP003',
        date: new Date('4 May 2023'),
        type: WaypointType.checkIn,
        description: 'Complete installation and begin data collection',
      },
    ],
  },
  // {
  //   entityId: 'ENTITY_004',
  //   waypoints: [
  //     {
  //       id: 'E004WP001',
  //       date: new Date('1 Mar 2023'),
  //       type: WaypointType.checkIn,
  //       description: 'Identify key genetic traits to target',
  //     },
  //     {
  //       id: 'E004WP002',
  //       date: new Date('5 Apr 2023'),
  //       type: WaypointType.checkIn,
  //       description: 'Begin breeding program for improved meat quality',
  //     },
  //     {
  //       id: 'E004WP003',
  //       date: new Date('3 May 2023'),
  //       type: WaypointType.checkIn,
  //       description: 'Begin breeding program for improved disease resistance',
  //     },
  //   ],
  // },
  {
    entityId: 'ENTITY_005', // New premium chicken products
    waypoints: [
      {
        id: 'E005WP001',
        date: addDays(new Date(), -30),
        type: WaypointType.checkIn,
        description: 'Agree on plan to launch',
      },
      {
        id: 'E005WP002', // Last waypoint
        date: addDays(new Date(), -10),
        type: WaypointType.checkIn,
        description: 'Check in on progress',
      },
      {
        id: 'E005WP003', // Next waypoint
        date: addDays(new Date(), 3),
        type: WaypointType.checkIn,
        description: 'Internal review and contingency plan',
      },
      {
        id: 'E005WP004',
        date: addDays(new Date(), 14),
        type: WaypointType.checkIn,
        description: 'Check distribution model and partnerships',
      },
      {
        id: 'E005WP005',
        date: addDays(new Date(), 30),
        type: WaypointType.checkIn,
        description: 'Pre-launch steering committee',
      },
      {
        id: 'E005WP006',
        date: addDays(new Date(), 60),
        type: WaypointType.milestone,
        description: 'Launch premium product line in 500 stores',
      },
    ],
  },
  {
    entityId: 'ENTITY_006',
    waypoints: [
      {
        id: 'E006WP001',
        date: new Date('26 Jan 2023'),
        type: WaypointType.checkIn,
        description: 'Agree on plan to launch',
      },
      {
        id: 'E006WP002', // Last waypoint
        date: new Date('20 Feb 2023'),
        type: WaypointType.checkIn,
        description: 'Check in on progress',
      },
    ],
  },
  {
    entityId: 'ENTITY_007',
    waypoints: [
      {
        id: 'E007WP001',
        date: new Date('10 Mar 2023'),
        type: WaypointType.milestone,
        description: 'Launch new website',
      },
    ],
  },
  {
    entityId: 'ENTITY_008',
    waypoints: [
      {
        id: 'E008WP001',
        date: new Date('1 Aug 2023'),
        type: WaypointType.checkIn,
        description: 'Implementation meeting with software vendor',
      },
      {
        id: 'E008WP002',
        date: new Date('15 Sep 2023'),
        type: WaypointType.checkIn,
        description: 'Complete training for all logistics employees',
      },
      {
        id: 'E008WP003',
        date: new Date('31 Oct 2023'),
        type: WaypointType.milestone,
        description: 'Launch real-time logistics management system',
      },
    ],
  },
  {
    entityId: 'ENTITY_009',
    waypoints: [
      {
        id: 'E009WP001',
        date: new Date('1 Sep 2023'),
        type: WaypointType.checkIn,
        description: 'Finalise refrigerated truck fleet specifications',
      },
      {
        id: 'E009WP002',
        date: new Date('15 Oct 2023'),
        type: WaypointType.checkIn,
        description: 'Sign agreements with logistics partners for delivery',
      },
      {
        id: 'E009WP003',
        date: new Date('31 Oct 2023'),
        type: WaypointType.milestone,
        description: 'Launch refrigerated truck fleet and begin deliveries',
      },
    ],
  },
  {
    entityId: 'ENTITY_010',
    waypoints: [
      {
        id: 'E010WP001',
        date: new Date('1 Sep 2023'),
        type: WaypointType.checkIn,
        description: 'Begin digitising employee training materials',
      },
      {
        id: 'E010WP002',
        date: new Date('15 Oct 2023'),
        type: WaypointType.checkIn,
        description: 'Complete development of e-learning modules',
      },
      {
        id: 'E010WP003',
        date: new Date('30 Nov 2023'),
        type: WaypointType.checkIn,
        description: 'Launch digital safety assurance system',
      },
      {
        id: 'E010WP004',
        date: new Date('31 Dec 2023'),
        type: WaypointType.milestone,
        description: 'Completion of the employee safety digitisation project',
      },
    ],
  },
];

// watchouts

export const MockWatchouts = [
  {
    entityId: 'ENTITY_005', // New premium chicken products
    watchouts: [
      {
        id: 'WATCHOUT_001',
        description:
          'Delayed and/or suboptimal launch may impact our ability to meet the sales target for the new product line',
        type: WatchoutType.risk,
        sourceEntityName: 'New product line',
        creatorName: 'Alex Bancroft',
        dueDate: '28 Apr 2023',
        escalatedAssigneeName: 'Mark Ortez',
        status: WatchoutStatus.escalated,
        hasActionPlan: false,
        actionPlan: '',
      },
      {
        id: 'WATCHOUT_002',
        description:
          'Potential changes in regulation related to safety, packaging, or labelling',
        type: WatchoutType.risk,
        sourceEntityName: 'New product line',
        creatorName: 'Alex Bancroft',
        dueDate: '',
        escalatedAssigneeName: '',
        status: WatchoutStatus.open,
        hasActionPlan: false,
        actionPlan: '',
      },
      {
        id: 'WATCHOUT_003',
        description:
          'Supply chain disruption to launching the product nation-wide',
        type: WatchoutType.issue,
        sourceEntityName: 'New product line',
        creatorName: 'Alex Bancroft',
        dueDate: '28 Apr 2023',
        escalatedAssigneeName: '',
        status: WatchoutStatus.open,
        hasActionPlan: false,
        actionPlan: '',
      },
      {
        id: 'WATCHOUT_004',
        description: 'New entrants into the premium widget space',
        type: WatchoutType.risk,
        sourceEntityName: 'New product line',
        creatorName: 'Alex Bancroft',
        dueDate: '',
        escalatedAssigneeName: '',
        status: WatchoutStatus.open,
        hasActionPlan: false,
        actionPlan: '',
      },
    ],
  },
];

// strategic objectives

export const MockStrategicObjectives = [
  {
    strategicObjectiveId: 'SO001',
    strategicObjective:
      'Expand production capacity and improve operational processes',
    baseline: '12,000 units/hour',
    target: '20,000 units/hour',
    current: '15,000 units/hour',
  },
  {
    strategicObjectiveId: 'SO002',
    strategicObjective: 'Expand market share in Australia',
    baseline: '6%',
    target: '10%',
    current: '7.3%',
  },
  {
    strategicObjectiveId: 'SO003',
    strategicObjective: 'Improve distribution efficiency',
    baseline: '10 day order fulfillment cycle time',
    target: '5 day order fulfillment cycle time',
    current: '8 day order fulfillment cycle time',
  },
  {
    strategicObjectiveId: 'SO004',
    strategicObjective: 'Improve workforce safety and compliance',
    baseline: '2.5% LTIFR',
    target: '0% LTIFR',
    current: '1.8% LTIFR',
  },
];

// objectives per entity
export const MockEntityObjectives = [
  {
    entityId: 'ENTITY_001',
    objectives: [
      // This duplicates strategic objectives above for the top level entity (except id)
      {
        objectiveId: 'E001O001',
        objective: 'Expand processing capacity and upgrade farming practices',
        baseline: '12,000 units/hour',
        target: '20,000 units/hour',
        current: '15,000 units/hour',
      },
      {
        objectiveId: 'E001O002',
        objective: 'Expand market share in Australia',
        baseline: '6%',
        target: '10%',
        current: '7%',
      },
      {
        objectiveId: 'E001O003',
        objective: 'Improve distribution efficiency',
        baseline: '10 day order fulfillment cycle time',
        target: '5 day order fulfillment cycle time',
        current: '8 day order fulfillment cycle time',
      },
      {
        objectiveId: 'E001O004',
        objective: 'Improve workforce safety and compliance',
        baseline: '2.5% LTIFR',
        target: '0% LTIFR',
        current: '1.8% LTIFR',
      },
    ],
  },
  {
    entityId: 'ENTITY_002', // Refrigeration system upgrade across all sites
    objectives: [
      {
        objectiveId: 'E002O001',
        objective: 'Improve temperature control and stability',
        baseline: 'Within +/- 2 degrees Celsius',
        target: 'Within +/- 1 degree Celsius',
        current: 'Within +/- 2 degrees Celsius',
      },
      {
        objectiveId: 'E002O002',
        objective: 'Increase lifespan of refrigeration systems',
        baseline: '10 years',
        target: '15 years',
        current: '12 years',
      },
      {
        objectiveId: 'E002O003',
        objective: 'Decrease maintenance cost and downtime',
        baseline: '$250K per year',
        target: '$200K per year',
        current: '$245K per year',
      },
    ],
  },
  {
    entityId: 'ENTITY_003', // Smart monitoring systems
    objectives: [
      {
        objectiveId: 'E003O001',
        objective: 'Increase hatchability rate',
        baseline: '80%',
        target: '85%',
        current: '1.3K tons',
      },
      {
        objectiveId: 'E003O002',
        objective: 'Minimize stress and aggression in chickens',
        baseline: 'Moderate',
        target: 'Low',
        current: 'Moderate',
      },
      {
        objectiveId: 'E003O003',
        objective: 'Increase early disease detection',
        baseline: '50%',
        target: '80%',
        current: '45%',
      },
    ],
  },
  // {
  //   entityId: 'ENTITY_004', // Genetics research program
  //   objectives: [
  //     {
  //       objectiveId: 'E004O001',
  //       objective: 'Increase early disease detection',
  //       baseline: '50%',
  //       target: '80%',
  //       current: '70%',
  //     },
  //     {
  //       objectiveId: 'E004O002',
  //       objective: 'Reduce mortality rate',
  //       baseline: '5%',
  //       target: '2%',
  //       current: '3.5%',
  //     },
  //     {
  //       objectiveId: 'E004O003',
  //       objective: 'Improve feed conversion ratio',
  //       baseline: '1.8',
  //       target: '1.6',
  //       current: '1.7',
  //     },
  //     {
  //       objectiveId: 'E004O004',
  //       objective: 'Minimize stress and aggression in chickens',
  //       baseline: 'Moderate',
  //       target: 'Low',
  //       current: 'Moderate',
  //     },
  //     {
  //       objectiveId: 'E004O005',
  //       objective: 'Improve hatchabiilty rate',
  //       baseline: '85%',
  //       target: '90%',
  //       current: '87%',
  //     },
  //     {
  //       objectiveId: 'E004O006',
  //       objective: 'Optimise feeding and drinking behavior',
  //       baseline: 'Manual observation',
  //       target: 'Automated monitoring',
  //       current:
  //         'In progress: first prototypes of automated monitoring system developed',
  //     },
  //   ],
  // },

  {
    entityId: 'ENTITY_005', // New premium chicken products
    objectives: [
      {
        objectiveId: 'E005O001',
        objective: 'Increase revenue by $50M',
        baseline: '$0',
        target: '$50M',
        current: '$12M',
      },
      {
        objectiveId: 'E005O002',
        objective:
          'New customer acquisition and presence by signing national health stores chains',
        baseline: 'No presence in national health store chains',
        target:
          'Secure partnerships with at least 3 national health store chains within the first year of launch',
        current: 'Partnerships secured with 1 national health store chain',
      },
      {
        objectiveId: 'E005O003',
        objective: 'Improve profitability to 15% in the premium product space',
        baseline: 'No profitability in the premium product space',
        target:
          'Achieve a profitability of 15% or higher within the first year of launch',
        current: 'Profitability in the premium product space at 10%',
      },
      {
        objectiveId: 'E005O004',
        objective: 'Differentiating brand recognition',
        baseline: 'No established brand recognition',
        target:
          'Develop a strong brand identity and recognition in the health-conscious consumer market within the first year of launch',
        current: 'Brand recognition established among 25% of target market',
      },
    ],
  },
];

// members per entity
export const MockMembersPerEntity = [
  {
    entityId: 'ENTITY_001',
    members: [
      {
        firstName: 'FirstName',
        surname: 'Surname',
        email: 'first@company.com',
        businessRole: 'Outcome owner',
        permissionLevel: 'Owner',
      },
      {
        firstName: 'FirstName',
        surname: 'Surname',
        email: 'second@company.com',
        businessRole: 'Oversight',
        permissionLevel: 'Editor',
      },
      {
        firstName: 'FirstName',
        surname: 'Surname',
        email: 'third@company.com',
        businessRole: 'Day-to-day management',
        permissionLevel: 'Editor',
      },
      {
        firstName: 'FirstName',
        surname: 'Surname',
        email: 'fourth@company.com',
        businessRole: 'Contributor',
        permissionLevel: 'Viewer',
      },
    ],
  },
];

export const MockMutualActionPlans = [
  {
    id: 'MAP_001',
    actionItems: [
      {
        date: new Date('2 Mar 2023'),
        description: 'Action xxx',
        isDone: true,
      },
      {
        date: new Date('22 Mar 2023'),
        description: 'Action yyy',
        isDone: false,
      },
    ],
  },
  {
    id: 'MAP_002',
    actionItems: [
      {
        date: new Date('15 Mar 2023'),
        description: 'Action aaa',
        isDone: false,
      },
      {
        date: new Date('2 Apr 2023'),
        description: 'Action bbb',
        isDone: false,
      },
      {
        date: new Date('28 Apr 2023'),
        description: 'Action ccc',
        isDone: false,
      },
    ],
  },
];

// Relationships
// Assumption here is that the table will be paired
// so that we can filter by a single entity
export const MockRelationships = [
  {
    relationshipId: 'REL_005-002',
    entityId: 'ENTITY_005',
    relatedentityId: 'ENTITY_002',
    type: RelationshipType.enables, // 006 enables 005
    mutualActionPlanId: 'MAP_001',
    description: '',
  },
  {
    relationshipId: 'REL_002-005',
    entityId: 'ENTITY_002',
    relatedentityId: 'ENTITY_005',
    type: RelationshipType.dependsOn, // 005 depends on 006
    mutualActionPlanId: 'MAP_001',
    description: '',
  },

  {
    relationshipId: 'REL_005-006',
    entityId: 'ENTITY_005',
    relatedentityId: 'ENTITY_006',
    type: RelationshipType.dependsOn, // 006 depends on 005
    mutualActionPlanId: 'MAP_001',
    description: '',
  },
  {
    relationshipId: 'REL_006-005',
    entityId: 'ENTITY_006',
    relatedentityId: 'ENTITY_005',
    type: RelationshipType.enables, // 005 enables 006
    mutualActionPlanId: 'MAP_001',
    description: '',
  },

  {
    relationshipId: 'REL_005-007',
    entityId: 'ENTITY_005',
    relatedentityId: 'ENTITY_007',
    type: RelationshipType.dependsOn, // 007 depends on 005
    mutualActionPlanId: 'MAP_002',
    description: '',
  },
  {
    relationshipId: 'REL_007-005',
    entityId: 'ENTITY_007',
    relatedentityId: 'ENTITY_005',
    type: RelationshipType.enables, // 005 enables 007
    mutualActionPlanId: 'MAP_002',
    description: '',
  },
];

// entities and meta data

// children entities

// const MockDataChildEntity0 = {
//   id: 'ENTITY_002',
//   name: 'My Second Entity',
//   description: 'Lifecycle Management',
//   stage: 'In-flight',
//   createdAt: new Date('2022-01-01T00:00:00.000Z'),
//   updatedAt: new Date('2023-02-20T00:00:00.000Z'),
//   startDate: new Date('2023-01-01T00:00:00.000Z'),
//   endDate: new Date('2023-05-22T00:00:00.000Z'),
//   parentId: 'ENTITY_001',
//   children: [],
//   primaryStrategicObjective: 'SO001',
//   primaryParentObjective: '',
//   objectives: MockEntityObjectives[1].objectives,
//   attachments: null,
//   waypoints: MockWaypoints[0].waypoints,
//   members: MockMembersPerEntity[0].members,
//   relationships: MockRelationships[1].relationships,
//   group: null,
//   budget: null,
//   createdBy: 'USER_001',
//   workspaceId: 'WORKSPACE_001',
// };

// // top level parent
// const TopLevelEntity0 = {
//   id: 'ENTITY_001',
//   name: 'Hazeldenes Company Level',
//   description: 'Top level project holder',
//   stage: 'In-flight',
//   createdAt: new Date('2022-01-01T00:00:00.000Z'),
//   updatedAt: new Date('2023-02-20T00:00:00.000Z'),
//   startDate: new Date('2023-01-01T00:00:00.000Z'),
//   endDate: new Date('2023-05-22T00:00:00.000Z'),
//   parentId: 'ENTITY_001',
//   children: [MockDataChildEntity0],
//   primaryStrategicObjective: 'SO001',
//   primaryParentObjective: '',
//   objectives: MockEntityObjectives[1].objectives,
//   attachments: null,
//   waypoints: MockWaypoints[0].waypoints,
//   members: MockMembersPerEntity[0].members,
//   relationships: MockRelationships[0].relationships,
//   group: null,
//   budget: null,
//   createdBy: 'USER_001',
//   workspaceId: 'WORKSPACE_001',
// };

// Entities
export const MockDataChildEntity0 = {
  id: 'ENTITY_002',
  name: 'Production systems upgrade',
  description: 'Operationalise new systems across all of our production sites.',
  stage: 'In-flight',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-01-09T00:00:00.000Z'),
  endDate: new Date('2023-04-30T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO001',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: undefined,
  isStarred: false,
};

export const MockDataChildEntity1 = {
  id: 'ENTITY_003',
  name: 'Smart monitoring systems',
  description:
    'Implement robotics and sensors to monitor chicken health and behavior.',
  stage: 'In-flight',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-02-20T00:00:00.000Z'),
  endDate: new Date('2023-08-30T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO001',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: undefined,
  isStarred: false,
};

// export const MockDataChildEntity2 = {
//   id: 'ENTITY_004',
//   name: 'Genetics research program',
//   description:
//     'Identify the most desirable traits and develop breeding programs to produce chickens with better meat quality and disease resistance.',
//   stage: 'Planning',
//   createdAt: new Date('2023-02-21T00:00:00.000Z'),
//   updatedAt: new Date('2023-02-21T00:00:00.000Z'),
//   startDate: new Date('2023-05-01T00:00:00.000Z'),
//   endDate: new Date('2023-07-31T00:00:00.000Z'),
//   parentId: 'ENTITY_001',
//   children: [],
//   primaryStrategicObjective: 'SO001',
//   primaryParentObjective: '',
//   objectives: MockEntityObjectives[1].objectives,
//   attachments: null,
//   waypoints: [],
//   members: [],
//   relationships: [],
//   group: null,
//   budget: null,
//   createdBy: 'USER_001',
//   workspaceId: 'WORKSPACE_001',
//   myRole: undefined,
//   isStarred: false,
// };

// This is the main project level entity that Yuyan will use (aside from the company level entity)
export const MockDataChildEntity3 = {
  id: 'ENTITY_005',
  name: 'New product line',
  description: 'Launch a new line of premium widgets aimed at Gen-Y consumers.',
  stage: 'In-flight',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-02-14T00:00:00.000Z'),
  endDate: new Date('2023-06-30T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO002',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: MockWaypoints.find(item => item.entityId === 'ENTITY_005'),
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: 'Owner',
  isStarred: true,
};

export const MockDataChildEntity4 = {
  id: 'ENTITY_006',
  name: 'Eco-friendly packaging program',
  description:
    'Research and implement new sustainable packaging materials and design a packaging recycling program for customers.',
  stage: 'Planning',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-04-17T00:00:00.000Z'),
  endDate: new Date('2023-09-30T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO002',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: undefined,
  isStarred: false,
};

export const MockDataChildEntity5 = {
  id: 'ENTITY_007',
  name: 'Nation-wide marketing campaign',
  description:
    'Our 2024 marketing push, to be centred around the premium products and eco-friendly packaging.',
  stage: 'Concept',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-07-01T00:00:00.000Z'),
  endDate: new Date('2023-10-31T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO002',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: undefined,
  isStarred: false,
};

export const MockDataChildEntity6 = {
  id: 'ENTITY_008',
  name: 'Real-time logistics management system',
  description:
    'Implement software to track inventory, shipments, and delivery times, and ensure employees use the new system.',
  stage: 'In-flight',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-03-01T00:00:00.000Z'),
  endDate: new Date('2023-09-30T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO003',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: undefined,
  isStarred: false,
};

export const MockDataChildEntity7 = {
  id: 'ENTITY_009',
  name: 'In-house trucking',
  description:
    'Invest in our own fleet of delivery vehicles and hire experienced drivers and logistics professionals to ensure the timely and safe delivery of products to our customers.',
  stage: 'Planning',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-08-01T00:00:00.000Z'),
  endDate: new Date('2023-12-15T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO003',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: undefined,
  isStarred: false,
};

export const MockDataChildEntity8 = {
  id: 'ENTITY_010',
  name: 'Digitise employee safety training and assurance',
  description:
    'Modernise employee safety training and assurance by digitising training materials, developing e-learning modules, and implementing a digital safety assurance system to improve effectiveness, increase participation, and improve safety data tracking and reporting.',
  stage: 'In-flight',
  createdAt: new Date('2023-02-21T00:00:00.000Z'),
  updatedAt: new Date('2023-02-21T00:00:00.000Z'),
  startDate: new Date('2023-02-01T00:00:00.000Z'),
  endDate: new Date('2023-04-30T00:00:00.000Z'),
  parentId: 'ENTITY_001',
  children: [],
  primaryStrategicObjective: 'SO004',
  primaryParentObjective: '',
  objectives: MockEntityObjectives[1].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: undefined,
  isStarred: false,
};

export const TopLevelEntity0 = {
  id: 'ENTITY_0001',
  name: 'Company-wide',
  description: 'This is the top level, representing our entire organisation.',
  stage: 'In-flight',
  createdAt: new Date('2022-01-01T00:00:00.000Z'),
  updatedAt: new Date('2023-02-20T00:00:00.000Z'),
  startDate: new Date('2023-01-01T00:00:00.000Z'),
  endDate: null,
  parentId: null,
  children: [
    MockDataChildEntity0,
    MockDataChildEntity1,
    // MockDataChildEntity2,
    MockDataChildEntity3,
    MockDataChildEntity4,
    MockDataChildEntity5,
    MockDataChildEntity6,
    MockDataChildEntity7,
    MockDataChildEntity8,
  ],
  primaryStrategicObjective: 'SO001', // TOOD: Agree logic for top level entity
  primaryParentObjective: '',
  objectives: MockEntityObjectives[0].objectives,
  attachments: null,
  waypoints: [],
  members: [],
  relationships: [],
  group: null,
  budget: null,
  createdBy: 'USER_001',
  workspaceId: 'WORKSPACE_001',
  myRole: 'Oversight',
  isStarred: true,
};

export const objectivePanelData = [
  {
    entityId: 'ENTITY_0001',
    panelData: {
      open: false,
      setOpen: () => alert('Hide the slide over panel'),
      title: 'Funnel conversion rate',
      parentEntityName: 'APAC Business Unit',
      parentEntityUrl: '/entity/ENTITY_ID',
      updates: [
        {
          date: '12 Dec 2022',
          value: '15%',
          tag: 'Baseline',
        },
        {
          date: '1 Jan 2023',
          value: '17%',
          tag: '',
        },
        {
          date: '1 Feb 2023',
          value: '17%',
          tag: 'Latest',
        },
        {
          date: '1 Jun 2023',
          value: '20%',
          tag: 'Target',
        },
      ],
    },
  },
  {
    entityId: 'ENTITY_002',
    panelData: {
      open: false,
      setOpen: () => alert('Hide the slide over panel'),
      title: 'Expand production capacity and improve operational processes',
      parentEntityName: 'Company-wide',
      parentEntityUrl: '/entity/ENTITY_0001',
      updates: [
        {
          date: '1 Dec 2022',
          value: '12,000 units/hour',
          tag: 'Baseline',
        },
        {
          date: '1 Jan 2022',
          value: '12,500 units/hour',
          tag: '',
        },
        {
          date: '1 Feb 2023',
          value: '13,800 units/hour',
          tag: '',
        },
        {
          date: '1 Mar 2023',
          value: '15,000 units/hour',
          tag: 'Latest',
        },
        {
          date: '1 Jun 2023',
          value: '20,000 units/hour',
          tag: 'Target',
        },
      ],
    },
  },
  {
    entityId: 'ENTITY_003',
    panelData: {
      open: false,
      setOpen: () => alert('Hide the slide over panel'),
      title: 'Customer churn rate',
      parentEntityName: 'North America Business Unit',
      parentEntityUrl: '/entity/ENTITY_ID',
      updates: [
        {
          date: '12 Dec 2022',
          value: '5%',
          tag: 'Baseline',
        },
        {
          date: '1 Jan 2023',
          value: '4%',
          tag: '',
        },
        {
          date: '1 Feb 2023',
          value: '4%',
          tag: 'Latest',
        },
        {
          date: '1 Jun 2023',
          value: '3%',
          tag: 'Target',
        },
      ],
    },
  },
  // {
  //   entityId: 'ENTITY_004',
  //   panelData: {
  //     open: false,
  //     setOpen: () => alert('Hide the slide over panel'),
  //     title: 'Employee satisfaction',
  //     parentEntityName: 'Human Resources Department',
  //     parentEntityUrl: '/entity/ENTITY_ID',
  //     updates: [
  //       {
  //         date: '12 Dec 2022',
  //         value: '70%',
  //         tag: 'Baseline',
  //       },
  //       {
  //         date: '1 Jan 2023',
  //         value: '72%',
  //         tag: '',
  //       },
  //       {
  //         date: '1 Feb 2023',
  //         value: '74%',
  //         tag: 'Latest',
  //       },
  //       {
  //         date: '1 Jun 2023',
  //         value: '80%',
  //         tag: 'Target',
  //       },
  //     ],
  //   },
  // },
  {
    entityId: 'ENTITY_005',
    panelData: {
      open: false,
      setOpen: () => alert('Hide the slide over panel'),
      title: 'Expand market share in Australia',
      parentEntityName: 'Hazeldenes Company',
      parentEntityUrl: '/entity/ENTITY_0001',
      updates: [
        {
          date: '30 Jun 2022',
          value: '6%',
          tag: 'Baseline',
        },
        {
          date: '30 Sep 2022',
          value: '6.5%',
          tag: '',
        },
        {
          date: '17 Dec 2022',
          value: '7.3%',
          tag: 'Latest',
        },
        {
          date: '1 Jun 2023',
          value: '10%',
          tag: 'Target',
        },
      ],
    },
  },
];

export const performancePanelData = [
  {
    entityId: 'ENTITY_0001',
    panelData: {
      open: false,
      setOpen: () => null,
      title: 'The name of the Entity we just clicked on',
      titleUrl: 'EntityURL',
      // Replace these with components
      description: 'Description of the entity we want to deep dive into',
      managers: [
        { firstName: 'Alex', surname: 'Smith', email: 'alex@company.com' },
        { firstName: 'Sam', surname: 'Jones', email: 'sam@company.com' },
      ],
      endDate: '1 Jan 2023 (in X months)',
      outcomes: [
        {
          description: 'Benefit 1',
          target: 'Target for benefit 1',
          targetDate: '30 Jun 2024',
        },
        {
          description: 'Benefit 2',
          target: 'Target for benefit 2',
          targetDate: '30 Dec 2025',
        },
      ],
      reviews: [
        {
          id: 'REVIEW_ID_1',
          date: '1 Nov 2022',
          confidence: '',
          remarks: '',
        },
        {
          id: 'REVIEW_ID_2',
          date: '2 Dec 2022',
          confidence: 'High',
          remarks: 'Nothing to worry about.',
        },
        {
          id: 'REVIEW_ID_3',
          date: '3 Jan 2023',
          confidence: 'Medium',
          remarks: 'Something just blindsided us.',
        },
        {
          id: 'REVIEW_ID_4',
          date: '4 Feb 2023',
          confidence: 'Low',
          remarks: "We're in trouble and need support.",
        },
      ],
      waypoints: [
        { date: '5 Mar 2023', description: 'Fix part 1' },
        { date: '6 Apr 2023', description: 'Fix part 2' },
        { date: '27 May 2023', description: 'Relaunch again' },
      ],
      escalations: [
        {
          id: 'ESC-001',
          description: 'Short description of the watchout',
          type: 'Issue',
          sourceEntityName: 'Name of a child entity',
          creatorName: 'Bruce Banner',
          dueDate: '4 May 2023',
          escalatedAssigneeName: 'Tony Stark',
        },
        {
          id: 'ESC-002',
          description: 'Short description of another watchout',
          type: 'Decision',
          sourceEntityName: 'Name of the same or a different child entity',
          creatorName: 'Steven Strange',
          dueDate: '30 Jun 2023',
          escalatedAssigneeName: 'Tony Stark',
        },
      ],
    },
  },
  {
    entityId: 'ENTITY_002',
    panelData: {
      open: false,
      setOpen: () => null,
      title: 'Revenue growth by product',
      titleUrl: '/entity/ENTITY_002',
      description:
        'Analyze the performance of different products and identify areas for improvement.',
      managers: [
        { firstName: 'Emily', surname: 'Lee', email: 'emily@company.com' },
        { firstName: 'Chris', surname: 'Wong', email: 'chris@company.com' },
      ],
      endDate: '1 Apr 2023 (in 1 month)',
      outcomes: [
        {
          description: 'Increase revenue for Product A',
          target: '10% growth',
          targetDate: '30 Jun 2023',
        },
        {
          description: 'Reduce customer churn for Product B',
          target: '5% reduction',
          targetDate: '30 Dec 2023',
        },
      ],
      reviews: [
        {
          id: 'REVIEW_ID_5',
          date: '1 Mar 2023',
          confidence: '',
          remarks: '',
        },
        {
          id: 'REVIEW_ID_6',
          date: '2 Mar 2023',
          confidence: 'High',
          remarks: 'Product A is doing well.',
        },
      ],
      waypoints: [
        {
          date: '15 Mar 2023',
          description: 'Analyze product A revenue growth',
        },
        {
          date: '25 Mar 2023',
          description: 'Identify reasons for product B churn',
        },
      ],
      escalations: [
        {
          id: 'ESC-003',
          description: 'Low inventory levels for Product C',
          type: 'Issue',
          sourceEntityName: 'Product C',
          creatorName: 'Bruce Banner',
          dueDate: '4 Apr 2023',
          escalatedAssigneeName: 'Tony Stark',
        },
      ],
    },
  },
  {
    entityId: 'ENTITY_003',
    panelData: {
      open: false,
      setOpen: () => null,
      title: 'Employee engagement survey',
      titleUrl: '/entity/ENTITY_003',
      description:
        'Review results of recent employee engagement survey and identify areas for improvement.',
      managers: [
        { firstName: 'Kate', surname: 'Johnson', email: 'kate@company.com' },
        { firstName: 'Tom', surname: 'Chen', email: 'tom@company.com' },
      ],
      endDate: '1 May 2023 (in 2 months)',
      outcomes: [
        {
          description: 'Increase employee satisfaction',
          target: '80% satisfaction rate',
          targetDate: '30 Jun 2023',
        },
        {
          description: 'Reduce employee turnover',
          target: '10% reduction',
          targetDate: '30 Dec 2023',
        },
      ],
      reviews: [
        {
          id: 'REVIEW_ID_7',
          date: '1 Apr 2023',
          confidence: '',
          remarks: '',
        },
        {
          id: 'REVIEW_ID_8',
          date: '2 Apr 2023',
          confidence: 'Medium',
          remarks: 'Results indicate some areas for improvement.',
        },
      ],
      waypoints: [
        { date: '15 Apr 2023', description: 'Analyze survey results' },
        {
          date: '25 Apr 2023',
          description: 'Develop action plan for improvement',
        },
      ],
      escalations: [
        {
          id: 'ESC-004',
          description: 'Customer complaint',
          type: 'Issue',
          sourceEntityName: 'Employee X',
          creatorName: 'Kate Johnson',
          dueDate: '4 May 2023',
          escalatedAssigneeName: 'Tom Chen',
        },
      ],
    },
  },
  // {
  //   entityId: 'ENTITY_004',
  //   panelData: {
  //     open: false,
  //     setOpen: () => null,
  //     title: 'Marketing campaign performance',
  //     titleUrl: '/entity/ENTITY_004',
  //     description:
  //       'Analyze the performance of a recent marketing campaign and identify areas for optimization.',
  //     managers: [
  //       { firstName: 'Mark', surname: 'Davis', email: 'mark@company.com' },
  //       { firstName: 'Jenny', surname: 'Wang', email: 'jenny@company.com' },
  //     ],
  //     endDate: '1 Jun 2023 (in 3 months)',
  //     outcomes: [
  //       {
  //         description: 'Increase website traffic',
  //         target: '20% growth',
  //         targetDate: '30 Jun 2023',
  //       },
  //       {
  //         description: 'Improve lead generation',
  //         target: '10% increase in leads',
  //         targetDate: '30 Dec 2023',
  //       },
  //     ],
  //     reviews: [
  //       {
  //         id: 'REVIEW_ID_9',
  //         date: '1 May 2023',
  //         confidence: '',
  //         remarks: '',
  //       },
  //       {
  //         id: 'REVIEW_ID_10',
  //         date: '2 May 2023',
  //         confidence: 'Low',
  //         remarks: 'Campaign is not performing as expected.',
  //       },
  //     ],
  //     waypoints: [
  //       {
  //         date: '15 May 2023',
  //         description: 'Analyze website traffic and lead data',
  //       },
  //       { date: '25 May 2023', description: 'Identify areas for optimization' },
  //     ],
  //     escalations: [
  //       {
  //         id: 'ESC-005',
  //         description: 'Negative feedback on campaign messaging',
  //         type: 'Issue',
  //         sourceEntityName: 'Marketing campaign',
  //         creatorName: 'Mark Davis',
  //         dueDate: '4 Jun 2023',
  //         escalatedAssigneeName: 'Jenny Wang',
  //       },
  //     ],
  //   },
  // },
  {
    entityId: 'ENTITY_005',
    panelData: {
      open: false,
      setOpen: () => null,
      title: 'New product line',
      titleUrl: '/entity/ENTITY_005',
      description:
        'Launch a new line of premium widgets aimed at Gen-Y consumers.',
      managers: [
        { firstName: 'Alex', surname: 'Brown', email: 'alex@company.com' },
      ],
      endDate: '31 Dec 2023 (in 7 months)',
      outcomes: [
        {
          description: 'Increase revenue by $50M',
          target: '$50 million',
          targetDate: '30 Dec 2023',
        },
        {
          description:
            'New customer acquisition and presence by signing large retail store chains',
          target:
            'Secure partnerships with at least 3 large retail distributors within the first year of launch',
          targetDate: '30 Dec 2023',
        },
        {
          description:
            'Improve profitability to 15% in the premium product space',
          target: '15% or higher within the first year of launch',
          targetDate: '30 Dec 2023',
        },
        {
          description: 'Differentiating brand recognition',
          target:
            'Develop a strong brand identity and recognition in the Gen-Y consumer market within the first year of launch',
          targetDate: '30 Dec 2023',
        },
      ],
      reviews: [
        {
          id: 'REVIEW_ID_11',
          date: getLocalDate(addDays(new Date(), -58)),
          confidence: 'High',
          remarks: `Received initial buy-in from the senior executive group.`,
        },
        {
          id: 'REVIEW_ID_12',
          date: getLocalDate(addDays(new Date(), -45)),
          confidence: 'High',
          remarks: 'Initial customer testing is being received positively.',
        },
        {
          id: 'REVIEW_ID_13',
          date: getLocalDate(addDays(new Date(), -30)),
          confidence: 'High',
          remarks: `We're making good progress on our new line of premium chicken products. We've finalized recruitment for our customer taste testing focus group and we have a letter of intent with the health food store chain for distribution. Additionally, our campaign strategy for 'lean protein' is complete. We're on track to meet our milestones and are excited about the potential for this new line of products.`,
        },
        {
          id: 'REVIEW_ID_14',
          date: getLocalDate(addDays(new Date(), -10)),
          confidence: 'Low',
          remarks: `Due to recent negative publicity associated with our preferred distribution partner, the launch of our new line of premium widgets is at risk. The negative publicity has impacted consumer trust and confidence in the chain, which may damage the reputation of our company and the new product line and make it more difficult for us to meet the revenue target.

We are currently assessing the impact of this development on our distribution strategy, and are considering alternative options to ensure that our new product line is successfully launched.`,
        },
      ],
      waypoints: [
        {
          date: getLocalDate(addDays(new Date(), 3)),
          description: 'Check distribution model and partnerships',
        },
        {
          date: getLocalDate(addDays(new Date(), 14)),
          description: 'Pre-launch steering committee',
        },
      ],
      escalations: [
        {
          id: 'ESC-006',
          description:
            'Delayed and/or suboptimal launch may impact our ability to meet the sales target for the new product line',
          type: 'Risk',
          sourceEntityName: 'New product line',
          creatorName: 'David Brown',
          dueDate: '28 Feb 2023',
          escalatedAssigneeName: 'Alex Bancroft',
        },
      ],
    },
  },
  {
    entityId: 'ENTITY_006',
    panelData: {
      open: false,
      setOpen: () => null,
      title: 'Website performance analysis',
      titleUrl: '/entity/ENTITY_006',
      description:
        'Analyze website performance and identify areas for improvement.',
      managers: [
        { firstName: 'Mike', surname: 'Lee', email: 'mike@company.com' },
        { firstName: 'Karen', surname: 'Wu', email: 'karen@company.com' },
      ],
      endDate: '1 Aug 2023 (in 5 months)',
      outcomes: [
        {
          description: 'Improve website speed',
          target: '3 seconds load time',
          targetDate: '30 Jun 2023',
        },
        {
          description: 'Increase website traffic',
          target: '10% growth',
          targetDate: '30 Dec 2023',
        },
      ],
      reviews: [
        {
          id: 'REVIEW_ID_13',
          date: '1 Jul 2023',
          confidence: '',
          remarks: '',
        },
        {
          id: 'REVIEW_ID_14',
          date: '2 Jul 2023',
          confidence: 'Medium',
          remarks: 'Website speed is an issue.',
        },
      ],
      waypoints: [
        {
          date: '15 Jul 2023',
          description: 'Analyze website speed and performance data',
        },
        { date: '25 Jul 2023', description: 'Identify areas for improvement' },
      ],
      escalations: [
        {
          id: 'ESC-007',
          description: 'Website downtime',
          type: 'Issue',
          sourceEntityName: 'Website',
          creatorName: 'Mike Lee',
          dueDate: '4 Aug 2023',
          escalatedAssigneeName: 'Karen Wu',
        },
      ],
    },
  },
];
