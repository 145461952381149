import React, { useMemo } from 'react';
import { ProjectUpdateConfidence } from '../../../api/index';
import WizardSelect from '../../../common/wizard/components/WizardSelect';

import Modal from '../../../common/Modal';
import { reorderEnums } from '../../../utils/translate';
import WizardTextArea from '../../../common/wizard/components/WizardTextArea';
import WizardTextInput from '../../../common/wizard/components/WizardTextInput';
import PrimaryButton from '../../../common/PrimaryButton';
import SecondaryButton from '../../../common/SecondaryButton';
import {
  CaretRight,
  Info,
  Shuffle,
  Target,
  WarningOctagon,
} from 'phosphor-react';
import WizardContentsCard, {
  WizardContentsData,
} from '../../../common/wizard/components/WizardContentsCard';

type ReviewInputScreenProps = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
};

type WizardStepSummaryProps = {
  stepName: string;
  description: string;
};

// Copied from Wizard.tsx (minor edits)
function WizardStepSummary({
  stepName,
  description,
}: WizardStepSummaryProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = React.useState(true);

  const caretIcon = <CaretRight weight="bold" size={16} className="shrink-0" />;

  return (
    // This is a collapsible menu that shows the step name plus additional information that can be hidden
    <button
      type="button"
      className="flex-1 w-full border border-accent-light border-opacity-50 px-4 py-3 rounded-md bg-blue-50 hover:bg-blue-100 text-left text-sm"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex">
        {/* Expand/collapse indicator */}
        <div
          className={`w-4 h-4 flex shrink-0 ${
            isExpanded ? 'rotate-90' : ''
          } mt-1 mr-3`}
        >
          {caretIcon}
        </div>

        <div>
          {/* Name of the step */}
          <h4 className="font-medium leading-6 text-accent-light">
            {stepName}
          </h4>
          {/* Additional information about the step */}
          <div className={`${isExpanded ? 'mt-3' : 'hidden'}`}>
            {description}
          </div>
        </div>
      </div>
    </button>
  );
}

type InputRowProps = {
  question: string;
  input: React.ReactElement;
};

function InputRow({ question, input }: InputRowProps): React.ReactElement {
  return (
    <div className="table-row w-full text-sm">
      <div className="table-cell w-2/5 pl-1 pr-4 py-3 text-accent-light font-medium">
        {question}
      </div>
      <div className="table-cell w-3/5 pl-4 py-3">{input}</div>
    </div>
  );
}

type ContentsCardProps = {
  key: string;
  contents: WizardContentsData;
  isSelected: boolean;
};

function ContentsCard({ key, contents, isSelected }: ContentsCardProps) {
  return (
    <WizardContentsCard
      key={key}
      contents={contents}
      selected={isSelected}
      jumpTo={0}
    />
  );
}

const iconSize = 32;

const steps = [
  {
    id: 'required',
    icon: <Info size={iconSize} weight="duotone" className="" />,
    label: 'Required Information',
    description: 'Share how we are tracking at this waypoint',
    isOptional: false,
  },
  {
    id: 'objectives',
    icon: <Target size={iconSize} weight="duotone" className="" />,
    label: 'Objectives',
    description: 'Update our measures of success for this initiative',
    isOptional: true,
  },
  {
    id: 'risks',
    icon: <WarningOctagon size={iconSize} weight="duotone" className="" />,
    label: 'Risks',
    description: 'Check, add or escalate where needed',
    isOptional: true,
  },
  {
    id: 'dependencies',
    icon: <Shuffle size={iconSize} weight="duotone" className="" />,
    label: 'Dependencies',
    description: 'Check or add relationships with other initiatives',
    isOptional: true,
  },
];

export default function ReviewInputScreen({
  onClose,
}: ReviewInputScreenProps): React.ReactElement {
  const confidenceOptions = [
    { label: 'Low', value: 'low' },
    { label: 'Medium', value: 'medium' },
    { label: 'High', value: 'high' },
  ]; // ['High', 'Medium', 'Low'];

  const confidenceInput = (
    // <WizardSelect
    //   id="confidenceRating"
    //   key="confidenceRating"
    //   requestKey="confidenceRating"
    //   label={`Delivery Confidence`}
    //   options={confidenceOptions}
    //   //   decorationType="confidence"
    //   disabled={false}
    // />
    <div>
      <label className="block text-sm font-medium text-gray-700 sm:mt-2 mb-1">
        {'Delivery confidence'}
      </label>
      <select
        defaultValue={'medium'}
        className={`block rounded-md w-full py-2 px-3 border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm`}
      >
        <option value="high">High</option>
        <option value="medium">Medium</option>
        <option value="low">Low</option>
      </select>
    </div>
  );

  const commentInput = (
    <WizardTextArea
      id="ontimeRemark"
      key="ontimeRemark"
      requestKey="ontimeRemark"
      isWizard={true}
      label="Comments"
      placeholder="Share a short summary of our progress and trajectory"
      //   validation={REASON_VALIDATION}
    />
  );

  const spendInput = (
    <WizardTextInput
      id="spendToDate"
      key="spendToDate"
      isWizard={true}
      textType="number"
      requestKey="spendToDate"
      label="Spend to date"
      //   validation={NUMBER_VALIDATION}
      placeholder={`Previous review: $137,800`}
      decorationType="currency"
    />
  );

  return (
    <Modal width={'w-full'} onClose={() => onClose(false)}>
      <div className="h-screen flex bg-white align-top divide-x divide-gray-200">
        {/* Left side */}
        <div className="w-80 shrink-0 p-4 overflow-y-auto h-full">
          <h2 className="text-lg font-medium mb-4">Add review</h2>
          <div className="w-full">
            {steps
              .filter(item => !item.isOptional)
              .map(item => {
                const contents = {
                  icon: item.icon,
                  label: item.label,
                  description: item.description,
                };

                return (
                  <WizardContentsCard
                    key={item.id}
                    contents={contents}
                    selected={true}
                    jumpTo={0}
                  />
                );
              })}
          </div>

          <p className="mt-8 mb-2 uppercase text-sm font-semibold">Optional</p>
          <div className="space-y-2">
            {steps
              .filter(item => item.isOptional)
              .map(item => {
                const contents = {
                  icon: item.icon,
                  label: item.label,
                  description: item.description,
                };

                return (
                  <WizardContentsCard
                    key={item.id}
                    contents={contents}
                    selected={false}
                    jumpTo={0}
                  />
                );
              })}
          </div>
        </div>

        {/* Right side */}
        <div className="grow">
          <div className="px-6 py-4 h-full w-full flex justify-center">
            {/* Width limiter */}
            <div className="h-full w-full max-w-5xl flex flex-col">
              <div className="mt-10 mb-6">
                <WizardStepSummary
                  stepName="Required information"
                  description="Think about the time since the previous review and how we are tracking toward the bigger picture outcome of this initiative."
                />
              </div>

              {/* Input content */}
              <div className="grow w-full">
                <div className="table w-full">
                  <InputRow
                    question="Have things improved since our last review?"
                    input={<div>{confidenceInput}</div>}
                  />
                  <InputRow
                    question="What is at risk? What support is needed?"
                    input={<div>{commentInput}</div>}
                  />
                  <InputRow
                    question="Have we spent any additional funding?"
                    input={<div>{spendInput}</div>}
                  />
                </div>
              </div>

              {/* Action buttons */}
              <div className="flex items-center justify-end space-x-3">
                <SecondaryButton
                  label={`Cancel`}
                  onClick={() => onClose(false)}
                  disabled={false}
                />

                <PrimaryButton
                  icon="save"
                  label={`Save & Close`}
                  onClick={() => onClose(false)}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
