import { RelationshipType } from '../../../JLcomponents/swimlanes/RelationshipsPanel/RelationshipsPanel';
import { WatchoutStatus } from '../../../JLcomponents/waypoints/WatchoutsPanel/WatchoutsPanel';
import { getLocalDate, getRelativeTime } from '../../utils';
import {
  MockEntityObjectives,
  MockMembersPerEntity,
  MockMutualActionPlans,
  MockOutcomes,
  MockRelationships,
  MockReviews,
  MockStrategicObjectives,
  MockWatchouts,
  MockWaypoints,
  objectivePanelData,
  performancePanelData,
  TopLevelEntity0,
} from './MockDataStructure';

export const getStrategicContributionsData = (entityId: string) => {
  const allEntities = getAllEntities();
  const entity = allEntities.find(e => e.id === entityId);
  const entityChildren = entity?.children || [];
  const enhancedChildren = entityChildren.map(
    (c: { primaryStrategicObjective: string; id: string }) => {
      const so = MockStrategicObjectives.find(
        o => o.strategicObjectiveId === c.primaryStrategicObjective
      );
      const watchouts = MockWatchouts.find(w => w.entityId === c.id)?.watchouts;
      const escHasEscalations = watchouts?.some(
        e => e.status === WatchoutStatus.escalated
      );
      return {
        ...c,
        strategicObjective: so?.strategicObjective,
        baseline: so?.baseline,
        target: so?.target,
        current: so?.current,
        hasEscalations: escHasEscalations,
        confidence: getNewestReviewConfidenceRating(c.id),
      };
    }
  );
  return enhancedChildren;
};

export const getObjectivePanelData = (entityId: string) => {
  const obj = objectivePanelData.find(o => o.entityId === entityId);
  return obj?.panelData;
};

export const getPerformancePanelData = (entityId: string) => {
  const obj = performancePanelData.find(o => o.entityId === entityId);
  return obj?.panelData;
};

export const getAllEntityWaypoints = (entityId: string) => {
  const waypoints = MockWaypoints.find(w => w.entityId === entityId)?.waypoints;
  return waypoints;
};
// reverse sort
export const sortWaypointsByDate = (
  waypoints: any[] | undefined,
  reverse = true
) => {
  const w = waypoints?.sort(
    (
      a: { date: { getTime: () => number } },
      b: { date: { getTime: () => number } }
    ) => {
      if (reverse) {
        return b.date.getTime() - a.date.getTime();
      } else {
        return a.date.getTime() - b.date.getTime();
      }
    }
  );
  return w;
};

export const getReviewByWaypointId = (waypointId: string) => {
  const reviews = MockReviews.find(r => r.waypointId === waypointId);
  return reviews?.review;
};

export const getOutcomesByWaypointId = (waypointId: string) => {
  const outcomes = MockOutcomes.find(r => r.waypointId === waypointId);
  return outcomes?.checklist;
};

export const getWatchoutsByEntityId = (entityId: string) => {
  const watchouts = MockWatchouts.find(w => w.entityId === entityId)?.watchouts;
  return watchouts;
};

export const getRelationshipsByEntityId = (entityId: string | undefined) => {
  if (!entityId) {
    return [];
  } else {
    const relationships = MockRelationships.filter(
      r => r.entityId === entityId
    );
    console.log('relationships', relationships);
    return relationships;
  }
};

export const getRelationshipSlideoverData = (entityId: string) => {
  const relationships = getRelationshipsByEntityId(entityId);
  const dependents = relationships.filter(
    r => r.type === RelationshipType.dependsOn
  );
  const enablers = relationships.filter(
    r => r.type === RelationshipType.enables
  );
  const currentEntity = getEntityMetaData(entityId);
  const dependendsData = dependents.map(r => {
    const d = getEntityMetaData(r.relatedentityId);
    console.log('d', d);
    return {
      id: r.relatedentityId,
      name: d?.name,
      confidenceRating: getNewestReviewConfidenceRating(r.relatedentityId),
    };
  });
  const enablerData = enablers.map(r => {
    const e = getEntityMetaData(r.relatedentityId);
    return {
      id: r.relatedentityId,
      name: e?.name,
      confidenceRating: getNewestReviewConfidenceRating(r.relatedentityId),
    };
  });
  return {
    open: false,
    setOpen: () => null,
    title: currentEntity?.name,
    titleUrl: `/entity/${entityId}`,
    description: currentEntity?.description,
    // TODO: Fix date getter here - they aren't showing the right date in the panel
    startDate: `14 Feb 2023 (2 weeks ago)`,
    // `${getLocalDate(currentEntity?.endDate)} (${getRelativeTime(
    //   currentEntity?.startDate
    // )})`,
    endDate: `30 Jun 2023 (in 4 months)`,
    // `${getLocalDate(currentEntity?.endDate)} (${getRelativeTime(
    //   currentEntity?.endDate
    // )})`,
    dependents: dependendsData,
    enablers: enablerData,
  };
};

export const getMutualActionPlanByMapId = (mapId: string) => {
  const mutualActionPlan = MockMutualActionPlans.find(map => map.id === mapId);
  return mutualActionPlan;
};

export const getEntityMetaData = (entityId: string | undefined) => {
  if (!entityId) {
    return null;
  }
  const allEntities = getAllEntities();
  const entity = allEntities.find(e => e.id === entityId);
  if (!entity) {
    return null;
  }
  return {
    name: entity?.name,
    description: entity?.description,
    primaryStrategicObjectiveName: entity?.primaryStrategicObjective,
    lifecycleStage: entity?.lifecycleStage,
    startDate: entity?.startDate,
    endDate: entity?.endDate,
  };
};

export const getEntityMembers = (entityId: string) => {
  const members = MockMembersPerEntity.find(
    m => m.entityId === entityId
  )?.members;
  return members;
};

export const getAllEntityObjectives = (entityId: string) => {
  const objs = MockEntityObjectives.find(
    e => e.entityId === entityId
  )?.objectives;
  return objs;
};

const getAllReviews = (entityId: string) => {
  const waypoints = sortWaypointsByDate(getAllEntityWaypoints(entityId));
  const waypointsWithReviews = waypoints?.map((wp: { id: string }) => {
    const review = MockReviews.find(r => r.waypointId === wp.id);
    return {
      ...wp,
      confidenceRating: review?.review.confidenceRating,
    };
  });
  return waypointsWithReviews;
};

const getNewestReviewConfidenceRating = (entityId: string) => {
  const reviews = getAllReviews(entityId);
  const filtered = reviews?.filter(r => r.confidenceRating);
  return filtered ? filtered[0].confidenceRating : 'None';
};

export const getAllChildEntities = (entity: any) => {
  let childEntities = entity.children;
  entity.children.forEach((child: any) => {
    childEntities = childEntities.concat(getAllChildEntities(child));
  });
  return childEntities;
};

export const getAllEntities = () => {
  const allEntities = [
    TopLevelEntity0,
    ...getAllChildEntities(TopLevelEntity0),
  ];
  return allEntities;
};
