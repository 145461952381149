import DotMenu, { MenuOption } from "../../basic/DotMenu";

type DotMenuTableCellProps = {
  menuOptions: Array<MenuOption>;
};

function DotMenuTableCell({ menuOptions }: DotMenuTableCellProps) {
  return (
    <div className="flex items-center group relative">
      <DotMenu menuOptions={menuOptions} />
    </div>
  );
}

export default DotMenuTableCell;
