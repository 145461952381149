import React, { useContext } from 'react';
import { PortfolioPageContext } from '../PortfolioPageContext';

import PortfolioPerformancePanel from './PortfolioPerformancePanelBeta';

function StrategyAlignmentTab(): React.ReactElement {
  const { state: portfolioPageState } = useContext(PortfolioPageContext);

  const programsList = portfolioPageState?.selectedPortfolio?.programs;

  return (
    <div className="flex flex-col gap-y-6">
      <PortfolioPerformancePanel
        portfolio={portfolioPageState.selectedPortfolio}
        programsList={programsList}
        loadingPrograms={false}
        errorPrograms={undefined}
      />
    </div>
  );
}

export default StrategyAlignmentTab;
