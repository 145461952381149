import * as React from 'react';

import { useReducer } from 'react';

import { IInvitation, Program, Project } from '../../api/index';

export type ProjectPageState = {
  selectedProject: Project | null;
  usersProjects: Array<Project> | null;
  loadUpdate: boolean;
  loadAsk: boolean;
  projects: Array<Project> | null;
  program: Program | null;
  selectedUpdate: string | null;
  invitations: Array<IInvitation> | null;
};

const initialState: ProjectPageState = {
  selectedProject: null,
  projects: null,
  loadUpdate: false,
  loadAsk: false,
  selectedUpdate: null,
  usersProjects: null,
  invitations: null,
  program: null,
};

type SetSelectedProjectAction = {
  type: 'SET_SELECTED_PROJECT';
  project: Project;
};

type SetSelectedUpdateAction = {
  type: 'SET_SELECTED_UPDATE';
  update: string;
};

type SetProjectsAction = {
  type: 'SET_PROJECTS';
  projects: Array<Project>;
};

type SetInvitationsAction = {
  type: 'SET_INVITATIONS';
  invitations: Array<IInvitation> | null;
};

type SetUsersProjectsAction = {
  type: 'SET_USERS_PROJECTS';
  usersProjects: Array<Project>;
};

type LoadUpdateAction = {
  type: 'LOAD_UPDATE';
  loadUpdate: boolean;
};

type LoadAskAction = {
  type: 'LOAD_ASK';
  loadAsk: true;
};

type SetParentProgramAction = {
  type: 'SET_PARENT_PROGRAM';
  program: Program;
};

export type ProjectPageAction =
  | SetSelectedProjectAction
  | LoadUpdateAction
  | LoadAskAction
  | SetSelectedUpdateAction
  | SetProjectsAction
  | SetUsersProjectsAction
  | SetInvitationsAction
  | SetParentProgramAction;

type ProjectPageContextState = {
  dispatch: React.Dispatch<ProjectPageAction>;
  state: ProjectPageState;
};

export const ProjectPageContext = React.createContext<ProjectPageContextState>({
  dispatch: () => null,
  state: initialState,
});

const reducer = (state: ProjectPageState, action: ProjectPageAction) => {
  switch (action.type) {
    case 'SET_SELECTED_PROJECT':
      return {
        ...state,
        selectedProject: action.project,
      };
    case 'SET_PROJECTS':
      return {
        ...state,
        projects: action.projects,
      };
    case 'SET_USERS_PROJECTS':
      return {
        ...state,
        usersProjects: action.usersProjects,
      };
    case 'SET_SELECTED_UPDATE':
      return {
        ...state,
        selectedUpdate: action.update,
      };
    case 'SET_PARENT_PROGRAM':
      return {
        ...state,
        program: action.program,
      };
    case 'SET_INVITATIONS':
      return {
        ...state,
        invitations: action.invitations,
      };
    case 'LOAD_UPDATE':
      return {
        ...state,
        loadUpdate: action.loadUpdate,
      };
    case 'LOAD_ASK':
      return {
        ...state,
        loadAsk: true,
      };
    default:
      return state;
  }
};

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

export function ProjectPageContextProvider({
  children,
}: Props): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState);
  // useEffect(() => {
  //   localStorage.setItem('project', JSON.stringify(state))
  // }, [state])
  return (
    <ProjectPageContext.Provider value={{ dispatch, state }}>
      {children}
    </ProjectPageContext.Provider>
  );
}
