import React from "react";
import Avatar from "react-avatar";
import { avatarBgColor } from "../common/constants";

type UserChipProps = {
  firstName: string;
  surname: string;
  email: string;
};

const UserInfo = ({ firstName, surname, email }: UserChipProps) => {
  return (
    <div className="flex items-center">
      <span className="flex items-center">
        <Avatar
          name={firstName + " " + surname}
          size="36"
          round="18px"
          color={avatarBgColor}
        />
        <span className="ml-3 ">
          <p className="block truncate text-sm font-medium text-gray-900">
            {`${firstName} ${surname}`}
          </p>
          <p className="block truncate text-sm text-gray-400">{`${email}`}</p>
        </span>
      </span>
    </div>
  );
};

export default UserInfo;
