import * as React from 'react';
import { Fragment, useState, useContext } from 'react';

import * as ROUTES from './routes';
import { useNavigate, useParams } from 'react-router-dom';
import { classNames } from './utils';
import { UserContext } from '../context/UserContext';
import { BetaContext } from '../context/BetaContext';
import { DemoContext } from '../context/DemoContext';
import { PortfolioPageContext } from '../components/portfolio/PortfolioPageContext';
import { ProjectPageContext } from '../components/project/ProjectPageContext';
import { ProgramPageContext } from '../components/program/ProgramPageContext';
import { WorkspacePageContext } from '../components/workspace/WorkspacePageContext';

// import NavTree from './NavTree';
import InvitesDropdown from './InvitesDropdown';
import NotificationsDropdown from './NotificationsDropdown';
import { ulid } from 'ulid';
import Avatar from 'react-avatar';

import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  List,
  X,
  ToggleRight,
  ToggleLeft,
  SignOut,
  IdentificationCard,
} from 'phosphor-react';
import translate, { enumTranslates, strings } from './i18n/translate';
import { EntityType } from '../api/index';
import { avatarBgColor } from './constants';
import MainNavButton, { MainNavIcon } from './MainNavButton';

export type MainNavItem = {
  name: string;
  icon: MainNavIcon;
  group: 'home' | 'workspace tools' | 'help' | 'view';
  href: string;
  routes: string[];
  tooltip: string;
  isAction: boolean;
  action: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

// Icons for user menu
const iconProfile = (
  <IdentificationCard
    className="text-gray-400 group-hover:text-gray-500 shrink-0"
    weight="duotone"
    size={22}
  />
);
const iconToggleOn = (
  <ToggleRight className="text-primary-500 shrink-0" weight="fill" size={22} />
);
const iconToggleOff = (
  <ToggleLeft
    className="text-gray-400 group-hover:text-gray-500 shrink-0"
    weight="fill"
    size={22}
  />
);
const iconSignout = (
  <SignOut
    className="text-gray-400 group-hover:text-gray-500 shrink-0"
    weight="duotone"
    size={22}
  />
);

const userNavigation = [
  {
    name: 'Your profile',
    href: ROUTES.PROFILE,
    routes: ['profile'],
    current: false,
    icon: iconProfile,
  },
];

type TriangleProps = {
  width: number;
  height: number;
};

// Shape for styled header bar
const RightTriangle = ({ width, height }: TriangleProps) => (
  <svg
    className="text-primary-500"
    fill="currentColor"
    width={width}
    height={height}
  >
    <defs>
      <filter id="shadow" className="text-gray-400">
        <feDropShadow
          dx="1"
          dy="0"
          stdDeviation="1.8"
          floodColor="currentColor"
        />
      </filter>
    </defs>
    <polygon
      points={`0,0 ${width},0 0,${height}`}
      stroke="none"
      className={`text-primary-500`}
      filter="url(#shadow)"
    />
  </svg>
);

type Props = {
  children: React.ReactNode;
};

function MainLayout({ children }: Props): React.ReactElement {
  // Create string constants for local storage
  const sidebarKey = 'SIDEBAR_MODE';
  const sidebarOn = 'SHOW_SIDEBAR';
  const sidebarOff = 'HIDE_SIDEBAR';

  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(
    localStorage.getItem(sidebarKey)
      ? localStorage.getItem(sidebarKey) === sidebarOn
      : true
  );

  const navigate = useNavigate();
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const { programId } = useParams<{ programId: string }>();
  const { projectId } = useParams<{ projectId: string }>();
  const { isDemo, toggleIsDemo } = useContext(DemoContext);
  const { isBeta, toggleIsBeta } = useContext(BetaContext);

  const { state: workspacePageState } = useContext(WorkspacePageContext);
  const { state: projectState } = useContext(ProjectPageContext);
  const { state: programState } = useContext(ProgramPageContext);
  const { state: portfolioState } = useContext(PortfolioPageContext);

  const demo = () => {
    if (toggleIsDemo) {
      toggleIsDemo();
      navigate('/');
      setMobileSidebarOpen(false);
    }
  };

  const beta = () => {
    if (toggleIsBeta) {
      toggleIsBeta();
      navigate('/');
    }
  };

  const { signOut, profile, user } = React.useContext(UserContext);

  let displayName = 'Journey Lab';
  if (profile?.firstName) {
    displayName = profile.firstName + ' ' + profile.lastName;
  }

  function handleDesktopSidebarToggle() {
    localStorage.setItem(
      sidebarKey,
      desktopSidebarOpen ? sidebarOff : sidebarOn
    );
    setDesktopSidebarOpen(!desktopSidebarOpen);
  }

  // Setup new navigation menu for v2
  // Full version shows the workspace tools menu - to be updated for config and admin pages
  const v2NavigationFull: MainNavItem[] = [
    {
      name: 'Home',
      icon: 'home',
      group: 'home',
      href: '/',
      routes: ['/'],
      tooltip: `Direct access to the things you're contributing to`,
      isAction: false,
      action: undefined,
    },
    {
      name: 'Directory',
      icon: 'directory',
      group: 'home',
      href: ROUTES.PORTFOLIO_LIST,
      routes: ['portfolios'],
      tooltip: `Navigate to an entity in this workspace`,
      isAction: false,
      action: undefined,
    },
    {
      name: 'Reports',
      icon: 'reports',
      group: 'workspace tools',
      href: ROUTES.REPORTING,
      routes: ['reporting'],
      tooltip: `Share reports securely with anyone`,
      isAction: false,
      action: undefined,
    },
    {
      name: 'Prioritisation',
      icon: 'prioritisation',
      group: 'workspace tools',
      href: ROUTES.PRIORITISATION,
      routes: ['prioritisation'],
      tooltip: `Review and plan ${
        enumTranslates[EntityType.Portfolio]
      } changes in a sandbox environment`,
      isAction: false,
      action: undefined,
    },
    {
      name: 'Getting Started',
      icon: 'help',
      group: 'help',
      href: ROUTES.HELP,
      routes: ['help'],
      tooltip: `Guidance on how to get the most out of ${translate(
        strings.BRAND_NAME,
        'JourneyLab'
      )}`,
      isAction: false,
      action: undefined,
    },
    {
      name: desktopSidebarOpen
        ? isDemo
          ? 'Exit Demo Mode'
          : 'Enter Demo Mode'
        : '',
      icon: isDemo ? 'demoOn' : 'demoOff',
      group: 'help',
      href: '',
      routes: [],
      tooltip: isDemo
        ? `Return to ${enumTranslates[EntityType.Workspace]}`
        : `See an interactive example using sample data`,
      isAction: true,
      action: () => demo(),
    },
  ];

  // Simple version hides workspace tools
  // TODO: Company link goes to the top-level entity
  // TBD: How to handle this if more than one top-level entity
  const v2NavigationSimple: MainNavItem[] = [
    {
      name: 'Home',
      icon: 'home',
      group: 'home',
      href: '/',
      routes: ['/'],
      tooltip: `Direct access to the things you're contributing to`,
      isAction: false,
      action: undefined,
    },
    {
      name: 'My Organisation',
      icon: 'organisation',
      group: 'home',
      href: '/',
      routes: ['organisation'],
      tooltip: `Go to the highest level, representing this organisation`,
      isAction: false,
      action: undefined,
    },
    {
      name: 'Directory',
      icon: 'directory',
      group: 'home',
      href: ROUTES.PORTFOLIO_LIST,
      routes: ['portfolios'],
      tooltip: `Navigate to an entity in this workspace`,
      isAction: false,
      action: undefined,
    },

    {
      name: 'Getting Started',
      icon: 'help',
      group: 'help',
      href: ROUTES.HELP,
      routes: ['help'],
      tooltip: `Guidance on how to get the most out of ${translate(
        strings.BRAND_NAME,
        'JourneyLab'
      )}`,
      isAction: false,
      action: undefined,
    },
    {
      name: desktopSidebarOpen
        ? isDemo
          ? 'Exit Demo Mode'
          : 'Enter Demo Mode'
        : '',
      icon: isDemo ? 'demoOn' : 'demoOff',
      group: 'help',
      href: '',
      routes: [],
      tooltip: isDemo
        ? `Return to ${enumTranslates[EntityType.Workspace]}`
        : `See an interactive example using sample data`,
      isAction: true,
      action: () => demo(),
    },
  ];

  // Beta mode to be reinstated later
  // Currently repurposing this to toggle workspace level admin features in menu
  const sideNavigation = isBeta ? v2NavigationFull : v2NavigationSimple;

  // Button to collapse the menu
  const hideButton: MainNavItem = {
    name: desktopSidebarOpen ? 'Collapse Menu' : '',
    icon: desktopSidebarOpen ? 'hide' : 'show',
    group: 'view',
    href: '',
    routes: [],
    tooltip: desktopSidebarOpen ? 'Show icons only' : 'Show menu labels',
    isAction: true,
    action: () => handleDesktopSidebarToggle(),
  };

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Transition.Root show={mobileSidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 md:hidden"
          onClose={setMobileSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-0 pb-4 bg-gradient-to-b from-accent-dark to-secondary-800">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                {/* Close button */}
                <div className="absolute top-2 right-0 mr-3">
                  <button
                    type="button"
                    className="ml-1 hover:bg-white hover:bg-opacity-20 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-800"
                    onClick={() => setMobileSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <X
                      className="h-6 w-6 text-white"
                      weight="bold"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>

              {/* JourneyLab logo */}
              <div className="bg-gradient-to-tr from-primary-700 to-primary-500 hover:to-primary-600 py-2">
                <div className="flex-shrink-0 flex items-center px-4">
                  <img
                    className={`h-10 w-10 shrink-0 rounded-full`}
                    src={`/jl-logo-circle_bg-192.png`}
                    alt="JourneyLab logo"
                  />
                  <span className="font-normal text-xl ml-2.5 mt-0.5 text-white">
                    {translate(strings.BRAND_NAME, 'JourneyLab')}
                  </span>
                </div>
              </div>

              {/* Navigation Menu - mobile */}
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-3 pb-4 space-y-1">
                  {sideNavigation.map((item, index) => (
                    <React.Fragment key={ulid()}>
                      <div>
                        {/* Section label */}
                        <div
                          className={`${
                            index > 0 &&
                            sideNavigation[index - 1].group === item.group
                              ? 'hidden'
                              : 'mt-6 mb-2 ml-2 text-white text-sm font-medium uppercase tracking-wide'
                          }`}
                        >
                          {item.group === 'home' || !desktopSidebarOpen
                            ? ''
                            : item.group}
                        </div>

                        {/* Menu button */}
                        <MainNavButton
                          item={item}
                          desktopSidebarOpen={desktopSidebarOpen}
                          isDemo={isDemo}
                          disabled={false}
                          setMobileMenuOpen={setMobileSidebarOpen}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:flex-shrink-0">
        <div
          className={`flex flex-col ${desktopSidebarOpen ? 'w-72' : 'w-18'}`}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0">
            {/* JourneyLab logo */}
            <div
              className={`flex items-center w-full h-14 flex-shrink-0 px-4 bg-gradient-to-r from-primary-700 to-primary-600`}
            >
              <img
                className={`h-10 w-10 shrink-0 rounded-full`}
                src={`/jl-logo-circle_bg-192.png`}
                alt="JourneyLab logo"
              />
              {desktopSidebarOpen ? (
                <span className="font-normal text-xl ml-2.5 mt-0.5 text-white">
                  {translate(strings.BRAND_NAME, 'JourneyLab')}
                </span>
              ) : null}
            </div>

            {/* Navigation Menu - desktop */}
            <div className="flex-1 flex flex-col bg-gradient-to-b from-accent-dark to-secondary-800 overflow-y-auto">
              <nav className="px-3 pb-4 space-y-1">
                {/* v2 navigation */}
                {sideNavigation.map((item, index) => (
                  <React.Fragment key={ulid()}>
                    <div>
                      {/* Section label */}
                      <div
                        className={`${
                          index > 0 &&
                          sideNavigation[index - 1].group === item.group
                            ? 'hidden'
                            : 'mt-6 mb-2 ml-2 text-white text-sm font-medium uppercase tracking-wide'
                        }`}
                      >
                        {item.group === 'home' || !desktopSidebarOpen
                          ? ''
                          : item.group}
                      </div>

                      {/* Menu button */}
                      <MainNavButton
                        item={item}
                        desktopSidebarOpen={desktopSidebarOpen}
                        isDemo={isDemo}
                        disabled={false}
                      />
                    </div>
                  </React.Fragment>
                ))}
              </nav>

              {/* View modifiers */}
              <div className="px-3 my-6 w-full space-y-1 h-full grow flex flex-col justify-end">
                {/* Hide nav menu button */}
                <MainNavButton
                  item={hideButton}
                  desktopSidebarOpen={desktopSidebarOpen}
                  isDemo={isDemo}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-14 bg-white shadow">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
            onClick={() => setMobileSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <List className="h-6 w-6" weight="bold" aria-hidden="true" />
          </button>
          <div className="flex-1 pr-4 flex justify-between">
            <div className="flex-1 flex md:hidden"></div>
            <div className="hidden md:flex-1 md:flex justify-end mr-2">
              {/* <span className="flex mt-0.5"></span> */}
              <div className="bg-gradient-to-r from-primary-600 to-primary-500 w-20 grow"></div>
              <div className="triangle-container">
                <RightTriangle width={24} height={56} />
              </div>

              {/* Search bar */}
              {/* <form className="w-full flex md:ml-0" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search-field"
                    className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                    placeholder="Search"
                    type="search"
                    name="search"
                  />
                </div>
              </form> */}
            </div>

            {/* Notification area (top right) */}
            <div className="ml-3 flex items-center md:ml-6">
              {/* Invite and notification dropdowns */}
              <InvitesDropdown />
              <NotificationsDropdown />

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                    <span className="sr-only">Open user menu</span>
                    <Avatar
                      name={displayName}
                      size="40"
                      round="20px"
                      color={avatarBgColor}
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-3.5 w-fit rounded-md overflow-hidden border border-gray-200 shadow-md py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Menu.Item>
                      <div className="block w-full text-left mb-1 border-b border-gray-200 px-4 py-2 text-sm text-gray-700">
                        <div className="font-semibold">{displayName}</div>
                        <div className="text-xs text-gray-400 mb-1">
                          {profile?.email}
                        </div>
                      </div>
                    </Menu.Item>

                    {/* User menu items */}
                    {userNavigation.map(item => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'flex items-center px-4 py-2 text-sm text-gray-700 group'
                            )}
                          >
                            {item.icon}
                            <span className="whitespace-nowrap ml-2.5">
                              {item.name}
                            </span>
                          </a>
                        )}
                      </Menu.Item>
                    ))}

                    {/* Beta mode for whitelisted admins */}
                    {user?.isAdmin && (
                      <Menu.Item>
                        <button
                          type="button"
                          onClick={() => beta()}
                          className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 group"
                        >
                          {isBeta ? iconToggleOn : iconToggleOff}
                          <span className="whitespace-nowrap ml-2.5">
                            {'Show pre-release features'}
                          </span>
                        </button>
                      </Menu.Item>
                    )}

                    {/* Sign out */}
                    <Menu.Item>
                      <button
                        type="button"
                        onClick={() => signOut()}
                        className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 group"
                      >
                        {iconSignout}
                        <span className="whitespace-nowrap ml-2.5">
                          {'Sign out'}
                        </span>
                      </button>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        {/* Demo banner */}
        {isDemo ? (
          <div className="relative bg-yellow-200 justify-center align-middle shadow-sm">
            <div className="py-1 text-sm text-center">
              You are currently in demo mode
            </div>
          </div>
        ) : null}

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className={`max-w-7xl mx-auto px-4 py-6 sm:px-6 md:px-8 mb-8`}>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default MainLayout;
