import {
  ProjectPriority,
  ProjectRole,
  ProjectUpdateConfidence,
} from '../../api/index';
import { addDays } from '../../common/utils';

export type Scenario = {
  id: string;
  name: string;
  referencePortfolio: DummyPortfolio;
  sandboxPortfolio: DummyPortfolio | null;
  ownerName: string; // TODO: Replace this with a User object not just a name
  createdDate: Date;
  modifiedDate: Date;
};

export type DummyPortfolio = {
  portfolioId: string;
  name: string;
  programs: Array<DummyProgram>;
};

export type DummyProgram = {
  programId: string;
  name: string;
  outcome: string;
  projects: Array<DummyProject>;
  measures: Array<DummyProgramMeasure>;
  envelope: number;
};

export type DummyProgramMeasure = {
  id: string;
  name: string;
  targetValue?: string;
  achieveByDate?: Date;
  // strategicObjectiveId: Scalars['ID'];
};

type DummyMember = {
  name: string;
  role: ProjectRole;
};

const dummyUsers: DummyMember[] = [
  {
    name: 'Renee Smith',
    role: ProjectRole.BusinessOwner,
  },
  {
    name: 'Jacob Williams',
    role: ProjectRole.ProjectOwner,
  },
];

export type DummyProject = {
  projectId: string;
  programId: string;
  name: string;
  outcome: string;
  rankInProgram: number;
  tier: ProjectPriority;
  isActive: boolean;
  isNew: boolean;
  measures: Array<DummyProjectMeasure>;
  // TODO: Decouple the date and budget fields in the charter from the project reviews
  members: { name: string; role: ProjectRole }[];
  startDate: Date;
  originalEndDate: Date;
  originalBudget: number;
  updates: {
    confidence: ProjectUpdateConfidence;
    // We would get this from the latest project review
    latestTimeEstimate: Date;
    latestCostEstimate: number;
  }[];
  tags: string[]; // Just save the ids so we can manage tags independently at portfolio level
  remark: string; // Potentially this could be Project.ifNotDone: string, surfaced during prioritisation but saved to the project
};

export type DummyProjectMeasure = {
  id: string;
  programMeasureId: string;
  name: string;
  targetValue?: string;
  achieveByDate?: Date;
  // strategicObjectiveId: Scalars['ID'];
};

const dummyProjectList = [
  {
    projectId: 'project-0011',
    programId: 'program-1',
    name: 'Project Alpha',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 1,
    tier: ProjectPriority.Tier1,
    isActive: true,
    isNew: false,
    measures: [
      {
        id: 'deliverable-111',
        programMeasureId: 'benefit-11',
        name: 'Set up Customer Advisory Board',
        targetValue: 'Operational with 25 members',
        achieveByDate: addDays(new Date(), 50),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.High,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-A2', 'tag-B8'],
    remark: `If we don't do this project, we will lose our licence from the regulator.`,
  },
  {
    projectId: 'project-0012',
    programId: 'program-1',
    name: 'Project Apple',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 3,
    tier: ProjectPriority.Tier2,
    isActive: true,
    isNew: false,
    measures: [
      {
        id: 'deliverable-121',
        programMeasureId: 'benefit-12',
        name: 'Early access program for CAB members',
        targetValue: '90% uptake',
        achieveByDate: addDays(new Date(), 110),
      },
      {
        id: 'deliverable-121',
        programMeasureId: 'benefit-12',
        name: 'Expand EAP to known friendlies',
        targetValue: '200 non-CAB members',
        achieveByDate: addDays(new Date(), 100),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.Medium,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-A3'],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0013',
    programId: 'program-1',
    name: 'Project Ando',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 2,
    tier: ProjectPriority.Tier1,
    isActive: true,
    isNew: true,
    measures: [
      {
        id: 'deliverable-131',
        programMeasureId: 'benefit-12',
        name: 'Get listed on Product Hunt',
        targetValue: 'Achieved',
        achieveByDate: addDays(new Date(), 50),
      },
      // {
      //   id: 'deliverable-112',
      //   programMeasureId: 'benefit-12',
      //   name: 'Switch to renewable power sources',
      //   targetValue: '60% of total energy usage',
      //   achieveByDate: addDays(new Date('30 June 2023'), 0),
      // },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.High,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: [],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0014',
    programId: 'program-1',
    name: 'Project Aspen',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 5,
    tier: ProjectPriority.Tier3,
    isActive: false,
    isNew: false,
    measures: [
      {
        id: 'deliverable-141',
        programMeasureId: 'benefit-12',
        name: 'Generate and bring in leads via LinkedIn',
        targetValue: '100 qualified leads',
        achieveByDate: addDays(new Date(), 40),
      },
      // {
      //   id: 'deliverable-112',
      //   programMeasureId: 'benefit-12',
      //   name: 'Switch to renewable power sources',
      //   targetValue: '60% of total energy usage',
      //   achieveByDate: addDays(new Date('30 June 2023'), 0),
      // },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.Low,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-A2', 'tag-B4', 'tag-C1', 'tag-D1'],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0015',
    programId: 'program-1',
    name: 'Project Andromeda',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 6,
    tier: ProjectPriority.Tier3,
    isActive: false,
    isNew: false,
    measures: [
      {
        id: 'deliverable-151',
        programMeasureId: 'benefit-11',
        name: 'Enable open voting on product roadmap',
        targetValue: 'Go-live',
        achieveByDate: addDays(new Date(), 10),
      },
      // {
      //   id: 'deliverable-112',
      //   programMeasureId: 'benefit-12',
      //   name: 'Switch to renewable power sources',
      //   targetValue: '60% of total energy usage',
      //   achieveByDate: addDays(new Date('30 June 2023'), 0),
      // },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.High,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: [],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0016',
    programId: 'program-1',
    name: 'Project Argentina',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 4,
    tier: ProjectPriority.Tier3,
    isActive: true,
    isNew: false,
    measures: [
      // {
      //   id: 'deliverable-111',
      //   programMeasureId: 'benefit-11',
      //   name: 'Reduce total carbon emissions',
      //   targetValue: '50% reduction vs June 2022',
      //   achieveByDate: addDays(new Date('30 June 2023'), 0),
      // },
      // {
      //   id: 'deliverable-112',
      //   programMeasureId: 'benefit-12',
      //   name: 'Switch to renewable power sources',
      //   targetValue: '60% of total energy usage',
      //   achieveByDate: addDays(new Date('30 June 2023'), 0),
      // },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.Medium,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-A1', 'tag-D3'],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0021',
    programId: 'program-2',
    name: 'Project Bravo',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 3,
    tier: ProjectPriority.Tier1,
    isActive: true,
    isNew: false,
    measures: [
      {
        id: 'deliverable-211',
        programMeasureId: 'benefit-21',
        name: 'ERP vendor selection completed',
        targetValue: 'Contract signed',
        achieveByDate: addDays(new Date(), 20),
      },
      {
        id: 'deliverable-212',
        programMeasureId: 'benefit-21',
        name: 'New ERP system implemented',
        targetValue: 'Accepted as SOP',
        achieveByDate: addDays(new Date(), 200),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.High,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-A3', 'tag-D2'],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0022',
    programId: 'program-2',
    name: 'Project Banana',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 1,
    tier: ProjectPriority.Tier2,
    isActive: true,
    isNew: false,
    measures: [
      {
        id: 'deliverable-221',
        programMeasureId: 'benefit-22',
        name: 'Standardise prioritisation framework',
        targetValue: 'Accepted by stakeholders',
        achieveByDate: addDays(new Date(), 30),
      },
      {
        id: 'deliverable-222',
        programMeasureId: 'benefit-22',
        name: 'Reduce and deploy resources',
        targetValue: 'Realise $500,000 in savings',
        achieveByDate: addDays(new Date(), 70),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.High,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: [],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0023',
    programId: 'program-2',
    name: 'Project Beta',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 2,
    tier: ProjectPriority.Tier1,
    isActive: true,
    isNew: true,
    measures: [
      {
        id: 'deliverable-231',
        programMeasureId: 'benefit-23',
        name: 'Strategic partnerships signed',
        targetValue: 'Completed',
        achieveByDate: addDays(new Date(), 20),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.Low,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-A3', 'tag-B1'],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0031',
    programId: 'program-3',
    name: 'Project Charlie',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 3,
    tier: ProjectPriority.Tier1,
    isActive: true,
    isNew: false,
    measures: [
      {
        id: 'deliverable-311',
        programMeasureId: 'benefit-31',
        name: 'LED light replacement',
        targetValue: 'Completed for all company buildings',
        achieveByDate: addDays(new Date(), 14),
      },
      {
        id: 'deliverable-312',
        programMeasureId: 'benefit-31',
        name: 'Smart data center temperature controls',
        targetValue: 'Operationalised for data centre #1',
        achieveByDate: addDays(new Date(), 100),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.High,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-B5'],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0032',
    programId: 'program-3',
    name: 'Project Capsicum',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 2,
    tier: ProjectPriority.Tier3,
    isActive: true,
    isNew: false,
    measures: [
      {
        id: 'deliverable-321',
        programMeasureId: 'benefit-32',
        name: 'Rooftop solar installation',
        targetValue: 'Installed on 5 key sites',
        achieveByDate: addDays(new Date(), 50),
      },
      {
        id: 'deliverable-322',
        programMeasureId: 'benefit-32',
        name: 'On-site big battery installation',
        targetValue: 'Installed on 3 key sites',
        achieveByDate: addDays(new Date(), 80),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.High,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-B7'],
    remark: `If we don't do this project, bad things will happen`,
  },
  {
    projectId: 'project-0033',
    programId: 'program-3',
    name: 'Project Candy',
    outcome: `A short and succinct description of what this project aims to achieve`,
    rankInProgram: 1,
    tier: ProjectPriority.Tier1,
    isActive: false,
    isNew: false,
    measures: [
      {
        id: 'deliverable-331',
        programMeasureId: 'benefit-31',
        name: 'Buy carbon credits',
        targetValue: 'Equivalent of 3 tons of CO2 per year',
        achieveByDate: addDays(new Date(), 150),
      },
    ],
    members: dummyUsers,
    startDate: addDays(new Date(), -50),
    originalEndDate: addDays(new Date(), 150),
    originalBudget: 1000000,
    updates: [
      {
        confidence: ProjectUpdateConfidence.Medium,
        latestTimeEstimate: addDays(new Date(), 200),
        latestCostEstimate: 1200000,
      },
    ],
    tags: ['tag-D1'],
    remark: `If we don't do this project, bad things will happen`,
  },
];

const dummyOwnerName = 'Rose Wilson';

const dummyReferencePortfolio = {
  portfolioId: 'portfolio-1',
  name: 'Company Strategy 2025',
  programs: [
    {
      programId: 'program-1',
      name: 'Program A',
      outcome: 'Create benefits related to business area A',
      projects: dummyProjectList.filter(
        project => project.programId === 'program-1'
      ),
      measures: [
        {
          id: 'benefit-11',
          name: 'Improve customer advocacy',
          targetValue: '+30 NPS',
          achieveByDate: addDays(new Date(), 30),
        },
        {
          id: 'benefit-12',
          name: 'Convert and retain more early access users',
          targetValue: '20,000 MAU',
          achieveByDate: addDays(new Date(), 60),
        },
      ],
      envelope: 20000000,
    },
    {
      programId: 'program-2',
      name: 'Program B',
      outcome: 'Create benefits related to business area B',
      projects: dummyProjectList.filter(
        project => project.programId === 'program-2'
      ),
      measures: [
        {
          id: 'benefit-21',
          name: 'Increase ROI',
          targetValue: 'Maintain 12% return on overall technology portfolio',
          achieveByDate: addDays(new Date(), 90),
        },
        {
          id: 'benefit-22',
          name: 'Reduce R&D expenditure',
          targetValue: '$2M in R&D savings',
          achieveByDate: addDays(new Date(), 70),
        },
        {
          id: 'benefit-23',
          name: 'Engage with industry via external partnerships',
          targetValue: '10 MOUs signed with strategic partners',
          achieveByDate: addDays(new Date(), 20),
        },
      ],
      envelope: 10000000,
    },
    {
      programId: 'program-3',
      name: 'Program C',
      outcome: 'Create benefits related to business area C',
      projects: dummyProjectList.filter(
        project => project.programId === 'program-3'
      ),
      measures: [
        {
          id: 'benefit-31',
          name: 'Reduce total carbon emissions',
          targetValue: '50% reduction vs June 2022',
          achieveByDate: addDays(new Date('30 June 2023'), 0),
        },
        {
          id: 'benefit-32',
          name: 'Switch to renewable power sources',
          targetValue: '60% of total energy usage',
          achieveByDate: addDays(new Date('30 June 2023'), 0),
        },
      ],
      envelope: 8000000,
    },
    {
      programId: 'program-4',
      name: 'Program D',
      outcome: 'Create benefits related to business area D',
      projects: dummyProjectList.filter(
        project => project.programId === 'program-4'
      ),
      measures: [],
      envelope: 0,
    },
  ],
};

export const dummyScenarios: Array<Scenario> = [
  {
    id: 'scenario-001',
    name: 'FY2023 Plan - Alternative',
    referencePortfolio: dummyReferencePortfolio,
    sandboxPortfolio: null,
    ownerName: dummyOwnerName,
    createdDate: addDays(new Date(), -10),
    modifiedDate: addDays(new Date(), -1),
  },
  {
    id: 'scenario-002',
    name: 'FY2023 Plan - Reduced Investment Case',
    referencePortfolio: dummyReferencePortfolio,
    sandboxPortfolio: null,
    ownerName: dummyOwnerName,
    createdDate: addDays(new Date(), -8),
    modifiedDate: addDays(new Date(), -5),
  },
];
