import React, { useContext } from 'react';
import { Routes, BrowserRouter, Route } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';

import { AuthProvider } from './context/UserContext';
import { betaRoutes, privateRoutes, publicRoutes } from './common/routes';
import PrivateRoute from './common/PrivateRoute';

import en from 'javascript-time-ago/locale/en.json';
import { AppSyncProvider } from './context/AppSyncProvider';
import { WorkspacePageContextProvider } from './components/workspace/WorkspacePageContext';
import { PortfolioPageContextProvider } from './components/portfolio/PortfolioPageContext';
import { ProgramPageContextProvider } from './components/program/ProgramPageContext';
import { ProjectPageContextProvider } from './components/project/ProjectPageContext';
import { DemoProvider } from './context/DemoContext';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { ReportContextProvider } from './components/report/ReportContext';
import { BetaContext, BetaProvider } from './context/BetaContext';
import { SegmentWrapper } from './common/SegmentWrapper';
import PublicRoute from './common/PublicRoute';

TimeAgo.addDefaultLocale(en);

function App(): React.ReactElement {
  const { isBeta } = useContext(BetaContext);
  if (process.env.REACT_APP_SEGMENT_KEY) {
    window.analytics.load(process.env.REACT_APP_SEGMENT_KEY);
  }
  return (
    <>
      <BetaProvider>
        <AuthProvider>
          <AppSyncProvider>
            <DemoProvider>
              <WorkspacePageContextProvider>
                <PortfolioPageContextProvider>
                  <ProgramPageContextProvider>
                    <ProjectPageContextProvider>
                      <ReportContextProvider>
                        <ToastContainer />
                        <BrowserRouter>
                          <SegmentWrapper>
                            <Routes>
                              {publicRoutes.map(route => (
                                <Route
                                  key={route.path}
                                  path={route.path}
                                  element={
                                    <PublicRoute component={route.component} />
                                  }
                                />
                              ))}
                              {isBeta
                                ? betaRoutes.map((route, index) => (
                                    <Route
                                      key={index}
                                      path={route.path}
                                      element={
                                        <PrivateRoute
                                          path={route.path}
                                          component={route.component}
                                        />
                                      }
                                    />
                                  ))
                                : privateRoutes.map((route, index) => (
                                    <Route
                                      key={index}
                                      path={route.path}
                                      element={
                                        <PrivateRoute
                                          path={route.path}
                                          component={route.component}
                                        />
                                      }
                                    />
                                  ))}
                            </Routes>
                          </SegmentWrapper>
                        </BrowserRouter>
                      </ReportContextProvider>
                    </ProjectPageContextProvider>
                  </ProgramPageContextProvider>
                </PortfolioPageContextProvider>
              </WorkspacePageContextProvider>
            </DemoProvider>
          </AppSyncProvider>
        </AuthProvider>
      </BetaProvider>
    </>
  );
}

export default App;
