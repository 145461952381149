import * as React from 'react';

import { useEffect, useReducer } from 'react';

import {
  Portfolio,
  PortfolioUpdate,
} from '../../api/index';

export type PortfolioPageState = {
  selectedPortfolio: Portfolio | null;
  portfolios: Array<Portfolio> | null;
  portfolioUpdates: Array<PortfolioUpdate> | null;
  loadPortfolio: boolean;
  loadProgram: boolean;
  loadProject: boolean;
  loadNavTree: boolean;
  loadUpdate: boolean;
  invitations: Array<any> | null;
};

const initialState: PortfolioPageState =
  localStorage.getItem('portfolio') !== null
    ? JSON.parse(localStorage.getItem('portfolio') as string)
    : {
        portfolios: null,
        selectedPortfolio: null,
        loadPortfolio: false,
        loadProgram: false,
        loadProject: false,
        loadUpdate: false,
        loadNavTree: false,
        invitations: null,
        portfolioUpdates: null,
      };

type SetSelectedPortfolioAction = {
  type: 'SET_SELECTED_PORTFOLIO';
  portfolio: Portfolio;
};

type SetPortfoliosAction = {
  type: 'SET_PORTFOLIOS';
  portfolios: Array<Portfolio>;
};

type LoadPortfolioAction = {
  type: 'LOAD_PORTFOLIO';
  loadPortfolio: boolean;
};

type LoadProgramAction = {
  type: 'LOAD_PROGRAM';
  loadProgram: boolean;
};

type LoadUpdateAction = {
  type: 'LOAD_UPDATE';
  loadUpdate: boolean;
};

type LoadProjectAction = {
  type: 'LOAD_PROJECT';
  loadProject: boolean;
};

type LoadNavTreeAction = {
  type: 'LOAD_NAV_TREE';
  loadNavTree: boolean;
};

type SetInvitationsAction = {
  type: 'SET_INVITATIONS';
  invitations: Array<any> | null;
};

type SetUpdatesAction = {
  type: 'SET_UPDATES';
  portfolioUpdates: Array<PortfolioUpdate> | null;
};

export type PortfolioPageAction =
  | SetSelectedPortfolioAction
  | LoadPortfolioAction
  | LoadProgramAction
  | LoadProjectAction
  | SetPortfoliosAction
  | SetInvitationsAction
  | LoadNavTreeAction
  | SetUpdatesAction
  | LoadUpdateAction;

type PortfolioPageContextState = {
  dispatch: React.Dispatch<PortfolioPageAction>;
  state: PortfolioPageState;
};

export const PortfolioPageContext =
  React.createContext<PortfolioPageContextState>({
    dispatch: () => null,
    state: initialState,
  });

const reducer = (state: PortfolioPageState, action: PortfolioPageAction) => {
  switch (action.type) {
    case 'SET_SELECTED_PORTFOLIO':
      return {
        ...state,
        selectedPortfolio: action.portfolio,
      };
    case 'SET_PORTFOLIOS':
      return {
        ...state,
        portfolios: action.portfolios,
      };
    case 'SET_UPDATES':
      return {
        ...state,
        portfolioUpdates: action.portfolioUpdates,
      };
    case 'LOAD_NAV_TREE':
      return {
        ...state,
        loadNavTree: action.loadNavTree,
      };
    case 'LOAD_PORTFOLIO':
      return {
        ...state,
        loadPortfolio: !state.loadPortfolio,
      };
    case 'LOAD_PROGRAM':
      return {
        ...state,
        loadProgram: !state.loadProgram,
      };
    case 'LOAD_UPDATE':
      return {
        ...state,
        loadUpdate: !state.loadUpdate,
      };
    case 'SET_INVITATIONS':
      return {
        ...state,
        invitations: action.invitations,
      };
    case 'LOAD_PROJECT':
      return {
        ...state,
        loadProject: !state.loadProject,
      };
    default:
      return state;
  }
};

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

export function PortfolioPageContextProvider({
  children,
}: Props): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    localStorage.setItem('portfolio', JSON.stringify(state));
  }, [state]);
  return (
    <PortfolioPageContext.Provider value={{ dispatch, state }}>
      {children}
    </PortfolioPageContext.Provider>
  );
}
