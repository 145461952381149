import React, { useContext } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import { Program, Project } from '../../../api/index';
import { ProgramPageContext } from '../../program/ProgramPageContext';
import { PortfolioPageContext } from '../../portfolio/PortfolioPageContext';
import { ProjectPageContext } from '../../project/ProjectPageContext';
import SecondaryButton from '../../../common/SecondaryButton';
import { Link } from 'react-router-dom';
import { PORTFOLIO, PROGRAM } from '../../../common/routes';
import EntityChildList from './EntityChildList';
import OpenWizardButton from '../../../common/wizard/WizardButton';
import translate, { strings } from '../../../common/i18n/translate';

type Props = {
  entity: Project | Program;
  children: Project[];
  hasEditPermissions: boolean;
};

export default function EntityRelationshipsPanel({
  entity,
  children,
  hasEditPermissions,
}: Props): React.ReactElement {
  // Note: For now we are duplicating the data getting calls because of the old hierarchy
  // In the Entity model, this will just be simplified.

  // Get entity context
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const { state: programPageState } = useContext(ProgramPageContext);
  const { state: projectPageState } = useContext(ProjectPageContext);

  // Determine the entity type
  const isProject = entity.__typename === `Project`;

  const parentEntity = isProject
    ? entity.program
    : portfolioPageState.selectedPortfolio;

  const parentLink = isProject
    ? `${PROGRAM}/${parentEntity?.id}#about`
    : `${PORTFOLIO}/${parentEntity?.id}`;

  const relatedTitle = (
    <DetailsPanelHeader
      title={`Relationships`}
      description={`Direct parent and children of this entity`}
    />
  );

  const projectsButton = (
    <div className="flex gap-x-3">
      {/* Toggle performance view */}
      {/* <ToggleButton
        label="Show performance"
        stateOn={showPerformance}
        setStateOn={setShowPerformance}
      /> */}

      {/* Remap parent */}
      {/* <span className="sm:ml-3">
        {entity && (
          <SecondaryButton
            icon="hierarchy"
            label="Remap"
            onClick={() => null}
            disabled={false}
          />
        )}
      </span> */}

      {/* We disable for projects here as part of v1.5
      In v2 we will need some rules around how child entities are added */}
      {/* {programPageState?.selectedProgram && hasEditPermissions && (
        <OpenWizardButton
          title={translate(strings.WIZARD_NEW_PROJECT, 'New Project')}
          editObject={null}
          parentObject={entity}
          type="PROJECT_DETAILS"
          quickSave={true}
          startStep={1}
          disabled={isProject}
        />
      )} */}
    </div>
  );

  const relatedButton = (
    <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
      <span className="sm:ml-3">
        {entity && (
          <SecondaryButton
            icon="hierarchy"
            label="Remap"
            onClick={() => null}
            disabled={false}
          />
        )}
      </span>

      {/* For now, hide entire overflow menu since there is only a delete item. Later this will be per item in this menu */}
      {/* {entity && hasOwnerPermissions && OverflowMenuProject(entity)} */}
    </div>
  );

  // Set up content to show
  type relatedContent = {
    label: string;
    content: string | undefined;
    linkTo: string;
  };

  const CHILDREN_KEY = 'list_of_children';

  const relatedContentText: relatedContent[] = [
    {
      label: `Parent`,
      content: parentEntity?.name,
      linkTo: parentLink,
    },
    {
      label: `Children`,
      content: CHILDREN_KEY,
      linkTo: '',
    },
  ];

  // Content layout
  const relatedContent = (
    <div className="text-sm space-y-2">
      {/* Parent */}
      <div>
        <p className="flex justify-center text-xs text-gray-400 mb-0.5">
          Parent
        </p>
        <div className="flex justify-center">
          <Link
            to={parentLink}
            className="font-medium hover:text-primary-600 hover:underline"
          >
            <p className="">{parentEntity?.name}</p>
          </Link>
        </div>
      </div>

      {/* This entity */}
      <div className="flex justify-center mx-auto h-7 w-px bg-gray-300"></div>
      <div className="flex justify-center bg-primary-50 border border-primary-400 rounded-lg min-w-[24rem] w-min px-4 py-2 mx-auto">
        <div className="text-center">
          <p className="flex justify-center text-xs text-primary-600 mb-0.5">
            {`This${'\u00A0'}entity`}
          </p>
          <p className="flex justify-center text-base">{entity?.name}</p>
        </div>
      </div>
      <div className="flex justify-center mx-auto h-7 w-px bg-gray-300"></div>

      {/* Children */}
      <div>
        <p
          className={`flex justify-center text-xs text-gray-400 ${
            children.length > 0 ? 'mb-2' : 'mb-0.5'
          }`}
        >
          Children
        </p>
        {children.length > 0 ? (
          <EntityChildList children={children} showDescription={true} />
        ) : (
          <p className="flex justify-center italic text-gray-500">None</p>
        )}
      </div>
    </div>
  );

  return (
    <DetailsPanel
      headerLeft={relatedTitle}
      headerRight={projectsButton} // relatedButton
      content={relatedContent}
    />
  );
}
