import * as React from 'react';

import { createContext, useState } from 'react';

export type BetaContext = {
  isBeta: boolean;
  toggleIsBeta?: () => void;
};

const defaultState = {
  isBeta: localStorage.getItem('isBeta') == 'beta' ? true : false,
};

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

export const BetaContext = createContext<BetaContext>(defaultState);

export function BetaProvider({ children }: Props): React.ReactElement {
  const [isBeta, setIsBeta] = useState(defaultState.isBeta);

  const toggleIsBeta = () => {
    setIsBeta(!isBeta);
    localStorage.setItem('isBeta', !isBeta === true ? 'beta' : '');
  };

  return (
    <BetaContext.Provider
      value={{
        isBeta,
        toggleIsBeta,
      }}
    >
      {children}
    </BetaContext.Provider>
  );
}
