import React from "react";
import { Menu } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { classNames } from "../../utils/utils";

export type MenuOption = {
  id: string;
  action: () => void;
  text: string;
  icon: React.ReactElement;
};

export type DotMenuProps = {
  menuOptions: Array<MenuOption>;
};

const DotMenu = ({ menuOptions }: DotMenuProps) => {
  return (
    <Menu>
      <Menu.Button className="inline-flex p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
        <EllipsisVerticalIcon className="h-5 w-5 z-0" />
      </Menu.Button>
      <Menu.Items className="z-10 overflow-hidden w-fit origin-top-right absolute right-0 mt-20 rounded-md border border-gray-200 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        {/* Use the `active` render prop to conditionally style the active item. */}
        {menuOptions.map((item: MenuOption) => (
          <Menu.Item key={item.id}>
            {({ active }) => (
              <button
                className={classNames(
                  active
                    ? "bg-gray-100 text-gray-900"
                    : "bg-white text-gray-700",
                  "flex w-full items-center px-4 py-2 text-sm text-gray-700"
                )}
                onClick={item.action}
              >
                <span className="inline-block mr-3">{item.icon}</span>
                <span className="inline-block whitespace-nowrap">
                  {item.text}
                </span>
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default DotMenu;
