import React from 'react';
import { SlideOverSectionHeader } from '../../common/slideovers/SlideOverSectionHeader';

type PerformanceSlideOverOutcomesTabProps = {
  startDate: string;
  endDate: string;
};

export default function RelationshipSlideOverDatesTab({
  startDate,
  endDate,
}: PerformanceSlideOverOutcomesTabProps): React.ReactElement {
  return (
    <div className="space-y-6 text-sm">
      {/* Show the start date */}
      <div>
        <SlideOverSectionHeader label={`Start date`} />

        {startDate ? (
          <div>{startDate}</div>
        ) : (
          <div className="text-gray-400 italic">{`Not provided`}</div>
        )}
      </div>

      {/* Show the end date */}
      <div>
        <SlideOverSectionHeader label={`End date`} />

        {endDate ? (
          <div>{endDate}</div>
        ) : (
          <div className="text-gray-400 italic">{`Not provided`}</div>
        )}
      </div>
    </div>
  );
}
