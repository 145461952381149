import { Target } from 'phosphor-react';
import * as React from 'react';
import { ProgramMeasure, ProgramUpdate } from '../../../api/index';
import {
  getLocalDate,
  getProjectMeasureStatusColour,
} from '../../../common/utils';

const iconTarget = (
  <Target
    className="inline-block text-gray-500 mb-1"
    // color="#7D8390"
    weight="duotone"
    size={20}
  />
);

type Props = {
  benefit: ProgramMeasure;
  selectedUpdate: ProgramUpdate | null | undefined;
};

function ProgramMeasureCard({
  benefit,
  selectedUpdate,
}: Props): React.ReactElement {
  const layout =
    benefit === null ? (
      <div className="ml-3 text-sm text-gray-500 italic">
        Not a focus for this program
      </div>
    ) : (
      <div className="flex flex-col">
        {/* Title */}
        <div className="flex items-center pl-0 pr-3 mb-0.5">
          <div className="flex text-sm font-medium text-gray-900">
            {/* Status dot */}
            <div className="w-4 shrink-0 flex justify-center mr-2">
              <span
                className={`flex-shrink-0 inline-block h-3 w-3 rounded-full mx-auto mt-1 ${getProjectMeasureStatusColour(
                  selectedUpdate?.measures?.find(
                    m => m.measureId === benefit.id
                  )?.status
                )}`}
                aria-hidden="true"
              />
            </div>

            <div className="mt-0 font-medium">{benefit.name}</div>
          </div>
        </div>

        {/* Details */}
        <div className="pl-4 pr-3">
          <div className="space-y-2 mt-1">
            {/* Target block */}
            <div className="flex text-gray-500 font-normal">
              <span className="w-9 shrink-0 flex justify-center mr-0">
                {iconTarget}
              </span>
              {benefit.targetValue ? (
                <p>{`${benefit.targetValue}${
                  benefit.achieveByDate
                    ? ', by ' + getLocalDate(benefit.achieveByDate)
                    : ' (no target date)'
                }`}</p>
              ) : (
                <p className="italic">(No target)</p>
              )}
            </div>

            {/* Current status block */}
            {/* TODO: Replace with status summary field later */}
            {/* <div className="flex text-gray-500 font-normal">
              <span className="w-9 shrink-0 flex justify-center mr-1">
                {iconCurrentValue}
              </span>
              <div>
                {benefit.id &&
                selectedUpdate?.measures?.find(m => m.measureId === benefit.id)
                  ?.value &&
                (selectedUpdate?.measures?.find(m => m.measureId === benefit.id)
                  ?.value?.length as number) > 0
                  ? selectedUpdate?.measures?.find(
                      m => m.measureId === benefit.id
                    )?.value
                  : '(No update)'}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );

  return layout;
}

export default ProgramMeasureCard;
