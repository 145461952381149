import * as React from 'react';
import { Link } from 'react-router-dom';
import { Portfolio, Program, Project } from '../api/index';
import { DemoContext } from '../context/DemoContext';
import { UserContext } from '../context/UserContext';
import { getPermissionFromTree } from './utils';

type Props = {
  entity: Portfolio | Program | Project;
  parentPortfolio?: Portfolio; // Only needed for projects due to incomplete project.program.portfolio object (no members)
  to: string;
  allowedLayout: React.ReactElement;
  blockedLayout: React.ReactElement;
};

export default function PermissionLink({
  entity,
  parentPortfolio,
  to,
  allowedLayout,
  blockedLayout,
}: Props): React.ReactElement {
  const { profile: userProfile } = React.useContext(UserContext);
  const { isDemo } = React.useContext(DemoContext);

  const userHasAccess =
    userProfile &&
    (entity.__typename === 'Project'
      ? getPermissionFromTree(userProfile, entity, parentPortfolio)
          .entityPermission
      : getPermissionFromTree(userProfile, entity).entityPermission);

  return (
    <React.Fragment>
      {userHasAccess || isDemo ? (
        <Link to={to}>{allowedLayout}</Link>
      ) : (
        <React.Fragment>{blockedLayout}</React.Fragment>
      )}
    </React.Fragment>
  );
}
