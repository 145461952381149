/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import {
  EntityType,
  useCreateEntityMutation,
  CreateEntityMutation,
} from '../../../api/index';
import { ApolloError, FetchResult } from '@apollo/client';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
// import { ENTITY } from '../../routes';
import { enumTranslates } from '../../i18n/translate';

type editWizardDataType = {
  name: string;
  members: any;
  oldMembers: any;
  invitations: any;
  parentId: any;
  workspaceId: string;
};

// const useEdit = (): {
//   onSubmit: (wizardData: editWizardDataType) => Promise<void>;
//   response: {
//     response:
//       | FetchResult<
//           UpdateProjectMutation,
//           Record<string, unknown>,
//           Record<string, unknown>
//         >
//       | undefined;
//     loading: boolean;
//   };
// } => {
//   const [updateEntityMutation, { loading }] = useUpdateEntityMutation();
//   const [invite] = useInviteEntityMemberMutation();

//   const [removeMember] = useRemoveMemberMutation();
//   const [removeInvitation] = useRemoveInvitationMutation();
//   const [editMember] = useUpdateEntityMemberMutation();
//   const { dispatch: entityDispatch, state: entityPageState } =
//     useContext(EntityPageContext);
//   const { dispatch: entityDispatch } = useContext(EntityPageContext);
//   const [response, setResponse] =
//     useState<
//       FetchResult<
//         UpdateProjectMutation,
//         Record<string, unknown>,
//         Record<string, unknown>
//       >
//     >();
//   const onSubmit = useCallback(
//     async (wizardData: editWizardDataType) => {
//       try {
//         const inputData: any = {
//           id: wizardData.id,
//           name: wizardData.name,
//           primaryStrategicThemeId: wizardData.primaryStrategicThemeId,
//           startDate: wizardData.startDate,
//           changeType: wizardData.changeType,
//           envelope: wizardData.envelope,
//           measures: wizardData.measures.map((item: EntityMeasure) => {
//             return {
//               id: item.id,
//               name: item.name,
//               strategicObjectiveId: item.strategicObjectiveId,
//               targetValue: item.targetValue,
//               achieveByDate: item.achieveByDate,
//             };
//           }),
//           outcome: wizardData.outcome,
//           alignment: wizardData.alignment,
//           updateCadence: wizardData.updateCadence,
//           updateOwnerId: wizardData.updateOwnerId,
//         };

//         if (wizardData.nextUpdateDate && wizardData.nextUpdateDate.length > 0) {
//           inputData.nextUpdateDate = wizardData.nextUpdateDate?.split('T')[0];
//         }

//         const updateEntityResp = await updateEntityMutation({
//           variables: {
//             input: inputData,
//           },
//         });
//         const entityMembers = wizardData.members;
//         const entityId = wizardData?.id;
//         const previousMembers = wizardData.oldMembers;
//         if (entityId) {
//           for (const member of entityMembers) {
//             if (member.email && member.group && member.role) {
//               if (
//                 !previousMembers?.some(
//                   (m: { email: string }) => m.email === member.email
//                 ) ||
//                 member.resendInvite === 'yes'
//               ) {
//                 await invite({
//                   variables: {
//                     input: {
//                       inviteeEmail: member.email,
//                       entityId: entityId,
//                       entityType: EntityType.Entity,
//                       group: member.group,
//                     },
//                     role: member.role,
//                   },
//                 });
//               } else if (
//                 previousMembers?.some(
//                   (m: MemberExt) =>
//                     m.email === member.email &&
//                     (m.role !== member.role || m.group !== member.group)
//                 )
//               ) {
//                 await editMember({
//                   variables: {
//                     input: {
//                       userId: member.id,
//                       entityId: entityId,
//                       entityType: EntityType.Entity,
//                       group: member.group,
//                     },
//                     role: member.role,
//                   },
//                 });
//               }
//             }
//           }
//           for (const member of previousMembers) {
//             if (
//               member.email &&
//               !entityMembers.some(
//                 (m: { email: string }) => m.email === member.email
//               )
//             ) {
//               if (member.isInvitation) {
//                 await removeInvitation({
//                   variables: {
//                     entityId: wizardData.id,
//                     inviteeEmail: member.email,
//                   },
//                 });
//               } else {
//                 await removeMember({
//                   variables: {
//                     input: {
//                       entityId: wizardData.id,
//                       entityType: EntityType.Entity,
//                       userId: member.id,
//                     },
//                   },
//                 });
//               }
//             }
//           }
//         }

//         setResponse(updateEntityResp);
//         if (updateEntityResp.data?.updateEntity) {
//           entityDispatch({
//             type: 'LOAD_UPDATE',
//             loadUpdate: !entityPageState.loadUpdate,
//           });
//           entityDispatch({
//             type: 'SET_SELECTED_ENTITY',
//             entity: updateEntityResp.data?.updateEntity,
//           });
//           entityDispatch({
//             type: 'LOAD_ENTITY',
//             loadEntity: true,
//           });
//           toast.info(`Updated ${enumTranslates[EntityType.Entity]}`);
//         }
//       } catch (err) {
//         const error = err as ApolloError;
//         toast.error(error.message);
//       }
//     },
//     [
//       editMember,
//       invite,
//       entityDispatch,
//       entityDispatch,
//       entityPageState.loadUpdate,
//       removeInvitation,
//       removeMember,
//       updateEntityMutation,
//     ]
//   );

//   return { onSubmit, response: { response, loading } };
// };

type createWizardDataType = {
  name: string;
  members: any;
  oldMembers: any;
  invitations: any;
  objectives: any;
  parentId: any;
  workspaceId: string;
};

const useCreate = (
  quickAdd: boolean
): {
  onSubmit: (wizardData: createWizardDataType) => Promise<void>;
  response: {
    response:
      | FetchResult<
          CreateEntityMutation,
          Record<string, unknown>,
          Record<string, unknown>
        >
      | undefined;
    loading: boolean;
  };
} => {
  const [createEntityMutation, { loading }] = useCreateEntityMutation();
  // const [invite] = useInviteEntityMemberMutation();

  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const [response, setResponse] =
    useState<
      FetchResult<
        CreateEntityMutation,
        Record<string, unknown>,
        Record<string, unknown>
      >
    >();
  const onSubmit = useCallback(
    async (wizardData: createWizardDataType) => {
      const entityMembers = wizardData.members;
      delete wizardData.members;
      delete wizardData.objectives;
      delete wizardData.oldMembers;
      delete wizardData.invitations;
      try {
        const inputData = {
          ...wizardData,
          parentId: wizardData.parentId,
          workspaceId: wizardData.workspaceId,
        };
        const dataCreateEntity = await createEntityMutation({
          variables: {
            input: inputData,
          },
        });

        const entityId = dataCreateEntity?.data?.createEntity?.id;

        // if (entityId) {
        //   for (const member of entityMembers) {
        //     if (
        //       member.email !== user?.attributes?.email &&
        //       member.email &&
        //       member.group &&
        //       member.role
        //     ) {
        //       try {
        //         await invite({
        //           variables: {
        //             input: {
        //               inviteeEmail: member.email,
        //               entityId: entityId,
        //               entityType: EntityType.Entity,
        //               group: member.group,
        //             },
        //             role: member.role,
        //           },
        //         });
        //       } catch (err) {
        //         const error = err as ApolloError;
        //         toast.error(error.message);
        //       }
        //     }
        //   }
        // }
        setResponse(dataCreateEntity);
        if (dataCreateEntity.data?.createEntity) {
          toast.success(`Created new ${enumTranslates[EntityType.Entity]}`);
          if (quickAdd) {
            // entityDispatch({
            //   type: 'LOAD_ENTITY',
            //   loadEntity: true,
            // });
          } else {
            navigate(`entity/${dataCreateEntity.data.createEntity.id}`);
          }
        }
      } catch (err) {
        const error = err as ApolloError;
        toast.error(error.message);
      }
    },
    [createEntityMutation, navigate, quickAdd, user?.attributes?.email]
  );

  return { onSubmit, response: { response, loading } };
};

export const ENTITY_DETAILS = {
  useCreate: () => useCreate(false),
  // useEdit,
};

export const ENTITY_QUICK_ADD = {
  useCreate: () => useCreate(true),
  // useEdit,
};
