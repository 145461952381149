import React from 'react';
import { MenuOption } from '../../basic/DotMenu';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import OutcomesActionHeader from './OutcomesActionHeader';
import OutcomesContent, { WaypointChecklistItem } from './OutcomesContent';
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

type OutcomesPanelProps = {
  checklist: WaypointChecklistItem[];

  actionsDisabled: boolean;
  onEditClick: () => void;
  isVisible: boolean;
};

const OutcomesTitle = () => (
  <DetailsHeaderTitle
    title={`What outcomes do we expect to hit at this waypoint?`}
    tooltipText={`Achieving these would give us confidence that we are on track`}
  />
);

export default function OutcomesPanel({
  checklist,
  actionsDisabled,
  onEditClick,
  isVisible,
}: OutcomesPanelProps): React.ReactElement {
  // Set up content to show

  return (
    <DetailsHeader
      headerLeft={<OutcomesTitle />}
      headerRight={
        <OutcomesActionHeader
          hasOutcomes={checklist?.length > 0}
          onClick={onEditClick}
          isVisible={isVisible}
          disabled={actionsDisabled}
        />
      }
      content={<OutcomesContent checklist={checklist} />}
    />
  );
}
