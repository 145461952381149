import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import {
  ComponentInfo,
  GenericContainer,
} from '../../JLcomponents/basic/GenericContainer';
import AboutPanel from './AboutPanel/AboutPanel';
import MemberPanel from './MembersPanel/MembersPanel';
import ObjectivesPanel from './ObjectivesPanel/ObjectivesPanel';

const exampleObjectives = [
  {
    description: 'Acquire 1000 new customers',
    target: '1000 MAU',
    targetDate: '28 Feb 2023',
  },
  {
    description: 'Receive $10,000 in new revenue',
    target: '$10,000',
    targetDate: '31 Mar 2023',
  },
  {
    description: 'Maintain customer satisfaction',
    target: '+30 NPS',
    targetDate: '30 Apr 2023',
  },
];

const exampleMembers = [
  {
    firstName: 'Ada',
    surname: 'Bravo',
    email: 'adam@journeylab.io',
    businessRole: 'Outcome owner',
    permissionLevel: 'Owner',
  },
  {
    firstName: 'Chris',
    surname: 'Daniels',
    email: 'chris@journeylab.io',
    businessRole: 'Oversight',
    permissionLevel: 'Editor',
  },
  {
    firstName: 'Elle',
    surname: 'Frankston',
    email: 'elle@journeylab.io',
    businessRole: 'Day-to-day management',
    permissionLevel: 'Editor',
  },
  {
    firstName: 'Gary',
    surname: 'Hart',
    email: 'gary@journeylab.io',
    businessRole: 'Contributor',
    permissionLevel: 'Viewer',
  },
];

const containerPanels: ComponentInfo<any>[] = [
  {
    id: 'PANEL_001',
    Component: AboutPanel,
    props: {
      description: `Our new SEO platform has been re-engineered to help marketers optimise their website in the age of AI-enabled search engines.

        Sometimes, this will include a new line.
          
        - We should also consider dot points
        - [Future] We may also include markdown`,
      primaryStrategicObjectiveName: 'Increase customer count',
      lifecycleStage: 'In-flight',
      startDate: '1 Jan 2022 (2 months ago)',
      endDate: '31 Dec 2025 (in 3 years)',
    },
  },
  {
    id: 'PANEL_002',
    Component: ObjectivesPanel,
    props: {
      objectives: exampleObjectives,
      onEditClick: () => {
        console.log('Clicked button: Edit objectives');
      },
      actionsDisabled: false,
      isVisible: true,
    },
  },
  {
    id: 'PANEL_003',
    Component: MemberPanel,
    props: {
      members: exampleMembers,
      onEditClick: () => {
        console.log('Clicked button: Edit members');
      },
      actionsDisabled: false,
      isVisible: true,
    },
  },
];

function OverviewContainer(): React.ReactElement {
  const { user: currentUser, profile: userProfile } = useContext(UserContext);
  //   const { state: portfolioState } = useContext(PortfolioPageContext);
  // call the useGetEntities hook to get the entities
  // useGetEntities returns an array of entities formatted as above
  return <GenericContainer components={containerPanels} />;
}

export default OverviewContainer;
