import React, { useContext } from 'react';
import {
  classNames,
  getLocalDate,
  getPreviousProjectUpdate,
  UnlinkedTag,
} from '../../../common/utils';
import { ProjectPageContext } from '../ProjectPageContext';
import {
  ExceptionStatus,
  LogicModelGroup,
  ProgramMeasure,
  Project,
  ProjectMeasure,
  ProjectMeasureStatus,
  ProjectMeasureUpdate,
  ProjectUpdate,
} from '../../../api/index';

import translate, { strings } from '../../../common/i18n/translate';
import ReactTooltip from 'react-tooltip';
import ProjectMeasureCard from './ProjectMeasureCard';
import { Target } from 'phosphor-react';
import EmptyState from '../../../common/layout/EmptyState';
import { ulid } from 'ulid';

type LogicProps = {
  project: Project | null;
  update: ProjectUpdate | null;
  isLatestUpdate: boolean;
  filterExceptions?: boolean;
  showCompleted?: boolean;
  showPaused?: boolean;
};

export type LogicData = {
  measure: ProjectMeasure;
  latestMeasureUpdate: ProjectMeasureUpdate | null;
  previousMeasureUpdate: ProjectMeasureUpdate | null;
};

function LogicModel({
  project,
  update,
  isLatestUpdate,
  filterExceptions,
  showCompleted,
  showPaused,
}: LogicProps): React.ReactElement {
  const { state: projectPageState } = useContext(ProjectPageContext);

  // Get data in a convenient format for each project measure
  const showMeasures: Array<LogicData> = [];
  project?.measures?.forEach(measure => {
    const foundLatestMeasure = update?.measures?.find(
      m => m.measureId === measure.id
    );
    const foundPreviousMeasure = getPreviousProjectUpdate(
      project?.updates?.items && project.updates.items,
      update
    )?.measures?.find(m => m.measureId === measure.id);

    if (
      foundLatestMeasure &&
      filterExceptions &&
      foundLatestMeasure.exceptionStatus &&
      foundLatestMeasure.exceptionStatus !== ExceptionStatus.NoException
    ) {
      showMeasures.push({
        measure: measure,
        latestMeasureUpdate: foundLatestMeasure ? foundLatestMeasure : null,
        previousMeasureUpdate: foundPreviousMeasure
          ? foundPreviousMeasure
          : null,
      });
    } else if (!filterExceptions) {
      showMeasures.push({
        measure: measure,
        latestMeasureUpdate: foundLatestMeasure ? foundLatestMeasure : null,
        previousMeasureUpdate: foundPreviousMeasure
          ? foundPreviousMeasure
          : null,
      });
    }
  });

  const iconTarget = (
    <Target
      className="inline-block mr-1 flex-shrink-0 text-gray-500"
      weight="duotone"
      size={20}
      data-tip
      data-for={'target-icon'}
    />
  );

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {/* Updated table */}
          <div className="overflow-hidden border border-gray-200 rounded-lg">
            {/* Tailwind grid table style */}
            <table className="table min-w-full divide-y divide-gray-200">
              <thead className="table-header-group bg-gray-50">
                {/* Header row */}
                <tr className="table-row">
                  <th className="table-cell pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {`Relevant ${translate(
                      strings.PROGRAM_MEASURES,
                      'Program Measures'
                    )}`}
                  </th>
                  <th className="table-cell w-1/3 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {translate(strings.PROJECT_IMPACTS, 'Project Impacts')}
                  </th>
                  <th className="table-cell w-1/3 pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {translate(strings.PROJECT_OUTPUTS, 'Project Deliverables')}
                  </th>
                </tr>
              </thead>

              {/* Table data */}
              <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
                {(projectPageState.selectedProject?.program?.measures?.filter(
                  measure =>
                    showMeasures.some(
                      showMeasure =>
                        showMeasure.measure.programMeasureId === measure.id
                    )
                ).length as number) > 0 ? (
                  projectPageState.selectedProject?.program?.measures
                    ?.filter(measure =>
                      showMeasures.some(
                        showMeasure =>
                          showMeasure.measure.programMeasureId === measure.id
                      )
                    )
                    .map(
                      (
                        programMeasure: ProgramMeasure,
                        outcomeIndex: number
                      ) => (
                        // Create a row for each program measure
                        <tr
                          className="table-row align-top border-t"
                          key={programMeasure.id}
                        >
                          {/* Program measure */}
                          <td
                            className={classNames(
                              outcomeIndex == 0 ? '' : '',
                              'table-cell pl-4 pr-2 py-3 whitespace-normal align-top border-t border-gray-200'
                            )}
                          >
                            <div className="flex flex-col">
                              <div className="font-medium ">
                                {programMeasure.name}
                              </div>
                              <div className="">
                                <span className="flex align-top mt-1 text-gray-500">
                                  {iconTarget}
                                  <p>
                                    {!programMeasure.targetValue
                                      ? 'No targets set'
                                      : `${programMeasure.targetValue}${
                                          programMeasure.achieveByDate
                                            ? `, by ${getLocalDate(
                                                programMeasure.achieveByDate
                                              )}`
                                            : ' (no target date)'
                                        }`}
                                  </p>
                                </span>
                              </div>
                            </div>
                          </td>

                          {/* Benefits */}
                          <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200">
                            <div className="flex-col space-y-3">
                              {showMeasures
                                ?.filter(
                                  item =>
                                    item.measure.programMeasureId ==
                                      programMeasure.id &&
                                    item.measure?.logicModelGroup ==
                                      LogicModelGroup.Impacts &&
                                    (!showCompleted &&
                                    item.previousMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Done &&
                                    item.latestMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Done
                                      ? false
                                      : true) &&
                                    (!showPaused &&
                                    item.previousMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Paused &&
                                    item.latestMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Paused
                                      ? false
                                      : true)
                                )
                                .map((item, index) => (
                                  <div key={item.measure.id}>
                                    {/* Project impacts */}
                                    <ProjectMeasureCard
                                      measureData={item}
                                      index={index}
                                      validMapping={true}
                                      isLatestUpdate={isLatestUpdate}
                                      filterExceptions={filterExceptions}
                                    />
                                  </div>
                                ))}
                            </div>
                          </td>

                          {/* Deliverables */}
                          <td className="table-cell pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200">
                            <div className="flex-col space-y-3">
                              {showMeasures
                                ?.filter(
                                  item =>
                                    item.measure.programMeasureId ==
                                      programMeasure.id &&
                                    item.measure.logicModelGroup !=
                                      LogicModelGroup.Impacts &&
                                    (!showCompleted &&
                                    item.previousMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Done &&
                                    item.latestMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Done
                                      ? false
                                      : true) &&
                                    (!showPaused &&
                                    item.previousMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Paused &&
                                    item.latestMeasureUpdate?.status ===
                                      ProjectMeasureStatus.Paused
                                      ? false
                                      : true)
                                )
                                .map((item, index) => (
                                  <div key={item.measure.id}>
                                    {/* Project deliverables */}
                                    <ProjectMeasureCard
                                      measureData={item}
                                      index={index}
                                      validMapping={true}
                                      isLatestUpdate={isLatestUpdate}
                                      filterExceptions={filterExceptions}
                                    />
                                  </div>
                                ))}
                            </div>
                          </td>
                        </tr>
                      )
                    )
                ) : (
                  <tr className="table-row align-top border-t" key={ulid()}>
                    <td className="table-cell w-16 px-4 py-6 border-t border-gray-200">
                      <EmptyState type="benefits" secondaryText={''} />{' '}
                    </td>
                  </tr>
                )}

                {/* Also show unmapped items, if they exist */}
                {(showMeasures?.filter(
                  item =>
                    !projectPageState.selectedProject?.program.measures?.some(
                      m => m.id === item.measure.programMeasureId
                    )
                ).length as number) > 0 ? (
                  <tr className="table-row align-top border-t">
                    {/* No program measure */}
                    <td className="table-cell align-top pl-4 pr-2 py-3 italic text-gray-500 whitespace-normal border-t border-gray-200">
                      <UnlinkedTag tooltipId="icon-not-linked" />
                      <ReactTooltip
                        id={'icon-not-linked'}
                        // place="top"
                        type="warning"
                        effect="solid"
                        // delayShow={tooltipDelay}
                      >
                        <div className="text-sm text-center not-italic">
                          <p>
                            {`Not linked to a ${translate(
                              strings.PROGRAM_MEASURE,
                              'Program Measure'
                            ).toLowerCase()}, so they won't roll up properly.`}
                          </p>
                        </div>
                      </ReactTooltip>
                    </td>

                    <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200">
                      <div className="flex-col space-y-3">
                        {showMeasures
                          ?.filter(
                            item =>
                              !projectPageState.selectedProject?.program.measures?.some(
                                m => m.id === item.measure.programMeasureId
                              ) &&
                              item.measure?.logicModelGroup ==
                                LogicModelGroup.Impacts &&
                              (!showCompleted &&
                              item.previousMeasureUpdate?.status ===
                                ProjectMeasureStatus.Done &&
                              item.latestMeasureUpdate?.status ===
                                ProjectMeasureStatus.Done
                                ? false
                                : true) &&
                              (!showPaused &&
                              item.previousMeasureUpdate?.status ===
                                ProjectMeasureStatus.Paused &&
                              item.latestMeasureUpdate?.status ===
                                ProjectMeasureStatus.Paused
                                ? false
                                : true)
                          )
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {/* Project impacts */}
                              <ProjectMeasureCard
                                measureData={item}
                                index={index}
                                validMapping={false}
                                isLatestUpdate={isLatestUpdate}
                                filterExceptions={filterExceptions}
                              />
                            </React.Fragment>
                          ))}
                      </div>
                    </td>

                    <td className="table-cell pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200">
                      <div className="flex-col space-y-3">
                        {showMeasures
                          ?.filter(
                            item =>
                              !projectPageState.selectedProject?.program.measures?.some(
                                m => m.id === item.measure.programMeasureId
                              ) &&
                              item.measure.logicModelGroup !=
                                LogicModelGroup.Impacts &&
                              (!showCompleted &&
                              item.previousMeasureUpdate?.status ===
                                ProjectMeasureStatus.Done &&
                              item.latestMeasureUpdate?.status ===
                                ProjectMeasureStatus.Done
                                ? false
                                : true) &&
                              (!showPaused &&
                              item.previousMeasureUpdate?.status ===
                                ProjectMeasureStatus.Paused &&
                              item.latestMeasureUpdate?.status ===
                                ProjectMeasureStatus.Paused
                                ? false
                                : true)
                          )
                          .map((item, index) => (
                            <React.Fragment key={index}>
                              {/* Project deliverables */}
                              <ProjectMeasureCard
                                measureData={item}
                                index={index}
                                validMapping={false}
                                isLatestUpdate={isLatestUpdate}
                                filterExceptions={filterExceptions}
                              />
                            </React.Fragment>
                          ))}
                      </div>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogicModel;
