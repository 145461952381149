/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  EntityType,
  Portfolio,
  PortfolioMeasureStatus,
  Program,
  ProgramMeasureStatus,
  ProgramMember,
  ProgramRole,
  ProgramUpdateTrend,
} from '../../../api/index';
import Tag from '../../../common/Tag';
import translate, {
  enumTranslates,
  reorderEnums,
  strings,
} from '../../../common/i18n/translate';
import { Menu } from '@headlessui/react';
import { Check, X } from 'phosphor-react';
import _ from 'lodash';
import {
  capitaliseFirstLetter,
  getProgramMeasureStatusColour,
} from '../../../common/utils';

type Props = {
  field: FilterBy;
  inclusions: Array<any>;
  setInclusions: React.Dispatch<React.SetStateAction<Array<any>>>;
  portfolio?: Portfolio | null;
  programs?: (Program | undefined)[];
  isDisabled?: boolean;
};

//   Pre-process data into groups
const groupNames = [
  'Strategic Pillar',
  'Strategic Objective',
  'Strategic Objective Status',
  `${capitaliseFirstLetter(enumTranslates[EntityType.Program])}`,
  `${translate(strings.PROGRAM_MEASURE, 'Program Benefit')} Status`,
  `${translate(strings.PROJECT_DELIVERY_CONFIDENCE, 'Confidence')}`,
  'Business Owner',
] as const;
type FilterBy = typeof groupNames[number];

const iconClear = (
  <X className="h-4 w-4 text-gray-500 shrink-0" weight="bold" />
);

function FilterButton({
  field,
  inclusions,
  setInclusions,
  portfolio,
  programs,
  isDisabled,
}: Props) {
  function handleOptionClick(value: string) {
    const included = inclusions?.some(item => item === value);
    const newInclusions = [];

    if (included) {
      // Remove the item
      const index = inclusions?.indexOf(value);
      if (index > -1) {
        inclusions?.splice(index, 1);
      }
      inclusions?.forEach(item => newInclusions.push(item));
    } else {
      // Add the new item to the existing list
      inclusions?.forEach(item => newInclusions.push(item));
      newInclusions.push(value);
    }

    setInclusions(newInclusions);
  }

  // Reset this filter
  function handleClearFilterClick() {
    setInclusions([]);
  }

  type MenuOption = {
    value: string;
    display: string;
    // icon: iconShowCompleted,
    action: React.MouseEventHandler<HTMLButtonElement>;
  };

  function getOptions(): MenuOption[] {
    // Initialise output array
    const result: MenuOption[] = [];

    // Get set options
    const objectiveStatusArray = reorderEnums(
      Object.values(PortfolioMeasureStatus)
    ) as Array<PortfolioMeasureStatus>;

    const benefitStatusArray = reorderEnums(
      Object.values(ProgramMeasureStatus)
    ) as Array<ProgramMeasureStatus | string>;

    benefitStatusArray.push('');

    const confidenceArray = reorderEnums(
      Object.values(ProgramUpdateTrend)
    ) as Array<ProgramUpdateTrend>;

    switch (field) {
      case 'Strategic Pillar':
        portfolio
          ? portfolio.themes?.forEach(theme => {
              const option: MenuOption = {
                value: theme.id,
                display: theme.name,
                action: () => handleOptionClick(theme.id),
              };
              result.push(option);
            })
          : [];
        break;

      case 'Strategic Objective':
        // TODO: Sort things by strategic pillar

        portfolio
          ? portfolio.objectives?.forEach(objective => {
              const option: MenuOption = {
                value: objective.id,
                display: objective.description,
                action: () => handleOptionClick(objective.id),
              };
              result.push(option);
            })
          : [];
        break;

      case 'Strategic Objective Status':
        objectiveStatusArray.forEach(item => {
          const option: MenuOption = {
            value: item,
            display: enumTranslates[item],
            action: () => handleOptionClick(item),
          };
          result.push(option);
        });
        break;

      case `${capitaliseFirstLetter(enumTranslates[EntityType.Program])}`:
        // TODO: Sort things by strategic pillar

        programs && programs.length > 0
          ? programs.forEach(program => {
              if (program) {
                const option: MenuOption = {
                  value: program.id,
                  display: program.name,
                  action: () => handleOptionClick(program.id),
                };
                result.push(option);
              }
            })
          : [];
        break;

      case `${translate(strings.PROGRAM_MEASURE, 'Program Benefit')} Status`:
        benefitStatusArray.forEach(item => {
          const option: MenuOption = {
            value: item.length > 0 ? item : '',
            display:
              item.length > 0 ? enumTranslates[item] : 'New (not rated yet)',
            action: () => handleOptionClick(item),
          };
          result.push(option);
        });
        break;

      case `${translate(strings.PROJECT_DELIVERY_CONFIDENCE, 'Confidence')}`:
        confidenceArray.forEach(item => {
          const option: MenuOption = {
            value: item,
            display: enumTranslates[item],
            action: () => handleOptionClick(item),
          };
          result.push(option);
        });
        break;

      case 'Business Owner':
        programs && programs.length > 0
          ? programs?.forEach(program => {
              // Get list of program owners
              const owners = program?.members?.items?.filter(member => {
                const pm = member as ProgramMember;
                return pm.role === ProgramRole.BusinessOwner;
              });
              // Make each owner an option
              owners?.forEach(owner => {
                const option: MenuOption = {
                  value: owner.user.id,
                  display: `${owner.user.firstName} ${owner.user.lastName} (${owner.user.email})`,
                  action: () => handleOptionClick(owner.user.id),
                };
                result.push(option);
              });
            })
          : [];
        break;

      default:
        break;
    }

    return _.uniqWith(result, function (arrVal, othVal) {
      return arrVal.value == othVal.value;
    });
  }

  function isOptionIncluded(option: MenuOption): boolean {
    let result = false;
    if (inclusions && inclusions.some(item => option.value === item)) {
      result = true;
    }
    return result;
  }

  function getCheckboxIcon(option: MenuOption): React.ReactElement {
    const checked = isOptionIncluded(option);

    const layout = (
      <span
        className={`h-4 w-4 p-0.5 rounded flex justify-center mx-auto items-center ${
          field === 'Strategic Objective Status' ||
          field ===
            `${translate(strings.PROGRAM_MEASURE, 'Program Benefit')} Status`
            ? checked
              ? option.value.length > 0
                ? getProgramMeasureStatusColour(option.value)
                : 'bg-primary-500'
              : 'bg-white border-2 border-gray-400'
            : checked
            ? 'bg-primary-600'
            : 'bg-white border-2 border-gray-400'
        }`}
      >
        {checked ? (
          <Check weight="bold" className={`w-5 h-5 text-white`} />
        ) : null}
        {/* <input
          type="checkbox"
          checked={checked}
          disabled={true}
          className={`rounded ${
            checked ? `text-primary-600` : 'bg-white border-2 border-gray-400'
          }`}
        /> */}
      </span>
    );

    return layout;
  }

  return (
    <div className="flex group relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
      <Menu>
        {/* Drop down menu button for all filter options */}
        <Menu.Button
          disabled={isDisabled}
          className={`${
            inclusions.length > 0 ? 'rounded-l-md' : 'rounded-md'
          } disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50`}
        >
          <span className="flex gap-x-2 whitespace-nowrap">
            {field}{' '}
            {inclusions.length > 0 ? (
              <Tag
                type="custom"
                label={inclusions.length.toLocaleString()}
                bgColour="bg-primary-600"
                textColour="text-white"
              />
            ) : null}
          </span>
        </Menu.Button>

        {/* Separate button to clear this individual filter */}
        {inclusions.length > 0 ? (
          <button
            type="button"
            className={`flex justify-center items-center px-2 bg-gray-200 hover:bg-gray-300 border-y border-r rounded-r-md border-gray-300`}
            onClick={() => handleClearFilterClick()}
          >
            {iconClear}
          </button>
        ) : null}

        <Menu.Items className="origin-bottom-left absolute left-0 top-10 rounded-md overflow-hidden shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[1]">
          {/* Use the `active` render prop to conditionally style the active item. */}
          {getOptions().map(item => (
            <Menu.Item key={item.display}>
              {({ active }) => (
                <button
                  className={`${
                    active
                      ? 'bg-gray-100 text-gray-900'
                      : 'bg-white text-gray-700'
                  }  flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                  onClick={item.action}
                >
                  <span className="inline-flex items-center gap-x-2 mr-3 whitespace-nowrap">
                    {getCheckboxIcon(item)} {item.display}
                  </span>
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default FilterButton;
