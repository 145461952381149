import { EntityType } from '../../api/index';
import { capitaliseFirstLetter } from '../utils';

const brandName = 'JourneyLab';

// Configure entity names - assuming lower case and singular
// TODO: Provide an API and front end to configure these for each portfolio
function getCustomEntityNames() {
  return {
    billingAccount: 'billing Account',
    workspace: 'workspace',
    portfolio: 'portfolio',
    program: 'program',
    project: 'project',
  };
}
const workspaceLabel = getCustomEntityNames().workspace;
const billingAccountLabel = getCustomEntityNames().billingAccount;
const portfolioLabel = getCustomEntityNames().portfolio;
const programLabel = getCustomEntityNames().program;
const projectLabel = getCustomEntityNames().project;

export enum strings {
  BRAND_NAME = 'BRAND_NAME',
  MOBILE_WARNING = 'MOBILE_WARNING',
  EXPORT_REPORT = 'EXPORT_REPORT',
  LOADING_PORTFOLIOS = 'LOADING_PORTFOLIOS',
  LOADING_PROGRAMS = 'LOADING_PROGRAMS',
  LOADING_PROJECTS = 'LOADING_PROJECTS',
  PROGRAM_OVERVIEW = 'OVERVIEW',
  PROGRAM_OVERVIEW_DESCRIPTION = 'PROGRAM_OVERVIEW_DESCRIPTION',
  PROGRAM_OVERVIEW_PROJECTS_PRIORITY_LEVEL_DELIVERY_CONFIDENCE_TITLE = 'PROGRAM_OVERVIEW_PROJECTS_PRIORITY_LEVEL_DELIVERY_CONFIDENCE_TITLE',
  PROGRAM_OVERVIEW_ROADBLOCKS_TITLE = 'PROGRAM_OVERVIEW_ROADBLOCKS_TITLE',
  PROGRAM_OVERVIEW_RUNWAY_TITLE = 'PROGRAM_OVERVIEW_RUNWAY_TITLE',
  PROGRAM_SIGNIFICANT_ACTIVITY = 'SIGNIFICANT_PROGRAM_ACTIVITY',
  PROGRAM_SIGNIFICANT_ACTIVITY_DESCRIPTION = 'PROGRAM_SIGNIFICANT_ACTIVITY_DESCRIPTION',
  PROGRAM_SNAPSHOT = 'PROGRAM_SNAPSHOT',
  PROGRAM_SNAPSHOT_DESCRIPTION = 'PROGRAM_SNAPSHOT_DESCRIPTION',
  PROGRAM_SNAPSHOT_SUPPORT_NEEDED_DESCRIPTION = 'PROGRAM_SNAPSHOT_SUPPORT_NEEDED_DESCRIPTION',
  WHERE_SUPPORT_IS_NEEDED = 'WHERE_SUPPORT_IS_NEEDED',
  STRATEGIC_VISION = 'STRATEGIC_VISION',
  STRATEGIC_MISSION = 'STRATEGIC_MISSION',
  STRATEGIC_VISION_DESCRIPTION = 'STRATEGIC_VISION_DESCRIPTION',
  PORTFOLIO_STRATEGIC_PRIORITIES = 'PORTFOLIO_STRATEGIC_PRIORITIES',
  PORTFOLIO_STRATEGIC_PRIORITY = 'PORTFOLIO_STRATEGIC_PRIORITY',
  PORTFOLIO_STRATEGIC_PRIORITIES_DEFINITION = 'PORTFOLIO_STRATEGIC_PRIORITIES_DEFINITION',
  PORTFOLIO_STRATEGIC_OBJECTIVES = 'PORTFOLIO_STRATEGIC_OBJECTIVES',
  PORTFOLIO_STRATEGIC_OBJECTIVE = 'PORTFOLIO_STRATEGIC_OBJECTIVE',
  PORTFOLIO_STRATEGIC_OBJECTIVES_DEFINITION = 'PORTFOLIO_STRATEGIC_OBJECTIVES_DEFINITION',
  PORTFOLIO_ALL_PROGRAMS_PERFORMANCE = 'PORTFOLIO_ALL_PROGRAMS_PERFORMANCE',
  PORTFOLIO_ALL_PROGRAMS_PERFORMANCES_DEFINITION = 'PORTFOLIO_ALL_PROGRAMS_PERFORMANCES_DEFINITION',
  PORTFOLIO_MEASURES = 'PORTFOLIO_MEASURES',
  PORTFOLIO_MEASURES_DEFINITION = 'PORTFOLIO_MEASURES_DEFINITION',
  OBJECTIVES_CURRENT_RISK = 'OBJECTIVES_CURRENT_RISK',
  OBJECTIVES_FUTURE_RISK = 'OBJECTIVES_FUTURE_RISK',
  INFLIGHT_PROJECT_RAG = 'INFLIGHT_PROJECT_RAG',
  INFLIGHT_PROJECT_COUNT = 'INFLIGHT_PROJECT_COUNT',
  INFLIGHT_PROJECTS_BY_PRIORITY = 'INFLIGHT_PROJECTS_BY_PRIORITY',
  PORTFOLIO_BALANCE = 'PORTFOLIO_BALANCE',
  PROGRAMS_LOW_CONFIDENCE = 'PROGRAMS_LOW_CONFIDENCE',
  PROJECTS_LOW_CONFIDENCE = 'PROJECTS_LOW_CONFIDENCE',
  PROGRAMS_NO_BENEFIT = 'PROGRAMS_NO_BENEFIT',
  PROJECTS_NO_BENEFIT = 'PROJECTS_NO_BENEFIT',
  PROGRAM_BENEFITS_NOT_LINKED = 'PROGRAM_BENEFITS_NOT_LINKED',
  PROJECT_BENEFITS_NOT_LINKED = 'PROJECT_BENEFITS_NOT_LINKED',

  PROGRAM_MEASURES = 'PROGRAM_MEASURES',
  PROGRAM_MEASURE = 'PROGRAM_MEASURE',
  PROGRAM_MEASURES_DEFINITION = 'PROGRAM_MEASURES_DEFINITION',

  PROJECT_DELIVERY_CONFIDENCE = 'PROJECT_DELIVERY_CONFIDENCE',
  PROJECT_DELIVERY_DATE = 'PROJECT_DELIVERY_DATE',
  PROJECT_TOTAL_COST = 'PROJECT_TOTAL_COST',
  PROJECT_MEASURES = 'PROJECT_MEASURES',
  PROJECT_MEASURE = 'PROJECT_MEASURE',
  PROJECT_OUTPUTS = 'PROJECT_OUTPUTS',
  PROJECT_IMPACTS = 'PROJECT_IMPACTS',
  PROJECT_IMPACT = 'PROJECT_IMPACT',
  PROJECT_IMPACTS_DEFINITION = 'PROJECT_IMPACTS_DEFINITION',
  PROJECT_EXCEPTIONS = 'PROJECT_EXCEPTIONS',
  PROJECT_REVIEW = 'PROJECT_REVIEW',

  MEASURES_OF_SUCCESS = 'MEASURES_OF_SUCCESS',
  ROLES_PERMISSIONS = 'ROLES_PERMISSIONS',
  WIZARD_CONTENT_INTRO = 'WIZARD_CONTENT_INTRO',
  PENDING_SIGNUP = 'PENDING_SIGNUP',
  PENDING_INVITE = 'PENDING_INVITE',
  NO_INVITES = 'NO_INVITES',
  NO_NOTIFICATIONS = 'NO_NOTIFICATIONS',
  GOOD_EXCEPTION = 'GOOD_EXCEPTION',
  GOOD_EXCEPTION_DESCRIPTION = 'GOOD_EXCEPTION_DESCRIPTION',
  BAD_EXCEPTION = 'BAD_EXCEPTION',
  BAD_EXCEPTION_DESCRIPTION = 'BAD_EXCEPTION_DESCRIPTION',
  NO_EXCEPTION = 'NO_EXCEPTION',
  UNMAPPED = 'UNMAPPED',

  PROJECT_PROMPT_HIGHLIGHT = 'PROJECT_PROMPT_HIGHLIGHT',
  PROJECT_PROMPT_HIGHLIGHT_QUESTION = 'PROJECT_PROMPT_HIGHLIGHT_QUESTION',
  PROJECT_PROMPT_LOWLIGHT = 'PROJECT_PROMPT_LOWLIGHT',
  PROJECT_PROMPT_LOWLIGHT_QUESTION = 'PROJECT_PROMPT_LOWLIGHT_QUESTION',
  PROJECT_PROMPT_FUTURE = 'PROJECT_PROMPT_FUTURE',
  PROJECT_PROMPT_FUTURE_QUESTION = 'PROJECT_PROMPT_FUTURE_QUESTION',

  WIZARD_NEW_WORKSPACE = 'WIZARD_NEW_WORKSPACE',
  WIZARD_EDIT_WORKSPACE = 'WIZARD_EDIT_WORKSPACE',
  WIZARD_NEW_PORTFOLIO = 'WIZARD_NEW_PORTFOLIO',
  WIZARD_EDIT_PORTFOLIO = 'WIZARD_EDIT_PORTFOLIO',
  WIZARD_NEW_PROGRAM = 'WIZARD_NEW_PROGRAM',
  WIZARD_EDIT_PROGRAM = 'WIZARD_EDIT_PROGRAM',
  WIZARD_NEW_PROJECT = 'WIZARD_NEW_PROJECT',
  WIZARD_EDIT_PROJECT = 'WIZARD_EDIT_PROJECT',
  WIZARD_NEW_PORTFOLIO_REVIEW = 'WIZARD_NEW_PORTFOLIO_REVIEW',
  WIZARD_EDIT_PORTFOLIO_REVIEW = 'WIZARD_EDIT_PORTFOLIO_REVIEW',
  WIZARD_NEW_PROGRAM_REVIEW = 'WIZARD_NEW_PROGRAM_REVIEW',
  WIZARD_EDIT_PROGRAM_REVIEW = 'WIZARD_EDIT_PROGRAM_REVIEW',
  WIZARD_NEW_PROJECT_REVIEW = 'WIZARD_NEW_PROJECT_REVIEW',
  WIZARD_EDIT_PROJECT_REVIEW = 'WIZARD_EDIT_PROJECT_REVIEW',
  WIZARD_NEW_PROJECT_ASK = 'WIZARD_NEW_PROJECT_ASK',
  WIZARD_EDIT_PROJECT_ASK = 'WIZARD_EDIT_PROJECT_ASK',
}

// Dictionary to correctly display API.ts enums
export const enumTranslates: { [key: string]: string } = {
  // Entity
  billingAccount: billingAccountLabel,
  workspace: workspaceLabel,
  portfolio: portfolioLabel,
  program: programLabel, //'programX',
  project: projectLabel,
  // Role
  administrator: 'Administrator',
  executive: 'Executive',
  portfolioManager: 'Portfolio Manager',
  stakeholder: 'Stakeholder',
  businessOwner: 'Business Owner',
  programOwner: `${capitaliseFirstLetter(programLabel)} Manager`,
  projectOwner: `${capitaliseFirstLetter(projectLabel)} Manager`,
  contributor: 'Contributor',
  sponsor: 'Sponsor', // Deprecated
  expert: 'Subject Matter Expert', // Deprecated
  delivery: 'Delivery Team', // Deprecated
  other: 'Other Stakeholder', // Deprecated
  // InviteGroup
  owner: 'Owner',
  editor: 'Editor',
  viewer: 'Viewer',
  // Group (permissions)
  Owner: 'Owner',
  Editor: 'Editor',
  Viewer: 'Viewer',
  Stakeholder: 'Stakeholder',
  // Program ChangeType
  investment: 'Time-bound',
  bau: 'Ongoing',
  // ProgramUpdateTrend
  trendup: 'High',
  trendflat: 'Medium',
  trenddown: 'Low',
  // ProjectStage
  inplanning: 'In Planning',
  inflight: 'In-flight',
  completed: 'Completed',
  stopped: 'Stopped',
  // ProjectPriority
  tier1: 'Tier 1',
  tier2: 'Tier 2',
  tier3: 'Tier 3',
  // LogicModelGroup
  outputs: `${capitaliseFirstLetter(projectLabel)} Deliverable`,
  impacts: `${capitaliseFirstLetter(projectLabel)} Benefit`,
  // UpdateCadence
  weekly: 'Weekly',
  fortnightly: 'Fortnightly',
  monthly: 'Monthly',
  // DaysOfWeek
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  // UpdateStatus
  draft: 'Draft',
  published: 'Published',
  // ProjectUpdateConfidence
  high: 'High',
  medium: 'Medium',
  low: 'Low',
  // OnTimeStatus and OnBudgetStatus
  ontime: 'No change',
  onbudget: 'No change',
  na: 'N/A',
  rebaselined: 'New estimate',
  // RoadblockType - deprecated
  internalDependency: 'Internal dependency',
  resourcing: 'Insufficient resourcing',
  leadershipCommitment: 'Lack of leadership commitment',
  externalDependency: 'External dependency',
  changeInEnvironment: 'Change in environment',
  // RoadblockStatus
  ongoing: 'Ongoing',
  resolved: 'Resolved',
  // ProjectMeasureStatus
  onTrack: 'On track',
  risk: 'At risk but we have a plan',
  helpNeeded: 'At risk and we need help',
  paused: 'Paused for now',
  metOutcomes: 'Met intended outcomes', // TODO: Update entry in backend - done -> metOutcomes
  // Exceptions
  goodException: 'This is going well',
  badException: 'This is keeping us up at night',
  noException: 'Not exceptional at this time',
  // AskType
  decision: 'For decision',
  action: 'For action',
  noting: 'For noting',
  // AskStatus
  notified: 'Notified',
  seen: 'Seen',
  done: 'Done',
  confirmed: 'Closed',
  //Billing Account status
  active: 'Active',
  locked: 'Locked',
  suspended: 'Suspended',
  //Billing Account Plan
  single: 'Single',
  unlimited: 'Unlimited',
};

const TRANSLATION_MAP: { [key: string]: string } = {
  [strings.BRAND_NAME]: brandName,
  [strings.MOBILE_WARNING]: `${brandName} is designed for larger screens`,
  [strings.PROGRAM_OVERVIEW_DESCRIPTION]: `Distribution of ${projectLabel}s in this ${programLabel}`,
  [strings.PROGRAM_OVERVIEW_PROJECTS_PRIORITY_LEVEL_DELIVERY_CONFIDENCE_TITLE]: `${capitaliseFirstLetter(
    projectLabel
  )}s by Priority Level and Delivery Confidence`,
  [strings.PROGRAM_OVERVIEW_ROADBLOCKS_TITLE]: 'Roadblocks',
  [strings.PROGRAM_OVERVIEW_RUNWAY_TITLE]: 'Runway',
  [strings.PROGRAM_SNAPSHOT]: `${capitaliseFirstLetter(programLabel)} Reviews`,
  [strings.PROGRAM_SNAPSHOT_DESCRIPTION]: `How we're tracking toward strategic objectives`,
  [strings.PROGRAM_SIGNIFICANT_ACTIVITY_DESCRIPTION]:
    'Updates with impact across projects',
  [strings.PROGRAM_SNAPSHOT_SUPPORT_NEEDED_DESCRIPTION]:
    'Things that would benefit from additional support',
  [strings.STRATEGIC_VISION]: 'Vision',
  [strings.STRATEGIC_MISSION]: 'Mission',
  [strings.STRATEGIC_VISION_DESCRIPTION]:
    'The future we are seeking to bring to life',
  [strings.PORTFOLIO_STRATEGIC_PRIORITIES]: 'Strategic Pillars',
  [strings.PORTFOLIO_STRATEGIC_PRIORITY]: 'Strategic Pillar',
  [strings.PORTFOLIO_STRATEGIC_PRIORITIES_DEFINITION]:
    'Focus areas for our organisational strategy',
  [strings.PORTFOLIO_STRATEGIC_OBJECTIVES]: 'Strategic Objectives',
  [strings.PORTFOLIO_STRATEGIC_OBJECTIVE]: 'Strategic Objective',
  [strings.PORTFOLIO_STRATEGIC_OBJECTIVES_DEFINITION]: `The value drivers we are investing in`,
  [strings.PORTFOLIO_ALL_PROGRAMS_PERFORMANCE]: `${capitaliseFirstLetter(
    programLabel
  )} Performance`,
  [strings.PORTFOLIO_ALL_PROGRAMS_PERFORMANCES_DEFINITION]:
    'How each program is tracking',
  [strings.PORTFOLIO_MEASURES_DEFINITION]: `How we'll know whether we have succeeded`,
  [strings.PORTFOLIO_MEASURES]: `${capitaliseFirstLetter(portfolioLabel)} KPIs`,
  [strings.OBJECTIVES_CURRENT_RISK]: 'Strategic objectives currently at risk',
  [strings.OBJECTIVES_FUTURE_RISK]:
    'Strategic objectives may present a future risk',
  [strings.INFLIGHT_PROJECT_RAG]: `${capitaliseFirstLetter(
    projectLabel
  )}s rated high confidence`,
  [strings.INFLIGHT_PROJECT_COUNT]: `${capitaliseFirstLetter(
    projectLabel
  )}s currently in-flight`,
  [strings.INFLIGHT_PROJECTS_BY_PRIORITY]: `In-flight ${projectLabel}s in Priority Tier 1`,
  [strings.PORTFOLIO_BALANCE]: `Check ${projectLabel} distribution`,
  [strings.PROGRAMS_LOW_CONFIDENCE]: `${capitaliseFirstLetter(
    programLabel
  )}s need more support`,
  [strings.PROJECTS_LOW_CONFIDENCE]: `${capitaliseFirstLetter(
    projectLabel
  )}s need more support`,
  [strings.PROGRAMS_NO_BENEFIT]: `${capitaliseFirstLetter(
    programLabel
  )}s with no goals`,
  [strings.PROJECTS_NO_BENEFIT]: `In-flight ${projectLabel}s with no benefits`,
  [strings.PROGRAM_BENEFITS_NOT_LINKED]: `${capitaliseFirstLetter(
    programLabel
  )} goals not linked to strategy`,
  [strings.PROJECT_BENEFITS_NOT_LINKED]: `${capitaliseFirstLetter(
    projectLabel
  )} benefits not linked to strategy`,
  [strings.PROGRAM_MEASURES]: `${capitaliseFirstLetter(
    enumTranslates[EntityType.Program]
  )} Goals`,
  [strings.PROGRAM_MEASURE]: `${capitaliseFirstLetter(
    enumTranslates[EntityType.Program]
  )} Goal`,
  [strings.PROGRAM_MEASURES_DEFINITION]: `Business benefits that feed into our strategic goals`,
  [strings.PROJECT_DELIVERY_CONFIDENCE]: 'Confidence',
  [strings.PROJECT_DELIVERY_DATE]: `Estimated Completion${'\u00A0'}Date`,
  [strings.PROJECT_TOTAL_COST]: 'Estimated Total Cost',
  [strings.PROJECT_MEASURES]: 'Benefits & Deliverables',
  [strings.PROJECT_MEASURE]: `${capitaliseFirstLetter(
    enumTranslates[EntityType.Project]
  )} Benefit`,
  [strings.PROJECT_OUTPUTS]: `${capitaliseFirstLetter(
    enumTranslates[EntityType.Project]
  )} Deliverables`,
  [strings.PROJECT_IMPACTS]: `${capitaliseFirstLetter(
    enumTranslates[EntityType.Project]
  )} Benefits`,
  [strings.PROJECT_IMPACT]: `${capitaliseFirstLetter(
    enumTranslates[EntityType.Project]
  )} Benefit`,
  [strings.PROJECT_IMPACTS_DEFINITION]: `The intended results of each ${
    enumTranslates[EntityType.Project]
  }`,
  [strings.PROJECT_REVIEW]: `${capitaliseFirstLetter(projectLabel)} Review`,
  [strings.PROJECT_EXCEPTIONS]: 'Callouts',
  [strings.GOOD_EXCEPTION]: `Recognition`,
  [strings.GOOD_EXCEPTION_DESCRIPTION]: `What's going well?`,
  [strings.BAD_EXCEPTION]: `Risks`,
  [strings.BAD_EXCEPTION_DESCRIPTION]: `What's keeping us up at night?`,
  [strings.NO_EXCEPTION]: `Not rated`,
  [strings.MEASURES_OF_SUCCESS]: 'Measures of Success',
  [strings.ROLES_PERMISSIONS]: 'Roles & Permissions',
  [strings.WIZARD_CONTENT_INTRO]: `What we'll cover in this wizard`,
  [strings.PENDING_SIGNUP]: `User hasn't created an account yet`,
  [strings.PENDING_INVITE]: 'Invite sent',
  [strings.NO_INVITES]: 'No open invitations at this time',
  [strings.NO_NOTIFICATIONS]: 'No notifications at this time',
  [strings.UNMAPPED]: `Not linked`,

  [strings.PROJECT_PROMPT_HIGHLIGHT]: `Achievements`,
  [strings.PROJECT_PROMPT_HIGHLIGHT_QUESTION]: `What have we achieved to push us forward? What key lessons have
  we learnt?`,
  [strings.PROJECT_PROMPT_LOWLIGHT]: `Watchouts`,
  [strings.PROJECT_PROMPT_LOWLIGHT_QUESTION]: `What potential issues do we need to keep an eye on?`,
  [strings.PROJECT_PROMPT_FUTURE]: `What's next`,
  [strings.PROJECT_PROMPT_FUTURE_QUESTION]: `What should we focus on in the weeks ahead to improve ${projectLabel} outcomes?`,

  [strings.WIZARD_NEW_PORTFOLIO]: `${capitaliseFirstLetter(
    portfolioLabel
  )} Setup`,
  [strings.WIZARD_EDIT_PORTFOLIO]: `Edit ${capitaliseFirstLetter(
    portfolioLabel
  )}`,
  [strings.WIZARD_NEW_WORKSPACE]: `${capitaliseFirstLetter(
    workspaceLabel
  )} Setup`,
  [strings.WIZARD_EDIT_WORKSPACE]: `Edit ${capitaliseFirstLetter(
    workspaceLabel
  )}`,
  [strings.WIZARD_NEW_PROGRAM]: `${capitaliseFirstLetter(programLabel)} Setup`,
  [strings.WIZARD_EDIT_PROGRAM]: `Edit ${capitaliseFirstLetter(programLabel)}`,
  [strings.WIZARD_NEW_PROJECT]: `${capitaliseFirstLetter(projectLabel)} Setup`,
  [strings.WIZARD_EDIT_PROJECT]: `Edit ${capitaliseFirstLetter(projectLabel)}`,
  [strings.WIZARD_NEW_PORTFOLIO_REVIEW]: `New ${capitaliseFirstLetter(
    portfolioLabel
  )} Review`,
  [strings.WIZARD_EDIT_PORTFOLIO_REVIEW]: `Edit ${capitaliseFirstLetter(
    portfolioLabel
  )} Review`,
  [strings.WIZARD_NEW_PROGRAM_REVIEW]: `New ${capitaliseFirstLetter(
    programLabel
  )} Review`,
  [strings.WIZARD_EDIT_PROGRAM_REVIEW]: `Edit ${capitaliseFirstLetter(
    programLabel
  )} Review`,
  [strings.WIZARD_NEW_PROJECT_REVIEW]: `New ${capitaliseFirstLetter(
    projectLabel
  )} Review`,
  [strings.WIZARD_EDIT_PROJECT_REVIEW]: `Edit ${capitaliseFirstLetter(
    projectLabel
  )} Review`,
  [strings.WIZARD_NEW_PROJECT_ASK]: `New Ask`,
  [strings.WIZARD_EDIT_PROJECT_ASK]: `Ask`,
};

// Dictionary to sort API.ts enums
// These need to be internally consistent for each set
export const statusOrders: { [key: string]: number } = {
  // ProjectMeasureStatus - for the status dots
  helpNeeded: 1,
  risk: 2,
  onTrack: 3,
  done: 4,
  paused: 5,
  // Exceptions
  goodException: 1,
  badException: 2,
  noException: 3,
};

export const programConfidenceOrders: { [key: string]: number } = {
  // ProgramUpdateTrend
  trenddown: 1,
  trendflat: 2,
  trendup: 3,
};

export const projectConfidenceOrders: { [key: string]: number } = {
  // ProjectUpdateConfidence
  low: 1,
  medium: 2,
  high: 3,
};

export const projectStageOrders: { [key: string]: number } = {
  // ProjectStage
  inplanning: 2,
  inflight: 1,
  completed: 3,
  stopped: 4,
};

export const projectPriorityOrders: { [key: string]: number } = {
  // ProjectPriority
  tier1: 1,
  tier2: 2,
  tier3: 3,
};

export const portfolioRoleOrders: { [key: string]: number } = {
  // Portfolio Role
  administrator: 1,
  stakeholder: 2,
};

export const programRoleOrders: { [key: string]: number } = {
  // Project Role
  businessOwner: 1,
  programOwner: 2,
  contributor: 3,
};

export const projectRoleOrders: { [key: string]: number } = {
  // Project Role
  businessOwner: 1,
  projectOwner: 2,
  contributor: 3,
};

export const permissionToVerb: { [key: string]: string } = {
  // InviteGroup
  owner: 'owns',
  editor: 'can edit',
  viewer: 'can view',
  stakeholder: 'can view',
  // Group (permissions)
  Owner: 'owns',
  Editor: 'can edit',
  Viewer: 'can view',
  Stakeholder: 'can view',
};

export function getEnumString(enumstr: string): string {
  return enumTranslates[enumstr] ? enumTranslates[enumstr] : enumstr;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function reorderEnums(enums: any): any {
  // console.log('reordered:', enums)
  const keys = Object.keys(enumTranslates);
  // console.log(keys)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reordered = keys.filter(k => enums.some((v: any) => v === k));
  // console.log(reordered)
  if (reordered.length != enums.length) return enums;
  return reordered;
}

export default function translate(
  translationConstant: strings,
  context: string
): string {
  // In the future we can pre-generate a translation map for internationalization
  // and the constant + context will be important
  context; // no-op for now to remove lint error

  // But we can do a simple map for now
  if (TRANSLATION_MAP[translationConstant] != null) {
    return TRANSLATION_MAP[translationConstant];
  }

  console.error(
    `No translation found for ${translationConstant} so falling back to title casing logic`
  );

  // And do a dumb fallback
  return (
    translationConstant
      // replace multiple underscores in a row with a single one
      .replace(/_+/g, '_')
      // break up the string by underscore
      .split('_')
      // title case the individual words
      .map((token: string) => {
        return `${token[0].toUpperCase()}${token.slice(1).toLowerCase()}`;
      })
      // and bring together
      .join(' ')
  );
}
