import { User } from '../../../api/index';
import { UserExt } from '../../types';
import { WizardType } from '../WizardButton';
import { EntityDetailsPayload } from './EntityDetailsPayload';
// import { PortfolioDetailsPayload } from './PortfolioDetailsPayload';
// import { ProgramDetailsPayload } from './ProgramDetailsPayload';
// import { ProgramUpdatePayload } from './ProgramUpdatePayload';
// import { ProjectAskPayload } from './ProjectAskPayload';
// import { ProjectDetailsPayload } from './ProjectDetailsPayload';
// import { ProjectUpdatePayload } from './ProjectUpdatePayload';
// import { PortfolioUpdatePayload } from './PortfolioUpdatePayload';
import { WorkspaceDetailsPayload } from './WorkspaceDetailsPayload';

export const getInitialPayload = (
  type: WizardType,
  editObject: { [key: string]: unknown } | null,
  parentObject: { [key: string]: unknown } | undefined | null,
  user: UserExt | null,
  topLevelEntity: { [key: string]: unknown } | undefined | null,
  profile: User | null,
  workspaceId: string,
  rowId?: string,
): { [key: string]: unknown } => {
  // (1) and then we would update this return object to contain the existing values for those fields
  // values here correspond to the "key" in the input components
  switch (type) {
    // case 'PORTFOLIO_DETAILS':
    //   return PortfolioDetailsPayload(editObject, parentObject, user, profile);
    case 'WORKSPACE_DETAILS':
      return WorkspaceDetailsPayload(editObject, parentObject);
    case 'ENTITY_DETAILS':
      return EntityDetailsPayload(
        editObject,
        parentObject,
        user,
        topLevelEntity,
        profile,
        workspaceId
      );
    // case 'PORTFOLIO_UPDATE':
    //   return PortfolioUpdatePayload(editObject, parentObject, rowId);
    // case 'PROGRAM_DETAILS':
    //   return ProgramDetailsPayload(editObject, parentObject, user, profile);
    // case 'PROGRAM_QUICK_ADD':
    //   return ProgramDetailsPayload(editObject, parentObject, user, profile);
    // case 'PROGRAM_UPDATE':
    //   return ProgramUpdatePayload(editObject, parentObject);
    // case 'PROJECT_DETAILS':
    //   return ProjectDetailsPayload(editObject, parentObject, user, profile);
    // case 'PROJECT_QUICK_ADD':
    //   return ProjectDetailsPayload(editObject, parentObject, user, profile);
    // case 'PROJECT_ASK':
    //   return ProjectAskPayload(editObject, parentObject);
    // case 'PROJECT_UPDATE':
    //   return ProjectUpdatePayload(editObject, parentObject);
  }
  return { key: 'something' };
};
