import React, { useContext, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getObjectivePanelData,
  getPerformancePanelData,
  getStrategicContributionsData,
} from '../../../common/wizard/payloads/MockQueryFunctions';
import { UserContext } from '../../../context/UserContext';
import {
  ComponentInfo,
  GenericContainer,
} from '../../../JLcomponents/basic/GenericContainer';
import EntitiesListPanel from '../EntitiesListPanel/EntitiesListPanel';
import ObjectiveSlideOver from '../ObjectiveSlideOver';
import PerformanceSlideOver from '../PerformanceSlideOver/PerformanceSlideOver';

const entities = [
  {
    id: 'Entity_001',
    strategicObjective: 'Increase monthly active users by 10%',
    baseline: '9K MAU',
    target: '11K MAU',
    current: '10K MAU',
    confidence: 'Low',
    entityName: 'Customer App 2.0',
    hasEscalations: true,
  },
  {
    id: 'Entity_002',
    strategicObjective: 'Increase monthly active users by 10%',
    baseline: '9K MAU',
    target: '11K MAU',
    current: '10K MAU',
    confidence: 'Medium',
    entityName: 'Establish Loyalty Program',
    hasEscalations: false,
  },
  {
    id: 'Entity_003',
    strategicObjective: 'Increase monthly active users by 10%',
    baseline: '9K MAU',
    target: '11K MAU',
    current: '10K MAU',
    confidence: 'Medium',
    entityName: 'Do Exciting things',
    hasEscalations: false,
  },
  {
    id: 'Entity_004',
    strategicObjective: 'Increase number of vendors on the platform',
    baseline: '50 vendors',
    target: '75 vendors',
    current: '47 vendors',
    confidence: 'Low',
    entityName: 'Establish Loyalty Program',
    hasEscalations: false,
  },
  {
    id: 'Entity_005',
    strategicObjective: 'Reduce customer churn by 15%',
    baseline: '12% churn rate',
    target: '10% churn rate',
    current: '11% churn rate',
    confidence: 'Low',
    entityName: 'Improve customer service',
    hasEscalations: true,
  },
  {
    id: 'Entity_006',
    strategicObjective: 'Reduce customer churn by 15%',
    baseline: '12% churn rate',
    target: '10% churn rate',
    current: '11% churn rate',
    confidence: 'Medium',
    entityName: 'Offer better pricing',
    hasEscalations: false,
  },
  {
    id: 'Entity_007',
    strategicObjective: 'Reduce customer churn by 15%',
    baseline: '12% churn rate',
    target: '10% churn rate',
    current: '11% churn rate',
    confidence: 'High',
    entityName: 'Implement rewards program',
    hasEscalations: false,
  },
  {
    id: 'Entity_008',
    strategicObjective: 'Increase average order value by 20%',
    baseline: '$50 AOV',
    target: '$60 AOV',
    current: '$55 AOV',
    confidence: 'Low',
    entityName:
      'Cross-sell related products and do a bunch of other exciting things',
    hasEscalations: true,
  },
  {
    id: 'Entity_009',
    strategicObjective: 'Increase average order value by 20%',
    baseline: '$50 AOV',
    target: '$60 AOV',
    current: '$55 AOV',
    confidence: 'Medium',
    entityName: 'Upsell premium features',
    hasEscalations: false,
  },
  {
    id: 'Entity_010',
    strategicObjective: 'Increase average order value by 20%',
    baseline: '$50 AOV',
    target: '$60 AOV',
    current: '$55 AOV',
    confidence: 'High',
    entityName: 'Create product bundles',
    hasEscalations: true,
  },
  {
    id: 'Entity_011',
    strategicObjective: 'Improve website conversion rate by 10%',
    baseline: '3% conversion rate',
    target: '3.3% conversion rate',
    current: '3.1% conversion rate',
    confidence: 'High',
    entityName: 'Simplify checkout process',
    hasEscalations: false,
  },
  {
    id: 'Entity_012',
    strategicObjective: 'Improve website conversion rate by 10%',
    baseline: '3% conversion rate',
    target: '3.3% conversion rate',
    current: '3.1% conversion rate',
    confidence: 'High',
    entityName: 'Add customer reviews',
    hasEscalations: false,
  },
];

const baseObjectiveSlideoverData = {
  open: false,
  setOpen: () => console.log('Hide the slide over panel'),
  title: 'Funnel conversion rate',
  parentEntityName: 'APAC Business Unit',
  parentEntityUrl: 'www.convexview.com/entity/ENTITY_ID',
  updates: [
    {
      date: '12 Dec 2022',
      value: '15%',
      tag: 'Baseline',
    },
    {
      date: '1 Jan 2023',
      value: '17%',
      tag: '',
    },
    {
      date: '1 Feb 2023',
      value: '17%',
      tag: 'Latest',
    },
    {
      date: '1 Jun 2023',
      value: '20%',
      tag: 'Target',
    },
  ],
};

const nullObjectiveSlideoverData = {
  open: false,
  setOpen: () => null,
  title: '',
  parentEntityName: '',
  parentEntityUrl: '',
  updates: [
    {
      date: '',
      value: '',
      tag: '',
    },
  ],
};

const slideoverObjectivesData: any[] = [];
entities.forEach(entity => {
  slideoverObjectivesData.push({
    entityId: entity.id,
    ...baseObjectiveSlideoverData,
  });
});

const basePerformanceSlideoverData = {
  open: false,
  setOpen: () => null,
  title: 'The name of the Entity we just clicked on',
  titleUrl: 'EntityURL',
  // Replace these with components
  description: 'Description of the entity we want to deep dive into',
  managers: [
    { firstName: 'Alex', surname: 'Smith', email: 'alex@company.com' },
    { firstName: 'Sam', surname: 'Jones', email: 'sam@company.com' },
  ],
  endDate: '1 Jan 2023 (in X months)',
  outcomes: [
    {
      description: 'Benefit 1',
      target: 'Target for benefit 1',
      targetDate: '30 Jun 2024',
    },
    {
      description: 'Benefit 2',
      target: 'Target for benefit 2',
      targetDate: '30 Dec 2025',
    },
  ],
  reviews: [
    {
      id: 'REVIEW_ID_1',
      date: '1 Nov 2022',
      confidence: '',
      remarks: '',
    },
    {
      id: 'REVIEW_ID_2',
      date: '2 Dec 2022',
      confidence: 'High',
      remarks: 'Nothing to worry about.',
    },
    {
      id: 'REVIEW_ID_3',
      date: '3 Jan 2023',
      confidence: 'Medium',
      remarks: 'Something just blindsided us.',
    },
    {
      id: 'REVIEW_ID_4',
      date: '4 Feb 2023',
      confidence: 'Low',
      remarks: "We're in trouble and need support.",
    },
  ],
  waypoints: [
    { date: '5 Mar 2023', description: 'Fix part 1' },
    { date: '6 Apr 2023', description: 'Fix part 2' },
    { date: '27 May 2023', description: 'Relaunch again' },
  ],
  escalations: [
    {
      id: 'ESC-001',
      description: 'Short description of the watchout',
      type: 'Issue',
      sourceEntityName: 'Name of a child entity',
      creatorName: 'Bruce Banner',
      dueDate: '4 May 2023',
      escalatedAssigneeName: 'Tony Stark',
    },
    {
      id: 'ESC-002',
      description: 'Short description of another watchout',
      type: 'Decision',
      sourceEntityName: 'Name of the same or a different child entity',
      creatorName: 'Steven Strange',
      dueDate: '30 Jun 2023',
      escalatedAssigneeName: 'Tony Stark',
    },
  ],
};

const slideoverPerformanceData: any[] = [];
entities.forEach(entity => {
  slideoverPerformanceData.push({
    entityId: entity.id,
    ...basePerformanceSlideoverData,
  });
});

const nullPerformanceSlideoverData = {
  open: false,
  setOpen: () => null,
  title: '',
  titleUrl: '',
  description: '',
  managers: [],
  endDate: '',
  outcomes: [],
  reviews: [],
  waypoints: [],
  escalations: [],
};

function NorthStarTab(): React.ReactElement {
  const { user: currentUser, profile: userProfile } = useContext(UserContext);
  const [perfSliderData, setPerfSliderData] = useState(
    nullPerformanceSlideoverData
  );
  const [objSliderData, setObjSliderData] = useState(
    nullObjectiveSlideoverData
  );
  const { entityId } = useParams<{ entityId: string }>();

  const [entitiesData] = useState(
    getStrategicContributionsData(entityId as string)
  );

  const handleObjectivesClick = (entityId: string) => {
    // console.log('clicked on ' + entityId);
    const objData = getObjectivePanelData(entityId);

    const newObjData = {
      ...objData,
      open: true,
      setOpen: () => closeObjSlideover(),
    };
    console.log('objData', newObjData);
    setObjSliderData(newObjData as any);
  };

  const closeObjSlideover = () => {
    setObjSliderData({ ...objSliderData, open: false });
  };

  const closePerfSlideover = () => {
    setPerfSliderData({ ...perfSliderData, open: false });
  };

  const handlePerformanceClick = (entityId: string) => {
    // console.log('clicked on' + entityId);
    const perfData = getPerformancePanelData(entityId);
    // delete perfData.open;
    // delete perfData.setOpen;
    const newPerfData = {
      ...perfData,
      open: true,
      setOpen: () => closePerfSlideover(),
    };
    console.log('perfData', newPerfData);
    setPerfSliderData(newPerfData as any);
  };

  const containerPanels: ComponentInfo<any>[] = [
    {
      id: 'ENTITIES_LIST_PANEL_001',
      Component: EntitiesListPanel,
      props: {
        entities: entitiesData,
        onEditClick: () => {
          // console.log('onEditClick');
          console.log('onEditClick');
        },
        onObjectivesClick: handleObjectivesClick,
        onPerformanceClick: handlePerformanceClick,
        actionsDisabled: false,
        isVisible: false,
      },
    },
  ];

  return (
    <>
      <GenericContainer components={containerPanels} />
      <PerformanceSlideOver {...perfSliderData} />
      <ObjectiveSlideOver {...objSliderData} />
    </>
  );
}

export default NorthStarTab;
