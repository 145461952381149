import React from 'react';
import {
  Article,
  Bank,
  Bell,
  Briefcase,
  ChartLine,
  Chats,
  EnvelopeSimple,
  FlagBanner,
  FolderSimple,
  Link,
  NotePencil,
  Pause,
  Question,
  Wrench,
} from 'phosphor-react';
import { enumTranslates } from '../i18n/translate';
import { EntityType } from '../../api/index';

//   Pre-process data into groups
const groupNames = [
  `${enumTranslates[EntityType.Portfolio]}s`,
  `${enumTranslates[EntityType.Program]}s`,
  `${enumTranslates[EntityType.Project]}s`,
  `strategic pillars`,
  `strategic objectives`,
  `strategic objective reviews`,
  `${enumTranslates[EntityType.Program]} reviews`,
  `in-flight ${enumTranslates[EntityType.Project]}s`,
  `planned ${enumTranslates[EntityType.Project]}s`,
  `inactive ${enumTranslates[EntityType.Project]}s`,
  `${enumTranslates[EntityType.Project]} reviews`,
  `benefits`,
  `members`,
  `asks`,
  `links`,
  `comments`,
  `invites`,
  `notifications`,
  `waypoint review`,
] as const;
export type EmptyStateType = typeof groupNames[number];

type Props = {
  type: EmptyStateType;
  secondaryText?: string | React.ReactElement;
  hideIcon?: boolean;
};

const iconStyle = 'mx-auto h-12 w-12 text-gray-300';
const iconDefault = <Question className={iconStyle} weight="duotone" />;
const iconPortfolio = <Briefcase className={iconStyle} weight="duotone" />;
const iconProgram = <FolderSimple className={iconStyle} weight="duotone" />;
const iconProject = <Wrench className={iconStyle} weight="duotone" />;
const iconPlanned = <NotePencil className={iconStyle} weight="duotone" />;
const iconInactive = <Pause className={iconStyle} weight="duotone" />;
const iconReview = <Article className={iconStyle} weight="duotone" />;
const iconPillar = <Bank className={iconStyle} weight="duotone" />;
const iconBenefit = <ChartLine className={iconStyle} weight="duotone" />;
const iconObjective = <FlagBanner className={iconStyle} weight="duotone" />;
const iconInvite = <EnvelopeSimple className={iconStyle} weight="duotone" />;
const iconNotification = <Bell className={iconStyle} weight="duotone" />;
const iconComment = <Chats className={iconStyle} weight="duotone" />;
const iconLink = <Link className={iconStyle} weight="duotone" />;

export default function EmptyState({
  type,
  secondaryText,
  hideIcon,
}: Props): React.ReactElement {
  function getIcon(): JSX.Element {
    let icon = iconDefault;
    switch (type) {
      case `${enumTranslates[EntityType.Portfolio]}s`:
        icon = iconPortfolio;
        break;
      case `${enumTranslates[EntityType.Program]}s`:
        icon = iconProgram;
        break;
      case `${enumTranslates[EntityType.Project]}s`:
        icon = iconProject;
        break;
      case `in-flight ${enumTranslates[EntityType.Project]}s`:
        icon = iconProject;
        break;
      case `planned ${enumTranslates[EntityType.Project]}s`:
        icon = iconPlanned;
        break;
      case `inactive ${enumTranslates[EntityType.Project]}s`:
        icon = iconInactive;
        break;
      case 'strategic objective reviews':
        icon = iconReview;
        break;
      case `${enumTranslates[EntityType.Program]} reviews`:
        icon = iconReview;
        break;
      case `${enumTranslates[EntityType.Project]} reviews`:
        icon = iconReview;
        break;
      case 'strategic pillars':
        icon = iconPillar;
        break;
      case 'strategic objectives':
        icon = iconObjective;
        break;
      case 'benefits':
        icon = iconBenefit;
        break;
      case 'comments':
        icon = iconComment;
        break;
      case 'invites':
        icon = iconInvite;
        break;
      case 'notifications':
        icon = iconNotification;
        break;
      case 'links':
        icon = iconLink;
        break;
      case 'waypoint review':
        icon = iconReview;
        break;

      default:
    }
    return icon;
  }

  const defaultSecondaryText = `Get started by adding a ${type}.`;

  return (
    <div className="text-center flex flex-col justify-center">
      {hideIcon ? null : getIcon()}
      <h3 className="mt-2 text-sm font-medium text-gray-900">No {type}</h3>
      <div className="mt-1 text-sm text-gray-500">
        {secondaryText ? secondaryText : defaultSecondaryText}
      </div>
    </div>
  );
}
