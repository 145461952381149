import React, { useState } from 'react';
import { SlideOverTabSelector } from '../../common/slideovers/SlideOverTabSelector';
import PerformanceSlideOverEscalationsTab from './PerformanceSlideOverEscalationsTab';
import PerformanceSlideOverObjectivesTab from './PerformanceSlideOverObjectivesTab';
import PerformanceSlideOverTrajectoryTab from './PerformanceSlideOverTrajectoryTab';

export type Manager = {
  firstName: string;
  surname: string;
  email: string;
};

export type EntityOutcome = {
  description: string;
  target: string;
  targetDate: string;
};

export type Review = {
  id: string;
  date: string;
  confidence: string;
  remarks: string;
};

export type FutureWaypoint = {
  date: string;
  description: string;
};

// Note: This is analogous to the old Asks feature but simplfied and updated for a broader use case
// The data structure below is indicative only
// I imagine this to be the full data model.
// If this screen is just for escalated Watchouts, we can filter the Watchouts list and strip this off the .status and .escalation fields
export type EscalatedWatchout = {
  id: string; // The other types will also actually use an id for mapping keys
  description: string;
  type: 'Risk' | 'Issue' | 'Decision';
  sourceEntityName: string;
  creatorName: string; // Future: We may use an id to allow richer interactions (e.g. click to contact)
  dueDate?: string;
  escalatedAssigneeName: string;
};

type PerformanceSlideOverContentProps = {
  managers: Manager[];
  endDate: string;
  outcomes: EntityOutcome[];
  reviews: Review[];
  waypoints: FutureWaypoint[];
  escalations: EscalatedWatchout[];
};

type SlideOverContentProps = {
  selectedTabIndex: number;
  managers: Manager[];
  endDate: string;
  outcomes: EntityOutcome[];
  reviews: Review[];
  waypoints: FutureWaypoint[];
  escalations: EscalatedWatchout[];
};

const SlideOverTab = ({
  selectedTabIndex,
  managers,
  endDate,
  outcomes,
  reviews,
  waypoints,
  escalations,
}: SlideOverContentProps) => {
  let performanceSlideOverTab;

  if (selectedTabIndex === 0) {
    performanceSlideOverTab = (
      <PerformanceSlideOverObjectivesTab
        managers={managers}
        endDate={endDate}
        objectives={outcomes}
      />
    );
  } else if (selectedTabIndex === 1) {
    performanceSlideOverTab = (
      <PerformanceSlideOverTrajectoryTab
        reviews={reviews}
        waypoints={waypoints}
      />
    );
  } else {
    performanceSlideOverTab = (
      <PerformanceSlideOverEscalationsTab escalations={escalations} />
    );
  }

  return performanceSlideOverTab;
};

// This is a wrapper component for the performance slide over content, which is split into two tabs
export default function PerformanceSlideOverContent({
  managers,
  endDate,
  outcomes,
  reviews,
  waypoints,
  escalations,
}: PerformanceSlideOverContentProps): React.ReactElement {
  const tabNames = [`Objectives`, `Trajectory`, `Escalations`];
  const tabIndicators = [false, false, escalations?.length > 0];
  const [selectedTabIndex, setSelectedTabIndex] = useState(1); // Default to the trajectory panel

  return (
    <div>
      <div className="pb-12">
        {/* Tab selector */}
        <SlideOverTabSelector
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          tabNames={tabNames}
          tabIndicators={tabIndicators}
        />
        <SlideOverTab
          selectedTabIndex={selectedTabIndex}
          managers={managers}
          endDate={endDate}
          outcomes={outcomes}
          reviews={reviews}
          waypoints={waypoints}
          escalations={escalations}
        />
      </div>
    </div>
  );
}
