import React from 'react';
import {
  ExceptionStatus,
  LogicModelGroup,
  ProgramUpdate,
  Project,
  ProjectMeasure,
  ProjectMeasureStatus,
  ProjectUpdate,
} from '../../../api/index';
import {
  getLastTwoProjectUpdates,
  getLocalDate,
  getProjectMeasureStatusColour,
  getRelativeTime,
} from '../../../common/utils';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { enumTranslates } from '../../../common/i18n/translate';

export const LATEST_KEY = 'latest-data';

export type RelevantUpdates = {
  programUpdate: ProgramUpdate | null;
  projectUpdates: {
    project: Project;
    latestUpdate: ProjectUpdate | null;
    previousUpdate: ProjectUpdate | null;
  }[];
};

export type ProjectMeasureData = {
  measure: ProjectMeasure;
  latest: {
    status: ProjectMeasureStatus | null;
    update: ProjectUpdate | null;
    remarks: string;
    exceptionStatus: ExceptionStatus | null;
  };
  previous: {
    status: ProjectMeasureStatus | null;
    update: ProjectUpdate | null;
    remarks: string;
    exceptionStatus: ExceptionStatus | null;
  };
};

// Get the parent project for a project measure from amongst the given list of projects
export function getParentProject(
  measureToFind: ProjectMeasure,
  projects: Array<Project>
): Project | undefined {
  const foundProject = projects.find(project =>
    project.measures?.some(measure => measure.id === measureToFind.id)
  );
  return foundProject;
}

// Get the program and project updates based on selected program measure selector
export function getUpdatesBySelectedProgramUpdate(
  key: string,
  selectedProgramUpdate: ProgramUpdate | null,
  projects: Array<Project>
): RelevantUpdates {
  // Initialise
  let programUpdate: ProgramUpdate | null = null;
  const projectUpdatesResult: Array<{
    project: Project;
    latestUpdate: ProjectUpdate | null;
    previousUpdate: ProjectUpdate | null;
  }> = [];

  // Pass through the selected program update
  programUpdate = selectedProgramUpdate || null;

  if (key === LATEST_KEY) {
    // Get the two most recent project updates for each project
    projects.forEach(project => {
      const latestUpdates = getLastTwoProjectUpdates(project);

      if (latestUpdates) {
        projectUpdatesResult?.push({
          project: project,
          latestUpdate: latestUpdates.latestUpdate,
          previousUpdate: latestUpdates.previousUpdate,
        });
      }
    });
  } else {
    // Get data based on the selected program update date
    // For projects, this should be the most recent update before the selected date
    projects.forEach(project => {
      // Get all updates from the project
      const projectUpdates: Array<ProjectUpdate> =
        project?.updates?.items && project.updates.items.length > 0
          ? project.updates.items
          : [];
      const projectUpdatesSorted: Array<ProjectUpdate> = _.orderBy(
        [...projectUpdates],
        ['updateDate'],
        ['desc']
      );

      // Find the project update prior to the program update date...
      const foundLatestUpdate =
        projectUpdatesSorted.find(
          update =>
            new Date(update.updateDate).getTime() <
            new Date(programUpdate?.updateDate).getTime()
        ) || null;

      // ...as well as the one before that
      const foundPreviousUpdate =
        projectUpdatesSorted.find(
          update =>
            new Date(update.updateDate).getTime() <
            new Date(foundLatestUpdate?.updateDate).getTime()
        ) || null;

      projectUpdatesResult?.push({
        project: project,
        latestUpdate: foundLatestUpdate,
        previousUpdate: foundPreviousUpdate,
      });
    });
  }

  return {
    programUpdate: programUpdate,
    projectUpdates: projectUpdatesResult,
  };
}

// Get the detailed measure fields to be shown on screen
export function getProjectMeasureData(
  projectMeasures: Array<ProjectMeasure>,
  updatesData: RelevantUpdates
): Array<ProjectMeasureData> {
  // Initialise
  const result: Array<ProjectMeasureData> = [];

  // Get relevant project updates for all projects in the current program
  const latestProjectUpdates = updatesData.projectUpdates.map(
    item => item.latestUpdate
  );
  const previousProjectUpdates = updatesData.projectUpdates.map(
    item => item.previousUpdate
  );

  // Get statuses for each measure
  projectMeasures.map(pm => {
    // ...from latest project update
    const latestUpdate = latestProjectUpdates.find(update =>
      update?.measures?.some(measure => measure.measureId == pm.id)
    );
    const latestMeasure = latestUpdate?.measures?.find(
      measure => measure.measureId == pm.id
    );
    const latestData = {
      status: latestMeasure?.status ? latestMeasure.status : null,
      update: latestUpdate ? latestUpdate : null,
      remarks: latestMeasure?.remarks ? latestMeasure.remarks : '',
      exceptionStatus: latestMeasure?.exceptionStatus
        ? latestMeasure.exceptionStatus
        : null,
    };

    // ...from previous project update
    const previousUpdate = previousProjectUpdates.find(update =>
      update?.measures?.some(measure => measure.measureId == pm.id)
    );
    const previousMeasure = previousUpdate?.measures?.find(
      measure => measure.measureId == pm.id
    );
    const previousData = {
      status: previousMeasure?.status ? previousMeasure.status : null,
      update: previousUpdate ? previousUpdate : null,
      remarks: previousMeasure?.remarks ? previousMeasure.remarks : '',
      exceptionStatus: previousMeasure?.exceptionStatus
        ? previousMeasure.exceptionStatus
        : null,
    };

    // Only include project impacts on the program screen roll up
    if (pm.logicModelGroup === LogicModelGroup.Impacts) {
      result.push({
        measure: pm,
        latest: latestData,
        previous: previousData,
      });
    }
  });

  return result;
}

export function getStatusDot(
  status: ProjectMeasureStatus | string | null,
  date: Date,
  id: string
): React.ReactElement {
  const dot = (
    <React.Fragment>
      <span
        className={`${getProjectMeasureStatusColour(
          status
        )} inline-block rounded-full w-3 h-3 flex-shrink-0`}
        data-tip
        data-for={id}
      />

      {/* Add tooltip to show more info on hover */}
      <ReactTooltip id={id} effect="solid">
        <div className="text-center">
          <p className="font-semibold">
            {status ? enumTranslates[status] : 'Not rated'}
          </p>
          <p>
            {status ? `${getLocalDate(date)} (${getRelativeTime(date)})` : null}
          </p>
        </div>
      </ReactTooltip>
    </React.Fragment>
  );
  return dot;
}
