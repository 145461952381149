import React from 'react';
import { EscalatedWatchout } from './PerformanceSlideOverContent';
import { SlideOverSectionHeader } from '../../common/slideovers/SlideOverSectionHeader';
import PerformanceSlideOverEscalationCard from './PerformanceSlideOverEscalationCard';

type PerformanceSlideOverEscalationsTabProps = {
  escalations: EscalatedWatchout[];
};

export default function PerformanceSlideOverEscalationsTab({
  escalations,
}: PerformanceSlideOverEscalationsTabProps): React.ReactElement {
  return (
    <div className="space-y-6">
      {/* Show the list of escalations as cards */}
      <div>
        <SlideOverSectionHeader label={`What needs attention`} />

        {/* Escalations */}
        {escalations?.length > 0 ? (
          <div className="space-y-3 mt-1">
            {escalations.map(escalation => (
              <PerformanceSlideOverEscalationCard
                key={escalation.id}
                escalation={escalation}
              />
            ))}
          </div>
        ) : (
          // Empty state
          <div className="text-gray-400 italic">
            No unresolved escalations at this time
          </div>
        )}
      </div>
    </div>
  );
}
