import * as React from 'react';
import { Circle } from 'phosphor-react';

type Props = {
  selected: boolean;
  label: string;
  showIndicator: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
};

const Indicator = () => (
  <Circle weight="fill" size={10} className="text-red-600 animate-pulse" />
);

export const SlideOverTab = ({
  selected,
  label: title,
  showIndicator,
  isDisabled,
  onClick,
}: Props) => {
  let style = '';

  if (selected) {
    style =
      'text-accent-light bg-gradient-to-tr from-white to-white border-gray-200 shadow';
  } else if (isDisabled) {
    style = 'text-gray-400';
  } else {
    style = `text-gray-400 hover:text-gray-600 hover:bg-gray-100`;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className={`${style} group inline-flex items-center justify-center pl-4 ${
        showIndicator ? 'pr-3' : 'pr-4'
      } py-2 text-sm w-min h-full rounded-md`}
    >
      <div className="flex items-center justify-center font-semibold whitespace-nowrap mx-auto gap-x-1.5">
        {title}
        {showIndicator && <Indicator />}
      </div>
    </button>
  );
};
