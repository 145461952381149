import * as React from "react";

// TODO: Resolve these imports when copied into main repo
// Affected code commented out below

import translate, { strings } from "../../utils/translate";
import { capitaliseFirstLetter } from "../../utils/utils";

type Props = {
  type: TagType;
  label?: string;
  bgColour?: string;
  textColour?: string;
  border?: boolean;
  groupLabel?: string;
  groupBgColour?: string;
  groupTextColour?: string;
  decoration?: React.ReactElement;
};

type TagType =
  | "pending"
  | "user"
  | "ask type"
  | "overdue"
  | "preview"
  | "new"
  | "custom";

export default function Tag({
  type,
  label,
  bgColour,
  textColour,
  border,
  groupLabel,
  groupBgColour,
  groupTextColour,
  decoration,
}: Props): React.ReactElement {
  let displayLabel = "";
  let style = `bg-gray-200 text-gray-900`;

  switch (type) {
    case "pending":
      displayLabel = translate(strings.PENDING_INVITE, "Invite sent");
      // displayLabel = "Invite sent";
      style = `bg-gray-200 text-gray-900`;

      break;
    case "user":
      displayLabel = "You";
      style = `bg-gray-200 text-gray-900`;

      break;
    case "ask type":
      displayLabel = label ? label : "";
      style = `bg-blue-700 text-white`;

      break;
    case "overdue":
      displayLabel = capitaliseFirstLetter(type);
      // displayLabel = type;
      style = `bg-red-200 text-red-600`;

      break;
    case "preview":
      displayLabel = capitaliseFirstLetter(type);
      // displayLabel = type;
      style = `bg-gray-200 text-gray-900`;
      break;
    case "new":
      displayLabel = capitaliseFirstLetter(type);
      // displayLabel = type;
      style = `bg-primary-100 text-primary-700`;
      break;
    case "custom":
      displayLabel = label ? label : "";
      style = `${bgColour} ${textColour}`;
      break;

    default:
      break;
  }

  const groupStyles = `${groupBgColour} ${groupTextColour}`;

  return (
    <span
      className={`flex items-center text-center text-xs font-medium whitespace-nowrap w-min h-min rounded-full ${
        border ? "border border-gray-300" : ""
      }`}
    >
      {decoration || null}
      {groupLabel ? (
        <span
          className={`${groupStyles} align-middle items-center justify-center rounded-l-full px-2 py-0.5`}
        >
          {groupLabel}
        </span>
      ) : null}
      <span
        className={`${style} align-middle items-center justify-center ${
          groupLabel ? "rounded-r-full" : "rounded-full"
        } px-2 py-0.5`}
      >
        {displayLabel}
      </span>
    </span>
  );
}
