import * as React from 'react';
import {
  useWizardOptions,
  WizardContext,
} from '../../../common/wizard/WizardContext';
import { enumTranslates } from '../../../common/i18n/translate';
import { Info } from 'phosphor-react';
import { WizardContentsData } from '../../../common/wizard/components/WizardContentsCard';
import WizardTextInput from '../../../common/wizard/components/WizardTextInput';
import WizardSelect from '../../../common/wizard/components/WizardSelect';
import {
  Validation,
  ValidField,
} from '../../../common/wizard/validation/Validation.types';
import { Entity, EntityType, LifecycleStage } from '../../../api/index';
import {
  wizardContentsIconSize,
  wizardContentsIconStyle,
} from '../../../common/constants';
import { useMemo } from 'react';
import WizardDateInput from '../../../common/wizard/components/WizardDateInput';

// Step metadata
const stepName = 'Required Information';
const iconAbout = (
  <Info
    className={wizardContentsIconStyle}
    weight="duotone"
    size={wizardContentsIconSize}
  />
);

const meta: WizardContentsData = {
  icon: iconAbout,
  label: stepName,
  description: `The bare minimum we need to know about this Entity`,
};

function Description(): React.ReactElement {
  const paragraphs = [
    `Once the ${
      enumTranslates[EntityType.Entity]
    } has a name, you can save your progress and come back at any time.`,
  ];

  return (
    <div className="space-y-3">
      {paragraphs.map((paragraph, idx) => (
        <p key={idx} className="text-sm">
          {paragraph}
        </p>
      ))}
    </div>
  );
}

const ENTITY_NAME_VALIDATION: Validation = {
  rules: [{ level: 'error', type: 'NonEmptyValueRule' }],
};

function Content(): React.ReactElement {
  const { dispatch, state } = React.useContext(WizardContext);
  const options = useWizardOptions();
  const parentEntity = options.parentObject as Entity;
  const topLevelEntity = options.topLevelEntity as Entity;

  const stageOptions = useMemo(() => {
    return Object.values(LifecycleStage);
  }, []);

  function getObjectives(
    parentEntity: Entity
  ): Array<{ label: string; value: string }> {
    if (parentEntity === undefined || parentEntity === null) {
      return [];
    }
    const foundObjectives: Array<{ label: string; value: string }> = [];
    parentEntity.objectives?.items?.forEach(objective => {
      if (objective?.description && objective.id) {
        foundObjectives.push({
          label: objective.description,
          value: objective.id,
        });
      }
    });
    return foundObjectives;
  }

  const [fieldValidations, setFieldValidations] = React.useState<
    ValidField[] | []
  >([
    {
      field: 'name',
      valid: false,
    },
  ]);
  React.useEffect(() => {
    const isValid = fieldValidations.some(
      (f: { valid: boolean }) => f.valid !== true
    );
    dispatch({
      type: 'WRITE_TO_STEP_VALIDATION',
      key: state.currentStepIndex,
      value: !isValid,
    });
  }, [dispatch, fieldValidations, state.currentStepIndex]);

  return (
    <div className="mt-5 md:mt-0">
      <div className="grid grid-cols-6 gap-4">
        <div className="col-span-6">
          <WizardTextInput
            id="name"
            key="name"
            isWizard={true}
            requestKey="name"
            setFieldValidations={setFieldValidations}
            fieldValidations={fieldValidations}
            label={`What shall we call this ${
              enumTranslates[EntityType.Entity]
            }?`}
            validation={ENTITY_NAME_VALIDATION}
            placeholder={`A few words that identify this ${
              enumTranslates[EntityType.Entity]
            }`}
          />
          {parentEntity && (
            <p className="text-xs text-gray-500 mt-1">
              {`This ${
                enumTranslates[EntityType.Entity]
              } will be created under the ${
                enumTranslates[EntityType.Entity]
              } ${parentEntity.name}`}
            </p>
          )}
        </div>
        <div className="col-span-6">
          <WizardTextInput
            id="description"
            key="description"
            isWizard={true}
            requestKey="description"
            label={`Description`}
            placeholder={`A few words that describe this ${
              enumTranslates[EntityType.Entity]
            }`}
          />
        </div>

        {topLevelEntity && (
          <div className="col-span-6">
            <WizardSelect
              id="primaryStrategicObjective"
              key="primaryStrategicObjective"
              requestKey="primaryStrategicObjective"
              label={`Primary Strategic Objective`}
              options={getObjectives(topLevelEntity)}
              disabled={false}
            />
          </div>
        )}

        {parentEntity && (
          <div className="col-span-6">
            <WizardSelect
              id="primaryParentObjective"
              key="primaryParentObjective"
              requestKey="primaryParentObjective"
              label={`Primary Parent Objective`}
              options={getObjectives(parentEntity)}
              disabled={false}
            />
          </div>
        )}
        <div className="col-span-6">
          <WizardSelect
            id="stage"
            key="stage"
            requestKey="stage"
            label={`Lifecycle Stage`}
            options={stageOptions}
            disabled={false}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <WizardDateInput
            id="startDate"
            isWizard={true}
            key="startDate"
            requestKey="startDate"
            label={`When does/did this work begin?`}
            validation={undefined}
          />
          <WizardDateInput
            id="endDate"
            isWizard={true}
            key="endDate"
            requestKey="endDate"
            label={`When does/did this work end?`}
            validation={undefined}
          />
        </div>
        <div className="col-span-6 sm:col-span-3">
          <WizardTextInput
            id="budget"
            key="budget"
            isWizard={true}
            requestKey="budget"
            label={`How much funding has been allocated in total?`}
            decorationType="currency"
          />
        </div>
      </div>
    </div>
  );
}

export default {
  meta: meta,
  description: <Description />,
  content: <Content />,
  valid: true,
};
