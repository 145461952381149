import React from "react";
import ToggleButton from "../../basic/ToggleButton";

export type ShortcutsActionHeaderProps = {
  showStarred: boolean;
  setShowStarred: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShortcutsActionHeader = ({
  showStarred,
  setShowStarred,
}: ShortcutsActionHeaderProps) => {
  const label = "Show starred only";

  return (
    <div className="flex items-center">
      <span className="flex flex-wrap">
        <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
          <span className="sm:ml-3">
            <ToggleButton
              state={showStarred}
              setState={setShowStarred}
              label={label}
              disabled={false}
            />
          </span>
        </div>
      </span>
    </div>
  );
};

export default ShortcutsActionHeader;
