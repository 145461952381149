import React from "react";
import { FullIcon, NaIcon, PartialIcon } from "../../basic/ButtonIcons";
import ButtonGroup, {
  ButtonGroupType,
} from "../../common/buttonGroups/ButtonGroup";

const ProgressRatingOptions = [`na`, `partial`, `complete`, `none`] as const;

// TODO: Check contributing logic
export type ProgressRating = typeof ProgressRatingOptions[number];

export type ToggleButtonProps = {
  onClick: () => void;
  icon: React.ReactElement;
  selected: boolean;
  tooltipText?: string;
};

type RatingBarProps = {
  defaultValue: ProgressRating;
  onClick: (value: string | undefined) => void;
};

export default function RatingBar({
  defaultValue,
  onClick,
}: RatingBarProps): React.ReactElement {
  const ratingButtons: ButtonGroupType[] = [
    {
      id: "rating-na",
      icon: <NaIcon />,
      value: "na",
      tooltipText: `No longer applicable`,
    },
    {
      id: "rating-partial",
      icon: <PartialIcon />,
      value: "partial",
      tooltipText: `Partially complete`,
    },
    {
      id: "rating-full",
      icon: <FullIcon />,
      value: "complete",
      tooltipText: `Done`,
    },
  ];

  return (
    <ButtonGroup
      buttons={ratingButtons}
      defaultValue={defaultValue}
      onClick={onClick}
    />
  );
}
