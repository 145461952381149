import React from 'react';
import './index.css';
import App from './App';
import { Amplify } from 'aws-amplify';
import { aws_config } from './amplify.conf';
import { createRoot } from 'react-dom/client';

Amplify.configure(aws_config);

// ReactDOM.render(<App />, document.getElementById('root'));

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<App />);
