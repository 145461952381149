import React from "react";
import { ToggleOffIcon, ToggleOnIcon } from "./ButtonIcons";

export interface ToggleButton {
  id?: string;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;

  label: string;
  disabled?: boolean;
}

const ToggleButton = ({ state, setState, label, disabled }: ToggleButton) => {
  const icon = state ? <ToggleOnIcon /> : <ToggleOffIcon />;

  function handleClick() {
    setState(!state);
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className="gap-x-2 bg-gradient-to-tr from-gray-100 to-gray-50 hover:to-gray-100 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm whitespace-nowrap text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
      onClick={handleClick}
    >
      {icon}
      {label}
    </button>
  );
};

export default ToggleButton;
