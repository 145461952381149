import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { ulid } from 'ulid';
import { ProjectUpdateConfidence, ProgramUpdateTrend } from '../api/index';
import translate, { enumTranslates, strings } from './i18n/translate';
import {
  capitaliseFirstLetter,
  getConfidenceColour,
  getLocalDate,
  getRelativeTime,
} from './utils';
import { classNames } from './utils';

type Props = {
  rating: ProjectUpdateConfidence | ProgramUpdateTrend | null | undefined;
  reviewDate?: Date;
  size?: 'normal' | 'shortened';
};

export default function ConfidenceBadge({
  rating,
  reviewDate,
  size,
}: Props): React.ReactElement {
  const key = ulid();
  const label = enumTranslates[rating as string];

  const layout = (
    <React.Fragment>
      {rating ? (
        <span
          data-tip
          data-for={key}
          className={classNames(
            getConfidenceColour(rating, 'normal'),
            `block h-6 ${
              size && size == 'shortened' ? 'w-6' : 'w-16'
            } flex-shrink-0 text-center text-xs align-middle items-center justify-center text-white rounded font-semibold px-1 py-1 whitespace-nowrap`
          )}
        >
          {size && size == 'shortened' ? label[0] : label}
        </span>
      ) : (
        <span
          data-tip
          data-for={key}
          className={`bg-white block h-6 ${
            size && size == 'shortened' ? 'w-6' : 'w-16'
          } flex-shrink-0 text-center text-xs align-middle items-center justify-center text-gray-400 rounded px-1 py-1 border border-gray-300 whitespace-nowrap italic`}
        >
          {size && size == 'shortened' ? '-' : 'Not rated'}
        </span>
      )}

      <ReactTooltip
        id={key}
        // place="right"
        // type="info"
        effect="solid"
        // delayShow={tooltipDelay}
      >
        <div className="text-center">
          <p className="font-semibold">
            {size === 'shortened'
              ? rating
                ? `${enumTranslates[rating]} ${translate(
                    strings.PROJECT_DELIVERY_CONFIDENCE,
                    'Delivery Confidence'
                  ).toLowerCase()}`
                : `${capitaliseFirstLetter(
                    translate(
                      strings.PROJECT_DELIVERY_CONFIDENCE,
                      'Delivery Confidence'
                    ).toLowerCase()
                  )} not rated`
              : `${capitaliseFirstLetter(
                  translate(
                    strings.PROJECT_DELIVERY_CONFIDENCE,
                    'Delivery Confidence'
                  ).toLowerCase()
                )}`}
          </p>
          <p className="text-xs mt-1">{`${
            reviewDate
              ? `As at ${getLocalDate(reviewDate)} (${getRelativeTime(
                  reviewDate
                )})`
              : ''
          }`}</p>
        </div>
      </ReactTooltip>
    </React.Fragment>
  );

  return layout;
}
