import React, { FC } from 'react';
import { ApolloProvider } from '@apollo/client';

import AppSyncClient from './AppSyncClient';

type Props = {
  children: React.ReactNode;
};

export const AppSyncProvider: FC<Props> = ({ children }) => {
  return <ApolloProvider client={AppSyncClient}>{children}</ApolloProvider>;
};
