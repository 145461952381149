import * as React from 'react';
import { Info } from 'phosphor-react';
import ReactTooltip from 'react-tooltip';
import { ulid } from 'ulid';

type Props = {
  title: string | React.ReactElement;
  description?: string;
};

export default function DetailsPanelHeader({
  title,
  description,
}: Props): React.ReactElement {
  const key = ulid();

  return (
    <div className="flex items-center gap-x-2 my-2">
      <h3 className="text-lg leading-6 font-medium text-gray-900 whitespace-nowrap">
        {title}
      </h3>

      {/* Optional description */}
      {description ? (
        <React.Fragment>
          <button
            type="button"
            className="h-min disabled:opacity-50 ml-auto inline-flex mt-0.5 p-0.5 rounded-full text-gray-400 hover:text-blue-600 hover:shadow-sm"
            data-tip
            data-for={key}
          >
            <Info
              weight="bold"
              className="h-4 w-4 shrink-0"
              aria-hidden="true"
            />
          </button>
          <ReactTooltip
            id={key}
            place="right"
            // type="info"
            effect="solid"
            // delayShow={tooltipDelay}
          >
            <div className="text-sm">{description}</div>
          </ReactTooltip>
        </React.Fragment>
      ) : null}
    </div>
  );
}
