import React from "react";
import { RelationshipType } from "./RelationshipsPanel";

type RelationshipIndicatorProps = {
  type: RelationshipType;
};

function relationshipDescription(type: RelationshipType): string {
  let output = "";

  switch (type) {
    case RelationshipType.enables:
      output = "They enable us";
      break;

    case RelationshipType.dependsOn:
      output = "We enable them";
      break;

    case RelationshipType.related:
      output = "We are related";
      break;

    default:
      break;
  }

  return output;
}

export default function RelationshipIndicator({
  type,
}: RelationshipIndicatorProps): React.ReactElement {
  // Using text instead for now to make things clearer (icons were confusing)

  // const relatedIcon = <ArrowsLeftRight size={20} weight="bold" />;
  // const enablesIcon = <ArrowLeft size={20} weight="bold" />;
  // const dependentIcon = <ArrowRight size={20} weight="bold" />;

  return (
    <div className="text-sm text-gray-500">
      {relationshipDescription(type)}
      {/* <Rectangle size={20} weight="duotone" className="text-primary-600" />
      {icon}
      <Rectangle size={20} weight="duotone" className="text-gray-400" /> */}
    </div>
  );
}
