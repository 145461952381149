/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { UserAuth } from '../common/types';
import { useAuth } from './authHooks';
import Spinner from '../common/SpinnerThemed';

export const UserContext = createContext<UserAuth>({
  user: null,
  signIn: async () => {},
  signOut: async () => {},
  isLoading: false,
  setProfile: () => {},
  profile: null,
  setLoading: () => {},
});

interface AuthProps {
  children: React.ReactNode;
}

export const AuthProvider: React.ElementType = ({ children }: AuthProps) => {
  const auth = useAuth();
  return (
    <UserContext.Provider value={auth}>
      {auth.isLoading ? <Spinner text="Loading..." /> : children}
    </UserContext.Provider>
  );
};
