import * as React from 'react';
import { useReducer } from 'react';
import { Program } from '../../api/index';

export type ProgramPageState = {
  selectedProgram: Program | null;
  loadUpdate: boolean;
  programs: Array<Program> | null;
  usersPrograms: Array<Program> | null;
  loadProject: boolean;
  invitations: Array<any> | null;
};

const initialState: ProgramPageState = {
  selectedProgram: null,
  programs: null,
  usersPrograms: null,
  loadUpdate: false,
  loadProject: false,
  invitations: null,
};

type SetSelectedProgramAction = {
  type: 'SET_SELECTED_PROGRAM';
  program: Program | null;
};

type SetProgramsAction = {
  type: 'SET_PROGRAMS';
  programs: Array<Program> | null;
};

type SetInvitationsAction = {
  type: 'SET_INVITATIONS';
  invitations: Array<any> | null;
};

type SetUsersProgramsAction = {
  type: 'SET_USERS_PROGRAMS';
  usersPrograms: Array<Program>;
};

type LoadUpdateAction = {
  type: 'LOAD_UPDATE';
  loadUpdate: boolean;
};

type LoadProjectAction = {
  type: 'LOAD_PROJECT';
  loadProject: boolean;
};

export type ProgramPageAction =
  | SetSelectedProgramAction
  | LoadUpdateAction
  | SetProgramsAction
  | LoadProjectAction
  | SetInvitationsAction
  | SetUsersProgramsAction;

type ProgramPageContextState = {
  dispatch: React.Dispatch<ProgramPageAction>;
  state: ProgramPageState;
};

export const ProgramPageContext = React.createContext<ProgramPageContextState>({
  dispatch: () => null,
  state: initialState,
});

const reducer = (state: ProgramPageState, action: ProgramPageAction) => {
  switch (action.type) {
    case 'SET_SELECTED_PROGRAM':
      return {
        ...state,
        selectedProgram: action.program,
      };
    case 'SET_INVITATIONS':
      return {
        ...state,
        invitations: action.invitations,
      };
    case 'SET_PROGRAMS':
      return {
        ...state,
        programs: action.programs,
      };
    case 'SET_USERS_PROGRAMS':
      return {
        ...state,
        usersPrograms: action.usersPrograms,
      };
    case 'LOAD_UPDATE':
      return {
        ...state,
        loadUpdate: !state.loadUpdate,
      };
    case 'LOAD_PROJECT':
      return {
        ...state,
        loadProject: action.loadProject,
      };
    default:
      return state;
  }
};

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

export function ProgramPageContextProvider({
  children,
}: Props): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState);
  // useEffect(() => {
  //   localStorage.setItem('program', JSON.stringify(state))
  // }, [state])

  // console.log(state);
  return (
    <ProgramPageContext.Provider value={{ dispatch, state }}>
      {children}
    </ProgramPageContext.Provider>
  );
}
