import * as React from 'react';

type Props = {
  header: string;
  content: string | React.ReactElement;
};

export default function TextBlock({
  header,
  content,
}: Props): React.ReactElement {
  return (
    <div className="flex flex-col lg:flex-row text-sm">
      <div className="font-medium lg:w-28">{header}</div>
      <div className="text-gray-600">{content}</div>
    </div>
  );
}
