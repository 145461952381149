import { Program, ProgramUpdate } from '../../../api/index';
import _ from 'lodash';

export function getLastFourUpdates(
  program: Program | null
): (ProgramUpdate | undefined)[] {
  const result: (ProgramUpdate | undefined)[] = new Array<undefined>(4);

  if (program) {
    const updates = program?.updates?.items;

    if (updates) {
      const programUpdatesSorted = _.orderBy(
        [...updates],
        ['updateDate'],
        ['desc']
      );

      // Get first 4 items
      const foundUpdates = programUpdatesSorted.slice(0, 4);
      foundUpdates.forEach(item => result.push(item));

      // Ensure there are at least 4 items
      if (foundUpdates.length < 4) {
        const delta = 4 - foundUpdates.length;

        for (let index = 0; index < delta; index++) {
          result.push(undefined);
        }
      }
    }
  }

  return result.slice(4);
}
