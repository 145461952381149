import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import WizardButton from '../../../common/wizard/WizardButton';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import BasicTable, { DataRow } from '../../common/tables/BasicTable';
import BasicTableCell from '../../common/tables/BasicTableCell';
import StarTableCell from '../../common/tables/StarTableCell';
import ShortcutsActionHeader from './ShortcutsActionHeader';
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

const ShortcutsTitle = () => (
  <DetailsHeaderTitle
    title={`What I'm working on`}
    tooltipText={`The initiatives I have access to`}
  />
);

type ShortcutsContentProps = {
  entities: ShortcutEntity[];
  starHandler: (entity: string) => void;
};

function ShortcutsContent({ entities, starHandler }: ShortcutsContentProps) {
  const tableHeader: DataRow = {
    data: [
      {
        key: 'TH1',
        cellContents: <BasicTableCell contents="" />,
        widthClass: 'w-12',
        // tooltip: <div className="inline-flex">'No comment'</div>,
      },
      { key: 'TH2', cellContents: <BasicTableCell contents="Name" /> },
      {
        key: 'TH3',
        cellContents: <BasicTableCell contents="My role" />,
        // tooltip: <div>When we expect to achieve the target</div>,
      },
    ],
  };

  const tableData: DataRow[] = [];
  entities.forEach(entity =>
    tableData.push({
      data: [
        {
          key: `${entity.description}-1`,
          cellContents: (
            <StarTableCell
              entityId={entity.id}
              isStarred={entity.isStarred}
              starHandler={starHandler}
            />
          ),
        },
        {
          key: `${entity.description}-2`,
          cellContents: (
            <div className={`${entity?.parentId && 'pl-4'} font-medium`}>
              <Link to={`/entity/${entity.id}`}>{entity.name} </Link>
            </div>
          ),
        },
        {
          key: `${entity.description}-3`,
          cellContents: <BasicTableCell contents={entity.myRole || '-'} />,
        },
      ],
    })
  );

  return (
    <div className="min-w-full text-sm space-y-4 lg:space-y-3">
      {entities.length > 0 ? (
        <BasicTable tableData={tableData} tableHeader={tableHeader} />
      ) : (
        // Empty state
        <div className="text-gray-400 italic text-center">
          No entities available yet
        </div>
      )}
    </div>
  );
}

export type ShortcutEntity = {
  id: string;
  name: string;
  description?: string;
  isStarred: boolean;
  myRole: string | null;
  parentId?: string;
};

export type ShortcutsPanelProps = {
  myEntities: ShortcutEntity[];
  workspaceId: string;
  //   actionsDisabled: boolean;
  //   onEditClick: () => void;
  //   isVisible: boolean;
};

export default function ShortcutsPanel({
  myEntities,
  workspaceId,
}: // actionsDisabled,
// onEditClick,
// isVisible,
ShortcutsPanelProps): React.ReactElement {
  const [showStarred, setShowStarred] = useState(false);
  const [shownEntities, setShownEntities] = useState(myEntities);

  const displayEntities = showStarred
    ? shownEntities.filter(entity => entity.isStarred)
    : shownEntities;

  const starHandler = (entityId: string) => {
    const e = shownEntities.find(
      entity => entity.id === entityId
    ) as ShortcutEntity;
    e.isStarred = !e.isStarred;

    setShownEntities([...shownEntities]);
  };

  return (
    <DetailsHeader
      headerLeft={<ShortcutsTitle />}
      headerRight={
        <div className="flex items-center">
          <ShortcutsActionHeader
            showStarred={showStarred}
            setShowStarred={setShowStarred}
          />
          <span className="sm:ml-3 ">
            {
              <WizardButton
                title={'Add entity'}
                editObject={null}
                parentObject={null}
                topLevelEntity={null}
                type="ENTITY_DETAILS"
                startStep={1}
                workspaceId={workspaceId}
              />
            }
          </span>
        </div>
      }
      content={
        <ShortcutsContent
          entities={displayEntities}
          starHandler={starHandler}
        />
      }
    />
  );
}
