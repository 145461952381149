import {
  ValidateResult,
  ValidationRule,
  ValidationRuleStrategy,
} from '../Validation.types';

const RegexValidationRuleStrategy: ValidationRuleStrategy = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(rule: ValidationRule, value: string): ValidateResult {
    // To be implemented
    return {
      isValid: true,
      messages: [],
    };
  },
};

export default RegexValidationRuleStrategy;
