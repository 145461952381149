/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Tab as HeadlessUITab } from '@headlessui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Path } from 'typescript';
import { Entity } from '../../api/index';
import Spinner from '../SpinnerThemed';

export type Tab = {
  tab: React.ReactElement;
  content: React.ReactElement;
  hash?: string;
};

type Props = {
  title: string;
  subtitle?: string;
  // header: React.ReactElement;
  entity?: Entity | null;
  tabs: Array<Tab>;
  isLoading: boolean;
  // selected?: number;
  // setSelectedIndex?: React.Dispatch<React.SetStateAction<number>>;
};

export default function HeaderWithTabsLayout({
  title,
  subtitle,
  tabs,
  entity,
  isLoading,
}: Props): React.ReactElement {
  const { hash } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex-col">
      {/* Breadcrumbs */}
      <div className="text-sm ml-2">
        {/* {entity && <CrumbTrail entity={entity} isLoading={isLoading} />} */}
      </div>

      <HeadlessUITab.Group
        onChange={index => {
          navigate(tabs[index].hash as Path);
        }}
        selectedIndex={
          tabs
            .map(function (tab) {
              return tab.hash;
            })
            .indexOf(hash) || 0
        }
      >
        <div className="sticky top-0 bg-white z-[5] w-full pt-2">
          <div className="flex flex-col xl:flex-row align-bottom justify-start w-full">
            {/* Header */}
            <div className="flex flex-col justify-end align-baseline pl-2 pr-8 py-2 flex-grow space-x-3">
              {/* Title */}
              {/* {isLoading && ? (
                // Show placeholder
                <div className="h-9 w-3/4 rounded bg-gray-400 animate-pulse" />
              ) : ( */}
              <h2 className="text-2xl sm:text-3xl font-bold text-gray-900">
                {title}
              </h2>
              {/* )} */}

              {/* Subtitle (optional) */}
              {subtitle &&
                (isLoading ? (
                  // Show placeholder
                  <div className="h-5 w-3/5 rounded bg-gray-400 mt-2 animate-pulse" />
                ) : (
                  <div className="mt-2 mb-1 mr-6 flex align-top text-sm text-gray-500">
                    {subtitle}
                  </div>
                ))}
            </div>

            {/* Tab list */}
            <div className="flex flex-col justify-end">
              <div className="flex justify-start px-2">
                <HeadlessUITab.List
                  className={`flex h-min gap-x-1 gap-y-1 overflow-x-auto p-1.5 bg-gray-50 rounded-lg shadow-inner`}
                >
                  {tabs.map(tab => tab.tab)}
                </HeadlessUITab.List>
              </div>
            </div>
          </div>

          <div className="border-t border-primary-500 mt-2 mb-4 -mx-2 md:mx-0"></div>
        </div>

        <div className="px-0 md:px-2">
          {isLoading ? (
            <div className="flex flex-col align-middle items-center">
              <Spinner text={'Loading...'} />
            </div>
          ) : (
            <HeadlessUITab.Panels>
              {tabs.map(tab => tab.content)}
            </HeadlessUITab.Panels>
          )}
        </div>
      </HeadlessUITab.Group>
    </div>
  );
}
