import React, { useState } from 'react';
import { CaretDown, CaretUp, FolderSimple, Plus, X } from 'phosphor-react';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import { DummyProject, Scenario } from './dummyData';
import DetailsPanelSectionDivider from '../../common/DetailsPanelSectionDivider';
import StyledTable, {
  StyledTableRow,
  TableSchema as StyledTableSchema,
} from '../../common/layout/StyledTable';
import { capitaliseFirstLetter, getPriorityIcon } from '../../common/utils';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import { TrashIcon } from '@heroicons/react/24/solid';
import SlideOver from './SlideOver';
import {
  buttonClass,
  ShowProjectSlideOverButton,
  SlideOverProjectContent,
  SlideOverProjectMeta,
} from './HelperFunctions';
import PrimaryButton from '../../common/PrimaryButton';
import { enumTranslates } from '../../common/i18n/translate';
import { EntityType } from '../../api/index';

type Props = {
  selectedScenario: Scenario | undefined;
};

function WhatsIncluded({ selectedScenario }: Props): React.ReactElement {
  const [selectedProgramId, setSelectedProgramId] = useState('program-1');
  const [showSlideOver, setShowSlideOver] = useState(false);
  const [slideOverProjectId, setSlideOverProject] = useState('');

  const sandboxPortfolio = selectedScenario?.referencePortfolio;
  const sandboxProgram = sandboxPortfolio?.programs.find(
    program => program.programId === selectedProgramId
  );
  const sandboxProjects =
    sandboxProgram && sandboxProgram.projects.length > 0
      ? sandboxProgram.projects
      : [];
  const sandboxProjectsSorted = _.orderBy(
    [...sandboxProjects],
    ['tier'],
    ['asc']
  );

  const slideOverProject = sandboxProjectsSorted.find(
    project => project.projectId === slideOverProjectId
  );

  function handleSaveScenarioClick() {
    console.log('Placeholder: Save scenario');
    return;
  }

  const scenariosTitle = (
    <DetailsPanelHeader
      title={`What's Included`}
      description={`Which projects are in or out, by program`}
    />
  );

  const scenariosButton = (
    <React.Fragment>
      <PrimaryButton
        icon="save"
        label="Save scenario"
        onClick={handleSaveScenarioClick}
        disabled={false}
      />

      {/* <button
        type="button"
        //   disabled={isDemo}
        className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm whitespace-nowrap text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        onClick={handleSaveScenarioClick}
      >
        <FloppyDisk
          weight="fill"
          className="-ml-1 mr-2 h-5 w-5"
          aria-hidden="true"
        />
        Save scenario
      </button> */}
    </React.Fragment>
  );

  function handleUpdateSelection(value: string) {
    setSelectedProgramId(value);
  }

  const iconProgram = (
    <FolderSimple className="w-6 h-6 flex-shrink-0 text-gray-500 group-hover:text-primary-500" />
  );

  const buttonClassDelete = `h-min disabled:opacity-50 ml-auto inline-flex p-1 rounded-full text-gray-400 hover:bg-red-100 hover:text-red-500 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`;

  function handleRankIncreaseClick(sandboxProjectId: string) {
    console.log(
      `Placeholder: Increase rank of project (id: ${sandboxProjectId})`
    );
    return;
  }

  function rankIncreaseButton(sandboxProjectId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClass}
          onClick={() => handleRankIncreaseClick(sandboxProjectId)}
          data-tip
          data-for={`${sandboxProjectId}-rankUp`}
        >
          <CaretUp weight="bold" className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${sandboxProjectId}-rankUp`}
          place="top"
          // type="info"
          effect="solid"
          // delayShow={tooltipDelay}
        >
          <div className="text-sm">
            <p>{`Move up`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  function handleRankDecreaseClick(sandboxProjectId: string) {
    console.log(
      `Placeholder: Decrease rank of project (id: ${sandboxProjectId})`
    );
    return;
  }

  function rankDecreaseButton(sandboxProjectId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClass}
          onClick={() => handleRankDecreaseClick(sandboxProjectId)}
          data-tip
          data-for={`${sandboxProjectId}-rankDown`}
        >
          <CaretDown weight="bold" className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${sandboxProjectId}-rankDown`}
          place="top"
          // type="info"
          effect="solid"
          // delayShow={tooltipDelay}
        >
          <div className="text-sm">
            <p>{`Move down`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  function handleMakeInactiveClick(sandboxProjectId: string) {
    console.log(`Placeholder: Make project inactive (id: ${sandboxProjectId})`);
    return;
  }

  function makeInactiveButton(sandboxProjectId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClass}
          onClick={() => handleMakeInactiveClick(sandboxProjectId)}
          data-tip
          data-for={`${sandboxProjectId}-makeInactive`}
        >
          <X weight="bold" className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${sandboxProjectId}-makeInactive`}
          place="top"
          // type="info"
          effect="solid"
          // delayShow={tooltipDelay}
        >
          <div className="text-sm">
            <p>{`Move to excluded list`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  function handleMakeActiveClick(sandboxProjectId: string) {
    console.log(`Placeholder: Make project active (id: ${sandboxProjectId})`);
    return;
  }

  function makeActiveButton(sandboxProjectId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClass}
          onClick={() => handleMakeActiveClick(sandboxProjectId)}
          data-tip
          data-for={`${sandboxProjectId}-makeActive`}
        >
          <Plus weight="bold" className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${sandboxProjectId}-makeActive`}
          place="top"
          // type="info"
          effect="solid"
          // delayShow={tooltipDelay}
        >
          <div className="text-sm">
            <p>{`Move to included list`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  function handleDeleteProjectClick(sandboxProjectId: string) {
    console.log(`Placeholder: Delete project (id: ${sandboxProjectId})`);
    return;
  }

  function deleteProjectButton(sandboxProjectId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClassDelete}
          onClick={() => handleDeleteProjectClick(sandboxProjectId)}
          data-tip
          data-for={`${sandboxProjectId}-deleteProject`}
        >
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${sandboxProjectId}-deleteProject`}
          place="top"
          // type="info"
          effect="solid"
          // delayShow={tooltipDelay}
        >
          <div className="text-sm">
            <p>{`Delete project...`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  const includedSchema: StyledTableSchema = {
    columns: [
      {
        label: 'Rank',
        key: 'rank',
        widthClass: 'w-16',
      },
      {
        label: 'Tier',
        key: 'tier',
        widthClass: 'w-16',
      },
      {
        label: `Included ${capitaliseFirstLetter(
          enumTranslates[EntityType.Project]
        )}s`,
        key: 'includedProjects',
      },
      {
        label: 'Actions',
        key: 'actions',
        widthClass: 'w-40',
      },
    ],
  };

  const excludedSchema: StyledTableSchema = {
    columns: [
      {
        label: `Excluded ${capitaliseFirstLetter(
          enumTranslates[EntityType.Project]
        )}s`,
        key: 'excludedProjects',
      },
      {
        label: 'Actions',
        key: 'actions',
        widthClass: 'w-20',
      },
    ],
  };

  function projectToFormattedRow(
    project: DummyProject | null,
    rank?: number
  ): StyledTableRow {
    let id = '';
    let cellLayout = <React.Fragment></React.Fragment>;

    if (project) {
      id = project.projectId;
      cellLayout = (
        <div className="text-sm font-semibold -mr-3">{project.name}</div>
      );
    } else {
      id = 'noId';
      cellLayout = (
        <div className="text-sm italic text-gray-400 -mr-3">
          No projects found
        </div>
      );
    }

    const output: StyledTableRow = {
      id: id,
      cells: [],
      canExpand: false,
    };

    // Layout if active project
    if (project && project.isActive) {
      // Rank
      output.cells.push({
        content: (
          <div className="text-sm font-semibold">
            {rank ? rank : ''}.{/* {project.rankInProgram}. */}
          </div>
        ),
      });
      // Tier
      output.cells.push({
        content: <div>{getPriorityIcon(project.tier)}</div>,
      });
      // Project name for included projects
      output.cells.push({
        content: cellLayout,
      });
      // Actions
      output.cells.push({
        content: (
          <div className="flex gap-x-2">
            <span>
              {ShowProjectSlideOverButton(
                project.projectId,
                setShowSlideOver,
                setSlideOverProject
              )}
            </span>
            <span>{rankIncreaseButton(project.projectId)}</span>
            <span>{rankDecreaseButton(project.projectId)}</span>
            <span>{makeInactiveButton(project.projectId)}</span>
          </div>
        ),
      });
    } else {
      // Project name for excluded projects
      output.cells.push({
        content: cellLayout,
      });
      // Actions, if there is a project
      if (project && !project.isActive) {
        output.cells.push({
          content: (
            <div className="flex gap-x-2">
              <span>
                {ShowProjectSlideOverButton(
                  project.projectId,
                  setShowSlideOver,
                  setSlideOverProject
                )}
              </span>
              <span>{makeActiveButton(project.projectId)}</span>
              <span>{deleteProjectButton(project.projectId)}</span>
            </div>
          ),
        });
      }
    }

    return output;
  }

  function getProjectRows(isActive: boolean): StyledTableRow[] {
    // return sandboxProjectsSorted &&
    //   sandboxProjectsSorted.filter(item => item.isActive === isActive).length >
    //     0
    //   ? sandboxProjectsSorted
    //       .filter(item => item.isActive === isActive)
    //       .map(item => projectToFormattedRow(item))
    //   : [projectToFormattedRow(null)];

    return sandboxProjectsSorted
      .filter(item => item.isActive === isActive)
      .map((item, index) => projectToFormattedRow(item, index + 1));
  }

  const slideOver = (
    <SlideOver
      open={showSlideOver}
      setOpen={setShowSlideOver}
      title={slideOverProject ? slideOverProject.name : ''}
      meta={SlideOverProjectMeta(slideOverProject)}
      content={SlideOverProjectContent(slideOverProject)}
    />
  );

  const scenariosContent = (
    <div className="flex flex-col space-y-6">
      <div>
        <DetailsPanelSectionDivider
          title={capitaliseFirstLetter(enumTranslates[EntityType.Program])}
        />
        <div className="w-full lg:max-w-md mx-auto">
          {/* Program selector */}
          <div className="flex w-full">
            <span
              className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center"
              data-tip
              data-for={'look-back-icon'}
            >
              {iconProgram}
            </span>
            <select
              className="block grow rounded-r-md pl-3 pr-8 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              defaultValue={sandboxPortfolio?.programs[0].name}
              onChange={e => handleUpdateSelection(e.target.value)}
            >
              {sandboxPortfolio?.programs.map((item, idx) => (
                <option key={idx} value={item.programId}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-2 ml-12 px-3 text-sm">
            {sandboxProgram?.outcome}
          </div>
        </div>
      </div>

      {/* Project list */}
      <div>
        <DetailsPanelSectionDivider
          title={`${capitaliseFirstLetter(
            enumTranslates[EntityType.Project]
          )}s`}
        />
        <div className="flex flex-col md:flex-row w-full gap-4">
          <div className="w-full md:w-2/3">
            <StyledTable
              schema={includedSchema}
              rowData={getProjectRows(true)}
              noRowsMessage={`No included projects`}
              // clickedRowId={setSelectedScenarioId}
            />
          </div>
          <div className="w-full md:w-fit md:grow">
            <div className="flex flex-col gap-y-3">
              <StyledTable
                schema={excludedSchema}
                rowData={getProjectRows(false)}
                noRowsMessage={`No excluded projects`}
                // clickedRowId={setSelectedScenarioId}
              />
              <div className="flex">
                <span className="mx-auto">
                  <PrimaryButton
                    icon="plus"
                    label={`New ${enumTranslates[EntityType.Project]}`}
                    onClick={() => null}
                    disabled={false}
                  />
                  {/* <button
                    type="button"
                    className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm whitespace-nowrap text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    // onClick={handleButtonClick}
                  >
                    <PlusIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    New project
                  </button> */}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Slideover */}
      {slideOver}
    </div>
  );

  return (
    <DetailsPanel
      headerLeft={scenariosTitle}
      headerRight={scenariosButton}
      content={scenariosContent}
    />
  );
}

export default WhatsIncluded;
