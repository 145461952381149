import { Question } from 'phosphor-react';
import React from 'react';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';

function SetStrategy(): React.ReactElement {
  const icon = <Question className="mx-auto h-12 w-12 text-gray-300" />;

  const reportingPlaceholderContent = (
    <>
      <div className="text-center">
        {icon}
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          Coming soon...
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          We'll let you know when this is ready
        </p>
      </div>
    </>
  );

  const scenariosTitle = (
    <DetailsPanelHeader
      title={`Strategy`}
      description={`Update your organisational strategy`}
    />
  );

  const scenariosContent = (
    <div className="flex-col space-y-6">{reportingPlaceholderContent}</div>
  );

  return (
    <DetailsPanel
      headerLeft={scenariosTitle}
      headerRight={null}
      content={scenariosContent}
    />
  );
}

export default SetStrategy;
