import React, { useContext, useEffect, useState } from 'react';
import PortfolioObjectivesTable from './PortfolioObjectivesView';
import { PortfolioPageContext } from '../PortfolioPageContext';
import WizardButton from '../../../common/wizard/WizardButton';
import EmptyState from '../../../common/layout/EmptyState';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import DetailsPanel from '../../../common/DetailsPanel';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import {
  EntityType,
  Group,
  Portfolio,
  // useRemovePortfolioMutation,
} from '../../../api/index';
import { useNavigate } from 'react-router-dom';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { HOME } from '../../../common/routes';
import Spinner from '../../../common/SpinnerThemed';
import Modal from '../../../common/Modal';
import { Menu } from '@headlessui/react';
import { classNames } from '../../../common/utils';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { BetaContext } from '../../../context/BetaContext';
import TagsPanel from '../TagsPanel';
import { CheckCircle, Circle, Trash } from 'phosphor-react';
import PortfolioMembershipTable from './PortfolioMembershipTable';
import Banner from '../../../common/Banner';

function OverflowMenu(portfolio: Portfolio | null) {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');

  const navigate = useNavigate();

  // const [removePortfolioMutation, { data, loading }] =
  //   useRemovePortfolioMutation();

  const handleDeleteClick = (portfolio: Portfolio | null) => {
    if (portfolio != null) {
      try {
        // removePortfolioMutation({
        //   variables: {
        //     id: portfolio.id,
        //   },
        // });
      } catch (e) {
        toast.error((e as ApolloError).message);
      }
    }
    return;
  };

  // useEffect(() => {
  //   if (data?.removePortfolio) {
  //     toast.success(
  //       `Deleted ${enumTranslates[EntityType.Portfolio]} '${
  //         portfolio?.name ? portfolio.name : ''
  //       }'`
  //     );
  //     navigate(`${HOME}`);
  //   }
  // }, [data, navigate, portfolio]);

  if (portfolio != null) {
    const iconDelete = <Trash weight="bold" className="h-5 w-5 text-red-600" />;
    const menuOptions = [
      {
        display: `Delete this ${enumTranslates[EntityType.Portfolio]}...`,
        icon: iconDelete,
        action: () => setModalOpen(true),
      },
    ];

    return (
      <div className="flex items-center group relative">
        {modalOpen && (
          <Modal width={'w-full max-w-xl'} onClose={() => setModalOpen(false)}>
            <div className="flex flex-col space-y-4 bg-white p-4 overflow-y-auto align-middle">
              <div className="flex flex-row items-center">
                <span className="flex p-2 rounded-full bg-red-600 align-middle items-center">
                  <Trash
                    weight="fill"
                    className="h-5 w-5 text-white mx-auto my-auto"
                  />
                </span>
                <h3 className="text-lg font-medium ml-3 text-gray-900">
                  Confirm Delete
                </h3>
              </div>
              <p className="mt-3 text-sm text-gray-500">
                {`Deleting a ${
                  enumTranslates[EntityType.Portfolio]
                } cannot be undone. Impacts also include the following:`}
                <ul className="list-disc list-outside ml-4 mt-2 text-sm text-gray-500">
                  <li>
                    {`All ${
                      enumTranslates[EntityType.Portfolio]
                    } members will lose access to this ${
                      enumTranslates[EntityType.Portfolio]
                    }`}
                  </li>
                  <li>
                    {`All ${enumTranslates[EntityType.Program]}s and ${
                      enumTranslates[EntityType.Project]
                    }s in this ${
                      enumTranslates[EntityType.Portfolio]
                    } will be deleted,
                    along with their data`}
                  </li>
                </ul>
              </p>
              <p className="mt-3 text-sm text-gray-500">
                {`If you still want to proceed, type "DELETE ${portfolio.name.toUpperCase()}" below, then press the "Delete ${
                  enumTranslates[EntityType.Portfolio]
                }" button.`}
              </p>
              <input
                type="text"
                className="block w-full shadow-sm focus:ring-red-600 focus:border-red-600 sm:text-sm border-gray-300 rounded-md"
                onChange={e => setDeleteInputValue(e.target.value)}
                placeholder={`Type "DELETE ${portfolio.name.toUpperCase()}" here`}
                value={deleteInputValue}
              />
              <span className="text-right space-x-3">
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  disabled={
                    deleteInputValue !==
                    `DELETE ${portfolio.name.toUpperCase()}`
                  }
                  onClick={() => handleDeleteClick(portfolio)}
                >
                  Delete {enumTranslates[EntityType.Portfolio]}
                </button>
              </span>
            </div>
          </Modal>
        )}
        <Menu>
          <Menu.Button className="inline-flex p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </Menu.Button>
          <Menu.Items className="w-fit origin-top-right absolute right-0 mt-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {/* Use the `active` render prop to conditionally style the active item. */}
            {menuOptions.map((item, idx) => (
              <Menu.Item key={idx}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-700',
                      'flex w-full items-center px-4 py-2 text-sm'
                    )}
                    onClick={item.action}
                  >
                    <span className="inline-block mr-3 ">{item.icon}</span>
                    <span className="inline-block whitespace-nowrap">
                      {item.display}
                    </span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    );
  }
}

function AdminTab(): React.ReactElement {
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const portfolio = portfolioPageState.selectedPortfolio;
  const { isBeta } = useContext(BetaContext);

  // Get list of who can edit the current portfolio

  // Check current user permisssion level
  const hasEditPermissions =
    portfolio?.group === Group.Owner || portfolio?.group === Group.Editor;
  const hasOwnerPermissions = portfolio?.group === Group.Owner;

  const pillarsAndObjectivesTitle = (
    <DetailsPanelHeader
      title={`${translate(
        strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
        'Strategic Objectives'
      )}`}
      description={`What success looks like for this ${
        enumTranslates[EntityType.Portfolio]
      }`}
    />
  );

  const invitations = { invitations: portfolioPageState.invitations };
  const editObject = {
    ...portfolio,
    ...invitations,
  };

  const visionButton = (
    <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
      <span className="sm:ml-3">
        {/* {portfolio && hasEditPermissions && (
          <WizardButton
            title={translate(strings.WIZARD_EDIT_PORTFOLIO, 'Edit Portfolio')}
            editObject={editObject}
            parentObject={portfolio.workspace}
            type="PORTFOLIO_DETAILS"
            quickSave={true}
            startStep={1}
          />
        )} */}
      </span>

      {/* For now, hide entire overflow menu since there is only a delete item. 
      Later this will be per item in this menu */}
      {portfolio && hasOwnerPermissions && OverflowMenu(portfolio)}
    </div>
  );

  const pillarsAndObjectivesContent =
    portfolio?.themes == undefined || portfolio?.themes.length == 0 ? (
      <EmptyState
        type="strategic pillars"
        secondaryText="Get started by adding a strategic pillar."
      />
    ) : portfolio?.objectives == undefined ||
      portfolio?.objectives.length == 0 ? (
      <EmptyState
        type="strategic objectives"
        secondaryText="Get started by adding a strategic objective."
      />
    ) : (
      <PortfolioObjectivesTable />
    );

  const visionTitle = (
    <DetailsPanelHeader
      title={'Vision & Mission'}
      description={translate(
        strings.STRATEGIC_VISION_DESCRIPTION,
        'Strategic Vision Description'
      )}
    />
  );

  const visionContent = (
    <div className="grid grid-cols-1 justify-between lg:space-x-6 lg:grid-cols-2 space-y-3 lg:space-y-0">
      <div className="col-span-1">
        <p className="text-sm font-semibold text-gray-900">Our Vision</p>
        {portfolio?.vision ? (
          <p className="mt-1 text-sm text-gray-600">{portfolio?.vision}</p>
        ) : (
          <p className="mt-1 text-sm italic text-gray-400">Not provided</p>
        )}
      </div>
      <div className="col-span-1">
        <p className="text-sm font-semibold text-gray-900">Our Mission</p>
        {portfolio?.mission ? (
          <p className="mt-1 text-sm text-gray-600">{portfolio?.mission}</p>
        ) : (
          <p className="mt-1 text-sm italic text-gray-400">Not provided</p>
        )}
      </div>
    </div>
  );

  const membersTitleBeta = (
    <DetailsPanelHeader
      title={translate(strings.ROLES_PERMISSIONS, 'Roles & Permissions')}
      description={`Who has access to this ${
        enumTranslates[EntityType.Portfolio]
      }`}
    />
  );

  const membersContentBeta =
    portfolio?.members?.items && portfolio?.members?.items.length === 0 ? (
      <EmptyState type="members" />
    ) : (
      <PortfolioMembershipTable />
    );

  const doneIcon = (
    <CheckCircle
      className={`w-5 h-5 flex-shrink-0 text-green-500`}
      weight="fill"
    />
  );
  const tbdIcon = (
    <Circle className={`w-5 h-5 flex-shrink-0 text-gray-400`} weight="bold" />
  );

  // Calculate setup progress
  const progressChecklist = [
    portfolio?.vision != undefined && portfolio?.vision.length > 0,
    portfolio?.mission != undefined && portfolio?.mission.length > 0,
    portfolio?.themes && portfolio.themes.length > 0,
    portfolio?.objectives && portfolio.objectives.length > 0,
    portfolio?.programs && portfolio.programs.length > 0,
    (portfolio?.members?.items && portfolio.members.items.length > 1) ||
      (portfolioPageState.invitations &&
        portfolioPageState.invitations.length > 0),
  ];

  const completedSteps = progressChecklist.reduce(
    (partialSum, a) => partialSum + (a == true ? 1 : 0),
    0
  );

  return (
    <div className="flex flex-col gap-y-6">
      {/* Banner */}
      {/* Only show if there are outstanding steps */}
      {hasEditPermissions && completedSteps != progressChecklist.length && (
        <Banner
          colour="blue"
          summary={`Setup progress: ${completedSteps} of ${progressChecklist.length} steps completed`}
          content={
            <ul className="list-outside space-y-1">
              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[0] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[0] ? doneIcon : tbdIcon} Add vision statement
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[1] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[1] ? doneIcon : tbdIcon} Add mission
                statement
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[2] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[2] ? doneIcon : tbdIcon} Add{' '}
                {translate(
                  strings.PORTFOLIO_STRATEGIC_PRIORITIES,
                  'Strategic Pillars'
                ).toLowerCase()}
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[3] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[3] ? doneIcon : tbdIcon} Set{' '}
                {translate(
                  strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                  'Strategic Objectives'
                ).toLowerCase()}
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[4] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[4] ? doneIcon : tbdIcon} Create a{' '}
                {enumTranslates[EntityType.Program]}
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[5] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[5] ? doneIcon : tbdIcon} Invite contributors
              </li>
            </ul>
          }
        />
      )}

      {/* Vision */}
      <DetailsPanel
        headerLeft={visionTitle}
        headerRight={visionButton}
        content={visionContent}
      />

      {/* Objectives */}
      <DetailsPanel
        headerLeft={pillarsAndObjectivesTitle}
        headerRight={null}
        content={pillarsAndObjectivesContent}
      />

      {/* Member */}
      <DetailsPanel
        headerLeft={membersTitleBeta}
        headerRight={null}
        content={membersContentBeta}
      />

      {/* Tags */}
      {isBeta ? <TagsPanel portfolio={portfolio} /> : null}
    </div>
  );
}

export default AdminTab;
