import React, { MouseEvent } from 'react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import { Copy } from 'phosphor-react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import StyledTable, {
  StyledTableRow,
  TableSchema,
} from '../../common/layout/StyledTable';
import { getLocalDate } from '../../common/utils';
import { Scenario } from './dummyData';
import PrimaryButton from '../../common/PrimaryButton';
import { enumTranslates } from '../../common/i18n/translate';
import { EntityType } from '../../api/index';
import { avatarBgColor } from '../../common/constants';

type Props = {
  scenarioList: Scenario[];
  selectedScenarioId: string;
  setSelectedScenarioId: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTabIndex?: React.Dispatch<React.SetStateAction<number>>;
};

function Scenarios({
  scenarioList,
  selectedScenarioId,
  setSelectedScenarioId,
  setSelectedTabIndex: setSelectedIndex,
}: Props): React.ReactElement {
  // Get data

  function handleNewScenarioClick() {
    console.log('Placeholder: Create new scenario');
    return;
  }

  function handleEditScenarioClick(
    event: MouseEvent<HTMLElement>,
    scenarioId: string
  ) {
    // event.preventDefault;
    console.log(`Placeholder: Edit this scenario (id: ${scenarioId})`);
    setSelectedScenarioId(scenarioId);
    if (setSelectedIndex) {
      setSelectedIndex(1);
    }
    return;
  }

  function handleDuplicateScenarioClick(scenarioId: string) {
    console.log(`Placeholder: Duplicate this scenario (id: ${scenarioId})`);

    return;
  }

  function handleDeleteScenarioClick(scenarioId: string) {
    console.log(`Placeholder: Delete this scenario (id: ${scenarioId})`);

    return;
  }

  const scenariosTitle = (
    <DetailsPanelHeader
      title={`Scenario Manager`}
      description={`Create and edit scenarios`}
    />
  );

  const scenariosButton = (
    <React.Fragment>
      <PrimaryButton
        icon="plus"
        label="New scenario"
        onClick={handleNewScenarioClick}
        disabled={false}
      />

      {/* <button
        type="button"
        //   disabled={isDemo}
        className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm whitespace-nowrap text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        onClick={handleNewScenarioClick}
      >
        <Plus
          className="-ml-1 mr-2 h-5 w-5"
          weight="regular"
          aria-hidden="true"
        />
        New scenario
      </button> */}
    </React.Fragment>
  );

  const schema: TableSchema = {
    columns: [
      {
        label: '',
        key: 'isSelected',
        widthClass: 'w-10',
      },
      {
        label: 'Scenario Name',
        key: 'scenarioName',
      },
      {
        label: `Reference ${enumTranslates[EntityType.Portfolio]}`,
        key: 'referencePortfolio',
      },
      {
        label: 'Owner',
        key: 'scenarioOwner',
      },
      {
        label: 'Created',
        key: 'createdDate',
        widthClass: 'w-28',
      },
      {
        label: 'Modified',
        key: 'modifiedDate',
        widthClass: 'w-28',
      },
      {
        label: 'Actions',
        key: 'actions',
        widthClass: 'w-16',
      },
    ],
  };

  const buttonClass = `h-min disabled:opacity-50 ml-auto inline-flex p-1 rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`;
  const buttonClassDelete = `h-min disabled:opacity-50 ml-auto inline-flex p-1 rounded-full text-gray-400 hover:bg-red-100 hover:text-red-500 hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`;

  function editScenarioButton(scenarioId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClass}
          onClick={e => handleEditScenarioClick(e, scenarioId)}
          data-tip
          data-for={`${scenarioId}-edit`}
        >
          <PencilIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${scenarioId}-edit`}
          place="top"
          // type="info"
          effect="solid"
          // delayShow={tooltipDelay}
        >
          <div className="text-sm">
            <p>{`Edit scenario`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  function duplicateScenarioButton(scenarioId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClass}
          onClick={() => handleDuplicateScenarioClick(scenarioId)}
          data-tip
          data-for={`${scenarioId}-duplicate`}
        >
          <Copy weight="bold" className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${scenarioId}-duplicate`}
          place="top"
          // type="info"
          effect="solid"
          // delayShow={tooltipDelay}
        >
          <div className="text-sm">
            <p>{`Duplicate scenario`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  function deleteScenarioButton(scenarioId: string): React.ReactElement {
    const button = (
      <React.Fragment>
        <button
          type="button"
          // disabled={isDemo}
          className={buttonClassDelete}
          onClick={() => handleDeleteScenarioClick(scenarioId)}
          data-tip
          data-for={`${scenarioId}-delete`}
        >
          <TrashIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <ReactTooltip
          id={`${scenarioId}-delete`}
          place="top"
          // type="info"
          effect="solid"
        >
          <div className="text-sm">
            <p>{`Delete scenario...`}</p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );

    return button;
  }

  function scenarioToFormattedRow(scenario: Scenario): StyledTableRow {
    const output = {
      id: scenario.id,
      cells: [
        {
          content: (
            <div className="text-sm font-semibold w-min -mr-3 mb-1 items-center">
              {
                <input
                  id={`${scenario.id}-radio"`}
                  type="radio"
                  className="w-4 h-4 mr-2 text-primary-600 bg-gray-100 border-gray-300 focus:ring-primary-600 ring-offset-primary-600 focus:ring-2"
                  checked={selectedScenarioId === scenario.id}
                />
              }
            </div>
          ),
        },
        {
          content: <div className="text-sm font-semibold">{scenario.name}</div>,
        },
        {
          content: (
            <div className="text-sm">{scenario.referencePortfolio.name}</div>
          ),
        },
        {
          content: (
            <div className="text-sm space-x-2 flex items-center">
              <Avatar
                name={scenario.ownerName}
                size="32"
                round="16px"
                color={avatarBgColor}
              />
              <span className="">{scenario.ownerName}</span>
            </div>
          ),
        },
        {
          content: (
            <div className="text-sm whitespace-nowrap">
              {getLocalDate(scenario.createdDate)}
            </div>
          ),
        },
        {
          content: (
            <div className="text-sm whitespace-nowrap">
              {getLocalDate(scenario.modifiedDate)}
            </div>
          ),
        },
        {
          content: (
            <div className="flex gap-x-2">
              <span>{editScenarioButton(scenario.id)}</span>
              <span>{duplicateScenarioButton(scenario.id)}</span>
              <span>{deleteScenarioButton(scenario.id)}</span>
            </div>
          ),
        },
      ],
      canExpand: false,
    };

    return output;
  }

  const rows: StyledTableRow[] = scenarioList.map(item =>
    scenarioToFormattedRow(item)
  );

  const scenariosContent = (
    <div className="flex-col space-y-6">
      {/* Create and select existing scenarios for editing, based on existing
        portfolios the user has access to. Set when a scenario will become the
        new source of truth. */}
      <StyledTable
        schema={schema}
        rowData={rows}
        clickedRowId={setSelectedScenarioId}
        noRowsMessage={`No scenarios found`}
      />
    </div>
  );

  return (
    <DetailsPanel
      headerLeft={scenariosTitle}
      headerRight={scenariosButton}
      content={scenariosContent}
    />
  );
}

export default Scenarios;
