import React, { useCallback, useContext } from 'react';
import { capitaliseFirstLetter, classNames } from '../../common/utils';
import {
  EntityType,
  useSetLastViewedWorkspaceMutation,
  Workspace,
} from '../../api/index';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { DemoContext } from '../../context/DemoContext';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import { PencilSimple } from 'phosphor-react';
import Spinner from '../../common/SpinnerThemed';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { enumTranslates } from '../../common/i18n/translate';
import Tag from '../../common/Tag';
import { Link } from 'react-router-dom';
import { WORKSPACE } from '../../common/routes';

function WorkspacesTab(props: {
  setWorkspaceState: React.Dispatch<
    React.SetStateAction<Workspace | null | undefined>
  >;
}): React.ReactElement {
  const { isDemo } = useContext(DemoContext);

  const [setLastViewedWorkspace, { loading }] =
    useSetLastViewedWorkspaceMutation();
  const { state: workspacePageState, dispatch } =
    useContext(WorkspacePageContext);

  const handleworkspaceChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (id: any) => {
      const selectedworkspaceId = id;
      const selectedWorkspace = workspacePageState.workspaces.find(
        w => w.id === selectedworkspaceId
      );
      if (selectedWorkspace) {
        try {
          await setLastViewedWorkspace({
            variables: {
              workspaceId: selectedWorkspace.id,
            },
          });
          dispatch({
            type: 'SET_SELECTED_WORKSPACE',
            workspace: selectedWorkspace,
          });
          props.setWorkspaceState(selectedWorkspace);
        } catch (e) {
          toast.error((e as ApolloError).message);
        }
      }
    },
    [dispatch, props, setLastViewedWorkspace, workspacePageState.workspaces]
  );

  // TODO: Add a pop up so that a user can rename the workspace
  // The button should only be visible if the user owns the workspace
  // We need a way to get the workspace owner
  function showRenameWorkspaceModal(workspaceId: string) {
    // Placeholder
    console.log(workspaceId);
    return;
  }

  const workspacesTitle = (
    <DetailsPanelHeader
      title={`Available ${capitaliseFirstLetter(
        enumTranslates[EntityType.Workspace]
      )}s`}
      description={`${capitaliseFirstLetter(
        enumTranslates[EntityType.Workspace]
      )}s you own or have been invited to`}
    />
  );

  const workspacesContent = loading ? (
    <div className="flex flex-col align-middle items-center">
      <Spinner text={'Loading data...'} />
    </div>
  ) : (
    <React.Fragment>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
        {/* If demo mode is active, only show a placeholder time for the demo workspace */}
        {isDemo ? (
          <div
            className={classNames(
              '',
              'bg-secondary-800 text-sm col-span-1 h-32 rounded px-4 py-3'
            )}
          >
            <div className="flex flex-row justify-between items-center">
              <span className="font-semibold text-white">Demo Workspace</span>
            </div>
            <div className="mt-3 align-middle space-x-2">
              <span className="rounded text-xs px-2 py-1 text-white bg-primary-500">
                Current
              </span>
              <span className="rounded text-xs bg-gray-300 px-2 py-1">
                Demo
              </span>
              )
            </div>
          </div>
        ) : (
          // If not in demo mode, show the user's available workspaces
          workspacePageState?.workspaces?.map((workspace: Workspace) => {
            return workspace.id === workspacePageState.selectedWorkspace?.id ? (
              <div
                className={classNames(
                  'bg-secondary-800 text-sm col-span-1 h-32 rounded px-4 py-3'
                )}
                key={workspace.id}
              >
                <div className="flex flex-row justify-between items-center">
                  <span className="font-semibold text-white py-1">
                    {workspace.name}
                  </span>

                  {/* Only show rename button if the user owns this workspace */}
                  {workspacePageState.userWorkspace === workspace.id ? (
                    <button
                      className={classNames(
                        workspace.id ===
                          workspacePageState.selectedWorkspace?.id
                          ? 'hover:bg-gray-600'
                          : ' hover:bg-gray-500',
                        'w-min rounded-full hidden' // Hidden for now
                      )}
                      onClick={() => showRenameWorkspaceModal(workspace.id)}
                    >
                      <PencilSimple
                        weight="bold"
                        className="h-5 w-5 text-white m-1"
                        aria-hidden="true"
                      />
                    </button>
                  ) : null}
                </div>
                <div className="flex flex-wrap mt-3 align-middle gap-x-2 gap-y-1">
                  <Tag
                    type="custom"
                    label="Current"
                    bgColour="bg-primary-500"
                    textColour="text-white"
                  />
                  {/* <span className="rounded text-xs px-2 py-1 text-white bg-primary-500">
                    Current
                  </span> */}

                  {/* Only show this tag if the user owns this workspace */}

                  {workspacePageState.userWorkspace === workspace.id && (
                    <Tag
                      type="custom"
                      label={`My ${enumTranslates[EntityType.Workspace]}`}
                      bgColour="bg-gray-300"
                      textColour="text-black"
                    />

                    // <span className="inline-block rounded text-xs bg-gray-300 px-2 py-1 whitespace-nowrap">
                    //   {`My ${enumTranslates[EntityType.Workspace]}`}
                    // </span>
                  )}
                </div>
                <div className="flex flex-wrap mt-3 align-bottom right gap-x-2 gap-y-1">
                  {/* Only show this tag if the user owns this workspace */}
                  {workspacePageState.userWorkspace === workspace.id ? (
                    <span className="ml-auto text-xs px-2 py-1">
                      {/* <WizardButton
                        title={translate(
                          strings.WIZARD_NEW_WORKSPACE,
                          'Workspace Setup'
                        )}
                        editObject={workspacePageState?.selectedWorkspace}
                        parentObject={workspacePageState?.billingAccount}
                        type="WORKSPACE_DETAILS"
                        startStep={1}
                      /> */}
                      <Link to={`${WORKSPACE}/${workspace.id}`}>
                        <PencilSimple
                          className="hover:text-gray-600 cursor-pointer -ml-0.5 mr-2 h-5 w-5 text-gray-500"
                          weight="bold"
                          aria-hidden="true"
                        />
                      </Link>
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <div
                className={classNames(
                  'bg-gray-500 hover:bg-gray-600 cursor-pointer',
                  'text-sm col-span-1 h-32 rounded px-4 py-3'
                )}
                key={workspace.id}
                onClick={() => handleworkspaceChange(workspace.id)}
              >
                <div className="flex flex-row justify-between items-center">
                  <span className="font-semibold text-white py-1">
                    {workspace.name}
                  </span>
                </div>
                <div className="mt-3 align-middle space-x-2">
                  {/* Only show this tag if the user owns this workspace */}
                  {workspacePageState.userWorkspace === workspace.id ? (
                    <span className="rounded text-xs bg-gray-300 px-2 py-1">
                      My Workspaces
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })
        )}

        {/* TODO: Add a new workspace (below is a placeholder) */}
        {/* <div
          className="text-sm col-span-1 h-40 rounded p-3 border-2 border-gray-200 border-dashed cursor-pointer hover:bg-gray-100"
          // onClick={() => createNewWorkspace}
        >
          <div className="flex flex-col h-full text-gray-500 italic align-middle justify-center items-center text-center space-y-2">
            {icon}
            <div>Add new workspace</div>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );

  return (
    <div className="flex flex-col space-y-6">
      <DetailsPanel
        headerLeft={workspacesTitle}
        headerRight={null}
        content={workspacesContent}
      />
    </div>
  );
}

export default WorkspacesTab;
