import React from 'react';
import { CaretDown, Info } from 'phosphor-react';
import { Disclosure } from '@headlessui/react';

type Props = {
  colour: 'yellow' | 'blue';
  summary: string;
  content: string | React.ReactElement;
};

export default function Banner({
  colour,
  summary,
  content,
}: Props): React.ReactElement {
  const icon = (
    <Info weight="regular" className="w-5 h-5 inline-block ml-1 mr-2 my-auto" />
  );

  const layout = (
    <Disclosure>
      {({ open }) => (
        <div className={` border-${colour}-200 rounded-md overflow-hidden`}>
          <Disclosure.Button
            className={`flex flex-row bg-${colour}-100 text-sm p-2 w-full text-left align-middle`}
          >
            {icon}
            <p className="inline-block">{summary}</p>
            {/* Use the `open` render prop to rotate the icon when the panel is open */}
            <CaretDown
              weight="bold"
              className={`${
                open ? 'transform rotate-180' : ''
              } w-4 h-4 inline-block ml-auto mr-1 my-auto`}
            />
          </Disclosure.Button>

          <Disclosure.Panel
            className={`bg-${colour}-100 border-${colour}-200 text-sm px-10 pb-2 w-full text-left`}
          >
            {content}
          </Disclosure.Panel>

          {/* Workaround: Hidden div to prevent Tailwind class shaking */}
          <div className="hidden">
            <div className="bg-blue-100 border-blue-200" />
            <div className="bg-yellow-100 border-yellow-200" />
          </div>
        </div>
      )}
    </Disclosure>
  );

  return layout;
}
