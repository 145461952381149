import React, { useState, useEffect } from 'react';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';

import { AuthErrorType } from '../../common/types';

import PrimaryButton from '../../common/PrimaryButton';
import { useUpdateMyWorkspaceMutation } from '../../api/index';
import { WorkspacePageContext } from './WorkspacePageContext';
import Spinner from '../../common/SpinnerThemed';

type AppState = {
  name: string;
  id: string;
  formType: string;
  errorText: string;
  changeSuccess: boolean;
};

function WorkspaceSettings(): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state } = React.useContext(WorkspacePageContext);
  const initialFormState: AppState = {
    name: state.selectedWorkspace?.name || '',
    id: '',
    formType: 'editProfile',
    errorText: '',
    changeSuccess: false,
  };
  const [validation, setValidation] = useState<boolean>(false);
  const [formState, updateFormState] = useState<AppState>(initialFormState);
  const [updateWorkspaceMutation, { data, loading }] =
    useUpdateMyWorkspaceMutation();
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const nonEmpty = formState.name && formState.name !== '' ? true : false;
    setValidation(nonEmpty);
    !nonEmpty
      ? (formState.errorText = '')
      : (formState.errorText = `Workspace name cannot be empty`);
  }, [formState]);

  const change = async () => {
    const { name } = formState;
    try {
      await updateWorkspaceMutation({
        variables: {
          input: {
            id: formState.id,
            name: formState.name,
          },
        },
      });
    } catch (error) {
      const errorT = error as AuthErrorType;
      updateFormState(() => ({
        ...formState,
        errorText: errorT.message,
      }));
    }
  };

  const AboutTitle = (
    <DetailsPanelHeader title={state.selectedWorkspace?.name || ''} />
  );

  const workspaceChangeButton = (
    <PrimaryButton
      icon="save"
      label="Update workspace"
      onClick={() => change()}
      disabled={!validation}
    />
  );

  const workspaceChangeContent = (
    <React.Fragment>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-3">
        <div className="col-span-1">
          <div className="w-full flex justify-between">
            <label
              htmlFor="name"
              className="block text-sm font-semibold text-gray-900 mb-1"
            >
              Workspace Name
            </label>
          </div>

          <input
            id="name"
            name="name"
            type={'text'}
            value={formState.name}
            onChange={inputChange}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          />
        </div>
      </div>
    </React.Fragment>
  );

  return loading ? (
    <div className="flex flex-col align-middle items-center">
      <Spinner text={'Saving...'} />
    </div>
  ) : (
    <div className="flex flex-col space-y-6 max-w-3xl mx-auto">
      <DetailsPanel
        headerLeft={AboutTitle}
        headerRight={workspaceChangeButton}
        content={workspaceChangeContent}
      />
    </div>
  );
}

export default WorkspaceSettings;
