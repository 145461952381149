import { ulid } from 'ulid';

export const WorkspaceDetailsPayload = (
  editObject: { [key: string]: unknown } | null,
  parentObject: { [key: string]: unknown } | undefined | null
): { [key: string]: unknown } => {
  // (1) and then we would update this return object to contain the existing values for those fields
  // values here correspond to the "key" in the input components
  if (editObject == null) {
    return {
      // New ask default settings
      //id: 'DUMMY_NEW_ASK_ID',
      id: ulid(),
      name: '',
    };
  } else
    return {
      id: editObject.id,
      name: editObject.name,
      portfolioCount: editObject.portfolioCount,
      programCount: editObject.programCount,
      projectCount: editObject.projectCount,
      plan: parentObject?.plan,
      status: parentObject?.status,
    };
};
