import React, {
  createRef,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { Tab, Menu } from '@headlessui/react';
import MeasuresLogicModel from '../../project/OverviewTab/MeasuresLogicModel';
import {
  capitaliseFirstLetter,
  classNames,
  getConfidenceColour,
  getLocalDate,
  getPreviousProjectUpdate,
  getRelativeTime,
} from '../../../common/utils';
import { ProjectPageContext } from '../../project/ProjectPageContext';
import {
  EntityType,
  ExceptionStatus,
  Group,
  OnBudgetStatus,
  OnTimeStatus,
  Project,
  ProjectMeasureStatus,
  ProjectMeasureUpdate,
  ProjectUpdate,
  useGetProjectUpdatesLazyQuery,
  useRemoveProjectUpdateMutation,
  useUpdateProjectUpdateMutation,
} from '../../../api/index';
import WizardButton from '../../../common/wizard/WizardButton';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import _ from 'lodash';
import Modal from '../../../common/Modal';
import { DemoContext } from '../../../context/DemoContext';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import DetailsPanelSectionDivider from '../../../common/DetailsPanelSectionDivider';
import ReactTooltip from 'react-tooltip';
import Spinner from '../../../common/SpinnerThemed';
import {
  ArrowDown,
  ArrowUp,
  CaretLeft,
  CaretRight,
  Check,
  Square,
  Trash,
} from 'phosphor-react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import SecondaryButton from '../../../common/SecondaryButton';
import ToggleButton from '../../../common/ToggleButton';
import WaypointSelector from './WaypointSelector';
import { getWaypoints, Waypoint } from './DummyWaypoints';
import { ulid } from 'ulid';
import PrimaryButton from '../../../common/PrimaryButton';
import TextBlock from '../TextBlock';

function OverflowMenu(
  update: ProjectUpdate | null,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (update !== null) {
    const iconDelete = <Trash weight="bold" className="h-5 w-5 text-red-600" />;

    const menuOptions = [
      {
        display: 'Delete selected review...',
        icon: iconDelete,
        action: () => setModalOpen(true),
      },
    ];

    return (
      <div className="flex items-center group relative">
        <Menu>
          <span className="my-1">
            <Menu.Button className="inline-flex p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
              <EllipsisVerticalIcon className="h-5 w-5" />
            </Menu.Button>
          </span>
          <Menu.Items className="origin-top-right absolute right-0 mt-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {/* Use the `active` render prop to conditionally style the active item. */}
            {menuOptions.map((item, index) => (
              <Menu.Item key={index}>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-700'
                    }  flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                    onClick={item.action}
                  >
                    <span className="inline-block mr-3">{item.icon}</span>
                    <span className="inline-block mr-3 whitespace-nowrap">
                      {item.display}
                    </span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    );
  }
}

function FilterMenu(
  selectedUpdate: ProjectUpdate | null,
  previousUpdate: ProjectUpdate | null,
  showCompleted: boolean,
  setShowCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  showPaused: boolean,
  setShowPaused: React.Dispatch<React.SetStateAction<boolean>>
) {
  function getProjectMeasuresByStatus(
    status: ProjectMeasureStatus
  ): Array<ProjectMeasureUpdate> {
    const updateMeasuresList: Array<ProjectMeasureUpdate> =
      selectedUpdate?.measures || [];
    const previousMeasuresList: Array<ProjectMeasureUpdate> =
      previousUpdate?.measures || [];

    const foundMeasures = updateMeasuresList.filter(
      measure =>
        measure.status === status &&
        previousMeasuresList?.find(
          pm => pm.measureId === measure.measureId && pm.status === status
        )
    );

    return foundMeasures;
  }

  if (selectedUpdate != null) {
    const iconShowCompleted = (
      <span className="h-5 w-5 flex justify-center mx-auto items-center">
        <input
          type="checkbox"
          checked={showCompleted}
          disabled={true}
          className={`rounded ${
            showCompleted
              ? `text-blue-800`
              : 'bg-white border-2 border-blue-800'
          }`}
        />
      </span>
    );
    const iconShowPaused = (
      <span className="h-5 w-5 flex justify-center mx-auto items-center">
        <input
          type="checkbox"
          checked={showPaused}
          disabled={true}
          className={`rounded ${
            showPaused ? `text-gray-400` : 'bg-white border-2 border-gray-400'
          }`}
        />
      </span>
    );

    const menuOptions = [
      {
        display: `Completed items`,
        icon: iconShowCompleted,
        tag: getProjectMeasuresByStatus(ProjectMeasureStatus.Done).length,
        action: () => setShowCompleted(!showCompleted),
      },
      {
        display: `Paused items`,
        icon: iconShowPaused,
        tag: getProjectMeasuresByStatus(ProjectMeasureStatus.Paused).length,
        action: () => setShowPaused(!showPaused),
      },
    ];

    return (
      <div className="flex items-center group relative">
        <Menu>
          <Menu.Button as={'div'}>
            {/* className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500" */}
            <SecondaryButton
              icon="filter"
              label="Show more..."
              onClick={() => null}
              disabled={false}
            />

            {/* <Funnel
              className="h-5 w-5 mr-2 text-gray-500 shrink-0"
              weight="fill"
            />
            <span>Show more...</span> */}
          </Menu.Button>
          <Menu.Items className="origin-top-right absolute right-0 mt-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {/* Use the `active` render prop to conditionally style the active item. */}
            {menuOptions.map(item => (
              <Menu.Item key={item.display}>
                {({ active }) => (
                  <button
                    className={`${
                      active
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-700'
                    }  flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                    onClick={item.action}
                  >
                    <span className="inline-block mr-3">{item.icon}</span>
                    <span className="inline-block mr-3 whitespace-nowrap">
                      {item.display}
                    </span>

                    {/* Show relevant count */}
                    <span className="inline-block text-center text-xs font-medium align-middle items-center justify-center rounded-full px-2 py-0 bg-gray-200 text-black">
                      {item.tag}
                    </span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    );
  }
}

function EntityPlan(): React.ReactElement {
  const { state: projectPageState } = useContext(ProjectPageContext);
  const { isDemo } = useContext(DemoContext);

  // Get Current User permissions
  const userProjectPermission = projectPageState.selectedProject?.group;
  const hasEditPermissions =
    userProjectPermission == Group.Owner ||
    userProjectPermission == Group.Editor;
  // Scrolling state
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(false);
  const [updatedProject, setUpdatedProject] = useState<Project>();
  const [sizeChange, setSizeChange] = useState(false);
  const [GetProjectUpdates, { data, loading }] = useGetProjectUpdatesLazyQuery({
    fetchPolicy: 'no-cache',
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateProjectUpdateMutation, { data: dataUpdateProjectUpdate }] =
    useUpdateProjectUpdateMutation();

  const [removeProjectUpdateMutation, { data: dataRemove }] =
    useRemoveProjectUpdateMutation();

  const { dispatch: projectDispatch } = useContext(ProjectPageContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [filterExceptions, setFilterExceptions] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showPaused, setShowPaused] = useState(false);
  const [sortedUpdates, setSortedUpdates] = useState<ProjectUpdate[]>([]);
  const [isLastUpdateSelected, setIsLastUpdateSelected] = useState(true);
  const [defaultIndex, setDefaultIndex] = useState(sortedUpdates.length - 1);

  // Get dummy Waypoint data
  const waypoints = getWaypoints();

  const handleDeleteClick = (update: ProjectUpdate) => {
    if (update != null) {
      removeProjectUpdateMutation({
        variables: {
          input: {
            id: update.id,
            projectId: update.projectId,
          },
        },
      });
      setModalOpen(false);
    }
    return;
  };

  useEffect(() => {
    if (dataUpdateProjectUpdate) {
      projectDispatch({
        type: 'LOAD_UPDATE',
        loadUpdate: true,
      });
      if (dataUpdateProjectUpdate.updateProjectUpdate?.id) {
        projectDispatch({
          type: 'SET_SELECTED_UPDATE',
          update: dataUpdateProjectUpdate.updateProjectUpdate?.id,
        });
      }
    }
  }, [dataUpdateProjectUpdate, projectDispatch]);

  useEffect(() => {
    if (projectPageState.selectedProject?.id) {
      GetProjectUpdates({
        variables: {
          projectId: projectPageState.selectedProject.id,
          limit: 20,
        },
      });
    }
  }, [
    projectPageState.loadUpdate,
    GetProjectUpdates,
    projectPageState.selectedProject?.id,
  ]);

  useEffect(() => {
    if (dataRemove?.removeProjectUpdate) {
      projectDispatch({
        type: 'LOAD_UPDATE',
        loadUpdate: true,
      });
    }
  }, [dataRemove, projectDispatch]);

  useEffect(() => {
    if (data) {
      const newProjectState = Object.assign(
        {},
        projectPageState.selectedProject
      );
      const projectUpdates = data?.getProjectUpdates?.items || [];
      const sorted = _.sortBy([...projectUpdates], 'updateDate');
      setSortedUpdates(sorted);
      if (newProjectState) {
        newProjectState.updates = data.getProjectUpdates;
        setUpdatedProject(newProjectState);
      }
    }
  }, [data, projectDispatch, projectPageState.selectedProject]);

  const ref = useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    function handleResize() {
      setSizeChange(!sizeChange);
    }
    window.addEventListener(
      'resize',
      _.debounce(() => {
        handleResize();
      }, 100)
    );
  });

  const update = projectPageState.loadUpdate;
  const selectedUpdate = projectPageState.selectedUpdate;
  const [refs, setRefs] =
    useState<Map<string, React.RefObject<HTMLButtonElement>>>();

  React.useEffect(() => {
    if (sortedUpdates) {
      const mappedRefs = new Map<string, React.RefObject<HTMLButtonElement>>();
      sortedUpdates.forEach(u =>
        mappedRefs.set(u.id, createRef<HTMLButtonElement>())
      );
      setRefs(mappedRefs);
      if (selectedUpdate) {
        const ind = sortedUpdates.findIndex(u => u.id === selectedUpdate);
        setDefaultIndex(ind);
      } else {
        const ind = sortedUpdates.length - 1;
        setDefaultIndex(ind);
      }
    }
  }, [selectedUpdate, sortedUpdates]);

  useEffect(() => {
    if (ref.current?.scrollLeft && ref.current?.scrollLeft > 0) {
      setScrollLeft(true);
    } else {
      setScrollLeft(false);
    }
    const { current } = ref;
    if (
      current?.scrollWidth &&
      current?.scrollWidth !== current?.clientWidth &&
      ((current?.clientWidth &&
        current?.scrollLeft &&
        current?.scrollLeft !== current?.scrollWidth - current?.clientWidth) ||
        !current?.scrollLeft)
    ) {
      setScrollRight(true);
    } else {
      setScrollRight(false);
    }
  }, [sizeChange, update, defaultIndex, sortedUpdates]);

  useEffect(() => {
    if (sortedUpdates) {
      const selectedElement =
        sortedUpdates[defaultIndex || sortedUpdates.length - 1];
      if (selectedElement && selectedElement !== null) {
        const currentRef = refs?.get(
          selectedElement.id
        ) as RefObject<HTMLButtonElement>;
        if (currentRef.current) {
          currentRef.current.scrollIntoView(false);
        }
      }
    }
  }, [defaultIndex, refs, sortedUpdates]);

  const sideScroll = (
    element: HTMLDivElement | null,
    speed: number,
    distance: number,
    step: number
  ) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      if (element) {
        element.scrollLeft += step;
      }
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
    setSizeChange(!sizeChange);
  };

  const iconIncrease = (
    <ArrowUp className="mx-auto h-5 w-5 text-gray-800 shrink-0" weight="fill" />
  );

  const iconDecrease = (
    <ArrowDown
      className="mx-auto h-5 w-5 text-gray-800 shrink-0"
      weight="fill"
    />
  );

  function getDateChangePartialString(projectUpdate: ProjectUpdate) {
    const oldDate = new Date(projectUpdate.ontimeDateOriginal).getTime();
    const newDate = new Date(projectUpdate.ontimeDateNew).getTime();
    const direction = newDate > oldDate ? 'increased' : 'decreased';

    const output = `${direction} \n${
      projectUpdate.ontimeDateOriginal
        ? `from ${getLocalDate(projectUpdate.ontimeDateOriginal)} `
        : ``
    }to ${getLocalDate(projectUpdate.ontimeDateNew)}`;

    return output;
  }

  function getEstimatedDateChange(
    projectUpdate: ProjectUpdate
  ): React.ReactElement {
    const checkStatus = projectUpdate.ontimeStatus;
    let direction = <React.Fragment />;

    if (checkStatus === OnTimeStatus.Rebaselined) {
      const oldDate = new Date(projectUpdate.ontimeDateOriginal).getTime();
      const newDate = new Date(projectUpdate.ontimeDateNew).getTime();
      direction =
        newDate > oldDate ? (
          <React.Fragment>
            <span
              className=""
              data-tip
              data-for={`${projectUpdate.id}-dateChange`}
            >
              {iconIncrease}
            </span>
            <ReactTooltip id={`${projectUpdate.id}-dateChange`} effect="solid">
              <div className="text-sm">
                <p className="whitespace-pre-line">{`${capitaliseFirstLetter(
                  translate(
                    strings.PROJECT_DELIVERY_DATE,
                    'Estimated Completion Date'
                  ).toLowerCase()
                )} has ${getDateChangePartialString(projectUpdate)}`}</p>
                {projectUpdate.ontimeRemark ? (
                  <p className="italic mt-1 font-normal max-w-sm">{`Reason: ${projectUpdate.ontimeRemark}`}</p>
                ) : null}
              </div>
            </ReactTooltip>
          </React.Fragment>
        ) : newDate < oldDate ? (
          <React.Fragment>
            <span
              className=""
              data-tip
              data-for={`${projectUpdate.id}-dateChange`}
            >
              {iconDecrease}
            </span>
            <ReactTooltip id={`${projectUpdate.id}-dateChange`} effect="solid">
              <div className="text-sm">
                <p className="whitespace-pre-line">{`${capitaliseFirstLetter(
                  translate(
                    strings.PROJECT_DELIVERY_DATE,
                    'Estimated Completion Date'
                  ).toLowerCase()
                )} has ${getDateChangePartialString(projectUpdate)}`}</p>
                {projectUpdate.ontimeRemark ? (
                  <p className="italic mt-1 font-normal max-w-sm">{`Reason: ${projectUpdate.ontimeRemark}`}</p>
                ) : null}
              </div>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>-</React.Fragment>
        );
    } else {
      direction = <React.Fragment>-</React.Fragment>;
    }
    return direction;
  }

  function getCostChangePartialString(projectUpdate: ProjectUpdate) {
    const oldCost = projectUpdate.onbudgetValueOriginal || 0;
    const newCost = projectUpdate.onbudgetValueNew || 0;
    const direction = newCost > oldCost ? 'increased' : 'decreased';

    const output = `${direction} \n${
      projectUpdate.onbudgetValueOriginal
        ? `from $${projectUpdate.onbudgetValueOriginal?.toLocaleString()} `
        : ``
    }to $${projectUpdate.onbudgetValueNew?.toLocaleString()}`;

    return output;
  }

  function getEstimatedCostChange(
    projectUpdate: ProjectUpdate
  ): React.ReactElement {
    const checkStatus = projectUpdate.onbudgetStatus;
    let direction = <React.Fragment />;

    if (checkStatus === OnBudgetStatus.Rebaselined) {
      const oldCost = projectUpdate.onbudgetValueOriginal || 0;
      const newCost = projectUpdate.onbudgetValueNew || 0;
      direction =
        newCost > oldCost ? (
          <React.Fragment>
            <span
              className=""
              data-tip
              data-for={`${projectUpdate.id}-costChange`}
            >
              {iconIncrease}
            </span>
            <ReactTooltip id={`${projectUpdate.id}-costChange`} effect="solid">
              <div className="text-sm">
                <p className="whitespace-pre-line">{`${capitaliseFirstLetter(
                  translate(
                    strings.PROJECT_TOTAL_COST,
                    'Estimated Total Cost'
                  ).toLowerCase()
                )} has ${getCostChangePartialString(projectUpdate)}`}</p>
                {projectUpdate.onbudgetRemark ? (
                  <p className="italic mt-1 font-normal max-w-sm">{`Reason: ${projectUpdate.onbudgetRemark}`}</p>
                ) : null}
              </div>
            </ReactTooltip>
          </React.Fragment>
        ) : newCost < oldCost ? (
          <React.Fragment>
            <span
              className=""
              data-tip
              data-for={`${projectUpdate.id}-costChange`}
            >
              {iconDecrease}
            </span>
            <ReactTooltip id={`${projectUpdate.id}-costChange`} effect="solid">
              <div className="text-sm">
                <p className="whitespace-pre-line">{`${capitaliseFirstLetter(
                  translate(
                    strings.PROJECT_TOTAL_COST,
                    'Estimated Total Cost'
                  ).toLowerCase()
                )} has ${getCostChangePartialString(projectUpdate)}`}</p>
                {projectUpdate.onbudgetRemark ? (
                  <p className="italic mt-1 font-normal max-w-sm">{`Reason: ${projectUpdate.onbudgetRemark}`}</p>
                ) : null}
              </div>
            </ReactTooltip>
          </React.Fragment>
        ) : (
          <React.Fragment>-</React.Fragment>
        );
    } else {
      direction = <React.Fragment>-</React.Fragment>;
    }
    return direction;
  }

  const updateTitle: React.ReactElement = (
    <DetailsPanelHeader
      title={`Waypoints`}
      // title={`${translate(strings.PROJECT_REVIEW, `Project Review`)}s`}
      description={`How we're tracking toward ${translate(
        strings.PROGRAM_MEASURES,
        'Program Measures'
      ).toLowerCase()}`}
    />
  );

  // const updateButton: React.ReactElement = (
  //   <React.Fragment>
  //     {updatedProject && hasEditPermissions && (
  //       <WizardButton
  //         title={translate(
  //           strings.WIZARD_NEW_PROJECT_REVIEW,
  //           'New Project Review'
  //         )}
  //         editObject={null}
  //         parentObject={updatedProject}
  //         type="PROJECT_UPDATE"
  //         startStep={1}
  //       />
  //     )}
  //   </React.Fragment>
  // );

  const updateContent: React.ReactElement = loading ? (
    <div className="flex flex-col align-middle items-center">
      <Spinner
        text={`Loading ${enumTranslates[EntityType.Project]} updates...`}
      />
    </div>
  ) : (
    <React.Fragment>
      {sortedUpdates === undefined || sortedUpdates?.length == 0 ? (
        <React.Fragment>
          <div className="px-4 py-6 sm:p-6">
            <EmptyState
              type={`${enumTranslates[EntityType.Project]} reviews`}
            />
          </div>
          <div className="flex-col mt-3">
            <DetailsPanelSectionDivider title="Path to Outcomes" />
            <div className="mb-0">
              <MeasuresLogicModel
                project={projectPageState.selectedProject}
                update={null}
                isLatestUpdate={isLastUpdateSelected}
              />
            </div>
          </div>
        </React.Fragment>
      ) : (
        <Tab.Group
          selectedIndex={defaultIndex}
          onChange={index => {
            setDefaultIndex(index);
            setIsLastUpdateSelected(index === sortedUpdates.length - 1);
          }}
        >
          <Tab.List className="flex bg-gray-50 px-3 py-2 border border-gray-200 rounded-md">
            <div className="w-fit inline-flex text-gray-700 pr-4 py-2 font-semibold text-sm rounded-md flex-col space-y-3">
              <p className="whitespace-nowrap">Review Date:</p>
              <p className="whitespace-nowrap pt-0.5">
                {translate(
                  strings.PROJECT_DELIVERY_CONFIDENCE,
                  'Delivery Confidence'
                )}
                :
              </p>
              <p className="whitespace-nowrap">
                {/* {translate(
                  strings.PROJECT_DELIVERY_DATE,
                  'Estimated Completion Date'
                )} */}
                Est. Completion Date:
              </p>
              <p className="whitespace-nowrap">
                {/* {translate(strings.PROJECT_TOTAL_COST, 'Estimated Total Cost')} */}
                Est. Total Cost:
              </p>
            </div>
            {scrollLeft ? (
              <button
                onClick={() => {
                  sideScroll(
                    ref.current,
                    25,
                    100,
                    ref.current?.scrollWidth
                      ? -(ref.current?.scrollWidth / sortedUpdates.length)
                      : -10
                  );
                }}
                className="hover:bg-gray-200 rounded border-r border-gray-200"
              >
                <CaretLeft className="h-5 w-5 mx-1 shrink-0" weight="bold" />
              </button>
            ) : (
              ''
            )}
            <span className="overflow-x-hidden flex" ref={ref}>
              {sortedUpdates?.map((projectUpdate: ProjectUpdate) => (
                <Tab as={'div'} key={projectUpdate.id}>
                  {({ selected }) => (
                    <div id={projectUpdate.id}>
                      <button
                        type="button"
                        ref={refs?.get(projectUpdate.id)}
                        className={classNames(
                          selected
                            ? `${getConfidenceColour(
                                projectUpdate.confidenceRating,
                                'light'
                              )} text-black font-semibold`
                            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100',
                          'w-1/8 px-3 pt-2 pb-3 text-sm rounded-md'
                        )}
                      >
                        <div className="px-3 flex-col space-y-3">
                          {/* Review date */}
                          <div
                            className={
                              selected
                                ? 'text-black font-semibold'
                                : 'text-gray-500'
                            }
                          >
                            {moment(projectUpdate.updateDate).format(
                              'D/M/YYYY'
                            )}
                          </div>

                          {/* Delivery confidence */}
                          <div className="mt-2 flex text-sm text-gray-500 w-min mx-auto align-middle">
                            <ConfidenceBadge
                              rating={projectUpdate?.confidenceRating}
                              reviewDate={projectUpdate?.updateDate}
                            />
                          </div>

                          {/* Completion date */}
                          <div>
                            {projectUpdate.ontimeStatus ===
                            OnTimeStatus.Rebaselined
                              ? getEstimatedDateChange(projectUpdate)
                              : '-'}
                          </div>

                          {/* Total cost estimate */}
                          <div>
                            {projectUpdate.onbudgetStatus ===
                            OnBudgetStatus.Rebaselined
                              ? getEstimatedCostChange(projectUpdate)
                              : '-'}
                          </div>
                        </div>
                      </button>
                    </div>
                  )}
                </Tab>
              ))}
            </span>
            {scrollRight ? (
              <button
                onClick={() => {
                  sideScroll(
                    ref.current,
                    25,
                    100,
                    ref.current?.scrollWidth
                      ? ref.current?.scrollWidth / sortedUpdates.length
                      : 10
                  );
                }}
                className="hover:bg-gray-200 rounded border-l border-gray-200"
              >
                <CaretRight className="h-5 w-5 mx-1 shrink-0" weight="bold" />
              </button>
            ) : (
              ''
            )}
          </Tab.List>

          <Tab.Panels>
            {sortedUpdates?.map((selectedProjectUpdate: ProjectUpdate) => (
              <Tab.Panel key={selectedProjectUpdate.id}>
                {/* Project update delete confirmation modal */}
                {modalOpen && (
                  <Modal
                    width={'w-full max-w-xl'}
                    onClose={() => setModalOpen(false)}
                  >
                    <div className="flex flex-col space-y-4 bg-white p-4 md:w-full overflow-y-auto align-middle">
                      <div className="flex flex-row items-center">
                        <span className="flex p-2 rounded-full bg-red-600 align-middle items-center">
                          <Trash
                            weight="fill"
                            className="h-5 w-5 text-white mx-auto my-auto"
                          />
                        </span>
                        <h3 className="text-lg font-medium ml-3 text-gray-900">
                          Confirm Delete
                        </h3>
                      </div>
                      <p className="mt-3 text-sm text-gray-500">
                        Deleting a {enumTranslates[EntityType.Project]} review
                        cannot be undone.
                      </p>
                      <p className="mt-3 text-sm text-gray-500">
                        {`If you still want to delete the ${
                          enumTranslates[EntityType.Project]
                        } review from ${getLocalDate(
                          selectedProjectUpdate.updateDate
                        )}, press the "Delete review" button below.`}
                      </p>
                      <span className="text-right space-x-3">
                        <button
                          className="disabled:opacity-50 items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
                          onClick={() => setModalOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="disabled:opacity-50 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          onClick={() =>
                            handleDeleteClick(selectedProjectUpdate)
                          }
                        >
                          Delete review
                        </button>
                      </span>
                    </div>
                  </Modal>
                )}

                {/* Action menu */}
                <div className="flex justify-between mt-5">
                  <div className="flex flex-col items-center align-middle"></div>
                  <div className="ml-4 flex-shrink-0">
                    <span className="relative z-0 inline-flex gap-x-4">
                      {/* Edit button */}
                      {updatedProject &&
                        hasEditPermissions &&
                        isLastUpdateSelected &&
                        !isDemo && (
                          <WizardButton
                            title={translate(
                              strings.WIZARD_EDIT_PROJECT_REVIEW,
                              'Edit Project Review'
                            )}
                            editObject={selectedProjectUpdate}
                            parentObject={updatedProject}
                            topLevelEntity={updatedProject}
                            workspaceId=""
                            type="PROJECT_UPDATE"
                            startStep={1}
                          />
                        )}

                      {/* Overflow */}
                      {updatedProject && hasEditPermissions && !isDemo
                        ? OverflowMenu(
                            selectedProjectUpdate,
                            setModalOpen
                            // handlePublishClick,
                            // filterExceptions
                            // setFilterExceptions,
                          )
                        : null}
                    </span>
                  </div>
                </div>

                <div className="flex-col space-y-4">
                  {/* Runway */}
                  <div className="flex-col mt-3">
                    <DetailsPanelSectionDivider title="Forward Estimates" />

                    <div className="grid grid-cols-1 justify-between md:space-x-6 md:grid-cols-2">
                      <div className="col-span-1">
                        <p className="mt-1 text-sm font-semibold text-gray-900">
                          {capitaliseFirstLetter(
                            translate(
                              strings.PROJECT_DELIVERY_DATE,
                              'Estimated Completion Date'
                            ).toLowerCase()
                          )}
                        </p>
                        <div className="mt-1 mb-2 text-sm text-gray-600">
                          {selectedProjectUpdate.ontimeStatus ==
                          OnTimeStatus.Na ? (
                            <p>No hard deadline</p>
                          ) : selectedProjectUpdate.ontimeStatus ==
                            OnTimeStatus.Ontime ? (
                            <p>
                              {`${
                                enumTranslates[
                                  selectedProjectUpdate.ontimeStatus
                                ]
                              } ${
                                selectedProjectUpdate.ontimeDateOriginal
                                  ? `- delivery expected ${getLocalDate(
                                      selectedProjectUpdate.ontimeDateOriginal
                                    )} (${getRelativeTime(
                                      selectedProjectUpdate.ontimeDateOriginal
                                    )})`
                                  : `- ${translate(
                                      strings.PROJECT_DELIVERY_DATE,
                                      'Estimated Completion Date'
                                    ).toLowerCase()} not provided`
                              }`}
                            </p>
                          ) : (
                            <React.Fragment>
                              <p>
                                {capitaliseFirstLetter(
                                  getDateChangePartialString(
                                    selectedProjectUpdate
                                  )
                                )}
                              </p>
                              <p className="italic mt-1">
                                {`Reason: ${
                                  selectedProjectUpdate.ontimeRemark ||
                                  'None provided'
                                }`}
                              </p>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className="col-span-1">
                        <p className="mt-1 text-sm font-semibold text-gray-900">
                          {capitaliseFirstLetter(
                            translate(
                              strings.PROJECT_TOTAL_COST,
                              'Estimated total cost'
                            ).toLowerCase()
                          )}
                        </p>
                        <div className="mt-1 mb-2 text-sm text-gray-600">
                          {selectedProjectUpdate.onbudgetStatus ==
                          OnBudgetStatus.Na ? (
                            <p>No set budget</p>
                          ) : selectedProjectUpdate.onbudgetStatus ==
                            OnBudgetStatus.Onbudget ? (
                            <p>
                              {`${
                                enumTranslates[
                                  selectedProjectUpdate.onbudgetStatus
                                ]
                              } - $${selectedProjectUpdate.spendToDate?.toLocaleString()} spent
                              ${
                                selectedProjectUpdate.onbudgetValueOriginal
                                  ? ` of $${selectedProjectUpdate.onbudgetValueOriginal?.toLocaleString()}
                                budget`
                                  : ''
                              }`}
                            </p>
                          ) : (
                            <React.Fragment>
                              <p>
                                {capitaliseFirstLetter(
                                  getCostChangePartialString(
                                    selectedProjectUpdate
                                  )
                                )}
                              </p>
                              <p className="italic mt-1">
                                {`Reason: ${
                                  selectedProjectUpdate.onbudgetRemark ||
                                  'None provided'
                                }`}
                              </p>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Callouts */}
                  <div className="flex-col mt-2">
                    <DetailsPanelSectionDivider title="Reflections" />

                    <div className="grid grid-cols-1 justify-between md:space-x-6 md:grid-cols-3">
                      <div className="col-span-1">
                        <p className="mt-1 text-sm font-semibold text-gray-900">
                          {translate(
                            strings.PROJECT_PROMPT_HIGHLIGHT,
                            `Highlights`
                          )}
                        </p>
                        <p className="mt-1 mb-2 line-clamp-2 text-sm text-gray-600 whitespace-pre-wrap">
                          {selectedProjectUpdate.whatImproved &&
                          selectedProjectUpdate.whatImproved.length > 0
                            ? selectedProjectUpdate.whatImproved
                            : '(Nothing shared)'}
                        </p>
                      </div>

                      <div className="col-span-1">
                        <p className="mt-1 text-sm font-semibold text-gray-900">
                          {translate(
                            strings.PROJECT_PROMPT_LOWLIGHT,
                            `Lowlights`
                          )}
                        </p>
                        <p className="mt-1 mb-2 line-clamp-2 text-sm text-gray-600 whitespace-pre-wrap">
                          {selectedProjectUpdate.roadblockRemark &&
                          selectedProjectUpdate.roadblockRemark.length > 0
                            ? selectedProjectUpdate.roadblockRemark
                            : '(Nothing shared)'}
                        </p>
                      </div>

                      <div className="col-span-1">
                        <p className="mt-1 text-sm font-semibold text-gray-900">
                          {translate(
                            strings.PROJECT_PROMPT_FUTURE,
                            `What next...`
                          )}
                        </p>
                        <p className="mt-1 mb-2 line-clamp-2 text-sm text-gray-600 whitespace-pre-wrap">
                          {selectedProjectUpdate.whatNext &&
                          selectedProjectUpdate.whatNext.length > 0
                            ? selectedProjectUpdate.whatNext
                            : '(Nothing shared)'}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Logic Model */}
                  <div className="flex-col mt-3">
                    <DetailsPanelSectionDivider title="Path to Outcomes" />

                    <div className="flex justify-end gap-x-4 -mt-2 mb-4">
                      <span data-tip data-for={'filterExceptionsButton'}>
                        <ToggleButton
                          label={`Show ${translate(
                            strings.PROJECT_EXCEPTIONS,
                            'Exceptions'
                          ).toLowerCase()} only`}
                          stateOn={filterExceptions}
                          setStateOn={setFilterExceptions}
                          disabled={
                            !selectedProjectUpdate.measures?.some(
                              measure =>
                                measure.exceptionStatus ==
                                  ExceptionStatus.GoodException ||
                                measure.exceptionStatus ==
                                  ExceptionStatus.BadException
                            )
                          }
                        />
                      </span>

                      {!selectedProjectUpdate.measures?.some(
                        measure => measure.exceptionStatus != undefined
                      ) ? (
                        // Tooltip
                        <ReactTooltip
                          id={'filterExceptionsButton'}
                          effect="solid"
                        >
                          <div>
                            <p className="text-sm">
                              {`No exceptions have been added for this ${
                                enumTranslates[EntityType.Project]
                              }
                              review.`}
                            </p>
                          </div>
                        </ReactTooltip>
                      ) : null}

                      {FilterMenu(
                        selectedProjectUpdate,
                        getPreviousProjectUpdate(
                          sortedUpdates,
                          selectedProjectUpdate
                        ),
                        showCompleted,
                        setShowCompleted,
                        showPaused,
                        setShowPaused
                      )}
                    </div>

                    <div className="mb-0">
                      <MeasuresLogicModel
                        project={projectPageState.selectedProject}
                        update={selectedProjectUpdate}
                        isLatestUpdate={isLastUpdateSelected}
                        filterExceptions={filterExceptions}
                        showCompleted={showCompleted}
                        showPaused={showPaused}
                      />
                    </div>
                  </div>
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </React.Fragment>
  );

  const [selectedWaypoint, setSelectedWaypoint] = useState<Waypoint | null>(
    null
  );

  const outcomesTitle: React.ReactElement = (
    <DetailsPanelHeader
      title={`Outcomes`}
      description={`Proof points that give us confidence toward our goal`}
    />
  );

  const outcomesButton: React.ReactElement = (
    <div className="flex gap-x-3">
      <SecondaryButton
        label="Edit waypoint"
        icon="edit"
        onClick={() => null}
        disabled={false}
      />
    </div>
  );

  const iconChecked = (
    <div className="bg-primary-500 rounded mx-px">
      <Check weight="bold" className="text-white" size={18} />
    </div>
  );
  const iconUnchecked = (
    <Square weight="bold" className="text-gray-400" size={20} />
  );

  const outcomesContent: React.ReactElement = (
    <div className="text-sm">
      {/* Show a list of the required proof points / outcomes for the selected Waypoint */}
      <div>
        <ul className="list-outside ml-0 space-y-2">
          {selectedWaypoint?.checkList.map(item => {
            let ticked = item.checked;

            return (
              <li className="" key={ulid()} onClick={() => (ticked = !ticked)}>
                <div className="flex gap-x-3">
                  <div className="mt-0.5">
                    {ticked ? iconChecked : iconUnchecked}
                  </div>
                  <div>{item.description}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  const reviewTitle: React.ReactElement = (
    <DetailsPanelHeader
      title={`Review`}
      description={`How we are tracking and how we'll improve`}
    />
  );

  const reviewButton: React.ReactElement = (
    <div className="flex gap-x-3">
      {selectedWaypoint?.review ? (
        <SecondaryButton
          label="Edit review"
          icon="edit"
          onClick={() => null}
          disabled={false}
        />
      ) : (
        <PrimaryButton
          label="Add review"
          icon="plus"
          onClick={() => null}
          disabled={false}
        />
      )}
    </div>
  );

  const reviewContent: React.ReactElement = selectedWaypoint?.review ? (
    <div className="space-y-2">
      <TextBlock
        header={'Confidence'}
        content={
          <div className="-mt-0.5">
            <ConfidenceBadge rating={selectedWaypoint?.review?.confidence} />
          </div>
        }
      />
      <TextBlock
        header={'Remarks'}
        content={
          <p className="whitespace-pre-wrap">
            {selectedWaypoint.review.remarks}
          </p>
        }
      />
    </div>
  ) : (
    <div>
      {selectedWaypoint && (
        <EmptyState
          type="waypoint review"
          secondaryText={`Review expected ${getRelativeTime(
            selectedWaypoint?.date
          )}`}
        />
      )}
    </div>
  );

  return (
    <div className="space-y-6">
      <div>
        <WaypointSelector
          waypoints={waypoints}
          selectedWaypoint={selectedWaypoint}
          setSelectedWaypoint={setSelectedWaypoint}
        />
      </div>

      {/* For the selected waypoint, show:
          The required outcomes */}
      <DetailsPanel
        headerLeft={outcomesTitle}
        headerRight={outcomesButton}
        content={outcomesContent}
      />

      {/* The review */}
      <DetailsPanel
        headerLeft={reviewTitle}
        headerRight={reviewButton}
        content={reviewContent}
      />

      {/* <DetailsPanel
        headerLeft={updateTitle}
        headerRight={updateButton}
        content={updateContent}
      /> */}
    </div>
  );
}

export default EntityPlan;
