/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { Tab as HeadlessUITab } from '@headlessui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Path } from 'typescript';

export type Tab = {
  tab: React.ReactElement;
  content: React.ReactElement;
  hash?: string;
};

type Props = {
  header: React.ReactElement;
  tabs: Array<Tab>;
  isLoading: boolean;
  selected?: number;
  setSelectedIndex?: React.Dispatch<React.SetStateAction<number>>;
};

export default function BasicHeaderWithTabsLayout({
  header,
  tabs,
  isLoading,
  selected,
  setSelectedIndex,
}: Props): React.ReactElement {
  const { hash } = useLocation();
  const navigate = useNavigate();
  return (
    <div className="flex-col">
      <div className="px-2 mb-2">{header}</div>
      {/* TODO: Remove default later */}
      <HeadlessUITab.Group
        onChange={index => {
          navigate(tabs[index].hash as Path);
        }}
        selectedIndex={
          tabs
            .map(function (tab) {
              return tab.hash;
            })
            .indexOf(hash) || 0
        }
      >
        <div className="sticky top-0 bg-white z-[5]">
          <div className="flex justify-start px-2">
            <HeadlessUITab.List
              className={`flex mt-4 gap-x-1 gap-y-1 overflow-x-auto pb-2`}
            >
              {tabs.map(tab => tab.tab)}
            </HeadlessUITab.List>
          </div>

          <div className="border-t border-primary-500 mt-1 mb-4 -mx-2 md:mx-0"></div>
        </div>

        <div className="px-0 md:px-2">
          <HeadlessUITab.Panels>
            {tabs.map(tab => tab.content)}
          </HeadlessUITab.Panels>
        </div>
      </HeadlessUITab.Group>
    </div>
  );
}
