import React, { useContext, useEffect, useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import {
  EntityType,
  Group,
  Portfolio,
  PortfolioMeasureStatus,
  PortfolioMember,
  PortfolioUpdate,
  PortfolioUpdateByMeasure,
  Program,
  ProgramMeasure,
  ProgramMeasureStatus,
  ProgramMember,
  ProgramRole,
  ProgramUpdateTrend,
  StrategicObjective,
  StrategicTheme,
  useRemovePortfolioUpdateMutation,
  useUpdatePortfolioUpdateMutation,
} from '../../../api/index';
import {
  capitaliseFirstLetter,
  getLastTwoProgramUpdates,
  getLatestObjectiveUpdate,
  getLatestProgramBenefitStatus,
  getLatestProgramUpdate,
  getLocalDate,
  getParentProgram,
  getProgramMeasureStatusColour,
  getRelativeTime,
  numToChar,
  RecentProgramUpdates,
  removeTypename,
} from '../../../common/utils';
import {
  CaretDown,
  CaretUp,
  FolderSimple,
  Target,
  Trash,
  WifiX,
} from 'phosphor-react';
import moment from 'moment';
import Spinner from '../../../common/SpinnerThemed';
import { ApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import { PROGRAM } from '../../../common/routes';
import ReactTooltip from 'react-tooltip';
import Tag from '../../../common/Tag';
import EmptyState from '../../../common/layout/EmptyState';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import FilterButton from '../../project/OverviewTab/FilterButton';
import SlideOver from '../../prioritisation/SlideOver';
import { getStatusDot } from '../../program/PerformanceTab/HelperFunctions';
import OpenWizardButton from '../../../common/wizard/WizardButton';
import { PortfolioPageContext } from '../PortfolioPageContext';
import { DemoContext } from '../../../context/DemoContext';
import Modal from '../../../common/Modal';
import { toast } from 'react-toastify';
import ObjectiveSlideoverLayout from './ObjectiveSlideoverLayout';
import ProgramBenefitsSlideoverLayout from './ProgramBenefitsSlideoverLayout';
import ProgramSlideoverLayout from './ProgramSlideoverLayout';
import { UserContext } from '../../../context/UserContext';
import PermissionLink from '../../../common/PermissionLink';
import { ulid } from 'ulid';

type PanelProps = {
  portfolio: Portfolio | null;
  programsList: Array<Program> | null | undefined;
  loadingPrograms: boolean;
  errorPrograms: ApolloError | undefined;
};

function PortfolioPerformancePanel({
  portfolio,
  programsList,
  loadingPrograms,
  errorPrograms,
}: PanelProps): React.ReactElement {
  const { state: portfolioPageState, dispatch: portfolioDispatch } =
    useContext(PortfolioPageContext);
  const { user: currentUser } = useContext(UserContext);
  const { isDemo } = useContext(DemoContext);

  // Check current user permission level
  const hasEditPermissions =
    portfolio?.group === Group.Owner || portfolio?.group === Group.Editor;

  // Check for a role
  const portfolioMembers = portfolioPageState.selectedPortfolio?.members?.items
    ? (portfolioPageState.selectedPortfolio?.members
        ?.items as PortfolioMember[])
    : [];
  const currentUserRole = portfolioMembers.find(
    item => item.user.id === currentUser?.attributes?.sub
  )?.role;

  // Get data
  const [update, setUpdate] = useState<{
    lastUpdate: PortfolioUpdateByMeasure | null;
    update: PortfolioUpdate | null;
    date: string;
  }>();
  const { portfolioUpdates } = portfolioPageState;
  const [
    updatePortfolioUpdateMutation,
    { data: dataUpdate, loading: loadingUpdate },
  ] = useUpdatePortfolioUpdateMutation();
  const [
    removePortfolioUpdateMutation,
    { data: removeUpdate, loading: loadingRemove },
  ] = useRemovePortfolioUpdateMutation();

  useEffect(() => {
    if (
      dataUpdate?.updatePortfolioUpdate ||
      removeUpdate?.removePortfolioUpdate
    ) {
      portfolioDispatch({
        type: 'LOAD_UPDATE',
        loadUpdate: true,
      });
    }
  }, [
    portfolioDispatch,
    dataUpdate?.updatePortfolioUpdate,
    removeUpdate?.removePortfolioUpdate,
  ]);

  const deleteObjectiveUpdate = async (
    update: PortfolioUpdate,
    selectedObjective: string
  ) => {
    if (update != null) {
      console.log(selectedObjective);

      const newUpdate = { ...update };
      newUpdate.measures = newUpdate.measures?.filter(
        m => m.strategicObjectiveId !== selectedObjective
      );

      try {
        if (newUpdate.measures && newUpdate.measures?.length > 0) {
          const updatePortfolioUpdateResp = await updatePortfolioUpdateMutation(
            {
              variables: {
                input: removeTypename(newUpdate),
              },
            }
          );
          if (updatePortfolioUpdateResp.data?.updatePortfolioUpdate) {
            portfolioDispatch({
              type: 'LOAD_UPDATE',
              loadUpdate: true,
            });
            toast.info(
              `Removed ${translate(
                strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                'objective'
              )} review`
            );
            setModalOpen(false);
          }
        } else {
          const removePortfolioUpdateResp = await removePortfolioUpdateMutation(
            {
              variables: {
                input: { id: update.id, portfolioId: update?.portfolioId },
              },
            }
          );
          if (removePortfolioUpdateResp.data?.removePortfolioUpdate) {
            portfolioDispatch({
              type: 'LOAD_UPDATE',
              loadUpdate: true,
            });
            toast.info(
              `Removed ${translate(
                strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                'objective'
              )} review`
            );
            setModalOpen(false);
          }
        }
      } catch (err) {
        const error = err as ApolloError;
        toast.error(error.message);
      }
    }
    return;
  };

  // Slideover
  const [showSlideOver, setShowSlideOver] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [slideOverProgram, setSlideOverProgram] = useState<Program | null>(
    null
  );
  const [slideOverProgramMeasure, setSlideOverProgramMeasure] =
    useState<ProgramMeasure>();
  const [slideOverObjective, setSlideOverObjective] =
    useState<StrategicObjective>();

  type slideoverToShow =
    | 'NONE'
    | 'PROGRAM'
    | 'PROGRAM_BENEFIT'
    | 'STRATEGIC_OBJECTIVE';
  const [slideoverToShow, setSlideoverToShow] =
    useState<slideoverToShow>('NONE');

  // Highlight relevant row when slideover is showing
  const [highlightRow, setHighlightRow] = useState(false);
  useEffect(() => {
    setHighlightRow(showSlideOver);
  }, [showSlideOver]);

  // Icons
  const iconError = <WifiX className="mx-auto h-12 w-12 text-gray-300" />;

  const iconTarget = (
    <Target
      className="inline-block text-gray-500 mb-0.5 flex-shrink-0"
      weight="duotone"
      size={20}
    />
  );
  const iconProgram = (
    <FolderSimple
      className="inline-block text-gray-500 mb-0.5 flex-shrink-0"
      weight="duotone"
      size={20}
    />
  );

  // Track how project measure are displayed
  const PanelViewOptions: Array<{ label: string; value: string }> = [
    {
      label: `How are our ${translate(
        strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
        'Strategic Objectives'
      ).toLowerCase()} tracking?`,
      value: 'VIEW_STRATEGIC_OBJECTIVES',
    },
    {
      label: `How well are ${
        enumTranslates[EntityType.Program]
      }s progressing toward benefits?`,
      value: 'VIEW_PROGRAMS',
    },
    {
      label: `How do ${translate(
        strings.PROGRAM_MEASURES,
        'Program Benefits'
      ).toLowerCase()} link to our ${translate(
        strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
        'Strategic Objectives'
      ).toLowerCase()}?`,
      value: 'VIEW_PROGRAM_BENEFITS',
    },
  ];
  const [selectedView, setSelectedView] = useState(PanelViewOptions[0].value);

  // Handle user selection of projects view
  function handleViewSelection(value: string) {
    setSelectedView(value);

    if (value === 'VIEW_PROGRAM_BENEFITS') {
      setIncludedBenefitStatus([
        ProgramMeasureStatus.HelpNeeded,
        ProgramMeasureStatus.Risk,
      ]);
    }
  }

  // Data
  const programUpdates: Array<RecentProgramUpdates> = [];
  programsList?.forEach(program => {
    const latestUpdate = getLastTwoProgramUpdates(program);
    programUpdates.push(latestUpdate);
  });

  type timePoint = 'latest' | 'previous';

  // State tracking for filters
  const [includedPillars, setIncludedPillars] = useState<string[]>([]);
  const [includedObjectives, setIncludedObjectives] = useState<string[]>([]);
  const [includedObjectiveStatus, setIncludedObjectiveStatus] = useState<
    PortfolioMeasureStatus[]
  >([]);
  const [includedPrograms, setIncludedPrograms] = useState<string[]>([]);
  const [includedBenefitStatus, setIncludedBenefitStatus] = useState<
    (ProgramMeasureStatus | string)[]
  >([]);
  const [includedConfidence, setIncludedConfidence] = useState<
    ProgramUpdateTrend[]
  >([]);
  const [includedOwners, setIncludedOwners] = useState<string[]>([]);

  const objectives = portfolio?.objectives || [];
  const themes = portfolio?.themes || [];

  function getProgramBenefitsByObjective(objectiveId: string) {
    const result: Array<ProgramMeasure | null> = [];

    programsList?.forEach(program =>
      program?.measures?.forEach(measure => {
        result.push(
          measure.strategicObjectiveId == objectiveId ? measure : null
        );
      })
    );

    return result.filter(metric => metric != null);
  }

  function hasStatus(measure: ProgramMeasure | null, time: timePoint): boolean {
    // Return false if the measure does not exist
    if (!measure) {
      return false;
    }

    // Initialise variables and data
    let relevantUpdate = null;
    const data = programUpdates.find(
      item =>
        item?.program.id === getParentProgram(programsList, measure.id)?.id
    );

    // Get update based on which point in time we are seeking
    if (time === 'latest') {
      relevantUpdate = data?.latestUpdate;
    } else {
      relevantUpdate = data?.previousUpdate;
    }

    const foundStatus = relevantUpdate?.measures?.find(
      updatedMeasure => updatedMeasure.measureId == measure.id
    )?.status;

    if (foundStatus) {
      return true;
    } else {
      return false;
    }
  }

  function ProgramMeasureDot(
    measure: ProgramMeasure | null,
    time: timePoint
  ): React.ReactElement | null {
    // Return null if the measure does not exist
    if (!measure) {
      return null;
    }

    // Initialise variables and data
    let dotColour = '';
    let relevantUpdate = null;
    let measureUpdate = null;
    const data = programUpdates.find(
      item =>
        item?.program.id === getParentProgram(programsList, measure.id)?.id
    );

    // Get update based on which point in time we are seeking
    if (time === 'latest') {
      relevantUpdate = data?.latestUpdate;
    } else {
      relevantUpdate = data?.previousUpdate;
    }

    measureUpdate = relevantUpdate?.measures?.find(
      updatedMeasure => updatedMeasure.measureId == measure.id
    );

    const uniqueIdString = `${measureUpdate?.measureId}-${time}`;

    // Get the status and correct class colour
    dotColour = getProgramMeasureStatusColour(measureUpdate?.status);

    return (
      <React.Fragment>
        <span
          className={`flex-shrink-0 inline-block h-3 w-3 rounded-full 
        ${dotColour}`}
          aria-hidden="true"
          data-tip
          data-for={uniqueIdString}
        />

        <ReactTooltip id={uniqueIdString} effect="solid">
          <div>
            <p>
              {measureUpdate?.status
                ? enumTranslates[measureUpdate.status]
                : 'Not rated'}
              , as at {getLocalDate(relevantUpdate?.updateDate)}
            </p>
          </div>
        </ReactTooltip>
      </React.Fragment>
    );
  }

  function handleClearFiltersClick() {
    setIncludedPillars([]);
    setIncludedObjectives([]);
    setIncludedPrograms([]);
    setIncludedBenefitStatus([]);
  }

  // Layouts
  const panelTitle = (
    <DetailsPanelHeader
      title={
        // View selector
        <div className="flex -ml-4">
          <select
            className="max-w-full grow w-fit truncate block text-lg font-medium rounded-md -my-3 hover:bg-gray-50 border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500"
            defaultValue={PanelViewOptions[0].value}
            onChange={e => handleViewSelection(e.target.value)}
          >
            {/* Existing program updates */}
            {PanelViewOptions.map((item, idx) => (
              <option key={idx} value={item.value} className="text-base">
                {item.label}
              </option>
            ))}
          </select>
        </div>
      }
    />
  );

  const filterBar = (
    <React.Fragment>
      {/* Action bar */}
      <div className="rounded-md mb-2 items-center py-0 text-sm gap-x-3">
        {/* Filters */}
        <div className="flex justify-between px-1 pt-0">
          <div className="flex items-center gap-x-3 gap-y-2 flex-wrap">
            <span className="flex items-center gap-x-2 font-semibold mr-2 w-14 whitespace-nowrap">
              Filter by
            </span>
            {/* {iconFilter}  */}

            {/* TODO: Fix this - currently causes a screen crash */}
            {/* Common filters */}
            {/* <FilterButton
              field="Strategic Pillar"
              portfolio={portfolio}
              inclusions={includedPillars}
              setInclusions={setIncludedPillars}
            /> */}

            {/* These filters are only shown for certain views */}
            {selectedView === 'VIEW_STRATEGIC_OBJECTIVES' ? (
              <React.Fragment>
                <FilterButton
                  field="Strategic Objective"
                  portfolio={portfolio}
                  inclusions={includedObjectives}
                  setInclusions={setIncludedObjectives}
                />
                <FilterButton
                  field="Strategic Objective Status"
                  inclusions={includedObjectiveStatus}
                  setInclusions={setIncludedObjectiveStatus}
                />
              </React.Fragment>
            ) : selectedView === 'VIEW_PROGRAMS' ? (
              <React.Fragment>
                <FilterButton
                  field={`${capitaliseFirstLetter(
                    enumTranslates[EntityType.Program]
                  )}`}
                  programs={programsList || undefined}
                  inclusions={includedPrograms}
                  setInclusions={setIncludedPrograms}
                />
                <FilterButton
                  field="Business Owner"
                  programs={programsList || undefined}
                  inclusions={includedOwners}
                  setInclusions={setIncludedOwners}
                />
                <FilterButton
                  field={`${translate(
                    strings.PROJECT_DELIVERY_CONFIDENCE,
                    'Confidence'
                  )}`}
                  inclusions={includedConfidence}
                  setInclusions={setIncludedConfidence}
                />
              </React.Fragment>
            ) : selectedView === 'VIEW_PROGRAM_BENEFITS' ? (
              <React.Fragment>
                <FilterButton
                  field="Strategic Objective"
                  portfolio={portfolio}
                  inclusions={includedObjectives}
                  setInclusions={setIncludedObjectives}
                />
                <FilterButton
                  field="Strategic Objective Status"
                  inclusions={includedObjectiveStatus}
                  setInclusions={setIncludedObjectiveStatus}
                />
                <FilterButton
                  field={`${capitaliseFirstLetter(
                    enumTranslates[EntityType.Program]
                  )}`}
                  programs={programsList || undefined}
                  inclusions={includedPrograms}
                  setInclusions={setIncludedPrograms}
                />
                <FilterButton
                  field={`${translate(
                    strings.PROGRAM_MEASURE,
                    'Program Benefit'
                  )} Status`}
                  inclusions={includedBenefitStatus}
                  setInclusions={setIncludedBenefitStatus}
                />
              </React.Fragment>
            ) : null}
          </div>

          {/* Clear all button */}
          <div className="place-self-start">
            {(selectedView === 'VIEW_STRATEGIC_OBJECTIVES' &&
              (includedPillars.length > 0 ||
                includedObjectives.length > 0 ||
                includedObjectiveStatus.length > 0)) ||
            (selectedView === 'VIEW_PROGRAMS' &&
              (includedPillars.length > 0 ||
                includedPrograms.length > 0 ||
                includedOwners.length > 0 ||
                includedConfidence.length > 0)) ||
            (selectedView === 'VIEW_PROGRAM_BENEFITS' &&
              (includedPillars.length > 0 ||
                includedObjectives.length > 0 ||
                includedObjectiveStatus.length > 0 ||
                includedPrograms.length > 0 ||
                includedBenefitStatus.length > 0)) ? (
              <span>
                <button
                  type="button"
                  className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 whitespace-nowrap"
                  onClick={() => handleClearFiltersClick()}
                >
                  Clear all
                </button>
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  const themesToShow = themes?.filter(
    theme =>
      includedPillars.length === 0 ||
      includedPillars.some(item => item === theme.id)
  );

  function getObjectivesToShowByTheme(themeId: string): StrategicObjective[] {
    return objectives?.filter(
      objective =>
        objective.strategicThemeId == themeId &&
        (includedObjectives.length === 0 ||
          includedObjectives.some(item => item === objective.id)) &&
        (includedObjectiveStatus.length === 0 ||
          includedObjectiveStatus.some(
            item =>
              getLatestObjectiveUpdate(portfolioUpdates, objective.id)
                ?.lastUpdate?.status === item
          ))
    );
  }

  function getBenefitsToShowByObjective(
    objectiveId: string
  ): (ProgramMeasure | null)[] {
    const result = getProgramBenefitsByObjective(objectiveId).filter(
      measure =>
        (includedPrograms.length === 0 ||
          includedPrograms.some(item => {
            let filter = false;
            if (measure) {
              filter = item === getParentProgram(programsList, measure.id)?.id;
            }
            return filter;
          })) &&
        (includedOwners.length === 0 ||
          (measure &&
            (
              getParentProgram(programsList, measure.id)?.members
                ?.items as ProgramMember[]
            ).some(
              m =>
                m.role === ProgramRole.BusinessOwner &&
                includedOwners.includes(m.user.id)
            ))) &&
        (includedBenefitStatus.length === 0 ||
          includedBenefitStatus.some(
            item =>
              measure &&
              getLatestProgramBenefitStatus(measure, programsList) === item
          ))
    );

    return result;
  }

  function getProgramsToShowByTheme(themeId: string): (Program | undefined)[] {
    const result = programsList?.filter(
      program =>
        program?.primaryStrategicThemeId === themeId &&
        (includedPrograms.length === 0 ||
          includedPrograms.some(item => {
            let filter = false;
            if (program) {
              filter = item === program?.id;
            }
            return filter;
          })) &&
        (includedOwners.length === 0 ||
          (program?.members?.items as ProgramMember[]).some(
            m =>
              m.role === ProgramRole.BusinessOwner &&
              includedOwners.includes(m.user.id)
          )) &&
        (includedConfidence.length === 0 ||
          includedConfidence.some(rating => {
            let filter = false;
            if (program) {
              filter = getLatestProgramUpdate(program)?.trend === rating;
            }
            return filter;
          }))
    );

    return result ? result : [];
  }

  function ThemeSeparatorRow(theme: StrategicTheme): React.ReactElement {
    const [isExpanded, setIsExpanded] = useState(false);
    const iconExpand = (
      <CaretDown weight="bold" className="h-4 w-4 text-indigo-700 shrink-0" />
    );
    const iconCollapse = (
      <CaretUp weight="bold" className="h-4 w-4 text-indigo-700 shrink-0" />
    );

    const themeObjectives = objectives?.filter(
      objective => objective.strategicThemeId == theme.id
    );
    return (
      <tr key={ulid()} className="table-row bg-indigo-100">
        <td
          colSpan={100}
          className="table-cell w-16 text-left text-xs align-top"
        >
          <button
            type="button"
            className={`flex flex-col w-full px-4 py-0.5 ${
              selectedView === 'VIEW_PROGRAMS' ? 'hover:bg-indigo-200' : ''
            }`}
            onClick={() => setIsExpanded(!isExpanded)}
            disabled={selectedView != 'VIEW_PROGRAMS'}
          >
            <div className="flex items-center space-x-2">
              <div className="flex items-baseline space-x-2">
                <span className="text-xs text-white font-bold bg-indigo-600 rounded-full w-5 h-5 flex items-center align-middle justify-center text-center flex-shrink-0">
                  {`${numToChar(themes.indexOf(theme) + 1)}`}
                </span>
                <span className="font-medium text-indigo-800 text-sm">{`${theme.name}`}</span>
              </div>
              {selectedView === 'VIEW_PROGRAMS' ? (
                <div className="flex items-center">
                  <div className="mt-0 flex items-center justify-center rounded-full w-5 h-5 shrink-0">
                    {isExpanded ? iconCollapse : iconExpand}
                  </div>
                </div>
              ) : null}
            </div>

            {isExpanded ? (
              <div className="text-left mt-0.5 ml-7">
                {selectedView === 'VIEW_PROGRAMS' ? (
                  themeObjectives.length > 0 ? (
                    <React.Fragment>
                      <div className="font-medium mb-1">
                        {translate(
                          strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                          'Strategic Objectives'
                        )}
                        :
                      </div>
                      <ul className="list-disc list-outside ml-5">
                        {themeObjectives.map(objective => {
                          return <li key={ulid()}>{objective.description}</li>;
                        })}
                      </ul>
                    </React.Fragment>
                  ) : (
                    <div className="italic text-gray-500">{`No ${translate(
                      strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                      'Strategic Objectives'
                    ).toLowerCase()} added yet`}</div>
                  )
                ) : null}
              </div>
            ) : null}
          </button>
        </td>
      </tr>
    );
  }

  const strategicObjectivesView = (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        {/* Program update delete confirmation modal */}
        {modalOpen && update?.date && (
          <Modal width={'w-full max-w-xl'} onClose={() => setModalOpen(false)}>
            <div className="flex flex-col space-y-4 bg-white p-4 md:w-full overflow-y-auto align-middle">
              <div className="flex flex-row items-center">
                <span className="flex p-2 rounded-full bg-red-600 align-middle items-center">
                  <Trash
                    weight="fill"
                    className="h-5 w-5 text-white mx-auto my-auto"
                  />
                </span>
                <h3 className="text-lg font-medium ml-3 text-gray-900">
                  Confirm Delete
                </h3>
              </div>
              <p className="mt-3 text-sm text-gray-500">
                {`Deleting a ${translate(
                  strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                  'Strategic Objective'
                ).toLowerCase()} review cannot be undone.`}
              </p>
              <p className="mt-3 text-sm text-gray-500">
                If you still want to delete the review for "
                {
                  portfolio?.objectives?.find(
                    obj => obj.id === update?.lastUpdate?.strategicObjectiveId
                  )?.description
                }
                " from {getLocalDate(update?.date)}, press the "Delete review"
                button below.
              </p>
              <span className="text-right space-x-3">
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  onClick={() =>
                    deleteObjectiveUpdate(
                      update?.update as PortfolioUpdate,
                      update?.lastUpdate?.strategicObjectiveId as string
                    )
                  }
                >
                  Delete review
                </button>
              </span>
            </div>
          </Modal>
        )}

        {/* Updated table */}
        <div className="overflow-hidden border border-gray-200 rounded-lg">
          {/* Tailwind grid table style */}
          <table className="table min-w-full divide-y divide-gray-200">
            <thead className="table-header-group bg-gray-50">
              {/* Header row */}
              <tr className="table-row">
                <th className="table-cell w-1/3 pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                  {translate(
                    strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                    'Strategic Priorities'
                  )}
                  {/* <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                        {translate(
                          strings.PORTFOLIO_STRATEGIC_OBJECTIVES_DEFINITION,
                          'Strategic Priorities Definition'
                        )}
                      </p> */}
                </th>

                <th className="table-cell pl-2 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                  Latest Comments
                  {/* <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                        Executive summary of how each objective is going
                      </p> */}
                </th>

                {currentUserRole && hasEditPermissions && (
                  <th className="table-cell w-20 pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                    Actions
                    {/* <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                        Executive summary of how each objective is going
                      </p> */}
                  </th>
                )}
              </tr>
            </thead>

            {/* Table data */}
            <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
              {themesToShow && themesToShow.length > 0
                ? themesToShow?.map(
                    (theme: StrategicTheme, themeIndex: number) => (
                      <React.Fragment key={themeIndex}>
                        {/* Separator row */}
                        {ThemeSeparatorRow(theme)}

                        {/* Actual content */}
                        {getObjectivesToShowByTheme(theme.id).length > 0 ? (
                          getObjectivesToShowByTheme(theme.id)?.map(
                            (
                              objective: StrategicObjective,
                              objectiveIndex: number
                            ) => {
                              const lastUpdate = getLatestObjectiveUpdate(
                                portfolioUpdates,
                                objective.id
                              );
                              return (
                                // Create a row for each objective
                                <tr
                                  key={`${themeIndex}-${objectiveIndex}`}
                                  className="table-row"
                                >
                                  {/* Strategic objective */}
                                  <td
                                    className={`table-cell pl-4 pr-4 py-3 whitespace-normal align-top border-t border-gray-200 ${
                                      slideoverToShow ===
                                        'STRATEGIC_OBJECTIVE' &&
                                      slideOverObjective?.id ===
                                        objective?.id &&
                                      highlightRow
                                        ? 'bg-gray-100'
                                        : ''
                                    }`}
                                  >
                                    <div className="flex align-top gap-x-3">
                                      {/* Status dot */}
                                      <span className="ml-1">
                                        {getStatusDot(
                                          lastUpdate?.lastUpdate
                                            ?.status as string,
                                          lastUpdate?.date,
                                          lastUpdate?.lastUpdate
                                            ?.strategicObjectiveId as string
                                        )}
                                        {/* <p className="text-sm text-gray-900">
                                          {lastUpdate?.lastUpdate?.status}
                                        </p> */}
                                      </span>

                                      {/* Text */}
                                      <div>
                                        <button
                                          className={`font-medium text-left ${
                                            currentUserRole
                                              ? 'hover:underline'
                                              : ''
                                          }`}
                                          onClick={() =>
                                            handleStrategicObjectiveClick(
                                              objective
                                            )
                                          }
                                          disabled={!currentUserRole}
                                        >
                                          {objective.description}
                                        </button>

                                        <div>
                                          <span className="flex align-top mt-1 text-gray-500">
                                            {iconTarget}
                                            <p className="ml-1">
                                              {!objective.targets ||
                                              objective.targets.length < 1
                                                ? 'No targets set'
                                                : `${
                                                    objective.targets[0]
                                                      ?.targetValue
                                                  }${
                                                    objective.targets[0]
                                                      ?.achieveByDate
                                                      ? ', by ' +
                                                        getLocalDate(
                                                          objective.targets[0]
                                                            ?.achieveByDate
                                                        )
                                                      : ' (no target date)'
                                                  }`}
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  {/* Latest update data */}
                                  <td className="table-cell pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200 align-top">
                                    {lastUpdate?.date ? (
                                      <React.Fragment>
                                        {/* Show remarks if they exist */}
                                        {lastUpdate?.lastUpdate?.remarks &&
                                        lastUpdate?.lastUpdate?.remarks
                                          ?.length > 0 ? (
                                          <p className="text-sm whitespace-pre-wrap">
                                            {/* TODO: Remove this check when satisfaction field added to data model */}
                                            {lastUpdate?.lastUpdate
                                              ?.remarks[0] === '['
                                              ? lastUpdate?.lastUpdate?.remarks
                                                  .slice(3)
                                                  .trim()
                                              : lastUpdate?.lastUpdate?.remarks}
                                          </p>
                                        ) : (
                                          <p className="text-sm text-gray-500 italic">
                                            No remarks added in latest review.
                                          </p>
                                        )}

                                        {/* Show date if applicable */}
                                        {lastUpdate?.date ? (
                                          <p className="mt-3 text-gray-500 text-xs text-right">
                                            {`${
                                              lastUpdate?.date
                                                ? `${getLocalDate(
                                                    lastUpdate?.date
                                                  )} (${getRelativeTime(
                                                    lastUpdate?.date
                                                  )})`
                                                : 'Never'
                                            }`}
                                          </p>
                                        ) : null}
                                      </React.Fragment>
                                    ) : (
                                      <p className="text-sm text-gray-500 italic">
                                        No updates yet.
                                      </p>
                                    )}
                                  </td>

                                  {/* Action buttons */}

                                  {currentUserRole && hasEditPermissions && (
                                    <td className="table-cell pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200 align-end">
                                      {hasEditPermissions &&
                                        lastUpdate?.update !== null &&
                                        lastUpdate?.lastUpdate
                                          ?.strategicObjectiveId &&
                                        !isDemo && (
                                          <div className="inline-flex gap-x-1">
                                            {/* Edit button */}
                                            <span
                                              data-tip
                                              data-for={`edit-${lastUpdate?.lastUpdate?.strategicObjectiveId}`}
                                            >
                                              {/* <OpenWizardButton
                                                title={translate(
                                                  strings.WIZARD_EDIT_PORTFOLIO_REVIEW,
                                                  'Edit Portfolio Review'
                                                )}
                                                editObject={
                                                  lastUpdate?.update || null
                                                }
                                                parentObject={portfolio}
                                                type="PORTFOLIO_UPDATE"
                                                rowId={
                                                  lastUpdate?.lastUpdate
                                                    ?.strategicObjectiveId
                                                }
                                                startStep={1}
                                              /> */}
                                            </span>
                                            <ReactTooltip
                                              id={`edit-${lastUpdate?.lastUpdate?.strategicObjectiveId}`}
                                              // place="left"
                                              // type="dark"
                                              effect="solid"
                                            >
                                              <div className="text-sm">
                                                Edit the latest review for this{' '}
                                                {translate(
                                                  strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                                                  'Strategic Objective'
                                                ).toLowerCase()}
                                              </div>
                                            </ReactTooltip>

                                            {/* Delete button */}
                                            <button
                                              className={
                                                'group items-center text-sm  hover:bg-red-600 rounded-full'
                                              }
                                              onClick={() => {
                                                setUpdate(lastUpdate);
                                                setModalOpen(true);
                                              }}
                                            >
                                              <span
                                                className="block p-1 shrink-0"
                                                data-tip
                                                data-for={`delete-${lastUpdate?.lastUpdate?.strategicObjectiveId}`}
                                              >
                                                <Trash
                                                  weight="bold"
                                                  className="h-5 w-5 text-gray-400 group-hover:text-white"
                                                />
                                              </span>
                                            </button>
                                            <ReactTooltip
                                              id={`delete-${lastUpdate?.lastUpdate?.strategicObjectiveId}`}
                                              // place="left"
                                              // type="dark"
                                              effect="solid"
                                            >
                                              <div className="text-sm text-center">
                                                <p>
                                                  Delete the latest review for
                                                  this{' '}
                                                  {translate(
                                                    strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                                                    'Strategic Objective'
                                                  ).toLowerCase()}
                                                </p>
                                                <p className="text-xs mt-0.5">
                                                  (Requires confirmation)
                                                </p>
                                              </div>
                                            </ReactTooltip>
                                          </div>
                                        )}
                                    </td>
                                  )}
                                </tr>
                              );
                            }
                          )
                        ) : (
                          // There are no objectives to show for this pillar
                          <tr className="table-row italic text-gray-500">
                            <td className="table-cell w-16 px-4 py-3">
                              {`No relevant ${translate(
                                strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                                'Strategic Objectives'
                              ).toLowerCase()}`}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  )
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const programPerformanceView = (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        {/* Updated table */}
        <div className="overflow-hidden border border-gray-200 rounded-lg">
          {/* Tailwind grid table style */}
          <table className="table min-w-full divide-y divide-gray-200">
            <thead className="table-header-group bg-gray-50">
              {/* Header row */}
              <tr className="table-row">
                <th className="table-cell w-1/3 pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                  {`${enumTranslates[EntityType.Program]}s & ${translate(
                    strings.PROJECT_DELIVERY_CONFIDENCE,
                    'Delivery Confidence'
                  )}`}
                  {/* <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                        {translate(
                          strings.PORTFOLIO_STRATEGIC_OBJECTIVES_DEFINITION,
                          'Strategic Priorities Definition'
                        )}
                      </p> */}
                </th>

                <th className="table-cell pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                  Latest Comments
                  {/* <p className="text-xs normal-case font-normal mt-1 tracking-normal">
                        Executive summary of how each objective is going
                      </p> */}
                </th>
              </tr>
            </thead>

            {/* Table data */}
            <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
              {themesToShow && themesToShow.length > 0
                ? themesToShow?.map(
                    (theme: StrategicTheme, themeIndex: number) => (
                      <React.Fragment key={themeIndex}>
                        {/* Separator row */}
                        {ThemeSeparatorRow(theme)}

                        {/* Actual content */}
                        {getProgramsToShowByTheme(theme.id).length > 0 ? (
                          getProgramsToShowByTheme(theme.id)?.map(program =>
                            program ? (
                              // Create a row for each objective
                              <tr
                                key={`${themeIndex}-${program.id}`}
                                className={`table-row hover:bg-gray-100 ${
                                  slideoverToShow === 'PROGRAM' &&
                                  slideOverProgram?.id === program.id &&
                                  highlightRow
                                    ? 'bg-gray-100'
                                    : ''
                                }`}
                              >
                                {/* Strategic objective */}
                                <td
                                  className={`table-cell pl-4 pr-6 py-3 whitespace-normal align-top border-t border-gray-200`}
                                >
                                  <div className="flex align-baseline">
                                    <ConfidenceBadge
                                      rating={
                                        getLatestProgramUpdate(program)?.trend
                                      }
                                      reviewDate={
                                        getLatestProgramUpdate(program)
                                          ?.updateDate
                                      }
                                      size="shortened"
                                    />
                                    <button
                                      className={`group font-medium text-left ml-3 mt-0.5 ${
                                        currentUserRole ? 'hover:underline' : ''
                                      }`}
                                      onClick={() =>
                                        handleProgramClick(program)
                                      }
                                      disabled={!currentUserRole}
                                    >
                                      {program.name}
                                      {/* <span className="inline-block ml-1.5">
                                          {iconSidebar}
                                        </span> */}
                                    </button>
                                  </div>
                                </td>

                                {/* Latest update data */}
                                <td className="table-cell pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200 align-top">
                                  {getLatestProgramUpdate(program) ? (
                                    <React.Fragment>
                                      {/* <div className="flex items-center gap-x-2 -mt-0.5">
                                      <span className="font-semibold">
                                        {translate(
                                          strings.PROJECT_DELIVERY_CONFIDENCE,
                                          'Delivery Confidence'
                                        )}
                                        :
                                      </span>
                                      <ConfidenceBadge
                                        rating={
                                          getLatestProgramUpdate(program)?.trend
                                        }
                                      />
                                    </div> */}
                                      <p className="text-sm text-gray-900 mt-0.5 whitespace-pre-wrap">
                                        {
                                          getLatestProgramUpdate(program)
                                            ?.remark
                                        }
                                      </p>
                                      <p className="my-2 text-gray-500 text-xs text-right">
                                        {`${
                                          getLatestProgramUpdate(program)
                                            ?.updateDate
                                            ? `${getLocalDate(
                                                getLatestProgramUpdate(program)
                                                  ?.updateDate
                                              )} (${getRelativeTime(
                                                getLatestProgramUpdate(program)
                                                  ?.updateDate
                                              )})`
                                            : ''
                                        }`}
                                      </p>
                                    </React.Fragment>
                                  ) : (
                                    <div className="italic text-gray-500 mt-0.5">
                                      No program reviews yet
                                    </div>
                                  )}
                                </td>
                              </tr>
                            ) : null
                          )
                        ) : (
                          // There are no programs to show for this pillar
                          <tr className="table-row italic text-gray-500">
                            <td className="table-cell w-16 px-4 py-3">
                              {`No relevant programs`}
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    )
                  )
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const programBenefitsView = (
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        {/* Updated table */}
        <div className="overflow-hidden border border-gray-200 rounded-lg">
          {/* Tailwind grid table style */}
          <table className="table min-w-full divide-y divide-gray-200">
            <thead className="table-header-group bg-gray-50">
              {/* Header row */}
              <tr className="table-row">
                <th className="table-cell w-1/3 pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                  {translate(
                    strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                    'Strategic Priorities'
                  )}
                </th>

                <th className="table-cell pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-top">
                  {translate(strings.PROGRAM_MEASURES, 'Program Benefits')}
                </th>
              </tr>
            </thead>

            {/* Table data */}
            <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
              {themesToShow && themesToShow.length > 0
                ? themesToShow?.map(
                    (theme: StrategicTheme, themeIndex: number) => (
                      <React.Fragment key={themeIndex}>
                        {/* Separator row */}
                        {ThemeSeparatorRow(theme)}

                        {/* Actual content */}
                        {getObjectivesToShowByTheme(theme.id).length > 0 ? (
                          getObjectivesToShowByTheme(theme.id)?.map(
                            (objective: StrategicObjective) => {
                              const lastUpdate = getLatestObjectiveUpdate(
                                portfolioUpdates,
                                objective.id
                              );

                              const benefits = getBenefitsToShowByObjective(
                                objective.id
                              );

                              return benefits.length > 0 ? (
                                benefits.map((benefit, bIndex) => {
                                  const parentProgram = getParentProgram(
                                    programsList,
                                    benefit?.id
                                  );

                                  return (
                                    <tr key={ulid()} className="table-row">
                                      {/* rowSpan={benefits.length} */}
                                      <td
                                        className={`table-cell pl-4 pr-2 py-3 ${
                                          bIndex === 0
                                            ? 'border-t border-gray-200'
                                            : 'hidden'
                                        } ${
                                          slideoverToShow ===
                                            'STRATEGIC_OBJECTIVE' &&
                                          slideOverObjective?.id ===
                                            objective?.id &&
                                          highlightRow
                                            ? 'bg-gray-100'
                                            : ''
                                        }`}
                                      >
                                        <div className="flex align-top gap-x-3">
                                          {/* Status dot */}
                                          <span className="ml-1">
                                            {getStatusDot(
                                              lastUpdate?.lastUpdate
                                                ?.status as string,
                                              lastUpdate?.date,
                                              lastUpdate?.lastUpdate
                                                ?.strategicObjectiveId as string
                                            )}
                                          </span>

                                          {/* Text */}
                                          <div>
                                            <button
                                              className={`font-medium text-left ${
                                                currentUserRole
                                                  ? 'hover:underline'
                                                  : ''
                                              }`}
                                              onClick={() =>
                                                handleStrategicObjectiveClick(
                                                  objective
                                                )
                                              }
                                              disabled={!currentUserRole}
                                            >
                                              {objective.description}
                                            </button>
                                            <div>
                                              <span className="flex align-top mt-1 text-gray-500">
                                                {iconTarget}
                                                <p className="ml-1">
                                                  {!objective.targets ||
                                                  objective.targets.length < 1
                                                    ? 'No targets set'
                                                    : `${
                                                        objective.targets[0]
                                                          ?.targetValue
                                                      }${
                                                        objective.targets[0]
                                                          ?.achieveByDate
                                                          ? ', by ' +
                                                            getLocalDate(
                                                              objective
                                                                .targets[0]
                                                                ?.achieveByDate
                                                            )
                                                          : ' (no target date)'
                                                      }`}
                                                </p>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td
                                        className={`table-cell hover:bg-gray-100 ${
                                          slideoverToShow ===
                                            'PROGRAM_BENEFIT' &&
                                          slideOverProgramMeasure?.id ===
                                            benefit?.id &&
                                          highlightRow
                                            ? 'bg-gray-100'
                                            : ''
                                        } pl-3 pr-4 py-3 border-t border-gray-200`}
                                      >
                                        <div className="flex align-top">
                                          <span className="flex items-center mb-auto">
                                            {!hasStatus(benefit, 'previous') &&
                                            !hasStatus(benefit, 'latest') ? (
                                              <span className="flex items-center w-3.5 mt-0.5">
                                                {/* -mt-0.5 */}
                                                {ProgramMeasureDot(
                                                  benefit,
                                                  'latest'
                                                )}
                                                {/* <span className="ml-1.5">
                                                  <Tag type="new" />
                                                </span> */}
                                              </span>
                                            ) : (
                                              <span className="flex items-center w-3.5 mt-0.5">
                                                {/* Hide historical dot for now */}
                                                {/* {ProgramMeasureDot(
                                                  benefit,
                                                  'previous'
                                                )}
                                                <span className="mx-1.5">
                                                  {iconRightArrow}
                                                </span> */}
                                                {ProgramMeasureDot(
                                                  benefit,
                                                  'latest'
                                                )}
                                              </span>
                                            )}
                                          </span>

                                          <div className="ml-2.5 -mt-0.5">
                                            <button
                                              type="button"
                                              className={`flex align-baseline gap-x-2 text-left font-medium group`}
                                              onClick={() =>
                                                benefit &&
                                                handleProgramBenefitClick(
                                                  benefit
                                                )
                                              }
                                              disabled={!currentUserRole}
                                            >
                                              <span
                                                className={`${
                                                  currentUserRole
                                                    ? 'group-hover:underline'
                                                    : ''
                                                }`}
                                              >
                                                {benefit?.name}
                                              </span>
                                              {!hasStatus(
                                                benefit,
                                                'previous'
                                              ) &&
                                              !hasStatus(benefit, 'latest') ? (
                                                <Tag type="new" />
                                              ) : null}
                                            </button>

                                            {parentProgram && (
                                              <PermissionLink
                                                entity={parentProgram}
                                                to={`${PROGRAM}/${parentProgram?.id}#performance`}
                                                allowedLayout={
                                                  <p className="text-xs text-primary-600 mt-0.5 hover:underline hover:text-primary-600">
                                                    {`${parentProgram?.name}`}
                                                  </p>
                                                }
                                                blockedLayout={
                                                  <p className="text-xs text-gray-500 mt-0.5">
                                                    {`${parentProgram?.name}`}
                                                  </p>
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                // Row for when the program benefit has been filtered out
                                // We still want to show the data for strategic objective
                                <tr key={ulid()} className="table-row">
                                  <td
                                    className={`table-cell pl-4 pr-2 py-3 border-t border-gray-200 ${
                                      slideoverToShow ===
                                        'STRATEGIC_OBJECTIVE' &&
                                      slideOverObjective?.id ===
                                        objective?.id &&
                                      highlightRow
                                        ? 'bg-gray-100'
                                        : ''
                                    }`}
                                  >
                                    <div className="flex align-top gap-x-3">
                                      {/* Status dot */}
                                      <span className="ml-1">
                                        {getStatusDot(
                                          lastUpdate?.lastUpdate
                                            ?.status as string,
                                          lastUpdate?.date,
                                          lastUpdate?.lastUpdate
                                            ?.strategicObjectiveId as string
                                        )}
                                      </span>

                                      {/* Text */}
                                      <div>
                                        <button
                                          className="font-medium text-left hover:underline"
                                          onClick={() =>
                                            handleStrategicObjectiveClick(
                                              objective
                                            )
                                          }
                                        >
                                          {objective.description}
                                        </button>
                                        <div>
                                          <span className="flex align-top mt-1 text-gray-500">
                                            {iconTarget}
                                            <p className="ml-1">
                                              {!objective.targets ||
                                              objective.targets.length < 1
                                                ? 'No targets set'
                                                : `${
                                                    objective.targets[0]
                                                      ?.targetValue
                                                  }${
                                                    objective.targets[0]
                                                      ?.achieveByDate
                                                      ? ', by ' +
                                                        getLocalDate(
                                                          objective.targets[0]
                                                            ?.achieveByDate
                                                        )
                                                      : ' (no target date)'
                                                  }`}
                                            </p>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  <td
                                    className={`table-cell pl-2 pr-4 py-3 border-t border-gray-200 italic text-gray-500`}
                                  >
                                    <div className="flex align-top">{`No relevant ${translate(
                                      strings.PROGRAM_MEASURES,
                                      'Program Benefits'
                                    ).toLowerCase()}`}</div>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        ) : (
                          // There are no objectives to show for this pillar
                          <div className="table-row italic text-gray-500">
                            <div className="table-cell w-16 px-4 py-3">
                              {`No relevant ${translate(
                                strings.PORTFOLIO_STRATEGIC_OBJECTIVES,
                                'Strategic Objectives'
                              ).toLowerCase()}`}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    )
                  )
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const panelContent =
    loadingPrograms || loadingRemove || loadingUpdate ? (
      <div className="flex flex-col align-middle items-center">
        <Spinner text={'Loading data...'} />
      </div>
    ) : errorPrograms ? (
      <div className="text-center">
        {iconError}
        <h3 className="mt-2 text-sm font-medium text-gray-900">
          Something isn't quite right...
        </h3>
        <p className="mt-1 text-sm text-gray-500">{errorPrograms?.message}</p>
      </div>
    ) : themes?.length == 0 ? (
      <EmptyState
        type="strategic pillars"
        secondaryText="Get started by adding a strategic pillar."
      />
    ) : objectives?.length == 0 ? (
      <EmptyState
        type="strategic objectives"
        secondaryText="Get started by adding a strategic objective."
      />
    ) : (
      <div className="space-y-2">
        {filterBar}
        {selectedView === 'VIEW_STRATEGIC_OBJECTIVES'
          ? strategicObjectivesView
          : selectedView === 'VIEW_PROGRAMS'
          ? programPerformanceView
          : selectedView === 'VIEW_PROGRAM_BENEFITS'
          ? programBenefitsView
          : null}
      </div>
    );

  const performanceButton = (
    <React.Fragment>
      {/* {hasEditPermissions && selectedView === 'VIEW_STRATEGIC_OBJECTIVES' ? (
        <OpenWizardButton
          title={translate(
            strings.WIZARD_NEW_PORTFOLIO_REVIEW,
            'New Portfolio Review'
          )}
          editObject={null}
          parentObject={portfolio}
          type="PORTFOLIO_UPDATE"
          startStep={1}
        />
      ) : null} */}
    </React.Fragment>
  );

  // Setup slideover panels
  const programSlideOver = (
    <SlideOver
      open={showSlideOver}
      setOpen={setShowSlideOver}
      title={slideOverProgram ? slideOverProgram.name : ''}
      titleLink={
        slideOverProgram ? `${PROGRAM}/${slideOverProgram.id}#performance` : ''
      }
      meta={
        <React.Fragment>
          {slideOverProgram?.outcome ? (
            <div>{slideOverProgram?.outcome}</div>
          ) : (
            <div className="italic text-gray-500">No description provided.</div>
          )}
        </React.Fragment>
      }
      content={
        <ProgramSlideoverLayout
          slideOverProgram={slideOverProgram}
          programs={programsList || []}
        />
      }
    />
  );

  function handleProgramClick(program: Program) {
    setSlideoverToShow('PROGRAM');
    setSlideOverProgram(program);
    setShowSlideOver(true);
  }

  const programBenefitSlideOver = (
    <SlideOver
      open={showSlideOver}
      setOpen={setShowSlideOver}
      title={slideOverProgramMeasure ? slideOverProgramMeasure.name : ''}
      // titleLink={
      //   slideOverProgramMeasure ? `${PROGRAM}/${slideOverProgram.id}#performance` : ''
      // }
      meta={
        <div className="space-y-2 mt-1">
          {/* Target */}
          <div className="flex space-x-2">
            <span className="-mb-0.5 inline-block">{iconTarget}</span>

            {slideOverProgramMeasure?.targetValue ? (
              <div>{`${slideOverProgramMeasure?.targetValue}${
                slideOverProgramMeasure?.achieveByDate
                  ? `, by ${getLocalDate(
                      slideOverProgramMeasure?.achieveByDate
                    )}`
                  : ' (someday)'
              }`}</div>
            ) : (
              <div className="italic text-gray-500">No target provided.</div>
            )}
          </div>

          {/* Parent program */}
          <div className="flex space-x-2">
            <span className="inline-block">{iconProgram}</span>
            <Link
              to={`${PROGRAM}/${
                getParentProgram(programsList, slideOverProgramMeasure?.id)?.id
              }#performance`}
            >
              <span className="hover:underline hover:text-primary-600">
                {
                  getParentProgram(programsList, slideOverProgramMeasure?.id)
                    ?.name
                }
              </span>
            </Link>
          </div>
        </div>
      }
      content={
        <ProgramBenefitsSlideoverLayout
          slideOverProgramMeasure={slideOverProgramMeasure}
          programs={programsList || []}
        />
      }
    />
  );

  function handleProgramBenefitClick(programMeasure: ProgramMeasure) {
    setSlideoverToShow('PROGRAM_BENEFIT');
    setSlideOverProgramMeasure(programMeasure);
    setShowSlideOver(true);
  }

  const objectiveSlideOver = (
    <SlideOver
      open={showSlideOver}
      setOpen={setShowSlideOver}
      title={slideOverObjective ? slideOverObjective.description : ''}
      // titleLink={
      //   slideOverProgramMeasure ? `${PROGRAM}/${slideOverProgram.id}#performance` : ''
      // }
      meta={
        <div className="space-y-2 mt-1">
          {/* Target */}
          <div className="flex space-x-2">
            <span className="-mb-0.5 inline-block">{iconTarget}</span>

            {slideOverObjective?.targets &&
            slideOverObjective?.targets.length > 0 ? (
              <div className="space-y-2">
                {slideOverObjective?.targets?.map(target => {
                  return (
                    <div
                      key={ulid()}
                      className={`${
                        moment(target.achieveByDate).isBefore(new Date())
                          ? 'text-gray-500 line-through'
                          : ''
                      }`}
                    >{`${target?.targetValue}${
                      target?.achieveByDate
                        ? `, by ${getLocalDate(target?.achieveByDate)}`
                        : ' (someday)'
                    }`}</div>
                  );
                })}
              </div>
            ) : (
              <div className="italic text-gray-500">No target provided.</div>
            )}
          </div>
        </div>
      }
      content={
        <ObjectiveSlideoverLayout
          strategicObjective={slideOverObjective}
          portfolioUpdates={portfolioUpdates || []}
          programs={programsList || []}
        />
      }
    />
  );

  function handleStrategicObjectiveClick(objective: StrategicObjective) {
    setSlideoverToShow('STRATEGIC_OBJECTIVE');
    setSlideOverObjective(objective);
    setShowSlideOver(true);
  }

  return (
    <React.Fragment>
      <DetailsPanel
        headerLeft={panelTitle}
        headerRight={performanceButton}
        content={panelContent}
      />
      {slideoverToShow === 'STRATEGIC_OBJECTIVE'
        ? objectiveSlideOver
        : slideoverToShow === 'PROGRAM'
        ? programSlideOver
        : slideoverToShow === 'PROGRAM_BENEFIT'
        ? programBenefitSlideOver
        : null}
    </React.Fragment>
  );
}

export default PortfolioPerformancePanel;
