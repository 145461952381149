import React, { useContext } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import { enumTranslates } from '../../../common/i18n/translate';
import { Program, Project, ProjectStage } from '../../../api/index';
import { ProgramPageContext } from '../../program/ProgramPageContext';
import { PortfolioPageContext } from '../../portfolio/PortfolioPageContext';
import { ProjectPageContext } from '../../project/ProjectPageContext';
import { getLocalDate, getRelativeTime } from '../../../common/utils';

type Props = {
  entity: Project | Program;
  editButton: React.ReactElement;
  // hasEditPermissions: boolean;
  // hasOwnerPermissions: boolean;
};

function SimpleTextDisplay({
  label,
  content,
  isNa,
}: DisplayProps): React.ReactElement {
  const layout = (
    <div className="flex flex-col lg:flex-row">
      <div className={`pr-4 pb-1 align-top lg:w-32 lg:shrink-0`}>
        <p className="font-semibold">{label}</p>
      </div>
      <div className={`align-top whitespace-pre-wrap`}>
        <p className={isNa ? `italic text-gray-400` : `text-gray-600`}>
          {content}
        </p>
      </div>
    </div>
  );

  return layout;
}

type DisplayProps = {
  label: string;
  content: string;
  isNa: boolean;
};

export default function EntityAboutPanel({
  entity,
  editButton,
}: // hasEditPermissions,
// hasOwnerPermissions,
Props): React.ReactElement {
  // Note: For now we are duplicating the data getting calls because of the old hierarchy
  // In the Entity model, this will just be simplified.

  // Get entity context
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const { state: programPageState } = useContext(ProgramPageContext);
  const { state: projectPageState } = useContext(ProjectPageContext);

  // Determine the entity type
  const isProject = entity.__typename === `Project`;
  const thisEntity = isProject
    ? projectPageState.selectedProject
    : programPageState.selectedProgram;
  const parentEntity = isProject
    ? programPageState.selectedProgram
    : portfolioPageState.selectedPortfolio;

  const aboutTitle = (
    <DetailsPanelHeader
      title={`About`}
      description={`Background information about this entity`}
    />
  );

  // Set up content to show
  type aboutContent = { label: string; content: string; isNa: boolean };
  const aboutContentText: aboutContent[] = [
    {
      label: `Description`,
      content: entity?.outcome || `Not provided`,
      isNa: entity?.outcome?.length == 0,
    },
    {
      label: `Lifecycle stage`,
      // For lifecycle stage, we are temporarily forcing non projects to be in-flight
      // In v2, this will be based on the Entity and related logic
      content: `${
        !isProject
          ? enumTranslates[ProjectStage.Inflight]
          : entity?.stage
          ? enumTranslates[entity?.stage]
          : '-'
      }`, // We'll have a stage for "program" entities in the v2
      isNa: false,
    },
    {
      label: `Start date`,
      content:
        `${getLocalDate(entity?.startDate)} (${getRelativeTime(
          entity.startDate
        )})` || `Not provided`,
      isNa: !entity?.startDate,
    },
    {
      label: `End date`,
      content: `${
        isProject && entity?.deliveryDate
          ? `${getLocalDate(entity.deliveryDate)} (${getRelativeTime(
              entity.deliveryDate
            )})`
          : `Not provided`
      }`,
      isNa: !isProject || !entity?.deliveryDate,
    },
  ];

  // Content layout
  const aboutContent = (
    <div className="min-w-full text-sm space-y-3">
      {aboutContentText.map((item: aboutContent) => (
        <SimpleTextDisplay
          key={item.label}
          label={item.label}
          content={item.content}
          isNa={item.isNa}
        />
      ))}
    </div>
  );

  return (
    <DetailsPanel
      headerLeft={aboutTitle}
      headerRight={editButton}
      content={aboutContent}
    />
  );
}
