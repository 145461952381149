import * as React from 'react';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { SIGNIN, SIGNUPCONFIRM } from '../common/routes';
import { AuthErrorType } from '../common/types';
import graphic from './graphics/sign_up.png';
import ReactTooltip from 'react-tooltip';
import PasswordMatchIndicator from './PasswordMatchIndicator';
import { Info } from 'phosphor-react';
import translate, { strings } from '../common/i18n/translate';
import SmallScreenBanner from './SmallScreenBanner';
import { checkPassword } from '../common/utils';
import { minimumPasswordLength } from '../common/constants';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../common/PrimaryButton';

type AppState = {
  username: string;
  password: string;
  password_confirm: string;
  given_name: string;
  family_name: string;
  authCode: string;
  formType: string;
  loginError: boolean;
  errorText: string;
};

const initialFormState: AppState = {
  username: '',
  password: '',
  password_confirm: '',
  given_name: '',
  family_name: '',
  authCode: '',
  formType: 'signIn',
  loginError: false,
  errorText: '',
};

const iconInfo = (
  <Info
    className="w-5 h-5 inline-block ml-1 -mt-1 text-gray-400 hover:text-blue-600 rounded-full"
    weight="bold"
    data-tip
    data-for="password-info"
  />
);

function SignUp(): React.ReactElement {
  const [formState, updateFormState] = useState<AppState>(initialFormState);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  const SignUp = async () => {
    const { username, given_name, family_name, password, password_confirm } =
      formState;

    if (password !== password_confirm) {
      updateFormState(() => ({
        ...formState,
        errorText: 'Passwords do not match',
      }));
      return;
    }
    try {
      await Auth.signUp({
        username,
        password,
        attributes: { given_name, family_name },
      })
        .then(res => {
          window.analytics.track('Sign up', {
            userId: res.userSub,
            name: `${given_name} ${family_name}`,
            email: username,
            plan: 'test',
          });
          navigate(`${SIGNUPCONFIRM}/${username}`);
        })
        .catch(error => {
          const errorT = error as AuthErrorType;
          updateFormState(() => ({
            ...formState,
            errorText: errorT.message,
          }));
        });
    } catch (error) {
      const errorT = error as AuthErrorType;
      updateFormState(() => ({
        ...formState,
        errorText: errorT.message,
      }));
    }
  };

  function disableSubmit(formState: AppState) {
    if (
      formState.family_name.length > 0 &&
      formState.given_name.length > 0 &&
      formState.username.length > 0 &&
      checkPassword(formState.password).pass &&
      formState.password === formState.password_confirm
    ) {
      return false;
    }
    return true;
  }

  return (
    <div className="min-h-screen bg-secondary-800 sm:bg-gray-200 flex flex-col sm:justify-center sm:py-12 sm:px-6 lg:px-8">
      <div className="mt-0 w-full mx-auto sm:max-w-md md:max-w-5xl flex flex-col md:flex-row sm:shadow-lg sm:rounded-md overflow-hidden">
        {/* Support message */}
        <SmallScreenBanner />

        {/* Graphic */}
        <div className="flex flex-col flex-grow justify-between px-8 py-8 bg-white">
          <div className="flex flex-col text-left">
            <div className="flex items-center justify-center md:justify-start gap-x-3">
              <img
                className={`h-10 w-10 shrink-0 rounded-full`}
                src={`/jl-logo-circle_bg-192.png`}
                alt="JourneyLab logo"
              />
              <h1 className="pt-0.5 text-3xl font-medium">{`${translate(
                strings.BRAND_NAME,
                'JourneyLab'
              )}`}</h1>
            </div>
            {/* <p className="text-gray-500">By JourneyLab</p> */}
            <p className="mt-3 mb-0 md:mb-8 -mx-4 md:mx-0 text-center md:text-left text-lg md:text-xl text-primary-500 font-semibold">
              Join the movement
            </p>
          </div>
          <img
            className="hidden md:block h-96 object-contain my-auto"
            src={graphic}
            alt="Sign Up"
          />
        </div>

        {/* Form */}
        <div className="flex flex-col flex-grow flex-shrink-0 justify-between px-8 py-8 bg-gradient-to-b from-accent-dark to-secondary-800 text-white shadow">
          <div className="flex flex-col">
            <h2 className="mb-6 text-center text-2xl font-semibold">
              Sign up for an account
            </h2>

            <div>
              <label htmlFor="given_name" className="block text-sm font-medium">
                First Name
              </label>
              <div className="mt-1">
                <input
                  id="given_name"
                  name="given_name"
                  type="given_name"
                  autoComplete="first_name"
                  onChange={inputChange}
                  required
                  className="appearance-none text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-4">
              <label
                htmlFor="family_name"
                className="block text-sm font-medium"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  id="family_name"
                  name="family_name"
                  type="family_name"
                  autoComplete="last_name"
                  onChange={inputChange}
                  required
                  className="appearance-none text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-4">
              <label htmlFor="email" className="block text-sm font-medium">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="username"
                  type="email"
                  autoComplete="email"
                  onChange={inputChange}
                  required
                  className="appearance-none text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-4">
              <div className="w-full flex justify-between">
                <label htmlFor="password" className="block text-sm font-medium">
                  Password {iconInfo}
                </label>
                <ReactTooltip
                  id="password-info"
                  effect="solid"
                  place="right"
                  type="info"
                  // className="z-50"
                >
                  <div className="text-sm text-left">
                    <ul className="list-disc list-inside">
                      Minimum {minimumPasswordLength} characters, including:
                      <li>1 uppercase letter</li>
                      <li>1 lowercase letter</li>
                      <li>1 number</li>
                      <li>1 symbol</li>
                    </ul>
                  </div>
                </ReactTooltip>
                <span className="text-sm">
                  {formState.password.length > 0 ? (
                    <button
                      className=""
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  ) : null}
                </span>
              </div>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={inputChange}
                  required
                  className="appearance-none text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
              </div>
              {formState.password.length > 0 ? (
                <div className="grid grid-cols-5 justify-between gap-x-1 mt-1.5">
                  <PasswordMatchIndicator
                    label="ABC"
                    isValid={checkPassword(formState.password).upper}
                  />
                  <PasswordMatchIndicator
                    label="abc"
                    isValid={checkPassword(formState.password).lower}
                  />
                  <PasswordMatchIndicator
                    label="123"
                    isValid={checkPassword(formState.password).number}
                  />
                  <PasswordMatchIndicator
                    label="!@#"
                    isValid={checkPassword(formState.password).symbol}
                  />
                  <PasswordMatchIndicator
                    label="⟷"
                    isValid={checkPassword(formState.password).length}
                  />
                </div>
              ) : null}
            </div>

            <div className="mt-4">
              <div className="w-full flex justify-between">
                <label htmlFor="password" className="block text-sm font-medium">
                  Confirm Password
                </label>
                <span className="text-sm">
                  {formState.password_confirm.length > 0 ? (
                    <button
                      className=""
                      onClick={() => setShowConfirm(!showConfirm)}
                    >
                      {showConfirm ? 'Hide' : 'Show'}
                    </button>
                  ) : null}
                </span>
              </div>
              <div className="mt-1">
                <input
                  id="confirm-password"
                  name="password_confirm"
                  type={showConfirm ? 'text' : 'password'}
                  onChange={inputChange}
                  required
                  className="appearance-none text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            {formState.errorText && (
              <div className="login-form-error mt-4 mx-auto text-sm text-red-500 font-semibold text-center">
                {formState.errorText}
              </div>
            )}

            <div className="mt-4">
              <PrimaryButton
                label="Sign Up"
                onClick={SignUp}
                disabled={disableSubmit(formState)}
                width="w-full"
              />
            </div>
            <div className="pt-10">
              <p className="pb-2 text-sm text-center">
                Already have an account?
              </p>
              <div>
                <button
                  onClick={() => navigate(SIGNIN)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-500 bg-white bg-opacity-0 hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Sign In Here
                </button>
              </div>
            </div>
          </div>

          {/* Attribution: Graphic credit */}
          <a
            href="https://www.freepik.com/free-vector/new-team-members-concept-illustration_20064250.htm#query=new%20team%20member&position=0&from_view=author"
            target="_blank"
            className="text-xs text-secondary-50 text-center mt-8 hidden md:block"
          >
            Illustration by Storyset
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
