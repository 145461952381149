import React, { useContext, useEffect } from 'react';
import { Tab } from '@headlessui/react';

import HeaderWithTabsLayout from '../../common/layout/HeaderWithTabsLayout';
import BasicHeaderWithTabsLayout from '../../common/layout/BasicHeaderWithTabsLayout';
import PageHeader from './PageHeader';
import { PageTab } from '../../common/PageTab';

import AskPanel from './OverviewTab/AskPanel';
import UpdatePanel from './OverviewTab/UpdatePanel';
import AdminTab from './AdminTab/AdminTab';
import LinksPanel from './LinksPanel';

import { ulid } from 'ulid';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetOpenInvitationsByEntityLazyQuery,
  useGetProjectsLazyQuery,
  useSetLastViewedWorkspaceMutation,
  useGetProjectLazyQuery,
  useGetProgramLazyQuery,
  EntityType,
} from '../../api/index';
import { NOT_FOUND } from '../../common/routes';
import { ProjectPageContext } from './ProjectPageContext';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { toast } from 'react-toastify';
import { enumTranslates } from '../../common/i18n/translate';
import { capitaliseFirstLetter } from '../../common/utils';

// Entity imports for v1.5 (interim state for demos)
import EntityAbout from '../entity/overview/EntityOverview';
import EntityPlan from '../entity/waypoints/EntityWaypoints';
import EntityTeaming from '../entity/teaming/EntityTeaming';

function ProjectHomepage(): React.ReactElement {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  const { dispatch, state: projectPageState } = useContext(ProjectPageContext);
  const { state: workspacePageState, dispatch: workspacePageDispatch } =
    useContext(WorkspacePageContext);

  const [setLastViewedWorkspace] = useSetLastViewedWorkspaceMutation();
  const [GetProjects, { data, error, loading }] = useGetProjectsLazyQuery();

  const [
    GetProject,
    { data: dataProject, loading: loadingProject, error: errorProject },
  ] = useGetProjectLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [GetProgram, { data: dataProgram, loading: loadingProgram }] =
    useGetProgramLazyQuery();

  const [
    GetOpenInvitations,
    { data: dataInvitations, loading: loadingInvitations },
  ] = useGetOpenInvitationsByEntityLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (projectId) {
      GetProject({
        variables: {
          projectId: projectId,
        },
      });
      GetOpenInvitations({
        variables: {
          entityId: projectId,
          limit: 25,
        },
      });
    }
  }, [GetOpenInvitations, GetProject, projectId, projectPageState.loadUpdate]);

  useEffect(() => {
    if (dataProject?.getProject) {
      GetProjects({
        variables: {
          programId: dataProject?.getProject.parentId,
        },
      });
      GetProgram({
        variables: {
          programId: dataProject?.getProject.parentId,
        },
      });
    }
  }, [GetProgram, GetProjects, dataProject?.getProject]);

  useEffect(() => {
    if (dataInvitations?.getOpenInvitationsByEntity?.items) {
      dispatch({
        type: 'SET_INVITATIONS',
        invitations: Object.assign(
          dataInvitations?.getOpenInvitationsByEntity?.items
        ),
      });
    }
  }, [dataInvitations?.getOpenInvitationsByEntity?.items, dispatch]);

  useEffect(() => {
    if (errorProject?.message) {
      navigate(NOT_FOUND);
    }
  }, [errorProject, navigate]);

  useEffect(() => {
    if (error?.message) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (
      data?.getProjects &&
      dataProject?.getProject &&
      dataProgram?.getProgram
    ) {
      dispatch({
        type: 'SET_PROJECTS',
        projects: data?.getProjects,
      });
      dispatch({
        type: 'SET_SELECTED_PROJECT',
        project: dataProject.getProject,
      });
      dispatch({
        type: 'SET_PARENT_PROGRAM',
        program: dataProgram?.getProgram,
      });
    }
  }, [
    data?.getProjects,
    dataProject?.getProject,
    dataProgram?.getProgram,
    dispatch,
  ]);

  useEffect(() => {
    if (dataProject?.getProject) {
      const workspaceId = dataProject?.getProject.program.portfolio.parentId;
      const workspace = workspacePageState.workspaces.find(
        w => w.id === workspaceId
      );
      if (
        workspaceId &&
        workspace &&
        workspaceId !== workspacePageState.selectedWorkspace?.id
      ) {
        setLastViewedWorkspace({
          variables: {
            workspaceId: workspaceId,
          },
        });
        workspacePageDispatch({
          type: 'SET_SELECTED_WORKSPACE',
          workspace: workspace,
        });
      }
    }
  }, [
    dataProject?.getProject,
    setLastViewedWorkspace,
    workspacePageDispatch,
    workspacePageState.selectedWorkspace,
    workspacePageState.workspaces,
  ]);

  // We will move to a three tab structure for each Entity
  // In this update, we'll appropriate the Project page to reflect the front end changes to Entity
  const tabs = [
    // The first tab is context setting and contains the key metadata
    // These are things about the Entity that are typically set and change infrequently
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Overview`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            {projectPageState.selectedProject && (
              <EntityAbout
                entity={projectPageState.selectedProject}
                isLoading={
                  loading ||
                  loadingProject ||
                  loadingProgram ||
                  loadingInvitations
                }
              />
            )}
          </div>
        </Tab.Panel>
      ),
      hash: '#overview',
    },

    // The second tab is to plan and track how the Entity progresses over time
    // These are things that are periodically checked
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab selected={selected} title={`Waypoints`} />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            {/* The Asks function will be folded into the RAID logs as Issues */}
            {/* <AskPanel /> */}
            <EntityPlan />
          </div>
        </Tab.Panel>
      ),
      hash: '#waypoints',
    },

    // The third tab is about the team and ways of working
    // These are things about the people involved with the Entity
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Teaming`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            {projectPageState.selectedProject && (
              <EntityTeaming entity={projectPageState.selectedProject} />
            )}
          </div>
        </Tab.Panel>
      ),
      hash: '#teaming',
    },
  ];

  return (
    <HeaderWithTabsLayout
      title={
        projectPageState?.selectedProject?.name &&
        projectPageState?.selectedProject?.name.length > 0
          ? projectPageState?.selectedProject?.name
          : ''
      }
      entity={undefined}
      tabs={tabs}
      isLoading={
        loading || loadingProject || loadingProgram || loadingInvitations
      }
    />
  );
}

// This is an archive of the original project page for reference purposes only
function ProjectHomepageV1(): React.ReactElement {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  const { dispatch, state: projectPageState } = useContext(ProjectPageContext);
  const { state: workspacePageState, dispatch: workspacePageDispatch } =
    useContext(WorkspacePageContext);

  const [setLastViewedWorkspace] = useSetLastViewedWorkspaceMutation();
  const [GetProjects, { data, error, loading }] = useGetProjectsLazyQuery();

  const [
    GetProject,
    { data: dataProject, loading: loadingProject, error: errorProject },
  ] = useGetProjectLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [GetProgram, { data: dataProgram, loading: loadingProgram }] =
    useGetProgramLazyQuery();

  const [
    GetOpenInvitations,
    { data: dataInvitations, loading: loadingInvitations },
  ] = useGetOpenInvitationsByEntityLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (projectId) {
      GetProject({
        variables: {
          projectId: projectId,
        },
      });
      GetOpenInvitations({
        variables: {
          entityId: projectId,
          limit: 25,
        },
      });
    }
  }, [GetOpenInvitations, GetProject, projectId, projectPageState.loadUpdate]);

  useEffect(() => {
    if (dataProject?.getProject) {
      GetProjects({
        variables: {
          programId: dataProject?.getProject.parentId,
        },
      });
      GetProgram({
        variables: {
          programId: dataProject?.getProject.parentId,
        },
      });
    }
  }, [GetProgram, GetProjects, dataProject?.getProject]);

  useEffect(() => {
    if (dataInvitations?.getOpenInvitationsByEntity?.items) {
      dispatch({
        type: 'SET_INVITATIONS',
        invitations: Object.assign(
          dataInvitations?.getOpenInvitationsByEntity?.items
        ),
      });
    }
  }, [dataInvitations?.getOpenInvitationsByEntity?.items, dispatch]);

  useEffect(() => {
    if (errorProject?.message) {
      navigate(NOT_FOUND);
    }
  }, [errorProject, navigate]);

  useEffect(() => {
    if (error?.message) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (
      data?.getProjects &&
      dataProject?.getProject &&
      dataProgram?.getProgram
    ) {
      dispatch({
        type: 'SET_PROJECTS',
        projects: data?.getProjects,
      });
      dispatch({
        type: 'SET_SELECTED_PROJECT',
        project: dataProject.getProject,
      });
      dispatch({
        type: 'SET_PARENT_PROGRAM',
        program: dataProgram?.getProgram,
      });
    }
  }, [
    data?.getProjects,
    dataProject?.getProject,
    dataProgram?.getProgram,
    dispatch,
  ]);

  useEffect(() => {
    if (dataProject?.getProject) {
      const workspaceId = dataProject?.getProject.program.portfolio.parentId;
      const workspace = workspacePageState.workspaces.find(
        w => w.id === workspaceId
      );
      if (
        workspaceId &&
        workspace &&
        workspaceId !== workspacePageState.selectedWorkspace?.id
      ) {
        setLastViewedWorkspace({
          variables: {
            workspaceId: workspaceId,
          },
        });
        workspacePageDispatch({
          type: 'SET_SELECTED_WORKSPACE',
          workspace: workspace,
        });
      }
    }
  }, [
    dataProject?.getProject,
    setLastViewedWorkspace,
    workspacePageDispatch,
    workspacePageState.selectedWorkspace,
    workspacePageState.workspaces,
  ]);

  const tabs = [
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab
              selected={selected}
              title={`${capitaliseFirstLetter(
                enumTranslates[EntityType.Project]
              )} Overview`}
            />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <AskPanel />
            <UpdatePanel />
          </div>
        </Tab.Panel>
      ),
      hash: '#overview',
    },

    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Setup`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <AdminTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#setup',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Links`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <LinksPanel
              loadingEntity={
                loading ||
                loadingProject ||
                loadingProgram ||
                loadingInvitations
              }
              entity={projectPageState.selectedProject}
            />
          </div>
        </Tab.Panel>
      ),
      hash: '#links',
    },
  ];

  return (
    <BasicHeaderWithTabsLayout
      header={
        <PageHeader
          loading={
            !projectPageState.selectedProject?.id ||
            loading ||
            loadingProject ||
            loadingProgram ||
            loadingInvitations
          }
        />
      }
      tabs={tabs}
      isLoading={
        loading || loadingProject || loadingProgram || loadingInvitations
      }
    />
  );
}

export default ProjectHomepage;
