import * as React from 'react';
import ReactLoading from 'react-loading';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { HOME, SIGNUP, RESETPW } from '../common/routes';
import { useGetMyProfileLazyQuery } from '../api/index';
import graphic from './graphics/sign_in.png';
import translate, { strings } from '../common/i18n/translate';
import SmallScreenBanner from './SmallScreenBanner';
import PrimaryButton from '../common/PrimaryButton';

type AppState = {
  username: string;
  password: string;
  password_confirm: string;
  given_name: string;
  family_name: string;
  authCode: string;
  formType: string;
  loginError: boolean;
  errorText: string;
};

const initialFormState: AppState = {
  username: '',
  password: '',
  password_confirm: '',
  given_name: '',
  family_name: '',
  authCode: '',
  formType: 'signIn',
  loginError: false,
  errorText: '',
};

function SignIn(): React.ReactElement {
  const [disabled, setDisabled] = React.useState(false);
  const [formState, updateFormState] = useState<AppState>(initialFormState);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [GetMyProfile, { data }] = useGetMyProfileLazyQuery({
    fetchPolicy: 'network-only',
  });

  const user = React.useContext(UserContext);

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  const SignIn = async () => {
    setDisabled(true);
    const { username, password } = formState;

    await user
      .signIn({ username, password })
      .then(async () => {
        GetMyProfile();
      })
      .catch(error => {
        console.log(error);
        updateFormState(() => ({
          ...formState,
          loginError: true,
        }));
      });
  };

  useEffect(() => {
    if (data?.getMyProfile) {
      localStorage.setItem('profile', JSON.stringify(data?.getMyProfile));
      user.setProfile(data?.getMyProfile);
      navigate(HOME);
    }
  }, [data, navigate, user]);

  function handleKeyPress(e: React.KeyboardEvent) {
    const code = e.keyCode || e.charCode;

    // Attempt sign in if the enter key is pressed
    if (code == 13) {
      SignIn();
    }
  }

  return (
    <div className="min-h-screen bg-secondary-800 sm:bg-gray-200 flex flex-col sm:justify-center sm:py-12 sm:px-6 lg:px-8">
      <div className="mt-0 w-full mx-auto sm:max-w-md md:max-w-5xl flex flex-col md:flex-row sm:shadow-lg sm:rounded-md overflow-hidden">
        {/* Support message */}
        <SmallScreenBanner />

        {/* Graphic */}
        <div className="flex flex-col flex-grow justify-between px-8 py-8 bg-white">
          <div className="flex flex-col text-left">
            <div className="flex items-center justify-center md:justify-start gap-x-3">
              <img
                className={`h-10 w-10 shrink-0 rounded-full`}
                src={`/jl-logo-circle_bg-192.png`}
                alt="JourneyLab logo"
              />
              <h1 className="pt-0.5 text-3xl font-medium">{`${translate(
                strings.BRAND_NAME,
                'JourneyLab'
              )}`}</h1>
            </div>
            {/* <p className="text-gray-500">By JourneyLab</p> */}
            <p className="mt-3 mb-0 md:mb-8 -mx-4 md:mx-0 text-center md:text-left text-lg md:text-xl text-primary-500 font-semibold">
              Turn change into your competitive advantage
            </p>
          </div>
          <img
            className="hidden md:block h-96 object-contain"
            src={graphic}
            alt="Sign In"
          />
        </div>

        {/* Form */}
        <div className="flex flex-col flex-grow flex-shrink-0 justify-between px-8 py-8 bg-gradient-to-b from-accent-dark to-secondary-800 text-white shadow">
          <div className="flex flex-col">
            <h2 className="mb-4 text-center text-2xl font-semibold">
              Sign in to get started
            </h2>

            {/* Show an error if credentials not found */}
            {/* Keep the message generic per OWASP guidelines */}
            {/* https://owasp.org/www-project-web-security-testing-guide/latest/4-Web_Application_Security_Testing/03-Identity_Management_Testing/04-Testing_for_Account_Enumeration_and_Guessable_User_Account */}
            <div className="mb-4">
              {formState.loginError && (
                <div className="login-form-error mx-auto text-sm text-red-600 font-semibold text-center">
                  <p>
                    Sorry, we couldn't find an account with those credentials.
                  </p>
                  <p>Please try again.</p>
                </div>
              )}
            </div>

            <div className="">
              <label htmlFor="email" className="block text-sm font-medium">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="username"
                  type="email"
                  autoComplete="email"
                  required
                  onChange={inputChange}
                  className="appearance-none text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-4">
              <div className="w-full flex justify-between">
                <label htmlFor="password" className="block text-sm font-medium">
                  Password
                </label>
                <span className="text-sm">
                  {formState.password.length > 0 ? (
                    <button
                      className=""
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Hide' : 'Show'}
                    </button>
                  ) : null}
                </span>
              </div>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  required
                  onChange={inputChange}
                  className="appearance-none text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  onKeyDown={e => handleKeyPress(e)}
                />
              </div>
            </div>

            <div className="flex mt-6 mb-1 items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 mr-6 block text-sm whitespace-nowrap"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm text-right">
                <Link
                  to={RESETPW}
                  className="font-medium text-primary-500 hover:text-primary-600 text-right"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div className="mt-3">
              <PrimaryButton
                label={
                  disabled && !formState.loginError ? (
                    <div className="-pt-1 -mt-4 h-9">
                      <ReactLoading type="bubbles" height={10} width={50} />
                    </div>
                  ) : (
                    'Sign In'
                  )
                }
                onClick={SignIn}
                disabled={disabled && !formState.loginError}
                width="w-full"
              />
            </div>
            <div className="pt-10">
              <p className="pb-2 text-sm text-center">
                Don&lsquo;t have an account yet?
              </p>
              <div>
                <button
                  onClick={() => navigate(SIGNUP)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-500 bg-white bg-opacity-0 hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Sign Up Here
                </button>
              </div>
            </div>
          </div>

          {/* Attribution: Graphic credit */}
          <a
            href="https://www.freepik.com/free-vector/team-goals-concept-illustration_12704339.htm#query=team%20goals&position=2&from_view=author"
            target="_blank"
            className="text-xs text-secondary-50 text-center mt-8 hidden md:block"
          >
            Illustration by Storyset
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
