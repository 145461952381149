import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import { ulid } from 'ulid';

type Props = {
  selected: boolean;
  title: string;
  icon?: React.ReactElement;
  isDisabled?: boolean;
  onClick?: () => void;
};

export const PageTab = ({
  selected,
  title,
  icon,
  isDisabled,
  onClick,
}: Props) => {
  const key = ulid();

  return (
    <React.Fragment>
      <button
        type="button"
        onClick={onClick}
        className={`${
          selected
            ? 'text-accent-light bg-gradient-to-tr from-white to-white border-gray-200 shadow'
            : `${
                isDisabled
                  ? 'text-gray-400'
                  : `text-gray-400 hover:text-gray-600`
              } shadow-transparent border-transparent`
        } group h-min inline-flex border rounded-md items-center justify-center font-semibold text-sm ${
          icon ? 'px-3 py-1.5' : 'min-w-[7rem] px-4 py-2'
        }`}
        data-tip
        data-for={key}
      >
        <div className="flex items-center justify-center mx-auto">
          <span
            className={`sm:flex text-center items-center lg:whitespace-nowrap mx-auto`}
          >
            {icon ? icon : title}
          </span>
        </div>
      </button>

      {icon && (
        <ReactTooltip id={key} place="top" effect="solid">
          <div className="text-center">
            <p className="font-semibold">{title}</p>
          </div>
        </ReactTooltip>
      )}
    </React.Fragment>
  );
};
