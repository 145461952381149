import moment from 'moment';
import { ulid } from 'ulid';
import {
  UpdateCadence,
  Group,
  EntityRole,
  MembersPage,
  User,
  EntityMember,
  LifecycleStage,
} from '../../../api/index';
import { EntityInvitationExt, UserExt } from '../../types';
import { cadenceToDays } from '../../utils';

export const EntityDetailsPayload = (
  editObject: { [key: string]: unknown } | null,
  parentObject: { [key: string]: unknown } | undefined | null,
  user: UserExt | null,
  topLevelEntity: { [key: string]: unknown } | undefined | null,
  profile: User | null,
  workspaceId: string,
): { [key: string]: unknown } => {
  const parentMembers = parentObject?.members as MembersPage;
  // (1) and then we would update this return object to contain the existing values for those fields
  // values here correspond to the "key" in the input components
  if (editObject == null) {
    return {
      name: '',
      parentId: parentObject?.id,
      workspaceId: workspaceId,
      description: '',
      stage: LifecycleStage.Inflight,
      startDate: moment(new Date()).toISOString(),
      endDate: moment(new Date()).toISOString(),
      primaryStrategicObjective: '',
      primaryParentObjective: '',
      objectives: [],
      budget: 0,
      // workspaceId: add
      members: [
        {
          id: ulid(),
          email: user?.attributes?.email,
          customText: `${profile?.firstName} ${profile?.lastName}`,
          isInvitation: false,
          existingRoles: parentMembers?.items
            ? (parentMembers.items as EntityMember[]).filter(
                pm => pm.user.id === user?.attributes?.sub
              )
            : [],
          resendInvite: '',
          nonRemovable: true,
          role: EntityRole.OutcomeOwner,
          group: Group.Owner,
        },
      ],
    };
  } else {
    const members = (editObject?.members as MembersPage)
      .items as EntityMember[];
    const owners = members?.filter(r => r.group === Group.Owner);
    const initialMembers = members
      ? members.map(m => ({
          id: m.user.id,
          email: m.user?.email,
          customText: `${m.user?.firstName} ${m.user?.lastName}`,
          existingRoles: parentMembers?.items
            ? (parentMembers.items as EntityMember[]).filter(
                pm => pm.user.id === m.user.id
              )
            : [],
          role: m.role,
          group: m.group,
          isInvitation: false,
          resendInvite: '',
          isPendingUser: false,
          nonRemovable: m.group === Group.Owner && owners && owners?.length < 2,
        }))
      : [];
    const invitations = editObject.invitations as Array<EntityInvitationExt>;
    invitations?.forEach(m =>
      initialMembers?.push({
        id: ulid(),
        email: m.inviteeEmail,
        customText: m.firstName
          ? `${m.firstName} ${m.lastName}`
          : `${m.inviteeEmail}`,
        role: m.entityRole,
        group: m.group,
        existingRoles: [],
        isInvitation: true,
        isPendingUser: m.firstName ? false : true,
        resendInvite: 'no',
        nonRemovable: false,
      })
    );
    let nextUpdateDate = null;
    const today = new Date();
    if (editObject.nextUpdateDate && editObject.nextUpdateDate !== null) {
      nextUpdateDate = new Date(editObject.nextUpdateDate as string);
      if (nextUpdateDate < today) {
        nextUpdateDate.setDate(
          nextUpdateDate.getDate() +
            cadenceToDays(editObject.updateCadence as UpdateCadence)
        );
      }
    }
    return {
      id: editObject.id,
      name: editObject.name,
      parentId: editObject.parentId,
      startDate: editObject.startDate,
      endDate: editObject.endDate,
      description: editObject.description,
      stage: editObject.stage,
      primaryStrategicObjective: editObject.primaryStrategicObjective,
      primaryParentObjective: editObject.primaryParentObjective,
      objectives: editObject.objectives,
      members: initialMembers,
      oldMembers: initialMembers,
      budget: editObject.budget,
      isProject: editObject.isProject,
      workspaceId: editObject.workspaceId,
    };
  }
};
