import React from 'react';

export type PublicRouteProps = {
  component: React.ElementType;
};

const PublicRoute: React.FunctionComponent<PublicRouteProps> = ({
  component: Component,
}) => {
  return <Component />;
};

export default PublicRoute;
