import React from 'react';
import ConfidenceBadge from '../../basic/ConfidenceBadge';
import { MenuOption } from '../../basic/DotMenu';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import ReviewActionHeader from './ReviewActionHeader';
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

type ConfidenceRowProps = {
  label: string;
  confidence?: 'High' | 'Medium' | 'Low';
};

const ConfidenceRow = ({
  label,
  confidence,
}: ConfidenceRowProps): React.ReactElement => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className={`pr-4 pb-1 align-top lg:w-40 lg:shrink-0`}>
        <p className="font-semibold text-sm">{label}</p>
      </div>
      <div className={`align-top`}>
        <ConfidenceBadge
          confidenceLabel="Confidence rating"
          rating={confidence}
        />
      </div>
    </div>
  );
};

type ReviewRowProps = {
  label: string;
  content: string | undefined;
};

const ReviewRow = ({ label, content }: ReviewRowProps): React.ReactElement => {
  const isContentNull = !content;
  return (
    <div className="flex flex-col lg:flex-row text-sm">
      <div className={`pr-4 pb-1 align-top lg:w-40 lg:shrink-0`}>
        <p className="font-semibold">{label}</p>
      </div>
      <div className={`align-top whitespace-pre-wrap`}>
        <p className={isContentNull ? `italic text-gray-400` : `text-gray-800`}>
          {content || 'Not provided '}
        </p>
      </div>
    </div>
  );
};

const ReviewTitle = () => (
  <DetailsHeaderTitle
    title={`Review`}
    tooltipText={`How we're tracking towards our objectives`}
  />
);

type ReviewContentProps = {
  review: WaypointReview | undefined;
};

const ReviewContent = ({ review }: ReviewContentProps) =>
  review ? (
    <div className="min-w-full space-y-4 lg:space-y-3">
      <ConfidenceRow
        label="Confidence rating"
        confidence={review.confidenceRating}
      />
      <ReviewRow label="Summary remarks" content={review.remarks} />
      <ReviewRow
        label="Spend to date"
        content={
          review.spendToDate === 0
            ? `$0`
            : review.spendToDate
            ? `$${review.spendToDate?.toLocaleString()}`
            : undefined
        }
      />
    </div>
  ) : (
    <div className="text-sm text-gray-400 italic text-center">
      No review found for this waypoint
    </div>
  );

export type WaypointReview = {
  confidenceRating?: 'High' | 'Medium' | 'Low';
  remarks?: string;
  spendToDate?: number;
};

type ReviewPanelProps = {
  review?: WaypointReview;
  menuOptions: Array<MenuOption>;
  actionsDisabled: boolean;
  onEditClick: () => void;
  isVisible: boolean;
};

export default function ReviewPanel({
  review,
  menuOptions,
  actionsDisabled,
  onEditClick,
  isVisible,
}: ReviewPanelProps): React.ReactElement {
  // Set up content to show

  return (
    <DetailsHeader
      headerLeft={<ReviewTitle />}
      headerRight={
        <ReviewActionHeader
          hasReview={review != undefined}
          onClick={onEditClick}
          isVisible={isVisible}
          disabled={actionsDisabled}
          menuOptions={menuOptions}
        />
      }
      content={<ReviewContent review={review} />}
    />
  );
}
