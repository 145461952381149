import React, { useEffect, useState, Fragment, useContext } from 'react';
import PageHeader from './PageHeader';
import { Listbox, RadioGroup, Transition } from '@headlessui/react';
import { capitaliseFirstLetter, classNames } from '../../common/utils';
import {
  EntityType,
  GenerateReportMutation,
  Project,
  useGenerateReportMutation,
  useGetPortfoliosLazyQuery,
  useGetProgramsLazyQuery,
} from '../../api/index';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { ApolloError, FetchResult } from '@apollo/client';
import Modal from '../../common/Modal';
import { toast } from 'react-toastify';
import {
  portfolioReportTypes,
  programReportTypes,
  projectReportTypes,
  entityTypes,
} from './ReportConstants';
import Spinner from '../../common/SpinnerThemed';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import { Briefcase, CaretDown, Check, FolderSimple } from 'phosphor-react';
import DetailsPanelSectionDivider from '../../common/DetailsPanelSectionDivider';
import PrimaryButton from '../../common/PrimaryButton';
import { DemoContext } from '../../context/DemoContext';
import { enumTranslates } from '../../common/i18n/translate';

function ReportingHub(): React.ReactElement {
  const { state: workspacePageState } = useContext(WorkspacePageContext);
  const { isDemo } = useContext(DemoContext);

  const [
    GetPortfoliosQuery,
    { data: dataPortfolios, loading: loadingPortfolios },
  ] = useGetPortfoliosLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [GetProgramsQuery, { data: dataPrograms }] = useGetProgramsLazyQuery({
    fetchPolicy: 'network-only',
  });

  //Icons
  const listboxSelector = (
    <CaretDown
      className="h-5 w-5 text-gray-400"
      weight="bold"
      aria-hidden="true"
    />
  );

  const selectedMarker = (
    <Check className="h-5 w-5" weight="bold" aria-hidden="true" />
  );

  // Get data
  const portfolios = dataPortfolios?.getPortfolios;
  const programs = dataPrograms?.getPrograms;
  const projects: Project[] = [];

  // Track selected level tab in state
  const [selectedTab, setSelectedTab] = useState(entityTypes[0]);
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [reportNote, setReportNote] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [cc, setCc] = useState(true);

  useEffect(() => {
    if (workspacePageState.selectedWorkspace) {
      GetPortfoliosQuery({
        variables: {
          workspaceId: workspacePageState.selectedWorkspace.id,
        },
      });
    }
  }, [workspacePageState, GetProgramsQuery, GetPortfoliosQuery]);

  // Set default level to portfolio
  useEffect(() => {
    setSelectedTab(entityTypes[0]);
  }, []);

  useEffect(() => {
    if (dataPortfolios?.getPortfolios) {
      setSelectedPortfolio(dataPortfolios?.getPortfolios[0]);
    }
  }, [dataPortfolios]);

  useEffect(() => {
    if (dataPrograms?.getPrograms) {
      setSelectedProgram(dataPrograms?.getPrograms[0]);
    }
  }, [dataPrograms]);

  // Portfolio selections
  const [selectedPortfolio, setSelectedPortfolio] = useState(
    portfolios ? portfolios[0] : null
  );
  const [selectedPortfolioReport, setSelectedPortfolioReport] = useState(
    portfolioReportTypes[0]
  );

  // Program selections
  const [selectedProgram, setSelectedProgram] = useState(
    programs ? programs[0] : null
  );
  const [selectedProgramReport, setSelectedProgramReport] = useState(
    programReportTypes[0]
  );
  // Project selections
  const [selectedProject, setSelectedProject] = useState(
    projects ? projects[0] : null
  );
  const [selectedProjectReport, setSelectedProjectReport] = useState(
    projectReportTypes[0]
  );

  const [generateReport, { loading }] = useGenerateReportMutation();

  useEffect(() => {
    if (selectedPortfolio) {
      GetProgramsQuery({
        variables: {
          portfolioId: selectedPortfolio.id,
        },
      });
    }
  }, [GetProgramsQuery, selectedPortfolio]);

  const [response, setResponse] =
    useState<
      FetchResult<
        GenerateReportMutation,
        Record<string, unknown>,
        Record<string, unknown>
      >
    >();

  const _generateReport = async () => {
    try {
      let entityId;
      let type;
      if (selectedTab.value === EntityType.Portfolio && selectedPortfolio) {
        entityId = selectedPortfolio.id;
        type = selectedPortfolioReport.value;
      } else if (selectedTab.value === EntityType.Program && selectedProgram) {
        entityId = selectedProgram?.id;
        type = selectedProgramReport.value;
      }
      if (inviteeEmail && entityId && type) {
        const report = await generateReport({
          variables: {
            input: {
              entityId: entityId,
              inviteeEmail: inviteeEmail,
              type: type,
              reportNote,
              cc,
              entityType: selectedTab.value,
            },
          },
        });
        if (report) {
          setResponse(report);
          setModalOpen(true);
        }
      }
    } catch (e) {
      toast.error((e as ApolloError).message);
    }
  };

  const clearReportSelection = () => {
    setInviteeEmail('');
    if (portfolios) {
      setSelectedPortfolio(portfolios[0]);
    }
    setModalOpen(false);
  };

  const reportingTitle = (
    <DetailsPanelHeader
      title={'Report Configuration'}
      description={'Set up a report to share'}
    />
  );

  const reportingButton = (
    <div className="flex flex-row lg:ml-4 flex-shrink-0 gap-x-3">
      <span className="sm:ml-3">
        <PrimaryButton
          icon="report"
          label="Send report"
          onClick={() => _generateReport()}
          disabled={isDemo}
        />

        {/* <button
          type="button"
          onClick={() => _generateReport()}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <MailIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Generate link & send report
        </button> */}
      </span>
    </div>
  );

  const reportingContent = (
    <React.Fragment>
      {loading || loadingPortfolios ? (
        <div className="flex flex-col align-middle items-center">
          <Spinner text={'Loading...'} />
        </div>
      ) : (
        <div className="flex flex-col space-y-5">
          <div>
            {/* Send to... */}
            <DetailsPanelSectionDivider title="Recipient" />

            <div className="col-span-1">
              <div className="flex-col sm:grid sm:grid-cols-5">
                <div className="col-span-1 text-sm font-semibold mt-2 mb-1">
                  Send to...
                </div>
                <div className="col-span-4 flex-col">
                  <input
                    type="email"
                    placeholder="name@email.com"
                    onChange={e => setInviteeEmail(e.target.value)}
                    value={inviteeEmail}
                    className="shadow-sm w-full focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className="text-xs text-gray-500 mt-1">
                    For multiple recipients, separate with commas
                  </p>
                </div>
              </div>

              <div className="flex-col sm:grid sm:grid-cols-5 mt-3">
                <div className="col-span-1 text-sm font-semibold mt-1 mb-1">
                  Copy me in
                </div>
                <div className="col-span-4 items-center">
                  <input
                    type="checkbox"
                    name="cc"
                    onChange={e => setCc(e.target.checked)}
                    checked={cc}
                    className="shadow-sm h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded-md"
                  />
                  {cc ? (
                    <span className="text-sm text-gray-500 ml-2 mt-1">
                      You'll be cc'd in the email
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="flex-col sm:grid sm:grid-cols-5 mt-3">
                <div className="col-span-1 text-sm font-semibold mt-2 mb-1 mr-2 align-baseline">
                  <span>Message to recipient</span>
                  <span className="inline-block ml-1 sm:ml-0 sm:block text-xs text-gray-500 mt-1">
                    (Optional)
                  </span>
                </div>
                <div className="col-span-4 flex-col">
                  <textarea
                    placeholder="Briefly mention what to focus on"
                    onChange={e => setReportNote(e.target.value)}
                    value={reportNote}
                    className="shadow-sm w-full focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                  />
                  <p className="text-xs text-gray-500 mt-0">
                    We'll add this to the email
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div>
            {/* Report config */}
            <DetailsPanelSectionDivider title="Report" />

            <div className="grid grid-cols-1 gap-x-6">
              <div className="col-span-1 space-y-4">
                {/* Select entity level */}
                <div className="flex-col sm:grid sm:grid-cols-5">
                  <div className="col-span-1 py-2 text-sm font-semibold mb-1">
                    Level
                  </div>
                  <div className="col-span-4">
                    <div className="flex space-x-3">
                      <button
                        type="button"
                        className={classNames(
                          selectedTab == entityTypes[0]
                            ? 'bg-primary-100 text-primary-700'
                            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100',
                          'flex items-center px-4 py-2 font-medium text-sm rounded-md group'
                        )}
                        onClick={() => setSelectedTab(entityTypes[0])}
                      >
                        <span className="mr-1.5">
                          <Briefcase
                            className={`w-6 h-6 -ml-0.5 mr-0.5 flex-shrink-0 ${
                              selectedTab == entityTypes[0]
                                ? 'text-primary-700'
                                : 'text-gray-500 group-hover:text-gray-700'
                            } `}
                            weight="duotone"
                            data-tip
                            data-for={'portfolio-icon'}
                          />
                        </span>
                        {entityTypes[0].name}
                      </button>

                      <button
                        type="button"
                        className={classNames(
                          selectedTab == entityTypes[1]
                            ? 'bg-primary-100 text-primary-700'
                            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100',
                          'flex items-center px-4 py-2 font-medium text-sm rounded-md group'
                        )}
                        onClick={() => setSelectedTab(entityTypes[1])}
                      >
                        <span className="mr-1.5">
                          <FolderSimple
                            className={`w-6 h-6 -ml-0.5 mr-0.5 flex-shrink-0 ${
                              selectedTab == entityTypes[1]
                                ? 'text-primary-700'
                                : 'text-gray-500 group-hover:text-gray-700'
                            } `}
                            weight="duotone"
                            data-tip
                            data-for={'program-icon'}
                          />
                        </span>
                        {entityTypes[1].name}
                      </button>

                      {/* Hide project level for now since we have not enabled any project reports */}
                      {/* <button
                        type="button"
                        className={classNames(
                          selectedTab == entityTypes[2]
                            ? 'bg-primary-100 text-primary-700 font-semibold'
                            : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100',
                          'flex items-center px-4 py-2 font-medium text-sm rounded-md'
                        )}
                        onClick={() => setSelectedTab(entityTypes[2])}
                      >
                        {entityTypes[2].name}
                      </button> */}
                    </div>
                  </div>
                </div>

                {/* Select entity */}
                <div
                  className={classNames(
                    selectedTab == entityTypes[0] ||
                      selectedTab == entityTypes[1] ||
                      selectedTab == entityTypes[2]
                      ? ''
                      : 'hidden',
                    'flex-col sm:grid sm:grid-cols-5 mt-4 gap-y-4'
                  )}
                >
                  <div className="col-span-1 text-sm font-semibold mt-2 mb-1">
                    {capitaliseFirstLetter(
                      enumTranslates[EntityType.Portfolio]
                    )}
                  </div>
                  <div className="col-span-4">
                    <Listbox
                      value={selectedPortfolio}
                      onChange={setSelectedPortfolio}
                    >
                      <div className="mt-0 relative">
                        <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                          <span className="block truncate">
                            {selectedPortfolio?.name}
                          </span>
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            {listboxSelector}
                          </span>
                        </Listbox.Button>

                        <Transition
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-visible focus:outline-none sm:text-sm">
                            {portfolios?.map(portfolio => (
                              <Listbox.Option
                                key={portfolio.id}
                                className={({ active }) =>
                                  classNames(
                                    active
                                      ? 'text-white bg-primary-600'
                                      : 'text-gray-900',
                                    'cursor-default select-none relative py-2 pl-3 pr-9'
                                  )
                                }
                                value={portfolio}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <span
                                      className={classNames(
                                        selected
                                          ? 'font-semibold'
                                          : 'font-normal',
                                        'block truncate'
                                      )}
                                    >
                                      {portfolio.name}
                                    </span>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active
                                            ? 'text-white'
                                            : 'text-primary-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        {selectedMarker}
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </Listbox>
                  </div>
                </div>

                <div
                  className={classNames(
                    selectedTab == entityTypes[1] ||
                      selectedTab == entityTypes[2]
                      ? ''
                      : 'hidden',
                    'flex-col sm:grid sm:grid-cols-5 mt-4 gap-y-4'
                  )}
                >
                  <div className="col-span-1 text-sm font-semibold mt-2 mb-1">
                    {capitaliseFirstLetter(enumTranslates[EntityType.Program])}
                  </div>

                  {programs && programs.length > 0 ? (
                    <div className="col-span-4">
                      <Listbox
                        value={selectedProgram}
                        onChange={setSelectedProgram}
                        // TODO: Change should also update the report timeframe selector
                      >
                        <div className="mt-0 relative">
                          <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedProgram?.name}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              {listboxSelector}
                            </span>
                          </Listbox.Button>

                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-visible focus:outline-none sm:text-sm">
                              {programs?.map(program => (
                                <Listbox.Option
                                  key={program.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-primary-600'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={program}
                                >
                                  {({ selected, active }) => (
                                    <React.Fragment>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {program.name}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-primary-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          {selectedMarker}
                                        </span>
                                      ) : null}
                                    </React.Fragment>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  ) : (
                    <span className="col-span-4 text-sm mt-2 text-gray-400 italic">
                      No Programs in selected Portfolio
                    </span>
                  )}
                </div>

                <div
                  className={classNames(
                    selectedTab == entityTypes[2] ? '' : 'hidden',
                    'mt-4 gap-y-4'
                  )}
                >
                  <div className="col-span-1 text-sm font-semibold mt-2 mb-1">
                    {capitaliseFirstLetter(enumTranslates[EntityType.Project])}
                  </div>
                  {projects && projects.length > 0 ? (
                    <div className="col-span-4">
                      <Listbox
                        value={selectedProject}
                        onChange={setSelectedProject}
                        // TODO: Change should also update the report timeframe selector
                      >
                        <div className="mt-0 relative">
                          <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                            <span className="block truncate">
                              {selectedProject?.name}
                            </span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                              {listboxSelector}
                            </span>
                          </Listbox.Button>

                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-visible focus:outline-none sm:text-sm">
                              {projects?.map(project => (
                                <Listbox.Option
                                  key={project.id}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? 'text-white bg-primary-600'
                                        : 'text-gray-900',
                                      'cursor-default select-none relative py-2 pl-3 pr-9'
                                    )
                                  }
                                  value={project}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          selected
                                            ? 'font-semibold'
                                            : 'font-normal',
                                          'block truncate'
                                        )}
                                      >
                                        {project.name}
                                      </span>

                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? 'text-white'
                                              : 'text-primary-600',
                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                          )}
                                        >
                                          {selectedMarker}
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </Listbox>
                    </div>
                  ) : (
                    <div className="col-span-4 text-sm mt-2 text-gray-400 italic">
                      No Projects in selected Program
                    </div>
                  )}
                </div>

                {/* Report type */}
                <div className="flex-col sm:grid sm:grid-cols-5 mt-4">
                  <div className="col-span-1 text-sm font-semibold mt-2 mb-1">
                    Report Type
                  </div>
                  <div
                    className={`${
                      selectedTab == entityTypes[0] ? 'col-span-4' : 'hidden'
                    }`}
                  >
                    <RadioGroup
                      value={selectedPortfolioReport}
                      onChange={setSelectedPortfolioReport}
                    >
                      <RadioGroup.Label className="sr-only">
                        Report type
                      </RadioGroup.Label>
                      <div className="bg-white rounded-md -space-y-px">
                        {portfolioReportTypes.map((item, itemIdx) => (
                          <RadioGroup.Option
                            key={item.display}
                            value={item}
                            className={({ checked }) =>
                              classNames(
                                itemIdx === 0 ? 'rounded-md' : '',
                                itemIdx === portfolioReportTypes.length - 1
                                  ? 'rounded-md'
                                  : '',
                                checked ? 'bg-primary-100 z-8' : '',
                                'relative p-4 flex cursor-pointer focus:outline-none'
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <React.Fragment>
                                <span
                                  className={classNames(
                                    checked
                                      ? 'bg-primary-600 border-transparent'
                                      : 'bg-white border-gray-300',
                                    active
                                      ? 'ring-2 ring-offset-2 ring-primary-500'
                                      : '',
                                    'flex-shrink-0 h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <div className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? 'text-primary-700 font-semibold'
                                        : 'text-gray-900',
                                      'block text-sm font-medium'
                                    )}
                                  >
                                    {item.display}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? 'text-primary-700'
                                        : 'text-gray-500',
                                      'block text-sm mr-2 mt-1'
                                    )}
                                  >
                                    {item.description}
                                  </RadioGroup.Description>
                                </div>

                                {/* Thumbnail */}
                                {item.thumbnail ? (
                                  <img
                                    src={item.thumbnail}
                                    className="object-contain w-40 ml-auto"
                                  />
                                ) : null}
                              </React.Fragment>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div
                    className={`${
                      selectedTab == entityTypes[1] ? 'col-span-4' : 'hidden'
                    }`}
                  >
                    <RadioGroup
                      value={selectedProgramReport}
                      onChange={setSelectedProgramReport}
                    >
                      <RadioGroup.Label className="sr-only">
                        Report type
                      </RadioGroup.Label>
                      <div className="bg-white rounded-md -space-y-px">
                        {programReportTypes.map((item, itemIdx) => (
                          <RadioGroup.Option
                            key={item.display}
                            value={item}
                            className={({ checked }) =>
                              classNames(
                                itemIdx === 0 ? 'rounded-md' : '',
                                itemIdx === programReportTypes.length - 1
                                  ? 'rounded-md'
                                  : '',
                                checked ? 'bg-primary-100 z-8' : '',
                                'relative p-4 flex cursor-pointer focus:outline-none'
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <React.Fragment>
                                <span
                                  className={classNames(
                                    checked
                                      ? 'bg-primary-600 border-transparent'
                                      : 'bg-white border-gray-300',
                                    active
                                      ? 'ring-2 ring-offset-2 ring-primary-500'
                                      : '',
                                    'flex-shrink-0 h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <div className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? 'text-primary-700 font-semibold'
                                        : 'text-gray-900',
                                      'block text-sm font-medium'
                                    )}
                                  >
                                    {item.display}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? 'text-primary-700'
                                        : 'text-gray-500',
                                      'block text-sm mr-2 mt-1'
                                    )}
                                  >
                                    {item.description}
                                  </RadioGroup.Description>
                                </div>

                                {/* Thumbnail */}
                                {item.thumbnail ? (
                                  <img
                                    src={item.thumbnail}
                                    className="object-contain w-40 ml-auto"
                                  />
                                ) : null}
                              </React.Fragment>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  <div
                    className={`${
                      selectedTab == entityTypes[2] ? 'col-span-4' : 'hidden'
                    }`}
                  >
                    <RadioGroup
                      value={selectedProjectReport}
                      onChange={setSelectedProjectReport}
                    >
                      <RadioGroup.Label className="sr-only">
                        Privacy setting
                      </RadioGroup.Label>
                      <div className="bg-white rounded-md -space-y-px">
                        {projectReportTypes.map((item, itemIdx) => (
                          <RadioGroup.Option
                            key={item.display}
                            value={item}
                            className={({ checked }) =>
                              classNames(
                                itemIdx === 0 ? 'rounded-md' : '',
                                itemIdx === projectReportTypes.length - 1
                                  ? 'rounded-md'
                                  : '',
                                checked ? 'bg-primary-100 z-8' : '',
                                'relative p-4 flex cursor-pointer focus:outline-none'
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <React.Fragment>
                                <span
                                  className={classNames(
                                    checked
                                      ? 'bg-primary-600 border-transparent'
                                      : 'bg-white border-gray-300',
                                    active
                                      ? 'ring-2 ring-offset-2 ring-primary-500'
                                      : '',
                                    'flex-shrink-0 h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <div className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? 'text-primary-700 font-semibold'
                                        : 'text-gray-900',
                                      'block text-sm font-medium'
                                    )}
                                  >
                                    {item.display}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? 'text-primary-700'
                                        : 'text-gray-500',
                                      'block text-sm mr-2 mt-1'
                                    )}
                                  >
                                    {item.description}
                                  </RadioGroup.Description>
                                </div>

                                {/* Thumbnail */}
                                {item.thumbnail ? (
                                  <img
                                    src={item.thumbnail}
                                    className="object-contain w-40 ml-auto"
                                  />
                                ) : null}
                              </React.Fragment>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {modalOpen && (
        <Modal width={'w-full max-w-xl'} onClose={() => setModalOpen(false)}>
          <div className="flex flex-col space-y-4 bg-white p-4 overflow-y-auto align-middle">
            <div className="flex flex-row items-center">
              <span className="flex p-2 rounded-full bg-green-500 align-middle items-center">
                <Check
                  weight="bold"
                  className="h-5 w-5 text-white mx-auto my-auto"
                />
              </span>
              <h3 className="text-lg font-medium ml-3 text-gray-900">
                Report Sent
              </h3>
            </div>
            <p className="mt-3 text-sm text-gray-900">
              Your report for "{response?.data?.generateReport?.name}" has been
              shared with {inviteeEmail}.
            </p>
            <p className="mt-3 text-sm text-gray-900">
              Please note:
              <ul className="list-disc list-outside ml-4 mt-2 text-sm">
                <li>
                  Reports show the latest updates at this point in time only
                </li>
                <li>The link will expire in 1 week</li>
              </ul>
            </p>

            <span className="text-right">
              <button
                className="disabled:opacity-50 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                onClick={() => clearReportSelection()}
              >
                Done
              </button>
            </span>
          </div>
        </Modal>
      )}

      <div className="flex flex-col space-y-6 max-w-4xl mx-auto">
        <PageHeader />

        <DetailsPanel
          headerLeft={reportingTitle}
          headerRight={reportingButton}
          content={reportingContent}
        />
      </div>
    </React.Fragment>
  );
}

export default ReportingHub;
