import React from 'react';

function Header(): React.ReactElement {
  return (
    <div className="w-full lg:flex lg:items-center lg:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="mt-2 text-2xl font-bold leading text-gray-900 sm:text-3xl sm:truncate">
          Getting Started
        </h2>
        <div className="mt-2 mb-1 mr-6 flex align-top text-sm text-gray-500 max-w-3xl">
          <span className="">
            Can't find what you're looking for? Send us a note at{' '}
            <a
              href="mailto:team@journeylab.io"
              className="underline text-primary-500 inline-block"
            >
              team@journeylab.io
            </a>
          </span>
          .
        </div>
      </div>
    </div>
  );
}

export default Header;
