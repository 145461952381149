import React from 'react';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import WatchoutsActionHeader from './WatchoutsActionHeader';
import WatchoutsContent from './WatchoutsContent';
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

export type Watchout = {
  id: string; // The other types will also actually use an id for mapping keys
  description: string;
  type: WatchoutType;
  sourceEntityName: string;
  creatorName: string; // Future: We may use an id to allow richer interactions (e.g. click to contact)
  dueDate?: string;
  escalatedAssigneeName: string;
  status: WatchoutStatus;
  hasActionPlan: boolean;
  actionPlan: string;
};

export enum WatchoutType {
  risk = 'Risk',
  issue = 'Issue',
  decision = 'Decision',
}

export enum WatchoutStatus {
  open = 'Open',
  escalated = 'Escalated',
  closed = 'Closed',
}

// For reference from escalations tab
// export type EscalatedWatchout = {
//   id: string; // The other types will also actually use an id for mapping keys
//   description: string;
//   type: "Risk" | "Issue" | "Decision";
//   sourceEntityName: string;
//   creatorName: string; // Future: We may use an id to allow richer interactions (e.g. click to contact)
//   dueDate?: string;
//   escalatedAssigneeName: string;
// };

type WatchoutsPanelProps = {
  watchouts: Watchout[];

  actionsDisabled: boolean;
  onEditClick: () => void;
  isVisible: boolean;
};

const WatchoutsTitle = () => (
  <DetailsHeaderTitle
    title={`What do we need to keep an eye on?`}
    tooltipText={`Log of risks, issues and decisions`}
  />
);

export default function WatchoutsPanel({
  watchouts,
  actionsDisabled,
  onEditClick,
  isVisible,
}: WatchoutsPanelProps): React.ReactElement {
  // Set up content to show

  return (
    <DetailsHeader
      headerLeft={<WatchoutsTitle />}
      headerRight={
        <WatchoutsActionHeader
          hasWatchouts={watchouts?.length > 0}
          onClick={onEditClick}
          isVisible={isVisible}
          disabled={actionsDisabled}
        />
      }
      content={<WatchoutsContent watchouts={watchouts} />}
    />
  );
}
