import React, { useContext } from 'react';
import {
  EntityType,
  Group,
  Portfolio,
  Program,
  Project,
  ProjectStage,
} from '../../api/index';
import DetailsPanelSectionDivider from '../../common/DetailsPanelSectionDivider';
import translate, {
  enumTranslates,
  strings,
} from '../../common/i18n/translate';
import EmptyState from '../../common/layout/EmptyState';
import PermissionLink from '../../common/PermissionLink';
import { PROGRAM, PROJECT } from '../../common/routes';
import { numToChar } from '../../common/utils';
import OpenWizardButton from '../../common/wizard/WizardButton';
import { ProgramPageContext } from '../program/ProgramPageContext';
import { ProjectPageContext } from '../project/ProjectPageContext';
import _ from 'lodash';

type Props = {
  portfolio: Portfolio | null;
  showDetails: boolean;
};

function PortfolioTreeLayout({
  portfolio,
  showDetails,
}: Props): React.ReactElement {
  const { state: projectPageState } = useContext(ProjectPageContext);
  const { state: programPageState } = useContext(ProgramPageContext);
  const programs = portfolio?.programs || [];

  // Non-zero based index based on the pillar order
  function getStrategicThemeIndex(program: Program): number | null {
    const themes = program.portfolio.themes;
    const themeIds = themes?.map(theme => theme.id);
    const result = themeIds?.indexOf(program.primaryStrategicThemeId);
    return result === undefined ? null : result + 1;
  }
  const programsSorted = _.orderBy([...programs], ['name'], ['desc']);
  const programsGrouped = programsSorted?.slice()?.sort((a, b) => {
    const aRank = getStrategicThemeIndex(a) || 100;
    const bRank = getStrategicThemeIndex(b) || 100;

    return aRank > bRank ? 1 : -1;
  });

  function projectsByStage(
    program: Program,
    stage: ProjectStage
  ): Array<Project> {
    const childProjects = program.projects?.filter(
      project => project.program.id === program.id
    );
    const foundProjects = childProjects?.filter(
      project => project.stage === stage
    );
    const result = foundProjects ? foundProjects : [];

    return result;
  }

  const cardHeight = showDetails ? 'h-[120px]' : 'h-[64px]';

  const portfolioTreeContent = (
    <React.Fragment>
      <div className={`flex flex-col items-center text-sm text-left`}>
        <div
          className={`bg-white text-center border border-gray-200 rounded-lg px-4 py-4 mb-6 lg:min-w-[40rem] max-w-4xl`}
        >
          {/* Portfolio info */}
          <div className="font-semibold text-base">{portfolio?.name}</div>

          <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 gap-x-4 gap-y-2">
            {/* Vision */}
            <div className="col-span-1 flex flex-col">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">
                Our Vision
              </div>
              <div className="mb-3 text-sm text-black border border-indigo-300 p-3 rounded-md flex-grow">
                <div className={`text-center`}>
                  {portfolio?.vision && portfolio?.vision.length > 0
                    ? portfolio?.vision
                    : '(Not provided)'}
                </div>
              </div>
            </div>

            {/* Mission */}
            <div className="col-span-1 flex flex-col">
              <div className="text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">
                Our Mission
              </div>
              <div className="mb-3 text-sm text-black border border-indigo-300 p-3 rounded-md flex-grow">
                <div className={`text-center`}>
                  {portfolio?.mission && portfolio?.mission.length > 0
                    ? portfolio?.mission
                    : '(Not provided)'}
                </div>
              </div>
            </div>
          </div>

          <div className="text-xs font-medium text-gray-500 uppercase tracking-wider mt-2 mb-2">
            {translate(
              strings.PORTFOLIO_STRATEGIC_PRIORITIES,
              'Strategic Pillars'
            )}
          </div>

          {portfolio?.themes && portfolio?.themes.length > 0 ? (
            <React.Fragment>
              {/* Larger screens: Show as columns */}
              <div className={`w-full hidden lg:flex gap-x-4`}>
                {portfolio?.themes?.map((theme, themeIndex) => (
                  <div
                    key={theme.id}
                    className={`bg-indigo-100 border border-indigo-300 p-3 rounded text-left w-1/${portfolio?.themes?.length} lg:min-w-[144px]`}
                  >
                    <div className="flex flex-col">
                      <div className="inline-flex items-baseline space-x-3">
                        <span className="flex-grow font-semibold text-indigo-700">{`${theme.name}`}</span>
                        <span className="text-xs text-white font-bold bg-indigo-600 rounded-full w-5 h-5 flex items-center align-middle justify-center text-center flex-shrink-0">
                          {`${numToChar(themeIndex + 1)}`}
                        </span>
                      </div>
                      {showDetails ? (
                        <div className="mt-2">
                          {`${theme.description ? `${theme.description}` : ''}`}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>

              {/* Smaller screens: Show as rows */}
              <div className={`w-full flex flex-col lg:hidden gap-y-4`}>
                {portfolio?.themes?.map((theme, themeIndex) => (
                  <div
                    key={theme.id}
                    className={`bg-indigo-100 border border-indigo-300 p-3 rounded text-left w-full`}
                  >
                    <div className="flex flex-col">
                      <div className="inline-flex items-baseline space-x-3">
                        <span className="flex-grow font-semibold text-indigo-700">{`${theme.name}`}</span>
                        <span className="text-xs text-white font-bold bg-indigo-600 rounded-full w-5 h-5 flex items-center align-middle justify-center text-center flex-shrink-0">
                          {`${numToChar(themeIndex + 1)}`}
                        </span>
                      </div>
                      {showDetails ? (
                        <div className="mt-2">
                          {`${theme.description ? `${theme.description}` : ''}`}
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </React.Fragment>
          ) : (
            <div className="-mt-1">(No strategic pillars yet)</div>
          )}
        </div>

        <div className="w-full overflow-x-auto pb-4">
          <div
            className={`flex mx-auto justify-evenly gap-x-4 gap-y-4 w-fit`} // grid grid-cols-${programs?.length}
          >
            {programsGrouped?.length == 0
              ? null
              : programsGrouped?.map(program => (
                  // Create a column for each program
                  <div
                    key={program.id}
                    className="col-span-1 px-4 py-3 w-[18rem] bg-gray-100 border border-gray-200 rounded-md"
                  >
                    <div className="flex flex-col gap-y-3">
                      {/* Header */}
                      <div
                        className={`flex flex-col ${
                          showDetails ? 'h-40' : 'h-14'
                        } relative group overflow-clip safari-clip hover:overflow-visible hover:bg-gray-100`}
                      >
                        <div className="flex relative">
                          {/* Program info */}
                          <div
                            className={`${
                              showDetails
                                ? ''
                                : 'flex-grow hover:h-fit hover:bg-gray-100 hover:pb-4 hover:border-b-2 hover:border-primary-500 hover:-mx-4 hover:px-4'
                            }`}
                          >
                            <PermissionLink
                              entity={program}
                              to={`${PROGRAM}/${program.id}#performance`}
                              allowedLayout={
                                <div
                                  className={`font-semibold pr-10 mt-1 hover:underline hover:text-primary-600`}
                                >
                                  {program.name}
                                </div>
                              }
                              blockedLayout={
                                <div
                                  className={`font-semibold pr-10 mt-1 hover:text-gray-500`}
                                >
                                  {program.name}
                                </div>
                              }
                            />
                          </div>

                          <span className="h-min ml-2 -mr-1 absolute top-0 right-0">
                            <span className="text-xs text-white font-bold bg-indigo-600 rounded-full m-1 w-5 h-5 flex items-center align-middle justify-center text-center flex-shrink-0">
                              {`${numToChar(getStrategicThemeIndex(program))}`}
                            </span>

                            {programPageState.programs?.some(
                              p =>
                                p.id === program.id &&
                                (p.group === Group.Owner ||
                                  p.group === Group.Editor)
                            ) && (
                              <span className="hidden group-hover:block absolute top-0 right-0">
                                {/* <OpenWizardButton
                                  title={translate(
                                    strings.WIZARD_NEW_PROGRAM,
                                    'New Program'
                                  )}
                                  editObject={program}
                                  parentObject={portfolio}
                                  type="PROGRAM_QUICK_ADD"
                                  startStep={1}
                                /> */}
                              </span>
                            )}
                          </span>
                          {/* {OverflowMenu(
                          program,
                          isEditable
                          // setModalOpen
                          // handlePublishClick,
                          // filterExceptions
                          // setFilterExceptions,
                        )} */}
                        </div>
                        {showDetails ? (
                          <React.Fragment>
                            <div
                              className={`flex-grow mt-3 group-hover:bg-gray-100 group-hover:pb-4 group-hover:-mx-4 group-hover:px-4 group-hover:border-b-2 group-hover:border-primary-500`}
                            >
                              {program.outcome}
                            </div>
                          </React.Fragment>
                        ) : null}
                        {/* Gradient to indicate more text (in place of a height dependent multi-line ellipsis) */}
                        <div
                          className={`z-[2] blocker w-full h-8 bg-gradient-to-b from-transparent to-gray-100 absolute ${
                            showDetails ? 'top-32' : 'top-6'
                          } left-0 group-hover:hidden`}
                        ></div>
                      </div>

                      {/* List of projects */}
                      <div className="flex flex-col mt-2 gap-y-6">
                        <div>
                          {/* Start with in flight projects */}
                          <DetailsPanelSectionDivider
                            title={`${enumTranslates[ProjectStage.Inflight]}`}
                          />
                          {projectsByStage(program, ProjectStage.Inflight)
                            .length > 0 ? (
                            <div className="flex flex-col gap-y-4">
                              {projectsByStage(
                                program,
                                ProjectStage.Inflight
                              ).map(project => (
                                <div
                                  key={project.id}
                                  className={`z-[1] hover:z-[2] flex flex-col px-3 py-3 ${cardHeight} bg-gray-200 rounded-md overflow-clip safari-clip relative group hover:overflow-visible`}
                                >
                                  <div
                                    className={`flex justify-between relative`}
                                  >
                                    {portfolio && (
                                      <PermissionLink
                                        entity={project}
                                        parentPortfolio={portfolio}
                                        to={`${PROJECT}/${project.id}`}
                                        allowedLayout={
                                          <div className="font-semibold pr-10 hover:underline hover:text-primary-600">
                                            {project.name}
                                          </div>
                                        }
                                        blockedLayout={
                                          <div className="font-semibold pr-10 hover:text-gray-500">
                                            {project.name}
                                          </div>
                                        }
                                      />
                                    )}

                                    {projectPageState.usersProjects?.some(
                                      p =>
                                        p.id === project.id &&
                                        (p.group === Group.Editor ||
                                          p.group === Group.Owner)
                                    ) && (
                                      <span className="h-min ml-2 hidden group-hover:block absolute top-0 right-0">
                                        {/* <OpenWizardButton
                                          title={translate(
                                            strings.WIZARD_NEW_PROJECT,
                                            'New Project'
                                          )}
                                          editObject={project}
                                          parentObject={program}
                                          type="PROJECT_QUICK_ADD"
                                          startStep={1}
                                        /> */}
                                      </span>
                                    )}
                                  </div>

                                  {showDetails && project.outcome ? (
                                    <div className="-mx-3 ">
                                      <div
                                        className={`mt-2 bg-gray-200 h-20 group-hover:min-h-[80px] group-hover:h-fit px-3 group-hover:pb-3 group-hover:rounded-b-md group-hover:border-b-2 group-hover:border-gray-300`}
                                      >
                                        {project.outcome}
                                      </div>
                                      {/* <div className="h-2 w-full bg-gradient-to-b from-gray-400 to-transparent"></div> */}
                                    </div>
                                  ) : null}

                                  {/* Gradient to indicate more text (in place of a height dependent multi-line ellipsis) */}
                                  <div
                                    className={`blocker w-full h-10 bg-gradient-to-b from-transparent to-gray-200 absolute ${
                                      showDetails ? 'top-20' : 'top-6'
                                    } left-0 group-hover:hidden`}
                                  ></div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            // Show empty state
                            <div
                              className={`${cardHeight} px-4 ${
                                showDetails ? 'py-4' : 'py-3'
                              } border-2 border-dashed border-gray-300 rounded-md`}
                            >
                              <EmptyState
                                type={`in-flight ${
                                  enumTranslates[EntityType.Project]
                                }s`}
                                secondaryText={`${
                                  showDetails
                                    ? `We're not actively working on anything at this time`
                                    : ' '
                                }`}
                                hideIcon={true}
                              />
                            </div>
                          )}
                        </div>

                        <div>
                          {/* Then show planned projects */}
                          <DetailsPanelSectionDivider
                            title={`${enumTranslates[ProjectStage.Inplanning]}`}
                          />
                          {projectsByStage(program, ProjectStage.Inplanning)
                            .length > 0 ? (
                            <div className="flex flex-col gap-y-4">
                              {projectsByStage(
                                program,
                                ProjectStage.Inplanning
                              ).map(project => (
                                <div
                                  key={project.id}
                                  className={`z-[1] hover:z-[2] flex flex-col px-3 py-3 ${cardHeight} bg-gray-200 rounded-md overflow-clip safari-clip relative group hover:overflow-visible`}
                                >
                                  <div
                                    className={`flex justify-between relative`}
                                  >
                                    {portfolio && (
                                      <PermissionLink
                                        entity={project}
                                        parentPortfolio={portfolio}
                                        to={`${PROJECT}/${project.id}`}
                                        allowedLayout={
                                          <div className="font-semibold pr-10 hover:underline hover:text-primary-600">
                                            {project.name}
                                          </div>
                                        }
                                        blockedLayout={
                                          <div className="font-semibold pr-10 hover:text-gray-500">
                                            {project.name}
                                          </div>
                                        }
                                      />
                                    )}

                                    {/* {projectPageState.usersProjects?.some(
                                      p =>
                                        p.id === project.id &&
                                        (p.group === Group.Editor ||
                                          p.group === Group.Owner)
                                    ) && (
                                      <span className="h-min ml-2 hidden group-hover:block absolute top-0 right-0">
                                        <OpenWizardButton
                                          title={translate(
                                            strings.WIZARD_NEW_PROJECT,
                                            'New Project'
                                          )}
                                          editObject={project}
                                          parentObject={program}
                                          type="PROJECT_QUICK_ADD"
                                          startStep={1}
                                        />
                                      </span>
                                    )} */}
                                  </div>

                                  {showDetails && project.outcome ? (
                                    <div className="-mx-3 ">
                                      <div
                                        className={`mt-2 bg-gray-200 h-20 group-hover:min-h-[80px] group-hover:h-fit px-3 group-hover:pb-3 group-hover:rounded-b-md group-hover:border-b-2 group-hover:border-gray-300`}
                                      >
                                        {project.outcome}
                                      </div>
                                      {/* <div className="h-2 w-full bg-gradient-to-b from-gray-400 to-transparent"></div> */}
                                    </div>
                                  ) : null}

                                  {/* Gradient to indicate more text (in place of a height dependent multi-line ellipsis) */}
                                  <div
                                    className={`blocker w-full h-10 bg-gradient-to-b from-transparent to-gray-200 absolute ${
                                      showDetails ? 'top-20' : 'top-6'
                                    } left-0 group-hover:hidden`}
                                  ></div>
                                </div>
                              ))}
                            </div>
                          ) : null}

                          {/* Then quick add project action button */}
                          {programPageState.programs?.some(
                            p =>
                              p.id === program.id &&
                              (p.group === Group.Editor ||
                                p.group === Group.Owner)
                          ) && (
                            <div
                              className={`${cardHeight} ${
                                projectsByStage(
                                  program,
                                  ProjectStage.Inplanning
                                ).length > 0
                                  ? 'mt-4'
                                  : ''
                              } border-2 border-dashed border-gray-300 rounded-md`}
                            >
                              {/* Add new project button */}
                              {/* {
                                <OpenWizardButton
                                  title={translate(
                                    strings.WIZARD_NEW_PROJECT,
                                    'New Project'
                                  )}
                                  editObject={null}
                                  parentObject={program}
                                  type="PROJECT_QUICK_ADD"
                                  startStep={1}
                                />
                              } */}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

            {/* New Program */}
            {(portfolio?.group === Group.Editor ||
              portfolio?.group === Group.Owner) && (
              <div className="col-span-1 w-[18rem] bg-white border-2 border-dashed border-gray-300 rounded-md">
                {/* Add new program button */}
                {/* {
                  <OpenWizardButton
                    title={translate(strings.WIZARD_NEW_PROGRAM, 'New Program')}
                    editObject={null}
                    parentObject={portfolio}
                    type="PROGRAM_QUICK_ADD"
                    startStep={1}
                  />
                } */}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return portfolioTreeContent;
}

export default PortfolioTreeLayout;
