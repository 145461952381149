import * as React from 'react';

import { createContext, useState } from 'react';

export type DemoContext = {
  isDemo: boolean;
  toggleIsDemo?: () => void;
};

const defaultState = {
  isDemo: localStorage.getItem('isDemo') == 'demo' ? true : false,
};

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

export const DemoContext = createContext<DemoContext>(defaultState);

export function DemoProvider({ children }: Props): React.ReactElement {
  const [isDemo, setIsDemo] = useState(defaultState.isDemo);

  const toggleIsDemo = () => {
    setIsDemo(!isDemo);
    localStorage.setItem('isDemo', !isDemo === true ? 'demo' : '');
  };

  return (
    <DemoContext.Provider
      value={{
        isDemo,
        toggleIsDemo,
      }}
    >
      {children}
    </DemoContext.Provider>
  );
}
