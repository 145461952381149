import React from 'react';
import Tag from '../../common/Tag';

function Header(): React.ReactElement {
  return (
    <div className="w-full lg:flex lg:items-center lg:justify-between">
      <div className="flex-1 min-w-0">
        <h2 className="mt-2 text-2xl font-bold leading text-gray-900 sm:text-3xl sm:truncate flex gap-x-3">
          <span>Reporting Hub</span>
          <span className="flex flex-col justify-end pb-0.5">
            <span className="h-min">
              <Tag type="preview" />
            </span>
          </span>
        </h2>
        <div className="mt-2 mb-1 mr-6 flex align-top text-sm text-gray-500 max-w-3xl">
          Recipients will get an email with a link and access code for the
          selected report.
        </div>
      </div>
      <div className="mt-5 flex lg:mt-0 lg:ml-4"></div>
    </div>
  );
}

export default Header;
