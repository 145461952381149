import { Auth } from 'aws-amplify';
import * as React from 'react';
import { useState } from 'react';
import { SIGNIN } from '../common/routes';
import { AuthErrorType } from '../common/types';
import { useNavigate, useParams } from 'react-router-dom';
import graphic from './graphics/verify_code.png';
import translate, { strings } from '../common/i18n/translate';
import SmallScreenBanner from './SmallScreenBanner';
import PrimaryButton from '../common/PrimaryButton';

type AppState = {
  authCode: string;
  loginError: boolean;
  errorText: string;
};

const initialFormState: AppState = {
  authCode: '',
  loginError: false,
  errorText: '',
};

function SignUpConfirm(): React.ReactElement {
  const [formState, updateFormState] = useState<AppState>(initialFormState);
  const navigate = useNavigate();

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };
  const { email } = useParams<{ email: string }>();

  const confirmSignup = async () => {
    const { authCode } = formState;
    try {
      await Auth.confirmSignUp(email as string, authCode)
        .then(() => navigate(SIGNIN))
        .catch(error => {
          const errorT = error as AuthErrorType;
          updateFormState(() => ({
            ...formState,
            errorText: errorT.message,
          }));
        });
    } catch (error) {
      const errorT = error as AuthErrorType;
      updateFormState(() => ({
        ...formState,
        errorText: errorT.message,
      }));
    }
  };

  const resendSignUp = async () => {
    try {
      await Auth.resendSignUp(email as string)
        .then(() =>
          updateFormState(() => ({
            ...formState,
            errorText: 'New sign up code sent',
          }))
        )
        .catch(error => {
          const errorT = error as AuthErrorType;
          updateFormState(() => ({
            ...formState,
            errorText: errorT.message,
          }));
        });
    } catch (error) {
      const errorT = error as AuthErrorType;
      updateFormState(() => ({
        ...formState,
        errorText: errorT.message,
      }));
    }
  };

  return (
    <div className="min-h-screen bg-secondary-800 sm:bg-gray-200 flex flex-col sm:justify-center sm:py-12 sm:px-6 lg:px-8">
      <div className="mt-0 w-full mx-auto sm:max-w-md md:max-w-5xl flex flex-col md:flex-row sm:shadow-lg sm:rounded-md overflow-hidden">
        {/* Support message */}
        <SmallScreenBanner />

        {/* Graphic */}
        <div className="flex flex-col flex-grow justify-between px-8 py-8 bg-white">
          <div className="flex flex-col text-left">
            <div className="flex items-center justify-center md:justify-start gap-x-3">
              <img
                className={`h-10 w-10 shrink-0 rounded-full`}
                src={`/jl-logo-circle_bg-192.png`}
                alt="JourneyLab logo"
              />
              <h1 className="pt-0.5 text-3xl font-medium">{`${translate(
                strings.BRAND_NAME,
                'JourneyLab'
              )}`}</h1>
            </div>
            {/* <p className="text-gray-500">By JourneyLab</p> */}
            <p className="mt-3 mb-0 md:mb-8 -mx-4 md:mx-0 text-center md:text-left text-lg md:text-xl text-primary-500 font-semibold">
              Please check your email
            </p>
          </div>
          <img
            className="hidden md:block h-96 object-contain"
            src={graphic}
            alt="Reset Password"
          />
        </div>

        {/* Form */}
        <div className="flex flex-col flex-grow flex-shrink-0 justify-between px-8 py-8 bg-gradient-to-b from-accent-dark to-secondary-800 text-white shadow">
          <div className="flex flex-col">
            <h2 className="mb-4 text-center text-2xl font-semibold">
              Enter confirmation code
            </h2>

            <div className="mb-4">
              {formState.errorText && (
                <div className="login-form-error mx-auto text-sm text-red-600 font-semibold text-center">
                  {formState.errorText}
                </div>
              )}
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium">
                Confirmation code
              </label>
              <div className="mt-1">
                <input
                  id="code"
                  name="authCode"
                  type="text"
                  onChange={inputChange}
                  required
                  className="text-black appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="mt-4">
              <PrimaryButton
                label="Create Account"
                onClick={confirmSignup}
                disabled={false}
                width="w-full"
              />
            </div>

            <div className="mt-4">
              <button
                onClick={resendSignUp}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-500 bg-white bg-opacity-0 hover:bg-opacity-20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Resend Code
              </button>
            </div>

            {/* <div className="pt-10">
              <p className="pb-2 text-sm text-center">
                Don't need this any more?
              </p>
              <div>
                <button
                  onClick={() => props.navigate(SIGNIN)}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-primary-500 bg-secondary-800 hover:bg-secondary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Back to Sign In
                </button>
              </div>
            </div> */}
          </div>

          {/* Attribution: Graphic credit */}
          <a
            href="https://www.freepik.com/free-vector/two-factor-authentication-concept-illustration_13246824.htm#query=two%20factor%20authentication&position=0&from_view=author"
            target="_blank"
            className="text-xs text-secondary-50 text-center mt-8 hidden md:block"
          >
            Illustration by Storyset
          </a>
        </div>
      </div>
    </div>
  );
}

export default SignUpConfirm;
