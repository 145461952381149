import React, { useState } from 'react';
import { AddIcon, EditIcon } from '../../basic/ButtonIcons';
import BasicButton from '../../basic/BasicButton';
import DotMenu, { MenuOption } from '../../basic/DotMenu';
import ReviewInputScreen from './ReviewInputScreen';

export type AboutActionHeaderProps = {
  hasReview: boolean;
  onClick: () => void;
  disabled: boolean;
  isVisible: boolean;
  menuOptions: Array<MenuOption>;
};

const ReviewActionHeader = ({
  hasReview,
  onClick,
  disabled,
  isVisible,
  menuOptions,
}: AboutActionHeaderProps) => {
  const [modalOpen, setModalOpen] = useState(false);

  const label = hasReview ? 'Edit' : 'Add review';
  const icon = hasReview ? <EditIcon /> : <AddIcon />;

  function handleAddReviewClick() {
    setModalOpen(true);
  }

  return isVisible ? (
    <div className="flex items-center">
      <span className="flex flex-wrap">
        <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
          <span className="sm:ml-3">
            <BasicButton
              icon={icon}
              label={label}
              onClick={handleAddReviewClick}
              disabled={disabled}
            />
          </span>

          {hasReview && (
            <div className="flex items-center group relative">
              <DotMenu menuOptions={menuOptions} />
            </div>
          )}
        </div>
      </span>

      <div className="flex items-center group relative">
        {modalOpen && <ReviewInputScreen onClose={setModalOpen} />}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ReviewActionHeader;
