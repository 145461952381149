import React from 'react';
import {
  ArchiveIcon,
  DeadlineIcon,
  DeleteIcon,
  EscalateIcon,
  DeescalateIcon,
  AgreedActionsIcon,
} from '../../basic/ButtonIcons';
import RoundButton from '../../basic/RoundButton';
import Tag from '../../basic/Tag';
import BasicTable, { DataRow } from '../../common/tables/BasicTable';
import BasicTableCell from '../../common/tables/BasicTableCell';
import DotMenuTableCell from '../../common/tables/DotMenuTableCell';
import StyledTableCell from '../../common/tables/StyledTableCell';
import { Watchout, WatchoutStatus } from './WatchoutsPanel';
import ReactLayoutTableCell from './WatchoutTypeTableCell';

type WatchoutsContentProps = {
  watchouts: Watchout[];
};

function getActionButton(itemId: string, status: WatchoutStatus) {
  let button;

  if (status === WatchoutStatus.open) {
    button = {
      id: `ESCALATE_${itemId}`,
      action: () => console.log(`Escalate item ${itemId} clicked`),
      text: 'Escalate to...',
      icon: <EscalateIcon />,
    };
  } else if (status === WatchoutStatus.escalated) {
    button = {
      id: `DEESCALATE_${itemId}`,
      action: () => console.log(`De-escalate item ${itemId} clicked`),
      text: 'De-escalate',
      icon: <DeescalateIcon />,
    };
  } else {
    button = {
      id: `ARCHIVE_${itemId}`,
      action: () => console.log(`Archive item ${itemId} clicked`),
      text: 'Archive',
      icon: <ArchiveIcon />,
    };
  }

  return button;
}

type ActionPlanButtonType = {
  itemId: string;
  actionPlan: string;
};

function ActionPlanButton({
  itemId,
  actionPlan: actions,
}: ActionPlanButtonType) {
  return (
    <RoundButton
      id={`REVIEW_${itemId}`}
      icon={<AgreedActionsIcon />}
      label={`Agreed actions:`}
      secondaryText={actions}
      onClick={() => null}
    />
  );
}

// Menu options the user can take on each waypoint checklist item
function menuOptions(itemId: string, status: WatchoutStatus) {
  const customButtom = getActionButton(itemId, status);

  return [
    customButtom,
    {
      id: 'DUEDATE01',
      action: () => console.log(`Set due date for ${itemId}`),
      text: 'Set deadline...',
      icon: <DeadlineIcon />,
    },
    {
      id: 'DELETE01',
      action: () => console.log(`Delete item ${itemId}`),
      text: 'Delete item...',
      icon: <DeleteIcon />,
    },
  ];
}

export default function WatchoutsContent({
  watchouts,
}: WatchoutsContentProps): React.ReactElement {
  const tableHeader: DataRow = {
    data: [
      {
        key: 'TH1',
        cellContents: <BasicTableCell contents="Type" />,
        widthClass: 'w-20',
        // tooltip: <div className="inline-flex">'No comment'</div>,
      },
      { key: 'TH2', cellContents: <BasicTableCell contents="Description" /> },
      { key: 'TH3', cellContents: <BasicTableCell contents="Status" /> },
      {
        key: 'TH4',
        cellContents: <BasicTableCell contents="" />, // Actions menu
        // tooltip: <div>When we expect to achieve the target</div>,
      },
    ],
  };

  const tableData: DataRow[] = [];
  watchouts &&
    watchouts.forEach(watchout =>
      tableData.push({
        data: [
          {
            key: `${watchout.id}-type`,
            cellContents: (
              <ReactLayoutTableCell
                contents={<Tag type="ask type" label={watchout.type} />}
              />
            ),
          },
          {
            key: `${watchout.id}-description`,
            cellContents: (
              <StyledTableCell
                styleClass={`py-1 ${
                  watchout.status === WatchoutStatus.closed
                    ? 'line-through text-gray-400'
                    : ''
                }`}
                contents={watchout.description || '-'}
              />
            ),
          },
          {
            key: `${watchout.id}-status`,
            cellContents: (
              <ReactLayoutTableCell
                contents={
                  <Tag
                    type="custom"
                    bgColour="bg-gray-200"
                    textColour="text-black"
                    label={watchout.status}
                  />
                }
              />
            ),
          },
          {
            key: `${watchout.description}-actions`,
            cellContents: (
              <div className="flex gap-x-3 justify-end items-center">
                {watchout.hasActionPlan && (
                  <ActionPlanButton
                    itemId={watchout.id}
                    actionPlan={watchout.actionPlan}
                  />
                )}
                <DotMenuTableCell
                  menuOptions={menuOptions(watchout.id, watchout.status)}
                />
              </div>
            ),
          },
        ],
      })
    );

  return (
    <div className="min-w-full space-y-4 lg:space-y-3">
      {watchouts?.length > 0 ? (
        <React.Fragment>
          <BasicTable tableData={tableData} tableHeader={tableHeader} />

          {/* Ensure line-through class remains through Tailwind tree-shake */}
          <div className="hidden line-through" />
        </React.Fragment>
      ) : (
        // Empty state
        <div className="text-sm text-gray-400 italic text-center">
          No watchouts added yet
        </div>
      )}
    </div>
  );
}
