import React, { useState } from 'react';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import { enumTranslates } from '../../../common/i18n/translate';
import { EntityType, Program, Project, ProjectStage } from '../../../api/index';
import { getCurrentQuarter } from '../../../common/utils';
import ReactTooltip from 'react-tooltip';
import { CirclesThree } from 'phosphor-react';
import { ulid } from 'ulid';
import SlideOver from '../../prioritisation/SlideOver';
import EntitySwimlanesSlideOverContent from './EntitySwimlanesSlideOverContent';

type Props = {
  entity: Project | Program;
  children: Project[];
};

// For dummy data
export type SubEntity = {
  name: string;
  leadTime: number;
  length: number;
  status: ProjectStage;
  description: string;
};

export default function EntitySwimlanesPanel({
  entity,
  children,
}: Props): React.ReactElement {
  // TODO: We need to confirm the stages and logic here
  const swimlaneEntities = children.filter(
    item => item.stage === ProjectStage.Inflight
  );
  const laterEntities = children.filter(
    item => item.stage === ProjectStage.Inplanning
  );

  const quarters = getCurrentQuarter();

  // Slideover setup
  const [showSlideOver, setShowSlideOver] = useState(false);
  const [slideOverEntity, setSlideOverEntity] = useState<SubEntity>();

  // Setup slideover panels
  const programSlideOver = slideOverEntity && (
    <SlideOver
      open={showSlideOver}
      setOpen={setShowSlideOver}
      title={slideOverEntity.name || '[Entity Name]'}
      titleLink={
        // Blank for v1.5 while using dummy data, but we will add the relevant link in v2
        ''
      }
      meta={
        <React.Fragment>
          <div className="text-gray-600">
            <span className="inline-block font-semibold">Description:</span>{' '}
            {slideOverEntity.description}
          </div>
        </React.Fragment>
      }
      content={
        // <ProgramSlideoverLayout
        //   slideOverProgram={slideOverProgram}
        //   programs={programsList || []}
        // />
        <div className="text-sm">
          <EntitySwimlanesSlideOverContent entity={slideOverEntity} />
        </div>
      }
    />
  );

  function handleEntityClick(entity: SubEntity) {
    setSlideOverEntity(entity);
    setShowSlideOver(true);
  }

  const scheduleTitle = (
    <DetailsPanelHeader
      title={`Swimlanes`}
      description={`High level timeline for this ${
        enumTranslates[EntityType.Project]
      }`}
    />
  );

  // User can choose one of the following historical periods for comparison via a select
  const groupingOptions = [
    { name: 'By function', value: 'FUNCTION' },
    { name: 'By strategic objective', value: 'OBJECTIVE' },
    // { name: 'By dependency stream', value: 'DEPENDENCY' },
  ];

  // Dummy data for workstreams since we don't have a deep hierarchy yet
  // Lead and length should be less than 100 total
  const subEntities: SubEntity[] = [
    {
      name: 'Project A',
      leadTime: 0,
      length: 300,
      status: ProjectStage.Inflight,
      description: 'Enter a new market.',
    },
    {
      name: 'Project B',
      leadTime: 25,
      length: 180,
      status: ProjectStage.Inplanning,
      description: 'Reduce cost base incurred by legacy platforms.',
    },
  ];

  function handleUpdateSelection(value: string) {
    // Do nothing
    console.log('handleUpdateSelection here');
  }

  const scheduleButtonId = ulid();
  const scheduleButton = (
    <div className="flex items-center space-x-3">
      <div className="flex">
        <span
          className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center"
          data-tip
          data-for={scheduleButtonId}
        >
          <CirclesThree className="h-5 w-5" weight="bold" />
        </span>
        <select
          className="block rounded-r-md pl-3 pr-8 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          defaultValue={groupingOptions[0].name}
          onChange={e => handleUpdateSelection(e.target.value)}
        >
          {groupingOptions.map((item, itemIdx) => (
            <option key={itemIdx} value={item.value}>
              {item.name}
            </option>
          ))}
        </select>

        <ReactTooltip
          id={scheduleButtonId}
          place="top"
          type="info"
          effect="solid"
        >
          <div className="text-sm text-center">
            <p>How to group the entities.</p>
          </div>
        </ReactTooltip>
      </div>
    </div>
  );

  const scheduleContent = (
    <div className="space-y-6">
      {/* Rollup */}
      {children.length > 0 && (
        <div className="space-y-3">
          {/* <p className="text-sm font-semibold text-gray-900">{`Swimlanes`}</p> */}
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto">
              <div className="py-2 align-middle inline-block min-w-full">
                <div className="overflow-hidden border border-gray-200 rounded-md">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      {/* Header row */}

                      <tr className="">
                        <th
                          scope="col"
                          className={`w-1/6 pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                          key={`group`}
                        >
                          {`Group`}
                        </th>
                        <th
                          scope="col"
                          className={`pl-2 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                          key={`q1`}
                        >
                          {quarters[0]}
                        </th>
                        <th
                          scope="col"
                          className={`pl-2 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                          key={`q2`}
                        >
                          {quarters[1]}
                        </th>
                        <th
                          scope="col"
                          className={`pl-2 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                          key={`q3`}
                        >
                          {quarters[2]}
                        </th>
                        <th
                          scope="col"
                          className={`pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                          key={`q4`}
                        >
                          {quarters[3]}
                        </th>
                        <th
                          scope="col"
                          className={`w-1/4 pl-4 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                          key={`planned`}
                        >
                          {`Later...`}
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200 text-sm">
                      {swimlaneEntities.length > 0 ? (
                        swimlaneEntities.map((entity, index) => {
                          return (
                            <tr className="" key={entity.id}>
                              <td
                                className={`pl-4 pr-2 py-2 text-left align-top w-40`}
                              >
                                <p className="font-medium">{entity.name}</p>
                              </td>
                              <td
                                className={`pl-2 pr-4 py-2 align-top w-40 col-span-4`}
                                colSpan={4}
                              >
                                {/* These would be replaced with the list of grandchildren entities */}
                                <div className="space-y-2 -ml-2">
                                  {subEntities.map(item => (
                                    <div
                                      className="flex gap-x-2"
                                      key={item.name} // TODO: Use the actual id in v2
                                    >
                                      {/* First div is to capture lead time and is
                                   invisible */}
                                      <div
                                        className={`invisible w-[${item.leadTime}%] h-6 text-transparent`}
                                      >
                                        {/* Placeholder text to force the div to render */}
                                        {`.`}
                                      </div>
                                      <div
                                        className={`w-[70%] h-8 px-2 ${
                                          item.status === ProjectStage.Inflight
                                            ? 'bg-primary-200 hover:bg-primary-300 '
                                            : 'border-2 border-dashed border-primary-200 hover:bg-gray-100 '
                                        } rounded-md hover:cursor-pointer`}
                                        onClick={() => handleEntityClick(item)}
                                      >
                                        {item.name}
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </td>
                              {index === 0 && (
                                <td
                                  className={`pl-4 pr-4 py-2 align-top whitespace-pre-wrap border-l border-gray-200`}
                                  rowSpan={swimlaneEntities.length}
                                >
                                  <div className="space-y-2">
                                    {laterEntities.map(plannedEntity => (
                                      <div
                                        key={plannedEntity.id}
                                        className="bg-gray-100 border border-gray-200 rounded-md px-3 py-2"
                                      >
                                        {plannedEntity.name}
                                      </div>
                                    ))}
                                  </div>
                                </td>
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="" key={entity.id}>
                          <td
                            className={`pl-4 pr-2 py-2 text-left align-top w-40`}
                          >
                            <p className="font-medium"></p>
                          </td>
                          <td
                            className={`pl-2 pr-4 py-2 align-top w-40 col-span-4`}
                            colSpan={4}
                          >
                            {/* These would be replaced with the list of grandchildren entities */}
                            <div className="space-y-2"></div>
                          </td>

                          <td
                            className={`pl-4 pr-4 py-2 align-top whitespace-pre-wrap border-l border-gray-200`}
                            // rowSpan={swimlaneEntities.length}
                          >
                            <div className="space-y-2">
                              {laterEntities.map(plannedEntity => (
                                <div
                                  key={plannedEntity.id}
                                  className="bg-gray-100 border border-gray-200 rounded-md px-3 py-2"
                                >
                                  {plannedEntity.name}
                                </div>
                              ))}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Debug section to check data */}
          {/* {children.map(item => (
            <div className="text-sm">
              <div className="font-medium">{item.name}</div>
              <div>{`${getLocalDate(item.startDate)} - ${getLocalDate(
                item.deliveryDate
              )}`}</div>
            </div>
          ))} */}
        </div>
      )}
    </div>
  );

  return (
    <React.Fragment>
      {/* Swimlanes in a panel */}
      <DetailsPanel
        headerLeft={scheduleTitle}
        headerRight={children.length > 0 ? scheduleButton : null}
        content={scheduleContent}
      />

      {/* Slideover to show when we click in */}
      {programSlideOver}
    </React.Fragment>
  );
}
