import React from 'react';
import { Circle, Target } from 'phosphor-react';
import Tag from '../basic/Tag';
import { SlideOverSectionHeader } from '../common/slideovers/SlideOverSectionHeader';

export type ObjectiveUpdate = {
  date: string;
  value: string;
  tag: string;
};

type ObjectiveUpdateRowProps = {
  date: string;
  value: string;
  tag: string;
  index: number;
  totalRows: number;
};

const TimelineMarker = () => (
  <Circle size={18} weight="fill" className="text-primary-500 shrink-0" />
);

const TimelineMarkerTarget = () => (
  <Target size={24} weight="duotone" className="text-accent-light shrink-0" />
);

const TopLine = () => (
  <div
    className={`absolute w-px h-1/4 left-1/2 top-0 border-l-2 border-gray-300 -translate-x-px`}
  />
);

const TopLineDashed = () => (
  <div
    className={`absolute w-px h-2/5 left-1/2 top-0 border-l-2 border-gray-300 -translate-x-px border-dashed`}
  />
);

const BottomLine = () => (
  <div
    className={`absolute w-px h-1/4 left-1/2 bottom-0 border-l-2 border-gray-300 -translate-x-px`}
  />
);

const BottomLineDashed = () => (
  <div
    className={`absolute w-px h-1/4 left-1/2 bottom-0 border-l-2 border-gray-300 -translate-x-px border-dashed`}
  />
);

function ObjectiveUpdateRow({
  date,
  value,
  tag,
  index,
  totalRows,
}: ObjectiveUpdateRowProps): React.ReactElement {
  // Assuming that the target is the last item in the array
  const yPadding = tag === 'Target' ? 'pt-8 pb-3' : 'py-3';

  const showTopLine = index > 0 && totalRows > 1 && index < totalRows - 1;
  const showTopLineDashed = index === totalRows - 1 && tag === 'Target';
  const showBottomLine = totalRows > 1 && index < totalRows - 2;
  const showBottomLineDashed = totalRows > 1 && index === totalRows - 2;

  return (
    <div className={`table-row text-sm`}>
      {/* Date */}
      <div
        className={`table-cell px-2 ${yPadding} align-middle whitespace-nowrap text-gray-800 w-20 text-center`}
      >
        {date}
      </div>

      {/* Timeline styling */}
      <div
        className={`table-cell px-2 ${yPadding} align-middle relative w-min h-full`}
      >
        {/* Top half line */}
        {showTopLine && <TopLine />}
        {showTopLineDashed && <TopLineDashed />}

        {/* Bottom half line */}
        {showBottomLine && <BottomLine />}
        {showBottomLineDashed && <BottomLineDashed />}

        {/* Marker */}
        <div className="flex justify-center">
          {tag === 'Target' ? <TimelineMarkerTarget /> : <TimelineMarker />}
        </div>
      </div>

      {/* Details */}
      <div className={`table-cell px-2 ${yPadding} align-middle font-medium`}>
        {value}
      </div>

      {/* Tag */}
      <div className={`table-cell px-2 ${yPadding} align-middle`}>
        <div className="flex items-center justify-center">
          {tag.length > 0 && (
            <Tag
              type="custom"
              bgColour="bg-accent-light"
              textColour="text-white"
              label={tag}
            />
          )}
        </div>
      </div>
    </div>
  );
}

type ObjectiveSlideOverContentProps = {
  objectiveUpdates: ObjectiveUpdate[];
};

export default function ObjectiveSlideOverContent({
  objectiveUpdates,
}: ObjectiveSlideOverContentProps): React.ReactElement {
  return (
    <div className="pb-12">
      {/* Content */}
      <SlideOverSectionHeader label={`Progress`} />

      {objectiveUpdates?.length > 0 ? (
        <div className="table w-full">
          <div className="table-row-group">
            {objectiveUpdates.map((update, index) => (
              <ObjectiveUpdateRow
                key={update.date}
                date={update.date}
                value={update.value}
                tag={update.tag}
                index={index}
                totalRows={objectiveUpdates.length}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="text-gray-400 italic">No updates found</div>
      )}
    </div>
  );
}
