import React from 'react';
import { addDays, getLocalDate, getRelativeTime } from '../../../common/utils';
import { SubEntity } from './EntitySwimlanesPanel';

type Props = {
  entity: SubEntity; // Project | Program;
};

export default function EntitySwimlanesSlideOverContent({
  entity,
}: Props): React.ReactElement {
  // For the dummy data, we are specifying a lead time as a whole number percent of "four quarters"
  // e.g. 25 = 25% of the year
  // We need to convert this to days to get a relative time
  const startLeadtimeDays = Math.round((entity.leadTime / 100) * 365);
  const startDate = addDays(new Date(), startLeadtimeDays);
  const entityLengthDays = Math.round((70 / 100) * 365); // Fixed for now
  const endDate = addDays(startDate, entityLengthDays);

  return (
    <div className="space-y-4">
      <div>{`Start date: ${getLocalDate(startDate)} (${getRelativeTime(
        startDate
      )})`}</div>
      <div>{`End date: ${getLocalDate(endDate)} (${getRelativeTime(
        endDate
      )})`}</div>

      <div>
        Items to be added: Strategic objectives, dependencies, lifecycle stage.
      </div>
    </div>
  );
}
