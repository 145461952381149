import {
  ArrowDown,
  ArrowRight,
  ArrowUp,
  CalendarBlank,
  CurrencyDollar,
  SquareHalf,
  Target,
} from 'phosphor-react';
import React, { useRef } from 'react';
import Avatar from 'react-avatar';
import ReactTooltip from 'react-tooltip';
import { ProjectRole } from '../../api/index';
import ConfidenceBadge from '../../common/ConfidenceBadge';
import { avatarBgColor } from '../../common/constants';
import translate, {
  enumTranslates,
  strings,
} from '../../common/i18n/translate';
import StyledTable, {
  StyledTableRow,
  TableCell,
  TableSchema as StyledTableSchema,
} from '../../common/layout/StyledTable';
import Tag from '../../common/Tag';
import { getLocalDate } from '../../common/utils';
import { getTag } from '../portfolio/DefaultTags';
import { DummyProject, DummyProjectMeasure } from './dummyData';

export const buttonClass = `h-min disabled:opacity-50 ml-auto inline-flex p-1 rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`;

export function handleProjectSlideOverClick(
  sandboxProjectId: string,
  e: HTMLElement,
  setShowSlideOver: React.Dispatch<React.SetStateAction<boolean>>,
  setSlideOverProject: React.Dispatch<React.SetStateAction<string>>
) {
  setSlideOverProject(sandboxProjectId);
  setShowSlideOver(true);
  // () => e.blur();
  return;
}

export function ShowProjectSlideOverButton(
  sandboxProjectId: string,
  setShowSlideOver: React.Dispatch<React.SetStateAction<boolean>>,
  setSlideOverProject: React.Dispatch<React.SetStateAction<string>>
): React.ReactElement {
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const handleOnMouseUp = () => {
    btnRef.current?.blur();
  };

  const button = (
    <React.Fragment>
      <button
        type="button"
        ref={btnRef}
        // disabled={isDemo}
        className={buttonClass}
        onMouseUp={handleOnMouseUp}
        onClick={e =>
          handleProjectSlideOverClick(
            sandboxProjectId,
            e.currentTarget,
            setShowSlideOver,
            setSlideOverProject
          )
        }
        data-tip
        data-for={`${sandboxProjectId}-goToProjectPage`}
      >
        <SquareHalf weight="duotone" className="h-5 w-5" aria-hidden="true" />
      </button>
      <ReactTooltip
        id={`${sandboxProjectId}-goToProjectPage`}
        place="top"
        // type="info"
        effect="solid"
        // delayShow={tooltipDelay}
      >
        <div className="text-sm">
          <p>{`Show project information`}</p>
        </div>
      </ReactTooltip>
    </React.Fragment>
  );

  return button;
}

export function SlideOverProjectMeta(
  project: DummyProject | undefined
): React.ReactElement {
  let layout = <React.Fragment />;

  if (project) {
    layout = (
      <div className="flex flex-col mt-1">
        <div className="text-sm text-gray-900">{project?.outcome}</div>

        {project?.tags && project.tags.length > 0 ? (
          <div className="text-sm text-gray-900 mt-3 flex gap-2 flex-wrap">
            {project.tags.map((tagId, idx) => {
              const tag = getTag(tagId);
              const layout = (
                <Tag
                  key={idx}
                  type="custom"
                  label={`${tag.tag?.name}`}
                  bgColour="bg-gray-200"
                  textColour="text-black"
                  groupLabel={`${tag.parent?.name}`}
                  groupBgColour={`bg-${tag.parent?.color}`}
                  groupTextColour="text-white"
                />
              );

              return layout;
            })}
          </div>
        ) : null}
      </div>
    );
  }

  return layout;
}

export function SlideOverProjectContent(
  project: DummyProject | undefined
): React.ReactElement {
  // Initialise
  let layout = <React.Fragment />;

  // Icons
  const iconTarget = (
    <Target
      className="inline-block mr-1 flex-shrink-0 text-gray-500"
      weight="duotone"
      size={20}
    />
  );
  const iconCalendar = (
    <CalendarBlank className="w-6 h-6 flex-shrink-0 text-gray-500 group-hover:text-primary-500" />
  );
  const iconCurrency = (
    <CurrencyDollar className="w-6 h-6 flex-shrink-0 text-gray-500 group-hover:text-primary-500" />
  );
  const iconArrowRight = (
    <ArrowRight className="h-5 w-5 text-black-600 shrink-0 mx-2 mt-2" />
  );
  const iconUp = (
    <ArrowUp
      className="inline-block ml-1 mr-0.5 flex-shrink-0 text-gray-400"
      weight="fill"
      size={12}
    />
  );
  const iconDown = (
    <ArrowDown
      className="inline-block ml-1 mr-0.5 flex-shrink-0 text-gray-400"
      weight="fill"
      size={12}
    />
  );

  // Layout
  const benefitsSchema: StyledTableSchema = {
    columns: [
      {
        label: translate(strings.PROJECT_MEASURES, 'Project Benefits'),
        key: 'projectMeasure',
        widthClass: 'w-16',
      },
    ],
  };

  function projectBenefitsToFormattedRow(
    measure: DummyProjectMeasure
  ): StyledTableRow {
    const layout: TableCell[] = [];
    layout.push({
      content: (
        <div className="text-sm">
          <div className="font-semibold">{measure.name}</div>
          <div className="text-gray-500 flex align-top mt-0.5">
            {iconTarget}
            {`${measure.targetValue}${
              measure.achieveByDate
                ? `, by ${getLocalDate(measure.achieveByDate)}`
                : ''
            }`}
          </div>
        </div>
      ),
    });

    return {
      id: measure.id,
      cells: layout,
      canExpand: false,
    };
  }

  function getBenefitRows(project: DummyProject): StyledTableRow[] {
    const benefits = project.measures;

    return benefits.map(benefit => projectBenefitsToFormattedRow(benefit));
  }

  function ProjectBenefitsTable(project: DummyProject): React.ReactElement {
    const layout = (
      <StyledTable
        schema={benefitsSchema}
        rowData={getBenefitRows(project)}
        disableHoverHighlight={true}
        noRowsMessage="This project has no benefits"
      />
    );

    return layout;
  }

  if (project) {
    layout = (
      <div className="flex flex-col gap-y-5 mb-12">
        {/* Confidence */}
        <div className="text-sm flex justify-between items-center">
          <div className="font-semibold text-gray-900">
            {translate(
              strings.PROJECT_DELIVERY_CONFIDENCE,
              'Delivery Confidence'
            )}
          </div>
          <div className="">
            <ConfidenceBadge
              rating={project.updates[0].confidence}
              // reviewDate={null}
            />
          </div>
        </div>

        {/* Sponsors */}
        <div>
          <div className="text-sm font-semibold text-gray-900 mb-2">
            {`Who owns this project?`}
          </div>
          <div className="grid grid-cols-2 space-x-3">
            {/* Business owner */}
            <span className="flex items-center">
              <Avatar
                name={
                  project.members.find(
                    member => member.role === ProjectRole.BusinessOwner
                  )?.name
                }
                size="32"
                round="16px"
                color={avatarBgColor}
              />
              <span className="ml-3 ">
                <p className="block truncate text-sm font-medium text-gray-900">
                  {
                    project.members.find(
                      member => member.role === ProjectRole.BusinessOwner
                    )?.name
                  }
                </p>
                <p className="block truncate text-xs text-gray-400">
                  {enumTranslates[ProjectRole.BusinessOwner]}
                </p>
              </span>
            </span>

            {/* Project lead */}
            <span className="flex items-center">
              <Avatar
                name={
                  project.members.find(
                    member => member.role === ProjectRole.BusinessOwner
                  )?.name
                }
                size="32"
                round="16px"
                color={avatarBgColor}
              />
              <span className="ml-3 ">
                <p className="block truncate text-sm font-medium text-gray-900">
                  {
                    project.members.find(
                      member => member.role === ProjectRole.ProjectOwner
                    )?.name
                  }
                </p>
                <p className="block truncate text-xs text-gray-400">
                  {enumTranslates[ProjectRole.ProjectOwner]}
                </p>
              </span>
            </span>
          </div>
        </div>

        {/* Benefits */}
        <div>
          <div className="text-sm font-semibold text-gray-900 mb-2">
            What benefits will this project deliver?
            {/* {translate(
          strings.PROJECT_MEASURES,
          'Project Benefits'
        )} */}
          </div>
          {ProjectBenefitsTable(project)}
        </div>

        {/* Timeframe */}
        <div>
          <div className="text-sm font-semibold text-gray-900 mb-2">
            When will it be completed?
            {/* {translate(
          strings.PROJECT_DELIVERY_DATE,
          'Estimated Completion Date'
        )} */}
          </div>
          <div className="flex">
            {/* Original date */}
            <div className="w-1/2">
              <div className="flex w-full">
                <span
                  className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center"
                  data-tip
                  data-for={'look-back-icon'}
                >
                  {iconCalendar}
                </span>
                <div className="block grow rounded-r-md pl-3 pr-8 py-2 text-base border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                  {`${getLocalDate(project.originalEndDate)}`}
                </div>
              </div>
              <p className="block truncate text-xs text-gray-400 mt-1">
                {`Original Estimate`}
              </p>
            </div>

            {iconArrowRight}

            {/* Latest estimated date */}
            <div className="w-1/2">
              <div className="flex w-full">
                <span
                  className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center"
                  data-tip
                  data-for={'look-back-icon'}
                >
                  {iconCalendar}
                </span>
                <div className="block grow rounded-r-md pl-3 pr-8 py-2 text-base border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                  {`${getLocalDate(project.updates[0].latestTimeEstimate)}`}
                </div>
              </div>
              <p className="block truncate text-xs text-gray-400 mt-1">
                {`Latest Estimate`}
              </p>
            </div>
          </div>
        </div>

        {/* Cost */}
        <div>
          <div className="text-sm font-semibold text-gray-900 mb-2">
            How much will it cost?
            {/* {translate(
          strings.PROJECT_TOTAL_COST,
          'Estimated Total Cost'
        )} */}
          </div>
          <div className="flex">
            {/* Original cost */}
            <div className="w-1/2">
              <div className="flex w-full">
                <span
                  className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center"
                  data-tip
                  data-for={'look-back-icon'}
                >
                  {iconCurrency}
                </span>
                <div className="block grow rounded-r-md pl-3 pr-8 py-2 text-base border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                  {`${project.originalBudget.toLocaleString()}`}
                </div>
              </div>
              <p className="block truncate text-xs text-gray-400 mt-1">
                {`Original Estimate`}
              </p>
            </div>

            {iconArrowRight}

            {/* Latest estimated cost */}
            <div className="w-1/2">
              <div className="flex w-full">
                <span
                  className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center"
                  data-tip
                  data-for={'look-back-icon'}
                >
                  {iconCurrency}
                </span>
                <div className="block grow rounded-r-md pl-3 pr-8 py-2 text-base border border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                  {`${project.updates[0].latestCostEstimate.toLocaleString()}`}
                </div>
              </div>
              <p className="block truncate text-xs text-gray-400 mt-1">
                {`Latest Estimate:`}
                {project.updates[0].latestCostEstimate -
                  project.originalBudget >
                0
                  ? iconUp
                  : project.updates[0].latestCostEstimate -
                      project.originalBudget <
                    0
                  ? iconDown
                  : ''}
                {`$${(
                  project.updates[0].latestCostEstimate - project.originalBudget
                ).toLocaleString()}`}
              </p>
            </div>
          </div>
        </div>

        {/* Remarks */}
        <div>
          <div className="text-sm font-semibold text-gray-900 mb-1">
            What else do we need to consider?
          </div>
          <div className="text-sm">
            <div className="">{`${project.remark}`}</div>
          </div>
        </div>
      </div>
    );
  }

  return layout;
}
