import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  DocumentTextIcon,
  CalendarIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/solid';
import { portfolioReportTypes, programReportTypes } from './ReportConstants';
import {
  Program,
  Project,
  ReportPermission,
  ReportType,
  useCheckReportPermissionLazyQuery,
  useGetOtpReportLazyQuery,
  useGetReportLazyQuery,
} from '../../api/index';
import Modal from '../../common/Modal';
import { UserContext } from '../../context/UserContext';
import { NOT_FOUND } from '../../common/routes';
import Spinner from '../../common/SpinnerThemed';
import ProgramUpdates from './reportPages/ProgramUpdates';
import { Portfolio, ProgramUpdate } from '../../api/index';
import { ReportContext } from './ReportContext';
import ProgramSnapshotReport from './reportPages/ProgramSnapshotReport';

import ReactLoading from 'react-loading';
import {
  HomeIcon,
  ArrowLeftOnRectangleIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import { getLocalDate } from '../../common/utils';
import translate, { strings } from '../../common/i18n/translate';

export interface ProgramUpdateExt extends ProgramUpdate {
  programName: string;
  primaryStrategicThemeId: string;
}

export interface ProgramExt extends Program {
  latestUpdate: ProgramUpdate;
  portfolio: Portfolio;
}

function ReportPage(): React.ReactElement {
  const { reportId } = useParams<{ reportId: string }>();
  const navigate = useNavigate();
  const { user } = React.useContext(UserContext);
  const { state, dispatch } = React.useContext(ReportContext);
  const [checkReportPermission, { data, loading, error }] =
    useCheckReportPermissionLazyQuery();
  const [modalOpen, setModalOpen] = useState(false);
  const [otpValue, setOtpValue] = useState('');
  const [
    getReport,
    { data: reportData, loading: reportLoading, error: reportError },
  ] = useGetReportLazyQuery({
    variables: { id: reportId as string },
  });

  const [
    getReportOtp,
    { data: reportOtpData, loading: reportOtpLoading, error: reportOtpError },
  ] = useGetOtpReportLazyQuery();

  useEffect(() => {
    if (!state.report) {
      checkReportPermission({
        variables: { id: reportId as string },
      });
    }
  }, [state, checkReportPermission, reportId]);

  useEffect(() => {
    if (state?.report?.id && state.report.id !== reportId) {
      dispatch({
        type: 'SET_REPORT',
        report: null,
      });
    }
  }, [state, reportId, dispatch]);

  useEffect(() => {
    if (data && data.checkReportPermission) {
      if (
        data.checkReportPermission.reportPermission === ReportPermission.User
      ) {
        if (user) {
          getReport({ variables: { id: reportId as string } });
        } else {
          navigate(NOT_FOUND);
        }
      } else {
        setModalOpen(true);
      }
    }
  }, [data, getReport, navigate, reportId, user]);

  useEffect(() => {
    if (reportData && reportData.getReport) {
      dispatch({
        type: 'SET_REPORT',
        report: reportData.getReport,
      });
    }
  }, [reportData, dispatch]);

  useEffect(() => {
    if (reportOtpData && reportOtpData.getOtpReport) {
      dispatch({
        type: 'SET_REPORT',
        report: reportOtpData.getOtpReport,
      });
      setModalOpen(false);
    }
  }, [reportOtpData, dispatch]);

  function getReportWithOtp() {
    if (otpValue) {
      getReportOtp({
        variables: { id: reportId as string, otp: otpValue },
      });
    }
  }

  const report = state.report;
  function ReportComponent() {
    if (report) {
      if (report?.type === ReportType.ProgramUpdates) {
        return (
          <ProgramUpdates
            entity={JSON.parse(report.entity) as Portfolio}
            programUpdates={
              JSON.parse(report.reportContent) as ProgramUpdateExt[]
            }
          />
        );
      } else if (report?.type === ReportType.ProgramSnapshot) {
        return (
          <ProgramSnapshotReport
            program={JSON.parse(report.entity) as ProgramExt}
            includedProjects={JSON.parse(report.reportContent) as Project[]}
          />
        );
      } else return null;
    } else return null;
  }

  const reportingPlaceholderContent = (
    <>
      {modalOpen && (
        <Modal
          modalType="Otp"
          width="w-1/3"
          onClose={() => setModalOpen(false)}
        >
          <>
            <div className="flex flex-col space-y-4 bg-white p-4 md:w-full overflow-y-auto align-middle">
              <div className="flex flex-row items-center">
                <span className="flex p-2 rounded-full bg-secondary-900 align-middle items-center">
                  <ShieldCheckIcon className="h-5 w-5 text-white mx-auto my-auto" />
                </span>
                <h3 className="text-lg font-medium ml-3 text-gray-800">
                  Enter Access Code
                </h3>
              </div>
              <p className="mt-3 text-sm text-gray-900">
                You have received a report for "
                {data?.checkReportPermission?.name}"
              </p>
              <p className="mt-3 text-sm text-gray-900">
                To see the report, please enter the 6-digit code you received in
                the email.
              </p>
              <div>
                <input
                  type="text"
                  className={`block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm ${
                    reportOtpError
                      ? 'border-red-600 ring-red-600 ring-1'
                      : 'border-gray-300'
                  } rounded-md`}
                  onChange={e => setOtpValue(e.target.value)}
                  value={otpValue}
                />
                <p className="mt-1 text-sm text-red-600">
                  {reportOtpError
                    ? `${reportOtpError.message} - Please check your code and try again`
                    : ''}
                </p>
              </div>
              <span className="flex flex-row justify-end mt-4">
                <div className="flex">
                  {reportOtpLoading ? (
                    <div className="flex align-middle items-center px-3 py-2">
                      {/* <Spinner text={'Authenticating...'} /> */}
                      <ReactLoading
                        type="bubbles"
                        color="#2EC4B6"
                        height={40}
                        width={40}
                      />
                      <span className="text-sm">Authenticating...</span>
                    </div>
                  ) : (
                    <button
                      className="disabled:opacity-50 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      onClick={() => getReportWithOtp()}
                      disabled={!otpValue || otpValue.length !== 6}
                    >
                      See Report
                    </button>
                  )}
                </div>
              </span>
            </div>{' '}
          </>
        </Modal>
      )}
      <div>
        <ReportComponent />
      </div>
    </>
  );

  let reportType;
  switch (report?.type) {
    case ReportType.ProgramSnapshot:
      reportType = programReportTypes.find(
        r => r.value === ReportType.ProgramSnapshot
      );
      break;
    case ReportType.ProgramUpdates:
      reportType = portfolioReportTypes.find(
        r => r.value === ReportType.ProgramUpdates
      );
      break;
    default:
      reportType = {};
      break;
  }
  const reportingTitle =
    `${reportType?.display} Report for ${report?.name}` || 'No report found';

  return (
    <div className="h-screen flex flex-col space-y-6 overflow-hidden bg-gray-100">
      {/* Branding bar */}
      <div className="flex items-center h-16 flex-shrink-0 px-4 bg-secondary-800">
        <img
          className="h-10 w-auto shadow-lg bg-white p-0.5 rounded-full "
          src="/logos/CV-logo-01-bw.svg"
          alt="JourneyLab"
        />
        <span className="font-normal text-2xl ml-3 text-white shadow-lg">
          {translate(strings.BRAND_NAME, 'JourneyLab')}
        </span>
        <div className="ml-auto mr-0">
          {user ? (
            <Link to="/" className="">
              <button
                type="button"
                className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                // onClick={handleButtonClick}
              >
                <HomeIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Home
              </button>
            </Link>
          ) : (
            <div className="flex space-x-3">
              <Link to="/signin" className="text-white hover:text-primary-600">
                <button
                  type="button"
                  className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-secondary-600 hover:bg-secondary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  // onClick={handleButtonClick}
                >
                  <ArrowLeftOnRectangleIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Sign In
                </button>
              </Link>
              <Link to="/signup" className="text-white hover:text-primary-600">
                <button
                  type="button"
                  className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  // onClick={handleButtonClick}
                >
                  <UserPlusIcon
                    className="-ml-1 mr-2 h-5 w-5"
                    aria-hidden="true"
                  />
                  Create Account
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>

      {loading || reportLoading ? (
        <div className="flex flex-col align-middle items-center">
          <Spinner text={'Loading report...'} />
        </div>
      ) : error || reportError ? (
        <span>
          Error: `$
          {error?.message || reportError?.message}`
        </span>
      ) : (
        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          <div className="max-w-7xl mx-auto px-4 py-6 sm:px-6 md:px-8">
            {report && (
              <div className="flex-1 min-w-0">
                <h2 className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  {reportingTitle}
                </h2>
                <div className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0">
                  <div className="mt-2 mr-6 flex items-center text-sm text-gray-500">
                    <DocumentTextIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {reportType?.description}
                  </div>

                  <div className="mt-2 mr-6 flex items-center text-sm text-gray-500">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Report date: {getLocalDate(report?.createdAt)}
                  </div>
                </div>
              </div>
            )}

            <div className="py-6">{reportingPlaceholderContent}</div>
          </div>
        </main>
      )}
    </div>
  );
}

export default ReportPage;
