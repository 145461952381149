import React from "react";
import { AddIcon, EditIcon, UpdateIcon } from "../../basic/ButtonIcons";
import BasicButton from "../../basic/BasicButton";

export type WatchoutsActionHeaderProps = {
  hasWatchouts: boolean;
  onClick: () => void;
  disabled: boolean;
  isVisible: boolean;
};

const WatchoutsActionHeader = ({
  hasWatchouts: hasOutcomes,
  onClick,
  disabled,
  isVisible,
}: WatchoutsActionHeaderProps) => {
  const label = hasOutcomes ? "Review & update" : "Add watchout";
  const icon = hasOutcomes ? <UpdateIcon /> : <AddIcon />;

  return isVisible ? (
    <div className="flex items-center">
      <span className="flex flex-wrap">
        <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
          <span className="sm:ml-3">
            <BasicButton
              icon={icon}
              label={label}
              onClick={onClick}
              disabled={disabled}
            />
          </span>
        </div>
      </span>
    </div>
  ) : (
    <></>
  );
};

export default WatchoutsActionHeader;
