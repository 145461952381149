import React from 'react';
import Avatar from 'react-avatar';
import { avatarBgColor } from '../../common/constants';
import { UserContext } from '../../context/UserContext';

function Header(): React.ReactElement {
  const { user } = React.useContext(UserContext);

  // Name, including fallback
  let displayName = 'Journey Lab';
  if (user?.attributes?.given_name) {
    displayName =
      user?.attributes.given_name + ' ' + user?.attributes.family_name;
  }

  return (
    <div className="flex items-center">
      <Avatar name={displayName} size="60" round="30px" color={avatarBgColor} />
      <h2 className="pl-4 text-2xl text-left font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        {displayName}
      </h2>
      {/* <div className="mt-1 mb-2 flex flex-col items-center space-y-2"></div> */}
    </div>
  );
}

export default Header;
