import {
  Workspace,
  Portfolio,
  Program,
  Project,
  EntityType,
} from '../../api/index';
import { capitaliseFirstLetter } from '../../common/utils';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../common/routes';
import React from 'react';

type EntityChildListArgument = {
  entityChilds: Workspace[] | Portfolio[] | Program[] | Project[];
  entityChildType: EntityType;
  getChilds: (nextToken: string | null) => Promise<void>;
  nextToken: string | null | undefined;
};

function EntityChildList({
  entityChilds,
  entityChildType,
  getChilds,
  nextToken,
}: EntityChildListArgument): React.ReactElement {
  let route: string;

  switch (entityChildType) {
    case EntityType.Workspace:
      route = ROUTES.TEMP_WORKSPACE;
      break;
    case EntityType.Portfolio:
      route = ROUTES.TEMP_PORTFOLIO;
      break;
    case EntityType.Program:
      route = ROUTES.TEMP_PROGRAM;
      break;
    case EntityType.Project:
      route = ROUTES.TEMP_PROJECT;
      break;
    default:
      break;
  }

  return (
    <>
      {entityChilds.length > 0 ? (
        <>
          <h3 className="my-5 text-lg leading-6 font-medium text-gray-900">
            {`${capitaliseFirstLetter(entityChildType)}s`}
          </h3>

          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Id
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                          Created At
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {entityChilds.map((child, idx) => (
                        <tr key={idx}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {child.id}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              <Link
                                to={`${route}/${child.id}`}
                                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                              >
                                {child.name}
                              </Link>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {child.createdAt}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {nextToken && (
            <button
              type="button"
              onClick={() => getChilds(nextToken)}
              className="m-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              More {`${entityChildType}s`}
            </button>
          )}
        </>
      ) : (
        <h3 className="my-5 text-lg leading-6 font-medium text-gray-900">
          No {`${capitaliseFirstLetter(entityChildType)}`}
        </h3>
      )}
    </>
  );
}

export default EntityChildList;
