import React from 'react';
import { LogicModelGroup, ProjectMeasureStatus } from '../../../api/index';

import { ArrowRight, CalendarBlank, ChatText, Target } from 'phosphor-react';
import {
  classNames,
  getLocalDate,
  getProjectMeasureStatusColour,
} from '../../../common/utils';
import moment from 'moment';

import { LogicData } from './MeasuresLogicModel';
import Tag from '../../../common/Tag';
import ExceptionIcon from '../ExceptionIcon';

type Props = {
  measureData: LogicData;
  index: number;
  validMapping: boolean;
  isLatestUpdate: boolean;
  filterExceptions?: boolean;
};

export default function ProjectMeasureCard({
  measureData,
  index,
  validMapping,
  isLatestUpdate,
  filterExceptions,
}: Props): React.ReactElement {
  const type = measureData.measure.logicModelGroup;

  const targetIcon = (
    <Target
      className="inline-block -mt-0.5 mr-1.5 flex-shrink-0"
      color="#7D8390"
      weight="duotone"
      size={20}
      data-tip
      data-for={'target-icon'}
    />
  );

  const calendarIcon = (
    <CalendarBlank
      className="inline-block -mt-0.5 mr-1.5 flex-shrink-0"
      color="#7D8390"
      weight="duotone"
      size={20}
      data-tip
      data-for={'calendar-icon'}
    />
  );

  const remarksIcon = (
    <ChatText
      className="inline-block -mt-0.5 mr-1.5 flex-shrink-0"
      color="#7D8390"
      weight="duotone"
      size={20}
      data-tip
      data-for={'remarks-icon'}
    />
  );

  const RightArrowIcon = (
    <ArrowRight className="h-5 w-5 text-black-600 shrink-0" />
  );

  return (
    <div
      className={`flex rounded-md px-3 py-3 border ${
        index == 0 ? '' : 'mt-2'
      } ${
        validMapping
          ? 'bg-gray-100 border-gray-200'
          : 'bg-yellow-50 border-yellow-200'
      }`}
    >
      <div className="w-full">
        {/* Title */}
        <div className="flex w-full justify-between mb-2">
          {/* Name */}
          <div className="flex flex-grow text-sm font-medium mb-1">
            {measureData.measure.name}
          </div>

          {/* Status */}
          {filterExceptions ? (
            <ExceptionIcon
              exceptionStatus={measureData.latestMeasureUpdate?.exceptionStatus}
            />
          ) : (
            <div
              className={`flex w-12 ml-1 mr-2 ${
                !measureData.previousMeasureUpdate?.status &&
                !measureData.latestMeasureUpdate?.status
                  ? '-mt-1 '
                  : '-mt-0.5 '
              }`}
            >
              <div className="flex items-center mt-0.5 mb-auto">
                {/* Previous status */}
                {measureData.latestMeasureUpdate?.status ? (
                  <React.Fragment>
                    <span
                      className={classNames(
                        getProjectMeasureStatusColour(
                          measureData.previousMeasureUpdate?.status
                        ),
                        'flex-shrink-0 inline-block h-3 w-3 rounded-full'
                      )}
                      aria-hidden="true"
                    />
                    <span className="mx-1">{RightArrowIcon}</span>
                  </React.Fragment>
                ) : null}

                {/* Current status */}
                <span
                  className={classNames(
                    getProjectMeasureStatusColour(
                      measureData.latestMeasureUpdate?.status
                    ),
                    'flex-shrink-0 inline-block h-3 w-3 rounded-full'
                  )}
                  aria-hidden="true"
                />

                {/* New tag */}
                {!measureData.previousMeasureUpdate?.status &&
                !measureData.latestMeasureUpdate?.status ? (
                  <span className="inline-block ml-1.5">
                    <Tag type="new" />
                  </span>
                ) : null}
              </div>
            </div>
          )}
        </div>

        {type === LogicModelGroup.Outputs ? (
          /* Target - date only for deliverables */
          <div className="flex text-sm font-normal text-gray-500 align-top">
            <span>{calendarIcon}</span>
            <p>
              <span className="mr-2">
                {`${
                  measureData.measure.achieveByDate
                    ? `By ${getLocalDate(measureData.measure.achieveByDate)}`
                    : 'Not set'
                }`}
              </span>

              {/* Due soon tag */}
              {
                // Show tag if the measure is still in progress...
                (measureData.latestMeasureUpdate?.status ===
                  ProjectMeasureStatus.OnTrack ||
                  measureData.latestMeasureUpdate?.status ===
                    ProjectMeasureStatus.Risk ||
                  measureData.latestMeasureUpdate?.status ===
                    ProjectMeasureStatus.HelpNeeded) &&
                // ... and if it's within 7 days of being due...
                measureData.measure.achieveByDate &&
                moment(new Date(measureData.measure.achieveByDate)).diff(
                  moment(new Date()),
                  'days'
                ) < 7 &&
                // ...but not if it's actually overdue
                new Date(measureData.measure.achieveByDate).getTime() >=
                  new Date().getTime() &&
                // ... and it's the latest update
                isLatestUpdate ? (
                  <span className="inline-block -mt-0.5">
                    <Tag
                      type="custom"
                      label={`Due in ${moment(
                        new Date(measureData.measure.achieveByDate)
                      ).diff(moment(new Date()), 'days')} days`}
                      bgColour="bg-yellow-200"
                      textColour="text-yellow-600"
                    />
                  </span>
                ) : null
              }
              {/* Overdue tag */}
              {
                // Show tag if the measure is still in progress...
                (measureData.latestMeasureUpdate?.status ===
                  ProjectMeasureStatus.OnTrack ||
                  measureData.latestMeasureUpdate?.status ===
                    ProjectMeasureStatus.Risk ||
                  measureData.latestMeasureUpdate?.status ===
                    ProjectMeasureStatus.HelpNeeded) &&
                measureData.measure.achieveByDate &&
                // ...and it's overdue
                new Date(measureData.measure.achieveByDate).getTime() <
                  new Date().getTime() &&
                // ... and it's the latest update
                isLatestUpdate ? (
                  <span className="inline-block -mt-0.5">
                    <Tag type="overdue" />
                  </span>
                ) : null
              }
            </p>
          </div>
        ) : (
          <div>
            {/* Target - Impact version */}
            <div className="flex text-sm font-normal text-gray-500 align-top">
              <span>{targetIcon}</span>
              <p>
                {`${
                  measureData.measure.targetValue
                    ? measureData.measure.targetValue
                    : `N/A`
                }${
                  measureData.measure.achieveByDate
                    ? `, by ${getLocalDate(measureData.measure.achieveByDate)}`
                    : ''
                }`}

                {/* Due soon tag */}
                {
                  // Show tag if the measure is still in progress...
                  (measureData.latestMeasureUpdate?.status ===
                    ProjectMeasureStatus.OnTrack ||
                    measureData.latestMeasureUpdate?.status ===
                      ProjectMeasureStatus.Risk ||
                    measureData.latestMeasureUpdate?.status ===
                      ProjectMeasureStatus.HelpNeeded) &&
                  // ... and if it's within 7 days of being due...
                  measureData.measure.achieveByDate &&
                  moment(new Date(measureData.measure.achieveByDate)).diff(
                    moment(new Date()),
                    'days'
                  ) < 7 &&
                  // ...but not if it's actually overdue
                  new Date(measureData.measure.achieveByDate).getTime() >=
                    new Date().getTime() &&
                  // ... and it's the latest update
                  isLatestUpdate ? (
                    <span className="inline-block ml-2">
                      <Tag
                        type="custom"
                        label={`Due in ${moment(
                          new Date(measureData.measure.achieveByDate)
                        ).diff(moment(new Date()), 'days')} days`}
                        bgColour="bg-yellow-200"
                        textColour="text-yellow-600"
                      />
                    </span>
                  ) : null
                }
                {/* Overdue tag */}
                {
                  // Show tag if the measure is still in progress...
                  (measureData.latestMeasureUpdate?.status ===
                    ProjectMeasureStatus.OnTrack ||
                    measureData.latestMeasureUpdate?.status ===
                      ProjectMeasureStatus.Risk ||
                    measureData.latestMeasureUpdate?.status ===
                      ProjectMeasureStatus.HelpNeeded) &&
                  measureData.measure.achieveByDate &&
                  // ...and it's overdue
                  new Date(measureData.measure.achieveByDate).getTime() <
                    new Date().getTime() &&
                  // ... and it's the latest update
                  isLatestUpdate ? (
                    <span className="inline-block ml-2">
                      <Tag type="overdue" />
                    </span>
                  ) : null
                }
              </p>
            </div>
          </div>
        )}

        {/* Remarks */}
        {measureData.latestMeasureUpdate?.remarks &&
        measureData.latestMeasureUpdate?.remarks.length > 0 ? (
          <div className="flex text-sm font-normal text-gray-500 align-top">
            <span className="">{remarksIcon}</span>
            <p className="whitespace-pre-wrap">
              {measureData.latestMeasureUpdate.remarks}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
