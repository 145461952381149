import React, { useContext } from 'react';
import { ProjectPageContext } from '../../project/ProjectPageContext';
import { EntityType, Group, Program, Project } from '../../../api/index';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import ProjectMembershipTable from '../../project/AdminTab/ProjectMembershipTable';
import ProgramMembershipTable from '../../program/AdminTab/ProgramMembershipTable';
import { ProgramPageContext } from '../../program/ProgramPageContext';
import { PortfolioPageContext } from '../../portfolio/PortfolioPageContext';

type Props = {
  entity: Project | Program;
};

function EntityTeaming({ entity }: Props): React.ReactElement {
  // Note: For now we are duplicating the data getting calls because of the old hierarchy
  // In the Entity model, this will just be simplified.

  // Get entity context
  const { state: portfolioPageState } = useContext(PortfolioPageContext);
  const { state: programPageState } = useContext(ProgramPageContext);
  const { state: projectPageState } = useContext(ProjectPageContext);

  // Determine the entity type
  const isProject = entity.__typename === `Project`;
  const thisEntity = isProject
    ? projectPageState.selectedProject
    : programPageState.selectedProgram;
  const parentEntity = isProject
    ? programPageState.selectedProgram
    : portfolioPageState.selectedPortfolio;

  // Get Current User permissions
  const userProjectPermission = thisEntity?.group;

  const hasEditPermissions =
    userProjectPermission == Group.Owner ||
    userProjectPermission == Group.Editor;
  const hasOwnerPermissions = userProjectPermission == Group.Owner;

  const invitations = { invitations: projectPageState.invitations };
  const editObject = { ...thisEntity, ...invitations };

  const membersTitleBeta = (
    <DetailsPanelHeader
      title={translate(strings.ROLES_PERMISSIONS, 'Roles & Permissions')}
      description={`Who has access to this ${
        enumTranslates[EntityType.Project]
      }`}
    />
  );

  const membersContentBeta =
    thisEntity?.members?.items && thisEntity?.members?.items.length === 0 ? (
      <EmptyState type="members" />
    ) : isProject ? (
      <ProjectMembershipTable />
    ) : (
      <ProgramMembershipTable />
    );

  return (
    <React.Fragment>
      {/* Member */}
      <DetailsPanel
        headerLeft={membersTitleBeta}
        headerRight={null}
        content={membersContentBeta}
      />
    </React.Fragment>
  );
}

export default EntityTeaming;
