import React, { useEffect, useState } from 'react';
import { IInvitation } from '../../api/index';

import { useGetMyInvitationsLazyQuery } from '../../api/index';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import EmptyState from '../../common/layout/EmptyState';
import Spinner from '../../common/SpinnerThemed';
import InvitationItem from './InvitationItem';

function InvitationList(): React.ReactElement {
  const pageSize = 10;
  const [invitations, setInvitations] = useState<IInvitation[]>([]);

  const [GetMyInvitationsQuery, { data, loading, error }] =
    useGetMyInvitationsLazyQuery({
      fetchPolicy: 'network-only',
      variables: {
        limit: pageSize,
      },
    });

  useEffect(() => {
    GetMyInvitationsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data?.getMyInvitations?.items) {
      const newInvitations = data?.getMyInvitations?.items as IInvitation[];
      setInvitations(oldInvitations => [...oldInvitations, ...newInvitations]);
    }
  }, [data]);

  const inviteTitle = (
    <DetailsPanelHeader
      title={'Invitations'}
      description={"Programs and projects you've been added to"}
    />
  );

  const invitesList = (
    <div className="-mx-6 -my-6">
      <div className="relative divide-y divide-gray-200">
        {invitations && invitations?.length > 0 ? (
          invitations.map(invitation => (
            <div className="">
              <InvitationItem
                invitation={invitation}
                key={`${invitation.entityId}#${invitation.inviteeEmail}`}
                refresh={() => {
                  GetMyInvitationsQuery();
                }}
              />
            </div>
          ))
        ) : (
          <div className="text-center">
            <EmptyState
              type="invites"
              secondaryText="You haven't been added to any program or projects yet."
            />
          </div>
        )}
      </div>

      {data?.getMyInvitations?.nextToken && (
        <div className="mt-6 px-5 py-1 bg-gray-50 sm:px-8 sm:py-2">
          <button
            onClick={() =>
              GetMyInvitationsQuery({
                variables: {
                  limit: pageSize,
                  nextToken: data?.getMyInvitations?.nextToken,
                },
              })
            }
            className="font-medium text-sm text-primary-600 hover:text-primary-500 hover:bg-gray-200 transition ease-in-out duration-150 px-3 py-1 rounded"
          >
            Show more invites <span aria-hidden="true">&rarr;</span>
          </button>
        </div>
      )}
    </div>
  );

  const invitesContent = loading ? (
    <div className="flex flex-col align-middle items-center">
      <Spinner text={'Loading data...'} />
    </div>
  ) : error ? (
    <span>Error: `${error.message}`</span>
  ) : (
    invitesList
  );

  return (
    <div className="flex flex-col space-y-6 max-w-3xl mx-auto">
      <DetailsPanel
        headerLeft={inviteTitle}
        headerRight={null}
        content={invitesContent}
      />
    </div>
  );
}

export default InvitationList;
