/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { useCallback, useContext, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import useValidation from '../validation/useValidation';
import { Validation } from '../validation/Validation.types';
import { WizardFormInputProps } from '../Wizard';
import { WizardContext } from '../WizardContext';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarBlank } from 'phosphor-react';

type Props = {
  label?: string | React.ReactElement | null;
  onChange?: (newValue: string | any) => void;
  manualChange?: (newValue: string | any, key: string) => void;
  placeholder?: string;
  validation?: Validation;
  minDate?: string | null;
  isWizard?: boolean | null;
  tableClassName?: string | undefined;
  disabled?: boolean;
  isClearable?: boolean;
} & WizardFormInputProps;

export default function WizardDateInput({
  id,
  label,
  onChange,
  manualChange,
  requestKey,
  validation,
  minDate,
  isWizard,
  tableClassName,
  disabled,
  isClearable,
}: Props): React.ReactElement {
  const { dispatch, state } = useContext(WizardContext);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { validate } = useValidation(validation);

  const handleChange = useCallback(
    (date: string | number | Date | null) => {
      const isoDate =
        date !== null
          ? moment(new Date(date).setHours(9, 0, 0, 0)).toISOString()
          : date;
      setIsDirty(true);
      if (isWizard) {
        dispatch({
          type: 'WRITE_TO_REQUEST_PAYLOAD',
          key: requestKey,
          value: isoDate,
        });
      } else if (manualChange != null) {
        manualChange(isoDate, requestKey);
      }
      if (onChange != null) {
        const value = { target: { value: isoDate } };
        onChange(value);
      }
    },
    [dispatch, onChange, manualChange, requestKey, isWizard]
  );

  let value = null;
  if (isWizard) {
    const currentDate = state.requestPayload[requestKey] as string;
    if (currentDate && currentDate !== '') {
      value = new Date(currentDate);
    }
  } else if (!isWizard) {
    if (requestKey && requestKey !== '') {
      value = new Date(requestKey);
    }
  }

  const { isValid, messages } = validate(String(value));

  const shouldShowError = !isValid && isDirty;

  let inputClassName = shouldShowError
    ? 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-r-md'
    : 'block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-r-md';

  if (disabled) {
    inputClassName =
      'block w-full shadow-sm text-gray-400 bg-gray-100 shadow-none sm:text-sm border-gray-300 rounded-r-md';
  }
  return (
    <React.Fragment>
      {isWizard ? (
        <label
          htmlFor={id || requestKey}
          className="block text-sm font-medium text-gray-700 sm:mt-2 mb-1"
        >
          {label}
        </label>
      ) : (
        ''
      )}
      <div
        className={isWizard ? 'flex mt-1 sm:mt-0 sm:col-span-2 relative' : ''}
      >
        {/* Show the calendar decoration if not being used in a wizard table */}
        {!tableClassName ? (
          <span className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center">
            <CalendarBlank className="h-5 w-5" weight="bold" />
          </span>
        ) : null}
        <DatePicker
          className={isWizard ? inputClassName : tableClassName}
          dateFormat="dd/MM/yyyy"
          isClearable={isClearable}
          placeholderText="Select a date"
          minDate={
            minDate
              ? moment(minDate).toDate()
              : moment().subtract(1, 'years').toDate()
          }
          selected={value}
          onChange={(date: Date) => handleChange(date)}
          disabled={disabled}
        />

        {shouldShowError ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {shouldShowError ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {messages[0]}
        </p>
      ) : null}
    </React.Fragment>
  );
}
