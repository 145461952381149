import { EntityType } from '../../api/index';
import { enumTranslates } from '../../common/i18n/translate';
import { capitaliseFirstLetter } from '../../common/utils';

export type TagGroup = {
  id: string;
  name: string;
  color: string;
  tags: ProjectTag[];
};

export type ProjectTag = {
  id: string;
  name: string;
};

export function getTag(tagId: string): {
  parent: TagGroup | undefined;
  tag: ProjectTag | undefined;
} {
  const group = DefaultTags.find(group => {
    const tags = group.tags;
    return tags.some(item => item.id === tagId);
  });
  const tag = group?.tags.find(item => item.id === tagId);

  if (tag) {
    return { parent: group, tag: tag };
  } else {
    return { parent: undefined, tag: undefined };
  }
}

// Note: To prevent tree-shaking due to concatenation, colors here need to be kept in sync with TagsPanel.tsx
export const DefaultTags: Array<TagGroup> = [
  {
    id: 'group-A',
    name: `${capitaliseFirstLetter(enumTranslates[EntityType.Project])} Type`,
    color: `sky-500`,
    tags: [
      { id: 'tag-A1', name: 'Routine maintenance' },
      { id: 'tag-A2', name: 'Regulatory' },
      { id: 'tag-A3', name: 'Business change' },
      { id: 'tag-A4', name: 'Innovation' },
    ],
  },
  {
    id: 'group-B',
    name: 'Function',
    color: `pink-500`,
    tags: [
      { id: 'tag-B1', name: 'Strategy' },
      { id: 'tag-B2', name: 'R&D' },
      { id: 'tag-B3', name: 'Operations' },
      { id: 'tag-B4', name: 'Sales & Marketing' },
      { id: 'tag-B5', name: 'People & Culture' },
      { id: 'tag-B6', name: 'Finance' },
      { id: 'tag-B7', name: 'Information Technology' },
      { id: 'tag-B8', name: 'Risk & Compliance' },
    ],
  },
  {
    id: 'group-C',
    name: 'Business Unit',
    color: `orange-500`,
    tags: [
      { id: 'tag-C1', name: 'Retail' },
      { id: 'tag-C2', name: 'Enterprise' },
      { id: 'tag-C3', name: 'New Markets' },
    ],
  },
  {
    id: 'group-D',
    name: 'Complexity',
    color: `lime-500`,
    tags: [
      { id: 'tag-D1', name: 'High complexity' },
      { id: 'tag-D2', name: 'Medium complexity' },
      { id: 'tag-D3', name: 'Low complexity' },
    ],
  },
];
