import { useState } from 'react';
import {
  Workspace,
  Portfolio,
  Program,
  Project,
  EntityType,
} from '../../api/index';
import { capitaliseFirstLetter } from '../../common/utils';
import React from 'react';

type EntityDetailsArgument = {
  entity: Workspace | Portfolio | Program | Project | null;
  entityType: EntityType;
  needCreate?: boolean;
  create?: (name: string) => void;
};

function EntityDetails({
  entity,
  entityType,
  needCreate = true,
  create,
}: EntityDetailsArgument): React.ReactElement {
  const [name, setName] = useState<string>('');

  let childType = '';

  switch (entityType) {
    case EntityType.Workspace:
      childType = EntityType.Portfolio;
      break;
    case EntityType.Portfolio:
      childType = EntityType.Program;
      break;
    case EntityType.Program:
      childType = EntityType.Project;
      break;
    default:
      break;
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          {capitaliseFirstLetter(entityType)}
        </h3>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Id</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {entity && entity.id}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {entity && entity.name}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Created At</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {entity && entity.createdAt}
            </dd>
          </div>
          {needCreate && (
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Add New {capitaliseFirstLetter(childType)}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <input
                  type="text"
                  placeholder={`${capitaliseFirstLetter(childType)} Name`}
                  onChange={e => setName(e.target.value)}
                  value={name}
                  className="shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                />
                <button
                  onClick={() => {
                    if (create) {
                      create(name);
                    }

                    setName('');
                  }}
                  className="m-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Create
                </button>
              </dd>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
}

export default EntityDetails;
