import {
  ValidateResult,
  ValidationRule,
  ValidationRuleStrategy,
} from '../Validation.types';

const IsNumericValidationRule: ValidationRuleStrategy = {
  validate(rule: ValidationRule, value: string): ValidateResult {
    const regex = new RegExp('^[0-9]\\d*(\\.\\d+)?$');
    return {
      isValid: regex.test(value),
      messages: ['Not a valid number'],
    };
  },
};

export default IsNumericValidationRule;
