import * as React from 'react';

type Props = {
  title?: string;
  children: Array<React.ReactElement> | React.ReactElement;
};

export default function ProgramDetailsContentCard({
  title,
  children,
}: Props): React.ReactElement {
  return (
    <div className="bg-white overflow-hidden border border-gray-200 rounded-lg h-full">
      {title ? (
        <div className="bg-gray-50 border-b border-gray-200 text-xs font-medium text-gray-500 uppercase tracking-wider text-center py-2">
          {title}
        </div>
      ) : null}
      <div className="p-4 h-full">{children}</div>
    </div>
  );
}
