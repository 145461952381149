import React, { useState } from 'react';
import { CaretDoubleDown } from 'phosphor-react';
import ConfidenceBadge from '../../basic/ConfidenceBadge';
import { SlideOverSectionHeader } from '../../common/slideovers/SlideOverSectionHeader';
import { RelatedEntity } from './RelationshipSlideOver';

type RelatedEntityCardProps = {
  relatedEntity: RelatedEntity;
  index: number;
  isEnabler: boolean;
};

function RelatedEntityCard({
  relatedEntity,
  index,
  isEnabler,
}: RelatedEntityCardProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  const dummyRelationshipDescriptions: string[] = [
    `This is a critical enabler for our project. We can't start testing until this is in place.`,
    `There is a loose dependency here for the packaging design.`,
    `This can't start until we finish.`,
  ];

  function getRelationshipDescription(isEnabler: boolean, index: number) {
    if (isEnabler && index === 0) {
      return dummyRelationshipDescriptions[0];
    } else if (!isEnabler && index === 0) {
      return dummyRelationshipDescriptions[1];
    } else {
      return dummyRelationshipDescriptions[2];
    }
  }

  function handleCardClick() {
    setIsExpanded(!isExpanded);
  }

  return (
    <button
      type="button"
      className="bg-gray-100 rounded-lg border border-gray-200 w-full text-left text-sm hover:bg-gray-200"
      onClick={handleCardClick}
    >
      {/* Header */}
      <div className="px-4 py-2 border-b border-gray-200 flex justify-between items-center">
        <div className="mr-2 font-medium">{relatedEntity.name}</div>
        <div>
          <ConfidenceBadge
            confidenceLabel="confidence rating"
            rating={relatedEntity.confidenceRating}
            size="shortened"
          />
        </div>
      </div>

      {/* Content */}
      <div
        className={`${
          isExpanded ? '' : 'hidden'
        } bg-white rounded-b-lg px-4 py-3`}
      >
        <div className="font-semibold text-sm mb-1">How we're related</div>
        <p>{getRelationshipDescription(isEnabler, index)}</p>
      </div>
    </button>
  );
}

const iconRelationship = (
  <CaretDoubleDown
    size={20}
    weight="bold"
    className="-my-2 shrink-0 text-gray-600"
  />
);

function RelationshipArrow(): React.ReactElement {
  return <div className="flex justify-center">{iconRelationship}</div>;
}

type RelationshipsSlideOverRelationshipsTabProps = {
  enablers: RelatedEntity[];
  thisEntityName: string;
  dependents: RelatedEntity[];
};

export default function RelationshipsSlideOverRelationshipsTab({
  enablers,
  thisEntityName,
  dependents,
}: RelationshipsSlideOverRelationshipsTabProps): React.ReactElement {
  return (
    <div className="space-y-6 text-sm text-base">
      {/* Show enablers */}
      <div>
        <SlideOverSectionHeader label={`Enablers`} />

        {enablers?.length > 0 ? (
          <div className="space-y-3">
            {enablers.map((entity, index) => (
              <RelatedEntityCard
                key={entity.id}
                relatedEntity={entity}
                index={index}
                isEnabler={true}
              />
            ))}
          </div>
        ) : (
          <div className="text-gray-400 italic">No enablers</div>
        )}
      </div>

      {/* Relationship arrow */}
      <RelationshipArrow />

      {/* Show this entity */}
      <div className="flex flex-col items-center rounded-lg border border-primary-600 bg-primary-50 px-3 py-3">
        <div className="text-xs text-gray-500 mb-1">{`This entity`}</div>
        <div className="font-medium">{thisEntityName}</div>
      </div>

      {/* Relationship arrow */}
      <RelationshipArrow />

      {/* Show dependents */}
      <div>
        <SlideOverSectionHeader label={`Dependents`} />

        {dependents?.length > 0 ? (
          <div className="space-y-3">
            {dependents.map((entity, index) => (
              <RelatedEntityCard
                key={entity.id}
                relatedEntity={entity}
                index={index}
                isEnabler={false}
              />
            ))}
          </div>
        ) : (
          <div className="text-gray-400 italic">No dependents</div>
        )}
      </div>
    </div>
  );
}
