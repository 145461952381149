import React from 'react';
import { AssignIcon, DeadlineIcon, DeleteIcon } from '../../basic/ButtonIcons';
import BasicTable, { DataRow } from '../../common/tables/BasicTable';
import BasicTableCell from '../../common/tables/BasicTableCell';
import DotMenuTableCell from '../../common/tables/DotMenuTableCell';
import ProgressTableCell from '../../common/tables/ProgressTableCell';
import StyledTableCell from '../../common/tables/StyledTableCell';

export type WaypointChecklistItem = {
  id: string;
  description: string;
  progress: 'none' | 'partial' | 'complete' | 'na';
  assigneeName?: string;
  dueDate?: string;
};

type WaypointChecklistContentProps = {
  checklist: WaypointChecklistItem[];
};

// Menu options the user can take on each waypoint checklist item
const menuOptions = (itemId: string) => [
  {
    id: 'ASSIGN01',
    action: () => console.log(`Assign item ${itemId} clicked`),
    text: 'Assign to...',
    icon: <AssignIcon />,
  },
  {
    id: 'DUEDATE01',
    action: () => console.log(`Set due date for ${itemId}`),
    text: 'Set deadline...',
    icon: <DeadlineIcon />,
  },
  {
    id: 'DELETE01',
    action: () => console.log(`Delete item ${itemId}`),
    text: 'Delete item...',
    icon: <DeleteIcon />,
  },
];

export default function OutcomesContent({
  checklist,
}: WaypointChecklistContentProps): React.ReactElement {
  const tableHeader: DataRow = {
    data: [
      {
        key: 'TH1',
        cellContents: <BasicTableCell contents="Progress" />,
        widthClass: 'w-40',
        // tooltip: <div className="inline-flex">'No comment'</div>,
      },
      { key: 'TH2', cellContents: <BasicTableCell contents="Description" /> },
      {
        key: 'TH3',
        cellContents: <BasicTableCell contents="" />, // Actions menu
        widthClass: 'w-12',
        // tooltip: <div>When we expect to achieve the target</div>,
      },
    ],
  };

  const tableData: DataRow[] = [];
  checklist &&
    checklist.forEach(checklistItem =>
      tableData.push({
        data: [
          {
            key: `${checklistItem.id}-1`,
            cellContents: (
              <ProgressTableCell
                itemId={checklistItem.id}
                rating={checklistItem.progress}
              />
            ),
          },
          {
            key: `${checklistItem.id}-2`,
            cellContents: (
              <StyledTableCell
                styleClass="py-1.5"
                contents={checklistItem.description || '-'}
              />
            ),
          },
          {
            key: `${checklistItem.description}-3`,
            cellContents: (
              <DotMenuTableCell menuOptions={menuOptions(checklistItem.id)} />
            ),
          },
        ],
      })
    );

  return (
    <div className="min-w-full space-y-4 lg:space-y-3">
      {checklist ? (
        <BasicTable tableData={tableData} tableHeader={tableHeader} />
      ) : (
        // Empty state
        <div className="text-sm text-gray-400 italic text-center">
          No items added yet
        </div>
      )}
    </div>
  );
}
