import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getLocalDate } from '../../common/utils';
import {
  getEntityMetaData,
  getMutualActionPlanByMapId,
  getRelationshipsByEntityId,
  getRelationshipSlideoverData,
  getStrategicContributionsData,
} from '../../common/wizard/payloads/MockQueryFunctions';
import { UserContext } from '../../context/UserContext';
import {
  ComponentInfo,
  GenericContainer,
} from '../../JLcomponents/basic/GenericContainer';
import { Task } from './GanttPanel/gantt';
import GanttPanel from './GanttPanel/GanttPanel';
import RelationshipSlideOver from './RelationshipSlideover/RelationshipSlideOver';
import RelationshipsPanel, {
  RelationshipType,
} from './RelationshipsPanel/RelationshipsPanel';

const exampleRelationships = [
  {
    id: 'RELATIONSHIP_001',
    relatedEntityName: 'A related project',
    relationshipWithThisEntity: RelationshipType.related,
    touchpointCount: 6,
    nextTouchpoint: 'in X days / weeks',
  },
  {
    id: 'RELATIONSHIP_002',
    relatedEntityName: 'A project that enables us',
    relationshipWithThisEntity: RelationshipType.enables,
    touchpointCount: 7,
    nextTouchpoint: 'in X days / weeks',
  },
  {
    id: 'RELATIONSHIP_003',
    relatedEntityName: 'A project that is dependent on us',
    relationshipWithThisEntity: RelationshipType.dependsOn,
    touchpointCount: 8,
    nextTouchpoint: 'in X days / weeks',
  },
];

const exampleProjects = [
  {
    id: 'PROJ_1',
    strategicObjective: 'Decrease churn',
    name: 'Increase Customer Rewards',
    startDate: new Date('2022-01-15'),
    endDate: new Date('2022-03-31'),
  },
  {
    id: 'PROJ_2',
    strategicObjective: 'Decrease churn',
    name: 'Vendor Negotiations',
    startDate: new Date('2022-02-01'),
    endDate: new Date('2023-05-16'),
  },
  {
    id: 'PROJ_3',
    strategicObjective: 'Improve customer satisfaction',
    name: 'Customer Feedback Program',
    startDate: new Date('2022-02-15'),
    endDate: new Date('2022-05-31'),
  },
  {
    id: 'PROJ_4',
    strategicObjective: 'Improve customer satisfaction',
    name: 'Product Expansion',
    startDate: new Date('2022-03-01'),
    endDate: new Date('2022-08-31'),
  },
  {
    id: 'PROJ_5',
    strategicObjective: 'Improve customer satisfaction',
    name: 'Process Automation',
    startDate: new Date('2022-04-01'),
    endDate: new Date('2022-12-31'),
  },
  {
    id: 'PROJ_6',
    strategicObjective: 'Expand into new markets',
    name: 'Market Research',
    startDate: new Date('2022-05-01'),
    endDate: new Date('2022-06-30'),
  },
  {
    id: 'PROJ_7',
    strategicObjective: 'Develop new products',
    name: 'Product Innovation',
    startDate: new Date('2022-06-01'),
    endDate: new Date('2022-11-30'),
  },
  {
    id: 'PROJ_8',
    strategicObjective: 'Increase employee engagement',
    name: 'Employee Recognition Program',
    startDate: new Date('2022-02-01'),
    endDate: new Date('2022-12-31'),
  },
  {
    id: 'PROJ_9',
    strategicObjective: 'Improve data analysis',
    name: 'Data Analytics Platform',
    startDate: new Date('2022-02-15'),
    endDate: new Date('2022-09-30'),
  },
  {
    id: 'PROJ_10',
    strategicObjective: 'Reduce environmental impact',
    name: 'Sustainability Program',
    startDate: new Date('2022-04-01'),
    endDate: new Date('2022-12-31'),
  },
  {
    id: 'PROJ_11',
    strategicObjective: 'Improve social responsibility',
    name: 'Community Engagement',
    startDate: new Date('2022-06-01'),
    endDate: new Date('2022-10-31'),
  },
  {
    id: 'PROJ_12',
    strategicObjective: 'Increase brand awareness',
    name: 'Marketing Campaign',
    startDate: new Date('2022-03-01'),
    endDate: new Date('2022-06-30'),
  },
  {
    id: 'PROJ_13',
    strategicObjective: 'Improve product quality',
    name: 'Quality Assurance Program',
    startDate: new Date('2022-02-01'),
    endDate: new Date('2022-12-31'),
  },
];

function SwimlanesContainer(): React.ReactElement {
  const nullSlideoverData = {
    open: false,
    setOpen: () => closePerfSlideover(),
    title: '',
    titleUrl: '',
    description: '',
    startDate: '',
    endDate: '',
    enablers: [],
    dependents: [],
  };
  const { user: currentUser, profile: userProfile } = useContext(UserContext);
  const { entityId } = useParams<{ entityId: string }>();
  const [slideoverData, setSlideoverData] = React.useState(nullSlideoverData);

  const mockRelationships = getRelationshipsByEntityId(entityId);
  const rels = mockRelationships.map(r => {
    const mutualActionPlan = getMutualActionPlanByMapId(r.mutualActionPlanId);
    // const nextTouchpoint = mutualActionPlan?.actionItems.reduce((a, b) =>
    //   a.date.getTime() > b.date.getTime() ? 1 : -1
    // );

    const result = {
      id: r.relationshipId,
      relatedEntityName: getEntityMetaData(r.relatedentityId)?.name,
      relationshipWithThisEntity: r.type,
      // Get from MAP
      touchpointCount: mutualActionPlan?.actionItems.length,
      nextTouchpoint: getLocalDate(mutualActionPlan?.actionItems[0].date || ''), // TODO: Find the right one based on actual dates. Simplified for demo reasons only.
    };

    return result;
  });
  const relationships = rels; //exampleRelationships;

  const projects = getStrategicContributionsData(entityId as string);
  // console.log('PROJECTS:', projects);

  // THIS IS ONLY FOR THE HAZELDENES DEMO
  // TODO: Replace this hardcoded value and instead detect if the entity is a top level entity
  // If it's a top level entity, the relationships panel is hidden
  // (It doesn't make sense for a whole company to have relationships with its child entities)
  const hideRelationshipsPanel = entityId === 'ENTITY_0001';

  const closePerfSlideover = () => {
    setSlideoverData({ ...slideoverData, open: false });
  };

  const handleTaskClick = (task: any) => {
    // console.log(task);
    const data = getRelationshipSlideoverData(task.id);
    const newData = {
      ...data,
      setOpen: () => closePerfSlideover(),
      open: !data.open,
    };
    setSlideoverData(newData as any);
  };

  const containerPanels: ComponentInfo<any>[] = [
    {
      id: 'PANEL_001',
      Component: RelationshipsPanel,
      props: {
        relatedEntities: relationships,
        actionsDisabled: false,
        onAddClick: () => console.log('Clicked button: Add relationship'),
        isVisible: true,
        isHidden: hideRelationshipsPanel,
      },
    },
    {
      id: 'PANEL_002',
      Component: GanttPanel,
      props: {
        projects: projects,
        taskClick: handleTaskClick,
      },
    },
  ];
  return (
    <>
      <GenericContainer components={containerPanels} />
      <RelationshipSlideOver {...slideoverData} />
    </>
  );
}

export default SwimlanesContainer;
