import React from 'react';
import { Chat, Funnel, PencilSimple, TreeStructure } from 'phosphor-react';

type Props = {
  icon?: iconType;
  label: string;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled: boolean;
};

type iconType = 'edit' | 'filter' | 'comment' | 'hierarchy';

const iconEdit = (
  <PencilSimple
    className="-ml-0.5 mr-2 h-5 w-5 text-gray-500"
    weight="bold"
    aria-hidden="true"
  />
);
const iconFilter = (
  <Funnel
    className="-ml-0.5 mr-2 h-5 w-5 text-gray-500"
    weight="fill"
    aria-hidden="true"
  />
);
const iconComment = (
  <Chat
    className="-ml-0.5 mr-2 h-5 w-5 text-gray-500"
    weight="fill"
    aria-hidden="true"
  />
);
const iconParent = (
  <TreeStructure
    className="-ml-0.5 mr-2 h-5 w-5 text-gray-500"
    weight="bold"
    aria-hidden="true"
  />
);

export default function SecondaryButton({
  icon,
  label,
  onClick,
  disabled,
}: Props): React.ReactElement {
  // Get the icon type
  function ButtonIcon(): React.ReactElement {
    let output = <React.Fragment />;

    switch (icon) {
      case 'edit':
        output = iconEdit;
        break;
      case 'filter':
        output = iconFilter;
        break;
      case 'comment':
        output = iconComment;
        break;
      case 'hierarchy':
        output = iconParent;
        break;

      default:
        break;
    }

    return output;
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className="bg-gradient-to-tr from-gray-100 to-gray-50 hover:to-gray-100 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm whitespace-nowrap text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
      // relative focus:z-10
      onClick={onClick}
    >
      {icon ? ButtonIcon() : null}
      {label}
    </button>
  );
}
