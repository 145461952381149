import { TagSimple } from 'phosphor-react';
import React from 'react';
import { EntityType, Portfolio } from '../../api/index';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import { enumTranslates } from '../../common/i18n/translate';
import Tag from '../../common/Tag';
import { capitaliseFirstLetter } from '../../common/utils';
import { DefaultTags, TagGroup } from './DefaultTags';

type Props = {
  portfolio: Portfolio | null;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function TagsPanel({ portfolio }: Props): React.ReactElement {
  const tags = DefaultTags;

  function getTagIcon(group: TagGroup): React.ReactElement {
    const iconTag = (
      <TagSimple
        className={`text-${group.color} inline-block mr-2 flex-shrink-0`}
        weight="duotone"
        size={20}
      />
    );

    return iconTag;
  }

  const panelTitle = (
    <DetailsPanelHeader
      title={`${capitaliseFirstLetter(
        enumTranslates[EntityType.Project]
      )} Tags`}
      description={`Standardised tagging for this ${
        enumTranslates[EntityType.Portfolio]
      }`}
    />
  );

  const panelButton = (
    <span className="flex items-center mt-1">
      <Tag type="preview" />
    </span>
  );

  const panelContent = (
    <React.Fragment>
      <div
        className={`grid grid-cols-1 lg:grid-cols-${tags.length} gap-4 text-sm`}
      >
        {tags.map((group, idx) => (
          // Group column for each tag group
          <div
            key={idx}
            className="bg-gray-100 border border-gray-200 rounded-md p-3"
          >
            <div className="font-semibold mb-3">{group.name}</div>
            {/* Tags in the group */}
            <div className="space-y-2">
              {group.tags.map((tag, idx) => (
                <div
                  key={idx}
                  className="truncate items-center bg-white border border-gray-200 px-3 py-2 rounded"
                >
                  {getTagIcon(group)} {tag.name}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Hidden div - add concatented styles here to prevent Tailwind tree shaking from dropping the export */}
      {/* This needs to be kept in sync with DefaultTags.tsx */}
      <div className="hidden">
        <div className="bg-orange-500 text-orange-500"></div>
        <div className="bg-lime-500 text-lime-500"></div>
        <div className="bg-pink-500 text-pink-500"></div>
        <div className="bg-sky-500 text-sky-500"></div>
      </div>
    </React.Fragment>
  );

  return (
    <DetailsPanel
      headerLeft={panelTitle}
      headerRight={panelButton}
      content={panelContent}
    />
  );
}

export default TagsPanel;
