import React, { useState } from 'react';
import { getLocalDate } from '../../common/utils';
import {
  DummyProgram,
  DummyProgramMeasure,
  DummyProject,
  DummyProjectMeasure,
  Scenario,
} from './dummyData';
import _ from 'lodash';
import Tag from '../../common/Tag';
import ReactTooltip from 'react-tooltip';
import translate, {
  enumTranslates,
  strings,
} from '../../common/i18n/translate';
import {
  ShowProjectSlideOverButton,
  SlideOverProjectContent,
  SlideOverProjectMeta,
} from './HelperFunctions';
import SlideOver from './SlideOver';
import { DefaultTags } from '../portfolio/DefaultTags';

import {
  ArrowDown,
  ArrowUp,
  CaretDoubleDown,
  CaretDoubleUp,
  TagSimple,
  Target,
} from 'phosphor-react';

import buRef from './previews/bu_ref.png';
import complexityRef from './previews/complexity_ref.png';
import functionRef from './previews/function_ref.png';
import projectTypeRef from './previews/project_type_ref.png';
import buScenario from './previews/bu_scenario.png';
import complexityScenario from './previews/complexity_scenario.png';
import functionScenario from './previews/function_scenario.png';
import projectTypeScenario from './previews/project_type_scenario.png';
import { EntityType } from '../../api/index';

type Props = {
  scenario: Scenario;
  // referencePortfolio: DummyPortfolio;
  // scenarioPortfolio: DummyPortfolio;
  sandboxProgram: DummyProgram | undefined;
  attribute: AttributeType;
  showTitle: boolean;
};

export type AttributeType =
  | 'BENEFITS'
  | 'COST'
  | 'COMMENTS'
  | 'PROJECTS'
  | 'TAGS';

function ComparisonTable({
  scenario,
  // referencePortfolio: reference,
  // scenarioPortfolio: scenario,
  sandboxProgram,
  attribute,
  showTitle,
}: Props): React.ReactElement {
  const [isCollapsed, toggleIsCollapsed] = useState(false);
  const [showSlideOver, setShowSlideOver] = useState(false);
  const [slideOverProjectId, setSlideOverProject] = useState('');

  const iconTarget = (
    <Target
      className="inline-block mr-1 flex-shrink-0 text-gray-500"
      weight="duotone"
      size={20}
    />
  );
  const iconTags = (
    <TagSimple
      className="inline-block flex-shrink-0 text-gray-500"
      weight="bold"
      size={20}
    />
  );

  const tagGroupNames = DefaultTags.map(item => item.name);
  const [selectedTagGroup, setSelectedTagGroup] = useState(tagGroupNames[0]);

  function getPreviewImages(selectedTagGroup: string): {
    reference: string;
    scenario: string;
  } {
    let reference = '';
    let scenario = '';

    switch (selectedTagGroup) {
      case 'Project Type':
        reference = projectTypeRef;
        scenario = projectTypeScenario;
        break;
      case 'Function':
        reference = functionRef;
        scenario = functionScenario;
        break;
      case 'Business Unit':
        reference = buRef;
        scenario = buScenario;
        break;
      case 'Complexity':
        reference = complexityRef;
        scenario = complexityScenario;
        break;

      default:
        break;
    }

    return { reference: reference, scenario: scenario };
  }

  function handleChartSelection(value: string) {
    setSelectedTagGroup(value);
  }

  function getTableData(attribute: AttributeType, isReference: boolean) {
    // Initialise
    let header = <React.Fragment></React.Fragment>;
    let content = <React.Fragment></React.Fragment>;

    if (!sandboxProgram) {
      return { header: header, content: <div>No relevant data</div> };
    }

    // Projects
    function getProjects(program: DummyProgram) {
      const projectList: DummyProject[] = [];
      isReference
        ? scenario.referencePortfolio.programs.forEach(program => {
            const projects = program.projects;
            projects.forEach(
              project => !project.isNew && projectList.push(project)
            );
          })
        : // TODO: Wire in sandboxPortfolio
          scenario.referencePortfolio?.programs.forEach(program => {
            const projects = program.projects;
            projects.forEach(project => projectList.push(project));
          });

      const projectListSorted = _.orderBy(
        [...projectList],
        ['isActive'],
        ['desc']
      );

      return projectListSorted.filter(
        project => project.programId === program.programId
      );
    }

    const slideOverProject = getProjects(sandboxProgram).find(
      project => project.projectId === slideOverProjectId
    );

    const slideOver = (
      <SlideOver
        open={showSlideOver}
        setOpen={setShowSlideOver}
        title={slideOverProject ? slideOverProject.name : ''}
        meta={SlideOverProjectMeta(slideOverProject)}
        content={SlideOverProjectContent(slideOverProject)}
      />
    );

    // Benefits
    function getProjectBenefitsByProgramBenefit(
      program: DummyProgram,
      programBenefit: DummyProgramMeasure,
      isReference: boolean
    ): { project: DummyProject; measure: DummyProjectMeasure }[] {
      const projects = program.projects;
      const projectMeasureList: {
        project: DummyProject;
        measure: DummyProjectMeasure;
      }[] = [];
      projects
        .filter(project => (isReference && !project.isNew) || !isReference)
        .forEach(project => {
          const measures = project.measures;
          measures.forEach(measure => {
            if (measure.programMeasureId === programBenefit.id) {
              projectMeasureList.push({ project: project, measure: measure });
            }
          });
        });

      return projectMeasureList;
    }

    function getProgramBenefits(program: DummyProgram) {
      const programBenefits: DummyProgramMeasure[] = program.measures;

      return { program: programBenefits };
    }

    // Costs
    function getCosts() {
      const referenceEnvelope = sandboxProgram?.envelope
        ? sandboxProgram?.envelope
        : 0;
      const referenceTotalBudgets = sandboxProgram?.envelope
        ? sandboxProgram?.envelope * 0.8
        : 0;
      // const referenceTotalSpend = 5000000;

      const sandboxEnvelope = sandboxProgram?.envelope
        ? sandboxProgram?.envelope
        : 0;
      const sandboxTotalBudgets = sandboxProgram?.envelope
        ? sandboxProgram?.envelope * 0.6
        : 0;
      // const sandboxTotalSpend = 5000000;

      const result = isReference
        ? {
            envelope: referenceEnvelope,
            totalBudgets: referenceTotalBudgets,
            remaining: referenceEnvelope - referenceTotalBudgets,
          }
        : {
            envelope: sandboxEnvelope,
            totalBudgets: sandboxTotalBudgets,
            remaining: sandboxEnvelope - sandboxTotalBudgets,
            deltaEnvelope: sandboxEnvelope - referenceEnvelope,
            deltaTotalBudgets: sandboxTotalBudgets - referenceTotalBudgets,
            deltaRemaining:
              sandboxEnvelope -
              sandboxTotalBudgets -
              (referenceEnvelope - referenceTotalBudgets),
          };

      return result;
    }

    // Other
    function getRemark(): string {
      const remark = isReference
        ? 'We are moving into a potential downturn environment and have been asked to be more fiscally responsible by the Board. The main opportunities appear to be in our R&D and venture space.'
        : 'We have decided to reduce investments in exploratory technologies to conserve cash.';

      return remark;
    }

    function getChange(input: number | undefined) {
      if (input == undefined) {
        return <></>;
      }

      let icon = <React.Fragment></React.Fragment>;
      const direction = input > 0 ? '+' : input < 0 ? '-' : '';
      const magnitude = Math.abs(input);

      const iconUp = (
        <ArrowUp
          className="inline-block mr-1 flex-shrink-0 text-gray-500"
          weight="bold"
          size={12}
        />
      );
      const iconDown = (
        <ArrowDown
          className="inline-block mr-1 flex-shrink-0 text-gray-500"
          weight="bold"
          size={12}
        />
      );

      switch (direction) {
        case '+':
          icon = iconUp;

          break;
        case '-':
          icon = iconDown;

          break;

        default:
          break;
      }

      return (
        <div className="flex items-center justify-end">
          {icon}
          {direction ? `$${magnitude.toLocaleString()}` : 'No change'}
        </div>
      );
    }

    switch (attribute) {
      case 'PROJECTS':
        header = (
          <div className="flex items-center justify-between">
            <div>{`Included ${enumTranslates[EntityType.Project]}s`}</div>
            <div>
              <Tag
                type="custom"
                label={getProjects(sandboxProgram)
                  .filter(project =>
                    isReference ? !project.isNew : project.isActive
                  )
                  .length.toLocaleString()}
                textColour="text-white"
                bgColour="bg-secondary-100"
              />
            </div>
          </div>
        );
        content =
          getProjects(sandboxProgram).filter(project =>
            isReference ? !project.isNew : project.isActive
          ).length > 0 ? (
            <div className="divide-y divide-gray-200">
              {getProjects(sandboxProgram).map((project, index) => (
                <div
                  className={`flex items-center justify-between gap-x-1 px-4 py-3 text-sm ${
                    !isReference && !project.isActive
                      ? 'text-gray-400 line-through'
                      : ''
                  }`}
                >
                  <div className="flex align-baseline">
                    <div
                      className={`w-5 font-semibold ${
                        !isReference && !project.isActive ? 'invisible' : ''
                      }`}
                    >
                      {index + 1}.
                    </div>
                    <div className={``}>{project.name}</div>
                    <div className={`${project.isNew ? '' : 'hidden'} ml-1.5`}>
                      <Tag type="new" />
                    </div>
                  </div>
                  <span>
                    {ShowProjectSlideOverButton(
                      project.projectId,
                      setShowSlideOver,
                      setSlideOverProject
                    )}
                  </span>
                </div>
              ))}

              {/* Slideover */}
              {slideOver}
            </div>
          ) : (
            <div className="italic text-gray-400 text-sm px-4 py-3">
              No relevant projects
            </div>
          );
        break;
      case 'TAGS':
        header = (
          <div className="flex items-center justify-between">Composition</div>
        );

        content = (
          <div className="">
            <div className="mx-4 my-4 flex">
              <span className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center">
                {iconTags}
              </span>
              <select
                className="pl-3 pr-3 w-full text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-r-md"
                value={selectedTagGroup}
                onChange={e => handleChartSelection(e.target.value)}
              >
                {tagGroupNames.map(group => (
                  <option
                    // className="text-xs font-semibold text-black"
                    key={group}
                  >
                    {group}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex px-4 pb-4 -pt-4 max-w-sm mx-auto">
              <img
                src={
                  isReference
                    ? getPreviewImages(selectedTagGroup).reference
                    : getPreviewImages(selectedTagGroup).scenario
                }
              />
            </div>
          </div>
        );
        break;
      case 'BENEFITS':
        header = <div className="">{attribute}</div>;
        content = (
          <div className="text-sm divide-y divide-gray-200">
            {getProgramBenefits(sandboxProgram).program.length > 0 ? (
              getProgramBenefits(sandboxProgram).program.map((measure, idx) => (
                <React.Fragment key={idx}>
                  <div className="px-4 py-3 ">
                    <div className="font-semibold">{measure.name}</div>
                    <div className="text-gray-500 flex align-top mt-0.5">
                      {iconTarget}
                      {`${measure.targetValue}${
                        measure.achieveByDate
                          ? `, by ${getLocalDate(measure.achieveByDate)}`
                          : ''
                      }`}
                    </div>
                  </div>
                  <div className="pl-8 divide-y divide-gray-200">
                    {getProjectBenefitsByProgramBenefit(
                      sandboxProgram,
                      measure,
                      isReference
                    ).length > 0 ? (
                      getProjectBenefitsByProgramBenefit(
                        sandboxProgram,
                        measure,
                        isReference
                      ).map((projectMeasure, idx) => (
                        <div
                          key={idx}
                          className={`px-2 py-3 flex flex-col ${
                            !isReference && !projectMeasure.project.isActive
                              ? 'text-gray-400 line-through'
                              : ''
                          }`}
                        >
                          <div
                            className={`flex items-center ${
                              !isReference && !projectMeasure.project.isActive
                                ? ''
                                : ''
                            }`}
                          >
                            <span className="font-semibold mr-1.5">
                              {projectMeasure.measure.name}
                            </span>
                            {projectMeasure.project.isNew ? (
                              <Tag type="new" />
                            ) : null}
                          </div>
                          <div
                            className={`flex align-top mt-0.5 ${
                              !isReference && !projectMeasure.project.isActive
                                ? 'text-gray-400'
                                : 'text-gray-500'
                            }`}
                          >
                            <Target
                              className={`inline-block mr-1 flex-shrink-0 ${
                                !isReference && !projectMeasure.project.isActive
                                  ? ''
                                  : ''
                              }`}
                              weight="duotone"
                              size={20}
                            />
                            {`${projectMeasure.measure.targetValue}${
                              projectMeasure.measure.achieveByDate
                                ? `, by ${getLocalDate(
                                    projectMeasure.measure.achieveByDate
                                  )}`
                                : ''
                            }`}
                          </div>
                          <span
                            className={`text-xs w-min mt-1 truncate hover:underline hover:cursor-pointer ${
                              !isReference && !projectMeasure.project.isActive
                                ? 'text-gray-400'
                                : 'text-primary-600'
                            }`}
                          >
                            {projectMeasure.project.name}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div className="italic text-gray-400 text-sm px-2 py-3">
                        {`No ${translate(
                          strings.PROJECT_MEASURE,
                          'Project benefit'
                        ).toLowerCase()}s`}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))
            ) : (
              <div className="italic text-gray-400 text-sm px-4 py-3">
                {`No ${translate(
                  strings.PROGRAM_MEASURES,
                  'Program benefits'
                ).toLowerCase()}`}
              </div>
            )}
          </div>
        );
        break;
      case 'COST':
        header = <div className="">{attribute}</div>;
        content = (
          <div className="divide-y divide-gray-200">
            <div className="px-4 py-3 text-sm flex justify-between h-14">
              <div className="font-semibold">Total program envelope</div>
              <div className="flex flex-col">
                <div className="text-right">
                  ${`${getCosts().envelope.toLocaleString()}`}
                </div>
                <div
                  className={`${
                    isReference ? 'invisible' : ''
                  } text-right text-xs text-gray-400`}
                >
                  {getChange(getCosts().deltaEnvelope)}
                </div>
              </div>
            </div>
            <div className="px-4 py-3 text-sm flex justify-between h-14">
              <div className="font-semibold">Allocated project budgets</div>
              <div className="flex flex-col">
                <div className="text-right">
                  ${`${getCosts().totalBudgets.toLocaleString()}`}
                </div>
                <div
                  className={`${
                    isReference ? 'invisible' : ''
                  } text-right text-xs text-gray-400`}
                >
                  {getChange(getCosts().deltaTotalBudgets)}
                </div>
              </div>
            </div>
            <div className="px-4 py-3 text-sm flex justify-between h-14">
              <div className="font-semibold">Remaining program envelope</div>
              <div className="flex flex-col">
                <div className="text-right">
                  ${`${getCosts().remaining?.toLocaleString()}`}
                </div>
                <div
                  className={`${
                    isReference ? 'invisible' : ''
                  } text-right text-xs text-gray-400`}
                >
                  {getChange(getCosts().deltaRemaining)}
                </div>
              </div>
            </div>
          </div>
        );
        break;
      case 'COMMENTS':
        header = <div className="">{attribute}</div>;
        content = <div className="px-4 py-3 text-sm">{getRemark()}</div>;
        break;

      default:
        break;
    }
    return { header: header, content: content, length: length };
  }

  const referenceContent = getTableData(attribute, true);
  const scenarioContent = getTableData(attribute, false);

  const layout = (
    <React.Fragment>
      <div className="">
        {/* shadow overflow-hidden sm:rounded-lg */}
        <table className="table min-w-full">
          {/* divide-y divide-gray-200 */}

          <thead className="table-header-group">
            {/* Header row */}
            <tr>
              <th scope="col" className="w-[45%]">
                {showTitle ? (
                  <div className="text-center">
                    <p className="text-gray-400 text-xs font-normal">{`Reference`}</p>
                    <div className="mb-4 font-semibold">
                      {scenario.referencePortfolio.name}
                    </div>
                  </div>
                ) : null}
                <div
                  className={`sm:rounded-t-lg ${
                    isCollapsed ? 'sm:rounded-b-lg' : ''
                  } bg-gray-50 border border-gray-200 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                >
                  {referenceContent.header}
                </div>
              </th>
              <th
                scope="col"
                className={`text-center align-bottom`}
                // key={index}
              >
                <button
                  type="button"
                  className="mb-2 inline-flex p-1 border border-transparent rounded-full text-gray-500 bg-gray-100 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  onClick={() => toggleIsCollapsed(!isCollapsed)}
                  data-tip
                  data-for={`${attribute}-collapseButton`}
                >
                  {isCollapsed ? (
                    <CaretDoubleDown
                      className="h-4 w-4"
                      weight="bold"
                      aria-hidden="true"
                    />
                  ) : (
                    <CaretDoubleUp
                      className="h-4 w-4"
                      weight="bold"
                      aria-hidden="true"
                    />
                  )}
                </button>
                <ReactTooltip
                  id={`${attribute}-collapseButton`}
                  place="top"
                  // type="info"
                  effect="solid"
                  // delayShow={tooltipDelay}
                >
                  <div className="text-sm font-normal">
                    <p>{isCollapsed ? 'Expand' : 'Collapse'}</p>
                  </div>
                </ReactTooltip>
              </th>
              <th scope="col" className="w-[45%]">
                {showTitle ? (
                  <div className="text-center">
                    <p className="text-gray-400 text-xs font-normal">{`This Scenario`}</p>
                    <div className="mb-4 font-semibold">{scenario.name}</div>
                  </div>
                ) : null}
                <div
                  className={`sm:rounded-t-lg ${
                    isCollapsed ? 'sm:rounded-b-lg' : ''
                  } bg-gray-50 border border-gray-200 px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
                >
                  {scenarioContent.header}
                </div>
              </th>
            </tr>
          </thead>

          <tbody
            className={`table-row-group bg-white divide-y divide-gray-200 ${
              isCollapsed ? 'hidden' : ''
            }`}
          >
            <tr
              // key={`id:${row.id}`}
              className="table-row"
            >
              <td
                // key={`id:${row.id}_col-key:${cellIndex}`}
                className={`align-top border-x border-b border-gray-200 rounded-b-lg`}
              >
                {referenceContent.content}
              </td>
              <td
                // key={`id:${row.id}_col-key:${cellIndex}`}
                className={`align-top`}
              ></td>
              <td
                // key={`id:${row.id}_col-key:${cellIndex}`}
                className={`align-top border-x border-b border-gray-200 rounded-b-lg`}
              >
                {scenarioContent.content}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );

  return layout;
}

export default ComparisonTable;
