import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: Array<React.ReactElement | null> | React.ReactElement;
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    analytics: any;
  }
}

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    window.analytics.page(location.pathname);
  }, [location]);
}

export function SegmentWrapper({ children }: Props): React.ReactElement {
  usePageViews();
  return (
    <div>
      <div>{children}</div>
    </div>
  );
}
