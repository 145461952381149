import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';
import { ProgramMeasure, ProjectMeasure } from '../../../api/index';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import {
  capitaliseFirstLetter,
  classNames,
  getLocalDate,
  getRelativeTime,
  UnlinkedTag,
} from '../../../common/utils';
import { ProjectPageContext } from '../ProjectPageContext';

function MeasuresTable(): React.ReactElement {
  const { state: projectPageState } = useContext(ProjectPageContext);

  function getProjectMeasureById(id: string): ProjectMeasure {
    const foundMeasure = projectPageState.selectedProject?.measures?.find(
      (measure: ProjectMeasure) => measure.id == id
    );
    if (foundMeasure == null) {
      throw new Error('Project Measure not found');
    }
    return foundMeasure;
  }

  function getProjectMeasuresByProgramMeasure(
    programMeasure: ProgramMeasure | null
  ): ProjectMeasure[] {
    let measures: ProjectMeasure[] = [];

    if (programMeasure) {
      // Get project measures that are mapped to this program measure
      measures =
        projectPageState.selectedProject?.measures?.filter(
          (measure: ProjectMeasure) =>
            measure.programMeasureId == programMeasure.id
        ) || [];
    } else {
      // Get unmapped measures
      measures =
        projectPageState.selectedProject?.measures?.filter(
          (measure: ProjectMeasure) =>
            !projectPageState.selectedProject?.program.measures?.some(
              m => m.id === measure.programMeasureId
            )
        ) || [];
    }
    return measures;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {/* Updated table */}
          <div className="overflow-hidden border border-gray-200 rounded-lg">
            {/* Tailwind grid table style */}
            <table className="table min-w-full divide-y divide-gray-200">
              <thead className="table-header-group bg-gray-50">
                {/* Header row */}
                <React.Fragment>
                  <tr className="table-row">
                    <th className="table-cell pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Relevant{' '}
                      {translate(strings.PROGRAM_MEASURES, 'Program Measures')}
                    </th>
                    <th className="table-cell px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {translate(strings.PROJECT_MEASURES, 'Project Measures')}
                    </th>
                    <th className="table-cell px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Target
                    </th>
                    <th className="table-cell pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Target Date
                    </th>
                  </tr>
                </React.Fragment>
              </thead>

              {/* Table data */}
              <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
                {projectPageState.selectedProject?.program?.measures?.map(
                  (programMeasure: ProgramMeasure, themeIndex: number) =>
                    getProjectMeasuresByProgramMeasure(programMeasure)?.map(
                      (measure: ProjectMeasure, measureIndex: number) => (
                        // Create a row for each theme
                        <tr
                          key={`${themeIndex}-${measureIndex}`}
                          className="table-row"
                        >
                          {/* Program measure */}
                          <td
                            rowSpan={
                              getProjectMeasuresByProgramMeasure(programMeasure)
                                ?.length
                            }
                            className={classNames(
                              measureIndex == 0
                                ? 'border-t border-gray-200'
                                : 'hidden',
                              'table-cell pl-4 pr-8 py-3 whitespace-normal font-medium align-top'
                            )}
                          >
                            {measureIndex == 0 ? programMeasure.name : ''}
                          </td>

                          {/* Measures */}
                          <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                            <div className="text-sm">
                              {measure.id &&
                                getProjectMeasureById(measure.id).name}
                              <p className="text-xs text-gray-400">
                                {measure.id &&
                                  getProjectMeasureById(measure.id)
                                    .logicModelGroup &&
                                  enumTranslates[
                                    getProjectMeasureById(measure.id)
                                      .logicModelGroup as string
                                  ]}
                              </p>
                            </div>
                          </td>

                          {/* Target */}
                          <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                            <div className="text-sm">
                              {measure.id &&
                              getProjectMeasureById(measure.id).targetValue ? (
                                getProjectMeasureById(measure.id).targetValue
                              ) : (
                                <p className="italic text-gray-500">-</p>
                              )}
                            </div>
                          </td>

                          {/* Target date */}
                          <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                            {measure.id &&
                            getProjectMeasureById(measure.id).achieveByDate ? (
                              <div className="text-sm">
                                <p className="whitespace-nowrap">
                                  {measure.id &&
                                    getLocalDate(
                                      getProjectMeasureById(measure.id)
                                        .achieveByDate
                                    )}
                                </p>
                                <p className="text-xs text-gray-400">
                                  {measure.id &&
                                    capitaliseFirstLetter(
                                      getRelativeTime(
                                        getProjectMeasureById(measure.id)
                                          .achieveByDate
                                      )
                                    )}
                                </p>
                              </div>
                            ) : (
                              <p className="text-sm italic text-gray-500">-</p>
                            )}
                          </td>
                        </tr>
                      )
                    )
                )}

                {/* Also show unmapped items */}
                {getProjectMeasuresByProgramMeasure(null)?.map(
                  (measure: ProjectMeasure, measureIndex: number) => (
                    // Create a row for each theme
                    <tr key={measureIndex} className="table-row">
                      {/* Program measure */}
                      <td
                        rowSpan={
                          getProjectMeasuresByProgramMeasure(null)?.length
                        }
                        className={classNames(
                          measureIndex == 0
                            ? 'border-t border-gray-200'
                            : 'hidden',
                          'table-cell pl-4 pr-8 py-3 whitespace-normal align-top italic text-gray-500'
                        )}
                      >
                        {measureIndex == 0 ? (
                          <React.Fragment>
                            <UnlinkedTag tooltipId="icon-not-linked" />
                            <ReactTooltip
                              id={'icon-not-linked'}
                              place="top"
                              type="warning"
                              effect="solid"
                              // delayShow={tooltipDelay}
                            >
                              <div className="text-sm text-center not-italic">
                                <p>
                                  These aren't currently linked to a{' '}
                                  {translate(
                                    strings.PROGRAM_MEASURE,
                                    'Program Measure'
                                  ).toLowerCase()}{' '}
                                  so they won't roll up properly.
                                </p>
                                <p>
                                  You can update this through the Project Setup
                                  wizard (click the Edit button above).
                                </p>
                              </div>
                            </ReactTooltip>
                          </React.Fragment>
                        ) : null}
                      </td>

                      {/* Measures */}
                      <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                        <div className="text-sm">
                          {measure.id && getProjectMeasureById(measure.id).name}
                          <p className="text-xs text-gray-400">
                            {measure.id &&
                              getProjectMeasureById(measure.id)
                                .logicModelGroup &&
                              enumTranslates[
                                getProjectMeasureById(measure.id)
                                  .logicModelGroup as string
                              ]}
                          </p>
                        </div>
                      </td>

                      {/* Target */}
                      <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                        <div className="text-sm">
                          {measure.id &&
                          getProjectMeasureById(measure.id).targetValue ? (
                            getProjectMeasureById(measure.id).targetValue
                          ) : (
                            <p className="italic text-gray-500">-</p>
                          )}
                        </div>
                      </td>

                      {/* Target date */}
                      <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                        {measure.id &&
                        getProjectMeasureById(measure.id).achieveByDate ? (
                          <div className="text-sm">
                            <p className="whitespace-nowrap">
                              {measure.id &&
                                getLocalDate(
                                  getProjectMeasureById(measure.id)
                                    .achieveByDate
                                )}
                            </p>
                            <p className="text-xs text-gray-400">
                              {measure.id &&
                                capitaliseFirstLetter(
                                  getRelativeTime(
                                    getProjectMeasureById(measure.id)
                                      .achieveByDate
                                  )
                                )}
                            </p>
                          </div>
                        ) : (
                          <p className="text-sm italic text-gray-500">N/A</p>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeasuresTable;
