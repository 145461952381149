import React from 'react';
import { Target } from 'phosphor-react';

type Props = {
  contents: string;
};

const TargetIcon = () => (
  <Target
    size={20}
    weight="duotone"
    className="text-accent-light shrink-0 mt-px"
    aria-hidden="true"
  />
);

function EntitiesListSOCell({ contents }: Props): React.ReactElement {
  return (
    <div className="inline-flex align-top text-accent-light font-medium gap-x-2">
      <TargetIcon /> {contents}
    </div>
  );
}

export default EntitiesListSOCell;
