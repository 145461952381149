import React from 'react';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import BasicTable, { DataRow } from '../../common/tables/BasicTable';
import BasicTableCell from '../../common/tables/BasicTableCell';
import MembersTableCell from '../../common/tables/MembersTableCell';
import MembersTableCellProps from '../../common/tables/MembersTableCell';
import StyledTableCell from '../../common/tables/StyledTableCell';
import MembersActionHeader from './MembersActionHeader';
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

const MembersTitle = () => (
  <DetailsHeaderTitle
    title={`Roles & Permissions`}
    tooltipText={`Who's on this entity`}
  />
);

type MembersContentProps = {
  members: Member[];
};

// TODO: This table should be grouped by the first column
function MembersContent({ members }: MembersContentProps) {
  const tableHeader: DataRow = {
    data: [
      {
        key: 'TH1',
        cellContents: <BasicTableCell contents="Business responsibility" />,
        // tooltip: <div className="inline-flex">'No comment'</div>,
      },
      { key: 'TH2', cellContents: <BasicTableCell contents="Name" /> },
      {
        key: 'TH3',
        cellContents: <BasicTableCell contents="Permission level" />,
        // tooltip: <div>When we expect to achieve the target</div>,
      },
    ],
  };

  const tableData: DataRow[] = [];
  members.forEach(member =>
    tableData.push({
      data: [
        {
          key: `${member.email}-role`,
          cellContents: (
            <StyledTableCell
              contents={member.businessRole || '-'}
              styleClass="font-medium"
            />
          ),
        },
        {
          key: `${member.email}-name`,
          cellContents: (
            <MembersTableCell
              firstName={member.firstName}
              surname={member.surname}
              email={member.email}
            />
          ),
        },
        {
          key: `${member.email}-permission`,
          cellContents: (
            <BasicTableCell contents={member.permissionLevel || '-'} />
          ),
        },
      ],
    })
  );

  return (
    <div className="min-w-full text-sm space-y-4 lg:space-y-3">
      {members.length > 0 ? (
        <BasicTable tableData={tableData} tableHeader={tableHeader} />
      ) : (
        // Empty state
        <div className="text-gray-400 italic text-center">
          No members added yet
        </div>
      )}
    </div>
  );
}

type Member = {
  firstName: string;
  surname: string;
  email: string;
  businessRole:
    | 'Outcome owner'
    | 'Oversight'
    | 'Day-to-day management'
    | 'Contributor';
  permissionLevel: 'Owner' | 'Editor' | 'Viewer';
};

type MembersPanelProps = {
  members: Member[];
  actionsDisabled: boolean;
  onEditClick: () => void;
  isVisible: boolean;
};

export default function MemberPanel({
  members,
  actionsDisabled,
  onEditClick,
  isVisible,
}: MembersPanelProps): React.ReactElement {
  // Set up content to show

  return (
    <DetailsHeader
      headerLeft={<MembersTitle />}
      headerRight={
        <MembersActionHeader
          memberCount={members.length}
          onClick={onEditClick}
          isVisible={isVisible}
          disabled={actionsDisabled}
        />
      }
      content={<MembersContent members={members} />}
    />
  );
}
