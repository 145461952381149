import React, { useState, useEffect } from 'react';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import PageHeader from './PageHeader';

import { Auth } from 'aws-amplify';
import { AuthErrorType } from '../../common/types';

import { UserContext } from '../../context/UserContext';
import { checkPassword, classNames } from '../../common/utils';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import PrimaryButton from '../../common/PrimaryButton';
import ReactTooltip from 'react-tooltip';
import { Info } from 'phosphor-react';
import PasswordMatchIndicator from '../../user/PasswordMatchIndicator';
import { minimumPasswordLength } from '../../common/constants';

type AppState = {
  givenName: string;
  familyName: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
  formType: string;
  errorText: string;
  changeSuccess: boolean;
};

const initialFormState: AppState = {
  givenName: '',
  familyName: '',
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  formType: 'editProfile',
  errorText: '',
  changeSuccess: false,
};

function ProfilePage(): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user, profile } = React.useContext(UserContext);
  const [passwordMatch, setPasswordMatch] = useState<boolean>(false);
  const [formState, updateFormState] = useState<AppState>(initialFormState);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const pwMatch = formState.confirmPassword === formState.newPassword;
    setPasswordMatch(pwMatch);
    pwMatch || formState.confirmPassword.length < minimumPasswordLength
      ? (formState.errorText = '')
      : (formState.errorText = `New password does not match`);
  }, [formState]);

  const changePassword = async () => {
    const { newPassword, oldPassword } = formState;
    try {
      if (
        newPassword.length < minimumPasswordLength ||
        oldPassword.length < minimumPasswordLength
      ) {
        throw new Error('Password is too short');
      }
      await Auth.changePassword(user, oldPassword, newPassword)
        .then(() => {
          passwordMatch;
          updateFormState(() => ({
            ...formState,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            changeSuccess: true,
            errorText: 'Password changed successfully!',
          }));
        })
        .catch(error => {
          const errorT = error as AuthErrorType;
          updateFormState(() => ({
            ...formState,
            errorText: errorT.message,
          }));
        });
    } catch (error) {
      const errorT = error as AuthErrorType;
      updateFormState(() => ({
        ...formState,
        errorText: errorT.message,
      }));
    }
  };

  let given_name = 'givenname',
    family_name = 'familyname';
  if (profile) {
    given_name = profile?.firstName;
    family_name = profile?.lastName;
  }

  const iconInfo = (
    <Info
      className="w-5 h-5 inline-block ml-1 -mt-1 text-gray-400 hover:text-blue-600 rounded-full"
      weight="bold"
      data-tip
      data-for="password-info"
    />
  );

  const AboutTitle = <DetailsPanelHeader title={'Account Details'} />;

  const AboutContent = (
    <div className="grid grid-cols-1 md:grid-cols-2 justify-between items-stretch gap-x-5 gap-y-3">
      <div className="col-span-1">
        <p className="text-sm font-semibold text-gray-900">First Name</p>
        <p className="mt-1 text-sm text-gray-600">{given_name}</p>
      </div>

      <div className="col-span-1">
        <p className="text-sm font-semibold text-gray-900">Last Name</p>
        <p className="mt-1 text-sm text-gray-600">{family_name}</p>
      </div>

      <div className="col-span-1">
        <p className="text-sm font-semibold text-gray-900">Email</p>
        <p className="mt-1 text-sm text-gray-600">{user?.attributes?.email}</p>
      </div>
    </div>
  );

  function disableSubmit(formState: AppState) {
    if (
      checkPassword(formState.newPassword).pass &&
      formState.newPassword === formState.confirmPassword
    ) {
      return false;
    }
    return true;
  }

  const passwordChangeTitle = <DetailsPanelHeader title={'Change Password'} />;

  const passwordChangeButton = (
    <PrimaryButton
      icon="save"
      label="Update password"
      onClick={() => changePassword()}
      disabled={disableSubmit(formState)}
    />
  );

  const passwordChangeContent = (
    <React.Fragment>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-3">
        {/* Column 1: Old password */}
        <div className="col-span-1">
          <div className="w-full flex justify-between">
            <label
              htmlFor="password"
              className="block text-sm font-semibold text-gray-900 mb-1"
            >
              Current Password
            </label>

            <span className="text-sm">
              {formState.oldPassword.length > 0 && (
                <button
                  className="text-gray-600"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? 'Hide' : 'Show'}
                </button>
              )}
            </span>
          </div>

          <input
            id="oldPassword"
            name="oldPassword"
            type={showCurrentPassword ? 'text' : 'password'}
            value={formState.oldPassword}
            onChange={inputChange}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          />
        </div>

        {/* Column 2: New password and confirmation */}
        <div className="col-span-1 space-y-4">
          <div className="">
            <div className="w-full flex justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-900 mb-1"
              >
                New Password {iconInfo}
              </label>
              <ReactTooltip
                id="password-info"
                effect="solid"
                place="right"
                type="info"
                // className="z-50"
              >
                <div className="text-sm text-left">
                  <ul className="list-disc list-inside">
                    Minimum {minimumPasswordLength} characters, including:
                    <li>1 uppercase letter</li>
                    <li>1 lowercase letter</li>
                    <li>1 number</li>
                    <li>1 symbol</li>
                  </ul>
                </div>
              </ReactTooltip>
              <span className="text-sm">
                {formState.newPassword.length > 0 && (
                  <button
                    className="text-gray-600"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? 'Hide' : 'Show'}
                  </button>
                )}
              </span>
            </div>

            <input
              id="newPassword"
              name="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              value={formState.newPassword}
              onInput={inputChange}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            />

            {formState.newPassword.length > 0 && (
              <div className="grid grid-cols-5 justify-between gap-x-1 mt-1.5 text-white">
                <PasswordMatchIndicator
                  label="ABC"
                  isValid={checkPassword(formState.newPassword).upper}
                />
                <PasswordMatchIndicator
                  label="abc"
                  isValid={checkPassword(formState.newPassword).lower}
                />
                <PasswordMatchIndicator
                  label="123"
                  isValid={checkPassword(formState.newPassword).number}
                />
                <PasswordMatchIndicator
                  label="!@#"
                  isValid={checkPassword(formState.newPassword).symbol}
                />
                <PasswordMatchIndicator
                  label="⟷"
                  isValid={checkPassword(formState.newPassword).length}
                />
              </div>
            )}
          </div>

          <div className="">
            <div className="w-full flex justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-semibold text-gray-900 mb-1"
              >
                Confirm New Password
              </label>

              <span className="text-sm">
                {formState.confirmPassword.length > 0 && (
                  <button
                    className="text-gray-600"
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                  >
                    {showPasswordConfirm ? 'Hide' : 'Show'}
                  </button>
                )}
              </span>
            </div>

            <input
              id="confirmPassword"
              name="confirmPassword"
              type={showPasswordConfirm ? 'text' : 'password'}
              value={formState.confirmPassword}
              onInput={inputChange}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
            />

            {/* Error message */}
            {formState.errorText ? (
              <div
                className={classNames(
                  formState.changeSuccess && passwordMatch
                    ? 'text-green-600'
                    : 'text-red-600',
                  'text-xs font-normal mt-1'
                )}
              >
                {formState.errorText && (
                  <div className="login-form-error flex items-center">
                    {formState.changeSuccess && passwordMatch ? (
                      <CheckCircleIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600"
                        aria-hidden="true"
                      />
                    ) : null}
                    {formState.errorText}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div className="flex flex-col space-y-6 max-w-3xl mx-auto">
      <PageHeader />
      <DetailsPanel
        headerLeft={AboutTitle}
        headerRight={null}
        content={AboutContent}
      />

      <DetailsPanel
        headerLeft={passwordChangeTitle}
        headerRight={passwordChangeButton}
        content={passwordChangeContent}
      />
    </div>
  );
}

export default ProfilePage;
