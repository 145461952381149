/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';

import { useCallback, useContext, useEffect, useState } from 'react';

import useValidation from '../validation/useValidation';
import { Validation, ValidField } from '../validation/Validation.types';
import { WizardFormInputProps } from '../Wizard';
import { WizardContext } from '../WizardContext';

import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { CurrencyDollar } from 'phosphor-react';

type Props = {
  label?: string | React.ReactElement | null;
  subLabel?: string;
  onChange?: (newValue: string) => void;
  manualChange?: (newValue: string | any, key: string) => void;
  placeholder?: string;
  validation?: Validation;
  isWizard?: boolean | null;
  tableClassName?: string | undefined;
  fieldValidations?: ValidField[] | [];
  cellFieldName?: string;
  setFieldValidations?: React.Dispatch<React.SetStateAction<ValidField[] | []>>;
  isUnique?: boolean;
  lines?: number;
  name?: string;
  decorationType?: 'currency';
  disabled?: boolean;
} & WizardFormInputProps;

export default function WizardTextArea({
  id,
  label,
  subLabel,
  onChange,
  manualChange,
  requestKey,
  placeholder,
  validation,
  tableClassName,
  isWizard,
  setFieldValidations,
  name,
  fieldValidations,
  cellFieldName,
  isUnique,
  decorationType,
  lines,
  disabled,
}: Props): React.ReactElement {
  const { dispatch, state } = useContext(WizardContext);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { validate } = useValidation(validation);

  const handleChange = useCallback(
    (e: any) => {
      setIsDirty(true);
      if (isWizard) {
        dispatch({
          type: 'WRITE_TO_REQUEST_PAYLOAD',
          key: requestKey,
          value: e.target.value,
        });
      } else if (manualChange != null && name) {
        manualChange(e.target.value, name);
      }
      if (onChange != null) {
        onChange(e);
      }
    },
    [dispatch, onChange, requestKey, manualChange, name, isWizard]
  );

  let value = '';

  if (isWizard && state.requestPayload[requestKey]) {
    value = state.requestPayload[requestKey] as string;
  } else if (!isWizard) {
    if (requestKey && requestKey !== '') {
      value = requestKey;
    }
  }

  const { isValid, messages } = validate(String(value));
  let valid = isValid;

  if (isUnique != undefined && isUnique === false) {
    valid = isUnique;
    messages.push('Value must be unique');
  }

  useEffect(() => {
    if (setFieldValidations && fieldValidations) {
      const newFieldValidations = [...fieldValidations];
      let fieldIndex;
      if (isWizard) {
        fieldIndex = newFieldValidations?.findIndex(
          obj => obj.field == requestKey
        );
      } else {
        fieldIndex = newFieldValidations?.findIndex(
          obj => obj.field == cellFieldName
        );
      }
      if (newFieldValidations[fieldIndex] !== undefined) {
        newFieldValidations[fieldIndex].valid = valid;
        setFieldValidations(newFieldValidations);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  const shouldShowError = !valid && isDirty;
  const hasDecoration = decorationType != undefined;
  const minHeight = 'min-h-[2.5rem]';

  let inputClassName = shouldShowError
    ? `block w-full ${minHeight} pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm ${
        hasDecoration ? 'rounded-r-md' : 'rounded-md'
      }`
    : `block w-full ${minHeight} shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 ${
        hasDecoration ? 'rounded-r-md' : 'rounded-md'
      }`;

  const inputClassNameCell = shouldShowError
    ? `w-full ${minHeight} mt-1 mb-2 border-red-300 text-red-900 placeholder-red-300 block shadow-sm sm:text-sm focus:border-red-500 focus:ring-red-500 rounded-md`
    : tableClassName;

  if (disabled) {
    inputClassName = `block w-full ${minHeight} shadow-sm text-gray-400 bg-gray-100 shadow-none sm:text-sm border-gray-300 ${
      hasDecoration ? 'rounded-r-md' : 'rounded-md'
    }`;
  }

  // Make text area auto expand height to fit text
  const tx = document.getElementsByTagName('textarea');
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute(
      'style',
      'height:' + tx[i].scrollHeight + 'px;overflow-y:hidden;'
    );
    tx[i].addEventListener('input', OnInput, false);
  }

  function OnInput(this: HTMLTextAreaElement) {
    this.style.height = 'auto';
    this.style.height = this.scrollHeight + 'px';
  }

  return (
    <React.Fragment>
      {(isWizard || manualChange) && (
        <div className="mb-1">
          <label
            htmlFor={id || requestKey}
            className="block text-sm font-medium text-gray-700 sm:mt-px"
          >
            {label}
          </label>
          {subLabel && (
            <p className="block text-xs text-gray-500 mb-2">{subLabel}</p>
          )}
        </div>
      )}
      <div className="flex mt-1 sm:mt-0 relative align-top">
        {/* Show the currency decoration if requested */}
        {decorationType == 'currency' ? (
          <span className="flex px-3 rounded-l-md border-t border-b border-l border-gray-300 bg-gray-50 text-gray-500 items-center align-middle justify-center">
            <CurrencyDollar className="h-5 w-5" weight="bold" />
          </span>
        ) : null}
        <textarea
          rows={lines || 3}
          className={isWizard || name ? inputClassName : inputClassNameCell}
          onChange={handleChange}
          placeholder={placeholder || ''}
          value={value}
          disabled={disabled}
        />
        {shouldShowError ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        ) : null}
      </div>
      {shouldShowError ? (
        <p className="mt-0 mb-1 text-xs text-red-500" id="email-error">
          {messages[0]}
        </p>
      ) : null}
    </React.Fragment>
  );
}
