import React, { useContext } from 'react';
import Auth from '@aws-amplify/auth';
import { SignInInput, UserAuth, UserExt } from '../common/types';
import { User } from '../api/index';
import { BetaContext } from './BetaContext';

export function useAuth(): UserAuth {
  const [user, setUser] = React.useState<UserExt | null>(null);
  const { isBeta, toggleIsBeta } = useContext(BetaContext);
  const [isLoading, setLoading] = React.useState(true);
  const [profile, setProfile] = React.useState<User | null>(
    (JSON.parse(localStorage.getItem('profile') || 'null') as User) || null
  );

  React.useEffect(() => {
    let active = true;

    const check = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user) {
          user.isAdmin =
            user.attributes.email === 'paul.wong.88@gmail.com' ||
            user.attributes.email === 'judah.chew@journeylab.io' ||
            user.attributes.email === 'paul.wong@journeylab.io' ||
            user.attributes.email === 'yuyan.wang@journeylab.io' ||
            user.attributes.email === 'team@journeylab.io' ||
            user.attributes.email === 'rodney.agassi@bi3technologies.com' ||
            user.attributes.email === 'sadin.nurkic@journeylab.io' ||
            user.attributes.email === 'leyladzeko@gmail.com';
        }
        if (active) setUser(user);
        setLoading(false);
      } catch (error) {
        if (active) setUser(null);
        setLoading(false);
      }
    };
    check();
    return () => {
      active = false;
    };
  }, [setUser]);

  const signIn = React.useCallback(
    async ({ username, password }: SignInInput) => {
      const signInUser: UserExt | null = await Auth.signIn(username, password);
      if (signInUser) {
        signInUser.isAdmin =
          username === 'paul.wong.88@gmail.com' ||
          username === 'paul.wong@journeylab.io' ||
          username === 'judah.chew@journeylab.io' ||
          username === 'yuyan.wang@journeylab.io' ||
          username === 'team@journeylab.io' ||
          username === 'leyladzeko@gmail.com' ||
          username === 'rodney.agassi@bi3technologies.com' ||
          username === 'sadin.nurkic@journeylab.io';
        window.analytics.identify(signInUser?.attributes?.sub, {
          name: `${signInUser.attributes?.given_name} ${signInUser.attributes?.family_name}`,
          email: signInUser.attributes?.email,
          plan: 'test',
        });
      }
      setUser(signInUser);
    },
    [setUser]
  );

  const signOut = React.useCallback(async () => {
    await Auth.signOut();
    setUser(null);
    if (isBeta && toggleIsBeta) {
      toggleIsBeta();
    }
    localStorage.removeItem('workspace');
    localStorage.removeItem('portfolio');
    localStorage.removeItem('project');
    localStorage.removeItem('program');
    localStorage.removeItem('profile');
    localStorage.removeItem('isBeta');
    window.analytics.reset();
  }, [isBeta, toggleIsBeta]);

  return { user, signIn, signOut, isLoading, setLoading, setProfile, profile };
}
