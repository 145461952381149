import { CaretRight } from 'phosphor-react';
import * as React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  statusIcon: React.ReactElement;
  title: React.ReactElement;
  isExpandable: boolean;
  expandDefault: boolean;
  content: React.ReactElement;
  link?: string;
};

function ExpandableSlideoverCard({
  statusIcon,
  title,
  isExpandable,
  expandDefault,
  content,
  link,
}: Props): React.ReactElement {
  const [isExpanded, setIsExpanded] = React.useState(expandDefault);

  const iconLink = (
    <CaretRight weight="bold" className="h-4 w-4 text-black shrink-0" />
  );

  function handleExpandClick() {
    console.log('expand');
    setIsExpanded(!isExpanded);
  }

  const layout = (
    <div className="bg-gray-100 border border-gray-200 rounded-md overflow-hidden text-sm">
      <div className="">
        {/* The first part is always visible */}
        <div className="relative align-top justify-between">
          <button
            type="button"
            className={`flex w-full align-top gap-x-3 pl-3 pr-12 py-2 ${
              isExpandable ? 'hover:bg-gray-200' : ''
            }`}
            onClick={handleExpandClick}
            disabled={!isExpandable}
          >
            <span className={`flex align-top w-6 flex-none`}>{statusIcon}</span>
            <div className="font-medium text-left">{title}</div>
          </button>

          {/* Link */}
          {link ? (
            <div className="absolute top-1 right-1 w-8 h-full ml-1">
              <Link to={link}>
                <div className="flex items-center justify-center w-7 h-7 rounded-full hover:bg-gray-200">
                  {iconLink}
                </div>
              </Link>
            </div>
          ) : null}
        </div>

        {/* This next part is only shown when the card is expanded */}
        {isExpanded ? <div className="w-full">{content}</div> : null}
      </div>
    </div>
  );

  return layout;
}

export default ExpandableSlideoverCard;
