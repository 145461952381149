import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import * as ROUTES from '../../common/routes';
import {
  /* GetPortfolioQuery,
  GetPortfolioQueryVariables,
  GetProgramsQuery,
  GetProgramsQueryVariables, */
  Portfolio,
  Program,
  //CreateProgramMutationVariables,
  //CreateProgramMutation,
  EntityType,
  //Visibility,
  //Priority,
} from '../../api/index';

import { Link } from 'react-router-dom';
import EntityDetails from './EntityDetails';
import EntityChildList from './EntityChildList';
import EntityMembers from '../../common/EntityMembers';

function TempPortfolio(): React.ReactElement {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const [portfolio] = useState<Portfolio | null>(null);
  const [programs] = useState<Program[]>([]);

  const [nextToken] = useState<string | null | undefined>(null);

  const _getPortfolio = async () => {
    /* try {
      setLoading(true)
      const variable: GetPortfolioQueryVariables = {
        portfolioId,
      }

      const resp: GraphQLResult<GetPortfolioQuery> = (await API.graphql(
        graphqlOperation(getPortfolio, variable),
      )) as { data: GetPortfolioQuery }

      if (resp.data) {
        const respGetPortfolio: GetPortfolioQuery = resp.data

        if (respGetPortfolio.getPortfolio) {
          setPortfolio(respGetPortfolio.getPortfolio)
        }
      }
    } catch (err: any) {
      if(isError(err.errors, "Unauthorized")){
        navigate(`${PORTFOLIO}/${portfolioId}/accessdenied`) 
      } else {
        navigate(NOT_FOUND)
      }  
      setLoading(false)   
    } */
  };

  const _getPrograms = async () => {
    /* try {
      const variable: GetProgramsQueryVariables = {
        portfolioId,
        limit: pageSize,
        nextToken,
      }

      const resp: GraphQLResult<GetProgramsQuery> = (await API.graphql(
        graphqlOperation(getPrograms, variable),
      )) as { data: GetProgramsQuery }

      if (resp.data) {
        const respGetPrograms: GetProgramsQuery = resp.data

        if (respGetPrograms.getPrograms) {
          if (respGetPrograms.getPrograms.items) {
            setPrograms(respGetPrograms.getPrograms.items as Program[])
          }

          setNextToken(respGetPrograms.getPrograms.nextToken)
        }
      }
    } catch (err) {
      console.error(err)
    } */
  };

  const _createProgram = async () => {
    /* try {
      const variable: CreateProgramMutationVariables = {
        input: {
          portfolioId,
          name,
          //visibility: Visibility.shared,
          //priority: Priority.tier1,
        },
      }

      const resp: GraphQLResult<CreateProgramMutation> = (await API.graphql(
        graphqlOperation(createProgram, variable),
      )) as { data: CreateProgramMutation }

      if (resp.data) {
        const respCreateProgram: CreateProgramMutation = resp.data

        if (respCreateProgram.createProgram) {
          setPrograms((state) => [
            ...state,
            respCreateProgram.createProgram as Program,
          ])
        }
      }
    } catch (err) {
      console.error(err)
    } */
  };

  useEffect(() => {
    _getPortfolio();
    _getPrograms();
  });

  return (
    <>
      <EntityDetails
        entity={portfolio}
        entityType={EntityType.Portfolio}
        create={_createProgram}
      />
      <EntityChildList
        entityChilds={programs}
        entityChildType={EntityType.Program}
        getChilds={_getPrograms}
        nextToken={nextToken}
      />
      <div className="my-5 underline text-blue-600 hover:text-blue-800 visited:text-purple-600">
        <Link to={ROUTES.PORTFOLIO}>Demo</Link>
      </div>
      <EntityMembers
        entityId={portfolioId as string}
        entityType={EntityType.Portfolio}
      ></EntityMembers>
    </>
  );
}

export default TempPortfolio;
