import React from 'react';
import { EntityOutcome, Manager } from './PerformanceSlideOverContent';
import { SlideOverSectionHeader } from '../../common/slideovers/SlideOverSectionHeader';
import UserInfo from '../../basic/UserInfo';
import { SlideOverSimpleDataRow } from '../../common/slideovers/SlideOverSimpleDataRow';
import PerformanceSlideOverOutcomeBlock from './PerformanceSlideOverOutcomeBlock';

type PerformanceSlideOverObjectivesTabProps = {
  managers: Manager[];
  endDate: string;
  objectives: EntityOutcome[];
};

export default function PerformanceSlideOverObjectivesTab({
  managers,
  endDate,
  objectives,
}: PerformanceSlideOverObjectivesTabProps): React.ReactElement {
  return (
    <div className="space-y-6">
      {/* Show the Entity managers */}
      <div>
        <SlideOverSectionHeader
          label={`Manager${managers?.length > 1 ? 's' : ''}`}
        />

        {/* Show a list of managers so users know who to contact */}
        {managers?.length > 0 ? (
          <div className="space-y-3">
            {managers.map(manager => (
              <div key={manager.email} className="">
                <UserInfo
                  firstName={manager.firstName}
                  surname={manager.surname}
                  email={manager.email}
                />
              </div>
            ))}
          </div>
        ) : (
          // Empty state
          <div className="text-gray-400 italic text-sm">Not assigned</div>
        )}
      </div>

      {/* Show the date the entity is due to end */}
      <SlideOverSimpleDataRow label={'End date'} content={endDate} />

      {/* Show what the entity is aiming to achieve */}
      <div>
        <SlideOverSectionHeader label={`Objectives`} />

        {objectives?.length > 0 ? (
          <div className="space-y-2">
            {objectives.map(outcome => (
              <div key={outcome.description} className="">
                <PerformanceSlideOverOutcomeBlock
                  description={outcome.description}
                  target={outcome.target}
                  targetDate={outcome.targetDate}
                />
              </div>
            ))}
          </div>
        ) : (
          // Empty state
          <div className="text-gray-400 italic text-sm">Not captured</div>
        )}
      </div>
    </div>
  );
}
