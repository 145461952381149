import { EntityType, ReportType } from '../../api/index';
import translate, {
  enumTranslates,
  strings,
} from '../../common/i18n/translate';

import programUpdatesThumbnail from './thumbnails/program_updates.png';
import programSnapshotThumbnail from './thumbnails/program_snapshot.png';
import { capitaliseFirstLetter } from '../../common/utils';

export const entityTypes = [
  {
    name: capitaliseFirstLetter(enumTranslates[EntityType.Portfolio]),
    value: EntityType.Portfolio,
  },
  {
    name: capitaliseFirstLetter(enumTranslates[EntityType.Program]),
    value: EntityType.Program,
  },
  {
    name: capitaliseFirstLetter(enumTranslates[EntityType.Project]),
    value: EntityType.Project,
  },
];

export const portfolioReportTypes = [
  //   {
  //     display: 'Portfolio Listing',
  //     description:
  //       'Displays a full list of programs and projects currently in a portfolio',
  //     value: ReportType.ProgramUpdates,
  //   },
  {
    display: `${capitaliseFirstLetter(
      enumTranslates[EntityType.Portfolio]
    )} Performance`,
    description: translate(
      strings.PORTFOLIO_ALL_PROGRAMS_PERFORMANCES_DEFINITION,
      'All Program Updates Definition'
    ),
    value: ReportType.ProgramUpdates,
    thumbnail: programUpdatesThumbnail,
  },
];

export const programReportTypes = [
  {
    display: `${capitaliseFirstLetter(
      enumTranslates[EntityType.Program]
    )} Performance`,
    description: translate(
      strings.PROGRAM_SNAPSHOT_DESCRIPTION,
      'Description of card on program details page'
    ),
    value: ReportType.ProgramSnapshot,
    thumbnail: programSnapshotThumbnail,
  },
  //   {
  //     display: 'Program Issues',
  //     description: 'Highlights which project measures need support',
  //     value: ReportType.ProgramSnapshot,
  //   },
];

export const projectReportTypes = [
  {
    display: 'Project Update',
    description: 'Shows a selected update for a project',
    value: null,
    thumbnail: null,
  },
];
