import React, { useContext, useEffect, useState } from 'react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import {
  Attachment,
  useCreateAttachmentMutation,
  useUpdateAttachmentMutation,
  useRemoveAttachmentMutation,
  Program,
  Project,
} from '../../api/index';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import EmptyState from '../../common/layout/EmptyState';
import Modal from '../../common/Modal';
import { Link, LinkSimple, PencilSimple, Trash } from 'phosphor-react';
import { Menu } from '@headlessui/react';
import { DemoContext } from '../../context/DemoContext';
import { ProjectPageContext } from './ProjectPageContext';
import Spinner from '../../common/SpinnerThemed';
import { toast } from 'react-toastify';
import { ProgramPageContext } from '../program/ProgramPageContext';
import PrimaryButton from '../../common/PrimaryButton';

type Props = {
  entity: Project | Program | null;
  loadingEntity: boolean;
};

function LinksPanel({ entity, loadingEntity }: Props): React.ReactElement {
  const links = entity?.attachments?.items;
  const { dispatch, state } = useContext(ProjectPageContext);
  const { dispatch: programDispatch, state: programState } =
    useContext(ProgramPageContext);
  const [createAttachment, { loading, data, error }] =
    useCreateAttachmentMutation();
  const [
    updateAttachment,
    { loading: updateLoading, error: updateError, data: updateData },
  ] = useUpdateAttachmentMutation();
  const [
    removeAttachment,
    { loading: removeLoading, data: removeData, error: removeError },
  ] = useRemoveAttachmentMutation();
  const { isDemo } = useContext(DemoContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [descriptionInputValue, setDescriptionInputValue] = useState('');
  const [urlInputValue, setUrlInputValue] = useState('');
  const [selectedAttachment, setSelectedAttachment] = useState<Attachment>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const iconClass = 'w-6 h-6 flex-shrink-0 text-white';
  const iconLink = <LinkSimple weight="bold" className={iconClass} />;

  const iconEdit = (
    <PencilSimple weight="bold" className="h-5 w-5 text-gray-500" />
  );
  const iconDelete = <Trash weight="bold" className="h-5 w-5 text-red-600" />;
  const menuOptions = [
    {
      display: 'Edit',
      icon: iconEdit,
      action: (attachment: Attachment) => {
        setSelectedAttachment(attachment);
        setIsEdit(true);
        setModalOpen(true);
      },
    },
    {
      display: 'Delete',
      icon: iconDelete,
      action: (attachment: Attachment) => handleDeleteAttachment(attachment),
    },
  ];

  function handleAddAttachment() {
    if (entity?.id) {
      createAttachment({
        variables: {
          input: {
            objectId: entity?.id,
            description: descriptionInputValue,
            url: urlInputValue,
            // avatar: "",
          },
        },
      });
    }
  }

  function handleEditAttachment() {
    if (selectedAttachment) {
      updateAttachment({
        variables: {
          input: {
            id: selectedAttachment.id,
            objectId: selectedAttachment.objectId,
            description: descriptionInputValue,
            url: urlInputValue,
            // avatar: "",
          },
        },
      });
    }
  }

  function handleDeleteAttachment(attachment: Attachment) {
    if (entity?.id) {
      removeAttachment({
        variables: {
          input: {
            objectId: entity?.id,
            id: attachment.id,
          },
        },
      });
    }
  }

  useEffect(() => {
    if (
      data?.createAttachment ||
      removeData?.removeAttachment ||
      updateData?.updateAttachment
    ) {
      setModalOpen(false);
      //TODO check entity typename here
      if (entity?.__typename === 'Project') {
        dispatch({
          type: 'LOAD_UPDATE',
          loadUpdate: !state.loadUpdate,
        });
      } else {
        programDispatch({
          type: 'LOAD_UPDATE',
          loadUpdate: !programState.loadUpdate,
        });
      }
    }
  }, [
    data,
    dispatch,
    removeData?.removeAttachment,
    state.loadUpdate,
    programState.loadUpdate,
    updateData?.updateAttachment,
    entity?.__typename,
    programDispatch,
  ]);

  useEffect(() => {
    if (selectedAttachment && selectedAttachment.description) {
      setDescriptionInputValue(selectedAttachment.description);
      setUrlInputValue(selectedAttachment.url);
    }
  }, [selectedAttachment]);

  useEffect(() => {
    if (error?.message) {
      toast.error(error.message);
    } else if (removeError?.message) {
      toast.error(removeError.message);
    } else if (updateError?.message) {
      toast.error(updateError.message);
    }
  }, [error, removeError, updateError]);

  const linksTitle = (
    <DetailsPanelHeader
      title={'Links'}
      description={`Shortcuts to other useful items`}
    />
  );

  const linksButton = (
    <React.Fragment>
      <div className="flex items-center group relative">
        {modalOpen && (
          <Modal width={'w-full max-w-xl'} onClose={() => setModalOpen(false)}>
            <div className="flex flex-col space-y-4 bg-white p-4 md:w-full overflow-y-auto align-middle">
              <div className="flex flex-row items-center">
                <span className="flex p-2 rounded-full bg-primary-500 align-middle items-center">
                  <Link className="h-5 w-5 text-white mx-auto my-auto" />
                </span>
                <h3 className="text-lg font-medium ml-3 text-gray-900">
                  {isEdit ? 'Edit' : 'Add'} Link
                </h3>
              </div>

              <div>
                <p className="mb-1 text-sm text-gray-900">Description</p>
                <input
                  type="text"
                  className="block w-full shadow-sm focus:ring-primary-600 focus:border-primary-600 sm:text-sm border-gray-300 rounded-md"
                  onChange={e => setDescriptionInputValue(e.target.value)}
                  placeholder={`e.g. Business case, Jira board, sponsor presentation`}
                  value={descriptionInputValue}
                />
              </div>
              <div>
                <p className="mb-1 text-sm text-gray-900">URL</p>
                <input
                  type="text"
                  className="block w-full shadow-sm focus:ring-primary-600 focus:border-primary-600 sm:text-sm border-gray-300 rounded-md"
                  onChange={e => setUrlInputValue(e.target.value)}
                  placeholder={`https://www.example.com/document`}
                  value={urlInputValue}
                />
              </div>

              <span className="text-right space-x-3 pt-3">
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                  onClick={() =>
                    isEdit ? handleEditAttachment() : handleAddAttachment()
                  }
                  // disabled={
                  //   deleteInputValue !== `DELETE ${project.name.toUpperCase()}`
                  // }
                  // onClick={() => handleDeleteClick(project)}
                >
                  {isEdit ? 'Save' : 'Add link'}
                </button>
              </span>
            </div>
          </Modal>
        )}
      </div>

      <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
        <PrimaryButton
          icon="plus"
          label="Add link"
          onClick={() => {
            setIsEdit(false);
            setModalOpen(true);
          }}
          disabled={isDemo}
        />
        {/* <button
          type="button"
          disabled={isDemo}
          className="disabled:opacity-50 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
          onClick={() => {
            setIsEdit(false);
            setModalOpen(true);
          }}
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Add link
        </button> */}
      </div>
    </React.Fragment>
  );

  const linksContent =
    loading || removeLoading || updateLoading || loadingEntity ? (
      <Spinner text="Loading links..." />
    ) : links && links.length > 0 ? (
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
        {links.map(link => (
          // Tiles with links to help users get started
          <div
            key={link.id}
            className="group flex justify-between rounded shadow"
          >
            <button
              type="button"
              className="bg-gray-200 flex-grow col-span-1 w-4/5"
            >
              <a href={link.url} target="_blank">
                <div className="h-full flex flex-row align-middle text-sm text-left">
                  <div className="text-sm text-left p-3 items-center bg-secondary-800 group-hover:bg-primary-500 rounded-l">
                    {iconLink}
                  </div>

                  <div className="flex flex-col my-auto text-left overflow-hidden pl-3 py-2">
                    <div className="text-sm font-semibold group-hover:underline ">
                      {link.description}
                    </div>
                    <span className="text-xs text-gray-500 truncate ellipsis whitespace-nowrap overflow-hidden">
                      {link.url}
                    </span>
                  </div>
                </div>
              </a>
            </button>

            <div className="flex w-8 pr-3 rounded-r items-center relative bg-gray-200">
              <Menu>
                <Menu.Button className="inline-flex p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-300 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                  <EllipsisVerticalIcon className="h-5 w-5" />
                </Menu.Button>
                <Menu.Items className="w-32 z-10 origin-top-right absolute right-0 mt-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {/* Use the `active` render prop to conditionally style the active item. */}
                  {menuOptions.map((item, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-white text-gray-700'
                          }
                            ${index === 0 ? 'rounded-t-md' : ''} ${
                            index === menuOptions.length - 1
                              ? 'rounded-b-md'
                              : ''
                          } flex w-full items-center px-4 py-2 text-sm text-gray-700`}
                          onClick={() => item.action(link)}
                        >
                          <span className="inline-block mr-3">{item.icon}</span>
                          <span className="inline-block">{item.display}</span>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Menu>
            </div>
          </div>
        ))}
      </div>
    ) : (
      <EmptyState
        type="links"
        secondaryText="Keep relevant items at hand by adding them here."
      />
    );

  const layout = (
    <React.Fragment>
      <DetailsPanel
        headerLeft={linksTitle}
        headerRight={linksButton}
        content={linksContent}
      />
    </React.Fragment>
  );

  return layout;
}

export default LinksPanel;
