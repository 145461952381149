import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  /* GetPortfoliosQuery,
  GetPortfoliosQueryVariables, */
  Workspace,
  Portfolio,
  /* CreatePortfolioMutation,
  CreatePortfolioMutationVariables, */
  /* GetWorkspaceQuery,
  GetWorkspaceQueryVariables, */
  EntityType,
} from '../../api/index';

import EntityDetails from './EntityDetails';
import EntityChildList from './EntityChildList';
import EntityMembers from '../../common/EntityMembers';

function TempWorkspace(): React.ReactElement {
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const [workspace] = useState<Workspace | null>(null);
  const [portfolios] = useState<Portfolio[]>([]);

  const [nextToken] = useState<string | null | undefined>(null);

  const _getWorkspace = async () => {
    /* try {
      setLoading(true)
      const variable: GetWorkspaceQueryVariables = {
        workspaceId,
      }
      const resp: GraphQLResult<GetWorkspaceQuery> = (await API.graphql(
        graphqlOperation(getWorkspace, variable),
      )) as { data: GetWorkspaceQuery }

      if (resp.data) {
        const respGetWorkspace: GetWorkspaceQuery = resp.data
        if (respGetWorkspace.getWorkspace) {
          setWorkspace(respGetWorkspace.getWorkspace)
        }
      }
    } catch (err: any) {
      if(isError(err.errors, "Unauthorized")){
        navigate(`${WORKSPACE}/${workspaceId}/accessdenied`) 
      } else {
        navigate(NOT_FOUND)
      }
    } */
  };

  const _getPortfolios = async () => {
    /* try {
      const variable: GetPortfoliosQueryVariables = {
        workspaceId,
        limit: pageSize,
        nextToken,
      }

      const resp: GraphQLResult<GetPortfoliosQuery> = (await API.graphql(
        graphqlOperation(getPortfolios, variable),
      )) as { data: GetPortfoliosQuery }

      if (resp.data) {
        const respGetPortfolios: GetPortfoliosQuery = resp.data
        if (respGetPortfolios.getPortfolios) {
          if (respGetPortfolios.getPortfolios.items) {
            setPortfolios(respGetPortfolios.getPortfolios.items)
          }
          if(respGetPortfolios.getPortfolios.nextToken){
            console.log(respGetPortfolios.getPortfolios.nextToken)
            setNextToken(respGetPortfolios.getPortfolios.nextToken)
          }
        }
      }
    } catch (err) {
      console.error(err)
    } */
  };

  const _createPortfolio = async () => {
    /* try {
      const variable: CreatePortfolioMutationVariables = {
        input: {
          workspaceId,
          name,
        },
      }
      const resp: GraphQLResult<CreatePortfolioMutation> = (await API.graphql(
        graphqlOperation(createPortfolio, variable),
      )) as { data: CreatePortfolioMutation }

      if (resp.data) {
        const respCreatePortfolio: CreatePortfolioMutation = resp.data

        if (respCreatePortfolio.createPortfolio) {
          setPortfolios((state) => [
            ...state,
            respCreatePortfolio.createPortfolio as Portfolio,
          ])
        }
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.error(err)
    } */
  };

  useEffect(() => {
    _getWorkspace();
    _getPortfolios();
  }, []);

  return (
    <>
      <EntityDetails
        entity={workspace}
        entityType={EntityType.Workspace}
        create={_createPortfolio}
      />
      <EntityChildList
        entityChilds={portfolios}
        entityChildType={EntityType.Portfolio}
        getChilds={_getPortfolios}
        nextToken={nextToken}
      />
      <EntityMembers
        entityId={workspaceId as string}
        entityType={EntityType.Workspace}
      ></EntityMembers>
    </>
  );
}

export default TempWorkspace;
