import React, { useContext, useEffect, useState } from 'react';
import {
  EntityType,
  Program,
  Project,
  useGetAllProgramsLazyQuery,
  useGetAllProjectsLazyQuery,
  useGetPortfoliosLazyQuery,
} from '../../api/index';
import { PortfolioPageContext } from '../portfolio/PortfolioPageContext';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { Link } from 'react-router-dom';
import { PORTFOLIO, PROGRAM, PROJECT } from '../../common/routes';
import WizardButton from '../../common/wizard/WizardButton';
import { DemoContext } from '../../context/DemoContext';
import { ProgramPageContext } from '../program/ProgramPageContext';
import { ProjectPageContext } from '../project/ProjectPageContext';
import { classNames } from '../../common/utils';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';
import Spinner from '../../common/SpinnerThemed';
import WorkspaceSelector from './WorkspaceSelector';
import { CaretRight, WifiX } from 'phosphor-react';
import translate, {
  enumTranslates,
  strings,
} from '../../common/i18n/translate';

const icon = <WifiX className="mx-auto h-12 w-12 text-gray-300" />;

function DirectoryList(): React.ReactElement {
  const { state: workspacePageState } = useContext(WorkspacePageContext);
  const { state: projectState, dispatch: projectDispatch } =
    useContext(ProjectPageContext);
  const { state: programState, dispatch: programDispatch } =
    useContext(ProgramPageContext);
  const { state: portfolioState, dispatch: portfolioDispatch } =
    useContext(PortfolioPageContext);

  const [
    GetAllProgramsQuery,
    { data: dataPrograms, loading: loadingPrograms, error: errorPrograms },
  ] = useGetAllProgramsLazyQuery({ fetchPolicy: 'no-cache' });

  const [
    GetAllProjectsQuery,
    { data: dataProjects, loading: loadingProjects, error: errorProjects },
  ] = useGetAllProjectsLazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (dataPrograms?.getAllPrograms) {
      programDispatch({
        type: 'SET_USERS_PROGRAMS',
        usersPrograms: dataPrograms?.getAllPrograms.filter(
          p =>
            p.portfolio.workspace.id ===
            workspacePageState.selectedWorkspace?.id
        ),
      });
    }
  }, [dataPrograms, programDispatch, workspacePageState.selectedWorkspace?.id]);

  useEffect(() => {
    if (dataProjects?.getAllProjects) {
      projectDispatch({
        type: 'SET_USERS_PROJECTS',
        usersProjects: dataProjects.getAllProjects.filter(
          p =>
            p.program.portfolio.workspace.id ===
            workspacePageState.selectedWorkspace?.id
        ),
      });
    }
  }, [dataProjects, projectDispatch, workspacePageState.selectedWorkspace?.id]);
  const [
    GetPortfoliosQuery,
    {
      data: dataPortfolios,
      loading: loadingPortfolios,
      error: errorPortfolios,
    },
  ] = useGetPortfoliosLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (workspacePageState?.selectedWorkspace) {
      GetPortfoliosQuery({
        variables: {
          workspaceId: workspacePageState?.selectedWorkspace.id,
        },
      });
      GetAllProgramsQuery({});
      GetAllProjectsQuery({});
    }
  }, [
    workspacePageState?.selectedWorkspace,
    GetPortfoliosQuery,
    GetAllProgramsQuery,
    GetAllProjectsQuery,
  ]);

  useEffect(() => {
    if (
      dataPortfolios?.getPortfolios &&
      portfolioState.selectedPortfolio === null
    ) {
      portfolioDispatch({
        type: 'SET_SELECTED_PORTFOLIO',
        portfolio: dataPortfolios.getPortfolios[0],
      });
    }
  }, [dataPortfolios, portfolioDispatch, portfolioState.selectedPortfolio]);

  useEffect(() => {
    if (dataPortfolios?.getPortfolios && dataPortfolios?.getPortfolios) {
      portfolioDispatch({
        type: 'SET_PORTFOLIOS',
        portfolios: dataPortfolios.getPortfolios,
      });
    }
  }, [dataPortfolios, portfolioDispatch]);

  // Get project / program list for current user
  const { isDemo } = useContext(DemoContext);

  // Get project / program list for current user
  let MyProjectsList: Project[] | undefined;
  let MyProgramsList: Program[] | undefined;
  if (isDemo) {
    MyProjectsList = projectState?.usersProjects || [];
    MyProgramsList = programState?.usersPrograms || [];
  } else {
    MyProjectsList = projectState?.usersProjects || [];
    MyProgramsList = programState?.usersPrograms || [];
  }

  const MyPortfolioList = dataPortfolios?.getPortfolios;

  // Track item selections for three panel view
  const [SelectedPortfolioId, setSelectedPortfolioId] = useState('');
  const [SelectedProgramId, setSelectedProgramId] = useState('');
  const [SelectedProjectId, setSelectedProjectId] = useState('');

  // Handle user selections in updated directory
  function handlePortfolioClick(portfolioId: string) {
    setSelectedPortfolioId(portfolioId);
    setSelectedProgramId('');
    setSelectedProjectId('');
  }

  function handleProgramClick(programId: string) {
    setSelectedProgramId(programId);
    setSelectedProjectId('');
  }

  function handleProjectClick(projectId: string) {
    setSelectedProjectId(projectId);
  }

  const directoryTitle = (
    <DetailsPanelHeader
      title={'Directory'}
      description={`Navigate to a ${enumTranslates[EntityType.Portfolio]}, ${
        enumTranslates[EntityType.Program]
      } or ${enumTranslates[EntityType.Project]} in this ${
        enumTranslates[EntityType.Workspace]
      }`}
    />
  );

  const directoryButton = (
    <React.Fragment>
      {!isDemo && workspacePageState.workspaces.length > 1 ? (
        <div className="flex align-middle items-center">
          <WorkspaceSelector />
        </div>
      ) : null}
    </React.Fragment>
  );

  const iconCaretRight = (
    <CaretRight
      weight="bold"
      size={16}
      className="shrink-0"
      aria-hidden="true"
    />
  );

  const directoryList = (
    <React.Fragment>
      {/* TODO: Filter to include planned or in-flight only; show completed in a separate archive area (to be designed) */}

      {/* Mobile */}
      <div className="block md:hidden overflow-hidden border border-gray-200 rounded-lg">
        <table className="table w-full divide-y divide-gray-200">
          <thead className="table-header-group bg-gray-50">
            <tr className="table-row">
              <th className="table-cell w-1/3 align-middle px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                What's in this {enumTranslates[EntityType.Workspace]}
              </th>
            </tr>
          </thead>

          <tbody className="table-row-group bg-white text-sm divide-y divide-gray-200">
            <tr className="table-row border-t border-gray-200">
              {/* Portfolios */}
              <td className="table-cell border-t border-gray-200 p-1 align-top">
                {MyPortfolioList?.length == 0 ? (
                  <div className="flex flex-col items-center py-4 space-y-4 border-2 border-gray-300 border-dashed rounded-md">
                    <div className="px-2 italic text-gray-500 text-center">
                      {`There are no ${
                        enumTranslates[EntityType.Portfolio]
                      }s in this ${enumTranslates[EntityType.Workspace]}`}
                    </div>
                    {/* {workspacePageState?.selectedWorkspace && (
                      <WizardButton
                        title={translate(
                          strings.WIZARD_NEW_PORTFOLIO,
                          'Portfolio Setup'
                        )}
                        editObject={null}
                        parentObject={workspacePageState?.selectedWorkspace}
                        type="PORTFOLIO_DETAILS"
                        startStep={1}
                      />
                    )} */}
                  </div>
                ) : (
                  MyPortfolioList?.map(portfolio => (
                    <React.Fragment key={portfolio.id}>
                      <div
                        className={classNames(
                          portfolio.id == SelectedPortfolioId
                            ? 'bg-primary-500 text-white hover:bg-primary-600'
                            : 'text-black hover:bg-gray-100',
                          'flex flex-row justify-between px-2 py-1 rounded cursor-pointer items-center'
                        )}
                        onClick={() => handlePortfolioClick(portfolio.id)}
                      >
                        <span className="flex-grow">{portfolio.name}</span>
                        {portfolio.id == SelectedPortfolioId ? (
                          <Link to={`${PORTFOLIO}/${portfolio.id}`}>
                            <span
                              className={classNames(
                                portfolio.id == SelectedPortfolioId
                                  ? 'bg-primary-500 text-white hover:bg-primary-700 rounded pl-2 pr-1'
                                  : 'text-black hover:bg-gray-200 rounded-full px-1',
                                'flex items-center text-sm py-1'
                              )}
                            >
                              <span
                                className={classNames(
                                  portfolio.id == SelectedPortfolioId
                                    ? ''
                                    : 'hidden',
                                  ''
                                )}
                              >
                                View
                              </span>

                              <span
                                className={`rounded-full ${
                                  portfolio.id == SelectedPortfolioId
                                    ? 'pl-1 xl:pl-2'
                                    : ''
                                }`}
                              >
                                {iconCaretRight}
                              </span>
                            </span>
                          </Link>
                        ) : (
                          <span
                            className={`rounded-full mx-1 my-1 text-gray-500 ${
                              portfolio.id == SelectedPortfolioId ? 'pl-2' : ''
                            }`}
                          >
                            {iconCaretRight}
                          </span>
                        )}
                      </div>

                      {/* Show programs in portfolio if portfolio is selected */}
                      {portfolio.id === SelectedPortfolioId ? (
                        <React.Fragment>
                          {/* Programs */}
                          <div className="flex flex-col border-t pl-4 pr-0 py-1 align-top w-full space-y-1">
                            {SelectedPortfolioId != '' &&
                            MyProgramsList?.filter(
                              program =>
                                program.portfolio.id == SelectedPortfolioId
                            ).length == 0 ? (
                              // Show empty state
                              <div className="flex flex-col items-center py-4 space-y-4 border-2 border-gray-300 border-dashed rounded-md">
                                <div className="px-2 italic text-gray-500 text-center">
                                  {`There are no ${
                                    enumTranslates[EntityType.Program]
                                  }s in this ${
                                    enumTranslates[EntityType.Portfolio]
                                  }`}
                                </div>
                                {/* {MyPortfolioList?.find(
                                  portfolio =>
                                    portfolio.id === SelectedPortfolioId
                                ) && (
                                  <WizardButton
                                    title={translate(
                                      strings.WIZARD_NEW_PROGRAM,
                                      'Program Setup'
                                    )}
                                    editObject={null}
                                    parentObject={MyPortfolioList?.find(
                                      portfolio =>
                                        portfolio.id === SelectedPortfolioId
                                    )}
                                    type="PROGRAM_DETAILS"
                                    startStep={1}
                                  />
                                )} */}
                              </div>
                            ) : (
                              // Show list of programs in selected portfolio
                              MyProgramsList?.filter(
                                program =>
                                  program.portfolio.id == SelectedPortfolioId
                              ).map(program => (
                                <React.Fragment key={program.id}>
                                  <div
                                    className={classNames(
                                      program.id == SelectedProgramId
                                        ? 'bg-primary-500 text-white hover:bg-primary-600'
                                        : 'text-black hover:bg-gray-100',
                                      'flex flex-row justify-between px-2 py-1 rounded cursor-pointer items-center'
                                    )}
                                    onClick={() =>
                                      handleProgramClick(program.id)
                                    }
                                  >
                                    <span className="flex-grow">
                                      {program.name}
                                    </span>

                                    {/* Allow link entry to program if we are in demo mode 
                      or if the user is a program member */}
                                    {program.id == SelectedProgramId ? (
                                      <Link
                                        to={`${PROGRAM}/${program.id}#performance`}
                                      >
                                        <span
                                          className={classNames(
                                            program.id == SelectedProgramId
                                              ? 'bg-primary-500 text-white hover:bg-primary-700 rounded pl-2 pr-1'
                                              : 'text-black hover:bg-gray-200 rounded-full px-1',
                                            'flex items-center text-sm py-1'
                                          )}
                                        >
                                          <span
                                            className={classNames(
                                              program.id == SelectedProgramId
                                                ? ''
                                                : 'hidden',
                                              ''
                                            )}
                                          >
                                            View
                                          </span>

                                          <span
                                            className={`rounded-full ${
                                              program.id == SelectedProgramId
                                                ? 'pl-1 xl:pl-2'
                                                : ''
                                            }`}
                                          >
                                            {iconCaretRight}
                                          </span>
                                        </span>
                                      </Link>
                                    ) : (
                                      <span
                                        className={`rounded-full mx-1 my-1 text-gray-500 ${
                                          program.id == SelectedProgramId
                                            ? 'pl-2'
                                            : ''
                                        }`}
                                      >
                                        {iconCaretRight}
                                      </span>
                                    )}
                                  </div>

                                  {/* Show projects if selected */}
                                  {program.id == SelectedProgramId ? (
                                    <React.Fragment>
                                      {/* Projects */}
                                      <div className="flex flex-col pl-4 pr-0 py-0 align-top w-full space-y-1">
                                        {SelectedProgramId != '' &&
                                        MyProjectsList?.filter(
                                          project =>
                                            project.program.id ==
                                            SelectedProgramId
                                        ).length == 0 ? (
                                          // Show empty state
                                          <div className="flex flex-col items-center py-4 space-y-4 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className="px-2 italic text-gray-500 text-center">
                                              {`There are no ${
                                                enumTranslates[
                                                  EntityType.Project
                                                ]
                                              }s in this ${
                                                enumTranslates[
                                                  EntityType.Program
                                                ]
                                              }`}
                                            </div>
                                            {/* {MyProgramsList?.find(
                                              program =>
                                                program.id === SelectedProgramId
                                            ) && (
                                              <WizardButton
                                                title={translate(
                                                  strings.WIZARD_NEW_PROJECT,
                                                  'Project Setup'
                                                )}
                                                editObject={null}
                                                parentObject={MyProgramsList?.find(
                                                  program =>
                                                    program.id ===
                                                    SelectedProgramId
                                                )}
                                                type="PROJECT_DETAILS"
                                                startStep={1}
                                              />
                                            )} */}
                                          </div>
                                        ) : (
                                          // Show list of projects in selected program
                                          MyProjectsList?.filter(
                                            project =>
                                              project.program.id ==
                                              SelectedProgramId
                                          ).map(project => (
                                            <div
                                              className={classNames(
                                                project.id == SelectedProjectId
                                                  ? 'bg-primary-500 text-white hover:bg-primary-600'
                                                  : 'text-black hover:bg-gray-100',
                                                'flex flex-row justify-between px-2 py-1 rounded cursor-pointer items-center'
                                              )}
                                              key={project.id}
                                              onClick={() =>
                                                handleProjectClick(project.id)
                                              }
                                            >
                                              <span className="flex-grow">
                                                {project.name}
                                              </span>

                                              {/* Allow link entry to project if we are in demo mode 
                      or if the user is a program/project member */}
                                              {project.id ==
                                              SelectedProjectId ? (
                                                <Link
                                                  to={`${PROJECT}/${project.id}`}
                                                >
                                                  <span
                                                    className={classNames(
                                                      project.id ==
                                                        SelectedProjectId
                                                        ? 'bg-primary-500 text-white hover:bg-primary-700 rounded pl-2 pr-1'
                                                        : 'text-black hover:bg-gray-200 rounded-full px-1',
                                                      'flex items-center text-sm py-1'
                                                    )}
                                                  >
                                                    <span
                                                      className={classNames(
                                                        project.id ==
                                                          SelectedProjectId
                                                          ? ''
                                                          : 'hidden',
                                                        ''
                                                      )}
                                                    >
                                                      View
                                                    </span>

                                                    <span
                                                      className={`rounded-full ${
                                                        project.id ==
                                                        SelectedProjectId
                                                          ? 'pl-1 xl:pl-2'
                                                          : ''
                                                      }`}
                                                    >
                                                      {iconCaretRight}
                                                    </span>
                                                  </span>
                                                </Link>
                                              ) : (
                                                <span
                                                  className={`rounded-full mx-1 my-1 text-gray-500 ${
                                                    project.id ==
                                                    SelectedProjectId
                                                      ? 'pl-2'
                                                      : ''
                                                  }`}
                                                >
                                                  {iconCaretRight}
                                                </span>
                                              )}
                                            </div>
                                          ))
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ) : null}
                                </React.Fragment>
                              ))
                            )}
                          </div>
                        </React.Fragment>
                      ) : null}
                    </React.Fragment>
                  ))
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Desktop */}
      <div className="hidden md:block overflow-hidden border border-gray-200 rounded-lg">
        <table className="table w-full divide-y divide-gray-200 table-fixed">
          <thead className="table-header-group bg-gray-50">
            <tr className="table-row">
              <th className="table-cell w-1/3 align-middle px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${enumTranslates[EntityType.Portfolio]}s`}
              </th>
              <th className="table-cell w-1/3 align-middle px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${enumTranslates[EntityType.Program]}s`}
              </th>
              <th className="table-cell w-1/3 align-middle px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {`${enumTranslates[EntityType.Project]}s`}
              </th>
            </tr>
          </thead>

          <tbody className="table-row-group bg-white text-sm divide-y divide-gray-200">
            <tr className="table-row border-t border-gray-200">
              {/* Portfolios */}
              <td className="table-cell border-t border-gray-200 p-1 align-top">
                {MyPortfolioList?.length == 0 ? (
                  <div className="flex flex-col items-center py-4 space-y-4">
                    <div className="px-2 italic text-gray-500 text-center">
                      {`There are no ${
                        enumTranslates[EntityType.Portfolio]
                      }s in this ${enumTranslates[EntityType.Workspace]}.`}
                    </div>
                    {/* {workspacePageState?.selectedWorkspace && (
                      <WizardButton
                        title={translate(
                          strings.WIZARD_NEW_PORTFOLIO,
                          'Portfolio Setup'
                        )}
                        editObject={null}
                        parentObject={workspacePageState?.selectedWorkspace}
                        type="PORTFOLIO_DETAILS"
                        startStep={1}
                      />
                    )} */}
                  </div>
                ) : (
                  MyPortfolioList?.map(portfolio => (
                    <div
                      className={classNames(
                        portfolio.id == SelectedPortfolioId
                          ? 'bg-primary-500 text-white hover:bg-primary-600'
                          : 'text-black hover:bg-gray-100',
                        'flex flex-row justify-between px-2 py-1 rounded cursor-pointer items-center'
                      )}
                      key={portfolio.id}
                      onClick={() => handlePortfolioClick(portfolio.id)}
                    >
                      <p
                        className={`${
                          portfolio.id == SelectedPortfolioId ? '' : 'truncate'
                        } mr-2`}
                      >
                        {portfolio.name}
                      </p>

                      {portfolio.id == SelectedPortfolioId ? (
                        <Link to={`${PORTFOLIO}/${portfolio.id}`}>
                          <span
                            className={classNames(
                              portfolio.id == SelectedPortfolioId
                                ? 'bg-primary-500 text-white hover:bg-primary-700 rounded-full xl:rounded xl:pl-2 pr-1'
                                : 'text-black hover:bg-gray-200 rounded-full px-1',
                              'flex items-center text-sm py-1'
                            )}
                          >
                            <span
                              className={classNames(
                                portfolio.id == SelectedPortfolioId
                                  ? ''
                                  : 'hidden',
                                'hidden xl:block'
                              )}
                            >
                              View
                            </span>

                            <span
                              className={`rounded-full ${
                                portfolio.id == SelectedPortfolioId
                                  ? 'pl-1 xl:pl-2'
                                  : ''
                              }`}
                            >
                              {iconCaretRight}
                            </span>
                          </span>
                        </Link>
                      ) : (
                        <span
                          className={`rounded-full mx-1 my-1 text-gray-500 ${
                            portfolio.id == SelectedPortfolioId ? 'pl-2' : ''
                          }`}
                        >
                          {iconCaretRight}
                        </span>
                      )}
                    </div>
                  ))
                )}
              </td>

              {/* Programs */}
              <td className="table-cell border-t border-l border-r border-gray-200 p-1 align-top">
                {SelectedPortfolioId != '' &&
                MyProgramsList?.filter(
                  program => program.portfolio.id == SelectedPortfolioId
                ).length == 0 ? (
                  // Show empty state
                  <div className="flex flex-col items-center py-4 space-y-4">
                    <div className="px-2 italic text-gray-500 text-center">
                      {`There are no ${
                        enumTranslates[EntityType.Program]
                      }s in this ${enumTranslates[EntityType.Portfolio]}.`}
                    </div>
                    {/* {MyPortfolioList?.find(
                      portfolio => portfolio.id === SelectedPortfolioId
                    ) && (
                      <WizardButton
                        title={translate(
                          strings.WIZARD_NEW_PROGRAM,
                          'Program Setup'
                        )}
                        editObject={null}
                        parentObject={MyPortfolioList?.find(
                          portfolio => portfolio.id === SelectedPortfolioId
                        )}
                        type="PROGRAM_DETAILS"
                        startStep={1}
                      />
                    )} */}
                  </div>
                ) : (
                  // Show list of programs in selected portfolio
                  MyProgramsList?.filter(
                    program => program.portfolio.id == SelectedPortfolioId
                  ).map(program => (
                    <div
                      className={classNames(
                        program.id == SelectedProgramId
                          ? 'bg-primary-500 text-white hover:bg-primary-600'
                          : 'text-black hover:bg-gray-100',
                        'flex flex-row justify-between px-2 py-1 rounded cursor-pointer items-center'
                      )}
                      key={program.id}
                      onClick={() => handleProgramClick(program.id)}
                    >
                      <p
                        className={`${
                          program.id == SelectedProgramId ? '' : 'truncate'
                        } mr-2`}
                      >
                        {program.name}
                      </p>

                      {/* Allow link entry to program if we are in demo mode 
                      or if the user is a program member */}
                      {program.id == SelectedProgramId ? (
                        <Link to={`${PROGRAM}/${program.id}#performance`}>
                          <span
                            className={classNames(
                              program.id == SelectedProgramId
                                ? 'bg-primary-500 text-white hover:bg-primary-700 rounded-full xl:rounded xl:pl-2 pr-1'
                                : 'text-black hover:bg-gray-200 rounded-full px-1',
                              'flex items-center text-sm py-1'
                            )}
                          >
                            <span
                              className={classNames(
                                program.id == SelectedProgramId ? '' : 'hidden',
                                'hidden xl:block'
                              )}
                            >
                              View
                            </span>

                            <span
                              className={`rounded-full ${
                                program.id == SelectedProgramId
                                  ? 'pl-1 xl:pl-2'
                                  : ''
                              }`}
                            >
                              {iconCaretRight}
                            </span>
                          </span>
                        </Link>
                      ) : (
                        <span
                          className={`rounded-full mx-1 my-1 text-gray-500 ${
                            program.id == SelectedProgramId ? 'pl-2' : ''
                          }`}
                        >
                          {iconCaretRight}
                        </span>
                      )}
                    </div>
                  ))
                )}
              </td>

              {/* Projects */}
              <td className="table-cell border-t border-gray-200 p-1 align-top">
                {SelectedProgramId != '' &&
                MyProjectsList?.filter(
                  project => project.program.id == SelectedProgramId
                ).length == 0 ? (
                  // Show empty state
                  <div className="flex flex-col items-center py-4 space-y-4">
                    <div className="px-2 italic text-gray-500 text-center">
                      {`There are no ${
                        enumTranslates[EntityType.Project]
                      }s in this ${enumTranslates[EntityType.Program]}.`}
                    </div>
                    {/* {MyProgramsList?.find(
                      program => program.id === SelectedProgramId
                    ) && (
                      <WizardButton
                        title={translate(
                          strings.WIZARD_NEW_PROJECT,
                          'Project Setup'
                        )}
                        editObject={null}
                        parentObject={MyProgramsList?.find(
                          program => program.id === SelectedProgramId
                        )}
                        type="PROJECT_DETAILS"
                        startStep={1}
                      />
                    )} */}
                  </div>
                ) : (
                  // Show list of projects in selected program
                  MyProjectsList?.filter(
                    project => project.program.id == SelectedProgramId
                  ).map(project => (
                    <div
                      className={classNames(
                        project.id == SelectedProjectId
                          ? 'bg-primary-500 text-white hover:bg-primary-600'
                          : 'text-black hover:bg-gray-100',
                        'flex flex-row justify-between px-2 py-1 rounded cursor-pointer items-center'
                      )}
                      key={project.id}
                      onClick={() => handleProjectClick(project.id)}
                    >
                      <p
                        className={`${
                          project.id == SelectedProjectId ? '' : 'truncate'
                        } mr-2`}
                      >
                        {project.name}
                      </p>

                      {/* Allow link entry to project if we are in demo mode 
                      or if the user is a program/project member */}
                      {project.id == SelectedProjectId ? (
                        <Link to={`${PROJECT}/${project.id}`}>
                          <span
                            className={classNames(
                              project.id == SelectedProjectId
                                ? 'bg-primary-500 text-white hover:bg-primary-700 rounded-full xl:rounded xl:pl-2 pr-1'
                                : 'text-black hover:bg-gray-200 rounded-full px-1',
                              'flex items-center text-sm py-1'
                            )}
                          >
                            <span
                              className={classNames(
                                project.id == SelectedProjectId ? '' : 'hidden',
                                'hidden xl:block'
                              )}
                            >
                              View
                            </span>

                            <span
                              className={`rounded-full ${
                                project.id == SelectedProjectId
                                  ? 'pl-1 xl:pl-2'
                                  : ''
                              }`}
                            >
                              {iconCaretRight}
                            </span>
                          </span>
                        </Link>
                      ) : (
                        <span
                          className={`rounded-full mx-1 my-1 text-gray-500 ${
                            project.id == SelectedProjectId ? 'pl-2' : ''
                          }`}
                        >
                          {iconCaretRight}
                        </span>
                      )}
                    </div>
                  ))
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );

  const directoryContent = (
    <>
      {loadingPrograms || loadingProjects || loadingPortfolios ? (
        <div className="flex flex-col align-middle items-center">
          <Spinner text={'Loading data...'} />
        </div>
      ) : errorPrograms || errorProjects || errorPortfolios ? (
        <>
          <div className="text-center">
            {icon}
            <h3 className="mt-2 text-sm font-medium text-gray-900">
              Something isn't quite right...
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {errorPrograms?.message ||
                errorProjects?.message ||
                errorPortfolios?.message}
            </p>
          </div>
        </>
      ) : (
        directoryList
      )}
    </>
  );

  return (
    <div className="flex flex-col gap-y-6">
      <DetailsPanel
        headerLeft={directoryTitle}
        headerRight={directoryButton}
        content={directoryContent}
      />
    </div>
  );
}

export default DirectoryList;
