import * as React from 'react';
import { useCallback, useContext } from 'react';

import {
  EntityType,
  useSetLastViewedWorkspaceMutation,
  Workspace,
} from '../../api/index';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { enumTranslates } from '../../common/i18n/translate';

export default function WorkspaceSelector(): React.ReactElement {
  const { state: workspacePageState, dispatch } =
    useContext(WorkspacePageContext);
  const [setLastViewedWorkspace] = useSetLastViewedWorkspaceMutation();

  const handleworkspaceChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (e: any) => {
      const selectedworkspaceId = e.target.value;
      const selectedWorkspace = workspacePageState.workspaces.find(
        w => w.id === selectedworkspaceId
      );
      if (selectedWorkspace) {
        try {
          await setLastViewedWorkspace({
            variables: {
              workspaceId: selectedWorkspace.id,
            },
          });
          dispatch({
            type: 'SET_SELECTED_WORKSPACE',
            workspace: selectedWorkspace,
          });
        } catch (e) {
          toast.error((e as ApolloError).message);
        }
      }
    },
    [dispatch, setLastViewedWorkspace, workspacePageState.workspaces]
  );

  return (
    <div>
      <select
        className="mt-1 block w-min pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
        onChange={handleworkspaceChange}
        value={''}
      >
        <option
          key={`workspace_switch`}
          value={''}
          disabled
          className="bg-white hidden"
        >
          {`Switch ${enumTranslates[EntityType.Workspace]}...`}
        </option>
        {workspacePageState?.workspaces?.map((workspace: Workspace) => {
          return (
            <option
              className="text-sm bg-white"
              key={workspace.id}
              value={workspace.id}
            >
              {workspace.name}
              {/* {workspace.id === workspacePageState.userWorkspace &&
                ' (My Workspace)'} */}
            </option>
          );
        })}
      </select>
    </div>
  );
}
