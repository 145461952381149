import React from 'react';
import SlideOver from '../../common/slideovers/SlideOver';
import RelationshipSlideOverContent from './RelationshipSlideOverContent';

export type RelatedEntity = {
  id: string;
  name: string;
  confidenceRating: 'High' | 'Medium' | 'Low';
  // TOOD: Add mutual action plan touchpoints
};

type RelationshipSlideOverProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  titleUrl: string;
  description: string;
  startDate: string;
  endDate: string;
  enablers: RelatedEntity[];
  dependents: RelatedEntity[];
};

type PerformanceSlideOverMetaProps = {
  description: string;
};

const RelationshipSlideOverMeta = ({
  description,
}: PerformanceSlideOverMetaProps) => <div>{description}</div>;

export default function RelationshipSlideOver({
  open,
  setOpen,
  title,
  titleUrl,
  description,
  startDate,
  endDate,
  enablers,
  dependents,
}: RelationshipSlideOverProps): React.ReactElement {
  // Note: In this case it's a very simple meta component
  // For other slideovers, we may have more complex layouts

  return (
    <React.Fragment>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={title}
        titleUrl={titleUrl}
        titleMetadata={<RelationshipSlideOverMeta description={description} />}
        content={
          <RelationshipSlideOverContent
            startDate={startDate}
            endDate={endDate}
            enablers={enablers}
            thisEntityName={title}
            dependents={dependents}
          />
        }
      />
    </React.Fragment>
  );
}
