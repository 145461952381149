import React from "react";
import { EditIcon } from "../../basic/ButtonIcons";
import BasicButton from "../../basic/BasicButton";
import DotMenu, { MenuOption } from "../../basic/DotMenu";

export type AboutActionHeaderProps = {
  onClick: () => void;
  disabled: boolean;
  isVisible: boolean;
  menuOptions: Array<MenuOption>;
};

const AboutActionHeader = ({
  onClick,
  disabled,
  isVisible,
  menuOptions,
}: AboutActionHeaderProps) => {
  const label = "Edit";

  return isVisible ? (
    <div className="flex items-center">
      <span className="flex flex-wrap">
        <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
          <span className="sm:ml-3">
            <BasicButton
              icon={<EditIcon />}
              label={label}
              onClick={onClick}
              disabled={disabled}
            />
          </span>
          <div className="flex items-center group relative">
            <DotMenu menuOptions={menuOptions} />
          </div>
        </div>
      </span>
    </div>
  ) : (
    <></>
  );
};

export default AboutActionHeader;
