import React from 'react';
import Tag from '../../basic/Tag';
import { TriangleDown } from '../../northstar/PerformanceSlideOver/PerformanceSlideOverTrajectoryTab';
import WaypointMarker from './WaypointMarker';
import { WaypointType } from './WaypointSelector';
import { getLocalDate } from '../../../utils/utils';

type WaypointTileProps = {
  id: string;
  isSelected: boolean;
  onClick: () => void;
  date: Date;
  type: WaypointType;
  description: string;
  isCurrentFocus: boolean;
  isBeforeCurrent: boolean;
};

export default function WaypointTile({
  id,
  isSelected,
  onClick,
  date,
  type,
  description,
  isCurrentFocus,
  isBeforeCurrent,
}: WaypointTileProps): React.ReactElement {
  const tileStyle = () => {
    let style = '';

    if (isBeforeCurrent && !isSelected) {
      style = 'bg-gray-100 shadow-inner hover:shadow';
    } else if (isBeforeCurrent && isSelected) {
      style = 'bg-gray-100 border-gray-200 shadow';
    } else if (!isBeforeCurrent && !isSelected) {
      style = 'bg-white border-gray-200 hover:shadow';
    } else {
      style = 'bg-white border-gray-200 shadow';
    }

    return style;
  };

  return (
    <button
      type="button"
      key={id}
      className={`relative w-40 h-48 shrink-0 px-2 py-4 flex flex-col items-center rounded-lg text-center cursor-pointer border mx-2.5 ${tileStyle()}`}
      onClick={onClick}
    >
      {/* Show the waypoint contents */}
      <div className="space-y-2">
        {/* Date */}
        <div
          className={`text-sm ${
            isSelected ? 'font-semibold' : 'font-medium text-gray-500'
          }`}
        >
          {getLocalDate(date)}
        </div>

        {/* Marker */}
        <div className={`text-xs flex justify-center py-1`}>
          <WaypointMarker id={id} type={type} isFaded={isBeforeCurrent} />
        </div>

        {/* Description */}
        <div
          className={`text-sm font-semibold overflow-y-hidden h-20 overflow-ellipsis ${
            isBeforeCurrent ? 'text-gray-400' : ''
          } ${!description && 'text-gray-400 italic'}`}
        >
          {description || 'No description provided'}
        </div>
      </div>

      {/* Make the next upcoming waypoint obvious */}
      {isCurrentFocus && (
        <div className="absolute -top-2.5">
          <Tag
            type="custom"
            bgColour="bg-indigo-500"
            textColour="text-white"
            label="Next waypoint"
          />
        </div>
      )}

      {/* Selection indicator */}
      <div className="absolute -bottom-5">
        <TriangleDown width={20} visible={isSelected} />
      </div>
    </button>
  );
}
