import * as React from 'react';
import { useCallback, useMemo, useState, useContext } from 'react';
import * as WizardActions from './actions/index';
import Wizard from './Wizard';
import { WizardOptions } from './WizardContext';
import Modal from '../Modal';

import { UserContext } from '../../context/UserContext';
import { DemoContext } from '../../context/DemoContext';

import { getInitialPayload } from './payloads/getInitialPayload';
import Spinner from '../SpinnerThemed';
// import PrimaryButton from '../PrimaryButton';
// import SecondaryButton from '../SecondaryButton';
import { PencilSimple } from 'phosphor-react';
// import { enumTranslates } from '../i18n/translate';
// import { EntityType } from '../../api/index';
import WorkspaceWizardLayout from '../../components/workspace/WorkspaceWizardLayout';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import EntitySetupStep0 from '../../components/entity/setupWizard/EntitySetupStep0';

export type WizardType =
  | 'WORKSPACE_DETAILS'
  | 'ENTITY_DETAILS'
  | 'PORTFOLIO_DETAILS' // Parent: Workspace
  | 'PROGRAM_DETAILS' // Parent: Portfolio
  | 'PROGRAM_QUICK_ADD' // Parent: Portfolio
  | 'PORTFOLIO_UPDATE' // Parent: Portfolio
  | 'PROGRAM_UPDATE' // Parent: Program
  | 'PROJECT_DETAILS' // Parent: Program
  | 'PROJECT_QUICK_ADD' // Parent: Program
  | 'PROJECT_UPDATE' // Parent: Project
  | 'PROJECT_ASK'; // Parent: Project; // Parent: BillingAccount

type Props = {
  title: string;
  editObject: { [key: string]: unknown } | null;
  parentObject: { [key: string]: unknown } | undefined | null;
  topLevelEntity: { [key: string]: unknown } | undefined | null;
  type: WizardType;
  startStep: number;
  quickSave?: boolean;
  rowId?: string;
  workspaceId: string;
  disabled?: boolean;
};

function ButtonStyle(
  type: WizardType,
  editObject: { [key: string]: unknown } | null,
  handleButtonClick: () => void
) {
  const { isDemo } = useContext(DemoContext);
  switch (type) {
    case 'ENTITY_DETAILS':
      return editObject == null ? (
        // Create new portfolio button
        <PrimaryButton
          icon="plus"
          label={`New Entity`}
          onClick={handleButtonClick}
          disabled={isDemo}
        />
      ) : (
        // Edit existing entity button
        <SecondaryButton
          icon="edit"
          label="Edit"
          onClick={handleButtonClick}
          disabled={isDemo}
        />
      );

    case 'WORKSPACE_DETAILS':
      return (
        <PencilSimple
          className="hover:text-gray-600 cursor-pointer -ml-0.5 mr-2 h-5 w-5 text-gray-500"
          weight="bold"
          aria-hidden="true"
          onClick={handleButtonClick}
        />
      );
  }
}

export default function OpenWizardButton({
  title,
  editObject,
  parentObject,
  topLevelEntity,
  type,
  startStep,
  quickSave,
  rowId,
  disabled,
  workspaceId,
}: Props): React.ReactElement {
  const [showWizard, setShowWizard] = useState<boolean>(false);
  const { user, profile } = useContext(UserContext);

  // Handle modal state
  const handleButtonClick = useCallback(() => {
    setShowWizard(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setShowWizard(false);
  }, []);

  const { onSubmit, response } =
    // editObject !== null
    //   ? "WizardActions[type].useEdit()"
    WizardActions[type].useCreate();

  const wizardOptions: Partial<WizardOptions> = useMemo(() => {
    return {
      title: title,
      editObject: editObject,
      parentObject: parentObject,
      topLevelEntity: topLevelEntity,
      isEditing: editObject != null,
      quickSave: quickSave,
      // TODO: [On hold] Jump to this step in the wizard when first opened
      startAtStep: startStep,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editObject, parentObject, startStep]);

  const wizardSteps = useMemo(() => {
    // We likely need a different step that updates the expected set of fields for clicking the edit button (1)
    switch (type) {
      case 'WORKSPACE_DETAILS':
        return [WorkspaceWizardLayout];
      case 'ENTITY_DETAILS':
        return [EntitySetupStep0];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editObject?.measures, type]);

  const initialPayload = useMemo(() => {
    // (1) and then we would update this return object to contain the existing values for those fields
    // values here correspond to the "key" in the input components
    return getInitialPayload(
      type,
      editObject,
      parentObject,
      user,
      topLevelEntity,
      profile,
      workspaceId,
      rowId
    );
  }, [
    type,
    editObject,
    parentObject,
    user,
    topLevelEntity,
    profile,
    workspaceId,
    rowId,
  ]);

  return (
    <React.Fragment>
      {ButtonStyle(type, editObject, handleButtonClick)}
      {showWizard ? (
        <Modal onClose={handleModalClose} width="w-full">
          {response.loading === true ? (
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <Spinner text={'Saving...'} />
            </div>
          ) : (
            <div />
          )}
          <Wizard
            initialPayload={initialPayload}
            onSubmit={onSubmit}
            options={wizardOptions}
            steps={wizardSteps}
          />
        </Modal>
      ) : null}
    </React.Fragment>
  );
}
