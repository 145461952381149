import React from 'react';

import {
  EntityType,
  Notification,
  useReadNotificationMutation,
} from '../../api/index';
import { Article, CaretRight } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { PORTFOLIO, PROGRAM, PROJECT, WORKSPACE } from '../../common/routes';
import { enumTranslates } from '../../common/i18n/translate';
import { getRelativeTime } from '../../common/utils';

type NotificationItemProps = {
  notification: Notification;
  refresh: () => void;
};

function NotificationItem({
  notification,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refresh,
}: NotificationItemProps): React.ReactElement {
  const [view, { loading, error }] = useReadNotificationMutation({
    variables: {
      id: notification.id,
    },
  });
  const navigate = useNavigate();

  const _view = async () => {
    try {
      if (!notification.read) {
        await view();
      }
      if (notification.entityType === EntityType.Program) {
        navigate(`${PROGRAM}/${notification.entityId}`);
      } else if (notification.entityType === EntityType.Project) {
        navigate(`${PROJECT}/${notification.entityId}`);
      } else if (notification.entityType === EntityType.Portfolio) {
        navigate(`${PORTFOLIO}/${notification.entityId}`);
      } else {
        navigate(`${WORKSPACE}`);
      }
      //refresh();
    } catch (err) {
      console.error(err);
    }
  };

  if (loading) {
    return <span>Loading...</span>;
  }

  if (error) {
    return <span>Error: `${error.message}`</span>;
  }

  function getNotificationIcon(read: boolean) {
    const icon = (
      <Article
        className={`flex-shrink-0 h-6 w-6 ${
          read ? 'text-gray-400' : 'text-primary-600'
        }`}
        weight="duotone"
        aria-hidden="true"
      />
    );

    return icon;
  }

  return (
    <button
      onClick={() => _view()}
      className={`px-6 py-3 w-full flex items-start hover:bg-gray-200 transition ease-in-out duration-150 ${
        !notification.read ? 'bg-gray-100' : ''
      }`}
    >
      {getNotificationIcon(notification.read)}
      <div className="ml-3 pt-0.5 text-left">
        <p
          className={`text-sm ${
            notification.read ? 'text-gray-500' : 'font-medium text-gray-900'
          }`}
        >
          {notification.message} ({getRelativeTime(notification.createdAt)})
        </p>
        <div className="flex items-center mt-1 text-sm text-primary-600">
          <p className="">
            {`Go to ${enumTranslates[notification.entityType]}`}
          </p>
          <CaretRight
            weight="bold"
            size={14}
            className="flex-shrink-0 ml-1 mt-0.5"
          />
        </div>
      </div>
    </button>
  );
}

export default NotificationItem;
