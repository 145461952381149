import React from "react";
import { AddIcon } from "../../basic/ButtonIcons";
import BasicButton from "../../basic/BasicButton";

export type RelationshipsActionHeaderProps = {
  onClick: () => void;
  disabled: boolean;
  isVisible: boolean;
};

const RelationshipsActionHeader = ({
  onClick,
  disabled,
  isVisible,
}: RelationshipsActionHeaderProps) => {
  const label = "Add relationship";

  return isVisible ? (
    <div className="flex items-center">
      <span className="flex flex-wrap">
        <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
          <span className="sm:ml-3">
            <BasicButton
              icon={<AddIcon />}
              label={label}
              onClick={onClick}
              disabled={disabled}
            />
          </span>
          {/* <div className="flex items-center group relative">
            <DotMenu menuOptions={menuOptions} />
          </div> */}
        </div>
      </span>
    </div>
  ) : (
    <></>
  );
};

export default RelationshipsActionHeader;
