import React, { useContext } from 'react';
import { ProgramMeasure, StrategicObjective } from '../../../api/index';
import { ProgramPageContext } from '../ProgramPageContext';
import {
  capitaliseFirstLetter,
  classNames,
  getLocalDate,
  getRelativeTime,
  UnlinkedTag,
} from '../../../common/utils';
import translate, { strings } from '../../../common/i18n/translate';
import ReactTooltip from 'react-tooltip';

function MeasuresTable(): React.ReactElement {
  const { state: programPageState } = useContext(ProgramPageContext);

  function getProgramMeasureById(id: string): ProgramMeasure {
    const foundMeasure = programPageState.selectedProgram?.measures?.find(
      measure => measure.id == id
    );
    if (foundMeasure == null) {
      throw new Error('Program Measure not found');
    }
    return foundMeasure;
  }

  function getProgramMeasuresByObjective(
    objective: StrategicObjective | null
  ): ProgramMeasure[] {
    let measures: ProgramMeasure[] = [];

    if (objective) {
      // Get measures that are mapped to this objective
      measures =
        programPageState.selectedProgram?.measures?.filter(
          (measure: ProgramMeasure) =>
            measure.strategicObjectiveId == objective.id
        ) || [];
    } else {
      // Get unmapped measures
      measures =
        programPageState.selectedProgram?.measures?.filter(
          (measure: ProgramMeasure) =>
            !programPageState.selectedProgram?.portfolio.objectives?.some(
              m => m.id === measure.strategicObjectiveId
            )
        ) || [];
    }
    return measures;
  }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          {/* Updated table */}
          <div className="overflow-hidden border border-gray-200 rounded-lg">
            {/* Tailwind grid table style */}
            <table className="table min-w-full divide-y divide-gray-200">
              <thead className="table-header-group bg-gray-50">
                {/* Header row */}
                <tr className="table-row">
                  <th className="table-cell pl-4 pr-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-middle">
                    {translate(
                      strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                      'Strategic Objective'
                    )}
                  </th>
                  <th className="table-cell px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-middle">
                    {translate(strings.PROGRAM_MEASURES, 'Program Outcomes')}
                  </th>
                  <th className="table-cell px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-middle">
                    Target
                  </th>
                  <th className="table-cell pl-2 pr-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider align-middle">
                    Target Date
                  </th>
                </tr>
              </thead>

              {/* Table data */}
              <tbody className="table-row-group bg-white divide-y divide-gray-200 text-sm">
                {programPageState.selectedProgram?.portfolio?.objectives?.map(
                  (objective, themeIndex) =>
                    getProgramMeasuresByObjective(objective)?.map(
                      (measure, measureIndex) => (
                        // Create a row for each theme
                        <tr
                          key={`${themeIndex}-${measureIndex}`}
                          className="table-row"
                        >
                          {/* Portfolio objective */}
                          <td
                            rowSpan={
                              getProgramMeasuresByObjective(objective)?.length
                            }
                            className={classNames(
                              measureIndex == 0
                                ? 'border-t border-gray-200'
                                : 'hidden',
                              'table-cell pl-4 pr-4 py-3 whitespace-normal font-medium align-top'
                            )}
                          >
                            {measureIndex == 0 ? objective.description : ''}
                          </td>

                          {/* Program outcomes */}
                          <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                            <div className="text-sm">
                              {measure.id &&
                                getProgramMeasureById(measure.id).name}
                            </div>
                          </td>

                          {/* Target */}
                          <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                            <div className="text-sm">
                              {measure.id &&
                              getProgramMeasureById(measure.id).targetValue ? (
                                getProgramMeasureById(measure.id).targetValue
                              ) : (
                                <p className="italic text-gray-500">-</p>
                              )}
                            </div>
                          </td>

                          {/* Target date */}
                          <td className="table-cell pl-2 pr-4 py-3 whitespace-normal border-t border-gray-200 align-top">
                            {measure.id &&
                            getProgramMeasureById(measure.id).achieveByDate ? (
                              <div className="text-sm">
                                <p className="whitespace-nowrap">
                                  {measure.id &&
                                    getLocalDate(
                                      getProgramMeasureById(measure.id)
                                        .achieveByDate
                                    )}
                                </p>
                                <p className="text-xs text-gray-400 font-normal">
                                  {measure.id &&
                                    capitaliseFirstLetter(
                                      getRelativeTime(
                                        getProgramMeasureById(measure.id)
                                          .achieveByDate
                                      )
                                    )}
                                </p>
                              </div>
                            ) : (
                              <p className="text-sm italic text-gray-500">-</p>
                            )}
                          </td>
                        </tr>
                      )
                    )
                )}

                {/* Also show unmapped items */}
                {getProgramMeasuresByObjective(null)?.map(
                  (measure, measureIndex) => (
                    // Create a row for each theme
                    <div className="table-row">
                      {/* Portfolio objective */}
                      <td
                        rowSpan={getProgramMeasuresByObjective(null)?.length}
                        className={classNames(
                          measureIndex == 0
                            ? 'border-t border-gray-200'
                            : 'hidden',
                          'table-cell pl-4 pr-8 py-3 whitespace-normal align-top'
                        )}
                      >
                        {measureIndex == 0 ? (
                          <React.Fragment>
                            <UnlinkedTag />
                            <ReactTooltip
                              id={'icon-not-linked'}
                              place="top"
                              type="warning"
                              effect="solid"
                              // delayShow={tooltipDelay}
                            >
                              <div className="text-sm text-center not-italic">
                                <p>
                                  {` These aren't linked to a ${translate(
                                    strings.PORTFOLIO_STRATEGIC_OBJECTIVE,
                                    'Portfolio Objective'
                                  ).toLowerCase()} so they won't roll up.`}
                                </p>
                                <p>
                                  You can update this through the Program Setup
                                  wizard (click the Edit button above).
                                </p>
                              </div>
                            </ReactTooltip>
                          </React.Fragment>
                        ) : null}
                      </td>

                      {/* Program outcomes */}
                      <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                        <div className="text-sm">
                          {measure.id && getProgramMeasureById(measure.id).name}
                        </div>
                      </td>

                      {/* Target */}
                      <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                        <div className="text-sm">
                          {measure.id &&
                          getProgramMeasureById(measure.id).targetValue ? (
                            getProgramMeasureById(measure.id).targetValue
                          ) : (
                            <p className="italic text-gray-500">-</p>
                          )}
                        </div>
                      </td>

                      {/* Target date */}
                      <td className="table-cell px-2 py-3 whitespace-normal border-t border-gray-200 align-top">
                        {measure.id &&
                        getProgramMeasureById(measure.id).achieveByDate ? (
                          <div className="text-sm">
                            <p className="whitespace-nowrap">
                              {measure.id &&
                                getLocalDate(
                                  getProgramMeasureById(measure.id)
                                    .achieveByDate
                                )}
                            </p>
                            <p className="text-xs text-gray-400 font-normal">
                              {measure.id &&
                                capitaliseFirstLetter(
                                  getRelativeTime(
                                    getProgramMeasureById(measure.id)
                                      .achieveByDate
                                  )
                                )}
                            </p>
                          </div>
                        ) : (
                          <p className="text-sm italic text-gray-500">-</p>
                        )}
                      </td>
                    </div>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeasuresTable;
