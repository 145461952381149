import React, { useState, useEffect } from 'react';
import DetailsPanel from '../../common/DetailsPanel';
import DetailsPanelHeader from '../../common/DetailsPanelHeader';

import { AuthErrorType } from '../../common/types';

import { useUpdateMyWorkspaceMutation } from '../../api/index';
import { WorkspacePageContext } from './WorkspacePageContext';

type AppState = {
  plan: string;
  id: string;
  formType: string;
  errorText: string;
  changeSuccess: boolean;
};

function AccountSettings(): React.ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { state } = React.useContext(WorkspacePageContext);
  const initialFormState: AppState = {
    plan: state.billingAccount?.plan || '',
    id: '',
    formType: 'editProfile',
    errorText: '',
    changeSuccess: false,
  };
  const [validation, setValidation] = useState<boolean>(false);
  const [formState, updateFormState] = useState<AppState>(initialFormState);
  const [updateWorkspaceMutation, { loading }] = useUpdateMyWorkspaceMutation();
  const inputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    updateFormState(() => ({ ...formState, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const nonEmpty = formState.plan && formState.plan !== '' ? true : false;
    setValidation(nonEmpty);
    !nonEmpty
      ? (formState.errorText = '')
      : (formState.errorText = `Workspace plan cannot be empty`);
  }, [formState]);

  const change = async () => {
    const { plan } = formState;
    try {
      // const updateWorkspaceResp = await updateWorkspaceMutation({
      //   variables: {
      //     input: {
      //       id: formState.id,
      //       plan: formState.plan,
      //     },
      //   },
      // });
    } catch (error) {
      const errorT = error as AuthErrorType;
      updateFormState(() => ({
        ...formState,
        errorText: errorT.message,
      }));
    }
  };

  const AboutTitle = (
    <DetailsPanelHeader title={'Manage your billing account'} />
  );

  const workspaceChangeButton = (
    <div/>
    // <PrimaryButton
    //   icon="save"
    //   label="Update plan"
    //   onClick={() => change()}
    //   disabled={true}
    // />
  );

  const workspaceChangeContent = (
    <React.Fragment>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5 gap-y-3">
        <div className="col-span-1">
          <div className="w-full flex justify-between">
            <label
              htmlFor="plan"
              className="block text-sm font-semibold text-gray-900 mb-1"
            >
              Account Plan
            </label>
          </div>

          <input
            id="plan"
            name="plan"
            disabled
            type={'text'}
            value={formState.plan}
            onChange={inputChange}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <div className="flex flex-col space-y-6 max-w-3xl mx-auto">
      <DetailsPanel
        headerLeft={AboutTitle}
        headerRight={workspaceChangeButton}
        content={workspaceChangeContent}
      />
    </div>
  );
}

export default AccountSettings;
