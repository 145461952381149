/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useState } from 'react';
import {
  EntityType,
  Group,
  Portfolio,
  Program,
  ProgramMeasure,
  ProgramUpdateTrend,
  Project,
  ProjectMeasure,
  ProjectMeasureStatus,
  ProjectPriority,
  ProjectUpdateConfidence,
} from '../../../api/index';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import { PROGRAM, PROJECT } from '../../../common/routes';
import {
  addDays,
  getLatestProgramUpdate,
  getLatestProjectUpdate,
  getLocalDate,
  getPermissionFromTree,
  getUnlinkedProjectMeasures,
  sortPrograms,
  sortProjects,
} from '../../../common/utils';
import { CaretDown, CaretUp, CheckCircle, Target } from 'phosphor-react';
import {
  getParentProject,
  getStatusDot,
} from '../../program/PerformanceTab/HelperFunctions';
import ConfidenceBadge from '../../../common/ConfidenceBadge';
import PermissionLink from '../../../common/PermissionLink';
import { BetaContext } from '../../../context/BetaContext';
import OpenWizardButton from '../../../common/wizard/WizardButton';
import { UserContext } from '../../../context/UserContext';
import WorkflowModal, { WorkflowType } from './WorkflowModal';

type DashboardCardType =
  | 'OBJECTIVES_CURRENT_RISK'
  | 'OBJECTIVES_FUTURE_RISK'
  | 'INFLIGHT_PROJECT_RAG'
  | 'INFLIGHT_PROJECT_COUNT'
  | 'INFLIGHT_PROJECTS_BY_PRIORITY'
  | 'PORTFOLIO_BALANCE'
  | 'PROGRAMS_LOW_CONFIDENCE'
  | 'PROJECTS_LOW_CONFIDENCE'
  | 'PROGRAMS_NO_BENEFIT'
  | 'PROJECTS_NO_BENEFIT'
  | 'PROGRAM_BENEFITS_NOT_LINKED'
  | 'PROJECT_BENEFITS_NOT_LINKED';

type CardProps = {
  type: DashboardCardType;
  portfolio: Portfolio;
  programs: Program[];
  projects: Project[];
  chart?: React.ReactElement;
};

const iconStyle =
  'w-5 h-5 flex-shrink-0 text-gray-900 group-hover:text-primary-500 mr-1';
const iconExpand = <CaretDown className={iconStyle} />;
const iconContract = <CaretUp className={iconStyle} />;

const checkBadge = (
  <CheckCircle
    className={`w-7 h-7 flex-shrink-0 text-green-500`}
    weight="fill"
  />
);

const iconTarget = (
  <Target
    className="inline-block mr-1 flex-shrink-0 text-gray-500"
    weight="duotone"
    size={20}
  />
);

type DummyObjective = {
  id: string;
  status: ProjectMeasureStatus;
  name: string;
  target: string;
  achieveByDate: Date;
  programBenefits: DummyBenefit[];
};

type DummyBenefit = {
  status: ProjectMeasureStatus;
  name: string;
  target: string;
  achieveByDate: Date;
  program: string;
};

const dummyCurrentRisks: DummyObjective[] = [
  {
    id: '001',
    status: ProjectMeasureStatus.HelpNeeded,
    name: 'Annual recurring revenue',
    target: '$10M ARR',
    achieveByDate: new Date('31 Dec 2022'),
    programBenefits: [
      {
        status: ProjectMeasureStatus.Risk,
        name: 'Core platform sales',
        target: '$4M ARR',
        achieveByDate: new Date('30 Sep 2022'),
        program: 'Program Alpha',
      },
      {
        status: ProjectMeasureStatus.OnTrack,
        name: 'Implementation services',
        target: '$2M ARR',
        achieveByDate: new Date('30 Sep 2022'),
        program: 'Program Alpha',
      },
      {
        status: ProjectMeasureStatus.OnTrack,
        name: 'Add-on sales',
        target: '$1M ARR',
        achieveByDate: new Date('31 Dec 2022'),
        program: 'Program Alpha',
      },
      {
        status: ProjectMeasureStatus.OnTrack,
        name: 'New concept trials',
        target: '$500K',
        achieveByDate: new Date('31 Dec 2022'),
        program: 'Program Bravo',
      },
      {
        status: ProjectMeasureStatus.OnTrack,
        name: 'Improve conversion rates',
        target: 'Increase from 2% to 3%',
        achieveByDate: new Date('31 Dec 2022'),
        program: 'Program Charlie',
      },
      {
        status: ProjectMeasureStatus.OnTrack,
        name: 'New targeted marketing campaign',
        target: 'Bring in 1000 new leads',
        achieveByDate: new Date('30 Aug 2022'),
        program: 'Program Charlie',
      },
    ],
  },
  {
    id: '002',
    status: ProjectMeasureStatus.Risk,
    name: 'Customer NPS',
    target: '+25 NPS',
    achieveByDate: new Date('31 Dec 2022'),
    programBenefits: [],
  },
];

const dummyFutureRisks: DummyObjective[] = [
  {
    id: '003',
    status: ProjectMeasureStatus.OnTrack,
    name: 'Operating cost savings',
    target: '$8M per annum',
    achieveByDate: new Date('31 Dec 2022'),
    programBenefits: [],
  },
];

function DashboardCard({
  type,
  portfolio,
  programs,
  projects,
  chart,
}: CardProps): React.ReactElement {
  const { isBeta } = useContext(BetaContext);
  const { profile: userObject } = useContext(UserContext);

  function getMessage(type: DashboardCardType): string {
    let message = '';

    // Prep data
    const projectsTiered = getProjectsByTier(projects);

    switch (type) {
      case 'INFLIGHT_PROJECTS_BY_PRIORITY':
        message =
          projects.length === 0
            ? (message = `No in-flight ${enumTranslates[EntityType.Project]}s`)
            : projects.length >= 5 &&
              projectsTiered.tier1.length > projectsTiered.tier2.length
            ? (message = `We may have too many high priority ${
                enumTranslates[EntityType.Project]
              }s`)
            : (message = `This looks reasonable`);
        break;

      default:
        break;
    }

    return message;
  }

  function getCardInfo(type: DashboardCardType): {
    quant: number | string | undefined;
    suffix?: string;
    description: string;
    message: string;
    expand: React.ReactElement;
  } {
    let description = '';
    let suffix = '';
    let message = '';
    let quant = undefined; // Math.floor(Math.random() * 5);
    let expand = <div>More stuff goes here</div>;

    switch (type) {
      case 'OBJECTIVES_CURRENT_RISK':
        // TODO: Wire in real data from portfolio
        quant = dummyCurrentRisks.length;
        description = translate(
          strings.OBJECTIVES_CURRENT_RISK,
          'Objectives currently at risk'
        );
        expand = (
          <ul className="divide-y divide-gray-200">
            {dummyCurrentRisks.map(item => (
              <li
                key={item.id}
                className="flex items-center justify-between text-sm bg-white hover:bg-gray-50 rounded-md overflow-hidden px-4 py-2"
              >
                {objectiveLayout(item)}
                <div className="">
                  <button
                    type="button"
                    className="disabled:opacity-50 inline-flex items-center px-3 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 whitespace-nowrap"
                    // onClick={() => handleDrillDownClick(result, item)}
                  >
                    Drill down
                  </button>
                </div>
              </li>
            ))}
          </ul>
        );

        break;

      case 'OBJECTIVES_FUTURE_RISK':
        // TODO: Wire in real data from portfolio
        quant = dummyFutureRisks.length;
        description = translate(
          strings.OBJECTIVES_FUTURE_RISK,
          'Objectives with future risk'
        );
        expand = (
          <ul className="divide-y divide-gray-200">
            {dummyFutureRisks.map(item => (
              <li
                key={item.id}
                className="flex items-center justify-between text-sm bg-white hover:bg-gray-50 rounded-md overflow-hidden px-4 py-2"
              >
                {objectiveLayout(item)}
                <div className="">
                  <button
                    type="button"
                    className="disabled:opacity-50 inline-flex items-center px-3 py-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 whitespace-nowrap"
                    // onClick={() => handleDrillDownClick(result, item)}
                  >
                    Drill down
                  </button>
                </div>
              </li>
            ))}
          </ul>
        );
        break;

      case 'INFLIGHT_PROJECT_RAG':
        if (
          projects.length === 0 ||
          projects.filter(project => getLatestProjectUpdate(project) != null)
            .length === 0
        ) {
          quant = '-';

          suffix = ``; //` of ${getTotalProjectsCount()}`;
          message = `No ${enumTranslates[EntityType.Project]} reviews yet`;
          description = translate(
            strings.INFLIGHT_PROJECT_RAG,
            'Project Status'
          );
          expand = (
            <div className="py-4 px-4 bg-white">
              <div className="">{chart}</div>
            </div>
          );
        } else {
          quant =
            (projects.filter(project => {
              const update = getLatestProjectUpdate(project);

              return update?.confidenceRating === ProjectUpdateConfidence.High;
            }).length /
              projects.length) *
            100; // We only receive the in-flight projects from parent
          suffix = '%'; //` of ${getTotalProjectsCount()}`;
          message = `Out of ${projects.length} in-flight ${
            enumTranslates[EntityType.Project]
          }s`;
          // `${
          //   quant >= 80 ? 'This looks good' : 'This can be improved'
          // }`;
          description = translate(
            strings.INFLIGHT_PROJECT_RAG,
            'Project Status'
          );
          expand = (
            <div className="py-4 px-4 bg-white">
              <div className="">{chart}</div>
            </div>
          );
        }

        break;

      case 'INFLIGHT_PROJECT_COUNT':
        quant = projects.length; // We only receive the in-flight projects from parent
        suffix = ''; //` of ${getTotalProjectsCount()}`;
        message = `${getTotalProjectsCount()} total`;
        description = translate(
          strings.INFLIGHT_PROJECT_COUNT,
          'Project Count'
        );
        expand = (
          <div className="py-4 px-4 bg-white">
            <div className="">{chart}</div>
          </div>
        );
        break;

      case 'INFLIGHT_PROJECTS_BY_PRIORITY':
        // In-flight projects only
        quant =
          projects.length === 0
            ? 0
            : (projects.filter(
                project => project.priority === ProjectPriority.Tier1
              ).length /
                projects.length) *
              100;
        suffix = '%';
        description = translate(
          strings.INFLIGHT_PROJECTS_BY_PRIORITY,
          'Projects by priority'
        );
        message = getMessage(type);
        expand = (
          <div className="py-4 px-4 bg-white">
            <div className="">{chart}</div>
          </div>
        );
        break;

      case 'PORTFOLIO_BALANCE':
        // In-flight projects only
        quant = 1;
        description = translate(strings.PORTFOLIO_BALANCE, 'Project balance');
        expand = (
          <div className="pt-4 px-4 bg-white">
            <div className="px-2">{chart}</div>
          </div>
        );
        break;

      case 'PROGRAMS_LOW_CONFIDENCE':
        quant = programs.filter(program => {
          const programUpdate = getLatestProgramUpdate(program);

          return (
            programUpdate?.trend === ProgramUpdateTrend.Trenddown ||
            programUpdate?.trend === ProgramUpdateTrend.Trendflat
          );
        }).length;
        description = translate(
          strings.PROGRAMS_LOW_CONFIDENCE,
          'Programs currently at risk'
        );
        expand = (
          <div className="divide-y divide-gray-200 text-sm">
            {sortPrograms(
              programs.filter(program => {
                const programUpdate = getLatestProgramUpdate(program);

                return (
                  programUpdate?.trend === ProgramUpdateTrend.Trenddown ||
                  programUpdate?.trend === ProgramUpdateTrend.Trendflat
                );
              })
            ).map(program => {
              const programUpdate = getLatestProgramUpdate(program);

              return (
                <div key={program.id}>
                  <PermissionLink
                    entity={program}
                    to={`${PROGRAM}/${program.id}#performance`}
                    allowedLayout={
                      <div className="group flex align-top gap-x-3 px-4 py-3 hover:bg-gray-50">
                        <span className="-mt-px">
                          <ConfidenceBadge
                            rating={programUpdate?.trend}
                            reviewDate={programUpdate?.updateDate}
                            size="shortened"
                          />
                        </span>
                        <div className="grow truncate group-hover:whitespace-normal group-hover:text-primary-600 group-hover:underline">
                          {program.name}
                        </div>
                      </div>
                    }
                    blockedLayout={
                      <div className="group flex align-top gap-x-3 px-4 py-3 hover:bg-gray-50">
                        <span className="-mt-px">
                          <ConfidenceBadge
                            rating={programUpdate?.trend}
                            reviewDate={programUpdate?.updateDate}
                            size="shortened"
                          />
                        </span>
                        <div className="grow truncate group-hover:whitespace-normal">
                          {program.name}
                        </div>
                      </div>
                    }
                  />
                </div>
              );
            })}
          </div>
        );

        break;

      case 'PROJECTS_LOW_CONFIDENCE':
        quant = projects.filter(project => {
          const projectUpdate = getLatestProjectUpdate(project);

          return (
            projectUpdate?.confidenceRating === ProjectUpdateConfidence.Low ||
            projectUpdate?.confidenceRating === ProjectUpdateConfidence.Medium
          );
        }).length;
        description = translate(
          strings.PROJECTS_LOW_CONFIDENCE,
          'Projects currently at risk'
        );
        expand = (
          <div className="divide-y divide-gray-200 text-sm">
            {sortProjects(
              projects.filter(project => {
                const projectUpdate = getLatestProjectUpdate(project);

                return (
                  projectUpdate?.confidenceRating ===
                    ProjectUpdateConfidence.Low ||
                  projectUpdate?.confidenceRating ===
                    ProjectUpdateConfidence.Medium
                );
              })
            )?.map(project => {
              const projectUpdate = getLatestProjectUpdate(project);

              return (
                <div key={project.id}>
                  <PermissionLink
                    entity={project}
                    parentPortfolio={portfolio}
                    to={`${PROJECT}/${project.id}`}
                    allowedLayout={
                      <div className="group flex align-top gap-x-3 px-4 py-3 hover:bg-gray-50">
                        <span className="-mt-px">
                          <ConfidenceBadge
                            rating={projectUpdate?.confidenceRating}
                            reviewDate={projectUpdate?.updateDate}
                            size="shortened"
                          />
                        </span>
                        <div className="grow truncate group-hover:whitespace-normal group-hover:text-primary-600 group-hover:underline">
                          {project.name}
                        </div>
                      </div>
                    }
                    blockedLayout={
                      <div className="group flex align-top gap-x-3 px-4 py-3 hover:bg-gray-50">
                        <span className="-mt-px">
                          <ConfidenceBadge
                            rating={projectUpdate?.confidenceRating}
                            reviewDate={projectUpdate?.updateDate}
                            size="shortened"
                          />
                        </span>
                        <div className="grow truncate group-hover:whitespace-normal">
                          {project.name}
                        </div>
                      </div>
                    }
                  />
                </div>
              );
            })}
          </div>
        );

        break;

      case 'PROGRAM_BENEFITS_NOT_LINKED':
        quant = getUnlinkedProgramBenefits().length;
        description = translate(
          strings.PROGRAM_BENEFITS_NOT_LINKED,
          'Program benefits not linked'
        );
        expand = (
          <div>
            <div className="divide-y divide-gray-200 text-sm">
              {getUnlinkedProgramBenefits().map((item, idx) => {
                return (
                  <div key={idx}>
                    <PermissionLink
                      entity={item.parent}
                      to={`${PROGRAM}/${item.parent?.id}#setup`}
                      allowedLayout={
                        <div className="group px-4 py-3 hover:bg-gray-50">
                          <p className="font-medium">{item.measure.name}</p>
                          <p className="text-xs text-gray-400 truncate group-hover:whitespace-normal group-hover:text-primary-600 group-hover:underline">
                            {item.parent.name}
                          </p>
                        </div>
                      }
                      blockedLayout={
                        <div className="group px-4 py-3 hover:bg-gray-50">
                          <p className="font-medium">{item.measure.name}</p>
                          <p className="text-xs text-gray-400 truncate group-hover:whitespace-normal">
                            {item.parent.name}
                          </p>
                        </div>
                      }
                    />
                  </div>
                );
              })}
            </div>

            {/* Button to open a workflow modal to fix each listed item */}
            {isBeta && (
              <button
                className="w-full bg-primary-500 hover:bg-primary-600 items-center px-4 py-2.5 text-sm font-semibold text-white focus:outline-none disabled:opacity-50"
                onClick={() =>
                  handleFixAllClick(
                    'PROGRAM_BENEFITS',
                    `Fix unlinked ${translate(
                      strings.PROGRAM_MEASURES,
                      'Program Benefits'
                    ).toLowerCase()}`,
                    getUnlinkedProgramBenefits()
                  )
                }
              >
                Fix all
              </button>
            )}
          </div>
        );
        break;

      case 'PROJECT_BENEFITS_NOT_LINKED':
        quant = getUnlinkedProjectBenefits().length;
        description = translate(
          strings.PROJECT_BENEFITS_NOT_LINKED,
          'Project benefits not linked'
        );
        expand = (
          <div>
            <div className="divide-y divide-gray-200 text-sm">
              {getUnlinkedProjectBenefits().map((item, idx) => {
                return (
                  <div key={idx}>
                    <PermissionLink
                      entity={item.parent}
                      to={`${PROJECT}/${item.parent.id}#setup`}
                      allowedLayout={
                        <div className="group px-4 py-3 hover:bg-gray-50">
                          <p className="font-medium">{item.measure.name}</p>
                          <p className="text-xs text-gray-400 truncate group-hover:whitespace-normal group-hover:text-primary-600 group-hover:underline">
                            {item.parent.name}
                          </p>
                        </div>
                      }
                      blockedLayout={
                        <div className="group px-4 py-3 hover:bg-gray-50">
                          <p className="font-medium">{item.measure.name}</p>
                          <p className="text-xs text-gray-400 truncate group-hover:whitespace-normal">
                            {item.parent.name}
                          </p>
                        </div>
                      }
                    />
                  </div>
                );
              })}
            </div>

            {/* Button to open a workflow modal to fix each listed item */}
            {isBeta && (
              <button
                className="w-full bg-primary-500 hover:bg-primary-600 items-center px-4 py-2.5 text-sm font-semibold text-white focus:outline-none disabled:opacity-50"
                onClick={() =>
                  handleFixAllClick(
                    'PROJECT_BENEFITS',
                    `Fix unlinked ${translate(
                      strings.PROJECT_MEASURES,
                      'Project Benefits'
                    ).toLowerCase()}`,
                    getUnlinkedProjectBenefits()
                  )
                }
              >
                Fix all
              </button>
            )}
          </div>
        );
        break;

      case 'PROGRAMS_NO_BENEFIT':
        quant = programs.filter(
          program => program.measures?.length === 0
        ).length;
        description = translate(
          strings.PROGRAMS_NO_BENEFIT,
          'Programs without benefits'
        );
        expand = (
          <div className="divide-y divide-gray-200 text-sm">
            {programs
              .filter(program => program.measures?.length === 0)
              .map(program => {
                const userPermission =
                  userObject &&
                  getPermissionFromTree(userObject, program, portfolio)
                    .entityPermission;
                const hasEditPermissions =
                  userPermission == Group.Owner ||
                  userPermission == Group.Editor;

                return (
                  <div
                    key={program.id}
                    className="flex justify-between items-center gap-x-3 px-4 py-3 bg-white hover:bg-gray-50"
                  >
                    <PermissionLink
                      entity={program}
                      to={`${PROGRAM}/${program.id}#setup`}
                      allowedLayout={
                        <div className="font-medium hover:text-primary-600 hover:underline">
                          {program.name}
                        </div>
                      }
                      blockedLayout={
                        <div className="font-medium hover:text-gray-600">
                          {program.name}
                        </div>
                      }
                    />

                    {/* {hasEditPermissions && (
                      <OpenWizardButton
                        title={translate(
                          strings.WIZARD_EDIT_PROGRAM,
                          'Edit Program'
                        )}
                        editObject={program}
                        parentObject={portfolio}
                        type="PROGRAM_DETAILS"
                        quickSave={true}
                        startStep={1}
                      />
                    )} */}
                  </div>
                );
              })}
          </div>
        );
        break;

      case 'PROJECTS_NO_BENEFIT':
        quant = projects.filter(
          project => project.measures?.length === 0
        ).length;
        description = translate(
          strings.PROJECTS_NO_BENEFIT,
          'Projects without benefits'
        );
        expand = (
          <div className="divide-y divide-gray-200 text-sm">
            {projects
              .filter(project => project.measures?.length === 0)
              .map(project => {
                const userPermission =
                  userObject &&
                  getPermissionFromTree(userObject, project, portfolio)
                    .entityPermission;
                const hasEditPermissions =
                  userPermission == Group.Owner ||
                  userPermission == Group.Editor;
                const parentProgram = project.program;
                // programs.find(
                //   program => program.id === project.program.id
                // );
                console.log(parentProgram);

                return (
                  <li
                    key={project.id}
                    className="flex justify-between items-center gap-x-3 px-4 py-3 bg-white hover:bg-gray-50"
                  >
                    <PermissionLink
                      entity={project}
                      to={`${PROJECT}/${project.id}#setup`}
                      allowedLayout={
                        <div className="font-medium hover:text-primary-600 hover:underline">
                          {project.name}
                        </div>
                      }
                      blockedLayout={
                        <div className="font-medium hover:text-gray-600">
                          {project.name}
                        </div>
                      }
                    />

                    {/* TODO: @Lejla - This wizard button is returning error "No program found" */}
                    {/* {hasEditPermissions && parentProgram && (
                      <OpenWizardButton
                        title={translate(
                          strings.WIZARD_EDIT_PROJECT,
                          'Edit Project'
                        )}
                        editObject={project}
                        parentObject={parentProgram}
                        type="PROJECT_DETAILS"
                        quickSave={true}
                        startStep={1}
                      />
                    )} */}
                  </li>
                );
              })}
          </div>
        );
        break;

      default:
        break;
    }

    return {
      quant: quant,
      suffix: suffix,
      description: description,
      message: message,
      expand: expand,
    };
  }

  const data = getCardInfo(type);

  const [isExpanded, setIsExpanded] = useState(false);

  const [modalType, setModalType] = useState<WorkflowType>();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalData, setModalData] = useState<
    | { measure: ProgramMeasure; parent: Program }[]
    | { measure: ProjectMeasure; parent: Project }[]
  >();

  function handleExpandClick() {
    setIsExpanded(!isExpanded);
  }

  function getUnlinkedProjectBenefits(): {
    measure: ProjectMeasure;
    parent: Project;
  }[] {
    const result: { measure: ProjectMeasure; parent: Project }[] = [];

    programs.forEach(program => {
      const unlinked = getUnlinkedProjectMeasures(
        program,
        projects.filter(project => project.parentId === program.id)
      );
      unlinked.forEach(item => {
        const parent = getParentProject(item, projects);
        parent && result.push({ measure: item, parent: parent });
      });
    });

    return result;
  }

  function getUnlinkedProgramBenefits(): {
    measure: ProgramMeasure;
    parent: Program;
  }[] {
    const result: { measure: ProgramMeasure; parent: Program }[] = [];
    const objectives = portfolio.objectives;

    programs.forEach(program => {
      const unlinked = program.measures?.filter(
        pm =>
          !objectives?.some(
            objective => objective.id === pm.strategicObjectiveId
          )
      );
      unlinked?.forEach(item =>
        result.push({ measure: item, parent: program })
      );
    });

    return result;
  }

  function objectiveLayout(item: any): React.ReactElement {
    const layout = (
      <div className="flex">
        <span className="mr-2.5">
          {getStatusDot(item.status, addDays(new Date(), -5), item.id)}
        </span>
        <div>
          <div className="font-medium">{item.name}</div>
          <div className="flex items-center text-gray-500 text-sm mt-1">
            {iconTarget}
            {`${item.target}${
              item.achieveByDate
                ? `, by ${getLocalDate(item.achieveByDate)}`
                : ''
            }`}
          </div>
        </div>
      </div>
    );

    return layout;
  }

  function getTotalProjectsCount() {
    const result: Project[] = [];
    programs.forEach(program => {
      const projects = program.projects;
      if (projects && projects.length > 0) {
        projects.forEach(project => {
          result.push(project);
        });
      }
    });

    return result.length;
  }

  function handleFixAllClick(
    type: WorkflowType,
    title: string,
    data:
      | { measure: ProgramMeasure; parent: Program }[]
      | { measure: ProjectMeasure; parent: Project }[]
  ) {
    setModalType(type);
    setModalTitle(title);
    setModalData(data);
    setModalOpen(true);
  }

  function getProjectsByTier(projects: Project[]): {
    tier1: Project[];
    tier2: Project[];
    tier3: Project[];
  } {
    const tier1 = projects.filter(
      project => project.priority === ProjectPriority.Tier1
    );
    const tier2 = projects.filter(
      project => project.priority === ProjectPriority.Tier2
    );
    const tier3 = projects.filter(
      project => project.priority === ProjectPriority.Tier3
    );

    return { tier1: tier1, tier2: tier2, tier3: tier3 };
  }

  // This is where we create rules / judgements about whether things look right
  function getBadge(type: DashboardCardType): {
    badge: React.ReactElement;
    message: string;
  } {
    let layout = <React.Fragment></React.Fragment>;
    const message = '';

    if (
      type != 'INFLIGHT_PROJECT_RAG' &&
      type != 'INFLIGHT_PROJECT_COUNT' &&
      type != 'INFLIGHT_PROJECTS_BY_PRIORITY' &&
      data.quant === 0
    ) {
      layout = <span className="block">{checkBadge}</span>;
    }

    // if (type === 'INFLIGHT_PROJECT_COUNT') {
    //   if (data.quant === 0) {
    //     console.log('No inflight projects');
    //     // layout = <div className="mb-2">{warnBadge}</div>;
    //   }
    // } else if (type === 'INFLIGHT_PROJECTS_BY_PRIORITY') {
    //   const projectsTiered = getProjectsByTier(projects);

    //   if (
    //     projects.length >= 5 &&
    //     projectsTiered.tier1.length > projectsTiered.tier2.length
    //   ) {
    //     layout = <span className="block">{warnBadge}</span>;
    //     message = 'We may have too many high priority projects';
    //   }
    // } else {
    //   if (data.quant === 0) {
    //     layout = <span className="block">{checkBadge}</span>;
    //   }
    // }

    return { badge: layout, message: message };
  }

  const layout = (
    <div
      className={`${
        type === 'PORTFOLIO_BALANCE' ? '' : 'border border-gray-200'
      } rounded-md overflow-hidden ${isExpanded ? 'bg-gray-100' : ''}`}
    >
      {/* Drill down modal */}
      {modalOpen && (
        <WorkflowModal
          type={modalType}
          setModalOpen={setModalOpen}
          modalTitle={modalTitle}
          modalData={modalData}
          portfolio={portfolio}
        />
      )}

      <button
        className={`w-full h-full ${
          type === 'PORTFOLIO_BALANCE'
            ? 'pt-2 pb-2 rounded-md border border-gray-200'
            : 'pt-3 pb-4'
        } ${data.quant === 0 ? '' : 'hover:bg-gray-100'}`}
        onClick={handleExpandClick}
        disabled={data.quant === 0}
      >
        <div className="relative">
          {/* Icons */}
          <div
            className={`absolute top-0 right-3.5 ${
              type === 'PORTFOLIO_BALANCE' ? '' : ''
            }`}
          >
            {data.quant === 0 ? null : isExpanded ? iconContract : iconExpand}
          </div>

          {/* Badge */}
          <span className="absolute -top-0.5 right-3">
            {getBadge(type).badge}
          </span>

          {/* Statistic */}
          <div
            className={`flex align-bottom mx-4 ${
              type === 'PORTFOLIO_BALANCE' ? 'hidden' : ''
            }`}
          >
            <div className="text-2xl font-semibold">{`${
              data.quant && typeof data.quant == 'number'
                ? data.quant.toFixed(0)
                : data.quant
            }${data.suffix ? data.suffix : ''}`}</div>
          </div>

          {/* Description */}
          <div
            className={`flex px-4 ${
              type === 'PORTFOLIO_BALANCE'
                ? 'justify-center text-center text-sm'
                : 'text-left font-medium mt-3'
            }`}
          >
            {`${data.description}`}
          </div>

          {/* Context message */}
          {type === 'PORTFOLIO_BALANCE' ? null : (
            <div
              className={`flex px-4 text-left text-sm text-gray-500 mt-0.5 mb-4`}
            >
              {`${data.message}`}
            </div>
          )}
        </div>
      </button>

      {/* Expand content */}
      {isExpanded ? (
        <div
          className={`${
            type === 'PORTFOLIO_BALANCE'
              ? ''
              : 'border-t border-gray-200 bg-white'
          }`}
        >
          {data.expand}
        </div>
      ) : null}
    </div>
  );

  return layout;
}

export default DashboardCard;
