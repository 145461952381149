import React from 'react';
import { Link } from 'react-router-dom';
import SlideOver from '../common/slideovers/SlideOver';
import ObjectiveSlideOverContent, {
  ObjectiveUpdate,
} from './ObjectiveSlideOverContent';

type ObjectiveSlideOverProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  parentEntityName: string;
  parentEntityUrl: string;
  updates: ObjectiveUpdate[];
};

type ObjectiveSlideOverMetaProps = {
  url: string;
  entityName: string;
};

const ObjectiveSlideOverMeta = ({
  url,
  entityName,
}: ObjectiveSlideOverMetaProps) => (
  <div>
    <Link
      to={url}
      className={`hover:underline hover:text-primary-600 outline-none`}
    >
      {entityName}
    </Link>
  </div>
);

export default function ObjectiveSlideOver({
  open,
  setOpen,
  title,
  parentEntityName,
  parentEntityUrl,
  updates,
}: ObjectiveSlideOverProps): React.ReactElement {
  return (
    <React.Fragment>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={title}
        titleMetadata={
          <ObjectiveSlideOverMeta
            url={parentEntityUrl}
            entityName={parentEntityName}
          />
        }
        content={<ObjectiveSlideOverContent objectiveUpdates={updates} />}
      />
    </React.Fragment>
  );
}
