import React from 'react';
import { MenuOption } from '../../basic/DotMenu';
import DetailsHeader from '../../common/panels/PanelHeader';
import DetailsHeaderTitle from '../../common/panels/PanelHeaderTitle';
import AboutActionHeader from './AboutActionHeader';
// enum Translates should be done before in the controller
// import { enumTranslates } from '../../../common/i18n/translate';

type AboutRowProps = {
  label: string;
  content: string | undefined;
};

const AboutRow = ({ label, content }: AboutRowProps): React.ReactElement => {
  const isContentNull = !content;
  return (
    <div className="flex flex-col lg:flex-row text-sm">
      <div className={`pr-4 pb-1 align-top lg:w-52 lg:shrink-0`}>
        <p className="font-semibold">{label}</p>
      </div>
      <div className={`align-top whitespace-pre-wrap`}>
        <p className={isContentNull ? `italic text-gray-400` : `text-gray-700`}>
          {content || 'Not provided '}
        </p>
      </div>
    </div>
  );
};

const AboutTitle = () => (
  <DetailsHeaderTitle
    title={`About`}
    tooltipText={`Background information about this entity`}
  />
);

type AboutContentProps = {
  description: string | undefined;
  primaryStrategicObjectiveName: string | undefined;
  lifecycleStage: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  budget?: number | undefined;
};

const AboutContent = ({
  description,
  primaryStrategicObjectiveName,
  lifecycleStage,
  startDate,
  endDate,
  budget,
}: AboutContentProps) => (
  <div className="min-w-full space-y-4 lg:space-y-3">
    <AboutRow label="Description" content={description} />
    <AboutRow
      label="Primary strategic objective"
      content={primaryStrategicObjectiveName}
    />
    <AboutRow label="Lifecycle stage" content={lifecycleStage} />
    <AboutRow label="Start date" content={startDate} />
    <AboutRow label="End date" content={endDate} />
    {budget && <AboutRow label="Budget" content={`$ ${budget}`} />}
  </div>
);

type AboutPanelProps = {
  description?: string;
  primaryStrategicObjectiveName?: string;
  lifecycleStage: string;
  startDate?: string;
  endDate?: string;
  budget?: number;
  menuOptions: Array<MenuOption>;
  actionsDisabled: boolean;
  onEditClick: () => void;
  isVisible: boolean;
};

export default function AboutPanel({
  description,
  primaryStrategicObjectiveName,
  lifecycleStage,
  startDate,
  endDate,
  budget,
  menuOptions,
  actionsDisabled,
  onEditClick,
  isVisible,
}: AboutPanelProps): React.ReactElement {
  // Set up content to show

  return (
    <DetailsHeader
      headerLeft={<AboutTitle />}
      headerRight={
        <AboutActionHeader
          onClick={onEditClick}
          isVisible={isVisible}
          disabled={actionsDisabled}
          menuOptions={menuOptions}
        />
      }
      content={
        <AboutContent
          description={description}
          primaryStrategicObjectiveName={primaryStrategicObjectiveName}
          lifecycleStage={lifecycleStage}
          startDate={startDate}
          endDate={endDate}
          budget={budget}
        />
      }
    />
  );
}
