import React from "react";

export interface BasicButtonProps {
  id?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
  label?: string;
  disabled?: boolean;
}

const BasicButton = ({ onClick, icon, disabled, label }: BasicButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      className="gap-x-2 bg-gradient-to-tr from-gray-100 to-gray-50 hover:from-gray-200 hover:to-gray-100 inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm whitespace-nowrap text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
      onClick={onClick}
    >
      {icon}
      {label}
    </button>
  );
};

export default BasicButton;
