import * as React from 'react';
import { SlideOverTab } from './SlideOverTab';

type SlideOverTabSelectorProps = {
  selectedTabIndex: number;
  setSelectedTabIndex: React.Dispatch<React.SetStateAction<number>>;
  tabNames: string[];
  tabIndicators: boolean[];
};

export const SlideOverTabSelector = ({
  selectedTabIndex,
  setSelectedTabIndex,
  tabNames,
  tabIndicators,
}: SlideOverTabSelectorProps) => {
  // const tabOptions = [`Outcomes`, `Trajectory`] as const;

  return (
    <div className="w-min mx-auto mb-4">
      <div className="bg-gray-100 p-1.5 shadow-inner rounded-lg">
        <div className="flex items-center justify-center overflow-hidden">
          {tabNames.map((tab, index) => (
            <SlideOverTab
              key={tab}
              selected={index === selectedTabIndex}
              label={tab}
              showIndicator={tabIndicators[index]}
              onClick={() => setSelectedTabIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
