import React from 'react';
import { Link, useParams } from 'react-router-dom';

function Forbidden(): React.ReactElement {
  const { page, id } = useParams<{ page: string; id: string }>();

  console.log(id);
  return (
    <div className="min-h-full pt-16 pb-12 flex flex-col bg-white shadow overflow-hidden sm:rounded-lg">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center">
          <Link to="/" className="inline-flex">
            <span className="sr-only">Workflow</span>
            <img
              className="h-12 w-auto bg-gray-300 rounded-full p-0.5"
              src="/logos/CV-logo-01-bw.svg"
              alt=""
            />
          </Link>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-primary-600 uppercase tracking-wide">
              403 error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              Access Denied.
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, you don't have access to this {page}.
            </p>
            <div className="mt-6">
              <Link
                to="/"
                className="text-base font-medium text-primary-600 hover:text-primary-500"
              >
                Request access<span aria-hidden="true"> &rarr;</span>
              </Link>{' '}
              or
              <Link
                to="/"
                className="text-base font-medium text-primary-600 hover:text-primary-500"
              >
                {' '}
                Go back home<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Forbidden;
