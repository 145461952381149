import React, { useState } from 'react';
import { SlideOverTabSelector } from '../../common/slideovers/SlideOverTabSelector';
import { RelatedEntity } from './RelationshipSlideOver';
import RelationshipSlideOverDatesTab from './RelationshipSlideOverDatesTab';
import RelationshipsSlideOverRelationshipsTab from './RelationshipsSlideOverRelationshipsTab';

type RelationshipSlideOverContentProps = {
  startDate: string;
  endDate: string;
  enablers: RelatedEntity[];
  thisEntityName: string;
  dependents: RelatedEntity[];
};

type SlideOverContentProps = {
  selectedTabIndex: number;
  startDate: string;
  endDate: string;
  enablers: RelatedEntity[];
  thisEntityName: string;
  dependents: RelatedEntity[];
};

const SlideOverTab = ({
  selectedTabIndex,
  startDate,
  endDate,
  enablers,
  thisEntityName,
  dependents,
}: SlideOverContentProps) => {
  let relationshipSlideOverTab;

  if (selectedTabIndex === 0) {
    relationshipSlideOverTab = (
      <RelationshipSlideOverDatesTab startDate={startDate} endDate={endDate} />
    );
  } else {
    relationshipSlideOverTab = (
      <RelationshipsSlideOverRelationshipsTab
        enablers={enablers}
        thisEntityName={thisEntityName}
        dependents={dependents}
      />
    );
  }

  return relationshipSlideOverTab;
};

// This is a wrapper component for the performance slide over content, which is split into two tabs
export default function RelationshipSlideOverContent({
  startDate,
  endDate,
  enablers,
  thisEntityName,
  dependents,
}: RelationshipSlideOverContentProps): React.ReactElement {
  const tabNames = [`Key Dates`, `Relationships`];
  const tabIndicators = [false, false];
  const [selectedTabIndex, setSelectedTabIndex] = useState(tabNames.length - 1);

  return (
    <div>
      <div className="pb-12">
        {/* Tab selector */}
        <SlideOverTabSelector
          tabIndicators={tabIndicators}
          selectedTabIndex={selectedTabIndex}
          setSelectedTabIndex={setSelectedTabIndex}
          tabNames={tabNames}
        />
        <SlideOverTab
          selectedTabIndex={selectedTabIndex}
          startDate={startDate}
          endDate={endDate}
          enablers={enablers}
          thisEntityName={thisEntityName}
          dependents={dependents}
        />
      </div>
    </div>
  );
}
