import React, { useContext, useEffect, useRef, useState } from 'react';
import { Tab } from '@headlessui/react';

import HeaderWithTabsLayout from '../../common/layout/HeaderWithTabsLayout';
// import BasicHeaderWithTabsLayout from '../../common/layout/BasicHeaderWithTabsLayout';
import { PageTab } from '../../common/PageTab';
import MyProjectsTab from './MyProjectsTab/MyProjectsTab';
import WorkspacesTab from './WorkspacesTab';

import {
  useCreateMyWorkspaceMutation,
  useAccessDemoMutation,
  useGetMyWorkspacesLazyQuery,
  Workspace,
  useGetMyWorkspaceLazyQuery,
  useGetLastViewedWorkspaceLazyQuery,
  EntityType,
  useGetBillingAccountLazyQuery,
  useGetWorkspaceEntitiesLazyQuery,
} from '../../api/index';
import { ulid } from 'ulid';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { DemoContext } from '../../context/DemoContext';
import { ApolloError } from '@apollo/client';
import FeedContent from './LatestUpdatesTab/FeedContent';
import { BetaContext } from '../../context/BetaContext';
import { enumTranslates } from '../../common/i18n/translate';
import { capitaliseFirstLetter } from '../../common/utils';
import ShortcutsContainer from '../../JLcomponents/dashboard/Shortcuts/ShortcutsContainer';

function Home(): React.ReactElement {
  // Contexts
  const { state: workspacePageState, dispatch } =
    useContext(WorkspacePageContext);
  const { isBeta } = useContext(BetaContext);
  const { isDemo } = useContext(DemoContext);

  // State
  const [workspace, setWorkspace] = useState<Workspace | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError>();

  const [
    GetLastViewedWorkspace,
    {
      data: lastWorkspace,
      loading: loadingLastWorkspace,
      error: errorLastWorkspace,
    },
  ] = useGetLastViewedWorkspaceLazyQuery({
    fetchPolicy: 'network-only',
  });
  // Data
  const [
    GetWorkspace,
    { data: dataWorkspace, loading: loadingWorkspace, error: errorWorkspace },
  ] = useGetMyWorkspaceLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    GetBillingAccount,
    {
      data: dataBillingAccount,
      loading: loadingBillingAccount,
      error: errorBillingAccount,
    },
  ] = useGetBillingAccountLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    GetMyWorkspaces,
    {
      data: dataWorkspaces,
      loading: loadingWorkspaces,
      error: errorWorkspaces,
    },
  ] = useGetMyWorkspacesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    GetWorkspaceEntitiesQuery,
    {
      data: dataWorkspaceEntities,
      loading: loadingWorkspaceEntities,
      error: errorWorkspaceEntities,
    },
  ] = useGetWorkspaceEntitiesLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [
    accessDemoMutation,
    { loading: loadingDemo, error: errorDemo, data: dataDemo },
  ] = useAccessDemoMutation();

  const [
    createMyWorkspaceMutation,
    { loading: createLoading, error: createError },
  ] = useCreateMyWorkspaceMutation();
  const [workspaceName, setWorkspaceName] = useState('');
  const [myWorkspaceCreatedSuccessfully, setMyWorkspaceCreatedSuccessfully] =
    useState<boolean>(false);
  useEffect(() => {
    if (isDemo) {
      accessDemoMutation({
        variables: {
          email: 'team@journeylab.io',
        },
      });
    } else {
      GetLastViewedWorkspace();
      GetWorkspace();
      GetMyWorkspaces();
    }
  }, [
    GetWorkspace,
    GetMyWorkspaces,
    accessDemoMutation,
    isDemo,
    GetLastViewedWorkspace,
  ]);

  useEffect(() => {
    if (!loadingLastWorkspace && !loadingWorkspace) {
      if (isDemo) {
        setWorkspace(dataDemo?.accessDemo);
      } else {
        if (dataWorkspace?.getMyWorkspace && dataWorkspaces?.getMyWorkspaces) {
          if (lastWorkspace?.getLastViewedWorkspace) {
            setWorkspace(lastWorkspace?.getLastViewedWorkspace);
          } else {
            setWorkspace(dataWorkspace?.getMyWorkspace);
          }

          const allWorkspaces = Object.assign(
            [],
            dataWorkspaces?.getMyWorkspaces
          );
          if (dataWorkspace?.getMyWorkspace) {
            allWorkspaces?.push(dataWorkspace?.getMyWorkspace);
            dispatch({
              type: 'SET_WORKSPACES',
              workspaces: allWorkspaces,
              userWorkspace: dataWorkspace?.getMyWorkspace?.id,
            });
          }
        }
      }
    }
  }, [
    lastWorkspace,
    errorLastWorkspace,
    isDemo,
    dataDemo,
    dataWorkspaces,
    dataWorkspace,
    dispatch,
    loadingLastWorkspace,
    loadingWorkspace,
  ]);

  useEffect(() => {
    if (isDemo) {
      setLoading(loadingDemo);
      setError(errorDemo);
    } else {
      setLoading(
        loadingWorkspace ||
          loadingWorkspaces ||
          loadingLastWorkspace ||
          loadingBillingAccount
      );
      setError(
        errorWorkspace ||
          errorWorkspaces ||
          errorLastWorkspace ||
          errorBillingAccount
      );
    }
  }, [
    errorDemo,
    errorLastWorkspace,
    errorWorkspace,
    errorWorkspaces,
    errorBillingAccount,
    isDemo,
    loadingDemo,
    loadingLastWorkspace,
    loadingWorkspace,
    loadingWorkspaces,
    loadingBillingAccount,
  ]);

  const betaRef = useRef(isBeta);

  useEffect(() => {
    if (isBeta !== betaRef.current) {
      window.location.reload();
    }
  }, [isBeta]);

  useEffect(() => {
    const allWorkspaces = Object.assign([], dataWorkspaces?.getMyWorkspaces);
    if (dataWorkspace?.getMyWorkspace) {
      dispatch({
        type: 'SET_WORKSPACES',
        workspaces: allWorkspaces,
        userWorkspace: dataWorkspace?.getMyWorkspace?.id,
      });
    }

    setLoading(loadingWorkspaces);
    setError(errorWorkspaces);
  }, [
    loadingWorkspaces,
    errorWorkspaces,
    dataWorkspaces,
    dataWorkspace,
    dispatch,
  ]);

  const workspaceRef = useRef(workspace);

  useEffect(() => {
    workspaceRef.current = workspace;
  });

  useEffect(() => {
    if (
      workspace &&
      (!workspaceRef ||
        workspaceRef === null ||
        workspace !== (workspaceRef as unknown as Workspace))
    ) {
      dispatch({
        type: 'SET_SELECTED_WORKSPACE',
        workspace: workspace,
      });
    }
  }, [dispatch, workspace]);

  const create = async () => {
    try {
      const { data } = await createMyWorkspaceMutation({
        variables: {
          name: workspaceName,
        },
      });

      if (data?.createMyWorkspace) {
        dispatch({
          type: 'SET_SELECTED_WORKSPACE',
          workspace: data?.createMyWorkspace,
        });
      }

      setMyWorkspaceCreatedSuccessfully(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (workspacePageState.selectedWorkspace?.id) {
      GetWorkspaceEntitiesQuery({
        variables: {
          workspaceId: workspacePageState.selectedWorkspace.id,
        },
      });
      if (
        workspacePageState.userWorkspace ===
        workspacePageState.selectedWorkspace?.id
      ) {
        GetBillingAccount({
          variables: {
            workspaceId: workspacePageState.selectedWorkspace.id,
          },
        });
      }
    }
  }, [
    GetWorkspaceEntitiesQuery,
    GetBillingAccount,
    workspacePageState.userWorkspace,
    workspacePageState.selectedWorkspace?.id,
  ]);

  useEffect(() => {
    if (dataBillingAccount?.getBillingAccount) {
      dispatch({
        type: 'SET_BILLING_ACCOUNT',
        billingAccount: dataBillingAccount?.getBillingAccount,
      });
    }
  }, [dataBillingAccount, dispatch]);

  // if (loading || createLoading) {
  //   return (
  //     <>
  //       <PageHeader />
  //     </>
  //   );
  // }

  if (error || createError) {
    return <span>{error?.message || createError?.message}</span>;
  }

  const tabs = [
    {
      tab: (
        <Tab key={ulid()} as={'div'}>
          {({ selected }) => (
            <React.Fragment>
              <PageTab selected={selected} title={`Shortcuts`} />
            </React.Fragment>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <ShortcutsContainer />
        </Tab.Panel>
      ),
      hash: '#shortcuts',
    },
    // {
    //   tab: (
    //     <Tab key={ulid()} as={'div'}>
    //       {({ selected }) => (
    //         <React.Fragment>
    //           <PageTab selected={selected} title={`Timeline`} />
    //         </React.Fragment>
    //       )}
    //     </Tab>
    //   ),
    //   content: (
    //     <Tab.Panel key={ulid()}>
    //       <div>
    //         Consolidated timeline component - to be added after Waypoints
    //       </div>
    //     </Tab.Panel>
    //   ),
    //   hash: '#timeline',
    // },
    // {
    //   tab: (
    //     <Tab key={ulid()} as={'div'}>
    //       {({ selected }) => (
    //         <React.Fragment>
    //           <PageTab selected={selected} title={`My Actions`} />
    //         </React.Fragment>
    //       )}
    //     </Tab>
    //   ),
    //   content: (
    //     <Tab.Panel key={ulid()}>
    //       <MyActionsContent />
    //     </Tab.Panel>
    //   ),
    //   hash: '#myactions',
    // },
    // {
    //   tab: (
    //     <Tab key={ulid()} as={'div'}>
    //       {({ selected }) => (
    //         <React.Fragment>
    //           <PageTab selected={selected} title={`Feed`} />
    //         </React.Fragment>
    //       )}
    //     </Tab>
    //   ),
    //   content: (
    //     <Tab.Panel key={ulid()}>
    //       <FeedContent />
    //     </Tab.Panel>
    //   ),
    //   hash: '#feed',
    // },
    // TODO: We wil move workspaces out of this page (new location TBD)
    {
      tab: (
        <Tab key={ulid()} as={'div'}>
          {({ selected }) => (
            <React.Fragment>
              <PageTab
                selected={selected}
                title={`${capitaliseFirstLetter(
                  enumTranslates[EntityType.Workspace]
                )}s`}
              />
            </React.Fragment>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            {!workspace && !myWorkspaceCreatedSuccessfully && (
              <React.Fragment>
                <input
                  type="text"
                  value={workspaceName}
                  placeholder="My workspace name"
                  onChange={e => setWorkspaceName(e.target.value)}
                />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => create()}
                >
                  Create My Workspace
                </button>
              </React.Fragment>
            )}
            <WorkspacesTab setWorkspaceState={setWorkspace} />
          </div>
        </Tab.Panel>
      ),
      hash: '#workspaces',
    },
  ];

  // v1 tabs for reference
  const v1Tabs = [
    {
      tab: (
        <Tab key={ulid()} as={'div'}>
          {({ selected }) => (
            <>
              <PageTab selected={selected} title={`Latest Updates`} />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <FeedContent />
        </Tab.Panel>
      ),
      hash: '#updates',
    },
    {
      tab: (
        <Tab key={ulid()} as={'div'}>
          {({ selected }) => (
            <>
              <PageTab
                selected={selected}
                title={`My ${capitaliseFirstLetter(
                  enumTranslates[EntityType.Project]
                )}s`}
              />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <MyProjectsTab />
        </Tab.Panel>
      ),
      hash: '#projects',
    },
    {
      tab: (
        <Tab key={ulid()} as={'div'}>
          {({ selected }) => (
            <>
              <PageTab
                selected={selected}
                title={`${capitaliseFirstLetter(
                  enumTranslates[EntityType.Workspace]
                )}s`}
              />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            {!workspace && !myWorkspaceCreatedSuccessfully && (
              <>
                <input
                  type="text"
                  value={workspaceName}
                  placeholder="My workspace name"
                  onChange={e => setWorkspaceName(e.target.value)}
                />
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => create()}
                >
                  Create My Workspace
                </button>
              </>
            )}
            <WorkspacesTab setWorkspaceState={setWorkspace} />
          </div>
        </Tab.Panel>
      ),
      hash: '#workspaces',
    },
  ];

  return (
    <HeaderWithTabsLayout title={'Home'} tabs={tabs} isLoading={loading} />
  );
}

export default Home;
