import React from 'react';
import { Question } from 'phosphor-react';
import {
  wizardContentsIconSize,
  wizardContentsIconStyle,
} from '../../constants';
import { WizardContext } from '../WizardContext';

export type WizardContentsData = {
  icon: JSX.Element;
  label: string;
  description: string;
  jumpToStep?: number;
};

type Props = {
  contents: WizardContentsData;
  selected: boolean;
  jumpTo: number;
};

function WizardContentsCard({
  contents,
  selected,
  jumpTo,
}: Props): React.ReactElement {
  const { state, dispatch } = React.useContext(WizardContext);

  const handleStepClick = React.useCallback(
    (stepIndex: number) => {
      dispatch({
        type: 'JUMP_TO_STEP',
        stepIndex,
      });
    },
    [dispatch]
  );

  // TODO: Remove placeholders
  const placeholderIcon = (
    <Question
      className={wizardContentsIconStyle}
      weight="duotone"
      size={wizardContentsIconSize}
    />
  );

  return (
    <button
      onClick={() =>
        handleStepClick(jumpTo ? jumpTo : contents?.jumpToStep || 0)
      }
      className="w-full"
      // disabled={!state.steps[state.currentStepIndex].valid}
    >
      <div
        className={`group col-span-1 flex h-20 border ${
          selected
            ? 'bg-primary-50 border-primary-500'
            : 'bg-white border-white'
        } ${
          (jumpTo || contents?.jumpToStep) &&
          state.steps[state.currentStepIndex].valid &&
          !selected
            ? 'hover:bg-gray-100 hover:cursor-pointer'
            : ''
        } rounded-md justify-start items-center align-middle text-left`}
      >
        <div className="flex flex-row items-center align-middle px-3">
          <div
            className={`mr-3 ${
              selected
                ? 'text-primary-500'
                : 'text-gray-400 group-hover:text-gray-700'
            }`}
          >
            {contents?.icon || placeholderIcon}
          </div>
          <div
            className={`flex flex-col text-left ${
              selected ? '' : 'text-gray-500 group-hover:text-gray-800'
            }`}
          >
            <div className="text-sm font-semibold">
              {contents?.label || 'XX'}
            </div>
            <div className="text-sm mt-0.5">
              {contents?.description || 'XX'}
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

export default WizardContentsCard;
