import * as React from 'react';
import { ArrowRight } from 'phosphor-react';
import { Project } from '../../../api/index';
import { PROJECT } from '../../../common/routes';
import Tag from '../../../common/Tag';
import ExceptionIcon from '../../project/ExceptionIcon';
import {
  getParentProject,
  getStatusDot,
  ProjectMeasureData,
} from './HelperFunctions';
import PermissionLink from '../../../common/PermissionLink';
import { PortfolioPageContext } from '../../portfolio/PortfolioPageContext';

const iconRightArrow = (
  <ArrowRight className="h-5 w-5 text-black shrink-0 mx-2" />
);

type Props = {
  item: ProjectMeasureData;
  projects: Array<Project>;
  isException: boolean;
};

function ProjectMeasureCard({
  item,
  projects,
  isException,
}: Props): React.ReactElement {
  const parentProject = getParentProject(item.measure, projects);
  const { state: portfolioPageState } = React.useContext(PortfolioPageContext);

  const layout = (
    // Make this take up the whole cell width
    <div className="-mr-4 py-3">
      {/* Header */}
      <div className="flex align-top px-2">
        {/* Status dots */}
        <div className="flex align-top h-min">
          {isException ? (
            <div className="mt-0.5">
              <ExceptionIcon exceptionStatus={item.latest.exceptionStatus} />
            </div>
          ) : (
            <div className="mt-0.5 flex">
              {/* If there is a latest update, show previous update */}
              {item.latest.update && item.latest.status ? (
                <React.Fragment>
                  <div className="flex items-center">
                    {getStatusDot(
                      item.previous.status,
                      item.previous.update?.updateDate,
                      `${item.measure.id}-previous`
                    )}
                    <span>{iconRightArrow}</span>
                  </div>
                </React.Fragment>
              ) : null}

              {/* Always show the latest */}
              <span className="flex items-center">
                {getStatusDot(
                  item.latest.status,
                  item.latest.update?.updateDate,
                  `${item.measure.id}-latest`
                )}
                {item.latest.update && item.latest.status ? null : (
                  <span className="inline-block ml-2">
                    <Tag type="new" />
                  </span>
                )}
              </span>
            </div>
          )}
        </div>

        <div className="ml-2.5">
          {/* Measure name */}
          <div className="font-medium pt-0.5">{item.measure.name}</div>

          {/* Project name and link */}
          {parentProject && portfolioPageState.selectedPortfolio && (
            <PermissionLink
              entity={parentProject}
              parentPortfolio={portfolioPageState.selectedPortfolio}
              to={`${PROJECT}/${parentProject.id}`}
              allowedLayout={
                <p className="text-xs text-primary-600 hover:underline hover:text-primary-700">
                  {parentProject?.name}
                </p>
              }
              blockedLayout={
                <p className="text-xs text-gray-500">{parentProject?.name}</p>
              }
            />
          )}
        </div>
      </div>
    </div>
  );

  return layout;
}

export default ProjectMeasureCard;
