import * as React from "react";
import ReactTooltip from "react-tooltip";
import { ulid } from "ulid";

// TODO: Link in the confidence enum

function getConfidenceColour(
  confidence: string | undefined | null,
  shade: "normal" | "light"
): string {
  let colourString;
  switch (confidence) {
    case "High":
      if (shade === "normal") {
        colourString = "bg-green-500";
      } else {
        colourString = "bg-green-100";
      }
      break;
    case "Medium":
      if (shade === "normal") {
        colourString = "bg-yellow-400";
      } else {
        colourString = "bg-yellow-100";
      }
      break;
    case "Low":
      if (shade === "normal") {
        colourString = "bg-red-500";
      } else {
        colourString = "bg-red-100";
      }
      break;
    default:
      colourString = "bg-gray-300";
  }

  return colourString;
}

type ConfidenceBadgeProps = {
  rating: string | null | undefined;
  reviewDate?: string;
  size?: "normal" | "shortened";
  confidenceLabel: string;
};
type tooltipProps = {
  id: string;
  ratingLabel: string;
  reviewDate?: string;
};

const ConfidenceTooltip = ({ id, ratingLabel, reviewDate }: tooltipProps) => (
  <ReactTooltip
    id={id}
    // place="right"
    // type="info"
    effect="solid"
    // delayShow={tooltipDelay}
  >
    <div className="text-center">
      <p className="font-semibold">{ratingLabel}</p>
      <p className="text-xs mt-1">{`${
        reviewDate ? `As at ${reviewDate}` : ""
      }`}</p>
    </div>
  </ReactTooltip>
);

const getRatingLabel = (
  confidenceLabel: string,
  rating: string | null | undefined,
  size?: "normal" | "shortened"
): string => {
  let ratingLabel = confidenceLabel;
  if (size === "shortened") {
    if (rating) {
      ratingLabel = `${rating} ${confidenceLabel}`;
    } else {
      ratingLabel = `${confidenceLabel} not rated`;
    }
  }
  return ratingLabel;
};

export default function ConfidenceBadge({
  rating,
  reviewDate,
  size,
  confidenceLabel,
}: ConfidenceBadgeProps): React.ReactElement {
  const key = ulid();
  //   const rating = enumTranslates[rating as string];

  const ratingLabel = getRatingLabel(confidenceLabel, rating, size);
  const layout = (
    <React.Fragment>
      {rating ? (
        <span
          data-tip
          data-for={key}
          className={`${getConfidenceColour(rating, "normal")} block h-6 ${
            size && size === "shortened" ? "w-6" : "w-16"
          } flex-shrink-0 text-center text-xs align-middle items-center justify-center text-white rounded font-semibold px-1 py-1 whitespace-nowrap`}
        >
          {size && size === "shortened" ? rating[0] : rating}
        </span>
      ) : (
        <span
          data-tip
          data-for={key}
          className={`bg-white block h-6 ${
            size && size === "shortened" ? "w-6" : "w-16"
          } flex-shrink-0 text-center text-xs align-middle items-center justify-center text-gray-400 rounded px-1 py-1 border border-gray-300 whitespace-nowrap italic`}
        >
          {size && size === "shortened" ? "-" : "Not rated"}
        </span>
      )}
      <ConfidenceTooltip
        id={key}
        ratingLabel={ratingLabel}
        reviewDate={reviewDate}
      />{" "}
    </React.Fragment>
  );

  return layout;
}
