import React, { useContext, useEffect, useState } from 'react';
import ProgramMeasuresTable from './ProgramMeasuresView';
import { ProgramPageContext } from '../ProgramPageContext';
import {
  capitaliseFirstLetter,
  classNames,
  getLocalDate,
  getRelativeTime,
} from '../../../common/utils';
import WizardButton from '../../../common/wizard/WizardButton';
import { Menu } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import {
  EntityType,
  Group,
  Program,
} from '../../../api/index';
import translate, {
  enumTranslates,
  strings,
} from '../../../common/i18n/translate';
import EmptyState from '../../../common/layout/EmptyState';
import { useNavigate } from 'react-router-dom';
import { PORTFOLIO } from '../../../common/routes';
import Spinner from '../../../common/SpinnerThemed';
import Modal from '../../../common/Modal';
import DetailsPanel from '../../../common/DetailsPanel';
import DetailsPanelHeader from '../../../common/DetailsPanelHeader';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { CheckCircle, Circle, Trash } from 'phosphor-react';
import ProgramMembershipTable from './ProgramMembershipTable';
import Banner from '../../../common/Banner';

function OverflowMenu(program: Program | null) {
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');

  const navigate = useNavigate();

  // const [removeProgramMutation, { data, loading }] = useRemoveProgramMutation();

  const handleDeleteClick = (program: Program | null) => {
    if (program != null) {
      try {
        // removeProgramMutation({
        //   variables: {
        //     id: program.id,
        //   },
        // });
      } catch (e) {
        toast.error((e as ApolloError).message);
      }
    }
    return;
  };

  // useEffect(() => {
  //   if (data?.removeProgram) {
  //     toast.success(
  //       `Deleted ${enumTranslates[EntityType.Program]} '${
  //         program?.name ? program.name : ''
  //       }'`
  //     );
  //     navigate(`${PORTFOLIO}/${program?.portfolio.id}`);
  //   }
  // }, [data, navigate, program]);

  if (program != null) {
    const iconDelete = <Trash weight="bold" className="h-5 w-5 text-red-600" />;
    const menuOptions = [
      {
        display: `Delete this ${enumTranslates[EntityType.Program]}...`,
        icon: iconDelete,
        action: () => setModalOpen(true),
      },
    ];

    return (
      <div className="flex items-center group relative">
        {modalOpen && (
          <Modal width={'w-full max-w-xl'} onClose={() => setModalOpen(false)}>
            <div className="flex flex-col space-y-4 bg-white p-4 overflow-y-auto align-middle">
              <div className="flex flex-row items-center">
                <span className="flex p-2 rounded-full bg-red-600 align-middle items-center">
                  <Trash
                    weight="fill"
                    className="h-5 w-5 text-white mx-auto my-auto"
                  />
                </span>
                <h3 className="text-lg font-medium ml-3 text-gray-900">
                  Confirm Delete
                </h3>
              </div>
              <p className="mt-3 text-sm text-gray-500">
                {`Deleting a ${enumTranslates[EntityType.Program]} cannot be
                undone. Impacts also include the following:`}
                <ul className="list-disc list-outside ml-4 mt-2 text-sm text-gray-500">
                  <li>
                    {`All ${
                      enumTranslates[EntityType.Program]
                    } members will lose
                    access to this ${enumTranslates[EntityType.Program]}`}
                  </li>
                  <li>
                    {`This ${
                      enumTranslates[EntityType.Program]
                    }'s updates will be
                    deleted`}
                  </li>
                  <li>
                    {`All ${enumTranslates[EntityType.Project]}s in this ${
                      enumTranslates[EntityType.Program]
                    } will be deleted, along with their data`}
                  </li>
                </ul>
              </p>
              <p className="mt-3 text-sm text-gray-500">
                {`If you still want to proceed, type "DELETE ${program.name.toUpperCase()}" below, then press the "Delete ${
                  enumTranslates[EntityType.Program]
                }" button.`}
              </p>
              <input
                type="text"
                className="block w-full shadow-sm focus:ring-red-600 focus:border-red-600 sm:text-sm border-gray-300 rounded-md"
                onChange={e => setDeleteInputValue(e.target.value)}
                placeholder={`Type "DELETE ${program.name.toUpperCase()}" here`}
                value={deleteInputValue}
              />
              <span className="text-right space-x-3">
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md shadow-sm bg-white text-black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-500"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="disabled:opacity-50 items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                  disabled={
                    deleteInputValue !== `DELETE ${program.name.toUpperCase()}`
                  }
                  onClick={() => handleDeleteClick(program)}
                >
                  Delete {enumTranslates[EntityType.Program]}
                </button>
              </span>
            </div>
          </Modal>
        )}
        <Menu>
          <Menu.Button className="inline-flex p-1 border border-transparent rounded-full text-gray-400 hover:bg-gray-200 hover:text-black hover:shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <EllipsisVerticalIcon className="h-5 w-5" />
          </Menu.Button>
          <Menu.Items className="w-fit origin-top-right absolute right-0 mt-20 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            {/* Use the `active` render prop to conditionally style the active item. */}
            {menuOptions.map((item, itemIdx) => (
              <Menu.Item key={itemIdx}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active
                        ? 'bg-gray-100 text-gray-900'
                        : 'bg-white text-gray-700',
                      'flex w-full items-center px-4 py-2 text-sm'
                    )}
                    onClick={item.action}
                  >
                    <span className="inline-block mr-3 ">{item.icon}</span>
                    <span className="inline-block whitespace-nowrap">
                      {item.display}
                    </span>
                  </button>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Menu>
      </div>
    );
  }
}

function AdminTab(): React.ReactElement {
  const { state: programPageState } = useContext(ProgramPageContext);
  const program = programPageState.selectedProgram;

  // Get Current User permissions
  const userPermission = program?.group;
  const hasEditPermissions =
    userPermission == Group.Owner || userPermission == Group.Editor;
  const hasOwnerPermissions = userPermission == Group.Owner;

  const aboutTitle = (
    <DetailsPanelHeader
      title={`About ${capitaliseFirstLetter(
        enumTranslates[EntityType.Program]
      )}`}
      description={`Basic information about this ${
        enumTranslates[EntityType.Program]
      }`}
    />
  );

  const invitations = { invitations: programPageState.invitations };
  const editObject = { ...program, ...invitations };
  const aboutButton = (
    <div className="flex flex-row ml-4 flex-shrink-0 gap-x-3">
      <span className="sm:ml-3">
        {/* {program && hasEditPermissions && (
          <WizardButton
            title={translate(strings.WIZARD_EDIT_PROGRAM, 'Edit Program')}
            editObject={editObject}
            parentObject={program.portfolio}
            type="PROGRAM_DETAILS"
            quickSave={true}
            startStep={1}
          />
        )} */}
      </span>

      {/* For now, hide entire overflow menu since there is only a delete item. 
      Later this will be per item in this menu */}
      {program && hasOwnerPermissions && OverflowMenu(program)}
    </div>
  );

  const aboutContent = (
    <div className="grid grid-cols-1 md:grid-cols-4 justify-between items-stretch gap-x-6 gap-y-3">
      <div className="flex-1">
        <p className="text-sm font-semibold text-gray-900">Start Date</p>
        <p className="mt-1 mb-0 text-sm text-gray-600">
          {getLocalDate(program?.startDate)}
        </p>
        <p className="text-xs text-gray-400">
          {capitaliseFirstLetter(getRelativeTime(program?.startDate))}
        </p>
      </div>
      <div className="flex-1">
        <p className="text-sm font-semibold text-gray-900">Delivery Approach</p>
        <p className="mt-1 text-sm text-gray-600">
          {program?.changeType ? enumTranslates[program?.changeType] : '-'}
        </p>
      </div>
      <div className="flex-1">
        <p className="text-sm font-semibold text-gray-900">
          Primary{' '}
          {translate(strings.PORTFOLIO_STRATEGIC_PRIORITY, 'Strategic Pillar')}
        </p>
        <p className="mt-1 text-sm text-gray-600">
          {program?.primaryStrategicThemeId
            ? program?.portfolio.themes?.find(
                theme => theme.id == program?.primaryStrategicThemeId
              )?.name
            : '-'}
        </p>
      </div>
      <div className="flex-1">
        <p className="text-sm font-semibold text-gray-900">Budget Envelope</p>
        <p className="mt-1 text-sm text-gray-600">
          {programPageState.selectedProgram?.envelope === undefined
            ? '-'
            : programPageState.selectedProgram?.envelope === null
            ? 'N/A'
            : programPageState.selectedProgram?.envelope >= 0
            ? `$${programPageState.selectedProgram?.envelope.toLocaleString()}`
            : ''}
        </p>
      </div>
      <div className="flex-1">
        <p className="text-sm font-semibold text-gray-900">Review Cadence</p>
        <p className="mt-1 text-sm text-gray-600">
          {program?.updateCadence && program?.nextUpdateDate
            ? `${enumTranslates[program?.updateCadence]} from ${getLocalDate(
                program?.nextUpdateDate
              )}`
            : '-'}
        </p>
      </div>

      {/* TODO: Confirm tagging infrastructure and mechanics */}
      {/* <div className="flex-1">
              <p className="mt-1 text-sm font-semibold text-gray-900">Tags</p>
              <div className="grid grid-flow-row gap-2 mt-2 mb-2 text-sm text-gray-600 bg-red-100">
                {project.tags.map((tag) => (
                  <span className="block w-min px-2 py-1 bg-gray-200 rounded whitespace-nowrap">
                    {tag}
                  </span>
                ))}
              </div>
            </div> */}
    </div>
  );

  const objectivesTitle = (
    <DetailsPanelHeader
      title={`Strategic Alignment`}
      description={`What we are aiming for and why it's important`}
    />
  );

  const objectivesContent = (
    <div className="grid grid-cols-1 justify-between lg:space-x-6 lg:grid-cols-2 space-y-3 lg:space-y-0">
      <div className="col-span-1">
        <p className="text-sm font-semibold text-gray-900">
          Program Description
        </p>
        {program?.outcome ? (
          <p className="mt-1 text-sm text-gray-600 whitespace-pre-wrap">
            {program?.outcome}
          </p>
        ) : (
          <p className="mt-1 text-sm italic text-gray-400">Not provided</p>
        )}
      </div>
      <div className="col-span-1">
        <p className="text-sm font-semibold text-gray-900">
          Alignment to Strategy
        </p>
        {program?.alignment ? (
          <p className="mt-1 text-sm whitespace-pre-wrap text-gray-600">
            {program?.alignment}
          </p>
        ) : (
          <p className="mt-1 text-sm italic text-gray-400">Not provided</p>
        )}
      </div>
    </div>
  );

  const outcomesTitle = (
    <DetailsPanelHeader
      title={translate(strings.PROGRAM_MEASURES, 'Program Outcomes')}
      description={'What success looks like for this program'}
    />
  );

  const outcomesContent =
    program?.measures && program?.measures?.length > 0 ? (
      <ProgramMeasuresTable />
    ) : (
      <EmptyState type="benefits" />
    );

  const membersTitleBeta = (
    <DetailsPanelHeader
      title={translate(strings.ROLES_PERMISSIONS, 'Roles & Permissions')}
      description={`Who has access to this ${
        enumTranslates[EntityType.Program]
      }`}
    />
  );

  const membersContentBeta =
    program?.members?.items && program?.members?.items.length === 0 ? (
      <EmptyState type="members" />
    ) : (
      <ProgramMembershipTable />
    );

  const doneIcon = (
    <CheckCircle
      className={`w-5 h-5 flex-shrink-0 text-green-500`}
      weight="fill"
    />
  );
  const tbdIcon = (
    <Circle className={`w-5 h-5 flex-shrink-0 text-gray-400`} weight="bold" />
  );

  // Calculate setup progress
  const progressChecklist = [
    program?.outcome != undefined && program.outcome.length > 0,
    program?.alignment != undefined && program.alignment.length > 0,
    program?.measures && program.measures.length > 0,
    program?.envelope != undefined && program.envelope > 0,
    program?.projects && program.projects.length > 0,
    (program?.members?.items && program.members.items.length > 1) ||
      (programPageState.invitations && programPageState.invitations.length > 0),
  ];

  const completedSteps = progressChecklist.reduce(
    (partialSum, a) => partialSum + (a == true ? 1 : 0),
    0
  );

  return (
    <React.Fragment>
      {/* Banner */}
      {/* Only show if there are outstanding steps */}
      {hasEditPermissions && completedSteps != progressChecklist.length && (
        <Banner
          colour="blue"
          summary={`Setup progress: ${completedSteps} of ${progressChecklist.length} steps completed`}
          content={
            <ul className="list-outside space-y-1">
              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[0] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[0] ? doneIcon : tbdIcon} Add description
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[1] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[1] ? doneIcon : tbdIcon} Define alignment to
                strategy
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[2] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[2] ? doneIcon : tbdIcon} Add{' '}
                {translate(
                  strings.PROGRAM_MEASURES,
                  'Program Benefits'
                ).toLowerCase()}
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[3] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[3] ? doneIcon : tbdIcon} Set budget envelope
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[4] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[4] ? doneIcon : tbdIcon} Create a{' '}
                {enumTranslates[EntityType.Project]}
              </li>

              <li
                className={`ml-1 flex items-center gap-x-2 ${
                  progressChecklist[5] ? 'line-through text-gray-600' : ''
                }`}
              >
                {progressChecklist[5] ? doneIcon : tbdIcon} Invite contributors
              </li>
            </ul>
          }
        />
      )}

      {/* About program */}
      <DetailsPanel
        headerLeft={aboutTitle}
        headerRight={aboutButton}
        content={aboutContent}
      />

      {/* Strategic alignment */}
      <DetailsPanel
        headerLeft={objectivesTitle}
        headerRight={null}
        content={objectivesContent}
      />

      {/* Program benefits */}
      <DetailsPanel
        headerLeft={outcomesTitle}
        headerRight={null}
        content={outcomesContent}
      />

      {/* Member */}
      <DetailsPanel
        headerLeft={membersTitleBeta}
        headerRight={null}
        content={membersContentBeta}
      />
    </React.Fragment>
  );
}

export default AdminTab;
