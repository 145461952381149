import React from 'react';
import { RadioGroup } from '@headlessui/react';
import ReactTooltip from 'react-tooltip';
import { classNames } from '../../../utils/utils';
import { BasicButtonProps } from '../../basic/BasicButton';
export interface ButtonGroupType extends BasicButtonProps {
  selected?: boolean;
  tooltipText?: string;
  value?: string;
  isSelectable?: boolean;
}

type ButtonGroupProps = {
  buttons: ButtonGroupType[];
  defaultValue?: string;
  onClick: (value: string | undefined, deselected?: boolean) => void;
};

export default function ButtonGroup({
  buttons,
  defaultValue,
  onClick,
}: ButtonGroupProps): React.ReactElement {
  const [selected, setSelected] = React.useState<string | undefined>(
    defaultValue
  );

  const handleSelection = (value: string | undefined) => {
    if (selected === value) {
      setSelected(undefined);
      onClick(value, true);
    } else {
      setSelected(value);
      onClick(value);
    }
  };

  return (
    <div className="w-min rounded-md shadow-sm border border-gray-300 divide-x divide-gray-300 overflow-hidden">
      <RadioGroup
        value={selected}
        onChange={handleSelection}
        className="inline-flex"
      >
        {buttons.map((button, index) => {
          return (
            <RadioGroup.Option
              key={button.id}
              data-tip
              data-for={button.id}
              value={button.value}
              className={({ active, checked }) =>
                classNames(
                  button.disabled
                    ? 'opacity-25 cursor-not-allowed'
                    : 'cursor-pointer focus:outline-none',
                  checked
                    ? 'bg-white text-gray-700'
                    : 'bg-gray-100 text-gray-300 hover:text-gray-700',
                  `whitespace-nowrap outline-none border-l gap-x-2 inline-flex items-center px-3 py-1.5 text-sm font-medium focus:z-10 focus:outline-none`
                )
              }
            >
              {button.icon}
              {button.label}
              {button.tooltipText && (
                <ReactTooltip id={button.id} place="top" effect="solid">
                  <div className="text-center">
                    <p className="font-semibold">{button.tooltipText}</p>
                  </div>
                </ReactTooltip>
              )}
            </RadioGroup.Option>
          );
        })}
      </RadioGroup>
    </div>
  );
}
