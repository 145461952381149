import * as React from 'react';
import { useContext, useEffect, useMemo } from 'react';
import { Tab } from '@headlessui/react';

import BasicHeaderWithTabsLayout from '../../common/layout/BasicHeaderWithTabsLayout';
import PageHeader from './PageHeader';
import { PageTab } from '../../common/PageTab';

import ProgramDetails from './Details/ProgramDetails';
import ProgramContentsTab from './ProgramContentsTab';
import AdminTab from './AdminTab/AdminTab';

import { ulid } from 'ulid';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EntityType,
  useGetOpenInvitationsByEntityLazyQuery,
  useGetProgramLazyQuery,
  useGetProgramsLazyQuery,
  useGetProjectsLazyQuery,
  useSetLastViewedWorkspaceMutation,
} from '../../api/index';
import { NOT_FOUND } from '../../common/routes';
import { WorkspacePageContext } from '../workspace/WorkspacePageContext';
import { ProgramPageContext } from './ProgramPageContext';
import LinksPanel from '../project/LinksPanel';
import { ProjectPageContext } from '../project/ProjectPageContext';
import { toast } from 'react-toastify';
import { capitaliseFirstLetter } from '../../common/utils';
import { enumTranslates } from '../../common/i18n/translate';
import EntityTeaming from '../entity/teaming/EntityTeaming';

import EntityAboutRollup from '../entity/overview/EntityOverviewRollup';
import HeaderWithTabsLayout from '../../common/layout/HeaderWithTabsLayout';
import EntityPlan from '../entity/waypoints/EntityWaypoints';

export type ProgramTabContentType =
  | 'PROGRAM_OVERVIEW'
  | 'PROGRAM_PERFORMANCE'
  | 'PROJECT_PERFORMANCE';

type Props = {
  tab: ProgramTabContentType;
};
// Use wrapper so that we have access to the selected program in ProgramPageContext
function ProgramDetailsWrapper({ tab }: Props): React.ReactElement | null {
  const { state: programPageState } = useContext(ProgramPageContext);
  if (programPageState.selectedProgram != null) {
    return (
      <ProgramDetails
        program={programPageState.selectedProgram}
        content={tab}
      />
    );
  }
  return null;
}

function ProgramHomepage(): React.ReactElement {
  const { programId } = useParams<{ programId: string }>();

  const { state: programPageState } = useContext(ProgramPageContext);
  const program = programPageState.selectedProgram;
  const navigate = useNavigate();

  const { dispatch: programDispatch } = useContext(ProgramPageContext);
  const { dispatch: projectDispatch } = useContext(ProjectPageContext);
  const { dispatch: workspacePageDispatch, state: workspacePageState } =
    useContext(WorkspacePageContext);
  const [setLastViewedWorkspace] = useSetLastViewedWorkspaceMutation();

  const [
    GetProgramsQuery,
    { data: dataPrograms, loading: loadingPrograms, error: errorPrograms },
  ] = useGetProgramsLazyQuery();
  const [
    GetProjectsQuery,
    { data: dataProjects, loading: loadingProjects, error: errorProjects },
  ] = useGetProjectsLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [
    GetProgramQuery,
    { data: dataProgram, loading: loadingProgram, error: errorProgram },
  ] = useGetProgramLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [
    GetOpenInvitations,
    {
      data: dataInvitations,
      loading: loadingInvitations,
      error: errorInvitations,
    },
  ] = useGetOpenInvitationsByEntityLazyQuery({
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (programId) {
      GetProjectsQuery({
        variables: {
          programId: programId,
        },
      });
      GetProgramQuery({
        variables: {
          programId: programId,
        },
      });
      GetOpenInvitations({
        variables: {
          entityId: programId,
          limit: 25,
        },
      });
    }
  }, [
    GetProgramQuery,
    GetOpenInvitations,
    GetProjectsQuery,
    programId,
    programPageState.loadUpdate,
  ]);

  useEffect(() => {
    if (dataProgram?.getProgram?.portfolio.id) {
      GetProgramsQuery({
        variables: {
          portfolioId: dataProgram?.getProgram?.portfolio.id,
        },
      });
    }
  }, [
    GetProgramsQuery,
    dataProgram,
    programPageState.loadProject,
    programPageState.loadUpdate,
  ]);

  useEffect(() => {
    if (
      dataProgram?.getProgram &&
      dataPrograms?.getPrograms &&
      dataProjects?.getProjects &&
      dataInvitations?.getOpenInvitationsByEntity?.items
    ) {
      const selectedProgram = dataProgram?.getProgram;
      if (selectedProgram) {
        programDispatch({
          type: 'SET_SELECTED_PROGRAM',
          program: selectedProgram,
        });
        projectDispatch({
          type: 'SET_PROJECTS',
          projects: dataProjects.getProjects || [],
        });
        const workspace = workspacePageState.workspaces.find(
          w => w.id === selectedProgram.portfolio.parentId
        );
        if (dataPrograms?.getPrograms) {
          programDispatch({
            type: 'SET_PROGRAMS',
            programs: dataPrograms?.getPrograms,
          });
        }
        if (dataInvitations?.getOpenInvitationsByEntity?.items) {
          programDispatch({
            type: 'SET_INVITATIONS',
            invitations: Object.assign(
              dataInvitations?.getOpenInvitationsByEntity?.items
            ),
          });
        }
        if (workspace && workspace !== workspacePageState.selectedWorkspace) {
          setLastViewedWorkspace({
            variables: {
              workspaceId: workspace.id,
            },
          });
          workspacePageDispatch({
            type: 'SET_SELECTED_WORKSPACE',
            workspace: workspace,
          });
        }
      }
    }
  }, [
    dataInvitations,
    dataProgram,
    dataPrograms,
    dataProjects,
    navigate,
    programDispatch,
    projectDispatch,
    setLastViewedWorkspace,
    workspacePageDispatch,
    workspacePageState.selectedWorkspace,
    workspacePageState.workspaces,
  ]);

  useEffect(() => {
    if (!programId || errorProgram) {
      navigate(NOT_FOUND);
    }
  }, [errorProgram, navigate, programId]);

  useEffect(() => {
    if (errorInvitations || errorPrograms || errorProjects) {
      toast.error(
        errorInvitations?.message ||
          errorPrograms?.message ||
          errorProjects?.message
      );
    }
  }, [errorInvitations, errorPrograms, errorProjects]);

  const showLoadingSpinner = useMemo(() => {
    return (
      loadingPrograms ||
      loadingProgram ||
      loadingProjects ||
      loadingInvitations ||
      dataPrograms?.getPrograms?.length === 0 ||
      program == null
    );
  }, [
    loadingPrograms,
    loadingProgram,
    loadingProjects,
    loadingInvitations,
    dataPrograms,
    program,
  ]);

  const tabs = [
    // The first tab is context setting and contains the key metadata
    // These are things about the Entity that are typically set and change infrequently
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Overview`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            {program && (
              <EntityAboutRollup
                entity={program}
                isLoading={showLoadingSpinner}
              />
            )}
          </div>
        </Tab.Panel>
      ),
      hash: '#overview',
    },

    // The second tab is to plan and track how the Entity progresses over time
    // These are things that are periodically checked
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <PageTab selected={selected} title={`Waypoints`} />
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          {/* <ProgramDetailsWrapper tab={'PROGRAM_PERFORMANCE'} /> */}
          <div className="flex-col space-y-6">
            <EntityPlan />
          </div>
        </Tab.Panel>
      ),
      hash: '#waypoints',
    },

    // The third tab is about the team and ways of working
    // These are things about the people involved with the Entity

    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Teaming`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            {program && <EntityTeaming entity={program} />}
          </div>
        </Tab.Panel>
      ),
      hash: '#teaming',
    },

    // {
    //   tab: (
    //     <Tab as={'div'} key={ulid()}>
    //       {({ selected }) => (
    //         <PageTab
    //           selected={selected}
    //           title={`${capitaliseFirstLetter(
    //             enumTranslates[EntityType.Program]
    //           )} Contents`}
    //         />
    //       )}
    //     </Tab>
    //   ),
    //   content: (
    //     <Tab.Panel key={ulid()}>
    //       <div className="flex-col space-y-6">
    //         <ProgramContentsTab />
    //       </div>
    //     </Tab.Panel>
    //   ),
    //   hash: '#contents',
    // },

    // {
    //   tab: (
    //     <Tab as={'div'} key={ulid()}>
    //       {({ selected }) => <PageTab selected={selected} title={`Links`} />}
    //     </Tab>
    //   ),
    //   content: (
    //     <Tab.Panel key={ulid()}>
    //       <div className="flex-col space-y-6">
    //         <LinksTab
    //           loadingEntity={showLoadingSpinner}
    //           entity={programPageState.selectedProgram}
    //         />
    //       </div>
    //     </Tab.Panel>
    //   ),
    //   hash: '#links',
    // },
  ];

  return (
    <HeaderWithTabsLayout
      title={program?.name && program?.name.length > 0 ? program?.name : ''}
      tabs={tabs}
      entity={undefined}
      isLoading={showLoadingSpinner}
    />
  );
}

// This is an archive of the original program page for reference purposes only
function ProgramHomepageV1(): React.ReactElement {
  const { programId } = useParams<{ programId: string }>();

  const { state: programPageState } = useContext(ProgramPageContext);
  const program = programPageState.selectedProgram;
  const navigate = useNavigate();

  const { dispatch: programDispatch } = useContext(ProgramPageContext);
  const { dispatch: projectDispatch } = useContext(ProjectPageContext);
  const { dispatch: workspacePageDispatch, state: workspacePageState } =
    useContext(WorkspacePageContext);
  const [setLastViewedWorkspace] = useSetLastViewedWorkspaceMutation();

  const [
    GetProgramsQuery,
    { data: dataPrograms, loading: loadingPrograms, error: errorPrograms },
  ] = useGetProgramsLazyQuery();
  const [
    GetProjectsQuery,
    { data: dataProjects, loading: loadingProjects, error: errorProjects },
  ] = useGetProjectsLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [
    GetProgramQuery,
    { data: dataProgram, loading: loadingProgram, error: errorProgram },
  ] = useGetProgramLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [
    GetOpenInvitations,
    {
      data: dataInvitations,
      loading: loadingInvitations,
      error: errorInvitations,
    },
  ] = useGetOpenInvitationsByEntityLazyQuery({
    fetchPolicy: 'no-cache',
  });
  useEffect(() => {
    if (programId) {
      GetProjectsQuery({
        variables: {
          programId: programId,
        },
      });
      GetProgramQuery({
        variables: {
          programId: programId,
        },
      });
      GetOpenInvitations({
        variables: {
          entityId: programId,
          limit: 25,
        },
      });
    }
  }, [
    GetProgramQuery,
    GetOpenInvitations,
    GetProjectsQuery,
    programId,
    programPageState.loadUpdate,
  ]);

  useEffect(() => {
    if (dataProgram?.getProgram?.portfolio.id) {
      GetProgramsQuery({
        variables: {
          portfolioId: dataProgram?.getProgram?.portfolio.id,
        },
      });
    }
  }, [
    GetProgramsQuery,
    dataProgram,
    programPageState.loadProject,
    programPageState.loadUpdate,
  ]);

  useEffect(() => {
    if (
      dataProgram?.getProgram &&
      dataPrograms?.getPrograms &&
      dataProjects?.getProjects &&
      dataInvitations?.getOpenInvitationsByEntity?.items
    ) {
      const selectedProgram = dataProgram?.getProgram;
      if (selectedProgram) {
        programDispatch({
          type: 'SET_SELECTED_PROGRAM',
          program: selectedProgram,
        });
        projectDispatch({
          type: 'SET_PROJECTS',
          projects: dataProjects.getProjects || [],
        });
        const workspace = workspacePageState.workspaces.find(
          w => w.id === selectedProgram.portfolio.parentId
        );
        if (dataPrograms?.getPrograms) {
          programDispatch({
            type: 'SET_PROGRAMS',
            programs: dataPrograms?.getPrograms,
          });
        }
        if (dataInvitations?.getOpenInvitationsByEntity?.items) {
          programDispatch({
            type: 'SET_INVITATIONS',
            invitations: Object.assign(
              dataInvitations?.getOpenInvitationsByEntity?.items
            ),
          });
        }
        if (workspace && workspace !== workspacePageState.selectedWorkspace) {
          setLastViewedWorkspace({
            variables: {
              workspaceId: workspace.id,
            },
          });
          workspacePageDispatch({
            type: 'SET_SELECTED_WORKSPACE',
            workspace: workspace,
          });
        }
      }
    }
  }, [
    dataInvitations,
    dataProgram,
    dataPrograms,
    dataProjects,
    navigate,
    programDispatch,
    projectDispatch,
    setLastViewedWorkspace,
    workspacePageDispatch,
    workspacePageState.selectedWorkspace,
    workspacePageState.workspaces,
  ]);

  useEffect(() => {
    if (!programId || errorProgram) {
      navigate(NOT_FOUND);
    }
  }, [errorProgram, navigate, programId]);

  useEffect(() => {
    if (errorInvitations || errorPrograms || errorProjects) {
      toast.error(
        errorInvitations?.message ||
          errorPrograms?.message ||
          errorProjects?.message
      );
    }
  }, [errorInvitations, errorPrograms, errorProjects]);

  const showLoadingSpinner = useMemo(() => {
    return (
      loadingPrograms ||
      loadingProgram ||
      loadingProjects ||
      loadingInvitations ||
      dataPrograms?.getPrograms?.length === 0 ||
      program == null
    );
  }, [
    loadingPrograms,
    loadingProgram,
    loadingProjects,
    loadingInvitations,
    dataPrograms,
    program,
  ]);

  const tabs = [
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <>
              <PageTab
                selected={selected}
                title={`${capitaliseFirstLetter(
                  enumTranslates[EntityType.Program]
                )} Contents`}
              />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <ProgramContentsTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#contents',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <>
              <PageTab selected={selected} title={`Performance Updates`} />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <ProgramDetailsWrapper tab={'PROGRAM_PERFORMANCE'} />
        </Tab.Panel>
      ),
      hash: '#performance',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => (
            <>
              <PageTab
                selected={selected}
                title={`Timeline${'\u00A0'}& Financials`}
              />
            </>
          )}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <ProgramDetailsWrapper tab={'PROGRAM_OVERVIEW'} />
          </div>
        </Tab.Panel>
      ),
      hash: '#timeline',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Setup`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <AdminTab />
          </div>
        </Tab.Panel>
      ),
      hash: '#setup',
    },
    {
      tab: (
        <Tab as={'div'} key={ulid()}>
          {({ selected }) => <PageTab selected={selected} title={`Links`} />}
        </Tab>
      ),
      content: (
        <Tab.Panel key={ulid()}>
          <div className="flex-col space-y-6">
            <LinksPanel loadingEntity={showLoadingSpinner} entity={program} />
          </div>
        </Tab.Panel>
      ),
      hash: '#links',
    },
  ];

  return (
    <BasicHeaderWithTabsLayout
      header={<PageHeader loading={showLoadingSpinner} />}
      tabs={tabs}
      isLoading={showLoadingSpinner}
    />
  );
}

export default ProgramHomepage;
